import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import structureIc from '../../../images/icon/structure-ic.png';
import { MdOutlinePeople } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import TournamentHeader from './common/TournamentHeader';
import { capitalize, getTournamentData } from '../../../utils/common.utils';
import { TiInputChecked } from "react-icons/ti";
import { FaHourglassStart } from 'react-icons/fa';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';

class TournamentStructure extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: null
        }
        this.props.updateLoader(true);
        getTournamentData(this.props.match.params.id, this.setData)

    }


    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.props.updateLoader(false);
            this.setState({
                tournamentDetails: values
            })
            this.props.dispatchData(values)
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    editStageDetails = (e, stage, index) => {
        console.log("**STAGE :: ", stage)
        e.preventDefault();
        this.props.history.push(`/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview/structure/tournament-stages/${stage.path}?index=${index}&isUpdate=${true}`);
    }

    getAction = (stage, index) => {
        switch (stage.status) {
            case "UPCOMING":
                return <FiEdit className='text-white float-end' onClick={(e) => this.editStageDetails(e, stage, index)} />
            case "IN_PROGRESS":
                return <FaHourglassStart className='text-white float-end' onClick={(e) => this.editStageDetails(e, stage, index)} />
            default:
                return <TiInputChecked className='btn-size-20 text-white float-end' />
        }
    }

    getStageDetails = (stage, i) => {
        var maxParticipants;
        if(stage.subType === 'FFA Flexible Stage'){
            maxParticipants = (stage.numberOfMatches)*(stage.maxParticipantsPerMatch);
        }else{
            maxParticipants = (stage.maxParticipants)
        }
        return (
            <div className='row'>
                <div className='col-10'>
                    <h6 className='fs-12 fw-bold text-warning mb-3'>{stage.name} (Stage {i + 1})</h6>
                </div>
                {/* <div className='col-7'>
                    <h6 className='fs-14 fw-bold text-white text-center mb-3 text-truncate'>{stage.name}</h6>
                </div> */}
                <div className='col-2'>
                    {this.getAction(stage, i)}
                </div>
                <div className='col-6'>
                    <h6 className='fs-12 fw-bold text-white'>{stage.subType}</h6>
                </div>
                <div className='col-6'>
                    <h6 className='fs-12 fw-bold text-white float-end'>{capitalize(stage.status.toString().toLowerCase())}</h6>
                </div>
                <div className='col-6'>
                    <h6 className='fs-12 fw-bold text-white'><MdOutlinePeople className='fs-16' />{stage.participants}</h6>
                </div>
                <div className='col-6'>
                    <p className='fs-12 mb-0 fw-bold text-white float-end'>{stage.participants}/{maxParticipants}</p>
                </div>
            </div>
        )
    }


    render() {
        // console.log("STAGES :: ",this.state.tournamentDetails?.stages)
        return (
            <div className="body-section tournament-structure">
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading="Structure" subHeading={null} history={this.props.history} />

                    <div className='row mt-5'>

                        {this.state.tournamentDetails?.stages?.map((stage, i) => {
                            const opacity = (stage.status === "COMPLETED") ? 0.7 : 1;
                            if (stage.status === "UPCOMING") {
                                return (
                                    <div className='col-lg-4 col-md-6 col-12' key={i} >
                                        <div className='brown-shadow p-3 mb-3' style={{ opacity: `${opacity}` }}>
                                            <NavLink to={{
                                                pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview/structure/tournament-stages/${i}/book-slot`,
                                                state: {
                                                    structure: stage
                                                }
                                            }}>
                                                {this.getStageDetails(stage, i)}
                                            </NavLink>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='col-lg-4 col-md-6 col-12 ' key={i} style={{ opacity: `${opacity}` }}>
                                        <div className='brown-shadow p-3 mb-3' style={{ opacity: `${opacity}` }}>
                                            {this.getStageDetails(stage, i)}
                                        </div>
                                    </div>
                                )
                            }
                            // return (

                            //     <div key={i}>
                            //         {stage.status === "UPCOMING" ?
                            //             <div className='col-lg-4 col-md-6 col-12 brown-shadow p-3 mb-3 rounded-15' key={i} style={{ opacity: `${opacity}` }}>
                            //                 <NavLink to={{
                            //                     pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview/structure/tournament-stages/${i}/book-slot`,
                            //                     state: {
                            //                         structure: stage
                            //                     }
                            //                 }}>
                            //                     {this.getStageDetails(stage, i)}
                            //                 </NavLink>

                            //             </div>
                            //             :

                            //         }
                            //     </div>
                            // )

                        }
                        )}



                    </div>
                    <div className='row '>
                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-md-4 col-12 ">
                            <div className='black-flat-btn d-flex flex-row align-items-center justify-content-center height-45 rounded-10 mt-5'>
                                <NavLink className="text-white" to={{
                                    pathname: `/organization/${this.props.match.params?.orgId}/tournament/${this.props.match.params.id}/overview/structure/tournament-stages`,
                                    state: {
                                        isConfig: true
                                    }
                                }}>
                                    <button className="btn" type="button">
                                        <AiFillPlusCircle className="text-white btn-size-25" />
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(TournamentStructure)