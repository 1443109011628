import React, { Component } from 'react'
import CommonApis from "../../../helper/common.api";
import sponsorIc from '../../../images/icon/sponsor-ic.png';

import { errorHandler, getTournamentData, SponsorTypes } from '../../../utils/common.utils';
import { Modal } from "react-bootstrap";

// Icon
import { AiFillPlusCircle } from "react-icons/ai";
import Sponsor from '../../popup/Sponsor';
import { FiEdit } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import ConfirmationModal from '../../popup/ConfirmationModal';
import { BsArrowRight } from 'react-icons/bs';
import TournamentApis from '../../../helper/tournament.api';
import MicroWebSiteApis from '../../../helper/microwebsite.api';
import { toast } from 'material-react-toastify';
import TournamentHeader from './common/TournamentHeader';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';

class TournamentSponsor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sponsorImage: null,
            sponsorIndex: null,
            sponsorForUpdate: null,
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : {},
            sponsorList: props?.tournamentData?._id ? props?.tournamentData?.sponsorDetails : [],
            openConfirm: false,
            deleteSponsorId: null,
        }
        this.commonApi = new CommonApis();
        this.tournamentApis = new TournamentApis();
        this.microWebSiteService = new MicroWebSiteApis();
        this.sponsorImageUrl = null;
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        console.log(values)
        if (values) {
            console.log('true')
            this.setState({
                tournamentDetails: values,
                sponsorList: values?.sponsorDetails
            })
            this.props.dispatchData(values)
        } else {
            console.log('false')
            this.props.history.push('/pageNotFound')
        }
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                sponsorImage: URL.createObjectURL(img)
            });
            const formData = new FormData();
            formData.append('file', event.target.files[0])
            this.commonApi.uploadSingleImg(formData)
                .then((response: AxiosResponse) => {
                    this.sponsorImageUrl = response.data.result;
                })
                .catch((err: AxiosError) => console.log(err));
        }
    };

    onClearImage = () => {
        this.setState({ sponsorImage: null })
    }

    triggerInputFile = () => this.fileInput.click();

    addOrRemoveSponsor = (event, isAddSponsor, index) => {
        event.preventDefault();
        var updatedSponsorList = this.state.sponsorList;
        isAddSponsor ? updatedSponsorList.push(null) : updatedSponsorList.splice(index, 1);
        this.setState({
            sponsorList: updatedSponsorList
        });

    }

    addSponsor = (values, logo) => {
        var updatedSponsorList = this.state.sponsorList;
        values.logo = logo;
        updatedSponsorList.push(values);
        this.setState({
            sponsorList: updatedSponsorList,
            sponsorForUpdate: null
        })
    }

    updateSponsor = (values, logo) => {
        var updatedSponsorList = this.state.sponsorList;
        values.logo = logo;
        updatedSponsorList[this.state.sponsorIndex] = values;
        if (this.state.sponsorForUpdate?._id) {
            updatedSponsorList[this.state.sponsorIndex]._id = this.state.sponsorForUpdate._id;
            this.props.updateLoader(true);
            this.microWebSiteService
                .updateSponsor(updatedSponsorList[this.state.sponsorIndex])
                .then((res: AxiosResponse) => {
                    this.props.updateLoader(false);
                    var tournamentDetails = this.state.tournamentDetails;
                    tournamentDetails.sponsorDetails = updatedSponsorList;
                    this.props.dispatchData(tournamentDetails)
                    this.setState({
                        sponsorImage: null,
                        sponsorIndex: null,
                        sponsorForUpdate: null,
                        sponsorList: updatedSponsorList,
                        tournamentDetails: tournamentDetails
                    })
                    toast.success("Sponsor updated successfully");
                })
                .catch((err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                });
        } else {
            this.setState({
                sponsorList: updatedSponsorList,
                sponsorForUpdate: null
            })
        }

    }


    updateSponsorDetails = (e, key, index) => {
        e.preventDefault();
    }

    deleteSponsor = (sponsorId, index) => {
        var updatedSponsorList = this.state.sponsorList;
        this.props.updateLoader(true);
        this.microWebSiteService.deleteSponsor(sponsorId)
            .then((res: AxiosResponse) => {
                this.props.updateLoader(false);
                updatedSponsorList.splice(index, 1);
                var tournamentDetails = this.state.tournamentDetails;
                tournamentDetails.sponsorDetails = updatedSponsorList;
                this.props.dispatchData(tournamentDetails)
                this.setState({
                    sponsorList: updatedSponsorList,
                    tournamentDetails: tournamentDetails
                })
                toast.success("Sponsor deleted successfully");
            })
            .catch((err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            });
    }

    openEditSponsor = (sponsor, index) => {
        let data = sponsor
        data.index = index
        this.setState({
            sponsorIndex: index,
            sponsorForUpdate: data,
        })
    }


    sponsorDeleteMsg = (name, index) => {
        this.setState({
            deleteSponsorId: index
        });
        return (
            <p className="fs-14 fw-600 text-center text-white-50">
                Are you sure you want to delete {name} from sponsors?
            </p>
        );
    };

    //open confirmation popup
    onClickOpenConfirm = (key?, msg?) => {
        const data = {
            msg: msg ? msg : "",
            key: key ? key : "",
        };
        this.setState({
            openConfirm: !this.state.openConfirm,
            confirm: data,
        });
    };

    //open confirmation
    confirmed = (key) => {
        var updatedSponsorList = this.state.sponsorList;
        if (key === "deleteSponsor") {
            this.deleteSponsor(updatedSponsorList[this.state.deleteSponsorId]._id, this.state.deleteSponsorId);
        } else {
            updatedSponsorList.splice(this.state.deleteSponsorId, 1);
        }
        this.setState({ openConfirm: false, deleteSponsorId: null })

    };


    onSubmit = () => {
        var data = {
            sponsorDetails: this.state.sponsorList.filter((sponsor) => sponsor !== null)
        }

        this.props.updateLoader(true);
        this.tournamentApis.updateTournaments(this.props.match.params.id, data).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                this.props.dispatchData(res.data.result)
                toast.success("Sponsors details updated successfully");
                this.props.history.push({
                    pathname: `/organization/${res.data.result.organizationId}/tournament/${this.props.match.params.id}/overview`,
                    state: {
                        orgId: res.data.result.organizationId,
                        tournamentDetails: res.data.result
                    }
                });
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )

    }

    render() {
        const { invalidImage } = this.state;
        const sponsorTypes = SponsorTypes.map((type, i) => {
            return <option key={i} value={type}>{type}</option>
        });

        return (
            <div className="body-section tournament-sponsor">
                <div className="container p-3 mb-5 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={sponsorIc} heading="Sponsor" subHeading={null} history={this.props.history} />

                    <div className='row p-3 justify-content-center'>

                        {this.state.sponsorList?.map((el, i) => {
                            if (el && !(el.ban || el.block)) {
                                return (
                                    <div className='col-lg-4 col-md-6 col-12 mb-2' key={i}>
                                        <div className="black-flat-btn rounded-10 text-center sponsor-border pt-4 mt-3 pb-4" style={{ height: "auto" }} key={i}  >
                                            <div className="col-10 offset-1 d-flex justify-content-center">
                                                <h1 className="fs-14 fw-600 text-white mt-2"> Sponsor {i + 1} </h1>
                                                <FiEdit className="text-white mt-2 ms-3" onClick={() => { this.openEditSponsor(el, i); }} />
                                                <HiOutlineTrash className="text-white mt-2 ms-2" onClick={() => {
                                                    this.onClickOpenConfirm(el._id ? "deleteSponsor" : "deleteTempSponsor", this.sponsorDeleteMsg(el.name, i));
                                                }}
                                                />
                                            </div>
                                            <div className="col-10 offset-1">
                                                <label className="fs-14 fw-600 text-white mt-2 float-start" htmlFor="" >
                                                    Sponsor Name
                                                </label>
                                                <input type="text" value={el.name} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                            </div>
                                            <div className="col-10 offset-1">
                                                <label className="fs-14 fw-600 text-white mt-2 float-start" htmlFor="" >
                                                    Sponsor Title
                                                </label>
                                                <input type="text" value={el.type} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                            </div>
                                            <div className="align-self-center mt-3">
                                                <img src={el.logo?.url} alt="" width="200" height='100' className="border border-2 rounded-10" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>

                    <div className='row p-3'>
                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12 black-flat-btn d-flex flex-row align-items-center justify-content-center height-45 rounded-15" onClick={() => { this.setState({ sponsorForUpdate: {} }) }}  >
                            <button className="btn" type="button" >
                                <AiFillPlusCircle className="text-white btn-size-25" />
                            </button>
                        </div>


                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12 d-flex justify-content-center">
                            <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-40 position-fixed" style={{ bottom: '60px' }} type="submit" onClick={this.onSubmit}>
                                <BsArrowRight className='black-rock fs-24' />
                            </button>
                        </div>

                        {/* <div className="d-flex justify-content-center mt-5">
                            <NavLink className="text-white" to="/configureMenu">
                                <button className="round-btn" type="submit">
                                    <img src={refresh} className="" />
                                </button>
                            </NavLink>

                        </div> */}
                    </div>
                </div>
                <Modal show={this.state.sponsorForUpdate} centered dialogClassName="BioEdit-modal" >
                    <Modal.Body>
                        <Sponsor
                            updateSponsor={this.updateSponsor}
                            updateLoader={this.props.updateLoader}
                            sponsorIndex={this.state.sponsorIndex}
                            addSponsor={this.addSponsor}
                            sponsorForUpdate={this.state.sponsorForUpdate}
                            sponsorArray={this.state.sponsorList?.length}
                            onClose={() => { this.setState({ sponsorForUpdate: null }) }} />
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.openConfirm}
                    centered
                    dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={this.state.confirm}
                            onClickOpenConfirm={this.onClickOpenConfirm}
                            confirmed={this.confirmed} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(TournamentSponsor)
