import React, { Component } from 'react';
import { getTournamentAdditionalData, getTournamentData } from '../../../../../../utils/common.utils';
import NoRecordFound from '../../../../../common/NoRecordFound';
import MatchesDuelScore from './MatchesDuelScore';
import MatchesFFAScore from './MatchesFFAScore';

export default class Score extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            tournamentDetails: null
        }
        props.updateLoader(true);
        getTournamentData(this.props.match.params.id, this.setData);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            });
            this.props.updateLoader(false);
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }
    
    render() {
        if(this.state.tournamentDetails){
            return (
                <div>
                    {(this.props.match.params?.type).includes('FFA') ?
                        <MatchesFFAScore  tournamentDetails={this.state.tournamentDetails} matchId={this.props.match.params.matchId} updateLoader={this.props.updateLoader} id={this.props.match.params.id} type={this.props.match.params?.type} showOrHideNav={this.props.showOrHideNav} history={this.props.history} props={this.props}/>
                    :
                        <MatchesDuelScore  tournamentDetails={this.state.tournamentDetails} matchId={this.props.match.params.matchId} updateLoader={this.props.updateLoader} id={this.props.match.params.id} type={this.props.match.params?.type} showOrHideNav={this.props.showOrHideNav} history={this.props.history} props={this.props}/>
                    }
                    
                </div>
            );
        }else{
            return (
                <NoRecordFound />
            );
        }
    }
}
