import React, { Component } from 'react';
import BettingApis from '../../../../../../helper/betting.api';
import MatchesApis from '../../../../../../helper/matches.api';
import Switch from "react-switch";
import betPoolIc from '../../../../../../images/icon/betPool-ic.png';
import betWhiteIc from '../../../../../../images/icon/betWhite-ic.png';
import { NavLink } from 'react-router-dom';
import { IoIosArrowForward, IoIosCloseCircleOutline } from 'react-icons/io';
import BettingStatementCard from '../score/common/BeatingStatementCard';
import { AiFillPlusCircle } from 'react-icons/ai';
import refresh from '../../../../../../images/icon/refresh-ic.png';
import instructionIcon from '../../../../../../images/icon/instruction-ic.png';
import bettingSwitchStatement from '../../../../../../images/bet/bettingSwitchStatement.png';
import { errorHandler, getTermsAndConditionsByType, getTournamentData, updateLoader } from '../../../../../../utils/common.utils';
// modals
import { Modal } from 'react-bootstrap';
import { toast } from 'material-react-toastify';
import BettingStatementForm from '../score/common/BettingStatementForm';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import NoRecordFound from '../../../../../common/NoRecordFound';
import BettingStatementResult from './BettingStatementResult';
import TermsConditions from '../../../../../popup/TermsConditions';
import { checkBettingStatus, getBettingStatements, getMatchDetails, getTeamsAndPlayers } from '../../../../../../utils/Betting.utils';
import { setUpdateScore } from '../../../../../../redux/actions/tournametActions';
import { connect } from 'react-redux';

import Bet from '../../../../../../images/icon/bet.png';
import TournamentHeader from '../../../common/TournamentHeader';
import { ParticipantType, TERMS_AND_CONDITIONS_TYPES } from '../../../../../../utils/enums.utils';


const mapStateToProps = (props) => {
    return {
        score: props.updateScore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data) => dispatch(setUpdateScore(data))
    }
}


class MatchBetting extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: null,
            disableChat: false,
            matchDetails: null,
            isBettingEnabled: false,
            selectedBettingStatements: [],//props.selectedBettingStatements,
            publishedTournamentCount: 0,//props.publishedTournamentCount,
            totalPublishedBettingPool: 0,//props.totalPublishedBettingPool,
            bettingStatements: [],//props.bettingStatements,
            payableAmount: props.payableAmount,
            showAddStatementModal: false,
            correctChoices: [],
            selectedIndex: null,
            gameNo: 0,
            statementId: null,
            openTermsModal: false,
            acceptCheck: false,
            togglePaymentModal: false,
            openConfirm: false,
        }
        //this.setSelectedBettingStatementsDetails(props.matchDetails,0)
        //console.log("-------matchId : ",this.props.match.params['id'])
        updateLoader(true);
        getTournamentData(this.props.match.params['id'], this.setData);

        getBettingStatements(this.props.match.params['matchId'], this.setBettingStatements);
    
        this.matchesApis = new MatchesApis();
        this.bettingApis = new BettingApis();
        this.getTermsData = getTermsAndConditionsByType(TERMS_AND_CONDITIONS_TYPES.SET_BET_FOR_GAME);
    }

    componentDidMount(){
        this.props.showOrHideNav(true);
      //  this.setSelectedBettingStatementsDetails(this.props.matchDetails,0)
    }

    // componentWillReceiveProps(newProps){
    //     this.setState({
    //         tournamentDetails: newProps.tournamentDetails,
    //         publishedTournamentCount: newProps.publishedTournamentCount,
    //         totalPublishedBettingPool: newProps.totalPublishedBettingPool,
    //         bettingStatements: newProps.bettingStatements,
    //         matchDetails: newProps.matchDetails,
    //     });
    //    // this.setSelectedBettingStatementsDetails(newProps.matchDetails,this.state.gameNo)
    // }

    setBettingStatements = (bettingStatements) => {
        this.setState({
            bettingStatements: bettingStatements
        });
    }


    setMatchDetails = (response) => {
        // this.setState({
        //     matchDetails: response
        // })

        try{
            var selectedBettingStatements = [];
            var payableAmount = 0;
            var publishedTournamentCount =0;
            var totalPublishedBettingPool=0;
            response?.matchBettingStatements?.map((statement,i)=>{
                if(!statement.isPaid){
                    selectedBettingStatements.push({
                        _id: statement._id,
                        statement: statement.bettingStatement,
                        options: statement.choices,
                        bettingPool: statement.bettingPool,
                        entryFees: statement.entryFees
                    });
                    payableAmount += statement.bettingPool;
                }else{
                    publishedTournamentCount++;
                    totalPublishedBettingPool += statement.bettingPool;
                }
            });
            console.log("------ tournamentDetails :: ",this.state.tournamentDetails)
            var playersDetails = getTeamsAndPlayers(this.state.tournamentDetails?.participantType,response.results[0])
           
           
            this.setState({
                matchDetails: response,
                isBettingEnabled: response.isBettingEnabled,
                disableChat: response?.chatEnabled,
                selectedBettingStatements: selectedBettingStatements,
                payableAmount: payableAmount,
                publishedTournamentCount: publishedTournamentCount, 
                totalPublishedBettingPool: totalPublishedBettingPool,
                players: playersDetails[1]? playersDetails[1] : []
            });
            this.setSelectedBettingStatementsDetails(response,0)

            
       
        }catch(err){
             console.log(err)
        }
    }

    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            });
            updateLoader(false);
            getMatchDetails(this.props.match.params['matchId'],this.setMatchDetails)
        } else {
            updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    updateBettingStatus = () => {
        // if(!this.state.tournamentDetails?.organizationDetails?.isBettingEnabled){
        //     toast.error("Betting for this tournament's organization is currently disabled by Espotz Admin");
        //     return false;
        // }
        if(!this.state.isBettingEnabled){
            this.setState({openConfirm: false});
        }
        if(!this.state.matchDetails?.matchStreamingLink){
            toast.error("You need to set match's stream link before enabling betting on this match.");
            return false;
        }
        if(this.state.matchDetails?.participants?.length < 2){
            toast.error("You need minimum 2 participants to enable betting.");
            return false;
        }
        let canAddBets = checkBettingStatus(this.state.matchDetails?.matchSchedule);
        if(!this.state.isBettingEnabled && !canAddBets) {
            toast.error('You can only enable betting 5 mins before match start time.');
            return false;
        }

        if(this.state.isBettingEnabled){
            toast.warning('You cannot disable the betting status after it is enabled.');
            return false;
        }

        updateLoader(true)
        let data = {
            isBettingEnabled: !this.state.isBettingEnabled
        }
        this.matchesApis.editMatchDetails(this.props.match.params['matchId'], data).then(
            (res: AxiosResponse) => {
                this.setState({ isBettingEnabled: res.data['result']?.isBettingEnabled })
                updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err);
            }
        )
    }

    onClickEditStatement = (index,statement) =>{
        this.setState({
            showAddStatementModal: true,
            selectedIndex: index,
            statementId: statement.statement._id
        })
    }

    showOrHideAddStatementModal = () =>{
        this.setState({
            showAddStatementModal: !this.state.showAddStatementModal,
            selectedIndex: null,
            statementId: null
        })
    }

    addUpdateStatement = (values, isUpdate, selectedIndex) =>{
     
      
            let selectedStatement = this.state.bettingStatements[parseInt(values.statement)]
            var newStatement = {
                statement: selectedStatement,
                bettingStatementId: selectedStatement,
                options: selectedStatement?.type,
                bettingPool: parseInt(values.bettingPool),
                entryFees: parseInt(values.entryFees)
            }
    
            if(selectedStatement?.type === 'CUSTOM'){
                newStatement.choices = values.choices;
            }
    
            this.addUpdateBettingStatementToMatch([newStatement], selectedIndex);
    }


    addUpdateBettingStatementToMatch = (newStatement, selectedIndex?) => {

        var bettingStatements = newStatement.map((bet,i)=>{ 
            return {
                bettingStatementId: bet.statement._id,
                bettingPool: bet.bettingPool,
                entryFees: bet.entryFees,
                choices: bet.statement.type === 'CUSTOM' ? (bet.choices? bet.choices : bet.options) : this.getBettingOptionBetweenTeamAndPlayers(bet.statement.type),
                correctChoice: null,
                playoffFormatIndex: this.state.gameNo
            };
        });

        var data = {
            matchBettingStatements : bettingStatements
        };

        updateLoader(true);
        this.bettingApis.addUpdateBettingStatementToMatch(this.state.matchDetails._id, data,selectedIndex).then((res: AxiosResponse) => {
            this.setSelectedBettingStatementsDetails(res.data.result[0],this.state.gameNo);
            updateLoader(false);
            toast.success("Betting statements updated successfully");
           
        }).catch((reason: AxiosError) => {
            updateLoader(false);
            errorHandler(reason);
        });

      
    }

    getBettingOptionBetweenTeamAndPlayers = (optionsType) =>{
        var result;
        if(this.state.tournamentDetails?.participantType === 'TEAM'){
      
            if(optionsType === 'TEAM'){
                result = this.state.matchDetails?.participants?.map((participant,i)=>{
                    if((typeof participant) === 'string'){
                        return participant;
                    }else{
                        return participant._id;
                    }
                    
                });
            }else{
                result = [];
                this.state.matchDetails?.participants?.map((participant,i)=>{
                    participant?.members?.players?.map((player,j)=>{
                        result.push(player)
                    });
                });
            }          
        }else{
            result = this.state.matchDetails?.participants?.map((participant,i)=>{
                 return participant._id;
             });
        }
        return result;
    }

    setSelectedBettingStatementsDetails = (response,gameIndex) =>{ 
        try{
            var selectedBettingStatements = [];
            var payableAmount = 0;
            var publishedTournamentCount =0;
            

            console.log(`response-------- `,response)
            
            if(!response.resultAdded) {
                response?.matchBettingStatements?.map((statement,i)=>{
              
               
                    if((statement.isPaid === false) && (statement.playoffFormatIndex === gameIndex)){
                   
                        selectedBettingStatements.push({
                            _id: statement._id,
                            statement: statement.bettingStatement,
                            options: statement.choices,
                            bettingPool: statement.bettingPool,
                            entryFees: statement.entryFees,
                            playoffFormatIndex: statement.playoffFormatIndex
                        });
                        payableAmount += statement.bettingPool;
    
                
                        
                    }
                 
                    if((statement.isPaid) && (statement.playoffFormatIndex === gameIndex)){
                        publishedTournamentCount++;
                    }
                });   
            }else{
                
                response?.matchBettingStatements?.map((statement,i)=>{
              
                   if((statement.isPaid === true) && (statement.playoffFormatIndex === gameIndex)){
               
                    selectedBettingStatements.push({
                            _id: statement._id,
                            statement: statement.bettingStatement,
                            options: statement.choices,
                            bettingPool: statement.bettingPool,
                            entryFees: statement.entryFees,
                            playoffFormatIndex: statement.playoffFormatIndex,
                            correctChoice: statement.correctChoice
                        });
                        payableAmount += statement.bettingPool;
    
                    
                    }
                 
                    if((statement.isPaid) && (statement.playoffFormatIndex === gameIndex)){
                        publishedTournamentCount++;
                    }
                });  
            }
          

            
            this.setState({
                matchDetails: response,
                disableChat: response?.chatEnabled,
                selectedBettingStatements: selectedBettingStatements,
                showAddStatementModal: false,
                openConfirmationModal: false,
                payableAmount: payableAmount,
                publishedTournamentCount: publishedTournamentCount, 
                gameNo: gameIndex,
                acceptCheck: false,
                confirmMessage : {
                    msg: null,
                    key: null
                }
            });

     

        }catch(err){
             console.log(err)
        }
       
    }

    // Get TO's confirmation before delete a statement
    onClickOpenConfirm = (index) => {

        if(index !== null && index !== undefined){
            this.setState({
                openConfirmationModal: true, 
                confirmMessage : {
                    msg: (
                        <p className="fs-14 fw-600 text-center text-white-50">
                            Are you sure you want to delete this statement from this match's betting ?
                        </p>
                    ),
                    key: index
                }
            });
        }else{
            this.setState({
                openConfirmationModal: false, 
                confirmMessage : { msg: null,  key: null }
            });
        }
    }

    // Delete statement after confirmation
    onConfirmed = (index) =>{
        updateLoader(true);
        this.bettingApis.deleteBettingDetails(this.state.selectedBettingStatements[index]._id).then((res: AxiosResponse) => {
            this.setSelectedBettingStatementsDetails(res.data.result[0],this.state.gameNo);
            updateLoader(false);
            toast.success("Betting statements deleted successfully");
        }).catch((reason: AxiosError) => {
            updateLoader(false);
            errorHandler(reason);
         });
    }

    makePaymentsAgainstBettingStatements = () =>{
        // For now Disabled Due to Dependencies
        //this.setState({ KYCModal: true })
        updateLoader(true);
        this.bettingApis.payStatementEC(this.state.matchDetails._id,this.state.gameNo).then((res: AxiosResponse) => {
            updateLoader(false);
            this.props.history.push(`/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/match/${this.props.type}/${this.props.match.params['matchId']}/score/publish-statement/${this.state.gameNo}`);
            this.togglePaymentModalStatus()
            toast.success("Payment for betting statements has been completed successfully");
           
        }).catch((reason: AxiosError) => {
            updateLoader(false);
            errorHandler(reason);
        });
    }

    getBettingStatementOrResultsCard = (selectedBettingStatements) =>{
        const isTeamGame = this.state.tournamentDetails?.participantType === ParticipantType.TEAM;
        //console.log("-------------isTeamGame", isTeamGame);
        var result;
        if(this.state.matchDetails.resultAdded){
            result = selectedBettingStatements?.map((statement,i)=>{
            
                if(this.state.gameNo === statement.playoffFormatIndex){
                    return  <BettingStatementResult indexId={i} onSelectCorrectAnswer={this.onSelectCorrectAnswer} matchDetails={this.state.matchDetails} isPublished={false} onClickEditStatement={this.onClickEditStatement} onClickOpenConfirm={this.onClickOpenConfirm} key={i} index={i+1} statement={statement} isTeamGame={isTeamGame} />
                }
            })
        }else{
            result = selectedBettingStatements?.map((statement,i)=>{
                if(this.state.gameNo === statement.playoffFormatIndex){
                    return  <BettingStatementCard matchDetails={this.state.matchDetails} isPublished={false} onClickEditStatement={this.onClickEditStatement} onClickOpenConfirm={this.onClickOpenConfirm} key={i} index={i+1} statement={statement} isTeamGame={isTeamGame} />
                }
            })
        }
        return result;
    }


    onSelectCorrectAnswer = (key, choice, statement) => {
        // console.log(`
        // key :: ${key}
        // choice :: ${choice}
        // statement ::
        // `,statement);

        var newChoice = {
            bettingStatementDetailsId: statement._id,
            correctChoice: choice
        }

        const correctChoices = this.state.correctChoices.filter((choice) => choice.bettingStatementDetailsId !== statement._id);

        correctChoices.push(newChoice);

        var selectedBettingStatements = this.state.selectedBettingStatements;

        selectedBettingStatements[key].correctChoice = choice;
        
        
        this.setState({
            selectedBettingStatements: selectedBettingStatements,
            correctChoices: correctChoices
        });

    }

    updateMultipleBettingResults = () =>{

        if(this.state.acceptCheck){

            
            updateLoader(true);
            this.bettingApis.updateMultipleBettingResults(this.state.correctChoices).then((res: AxiosResponse) => {
                this.setSelectedBettingStatementsDetails(res.data.result[0],this.state.gameNo);
                updateLoader(false);
                toast.success("Betting result updated successfully");
               
            }).catch((reason: AxiosError) => {
                updateLoader(false);
                errorHandler(reason);
            });
        }else{
            toast.warning("Please accept the terms and conditions.")
        }

    }

    togglePaymentModalStatus = () => {
        this.setState({paymentModalStatus: !this.state.paymentModalStatus});
    }

    toggleConfirmModalStatus = () => { 
        if(!this.state.tournamentDetails?.organizationDetails?.isBettingEnabled){
            toast.error("Betting for this tournament's organization is currently disabled by Espotz Admin");
            return false;
        } 
        this.setState({openConfirm: !this.state.openConfirm});
    }


    render() {
        const { gameNo,confirmMessage, openConfirmationModal, selectedBettingStatements,matchDetails } = this.state;
        console.log('getTermsData ::', this.getTermsData);
        var perGameBettingPool = 0;

        // statementId={this.state.statementId}
        // selectedBettingStatements={this.state.selectedBettingStatements} 
        // selectedIndex={this.state.selectedIndex} 
        // bettingStatements={this.state.bettingStatements} 

        console.log("-------- statementId :: ",this.state.statementId)
        console.log("-------- selectedBettingStatements :: ",this.state.selectedBettingStatements)
        console.log("-------- selectedIndex :: ",this.state.selectedIndex)
        console.log("-------- bettingStatements :: ",this.state.bettingStatements)
        console.log("--------------------------------------------------------")

        selectedBettingStatements?.forEach((statement,i)=>{
            if(this.state.gameNo === statement.playoffFormatIndex){
                perGameBettingPool += statement.bettingPool;
            }
        });

        

        if(this.state.matchDetails){
            var canAddBets = checkBettingStatus(this.state.matchDetails?.matchSchedule);
            //console.log('canAddBets ::', canAddBets);
            return (
                <section className="body-section match-duel-score">
                    <div className="container p-3 mt-lg-3">
                        <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={Bet} heading="Betting" subHeading={null} history={this.props.history} />
                        
                        <section>
                            <div className='row'>
                                <div className='col-lg-6 offset-lg-3 col-12'>
                                    <div className="contact-toggle d-flex align-items-center rounded-10 height-45 my-3">
                                        <p className="fs-16 fw-600 mb-0 flex-grow-1 ps-3">{this.state.isBettingEnabled ? 'Disable Betting' : 'Enable Betting'}</p>
                                        <Switch checked={this.state.isBettingEnabled} checkedIcon={false} uncheckedIcon={false} className="me-2" onChange={this.state.isBettingEnabled ? this.updateBettingStatus : this.toggleConfirmModalStatus} />
                                    </div>
                                </div>

                                <div className='d-flex overflow-auto'>

                                {this.state.matchDetails && this.state.isBettingEnabled &&  this.state.matchDetails?.results.length > 1 &&
                                    <button type="button" className="score-showdown-btn me-2" onClick={()=>this.setSelectedBettingStatementsDetails(this.state.matchDetails,0)} key={-1} style={{ opacity: this.state.gameNo === 0 ? 1 : 0.5 }}>RESULT</button>
                                }
                                {this.state.matchDetails && this.state.isBettingEnabled &&
                                    this.state.matchDetails?.results.length > 1 &&
                                    this.state.matchDetails?.results?.map((game, i) => {
                                        if(i===0){
                                            return(
                                                    <button type="button" className="score-showdown-btn me-2" onClick={()=>this.setSelectedBettingStatementsDetails(this.state.matchDetails,(i+1))} key={i} style={{ opacity: this.state.gameNo === (i+1) ? 1 : 0.5 }}>GAME {i + 1}</button>
                                            )
                                        }else{
                                            return <button type="button" className="score-showdown-btn me-2" onClick={()=>this.setSelectedBettingStatementsDetails(this.state.matchDetails,(i+1))} key={i} style={{ opacity: this.state.gameNo === (i+1) ? 1 : 0.5 }}>GAME {i + 1}</button>;
                                        }
                                    })
                                }
                                </div>

                                {this.state.isBettingEnabled ?
                                    <div className="">

                                        {/* Betting Payment Details */}
                                        {matchDetails.resultAdded ?
                                            <div className='dark-gray-card px-4 py-3'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='d-flex align-items-center'>
                                                                <img src={betPoolIc} className='btn-size-20 mb-1' alt='' />
                                                                <p className='fs-14 fw-700 text-warning mb-0 ms-2'>BETTING POOL</p>
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <p className='fs-14 fw-700 text-warning mb-0'>{perGameBettingPool} EC</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <div className='dark-gray-card px-4 py-3'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={betPoolIc} className='btn-size-20 mb-1' alt='' />
                                                            <p className='fs-14 fw-700 text-warning mb-0 ms-2'>BETTING POOL</p>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <p className='fs-14 fw-700 text-warning mb-0'>{this.state.matchDetails?.results.length === 1 ? this.state.totalPublishedBettingPool : this.state.matchDetails?.matchBettingStatements[this.state.gameNo]?.bettingPool} EC</p>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div>
                                                    <div className='col-12'>
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <div className='d-flex align-items-center'>
                                                                <img src={betWhiteIc} className='btn-size-15 mb-1' alt='' />
                                                                <p className='fs-14 fw-700 text-white mb-0 ms-2'>Published Statements:</p>
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <p className='fs-14 fw-700 text-warning mb-0 me-2'>{this.state.publishedTournamentCount}</p>
                                                                <NavLink to={`/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/match/${this.props.type}/${this.props.match.params['matchId']}/score/publish-statement/${this.state.gameNo}`}>
                                                                    <IoIosArrowForward className='text-white' />
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        {/* <button className='pink-skyblue-gradient-btn fs-14 fs-lg-18 fw-600 height-45 rounded-10 outline-0 border-0 text-white mt-2 w-100'
                                                            style={{ textDecoration: 'none' }} onClick={() => this.setState({ KYCModal: true })}>
                                                            Complete KYC
                                                        </button> */}
                                                        <button className={`pink-skyblue-gradient-btn fs-14 fs-lg-18 fw-600 height-45 rounded-10 outline-0 border-0 text-white mt-2 w-100 ${ this.state.payableAmount ?'':'opacity-50'}`} disabled={this.state.payableAmount == 0 ? true : false}
                                                            style={{ textDecoration: 'none' }} onClick={this.togglePaymentModalStatus}>
                                                            PAY
                                                            <span className='ms-2'>( {this.state.payableAmount} EC )</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    

                                        {this.state.matchDetails &&
                                            <div className='col-12 mt-3 ms-1'>
                                                <h4 className='fs-14 fw-700 text-white '>Total Statements : {this.state.selectedBettingStatements?.length}</h4>
                                            </div>    
                                        }
                                        
                                        <div className='row'>
                                            {/* Betting Statements List */}
                                            {this.state.matchDetails &&
                                                this.getBettingStatementOrResultsCard(selectedBettingStatements)
                                            }
                                            <div className={`${selectedBettingStatements.length ? 'd-flex justify-content-center align-items-center w-100 my-3 mt-5': 'd-none'}`}>
                                                <input type="checkbox" className='me-2' id="dialog"
                                                    checked={this.state.acceptCheck}
                                                    onChange={() => this.setState({ openTermsModal: !this.state.openTermsModal, showErrors: false })}
                                                />
                                                <label htmlFor="dialog" className='fs-14 fw-600 mb-0'>I accept the terms of servicesssss</label>
                                            </div>
                                        </div>
                                        
                                        {/* Add new statement */}
                                        {canAddBets &&
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <div className="black-flat-btn d-flex align-items-center justify-content-center height-45 rounded-10" onClick={() => this.setState({ showAddStatementModal: true, selectedIndex:null })}>
                                                        <button className="btn">
                                                            <AiFillPlusCircle className="btn-size-20 text-white" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        }

                                        {/* Refresh and back */}
                                        {!matchDetails.resultAdded ?
                                            <div className="d-flex justify-content-center">
                                                <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mt-5 btn-size-45 cursor-pointer" type="submit" onClick={this.props.switchBetweenResultAndBetting}>
                                                    <img src={refresh} alt="" className='btn-size-20' />
                                                </button>
                                            </div>
                                        :
                                            <div className="d-flex justify-content-center">
                                                <button onClick={()=>this.updateMultipleBettingResults()} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mt-5 btn-size-45 cursor-pointer">
                                                    <img src={refresh} alt="" className='btn-size-20' />
                                                </button>
                                            </div>
                                        }
                                        {/* Betting Info   !matchDetails.resultAdded*/}
                                        { canAddBets &&
                                            <div className='col-12 mt-5'>
                                                <div className='text-center'>
                                                    <img src={instructionIcon} className='' height={'40px'} alt='' />
                                                    <p className='fs-12 fs-lg-16 fw-500 mt-3'>Espotz Commission</p>
                                                </div>
                                                <div className='mt-3'>
                                                    <ol className='ps-0'>
                                                        <li className='fs-12 fs-lg-16 fw-500 text-start'>Commission will be charged by admin on betting pool when Betting pool {'>'} Ticket Collection.</li>
                                                        <li className='fs-12 fs-lg-16 fw-500 text-start mt-1'>Additional commission will be charged by admin on betting pool when Betting pool {'<'} Ticket Collection. </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className='text-center'>
                                        <img src={bettingSwitchStatement} height='200' alt='' />
                                    </div>
                                }
                            </div>

                            <Modal show={this.state.showAddStatementModal} centered dialogClassName="betting-statement-modal">
                                <Modal.Body>
                                    <BettingStatementForm 
                                        statementId={this.state.statementId}
                                        selectedBettingStatements={this.state.selectedBettingStatements} 
                                        selectedIndex={this.state.selectedIndex} 
                                        bettingStatements={this.state.bettingStatements} 
                                        addUpdateStatement={this.addUpdateStatement} 
                                        showOrHideAddStatementModal={this.showOrHideAddStatementModal} 
                                    />
                                </Modal.Body>
                            </Modal>

                            <Modal show={openConfirmationModal} centered dialogClassName="Confirmation-modal">
                                <Modal.Body>
                                    <ConfirmationModal confirm={confirmMessage} confirmed={this.onConfirmed} onClickOpenConfirm={this.onClickOpenConfirm} insteadDeactivate={{}} />
                                </Modal.Body>
                            </Modal>

                            <Modal centered show={this.state.openTermsModal} dialogClassName="social-media-modal">
                                <Modal.Body>
                                    <TermsConditions accept={() => { this.setState({ openTermsModal: false, acceptCheck: true }) }} onClose={() => { this.setState({ openTermsModal: false, acceptCheck: false }) }} terms={this.getTermsData?.rule} />
                                </Modal.Body>
                            </Modal>
                            <Modal show={this.state.paymentModalStatus} centered dialogClassName="accept-invite-modal">
                                <Modal.Body>
                                    <ConfirmationModal
                                        onClickOpenConfirm={this.togglePaymentModalStatus} 
                                        confirmed={this.makePaymentsAgainstBettingStatements}
                                        confirm={{ msg: (<p className="fs-14 fw-600 text-center text-white-50">{`You are paying ${this.state.payableAmount}EC for ${this.state.selectedBettingStatements?.length} betting statement(s), choose yes to continue.`}</p>),  key: null }}
                                    />
                                </Modal.Body>
                            </Modal>
                            <Modal show={this.state.openConfirm} centered dialogClassName="accept-invite-modal">
                                <Modal.Body>
                                    <ConfirmationModal
                                        onClickOpenConfirm={this.toggleConfirmModalStatus} 
                                        confirmed={this.updateBettingStatus}
                                        confirm={{ msg: (<p className="fs-14 fw-600 text-center text-white-50">{`Once the betting is enabled, you cannot disable the betting status. Are you sure you want to enable betting?`}</p>),  key: null }}
                                    />
                                </Modal.Body>
                            </Modal>

                        </section>
                    </div>
                    
                </section>
            );
        }else{
            return  <NoRecordFound />
        }
        
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(MatchBetting)