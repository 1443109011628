import React, { Component } from 'react';
import { getMonthAndDate, linkWithDiscord, saveCloseRegistration, showHideMatchInvite } from '../../../../../../utils/common.utils';
import { HiUserAdd } from "react-icons/hi";
import { Modal } from "react-bootstrap";
import MatchInvite from "../../../../../popup/MatchInvite";
import AcceptInvite from '../../../../../popup/AcceptInvite';
import { addParticipantInTournament, getMyTeams, getStageDetails } from '../../../../../../utils/Tournament.utils';
import { toast } from 'material-react-toastify';
import Registration from '../../../Registration';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import { profile } from '../../../../../../utils/localstorage.utils';
import { ERROR } from '../../../../../../utils/Strings.utils';
import winCup from "../../../../../../images/icon/winCup.png";
import Scoreboard from '../../../Scoreboard';

export default class DuelsRoundRobinMatches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stageDetails: props?.stageDetails? props.stageDetails : null ,
            tournamentDetails: props?.tournamentDetails? props.tournamentDetails : null,
            groupIndex: 0,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null,
            showRegistrationScreen: false,
            matchIndex: '0.0',
            showScoreBoard: false,
            matchDetails: null,
            matchNo: null
        }

        this.enableLoading = false;
        this.authUser = profile();
    }

    componentDidMount(){
        saveCloseRegistration(this.closeRegistrationScreen);
    }

    componentWillReceiveProps(newProps) {
        if(!this.enableLoading){
            this.setState({
                stageDetails: newProps?.stageDetails? newProps.stageDetails : this.state.stageDetails,
                tournamentDetails: newProps?.tournamentDetails? newProps.tournamentDetails : this.state.tournamentDetails,
            })
        }
    }

    // START :: Tournament Registration  Process
    registerForTournament = (e, matchDetail, matchIndex) => {
        e.preventDefault();


        if(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true && this.state.tournamentDetails?.participantType === 'TEAM'){
            this.switchRegistrationScreens(matchDetail,matchIndex);
        }else{

            this.enableLoading = true;
            if(this.state.tournamentDetails?.participantType === 'SINGLE'){
                this.setState({
                    initiateRegistration: true,
                    selectedMatchDetail: matchDetail,
                    selectedMatchIndex: matchIndex
                });
            }else{
                this.setState({
                selectedMatchDetail: matchDetail
                });
                getMyTeams(this.state.tournamentDetails.gameId, matchDetail, this.addParticipantInTournament, this.selectTeams,this.state.stageDetails.level-1,this.state.groupIndex)
            }
        }
    
    }   
    
    selectTeams = (success,data) =>{
        if(success){
            this.setState({
                teamsArray: data.teamsArray,
                initiateRegistration: true,
                selectedMatchDetail: data.selectedMatchDetail
            });
        }else{
            toast.warning(ERROR.NO_TEAM_FOR_TOURNAMENT);
        }

    }

    addParticipantInTournament = (matchId, teamId) =>{
        if(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true){
            this.switchRegistrationScreens(this.state.selectedMatchDetail,this.state.selectedMatchIndex,teamId);
            this.resetRegistration();
        }else{
            addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail._id, teamId, this.state.stageDetails, this.state.tournamentDetails,this.state.stageDetails.level-1,this.state.groupIndex);
        }
    }

    onClickOpenInvite = (joinTeamId?) => {
        this.setState({
            initiateRegistration: !this.state.initiateRegistration
        });
        if (joinTeamId) {
            addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails,this.state.stageDetails.level-1,this.state.groupIndex);
        }
        this.resetRegistration();
    }

    updateStageDetails = (response) =>{
        this.setState({
            stageDetails: response,
        });
        this.resetRegistration();
    }

    resetRegistration = () =>{
        this.setState({
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null
        });
    }

   
    switchRegistrationScreens = (matchDetail, matchIndex, teamId?) =>{
        this.setState({
            showRegistrationScreen : !this.state.showRegistrationScreen,
            selectedMatchDetail: matchDetail,
            matchIndex: matchIndex,
            selectedTeams: (teamId ? teamId : null)
        })
    }
  
    closeRegistrationScreen = () => {
        getStageDetails(this.state.tournamentDetails?._id,this.props.index,this.setData)
    }

    setData = (values) => {
        if (values) {
            this.setState({
                stageDetails: values,
                showRegistrationScreen: false
            });
        }else{
            this.setState({
                showRegistrationScreen: false
                });
        }
    }

    onClickRegister = () =>{
 
        if(profile()?.discordProfile?.discordUsername){
            return (
                <Modal.Body>
                    <AcceptInvite
                        clearInvitations={this.resetRegistration}
                        tournamentId={this.state.tournamentDetails?._id}
                        addParticipantInTournament={this.addParticipantInTournament}
                        joinTeamRole={null}
                        close={this.resetRegistration}
                        type="add"
                        info={{}}
                        title='Register'
                        role='player'
                        matchId={this.state.selectedMatchDetail?._id}
                        isTournamentInvitation={true}
                    />
                </Modal.Body>
            );
        }else{
            return (
                <Modal.Body>
                    <ConfirmationModal confirm={linkWithDiscord()} onClickOpenConfirm={this.resetRegistration} confirmed={this.confirmed} />
                </Modal.Body>
            );
        }
    }

    confirmed =()=>{
        this.resetRegistration();
        this.props.history.push('/profile');
    }

    hideAndShowScoreBoard = (matchDetails?, matchNo) =>{
        if(matchDetails?.resultAdded){
            this.setState({
                showScoreBoard: !this.state.showScoreBoard,
                matchDetails: !this.state.showScoreBoard === true ? matchDetails : null,
                matchNo
            });
        }
    }

    render() {
        const isAlreadyRegistered = this.state.tournamentDetails?.participantUserId?.includes(this.authUser?.id);
        
        if(this.state.stageDetails){
            if(this.state.showRegistrationScreen){
                return (
                    <Registration 
                        tournamentDetails={this.state.tournamentDetails} 
                        stageDetails={this.state.stageDetails}  
                        selectedMatchDetail={this.state.selectedMatchDetail}
                        matchIndex={this.state.matchIndex}
                        selectedGroupIndex= {this.state.groupIndex}
                        selectedTeams={this.state.selectedTeams}
                    />
                )
            }else{
                if(this.state.showScoreBoard){
                    return (
                        <Scoreboard
                            matchDetails={this.state.matchDetails}
                            hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                            tournamentDetails={this.state.tournamentDetails}
                            matchNo={this.state.matchNo}
                        />
                    )
                }else{
                    return (
                        <div id='tournament-date'>
                            {/* Groups Filter ( Winners or Looser ) */}
                            <div className='overflow-auto ms-2' >
                                <div className='' style={{width:'max-content'}}>
                                {this.state.stageDetails?.groups?.map((group, index) => {
                                    return (
                                        <button style={{padding:'15px 45px',boxShadow: '0.35px 3.35px 0.625px rgb(255 255 255 / 0%), 2.65px 2.65px 6.625px rgb(21 0 0 / 68%), inset -2.65px -2.65px 5.3px rgb(17 13 64 / 50%), inset 2.65px 2.65px 6.625px rgb(255 255 255 / 80%)', opacity: this.state.groupIndex === (index) ? '1' : '0.5'}} key={index} onClick={() => this.setState({groupIndex: index})} className='black-round-btn fs-16 fw-600 border border-dark text-warning me-4 rounded-10 mb-1' type='submit'>
                                            {this.state.stageDetails.groupsName[index]}
                                        </button> 
                                    )
                                })}
                                </div>
                            </div>

                            {/* Brackets Structure */}
                            <div className='w-100 d-flex overflow-auto'>
                                <div className='' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, overflow:'none', width:'fit-content'}}>
                                    {
                                        Object.keys(this.state.stageDetails.groups[this.state.groupIndex]).map((key, i) => {
                                            return (
                                                <div className="py-1 px-3" key={i}>
                                                    <button type="button" className={`rounded-1 text-warning score-showdown-btn2 me-2 pt-1 rounds mb-4 mt-2`}>ROUND {key}</button>
                                                    <div className='d-flex overflow-auto'>
                                                        {
                                                            this.state.stageDetails.groups[this.state.groupIndex][key].map((match, index) => {
                                                                
                                                            if(typeof match === 'object'){
                                                                    var dateAndTime = getMonthAndDate(match.matchSchedule);
                                                                    var player_1 = match.participants[0] ? match.participants[0] : null;
                                                                    var player_2 = match.participants[1] ? match.participants[1] : null;
                                                                    var isTeamPlayer = (this.state.tournamentDetails?.participantType === 'TEAM') ? true : false;
                                                                    const canParticipate = showHideMatchInvite(match, this.state.tournamentDetails);
                                                                    const matchNo = `M ${key}.${index+1}`;
                                                                    //console.log('canParticipate ---', canParticipate);
                                                                    //console.log(`Round ${key} match ${key}.${index + 1}::`, match);
                                                                    return (
                                                                        <div className="fs-14 fw-600 h-auto rounded-10 d-flex flex-column align-items-start  justify-content-start outline-0 border-0 text-white mb-3 mt-1 green-flat-btn flex-shrink-0 me-2 " style={{ width:'320px' }} key={index} onClick={()=>this.hideAndShowScoreBoard(match, matchNo)}>
                                                                            <span className="match-tag rounded-pill bg-black text-warning">Match {key}.{index + 1}</span>
                                                                            <div className="d-flex justify-content-between align-items-center w-100 px-2 py-2">
                                                                                <div className="d-flex flex-column">
                                                                                    {player_1 ? 
                                                                                        <span className="fw-bold fs-12 my-1 text-black d-flex align-items-center">
                                                                                            <img src={!isTeamPlayer ? player_1.profilePicture.url : player_1.logo.url} className='rounded-circle me-2' width={'17px'} height={'17px'} alt='' /> {isTeamPlayer ? player_1.name : player_1.inGameName}{match.resultAdded && match.finalResult[0].isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt='' /> : null}
                                                                                        </span> 
                                                                                        : 
                                                                                        <span className="fw-bold fs-12 my-1 text-white">Vacant</span>
                                                                                    }
                                                                                    {player_2 ? 
                                                                                        <span className="fw-bold fs-12 my-1 text-black d-flex align-items-center">
                                                                                            <img src={!isTeamPlayer ? player_2.profilePicture.url : player_2.logo.url} className='rounded-circle me-2' width={'17px'} height={'17px'} alt=''/> { isTeamPlayer ? player_2.name : player_2.inGameName}{match.resultAdded && match.finalResult[1].isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt='' /> : null}
                                                                                        </span> 
                                                                                        : 
                                                                                        <span className="fw-bold fs-12 my-1 text-white">Vacant</span>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                                { ((i === 0) && (match.participants?.length !== 2) && !isAlreadyRegistered && canParticipate) ?
                                                                                    <button onClick={(e) => this.registerForTournament(e,match,`1.${(index + 1)}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2"  >
                                                                                    <HiUserAdd className="btn-size-15"/>
                                                                                    </button>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                <div className="d-flex flex-column">
                                                                                    <span className="fw-bold fs-12 mt-1 text-black">{dateAndTime[0]}</span>
                                                                                    <span className="fw-bold fs-12 mb-1 text-black">{dateAndTime[1]}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            
                            </div>

                            {/* Tournament Invitation Section */}
                            <Modal centered show={this.state.initiateRegistration} dialogClassName="ClanInvite-modal">
                            {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                                this.onClickRegister()
                                :
                                <Modal.Body>
                                    <MatchInvite
                                        onClickOpenInvite={this.onClickOpenInvite}
                                        teamsArray={this.state.teamsArray}
                                        choice="Join Tournament"
                                        isRoundRobin={false}
                                    />
                                </Modal.Body>
                            }
                            </Modal>

                        </div>
                    );
                }
            }
        }else{
            return (
                <div>
                    <h1>No Data Found</h1>
                </div>
            );
        }

    }
}