import React, { Component } from 'react';
import spotlightActive from '../../../../images/den/interactions/spotlight-active.png';
import { deleteCommentOnPostOrReply, getDenProfilePicture } from '../../../../utils/Den.utils';
import Image from '../../../common/Image';
import { profile } from '../../../../utils/localstorage.utils';
import { LOGOS, DEN as DEN_ASSETS, POST_INTERACTIONS } from '../../../../utils/Images.utils';

import CommentActions from '../../../popup/den/CommentActions';
import denStar from '../../../../images/den/den-star-1.png';
import io from "socket.io-client";
import { toast } from 'material-react-toastify';
import SpotLight from '../../../popup/den/SpotLight';
import { ASSETS, DEN } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';
import { getDenDetailsByType } from '../../../../utils/common.utils';

var socket;

export default class LiveChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
           denProfile: props.denProfile,
           post: props.postDetails, 
           feedDetails: props.feedDetails, 
           postId: props.postId,
           message: '',
           messages: [],
           denProfileUsers : {},
           openSpotlight: false,
           commonSpotlightDonations: [],
           spotLightRange: null
        }

        socket = io(process.env.REACT_APP_SOCKET_URL);
    }


    componentDidMount() {
        this.getMessages();
    }

    componentWillReceiveProps(newProps){
        this.setState({
            denProfile: newProps.denProfile,
            post: newProps.postDetails, 
            feedDetails: newProps.feedDetails, 
            postId: newProps.postId
        })
    }

    getMessages = () => {
        socket.emit('get-data', { matchId: this.state.postId }, (data) => {
        })
        
        socket.on(`data-sent-${this.state.postId}`, (e) => {
            
            if(this.state.messages?.length !== e?.length){
                var commonSpotlightDonations = [];
                {e.map((message)=> {
                    if(message.spotlightDetails[0] && !commonSpotlightDonations.includes({star: message.spotlightDetails[0]?.star, amount: message.spotlightDetails[0]?.amount})){
                       commonSpotlightDonations.push(message.spotlightDetails[0]?.amount)
          
                    }    
                })}
                try{
                    let unique = [...new Set(commonSpotlightDonations)];
                    let spotLightRange = getDenDetailsByType('SPOTLIGHT');
                    this.updateState("spotLightRange : ",spotLightRange)
                    this.setState({ messages: e,commonSpotlightDonations: unique, spotLightRange: spotLightRange });
                }catch(err){
                    this.setState({ messages: e});
                }

            }

            if(this.messagesEnd){
                this.messagesEnd?.scrollIntoView({ behavior: "smooth" });
            }

        })
        socket.on(`get-data-error-${this.state.postId}`, (e) => {
        })
    }
    
    sendMessage = (e) => {
        console.log("before this.state.message :: ",this.state.message)
        this.setState({ message: '', openSpotlight: false });
        if(e){
            e.preventDefault();
        }

        const messageBody = {
            from: profile()?.id,
            matchId: this.state.postId,
            tournamentId: this.state.denProfile._id,
            message: this.state.message,
            messageType: 'TEXT'
        }

        console.log("After this.state.message :: ",this.state.message)

        socket.emit('chat-message', messageBody)
        socket.on(`data-saved-${this.state.postId}`, (e) => {

            this.getMessages()
        })
        socket.on(`chat-message-error-${this.state.postId}`, (e) => {
            this.setState({ message: '' })
        })
    }

    updateState = (key, val) =>{
        this.setState({
            [key]: val
        })
    }

    onClickSpotlight = () =>{
        if(this.state.denProfile?._id === this.state.post?.from[0]._id){
            toast.warning("You can not send spotlight to your own post")
        }else{
            this.setState({ openSpotlight: true });
        }
    }

    sendMessageAfterSptlight = (result) =>{
        var feedDetails = this.state.feedDetails;
        feedDetails[0].spotlight = result.postDetails?.spotlight;
       
        this.setState({ message: '', openSpotlight: false, feedDetails: feedDetails });

        const messageBody = {
            from: profile()?.id,
            matchId: this.state.postId,
            tournamentId: this.state.denProfile._id,
            spotlightId: result._id,
            message: result.message,
            messageType: 'TEXT'
        }

        socket.emit('chat-message', messageBody)
        socket.on(`data-saved-${this.state.postId}`, (e) => {

            this.getMessages()
        })
        socket.on(`chat-message-error-${this.state.postId}`, (e) => {
            this.setState({ message: '' })
        })
    }


   

    render() {
        const {denProfile, message, messages, commonSpotlightDonations, spotLightRange} = this.state;
        const profilePicture = getDenProfilePicture(denProfile);

        
        return(
            <div className='den-post-comments'>
                <hr className='my-0' style={{border: '1px solid #6D53DB'}} />
                <div className="p-2">
                    {/* <ReactionsNavInCommments postId={this.props.postId}/> */}

                    <div className='user-comment-container p-1 my-0' style={{borderRadius: '0px'}}>
                        <Image src={profilePicture?.url} param={this.state.denProfile?.name? this.state.denProfile?.name : "Test"} alt='' className='rounded-circle' style={{ height:'28px', width:'28px' }} />
                        <input type='text' className='fs-14 text-white flex-grow-1 mx-2' 
                            id="postCommentDescription"
                            autoComplete='off'
                            value={this.state.message}
                            onChange={(e) => this.updateState('message', e.target.value)}
                            style={{ background: 'transparent', border: 'none', outline: 'none' }}
                            placeholder={`Chat publicly as ${this.state.denProfile?.name}`}
                        />
                        {/* <img src={hammerInline} alt='' className='rounded-circle' style={{ height:'18px', width:'18px' }} onClick={()=> this.replyOnPostOrComment(this.props.postId, this.state.postCommentDescription, true)} /> */}
                        {/* <p className='fs-12 fw-bold opacity-75 my-0 mx-1'>{denProfile?.hammer} Left</p> */}
                        <button type="button" disabled={!this.state.message?.trim().length} className ='send-spotlight-btn column-center-align-div cursor-pointer me-1' onClick={(e)=>this.sendMessage(e)} style={{border:'none', outline:'none', opacity: this.state.message?.trim().length ? 1 :0.5}}>
                            {/* <span>1k</span> */}
                            <img src={POST_INTERACTIONS.ACTIONS.SEND} alt='' className='rounded-circle' style={{ height:'24px', width:'24px' }} />
                        </button>
                        <div className ='send-spotlight-btn column-center-align-div cursor-pointer'  onClick={()=> this.onClickSpotlight()}>
                            <img src={spotlightActive} alt='' className='rounded-circle' style={{ height:'24px', width:'24px' }} />
                        </div>
                    </div>
                    {/* LIVE CHAT */}
                    <div className ='live-chat-container d-flex flex-column'>
                        <div className ='live-chat-donations d-flex align-items-center p-2'>
                            <p className='fs-16 fw-700 text-white my-0'>Live Chat</p>
                            <div className ='d-flex flex-grow-1 overflow-auto px-2'>
                                {spotLightRange && commonSpotlightDonations.map((amount,i)=>{
                                    var index = spotLightRange.SET_SPOTLIGHT_VALUE.indexOf(amount);
                                    //console.log("INDEX, amount :: ",index,amount);
                                    if(index >=0){
                                        return(
                                            <div className ='livechat-spotlight-btn mx-1' key={i} style={{backgroundColor: STYLE.DEN.SPOTLIGHTS.STARS[index+1].COLOR}}>
                                                <img src={denStar} alt='' className='' style={{height:'16px', width:'16px',}} />
                                                <p className='fs-14 fw-600 my-0 ms-1'>{amount}</p>
                                            </div>
                                        )
                                    }else{
                                        return null;
                                    }
                                })}

                            </div>
                        </div>
                        <div className ='d-flex flex-column flex-grow-1 overflow-auto p-2'>
                            {messages.map((message, i)=> {
                                var profilePicture = getDenProfilePicture(message.denProfileDetails[0]);
                               // message.denProfileDetails[0].
                              
                             
                               const style ={ 
                                font: message.spotlightDetails[0]?.star ? STYLE.DEN.SPOTLIGHTS.STARS[message.spotlightDetails[0]?.star].NAME : {},
                                logo: message.spotlightDetails[0]?.star ? DEN_ASSETS.SPOTLIGHT.LOGO[message.spotlightDetails[0].star] : ""
                               }
                                
                              // {message.spotlightDetails[0]?.star && console.log("message.spotlightDetails[0]?.star :: ",message.spotlightDetails[0]?.star)}
                               
                               if(message.spotlightId){
                                return(
                                    <div className ='d-flex px-2 mb-2' key={i}>
                                        <Image src={profilePicture?.url} param={message.denProfileDetails[0]?.name? message.denProfileDetails[0]?.name : 'Test' }  alt='' className='rounded-circle' style={{height:'24px', width:'24px'}} />
                                        <div style={style.font}>
                                            {message.denProfileDetails[0]?.name? message.denProfileDetails[0]?.name : 'Test' }  <img src={style.logo} alt='' style={STYLE.FONT.HEIGHT.H16}/>
                                        </div>
                                        <p className='fs-12 fw-400 my-0 text-white flex-grow-1'>{message.message}</p>
                                    </div>
                                )
                               }
                               else{
                                return(
                                    <div className ='d-flex px-2 mb-2' key={i}>
                                        <Image src={profilePicture?.url} param={message.denProfileDetails[0]?.name? message.denProfileDetails[0]?.name : 'Test' }  alt='' className='rounded-circle' style={{height:'24px', width:'24px'}} />
                                        <p className='fs-12 fw-600 my-0 text-white-50 mx-2 flex-shrink-0'>{message.denProfileDetails[0]?.name? message.denProfileDetails[0]?.name : 'Test' }</p>
                                        <p className='fs-12 fw-400 my-0 text-white flex-grow-1'>{message.message}</p>
                                    </div>
                                )
                               }

                            })}
                            <div className='row' style={{ height: '20px' }} ref={(el) => { this.messagesEnd = el; }}></div>
                        </div>
                    </div>
                </div>

               

            <div className='comment-actions-modal'>
                {/* <CommentActions deleteComment={()=>deletePostCommentById(this.state.selectedComment?._id, this.afterDelete)} show={this.state.enableCommentActions} commentActionsFor={this.state.commentActionsFor}  onClose={()=>this.setState({enableCommentActions : false})} /> */}
                <CommentActions 
                    pinUnpinCommentOnPost={this.pinUnpinCommentOnPost} 
                    showPinPost={this.state.selectedActionCommentIndex === null ? true : false}  
                    enableEditComment={this.enableEditComment} 
                    deleteComment={()=>deleteCommentOnPostOrReply(this.state.selectedComment?._id,{postId: this.props.postDetails._id, commentId: this.state.replyOnComment}, this.afterDelete)} 
                    show={this.state.enableCommentActions} 
                    commentActionsFor={this.state.commentActionsFor}  
                    onClose={()=>this.setState({enableCommentActions : false})} 
                />

            </div>

            {this.state.openSpotlight && <SpotLight
                enableComment={true}
                isLiveStreaming={true} 
                type={DEN.SPOTLIGHT.TYPE.POST} 
                message={message}
                parentId={this.state.post?._id} 
                denProfile={this.state.denProfile} 
                toUser={this.state.post?.from[0]?._id} 
                openSpotlight={this.state.openSpotlight} 
                updateState={this.updateState} 
                close={this.sendMessageAfterSptlight}
            />}
     
            </div>
        )
    }
}