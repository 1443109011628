import { Component } from "react";
import validator from "validator";
//icons
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiOutlineMinusCircle } from "react-icons/ai";
//utils
import { capitalize } from '../../utils/common.utils'
//css
import '../../styles/Organization.css'

class AddMoreDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            socialFieldArr: this.props.data,
        };
    }

    updateDetails = (e, i) => {
        let { socialFieldArr } = this.state;
        socialFieldArr[i].value = e.target.value
        this.setState({ socialFieldArr });
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    handleClose = () => {
        this.props.renderAddedDetails(this.state.socialFieldArr)
    }

    validateDetails = () => {
        let errors = 0
        const filledFields = this.state.socialFieldArr
        filledFields.map((field) => {
             if (field.value !== '') {
                if (!field.value.includes((field.key).toLowerCase())){
                    field.error = 'Enter url of respective field';
                    errors += 1
                }
                else if ((validator.isURL(field.value)) && (field.value.includes(field.key))) {
                    field.error = ''
                }
                else {
                    errors += 1
                    field.error = 'Enter a valid url'
                }
            }
        })
        console.log('errors::' , errors)
        this.setState({ socialFieldArr: filledFields })
        if (errors > 0) {
            return false
        } else {
            return true
        }
    }

    passDetails = () => {
        if (this.validateDetails()){
            this.props.renderAddedDetails(this.state.socialFieldArr)
        }
    }

    showFields = (i, value) => {
        const newArray = this.state.socialFieldArr
        let temp = this.state.socialFieldArr[i]
        temp.isChecked = value
        newArray[i] = temp
        this.setState({ socialFieldArr: newArray })
    }
    render() {
        return (
            <>
                <form className="p-3">
                    <p className="fs-16 fw-600 text-center text-white mb-0">Add More Contact Details</p>
                    <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '20px', top: '15px' }} onClick={this.handleClose} />
                    <p className="fs-14 text-center fw-500 text-white mb-0 mt-3">
                        Select the contact details you would like to add.
                    </p>
                    <div className="row">
                        {/* facebook */}
                        {
                            this.state.socialFieldArr.map((el, i) => {
                                return (

                                    (el.isChecked) ?

                                        <div className="mb-2" key={i}>
                                            <label htmlFor="facebook" className="fs-14 fw-600 text-white">{capitalize(el.label)}</label>
                                            <div className="">
                                                <input
                                                    type="text"
                                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                    autoComplete="off"
                                                    onChange={e => this.updateDetails(e, i)}
                                                    value={el.value}
                                                    style={{ paddingRight:'35px' }}
                                                />
                                                <AiOutlineMinusCircle className="text-white me-3 position-absolute" style={{right:'10px', marginTop:'-30'}} onClick={() => { this.showFields(i, false)}} />
                                            </div>
                                            <p className="error-msg">{el.error}</p>
                                        </div> :
                                        <div className="col-12 d-flex flex-row mb-2" key={i}>
                                            <div className="black-flat-btn d-flex flex-row align-items-center mx-auto height-45 rounded-10 mt-2 w-100" onClick={() => { this.showFields(i, true) }}>
                                                <BsFillPlusCircleFill className="btn-size-20 ms-3 text-white" />
                                                <p className="fs-14 fw-500 text-white ms-3 my-auto">{capitalize(el.label)}</p>
                                            </div>
                                        </div>
                                )
                            })
                        }
                    </div>
                    <button className="white-flat-btn d-block fw-bold py-1 px-4 m-auto mt-3 mb-2" type="button" onClick={this.passDetails}>Add</button>
                </form>

            </>
        )
    }
}

export default AddMoreDetails