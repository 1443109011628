import { Component } from 'react';
import "../../styles/points.css";
import { getAdminData } from '../../utils/common.utils';

export default class TermsAndConditions extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
    }

    this.adminData = getAdminData();

  }

  render() {

    const rules = this.adminData?.termsAndConditions?.filter(val => val.type == 'SIGN_UP_PAGE');
    
    return(
      <section className="body-section">
               
          <div className="p-3 d-flex flex-column mx-auto notifications" >
            <div className="row text-center">
                <div className="col-12">
                  <h1 className="fs-20 fw-bold text-white mb-2 mt-3">Terms and Conditions</h1>
                </div>
               
            </div>
            <div className='row '>
              <ol className='text-white'>
                {rules[0]?.rule?.map((rule,i)=>{
                  return <li key={i}>{rule.description}</li>
                })}
              </ol>
            </div>
          </div>

      </section>
    );
  }
}