import { Component } from "react";
// Utils
import { AxiosResponse, AxiosError } from "axios";
import { errorHandler, updateLoader } from "../../utils/common.utils";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// Style
import "../../styles/Login.css";
// Assets
import { AiOutlineMail } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { VscLock } from "react-icons/vsc";
import { FiEye, FiEyeOff } from "react-icons/fi";
import LoginPcImage from '../../images/login_pc_image.png';
import facebookIcon from '../../images/icon/Facebook.png';
import discordIcon from '../../images/icon/discord.png';
import googleIcon from '../../images/icon/google.png';
import steamIcon from '../../images/icon/steam.png';
import twitchIcon from '../../images/icon/twitch.png';
// Services
import AuthApis from "../../helper/auth.api";

// Initial state value declaration
const initialLoginState = {
  showPassword: false,
  joinClanId: null,
  joinTeamId: null,
  joinOrgId: null,
  joinClanOrTeamRole: null,
  joinTournamentId: null,
  stageId: null,
  matchId: null,
  gameId: null,
  groupIndex: null
}

export default class Login extends Component {

  constructor() {
    super();
    this.state = { ...initialLoginState };

    this.api = new AuthApis();
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search);

    this.setState({
      joinClanId: queryParams.get('joinClanId'),
      joinTeamId: queryParams.get('joinTeamId'),
      joinOrgId: queryParams.get('joinOrgId'),
      joinClanOrTeamRole: queryParams.get('role'),
      joinTournamentId: queryParams.get('joinTournamentId'),
      stageId: queryParams.get('stageId'),
      matchId: queryParams.get('matchId'),
      gameId: queryParams.get('gameId'),
      groupIndex: queryParams.get('groupIndex'),
      
    });
  }

  // Go to forgot password page
  openForgotPassword = (props) => {
    this.setState({ ...initialLoginState });
    this.props.resetAndUpdateFlag(true,'openForgetPassword',true);
  }

  // Discard Login process
  handleClose = (props) => {
    this.setState({ ...initialLoginState });
    this.props.resetAndUpdateFlag(false,'openLogin',false);
  }

  // Go to sign up page
  handleOpenSignUp = () => {
    this.setState({ ...initialLoginState });
    this.props.resetAndUpdateFlag(true,'openSignUp',true);
  }

  // Initiate signing in process
  signIn = (value) => {
    value.password = value.password.trim()
    updateLoader(true);
    this.api.signIn(value)
      .then((response: AxiosResponse) => {
        this.handleClose();
        updateLoader(false);
        this.props.saveToLocalStorage(response.data.result, false);
      }).catch((reason: AxiosError) => {
        updateLoader(false);
        errorHandler(reason);
      });
  }

  // To show hidden password field
  handleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  // Initiate social media login process
  loginViaSocialMediaAccount = (type) => {
    if (this.state.joinClanOrTeamRole || this.state.joinTournamentId) {
      this.initiateInvitation(type)
    } else {
      window.open(process.env.REACT_APP_API_URL + "/public/api/v1/auth/" + type + "?type=LOGIN", "_self");
    }
  }

  // Initiate the joining new team,org or clan's invitation
  initiateInvitation = (type) => {

    var link;
    if (this.state.joinClanId) {
      link = "/public/api/v1/auth/" + type + "?type=LOGIN&joinClanId=" + this.state.joinClanId + "&role=" + this.state.joinClanOrTeamRole;
    } else if (this.state.joinTeamId) {
      link = "/public/api/v1/auth/" + type + "?type=LOGIN&joinTeamId=" + this.state.joinTeamId + "&role=" + this.state.joinClanOrTeamRole;
    } else if (this.state.joinOrgId) {
      link = "/public/api/v1/auth/" + type + "?type=LOGIN&joinOrgId=" + this.state.joinOrgId + "&role=" + this.state.joinClanOrTeamRole;
    }
    else {
      var additionalParam = `matchId=${this.state.matchId}&groupIndex=${this.state.groupIndex}`;
      link = `/public/api/v1/auth/${type}?type=LOGIN&joinTournamentId=${this.state.joinTournamentId}&stageId=${this.state.stageId}&${additionalParam}&gameId=${this.state.gameId}`;
    }

    window.open(process.env.REACT_APP_API_URL + link, "_self");
  }

  render() {
    const { showPassword } = this.state;
    const type = showPassword ? 'text' : 'password';

    return (
      <div className="login-modal-page">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex flex-column pt-4 px-3">
              <div>
                <h1 className="text-heading fs-18 fw-bold text-white text-center">Enter the Battlefield</h1>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '10px', top: '25px' }} onClick={this.handleClose} />
              </div>
              <p className="text-description fs-14 fw-nornal text-white text-center px-4">
                Experience all the features to the fullest by logging in
              </p>
              {/* Manual login section */}
              <Formik
                enableReinitialize
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object({
                  email: Yup.string().required('Enter your email').email('Invalid Email'),
                  password: Yup.string().required('Password required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    this.signIn(values)
                    setSubmitting(false);
                  }, 400);
                }}>
                <Form className="d-flex flex-column p-0 m-0" autoComplete="off">
                  {/* Email */}
                  <div className="my-2">
                    <label htmlFor='email' className="fs-16 fw-600 text-white mb-1">Email Address</label>
                    <div className="">
                      <AiOutlineMail className="field-icon position-absolute" style={{ marginTop: '12px' }} />
                      <Field
                        name='email' type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white height-45"
                        placeholder="Email" id="email"
                      />
                    </div>
                    <ErrorMessage component="span" name="email" className="error-msg" />
                  </div>
                  {/* Pasword */}
                  <div className="my-2">
                    <label htmlFor="password" className="fs-16 fw-600 text-white">Password</label>
                    <div className="purple-field  d-flex align-items-center">
                      <VscLock className="field-icon position-absolute" />
                      <Field
                        type={type}
                        name='password'
                        className="d-flex align-items-center w-100 border-0 ps-5 text-white height-45"
                        placeholder="Password"
                        id="password"
                        style={{backgroundColor:'transparent', outline: 'none' }}
                      />
                      {showPassword && (
                        <FiEye className="field-icon field-eye-icon me-2" onClick={this.handleShowPassword} />
                      )}
                      {!showPassword && (
                        <FiEyeOff className="field-icon field-eye-icon me-2" onClick={this.handleShowPassword} />
                      )}
                    </div>
                    <ErrorMessage component="span" name="password" className="error-msg" />
                    <p className="fs-14 fw-500 text-white float-end text-decoration-underline mt-1" onClick={this.openForgotPassword}>Forgot Password</p>
                  </div>

                  <button type='submit' className='white-flat-btn w-25 fw-bold py-2 m-auto my-3'>
                    Sign In
                  </button>
                </Form>
              </Formik>
              <div className="d-flex flex-row align-items-center mx-auto my-2" style={{ width: '40%' }}>
                <p className="modal-hr-line my-auto"></p>
                <p className="fs-16 fw-600 text-white mx-2 my-0">OR</p>
                <p className="modal-hr-line my-auto"></p>
              </div>
              {/* Social media login section */}
              <div className="share-icon-container d-flex justify-content-center mt-2 mb-2">
                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={facebookIcon} alt="facebook icon" width={'20px'} className="" onClick={() => { this.loginViaSocialMediaAccount("facebook") }} />
                </div>
                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={googleIcon} alt="google icon" width={'20px'} className="" onClick={() => { this.loginViaSocialMediaAccount("google") }} />
                </div>
                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={discordIcon} alt="discord icon" width={'15px'} className="" onClick={() => { this.loginViaSocialMediaAccount("discord") }} />
                </div>
                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={twitchIcon} alt="twitch icon" width={'20px'} className="" onClick={() => { this.loginViaSocialMediaAccount("twitch") }} />
                </div>
                {/* <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={steamIcon} alt="steam icon" width={'20px'} className="" onClick={() => { this.loginViaSocialMediaAccount("steam") }} />
                </div> */}
              </div>
            </div>
            {/* Go to sign up section */}
            <p className="mobile-text d-lg-none d-block mt-3 mb-0 fs-16  fw-normal text-white">
              Don't have an account? <span className="fs-16 fw-bold" onClick={this.handleOpenSignUp}>Sign Up</span>
            </p>
          </div>
          {/* Go to sign up section */}
          <div className="col-lg-6 d-lg-block d-none">
            <img src={LoginPcImage} alt="login pc" className="login-desktop-image" />
            <div className="right-login-text-container">
              <p className="fs-16 text-white mb-0">Don't have an account?</p>
              <p className="fs-16 fw-bold text-white mt-1 mb-0 cursor-pointer" onClick={this.handleOpenSignUp}>Sign Up</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}