export const STYLE = {
    DEN : {
        SEARCH : {
            ACTIVE : {fontSize: '16px', fontWeight: 400, color: '#AD26FF', borderBottom: '1px solid #AD26FF'}, 
            INACTIVE : {fontSize: '16px', fontWeight: 400, color: 'white'}
        },
        POST: {
            LINE : {border: '1px solid #6D53DB'},
            INTERACTIONS : {height:'16px', width: '16px', cursor: 'pointer'},
            SUB_POST: {border: '2px solid #352a5fe3', borderRadius:'20px', overflow: 'hidden'},
            FULL_WIDTH: {width: '-webkit-fill-available'}
        },
        SPOTLIGHT: {
            STAR: ["#FFF500", "#FF00A8", "#61C100", "#00A9C0", "#FF4D00" ],
            COMMENT: {
                "1": {borderBottom: '2px solid #FFF500'},
                "2": {borderBottom: '2px solid #FF00A8'},
                "3": {borderBottom: '2px solid #61C100'},
                "4": {borderBottom: '2px solid #00A9C0'},
                "5": {borderBottom: '2px solid #FF4D00'}
            }
        },
        SPOTLIGHTS: {
            STARS : {
                "1": {
                    COMMENT: {borderBottom: '2px solid #FFF500'},
                    COLOR: "#FFF500",
                    NAME: {color: "#FFF500", marginLeft: '2px', fontSize: "12px", fontWeight: 600, paddingLeft: '6px', paddingRight: '6px'}
                },
                "2": {
                    COMMENT: {borderBottom: '2px solid #FF00A8'},
                    COLOR: "#FF00A8",
                    NAME: {color: "#FF00A8", marginLeft: '2px', fontSize: "12px", fontWeight: 600, paddingLeft: '6px', paddingRight: '6px'}
                },
                "3": {
                    COMMENT: {borderBottom: '2px solid #61C100'},
                    COLOR: "#61C100",
                    NAME: {color: "#61C100", marginLeft: '2px', fontSize: "12px", fontWeight: 600, paddingLeft: '6px', paddingRight: '6px'}
                },
                "4": {
                    COMMENT: {borderBottom: '2px solid #00A9C0'},
                    COLOR: "#00A9C0",
                    NAME: {color: "#00A9C0", marginLeft: '2px', fontSize: "12px", fontWeight: 600, paddingLeft: '6px', paddingRight: '6px'}
                },
                "5": {
                    COMMENT: {borderBottom: '2px solid #FF4D00'},
                    COLOR: "#FF4D00",
                    NAME: {color: "#FF4D00", marginLeft: '2px', fontSize: "12px", fontWeight: 600, paddingLeft: '6px', paddingRight: '6px'}
                },
            }
        },
        PROFILE_CARD  : {background: '#231D36',  borderRadius: '8px' },
        EMPTY_LIST  : {marginTop: '200px',  marginBottom: '200px' },
        POST_STYLE : { height: '20px', width: 'auto' },
        REACTED_USERS : { height:'16px', width:'16px', marginLeft:'-8px'},
        REACTED_USERS_ICON : { height:'32px', width:'32px', cursor:'pointer', border: '1px solid #AD26FF', color: '#AD26FF'}
    },
    FONT: {
        WEIGHT : {
            W300 : {fontWeight: '300'}
        },
        HEIGHT : {
            H18 : { height: '18px'},
            H16 : { height: '16px'}
        },
        SPACE: {whiteSpace: 'pre-line'},
    },
    AREA: {
        WIDTH: {
            W24: {width:'24px'}
        },
        RADIUS: {
            R8 : {borderRadius: '8px'}
        }
    }
}