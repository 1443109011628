import { Component } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import logo from '../../images/email.png';
import expired from '../../images/clip-arts/expired.png'
import { AxiosResponse, AxiosError } from "axios";
import { toast } from 'material-react-toastify';
import "../../styles/OurPolicies.css";
import AuthApis from "../../helper/auth.api";
import { profile } from '../../utils/localstorage.utils'
import { errorHandler } from '../../utils/common.utils'

class OurPolicies extends Component{
    
    constructor(props) {
      super(props);
      this.state = {
        termsAndConditionsCheckbox: false
      };
    }

    componentDidMount(){
    }
    

    api = new AuthApis();
   
    resendEmailVerificationLink = () => {
      this.props.updateLoader(true);
      this.api.resendEmailVerificationLink()
      .then((response: AxiosResponse) => {
          this.props.updateLoader(false);
          toast.success(response.data.result);
      }).catch((reason: AxiosError) => {
          this.props.updateLoader(false);
          errorHandler(reason);
      });
    }
    
    handleVerifyActivateAccount = () => {
      window.open("https://mail.google.com/mail/", "_blank");
    }

    handleClose = () =>{
      this.props.openPolicies(false);
    }

    render() {
      const authUser = profile();
      var difference = Math.round(((new Date(authUser.createdAt) - new Date())/1000)/60);
      const isVerificationLinkExpired = (Math.abs(difference) > 10);
      
      return(
          <section className="our-policies">
            <div className="terms-conditions-container">
                <AiOutlineCloseCircle className="close-icon" onClick={this.handleClose} />
                <h1 className="fs-24 text-white text-center">Email Verification<br/> Required</h1>
                <p className="dialog-hr-line"></p>
                <img alt="img" src={isVerificationLinkExpired ? expired : logo} className="dialog-logo my-3 eml-vrf mt-4" />
                
                <div className="text-center">
                  {authUser.email ?
                    <div>
                      {isVerificationLinkExpired ?
                        <div>
                          <p className="fs-18 text-white my-3">
                            The verification link that we sent on <br/>
                            <span className="dialog-span">{authUser.email}</span> <br/>
                            has been expired.
                          </p>
                          <p className="fs-18 text-white my-3">
                          Click on the Send again to receive another email from us. 
                          </p>
                        </div>
                        :
                        <div>
                          <p className="fs-18 text-white my-3">
                            We've sent an email to<br/>
                            <span className="dialog-span">{authUser.email}</span>
                          </p>
                          <p className="fs-18 text-white my-3">
                            to verify your email address and activate your account. 
                          </p>
                        </div>
                      }
                     
                    </div>
                      :
                    <div>
                      <p className="fs-18 text-white my-3">
                        Go to menu/my profile/email and <br/>
                        enter a email address for notification.
                        <span className="dialog-span">{authUser.email}</span>
                      </p>
                      <p className="fs-18 text-white my-3">
                        Then go to your mail and verify your email to activate your account. 
                      </p>
                    </div>
                  }

                  {isVerificationLinkExpired ?
                    null
                  :
                    <p className="fs-18 text-white my-3"> <b>Note :</b> The link in the email will expire in 10 minutes. </p>
                  }
                  <button className="button" onClick={this.handleVerifyActivateAccount} type="submit">Check Your Mail Box </button>
                  
                  <p className="footer-text fs-14 mt-1 text-center"> Did not receive the verification link?<br /> <span className="fw-bold cursor-pointer" onClick={this.resendEmailVerificationLink}>Send Again</span> </p>

                </div>

            </div>
          </section>
      )
    }
}

export default OurPolicies