import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import fileAttach from '../../images/icon/fileAttach-ic.png';
import CommonApis from '../../helper/common.api';
import { updateLoader } from '../../utils/common.utils';
import { toast } from 'material-react-toastify';


class ReportBug extends Component {
    constructor(props) {
        super(props)
        this.state={
            email: props.authUser ? props.authUser?.user?.email :'',
            bug: '',
            description: '',
            images: []
        }
        this.commonApi = new CommonApis();
    }
    
    componentDidMount(){
        this.props.showOrHideNav(true);
    }

    resetState = () => { 
        this.setState({
            email: this.props.authUser ? this.props.authUser?.user?.email :'',
            bug: '',
            description: '',
            images: []
        })
    }

    updateState = (key, value) => {
        //console.log(`Key: ${key} || value: ${value}`)
        this.setState({
            [key] : value
        })
    }

    triggerInputFile = () => {
        if(this.state.images.length === 5){
            toast.warning('Maximum 5 images are allowed');
            return false;
        }
        this.fileInput.click()
    };

    uploadImages = (event) => {
        if (event.target.files?.length && event.target.files?.length < 6 && this.state.images?.length < 6) {
            let img = event.target.files[0];
            if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
                toast.warning('Please select a valid image type.');
                return false;
            }
            const formData = new FormData();
            for (let i = 0; i < event.target.files?.length; i++) {
                formData.append(i, event.target.files[i])
            }
            updateLoader(true)
            this.commonApi.uploadMultipleImg(formData)
                .then((response: AxiosResponse) => {
                    let images = this.state.images
                    for (let i = 0; i < response.data.result?.length; i++) {
                        if (images?.length < 6) {
                            images.push(response.data.result[i])
                        }
                    }
                    this.setState({
                        images: images
                    });
                    updateLoader(false)
                })
                .catch((err: AxiosError) => {
                    console.log(err)
                    updateLoader(false)
                });
        }
    }

    removeImg = (i) => {
        let images = this.state.images
        images.splice(i, 1)
        this.setState({ images: images })
    }

    formSubmit = (e) => { 
        e.preventDefault();
        const {email, bug, description, images} = this.state;
        if(email.trim() === ''){
            toast.warning('email required');
            return false;
        }
        if(bug.trim() === ''){
            toast.warning('Bug name required');
            return false;
        }
        if(description.trim() === ''){
            toast.warning('Description name required');
            return false;
        }
        const data = {
            email: email.trim(),
            bug: bug.trim(),
            description: description.trim(),
            images
        }
        updateLoader(true);
        this.commonApi.createBugReport(data)
            .then((response: AxiosResponse) => {
                console.log('response ::',response)
                this.resetState()
                toast.success(response.data.message);
                updateLoader(false)
            })
            .catch((err: AxiosError) => {
                console.log(err)
                updateLoader(false)
            });
    }

 
    render() {
        return(
            <section className='body-section'>
                <div className='container d-flex flex-column align-items-center'>
                    <h2 className='text-center'>Report a Bug</h2>
                    <div className='row w-100 p-3'>
                        <div className='col-12'>
                            <Form onSubmit={(e) => this.formSubmit(e)}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" 
                                        value={this.state.email} required 
                                        onChange={(e) => this.updateState('email', e.target.value)}
                                        disabled={this.props.authUser ? true : false}
                                    />
                                    <Form.Text className="text-white">
                                    We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Bug Name*</Form.Label>
                                    <Form.Control type="text" placeholder="Enter bug name" 
                                        value={this.state.bug} required maxLength={30}
                                        onChange={(e) => this.updateState('bug', e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Bug Description*</Form.Label>
                                    <Form.Control as="textarea" placeholder="Enter bug description" 
                                        value={this.state.description} required rows={3} maxLength={150}
                                        onChange={(e) => this.updateState('description', e.target.value)} 
                                    />
                                </Form.Group>

                                <div className="form-group my-2">
                                    <span className='d-flex align-items-center'>
                                        <label htmlFor="disputeImages" className='text-white fs-16 fw-600'>Upload Images</label>
                                        <p className='ms-2 text-white-50 fw-bold mb-0 fs-12'>(max 5)</p>
                                    </span>
                                    <div className='d-flex overflow-wrap mt-2'>
                                        {
                                            this.state.images?.map((el, i) => {
                                                return (
                                                    <span key={i}>
                                                        <AiFillCloseCircle className="btn-size-15 position-absolute text-white" style={{ marginTop: '-8px' }} onClick={() => { this.removeImg(i) }} />
                                                        <img src={el?.url} alt='' className='mx-2' height="50px" width="50px" />
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="dispute-upload-section d-flex align-items-center justify-content-center my-2">
                                        <input
                                            name="disputeImages"
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            id="upload_cover_file"
                                            hidden
                                            multiple
                                            ref={fileInput => this.fileInput = fileInput}
                                            onChange={this.uploadImages}
                                        />
                                        <div className="" onClick={this.triggerInputFile}>
                                            <img src={fileAttach} alt='' className='btn-size-20' />
                                        </div>
                                    </div>
                                </div>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default ReportBug