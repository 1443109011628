import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class OrganizationApis {
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    getOrganizations = (params ? ) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/organization/getOrganizations?${searchString}`);
        }
        return this.init().get("/private/api/v1/organization/getOrganizations");
    };

    getPublicOrganizations = (params ? ) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/public/api/v1/organization/getOrganizations?${searchString}`);
        }
        return this.init().get("/public/api/v1/organization/getOrganizations");
    };

    addNewOrganization = (data) => {
        return this.init().post("/private/api/v1/Organization/createOrganization", data);
    };
    updateOrganization = (id, data) => {
        return this.init().put(`/private/api/v1/Organization/updateOrganization/${id}`, data);
    }
    deleteOrganization = (id) => {
        return this.init().delete(`/private/api/v1/organization/deleteOrganization/${id}`);
    };

    joinOrganizationOrTeamUsingInvitationLink = (data, type) => {
        return this.init().post(`/private/api/v1/${type === "Organization" ? 'Organization/addToOrganizationUsingLink' : 'team/addToTeamUsingLink' } `, data);
    };

    getMicroWebUrl = (data) => {
        return this.init().post("/private/api/v1/organization/getMicroWebsiteLInk", data);
    }

}