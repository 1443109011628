import { actionTypes } from "../constants/actionTypes"

export const setTournamentDetails = (details) => {
    return {
        type: actionTypes.SET_TOURNAMENTDETAILS,
        payload: details
    }
}

export const setUpdateScore = (details) => {
    return {
        type: actionTypes.SET_UPDATE_SCORE,
        payload: details
    }
}