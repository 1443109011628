import { Component } from 'react';
//images
import participantAvtar from "../../../../images/participantAvtar.jpg";
import winnerCrown from "../../../../images/icon/winner-crown.png";
import orgIc from "../../../../images/icon/org-ic.png";
import { ellipsis, scoreCardGameURL } from '../../../../utils/common.utils';
import SponsorCards from './SponsorCards';
import CardHeader from './CardHeader';

export default class DuelForfeitCase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            game: props.game,
            tournamentDetails: props.tournamentDetails
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            game: nextProps.game,
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getURL = (el) => {
        if (this.state.tournamentDetails.participantType === 'SINGLE') {
            return el.participantDetails.profilePicture.url
        } else {
            return el.teamDetails.logo.url
        }
    }

    getForfeitCard = () => { 
        const { game, tournamentDetails} = this.state;
        const participantType = tournamentDetails?.participantType;
        return(
            <div className='col-12 col-lg-6 offset-lg-3'>
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />
                    
                    <div className='d-flex justify-content-around'>
                        
                        <div className='d-flex flex-column align-items-center'>
                            {/* <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} /> */}
                            <img src={game[0] ? this.getURL(game[0]) : participantAvtar} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                            <h5 className='text-white fw-bold fs-10 mt-2 text-center my-1'>{participantType === 'SINGLE' ? game[0].participantDetails?.inGameName : game[1].teamDetails?.name}</h5>
                            {!game[0].forfeit ? <p className='text-warning fw-bold fs-10 mt-2 text-center my-0'>Winner</p> : <p className='text-danger fw-bold fs-10 mt-2 text-center my-0'>Forfeit</p>}
                        </div>
                        <div className='d-flex flex-column align-items-center'>
                            {/* <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} /> */}
                            <img src={game[1] ? this.getURL(game[1]) : participantAvtar} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                            <h5 className='text-white fw-bold fs-10 mt-2 text-center my-1'>{participantType === 'SINGLE' ? game[1].participantDetails?.inGameName : game[1].teamDetails?.name}</h5>
                            {!game[1].forfeit ? <p className='text-warning fw-bold fs-10 mt-2 text-center my-0'>Winner</p> : <p className='text-danger fw-bold fs-10 mt-2 text-center my-0'>Forfeit</p>}
                        </div>
                        
                    </div>

                    <div className='forfeit-bg'>
                        <span className='my-1'>#ABANDONED</span>
                        <span className='my-1'>The player has not appeared or left the match in-between</span>
                        <span className='my-1' style={{color:'#FF4646'}}>Hence, disqualified from this match</span>
                    </div>

                    <p className='fs-10 fw-bold text-white-50 text-center my-1'>For more info, visit the rule section of this tournament</p>
                    
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="p-0">
                {this.getForfeitCard()}
            </div>
        )
    }
}