import { Component } from 'react';
import '../../styles/betting.css';
import allGamesIc from '../../images/games/allGames-ic.png';
import allGamesGrayIc from '../../images/games/allGamesGray-ic.png';
import lolIc from '../../images/games/lol-ic.png';
import lolGrayIc from '../../images/games/lolGray-ic.png';
import valorantIc from '../../images/games/valorant-ic.png';
import valorantGrayIc from '../../images/games/valorantGray-ic.png';
import dota2Ic from '../../images/games/dota2-ic.png';
import dota2GrayIc from '../../images/games/dota2Gray-ic.png';
import fortniteIc from '../../images/games/fortnite-ic.png';
import fortniteGrayIc from '../../images/games/fortniteGray-ic.png';
import pubgIc from '../../images/games/pubg-ic.png';
import pubgGrayIc from '../../images/games/pubgGray-ic.png';
import tournamentLogo from '../../images/image75.png';
import betIc from '../../images/icon/bet-ic.png';
import betWhiteIc from '../../images/icon/betWhite-ic.png';
import betAcceptBtn from '../../images/icon/bet-accept-btn.png';


import betPeopleIc from '../../images/icon/betPeople-ic.png';
import winCup from '../../images/icon/winCup.png';
import betCreatedIc from '../../images/icon/betCreated-ic.png';
import espotzLogo from '../../images/eSpotz_logo.png';
import { defaultPreviewImage } from '../../utils/common.utils';

import Loader from '../common/Loader';
import discord from '../../images/games/discord.png';
import instagram from '../../images/games/instagram.png';
import { NavLink } from 'react-router-dom';
import betChallenge from '../../images/icon/betChallenge.png';
import { AiFillPlusCircle } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BsCalendar } from 'react-icons/bs';

export default class Challenges extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tournamentView: 101,
      createChallengeModal: false,
    };
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  render() {
    return (
      <div className='body-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-3 mb-2'>
              <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#8C2FB8' }}>“ Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.”</marquee>
            </div>
            <div className='col-6'>
              <div className='create_challenge-card p-3' onClick={() => this.setState({ createChallengeModal: true })}>
                <div className='text-center d-flex align-items-center justify-content-center'>
                  <AiFillPlusCircle className='fs-24 text-white' />
                  <p className='fs-16 fw-700 text-white ms-2 mb-0'>Create</p>
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='my_challenge-card p-3'>
                <div className='text-center'>
                  <p className='fs-16 fw-700 text-white mb-0'>My Challenges</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='betting_navbar'>
          <div className='d-flex justify-content-around mt-4'>
            <h4 className='fs-10 fs-lg-14 cursor-pointer my-2' onClick={() => { this.setState({ tournamentView: 101 }) }}>
              <img alt='' src={`${this.state.tournamentView === 101 ? allGamesIc : allGamesGrayIc}`} width='40' />
            </h4>
            <h4 className='fs-10 fs-lg-14 cursor-pointer my-2' onClick={() => { this.setState({ tournamentView: 102 }) }}>
              <img alt='' src={`${this.state.tournamentView === 102 ? lolIc : lolGrayIc}`} width='40' />
            </h4>
            <h4 className='fs-10 fs-lg-14 cursor-pointer my-2' onClick={() => { this.setState({ tournamentView: 103 }) }}>
              <img alt='' src={`${this.state.tournamentView === 103 ? valorantIc : valorantGrayIc}`} width='40' />
            </h4>
            <h4 className='fs-10 fs-lg-14 cursor-pointer my-2' onClick={() => { this.setState({ tournamentView: 104 }) }}>
              <img alt='' src={`${this.state.tournamentView === 104 ? dota2Ic : dota2GrayIc}`} width='40' />
            </h4>
            <h4 className='fs-10 fs-lg-14 cursor-pointer my-2' onClick={() => { this.setState({ tournamentView: 105 }) }}>
              <img alt='' src={`${this.state.tournamentView === 105 ? fortniteIc : fortniteGrayIc}`} width='40' />
            </h4>
            <h4 className='fs-10 fs-lg-14 cursor-pointer my-2' onClick={() => { this.setState({ tournamentView: 106 }) }}>
              <img alt='' src={`${this.state.tournamentView === 106 ? pubgIc : pubgGrayIc}`} width='40' />
            </h4>
          </div>
        </div>

        <div className='container'>
          {(this.state.tournamentView === 101) &&
            <div className='row mt-3'>
              <div className='col-7'>
                <div className='d-flex align-items-center ms-2'>
                  <img alt='' src={betCreatedIc} className='btn-size-20' />
                  <p className='fs-16 fw-700 text-white ms-2 mb-0'>Challenge Now</p>
                </div>
              </div>
              <div className='col-5'>
                <div className='d-flex align-items-center justify-content-evenly'>
                  <p className='fs-14 fw-700 text-white-50'>EC</p>
                  <p className='fs-14 fw-700 text-white-50'>Time</p>
                </div>
              </div>

              <div className='col-12'>
                <div className='betting-card mx-2 mb-4 px-4 pt-4'>
                  <div className='row'>
                    <div className='col-2 text-center'>
                      <img alt='' src={tournamentLogo} width='40' height='40' />
                    </div>
                    <div className='col-8'>
                      <h1 className='fs-16 fw-700 text-white'>Rohan The Viking</h1>
                      <p className='fs-14 fw-600 text-white-50'>Challenge me on your gameand let’s decide who’s the real boss</p>
                    </div>
                    <div className='col-2 text-center'>
                      <img alt='' src={tournamentLogo} width='40' height='40' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4 d-flex align-items-center text-center'>
                      <img alt='' src={betWhiteIc} width='15' height='15' />
                      <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>500 ec</p>
                    </div>
                    <div className='col-4'>
                      <button className='bg-transparent border-0'>
                        <img alt='' src={betAcceptBtn} className='bet_accept-btn' />
                      </button>
                    </div>
                    <div className='col-4 text-center'>
                      <p className='fs-12 fw-700 text-white-50 mb-0'>23’ Mar 21</p>
                      <p className='fs-12 fw-700 text-white-50 mb-1'>@7:15 PM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='betting-card mx-2 mb-4 px-4 pt-4'>
                  <div className='row'>
                    <div className='col-2 text-center'>
                      <img alt='' src={tournamentLogo} width='40' height='40' />
                    </div>
                    <div className='col-8'>
                      <h1 className='fs-16 fw-700 text-white'>Rohan The Viking</h1>
                      <p className='fs-14 fw-600 text-white-50'>Challenge me on your gameand let’s decide who’s the real boss</p>
                    </div>
                    <div className='col-2 text-center'>
                      <img alt='' src={tournamentLogo} width='40' height='40' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4 d-flex align-items-center text-center'>
                      <img alt='' src={betWhiteIc} width='15' height='15' />
                      <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>500 ec</p>
                    </div>
                    <div className='col-4'>
                      <button className='bg-transparent border-0'>
                        <img alt='' src={betAcceptBtn} className='bet_accept-btn' />
                      </button>
                    </div>
                    <div className='col-4 text-center'>
                      <p className='fs-12 fw-700 text-white-50 mb-0'>23’ Mar 21</p>
                      <p className='fs-12 fw-700 text-white-50 mb-1'>@7:15 PM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {(this.state.tournamentView === 102) &&
            <div>
            </div>
          }

          {(this.state.tournamentView === 103) &&
            <div className='col-12 mt-3'>
            </div>
          }
          {(this.state.tournamentView === 104) &&
            <div className='row mt-3'>
            </div>
          }

          {(this.state.tournamentView === 105) &&
            <div>
            </div>
          }

          {(this.state.tournamentView === 106) &&
            <div className='col-12 mt-3'>
            </div>
          }

        </div>

        <Modal show={this.state.createChallengeModal} centered dialogClassName="create-challenge-modal">
          <Modal.Body>
            <div className='d-flex flex-column p-3'>
              <h1 className='fs-20 fw-600 text-white text-center'>Create Challenge</h1>
              <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => this.setState({ createChallengeModal: false })} />
              <Formik
                enableReinitialize
                initialValues={{
                  selectGame: '',
                  challengeMessage: '',
                  challengeAmount: '',
                  challengeDateTime: ''
                }}

                validationSchema={Yup.object({
                  selectGame: Yup.string().required("Please Select Game"),
                  challengeAmount: Yup.string().required("Enter Challenge Amount"),
                  challengeDateTime: Yup.string().required("Enter Challenge date and time"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                }}
              >
                <Form className="mt-3" autoComplete='off'>
                  <div className="">
                    <label htmlFor="selectGame" className="fs-14 fw-600 text-white">Game *</label>
                    <Field name="selectGame" as="select" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                      <option value=''>PUBG</option>
                      <option value=''>Valorant</option>
                      <option value=''>Fortnite</option>
                      <option value=''>League of Legends</option>
                    </Field>
                    <ErrorMessage component="span" className="error-msg" name="selectGame" />
                  </div>
                  <div className="mt-2">
                    <div className=''>
                      <div className=''>
                        <label htmlFor="challengeMessage" className="fs-14 fw-600 text-white">Challenge Message</label>
                      </div>
                      <Field name="challengeMessage" type="text" placeholder="Write Chalelnge message" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 height-45" />
                    </div>
                    <ErrorMessage component="span" className="error-msg" name="challengeMessage" />
                  </div>

                  <div className="mt-2">
                    <div className=''>
                      <div className=''>
                        <label htmlFor="challengeAmount" className="fs-14 fw-600 text-white">Challenge Amount *</label>
                      </div>
                      <Field name="challengeAmount" type="text" placeholder="Add EC" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 height-45" />
                    </div>
                    <ErrorMessage component="span" className="error-msg" name="challengeAmount" />
                  </div>

                  <div className="mt-2">
                    <label htmlFor="challengeDateTime" className="fs-14 fw-600 text-white mb-1">Tournament End Date & Time</label>
                    <div className='d-flex flex-column'>
                      <Field
                        name="challengeDateTime"
                        type="datetime-local"
                        className="purple-field d-flex align-items-center w-100 border-0 pe-3 fs-14 height-45"
                        placeholder="Tournament Start Date & Time"
                        style={{ color: 'transparent', opacity: 0, zIndex: 1 }}
                      />
                      <p className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 fw-500 height-45 w-100" style={{ marginTop: '-45px', marginBottom: '0px' }}>
                        <BsCalendar className='text-white position-relative ms-auto me-4' style={{ marginTop: '-5px' }} />
                      </p>
                    </div>
                    <ErrorMessage component="span" className="error-msg" name="challengeDateTime" />
                  </div>

                  <button type="submit" className="white-flat-btn d-flex fw-bold py-1 px-4 m-auto mt-3" >
                    Create
                  </button>
                </Form>
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
