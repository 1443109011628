import { Component } from 'react';

// Images
import stagesIc from '../../../images/icon/stages.png';
import swords from '../../../images/page/swords.png';


export default class RankingTable extends Component {
    render() {
        return (
            <section className='tournament-ranking-table text-white'>
                <div className='container'>
                    <div className="row">

                        <div className="col-12 mt-2">
                            <span className='d-flex'>
                                <img src={stagesIc} className='btn-size-15' alt=''/><h1 className='fs-16 fw-700 ms-2'>One for all</h1>
                            </span>

                            <div className='' style={{ overflowX: 'auto' }}>
                                <table className="table grey-card" 
                                // style={{ width: 'auto' }}
                                >
                                    <thead>
                                        <tr className='text-warning fs-16 fw-500'>
                                            <th scope="col" className='text-center'>Rank</th>
                                            <th scope="col">Name</th>
                                            <th scope="col" className='text-center'>Score</th>
                                            <th scope="col" className='text-center'>Game 1</th>
                                            <th scope="col" className='text-center'>Game 2</th>
                                            <th scope="col" className='text-center'> Game 3</th>
                                            <th scope="col" className='text-center'>Game 4</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-borderless text-white '>
                                        <tr>
                                            <th scope="row" className='text-center'>1st</th>
                                            <td >
                                                <span className='rank-shadow rank-1-shadow'></span>
                                                Mark</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='text-center'>2nd</th>
                                            <td >
                                                <span className='rank-shadow rank-2-shadow'></span>
                                                Rancho</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='text-center'>3rd</th>
                                            <td >
                                                    <span className='rank-shadow rank-3-shadow'></span>
                                                Pica</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='text-center'>4th</th>
                                            <td >Rolo</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='text-center'>5th</th>
                                            <td >venki</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='text-center'>6th</th>
                                            <td >Mark</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                            <td className='text-center'>000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center' type='submit'>
                            <img src={swords} alt='submit'height={'20px'} />
                        </button>

                    </div>
                </div>
            </section>
        )
    }
}
