import React, { Component } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import { STYLE } from '../../utils/Style.utils';
import SideSkeleton from './SideSkeleton';

export default class SkeletonProfilePage extends Component {
    state = {};

    render() {
        return(
            <div className='body-section den_page'>
                <div className='container'>
                    <Card className='profile-card-bg p-2' style={STYLE.DEN.PROFILE_CARD}>
                        <Card.Body>
                        {/* <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={6} />
                        </Placeholder> */}
                        <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={4} /> {' '}<Placeholder xs={4} /> <Placeholder.Button variant="primary" xs={2} />
                        </Placeholder>
                        </Card.Body>
                    </Card>
                    <div className="row">
                        <div className='col-12 col-lg-4 my-1'>
                            <SideSkeleton />;
                        </div>
                        <div className='col-12 col-lg-8 my-1'>
                            <SideSkeleton />;
                            <SideSkeleton />;
                            <SideSkeleton />;
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}