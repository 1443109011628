import React, { Component } from 'react';
import winnerCrown from "../../../../images/icon/winner-crown.png";
import denStar1 from '../../../../images/den/den-star-1.png';
import denStar2 from '../../../../images/den/den-star-2.png';
import { getDenPostsLeaderBoard } from '../../../../utils/Den.utils';
import Image from '../../../common/Image';
import { DEN } from '../../../../utils/Strings.utils';


export default class RightSideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            denLeaderBoardDetails: []
        }
    }

    componentDidMount(){
        this.getDenPostsLeaderBoard();
    }

    getDenPostsLeaderBoard = () => { 
        getDenPostsLeaderBoard(this.setDenPostsLeaderBoard)
    }

    setDenPostsLeaderBoard = (result) => {
        this.setState({
            denLeaderBoardDetails: result
        });
    }


     
    render() {
        const {denLeaderBoardDetails} = this.state;

        return (
            <div className='col-3 d-none d-lg-block'>
                <div className='d-flex flex-column'>
                    <div className='den-leaderboard-top p-2'>
                        <p className='fs-24 fw-bold text-white'>Den's Leaderboard</p>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='d-flex justify-content-center'>
                                    <p className='fw-bold me-2' style={{fontSize: '40px'}}>1</p>
                                    <div className='d-flex flex-column align-items-center'>
                                        <img src={winnerCrown} className="" alt='' style={{ height: '20px' }} />
                                        <Image src={denLeaderBoardDetails[0]?.type === DEN.SEARCH.TYPES.USER ? denLeaderBoardDetails[0]?.profilePicture?.url : denLeaderBoardDetails[0]?.logo?.url} alt='' param={denLeaderBoardDetails[0]?.name} className='rounded-circle mt-2' style={{height:'80px', width: '80px'}} />
                                        {denLeaderBoardDetails[0]?.name?.length > 20 ? <marquee className='marquee-text fs-20 fw-500 my-1 text-white' direction="left">{denLeaderBoardDetails[0]?.name}</marquee> :<p className='fs-20 fw-500 my-1 text-white'>{denLeaderBoardDetails[0]?.name}</p>}
                                        <p className='fs-20 fw-500 my-1 text-white'><img src={denStar1} alt='' style={{ height:'50px'}}/> {denLeaderBoardDetails[0]?.denStar}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    <p className='fw-bold me-2' style={{fontSize: '25px'}}>2</p>
                                    <div className='d-flex flex-column align-items-center'>
                                        <Image src={denLeaderBoardDetails[1]?.type === DEN.SEARCH.TYPES.USER ? denLeaderBoardDetails[1]?.profilePicture?.url : denLeaderBoardDetails[1]?.logo?.url} alt='' param={denLeaderBoardDetails[1]?.name} className='rounded-circle mt-2' style={{height:'50px', width: '50px'}} /> 
                                        {denLeaderBoardDetails[1]?.name.length > 15 ? <marquee className='marquee-text fs-16 fw-500 my-1 text-white' direction="left">{denLeaderBoardDetails[1]?.name}</marquee> : <p className='fs-16 fw-500 my-1 text-white'>{denLeaderBoardDetails[1]?.name}</p>}
                                        <p className='fs-16 fw-500 my-1 text-white'><img src={denStar1} alt='' style={{ height:'25px'}} /> {denLeaderBoardDetails[1]?.denStar}</p>   
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    <p className='fw-bold me-2' style={{fontSize: '25px'}}>3</p>
                                    <div className='d-flex flex-column align-items-center'>
                                        <Image src={denLeaderBoardDetails[2]?.type === DEN.SEARCH.TYPES.USER ? denLeaderBoardDetails[2]?.profilePicture?.url : denLeaderBoardDetails[2]?.logo?.url} param={denLeaderBoardDetails[2]?.name} className='rounded-circle mt-2' style={{height:'50px', width: '50px'}} /> 
                                        {denLeaderBoardDetails[2]?.name.length > 15 ? <marquee className='marquee-text fs-16 fw-500 my-1 text-white' direction="left">{denLeaderBoardDetails[2]?.name}</marquee> : <p className='fs-16 fw-500 my-1 text-white'>{denLeaderBoardDetails[2]?.name}</p>}
                                        <p className='fs-16 fw-500 my-1 text-white'><img src={denStar1} alt='' style={{ height:'25px'}} /> {denLeaderBoardDetails[2]?.denStar}</p>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='den-leaderboard-bottom'>
                        {denLeaderBoardDetails.map((profile, i) => {
                            const profilePicture = profile.type === DEN.SEARCH.TYPES.USER ? profile.profilePicture : profile.logo;
                            if(i > 2){
                                return (
                                    <div className='container d-flex align-items-center py-3' key={i} style={{borderTop: '1px solid rgba(173, 38, 255, 0.5)'}}>
                                        <p className='den-leaderboard-bottom-text my-1'>{i+1}</p>
                                        <Image src={profilePicture?.url} param={profile?.name} alt='' className='rounded-circle mx-2' style={{height:'32px', width: '32px'}} /> 
                                        {profile.name.length > 15 ? <marquee className='marquee-text den-leaderboard-bottom-text my-1 flex-grow-1' direction="left">{profile?.name}</marquee> : <p className='den-leaderboard-bottom-text my-1 flex-grow-1'>{profile?.name}</p>}
                                        <p className='den-leaderboard-score my-1'><img src={denStar2} alt='' style={{ height:'25px'}} /> {profile.denStar}</p> 
                                    </div>
                                )
                            }
                        })}
                        
                    </div>
                </div>
            </div>
        )
    }
}
