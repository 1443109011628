import React, { Component } from 'react';
//styles, images
import '../../../../styles/TournamentPage.css';
import { Carousel } from 'react-bootstrap';

export default class SponsorCards extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: this.props.tournamentDetails,
            tier1: this.props.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Powered By') || (sponsor.type === 'Sponsored By')),
            tier2: this.props.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Co-Powered By') || (sponsor.type === 'Co-sponsored By')),
            tier3: this.props.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Associate Sponsor') || (sponsor.type === 'Streaming Partner'))
        }
    }

    componentDidMount() { }

    componentWillReceiveProps(newProps) {
        this.setState({
            tournamentDetails: newProps.tournamentDetails,
            tier1: newProps.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Powered By') || (sponsor.type === 'Sponsored By')),
            tier2: newProps.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Co-Powered By') || (sponsor.type === 'Co-sponsored By')),
            tier3: newProps.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Associate Sponsor') || (sponsor.type === 'Streaming Partner'))
        });
    }
    getTierSponsors = (sponsors) => {
        return(
            <div className='col-4'>
                <Carousel>
                    {sponsors.map((sponsor, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <div className='d-flex flex-column'>
                                    <h1 className='sponsor-type text-warning text-center my-2 text-truncate'>{sponsor.type}</h1>
                                    <img src={sponsor.logo.url} alt='' className='sponsor-image' />
                                    <h1 className='sponsor-name fw-600 text-white text-center my-1'>{sponsor.name}</h1>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </div>
        )
    }

    render() {
        return (
            <section className='scorecard-sponsors p-0'>
                <div className='row d-flex justify-content-center'>
                    {this.state.tier1?.length ? this.getTierSponsors(this.state.tier1) : null}
                    {this.state.tier2?.length ? this.getTierSponsors(this.state.tier2) : null}
                    {this.state.tier3?.length ? this.getTierSponsors(this.state.tier3) : null}
                </div>
            </section>
        )
    }
}
