import React, { Component } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { profile } from '../../../../utils/localstorage.utils';
import profileLogo from '../../../../images/den/den-profile-logo.png';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';
import followers from '../../../../images/den/create-post/followers.png';
import { ASSETS, DEN } from '../../../../utils/Strings.utils';
import { capitalize, getDetailsObj } from '../../../../utils/common.utils';
import { followDenProfile, getDenProfilePicture, getDenProfiles, unFollowDenProfile, updateDenProfileInLocalStorage } from '../../../../utils/Den.utils';
import Image from '../../../common/Image';
import { NavLink } from 'react-router-dom';
import { STYLE } from '../../../../utils/Style.utils';
import { DEN_EMPTY_SCREENS } from '../../../../utils/Images.utils';


export default class FollowersAndFollowings extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "",
            followType: this.props.match?.params?.followType ? this.props.match.params.followType : DEN.FOLLOW_TYPE.FOLLOWERS,
            denProfile: null,
            myDenProfile: null,
            usersDenProfiles: [],
            detailsObj: null
        };
    }

    componentDidMount(){
        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId,this.props.match.params.currentUserDenId]},this.setMyDenProfile)
        }
    }

    setMyDenProfile = (result) =>{
        if(result[0]){
            const myDenProfile = result.filter((user)=> user._id === this.props.match.params.denId)[0];
            const denProfile = result.filter((user)=> user._id === this.props.match.params.currentUserDenId)[0];

        
            this.setState({
                myDenProfile: myDenProfile,
                denProfile: denProfile
            });
            getDetailsObj(denProfile, this.setDetailsObj)

            try{
                getDenProfiles({ profiles : denProfile[this.state.followType]},this.setDenProfiles);
            }catch(err){
                console.log("Error :: ",err)
            }

        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }


    setDenProfiles = (result) => {
        this.setState({
            usersDenProfiles: result
        });
    }

    getActionButtonForFollowors = (profileId) => {
        if(this.state.myDenProfile?._id === profileId){
            return null
        }
        if(!this.state.myDenProfile.followings.includes(profileId) && !this.state.myDenProfile.requested.includes(profileId)){
            return <button className='den-profile-btn follow-btn' style={{width:'auto'}} onClick={()=>followDenProfile(this.state.myDenProfile?._id, profileId,this.updateDenProfile)} >Follow</button>    
        }else if(this.state.myDenProfile.requested.includes(profileId)){
            return <button className='den-profile-btn request-btn' style={{width:'auto'}}>Requested</button>    
        }else{
            return <button className='den-profile-btn following-btn' style={{width:'auto'}}>Following</button>    
        }
    }

    updateDenProfile = (updatedDenProfile) => {
        //updateDenProfileInLocalStorage(updatedDenProfile)
        getDenProfiles({ profiles : updatedDenProfile[this.state.followType]},this.setDenProfiles)
        this.setState({
            denProfile: updatedDenProfile
        });
    }

    getView = () =>{
        const {followType, usersDenProfiles, denProfile, myDenProfile} = this.state;
        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2'>
                        <div className='row'>
                            <div className='col-4'>
                                <p className='fs-16 fw-600 my-0'><img src={followers} alt='' className='' style={{height:'16px', width: 'auto'}} /> {usersDenProfiles.length}</p>
                            </div>
                            <div className='col-4'>
                                <p className='fs-16 fw-700 text-center my-0'>{capitalize(DEN.FOLLOW_TYPE[followType.toUpperCase()])}</p>
                            </div>
                            <div className='col-4 text-end'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.history.goBack()} />
                            </div>
                        </div>
                        {/* Follow className = 'den-profile-btn follow-btn' */}
                        {/* Following className = 'den-profile-btn following-btn' */}
                        {/* Requested className = 'den-profile-btn request-btn' */}
                        <hr className='hr-line' />
                        {usersDenProfiles?.length > 0 ?
                            <div className='row'>
                                {usersDenProfiles.map((profile, i) => {
                                    var profilePicture = getDenProfilePicture(profile);
                                    return(
                                    <div className='col-12' key={i}>
                                        <div className='d-flex align-items-center'>
                                        <NavLink to={`/den/${this.state.myDenProfile?._id}/profile-page/${profile._id}`} className="cursor-pointer text-white"> 
                                            <Image src={profilePicture?.url} param={profile?.name} className='rounded-circle' style={{height:'40px', width:'40px'}}/>
                                            </NavLink>
                                            
                                            
                                            <NavLink to={`/den/${this.state.myDenProfile?._id}/profile-page/${profile._id}`}  className="cursor-pointer text-white flex-grow-1 mx-2">
                                                <p className='fs-16 fw-600 my-0'>{capitalize(profile?.name)}</p>
                                                <spam className='fs-12 text-white-50 fw-500'>{capitalize(profile?.profileTag)}</spam>
                                            </NavLink>
                                            {followType === DEN.FOLLOW_TYPE.FOLLOWERS ?
                                                this.getActionButtonForFollowors(profile._id)
                                            :
                                                myDenProfile._id  === denProfile._id ?
                                                <button className='den-profile-btn unfollowing-btn' style={{width:'auto'}} onClick={()=>unFollowDenProfile(denProfile?._id, profile?._id,this.updateDenProfile)}> Unfollow</button>    
                                                : 
                                                null
                                            }

                                        </div>
                                        <hr className='hr-line my-1' />
                                    </div>
                                    )
                                })}
                                
                            </div>
                        :
                            <div className='row'>
                                <div className="text-center">
                                    <img className="" src={followType === DEN.FOLLOW_TYPE.FOLLOWERS ? DEN_EMPTY_SCREENS.NO_FOLLOWERS : DEN_EMPTY_SCREENS.NO_FOLLOWING} alt={""} />
                                </div>
                            </div>  
                        }
                    </div>
                </div>
            </div>
        )
    }


    render() {
        return(
          <div className='body-section den_page'>
            <div className='container'>
                <div className='row'>
                    <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                    {this.getView()}
                    <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                </div>
            </div>
          </div>
        )
      }    
}
