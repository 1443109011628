import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LeftSideBar from '../common/LeftSideBar'
import RightSideBar from '../common/RightSideBar'
import { profile } from '../../../../utils/localstorage.utils';
import { DEN } from '../../../../utils/Strings.utils';
import { getDenProfiles } from '../../../../utils/Den.utils';

/**
* @author
* @class DenAchievements
**/

class DenAchievements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: DEN.TABS.ACHIEVEMENTS,
            denProfile: null,
        };
    }

    componentDidMount(){
        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile)

        }
    }

    setCurrentUserDenProfile = (result) =>{
        
        if(result[0]){
            this.setState({
                denProfile: result[0]
            });
        }
    }


 getView = () =>{
  return(
    <div className='col-12 col-lg-6'>
        <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
            <div>DenAchievements</div>
        </div>
    </div>
  )
 }

 render() {
  return(
       <div className='body-section den_page'>
          <div className='container'>
              <div className='row'>
                  <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                  {this.getView()}
                  <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
              </div>
          </div>
      </div>
)
   }
 }


DenAchievements.propTypes = {}
export default DenAchievements