import { actionTypes } from "../constants/actionTypes"

export const setAllGames = (data) => {
    return {
        type: actionTypes.SET_GAMES,
        payload: data
    }
}
export const setPublicTournaments = (data) => {
    return {
        type: actionTypes.PUBLIC_TOURNAMENTS,
        payload: data
    }
}
export const setUpdatedEc = (data) => {
    return {
        type: actionTypes.UPDATE_EC,
        payload: data
    }
}