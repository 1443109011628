import { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//styles and icons
import { BsInfoCircle } from 'react-icons/bs';
import '../../../../styles/Tournaments.css';
import { FaUserCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { COMMISSION_QUESTION_TYPE } from '../../../../utils/enums.utils';

export default class PremiumPaidFixed extends Component {
    constructor(props) {
        super(props)
        this.localObject = props.localObject;
        this.state = {
            kycVerified: true,
            entryFeeAmount:props?.initialValues?.buyEC ? this.props?.initialValues?.entryFeeAmount : ( props.localObject?.entryFeeAmount && props.id == props.localObject?.id) ? props.localObject.entryFeeAmount : '',
            prizePoolAmount: props?.initialValues?.buyEC ? this.props?.initialValues?.prizePoolAmount : ( props.localObject?.prizePoolAmount && props.id == props.localObject?.id) ? props.localObject.prizePoolAmount : '',
            ecToBeDeposited: props?.initialValues?.ecToBeDeposited ? this.props?.initialValues?.ecToBeDeposited : '',
            espotzCommission: props.commissionValues ? props.commissionValues.questions[COMMISSION_QUESTION_TYPE.PREMIUM_FIXED_PC] : 10,
        }
        console.log(' commissionValues ::',  props.commissionValues);
    }
    componentDidMount() {}

    componentWillReceiveProps(newProps){
        this.setState({
            entryFeeAmount: newProps?.initialValues?.entryFeeAmount ? newProps?.initialValues?.entryFeeAmount :  ( newProps.localObject?.entryFeeAmount && newProps.id == newProps.localObject?.id) ? newProps.localObject.entryFeeAmount : '',
            prizePoolAmount: newProps?.initialValues?.prizePoolAmount ? newProps?.initialValues?.prizePoolAmount :  ( newProps.localObject?.prizePoolAmount && newProps.id == newProps.localObject?.id) ? newProps.localObject.prizePoolAmount : '',
            espotzCommission: newProps?.initialValues?.espotzCommission ? newProps?.initialValues?.espotzCommission : 10,
            ecToBeDeposited: newProps?.initialValues?.ecToBeDeposited ? newProps?.initialValues?.ecToBeDeposited : 0
        });
    }

    updateDetails = (e, key) => {
        const {espotzCommission, entryFeeAmount, prizePoolAmount} = this.state;
        var depositAmount;
        var data = parseInt(e.target.value)
        e.preventDefault()
        this.localObject[key] = data;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        if (key === 'entryFeeAmount') {
            depositAmount = (100+ espotzCommission)*prizePoolAmount/100 + (data*this.props.maxParticipants);
        }else{
            depositAmount = (100+ espotzCommission)*data/100 + (entryFeeAmount*this.props.maxParticipants);
        }
        //console.log(`${key} == ${data} and ecToBeDeposited == ${depositAmount}`)
        this.setState({
            [key] : data, 
            ecToBeDeposited: depositAmount
        })
        //this.updateECToBeCollected() 
    }

    validatePricePool = (val) => {
        let error;
        if(this.state.ecToBeDeposited > this.props.balance){
            error = `Your available wallet balance is ${this.props.balance}`
        }
        return error;
    }

    passDetails = (obj) => {
        this.props.onSubmitData({...obj, ecToBeDeposited: this.state.ecToBeDeposited})
    }

    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
          <ReactTooltip id={id} place={place} effect="float" type={type}>
            {text}
          </ReactTooltip>
        )
    }

    render() {
        return (
            <section className="">
                <Formik
                    enableReinitialize
                    initialValues={{
                        entryFeeAmount: this.props?.initialValues?.entryFeeAmount ? this.props?.initialValues?.entryFeeAmount : '',
                        prizePoolAmount: this.props?.initialValues?.prizePoolAmount ? this.props?.initialValues?.prizePoolAmount : '',
                        ECToBeReceived: this.props?.initialValues?.ECToBeReceived ? this.props?.initialValues?.ECToBeReceived : '',
                        espotzCommission: this.state.espotzCommission
                    }}
                    validationSchema={Yup.object({
                        entryFeeAmount: Yup.number().integer().required('Enter Entry Fee Amount').min(0,'Cannot be less than Zero'),
                        prizePoolAmount: Yup.number().integer().required('Enter Prize Pool Amount').min(0,'Cannot be less than Zero'),
                        espotzCommission: Yup.number()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.passDetails(values)
                            setSubmitting(false);
                        }, 400);
                    }}>
                    <Form className="row mt-3" autoComplete="off">
                        {/* Entry Fee Amount */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Entry Fee Amount</label>
                            <div className="">
                                <Field
                                    name='entryFeeAmount' type="number" placeholder="Enter in EC"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 fw-500 height-45"
                                    onKeyUp={(e) => this.updateDetails(e, 'entryFeeAmount')}
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="entryFeeAmount" className="error-msg" />
                        </div>
                        {/* Prize Pool */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Prize Pool</label>
                            <div className="">
                                <Field
                                    name='prizePoolAmount' type="number" placeholder="Enter in EC"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 fw-500 height-45"
                                    onKeyUp={(e)=>this.updateDetails(e, 'prizePoolAmount')}
                                    validate={this.validatePricePool}
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="prizePoolAmount" className="error-msg" />
                        </div>
                        {/* EC Commission */}
                        <div className='col-lg-6'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <label className="fs-14 fw-600 text-white">Espotz Commission(in %)</label>
                                <Field
                                    name='espotzCommission' type='number'
                                    className='purple-field d-flex align-items-center w-25 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    placeholder='in %' disabled
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="espotzCommission" className="error-msg" />
                        </div>
                        {/* EC to be deposited */}
                        <div className='col-lg-6 d-flex justify-content-between align-items-center my-3'>
                            <label className="fs-14 fw-600 text-white">EC To Be Deposited </label>
                            <input
                                className='purple-field d-flex align-items-center w-50 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                disabled
                                value={this.state.ecToBeDeposited}
                                min={0} step={1} onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        
                        <div className='col-lg-6'>
                        <button className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-2'>
                            KYC Verification
                        </button>
                        </div>
                        {/* <button
                            className='tournament-btn category-btn w-100 my-2'
                            type='submit'
                            disabled={this.state.kycVerified ? false : true}
                            style={{ opacity: this.state.kycVerified ? 1 : 0.6 }}>
                            Pay
                        </button> */}
                        <div className='col-lg-6'>
                         <button type='submit' className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-4 mt-lg-2' >
                            Next
                        </button>
                        </div>
                    </Form>
                </Formik>
            </section>
        )
    }
}
