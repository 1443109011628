import { Component } from "react";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <div className="logout-modal position-fixed rounded-15 p-4">
          <p className="text-white fs-14 fw-600 text-center mb-3">Are you sure you want to logout ?</p>
          <div className="d-flex justify-content-center">
            <button className="white-flat-btn fw-bold py-2 px-4 m-auto mb-2 me-2" onClick={this.props.logout}>Yes</button>
            <button className="white-flat-btn fw-bold py-2 px-4 m-auto mb-2 ms-2" onClick={(e)=>this.props.resetAndUpdateFlag(false,'openLogout',false)}>No</button>
          </div>
        </div>
    );
  }
}

export default Logout;
