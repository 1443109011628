import { Component } from 'react'
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import {NotificationImages} from '../../utils/Images.utils'
import '../../styles/Notifications.css';
import { AiOutlineClose } from 'react-icons/ai';
import { errorHandler, remainingTime, updateLoader } from '../../utils/common.utils';
import NotificationsApi from '../../helper/notifications.api';
import { Modal } from 'react-bootstrap';
import ConfirmationModal from '../popup/ConfirmationModal';
import { WARNING } from '../../utils/Strings.utils';
import { receivedAt } from '../../utils/dateTime.utils';
import { updateNotificationCount } from '../../utils/notification.utils';


class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications :  [],
            showMoreNotificationIndex: null,
            clearAllNotifications : false
        };

        this.notificationsApi = new NotificationsApi();
    }

    componentDidMount() {
        //this.props.showOrHideNav(true);
        this.getMyNotification();
    }

    updateNotificationsLog = (notifications) =>{
        this.setState({
            notifications : notifications,
            clearAllNotifications : false
        })
    }

    getMyNotification = () => {
        updateLoader(true);
        this.notificationsApi.getMyNotification()
            .then(
                (res: AxiosResponse) => {
                    this.updateNotificationsLog(res.data.result.notifications)
                    updateLoader(false);
                }
            ).catch(
                (err: AxiosError) => {
                    updateLoader(false);
                    errorHandler(err);
                }
            )
    }

    deleteNotificationLog = (e, notificationId?) => {

        if(e){
            e.preventDefault();
        }

        updateLoader(true);
        this.notificationsApi.deleteNotificationLog(notificationId).then((res: AxiosResponse) => {
            this.updateNotificationsLog(res.data.result.notifications)
            updateLoader(false);
        }).catch((err: AxiosError) => {
            updateLoader(false);
            errorHandler(err);
        })
    }

    showMore = (e, index, details )=>{
        e.preventDefault();
        this.setState({ showMoreNotificationIndex : index });
        this.updateNotificationLogStatus(details);
      
    } 


    updateNotificationLogStatus = (details) =>{
     
        if(!details.isRead){
            const data = { 
                notificationIds :[details.notificationId] 
            }
            this.notificationsApi.updateNotificationLogStatus(data).then((res: AxiosResponse) => {
                this.updateNotificationsLog(res.data.result.notifications);
                updateNotificationCount();
            }).catch((err: AxiosError) => {
                errorHandler(err);
            })
        }
     

    }

    initiateNotificationAction = (details) => {
        this.updateNotificationLogStatus(details);
        if(details.notification.link){
            this.props.history.push(details.notification.link);
            if(details.notification.reload){
                window.location.reload();
            }
        }

    }

    render(){
        console.log('Notification Images ::', NotificationImages['Test']);

         var notifications = [...this.state.notifications].reverse();
         console.log("BEFORE -------",notifications)
         const count = notifications.filter(notification => notification.isRead === false).length; 
        return(
            <section className="body-section">
                {/* <NavLink to="/">
                    <IoArrowBackCircleOutline className="back-btn" />
                </NavLink> */}
                <div className="p-3 d-flex flex-column mx-auto notifications" >
                    <div className="row justify-content-between">
                        <div className="col-4">
                        <h1 className="fs-20 fw-bold text-white mb-2 mt-3">Notifications<span className='fs-12 fw-500 my-0 text-white  opacity-50 ps-2'><em>({count}/{notifications?.length})</em></span></h1>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-danger fs-12 fw-bold text-white mb-2 float-end mt-3" onClick={()=>this.setState({ clearAllNotifications : true})}>
                                <i className="fa fa-trash" /> Clear All
                            </button>
                        </div>
                    </div>
               
                    <div className='row'>
                  
                  
                        {notifications.map((details,i)=>{
                        //    console.log(`${i+1} details :: `,details.notification.createdAt)
                            return (
                                <div className='col-12 my-1 cursor-pointer' key={i} >
                                    <div className={`row pt-3 pb-3  ps-1 row pe-2 ${details.isRead ? '' :'border-start border-3 border-info'}`} style={{backgroundColor: '#231D36'}}>
                                        
                                        <div className='col-2 pe-0' onClick={()=>this.initiateNotificationAction(details)} >
                                            <div className='d-flex flex-column align-items-center h-100'>
                                                <img src={NotificationImages[details.notification.type] ? NotificationImages[details.notification.type] : NotificationImages.DEFAULT} 
                                                    // alt={NotificationImages.DEFAULT_IMAGE} 
                                                    className='notification-icon'
                                                    />
                                                <p className='fs-10 fw-500 text-white-50 mt-2'>{receivedAt(details.notification.createdAt)}</p>
                                            </div>
                                        </div>
                                        
                                        <div className='col-9 px-2'>
                                            <p className='fs-14 fw-500 my-0 text-white  opacity-75'>
                                                <b onClick={()=>this.initiateNotificationAction(details)} >{details.notification.heading} </b>
                                                { (this.state.showMoreNotificationIndex !== null && this.state.showMoreNotificationIndex === i ) ?  
                                                    <em onClick={()=>this.initiateNotificationAction(details)}>{details.notification.message}</em> : 
                                                    <>
                                                        <em onClick={()=>this.initiateNotificationAction(details)} >{details.notification.message.slice(0, ( 65-details.notification.heading.length))}...</em>
                                                        <a className="fs-14 fw-bold" onClick={(e) => this.showMore(e,i,details)}>Show More</a> 
                                                    </>
                                                }
                                            </p>
                                        </div>
                                        
                                        <div className='col-1 pe-0' onClick={(e)=>this.deleteNotificationLog(e,details.notificationId)} >
                                            <AiOutlineClose className='text-secondary'  />
                                            {/* <p className='fs-10 fw-500 text-white-50 mt-2'>7 h</p> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                      
                       
                    </div>
                </div>

                <Modal show={this.state.clearAllNotifications} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={{msg: ( <p className="fs-14 fw-600 text-center text-white-50"> {WARNING.CLEAR_NOTIFICATIONS} </p> ),key: null}}
                            onClickOpenConfirm={() => { this.setState({ clearAllNotifications: false }) }}
                            confirmed={()=>this.deleteNotificationLog(null) } />
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}

export default Notifications