import React, { Component } from 'react';
import betIc from '../../images/icon/bet-ic.png';
import tournamentLogo from '../../images/image75.png';
import betPeopleIc from '../../images/icon/betPeople-ic.png';
import winCup from '../../images/icon/winCup.png';
import { defaultPreviewImage, remainingTime } from '../../utils/common.utils';
import { ASSETS } from '../../utils/Strings.utils';
import { getPlayersLogoGrid } from '../../utils/Betting.utils';
import { getDateMonthYear } from '../../utils/dateTime.utils';
import Image from '../common/Image';

export default class UpcomingMatchesCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: props.tournamentDetails,
            matchesDetails: props.matchesDetails
        }
    }

    componentWillReceiveProps(newProps){
        if(newProps.matchesDetails){
            this.setState({
                matchesDetails: newProps.matchesDetails
            })
        }
    }

    jumpToBet = (match) => {
        if(this.props.redirect){
            const tournamentDetails = match.tournament;
            this.props.history.push(`/tournament/${tournamentDetails?._id}?page=BettingZone`);
        }else{
            return false;
        }
    }


    render() {
        const isTeamGame = (this.state.tournamentDetails.participantType === "TEAM");
        if(this.state.matchesDetails){
            if(this.state.tournamentDetails.competitionType === "DUEL" && isTeamGame){
                return (
                    <div className='col-12 col-md-6'>
                        {
                            this.state.matchesDetails?.map((match,index)=>{
                                //console.log('bet match ::::', match);
                                return (
                                    
                                    <div className='betting-card mx-2 my-3' key={index} onClick={(e) => this.props.redirect ? this.jumpToBet(match) : this.props.switchToBets(match)}>
                                        <div className='pseudo fs-10 fw-600'>
                                            {this.props.showDateMonthYear ? getDateMonthYear(match?.matchSchedule) : remainingTime(match?.matchSchedule)}
                                            <span className="before" style={{ background: 'rgb(28, 28, 28)' }}> </span>
                                            <span className="after" style={{ background: 'rgb(28, 28, 28)' }}> </span>
                                        </div>
                                        
                                        { this.state.tournamentDetails?.competitionType === "DUEL" ?
                                            <div className='row'>
                                                <div className='col-5 text-center'>
                                                    <img src={isTeamGame ? match?.participants[0]?.logo?.url : match?.participants[0]?.profilePicture?.url} width='40' height='40' alt='' className='rounded-circle' />
                                                    <p className='fs-12 fw-700 text-white mb-0'>{isTeamGame ? match?.participants[0]?.name : match?.participants[0]?.inGameName}</p>
                                                </div>
                                                <div className='col-2 text-center align-self-center'>
                                                    <p className='fs-16 fw-700 text-white'>VS</p>
                                                </div>
                                                <div className='col-5 text-center'>
                                                    <img src={isTeamGame ? match?.participants[1]?.logo?.url : match?.participants[1]?.profilePicture?.url} width='40' height='40' alt='' className='rounded-circle'/>
                                                    <p className='fs-12 fw-700 text-white mb-0'>{isTeamGame ? match?.participants[1]?.name : match?.participants[1]?.inGameName}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className='row'>
                                                {
                                                    match?.participants.map((player,i)=>{
                                                        //return <img className='btn-size-32 border border-1' key={i} src={player?.logo?.url} alt="profile-logo" width="50" height="50" />
                                                        return <Image src={player?.logo?.url} param={player.name} className='btn-size-32 border border-1' key={i} width="50" height="50" />
                                                    })  
                                                }
                                            </div>

                                        }
                                        
                                        <div className='d-flex align-items-center justify-content-between px-3 my-1'>
                                            <div className='d-flex'>
                                                <img src={betIc} width='16' height='16' alt='' />
                                                <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>Starting from <span className='lime-green-text'> {match.startingFrom} EC </span></p>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={betPeopleIc} width='16' height='16' alt='' />
                                                <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>{match.noOfBetters}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center px-3 my-1'>
                                            <img src={winCup} width='16' height='16' alt='' />
                                            <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>Cumilative total pool: <span className='light-yellow'> {match.cumulativePool} EC </span></p>
                                        </div>
                                        <div className='betting_card-footer d-flex align-items-center justify-content-between px-3'>
                                            <p className='fs-12 fw-700 light-yellow my-1'>{this.state.tournamentDetails?.name} <span className='fs-8'>By {match?.organization?.name}</span></p>
                                            {/* <p className='fs-12 fw-700 dodger-blue my-1'>{this.state.tournamentDetails?.organizationDetails?.name}</p> */}
                                        </div>
                                    </div>
                                    
                                )
                            })
                        }
                    </div>
                );
            }
            else{
                return (
                    <div className='col-12 col-md-6'>
                        {
                            this.state.matchesDetails?.map((match,index)=>{
                                //console.log('bet match:',match)
                                return (
                                    <div className='betting-card mx-2 my-3' key={index} onClick={(e) => this.props.redirect ? this.jumpToBet(match) : this.props.switchToBets(match)}>
                                        <div className='pseudo fs-10 fw-600'>
                                        {this.props.showDateMonthYear ? getDateMonthYear(match?.matchSchedule) : remainingTime(match?.matchSchedule)}
                                            <span className="before" style={{ background: 'rgb(28, 28, 28)' }}> </span>
                                            <span className="after" style={{ background: 'rgb(28, 28, 28)' }}> </span>
                                        </div>
                                        
                                        { this.state.tournamentDetails?.competitionType === "DUEL" ?
                                                <div className='row'>
                                                <div className='col-5 text-center'>
                                                    <img src={match?.participants[0]?.profilePicture?.url} width='40' height='40' alt='' className='rounded-circle' />
                                                    <p className='fs-12 fw-700 text-white mb-0'>{isTeamGame ? match?.participants[0]?.name : match?.participants[0]?.inGameName}</p>
                                                </div>
                                                <div className='col-2 text-center align-self-center'>
                                                    <p className='fs-16 fw-700 text-white'>VS</p>
                                                </div>
                                                <div className='col-5 text-center'>
                                                    <img src={match?.participants[1]?.profilePicture?.url} width='40' height='40' alt='' className='rounded-circle' />
                                                    <p className='fs-12 fw-700 text-white mb-0'>{isTeamGame ? match?.participants[1]?.name : match?.participants[1]?.inGameName}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className='row'>
                                                {getPlayersLogoGrid(match)}
                                            </div>

                                        }
                                        
                                        <div className='d-flex align-items-center justify-content-between px-3 my-1'>
                                            <div className='d-flex'>
                                                <img src={betIc} width='16' height='16' alt='' />
                                                <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>Starting from <span className='lime-green-text'> {match.startingFrom} EC </span></p>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={betPeopleIc} width='16' height='16' alt='' />
                                                <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>{match.noOfBetters}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center px-3 my-1'>
                                            <img src={winCup} width='16' height='16' alt='' />
                                            <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>Cumilative total pool: <span className='light-yellow'> {match.cumulativePool} EC </span></p>
                                        </div>
                                        <div className='betting_card-footer d-flex align-items-center justify-content-between px-3'>
                                            <p className='fs-12 fw-700 light-yellow my-1'>{this.state.tournamentDetails?.name} <span className='fs-8'>By {match?.organization?.name}</span></p>
                                            {/* <p className='fs-12 fw-700 dodger-blue my-1'>{this.state.tournamentDetails?.organizationDetails?.name}</p> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            }
        }else{
            return (
                <div className="text-center">
                    <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                </div>
            )
        }
    }
}