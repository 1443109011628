import { Component } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import scanIc from '../../images/icon/scan-ic.png';

export default class KycVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openKycVerification: false,
        };
    }

    render() {
        return (
            <div className="kyc-verification-page p-4">
                <div className='p-3 px-md-5 px-lg-5'>
                    <h1 className='fs-20 fw-600 text-white text-center'>KYC Verification</h1>
                    <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => this.setState({ KYCModal: false })} />
                    <p className='fs-14 fw-600 text-white text-center mt-2 mb-4' style={{ textAlign: 'justify' }}>You should complete KYC verification in orrder to publish betting statements</p>
                    <div className=''>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                aadharName: '',
                                aadharNumber: '',
                                panName: '',
                                panNumber: ''
                            }}
                            validationSchema={Yup.object({

                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 400);
                            }}>
                            <Form className="d-flex flex-column p-0 m-0" autoComplete="off">
                                <div className='d-flex'>
                                    <h1 className='fs-16 fw-700 text-info'>Scan aadhar card</h1>
                                    <img src={scanIc} className='btn-size-20 ms-2' alt='scan-ic' />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor='aadharName' className="fs-16 fw-600 text-white">Name of aadhar card</label>
                                    <Field
                                        name='aadharName'
                                        type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                        placeholder="Enter aadhar name"
                                    />
                                    <ErrorMessage component="span" name="aadharName" className="error-msg" />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor='aadharNumber' className="fs-16 fw-600 text-white">Aadhar card number</label>
                                    <Field
                                        name='aadharNumber'
                                        type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                        placeholder="XXXX-XXXX-XXXX"
                                    />
                                    <ErrorMessage component="span" name="aadharNumber" className="error-msg" />
                                </div>
                                <div className="d-flex flex-row align-items-center mx-auto my-2" style={{ width: '40%' }}>
                                    <p className="modal-hr-line my-auto"></p>
                                    <p className="fs-16 fw-600 text-white mx-2 my-0">OR</p>
                                    <p className="modal-hr-line my-auto"></p>
                                </div>
                                <div className='d-flex'>
                                    <h1 className='fs-16 fw-700 text-info'>Scan pan card</h1>
                                    <img src={scanIc} className='btn-size-20 ms-2' alt='scan-ic' />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor='panName' className="fs-16 fw-600 text-white">Name of pan card</label>
                                    <Field
                                        name='panName'
                                        type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                        placeholder="Enter pan name"
                                    />
                                    <ErrorMessage component="span" name="panName" className="error-msg" />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor='panNumber' className="fs-16 fw-600 text-white">Pan card number</label>
                                    <Field
                                        name='panNumber'
                                        type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                        placeholder="XXXX-XXXX-XXXX"
                                    />
                                    <ErrorMessage component="span" name="panNumber" className="error-msg" />
                                </div>

                                <div className="d-flex my-2">
                                    <Field type="checkbox" name='ageLimit' className="mt-1 me-2" />
                                    <p className="text-white fs-14 fw-normal mb-0">I agree to the terms & conditions</p>
                                </div>
                                <div className="d-flex my-0 ms-3">
                                    <ErrorMessage component="span" name="ageLimit" className="error-msg" />
                                </div>
                                <div className="d-flex my-2">
                                    <Field type="checkbox" name='termsConditions' className="mt-1 me-2" />
                                    <p className="text-white fs-14 fw-normal mb-0">I agree to betting terms & conditions</p>
                                </div>
                                <div className="d-flex my-0 ms-3">
                                    <ErrorMessage component="span" name="termsConditions" className="error-msg" />
                                </div>
                                <button type='submit' className='white-flat-btn fw-bold p-2 m-auto my-3'>
                                    Submit
                                </button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }
}
