import { Component } from 'react';
import { NavLink } from 'react-router-dom';
// Assets
import InProgress from '../../images/common/Inprogress.png';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import rsIc from '../../images/icon/rs-ic.png';

export default class ResponsibleGaming extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    render() {
        return (
            <div className='body-section d-flex'>
                <NavLink to="/home">
                    <IoArrowBackCircleOutline className="back-btn" />
                </NavLink>
                <div className='container'>
                    <h4 className='text-center mt-2'><img src={rsIc} height='20px'/> Responsible Gaming</h4>
                    <div className='d-flex justify-content-center align-items-center h-75'>
                        <img src={InProgress} className="w-50" alt='' />
                    </div>
                </div>
            </div>
        );
    }
}
