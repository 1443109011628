import React, { Component } from 'react'
import { BiGlobe } from 'react-icons/bi';
import { MdOutlineArrowForwardIos, MdOutlineLock } from 'react-icons/md';
import { Carousel } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { receivedAt } from '../../../../utils/dateTime.utils';
import { POST_INTERACTIONS } from '../../../../utils/Images.utils';
import { DEN } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';
import Image from '../../../common/Image';
import DenPoll from './DenPoll';
import Interactions from './Interactions';
import Sparks from './Sparks';
import { getNumberFormat } from '../../../../utils/Numbers.utils';

export default class PrivatePost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            post: props.post,
            profilePicture: props.profilePicture,
            sparkDetails: props.sparkDetails,
            i: props.i,
            denProfile: props.denProfile,
            interactions: props.interactions,
            feedDetails: props.feedDetails,
            selectedPostIndex: null,
            postToInteract: null,
        };
    }

    
  render() {

    const {i, post, profilePicture, interactions, denProfile, feedDetails,postToInteract, sparkDetails} = this.state;

    return (
        <div className='steel_gray-card'>
                            
            <div className='row px-3 pt-3'>
                <div className='col-12'>
                    <div className="d-flex">
            
                        <div onClick={()=>this.props.goToUserDenProfile(post.from[0]?._id)}  className="d-flex flex-grow-1 cursor-pointer">
                            <Image src={profilePicture?.url} param={post.from[0]?.name? post.from[0]?.name : 'Test' } className="rounded-circle btn-size-32 border-1"   alt="owner-avatar" />
                            <div className="ms-2 flex-grow-1">
                                <h1 className='fs-14 fw-600 text-white mb-0'>{post.from[0]?.name? post.from[0]?.name : null}  <span style={{fontWeight: '300'}}>{POST_INTERACTIONS.FEELINGS[post.feeling]? `is feeling ${POST_INTERACTIONS.FEELINGS[post.feeling].NAME}` : post.feeling}</span>  {POST_INTERACTIONS.FEELINGS[post.feeling] && <img src={POST_INTERACTIONS.FEELINGS[post.feeling]?.LOGO} alt='' style={{height:'18px'}} />}  </h1> 
                                
                                <p className='fs-12 text-white-50 fw-bold opacity-25'>{receivedAt(post.createdAt)} <span className='me-1'>.</span>
                                    {post.from[0]?.isPublic ? <BiGlobe className='text-white btn-size-12' /> :  <MdOutlineLock className='text-white btn-size-12' /> }
                                </p>
                            </div>
                        </div>

                        {/* {this.props.postType === DEN.POST_TYPES.DRAFT ? 
                            <NavLink className="post-action ms-auto" to={`/den/${denProfile?._id}/create-post/${post._id}`}><MdOutlineArrowForwardIos className="post-action ms-auto"  /></NavLink> 
                            :                                    
                            <div className="cursor-pointer" onClick={() => this.showHidePostAction(i,true, false)}>
                                <Image src={POST_INTERACTIONS.ACTIONS.MORE} params={'action'} className="" style={{width:'24px'}} />
                            </div>
                        } */}

                    </div>
                </div>
                <div className='col-12'>
                    <p className='fs-12 text-white-50 fw-bold'>This post is from private account. Please follow this den account to view this post.</p>
                </div>
                <div className='col-12 my-1 den-blur-post'>
                    {post.description &&
                    <>
                    { ((this.state.showMoreNotificationIndex !== null && this.state.showMoreNotificationIndex === i) || post.description.length < 251 ) ?
                        <p className='fs-12 fw-400 text-white'>{this.props.getFilterredDescription(post.description)}</p>
                        :
                        <p className='fs-12 fw-400 text-white'>
                            <em>{this.getFilterredDescription(post.description.slice(0, ( 250-post.description.length)))}...</em>
                            <a className="fs-14 fw-bold cursor-pointer den-show-more" onClick={() => this.updateState('showMoreNotificationIndex', i)}>Show More</a>
                        </p>
                    }
                    </>
                    }
                </div>
            </div>
            
            <div className='d-flex flex-column align-items-center den-blur-post'>

                {post.type && post.type === 'POST' ?
                
                <Carousel interval={null} className={`${post.assets.length > 1 ? '' : 'single'}`}>
                    {post.assets.map((asset,i)=>{
                        return(
                            <Carousel.Item key={i} interval={null}>
                                <img src={asset.url} className='feed_post-image' alt=''  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://espotz-webapp-images.s3.ap-south-1.amazonaws.com/assets/broken-1.png"
                                }} />
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                :
                <DenPoll updateState={()=>{}} post={post} denProfile={this.state.denProfile} updatePostDetails={this.updatePostDetails} enableEdit={false} />
                }
                
                {/* Grid of spark's on a post */}
                {this.state.selectedPostIndex !== null &&  <Sparks denProfile={this.state.denProfile} index={i} feedDetails={feedDetails} selectedPostIndex={this.state.selectedPostIndex} updateLikedPostDetails={this.updateLikedPostDetails} updateProfileDetails={this.updateProfileDetails} /> }
            </div>
            
            <div className='d-flex px-2 py-2 den-blur-post'>
                <div className='d-flex align-items-center flex-grow-1 cursor-pointer'>
                    {sparkDetails?.logo.map((key,k)=>{
                        return  <img key={k} src={POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO} alt='' className='rounded-circle' style={(k === sparkDetails?.logo.length-1) ? { height:'16px', width:'16px' , marginLeft:'-5px'} : { height:'16px', width:'16px' , marginLeft:'-5px'}} />
                    })}
                    <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{sparkDetails?.likes > 0 ? getNumberFormat(sparkDetails?.likes) : null}</span>
                </div>
                <div className='d-flex align-items-center mx-2'>
                    <img src={POST_INTERACTIONS.HAMMER.OFF} alt='' style={{height:'16px', width: '16px', cursor: 'pointer'}} />
                    <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{post.hammer.length}</span>
                </div>
                <div className='d-flex align-items-center mx-2'>
                    <img src={POST_INTERACTIONS.SHARE.OFF} alt='' style={{height:'16px', width: '16px', cursor: 'pointer'}} />
                    <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{post.shares.length}</span>
                </div>
            </div>

        </div>
    )
  }
}
