import React, { Component } from 'react';
//styles, images
import '../../../styles/TournamentPage.css';
import globe from '../../../images/games/globe.png';
import region from '../../../images/games/region.png';
import { RiFacebookFill, RiWhatsappFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { Carousel } from 'react-bootstrap';

export default class TournamentFooter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: this.props.tournamentDetails,
            tier1: this.props.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Powered By') || (sponsor.type === 'Sponsored By')),
            tier2: this.props.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Co-Powered By') || (sponsor.type === 'Co-sponsored By')),
            tier3: this.props.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Associate Sponsor') || (sponsor.type === 'Streaming Partner'))
        }
    }

    componentDidMount() { }

    componentWillReceiveProps(newProps) {
        this.setState({
            tournamentDetails: newProps.tournamentDetails,
            tier1: newProps.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Powered By') || (sponsor.type === 'Sponsored By')),
            tier2: newProps.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Co-Powered By') || (sponsor.type === 'Co-sponsored By')),
            tier3: newProps.tournamentDetails?.sponsorDetails?.filter(sponsor => (sponsor.type === 'Associate Sponsor') || (sponsor.type === 'Streaming Partner'))
        });
    }

    htmlToElements = (html) => {
        var template = document.createElement('template');
        template.innerHTML = html;
        return template.content.childNodes;
    }

    getSocialMediaIcons = () => {
        const socialMediaPlatforms = ["facebook", "instagram", "telegram", "twitter", "whatsapp"];
        // const socialMediaIcons = [RiFacebookFill,AiFillInstagram,FaTelegramPlane,FaTwitter,RiWhatsappFill];
        const socialMediaIcons = ["RiFacebookFill", "AiFillInstagram", "FaTelegramPlane", "FaTwitter", "RiWhatsappFill"];

        var arr = socialMediaPlatforms.map((platform, i) => {
            const temp = '<' + socialMediaIcons[i] + ' className="btn-size-20 text-dark"/>';
            return React.cloneElement(
                <div className="icon-container" style={{ display: `${this.state.microWebSiteData.contactDetails[platform] ? 'content' : 'none'}`, backgroundColor: '#ffb800' }}>
                    <a href={this.state.microWebSiteData.contactDetails[platform]}>
                        {this.htmlToElements(temp)}
                    </a>
                </div>
            );
        })
        return arr;
    }

    getTierSponsors = (sponsors) => {
        //console.log('tier sponsors::', sponsors)
        if (sponsors.length > 0) {
            return (
                <Carousel>
                    {sponsors.map((sponsor, index) => {
                        if(!(sponsor.ban || sponsor.block)){
                            return (
                                <Carousel.Item key={index}>
                                    <div className='d-flex flex-column'>
                                        <h1 className='fs-16 fw-600 text-warning text-center my-2 text-truncate'>{sponsor.type}</h1>
                                        <img src={sponsor.logo.url} alt='' className='border-2 border-white' />
                                        <h1 className='fs-14 fw-600 text-white text-center my-1'>{sponsor.name}</h1>
                                    </div>
                                </Carousel.Item>
                            )
                        }
                    })}
                </Carousel>
            )
        }
    }

    render() {
        const { tournamentDetails } = this.state
        return (
            <section className='page-footer registration-user-footer p-3'>
                <div className='container'>

                    <div className='row'>
                        <div className='col-lg-3 col-12 d-flex align-items-start mt-3'>
                            <div>
                                <img src={globe} alt='globe' style={{ height: '20px', width: '20px' }} />
                            </div>
                            <div className='ms-2'>
                                <h1 className='fs-20 fw-700 text-white'>Time Zone</h1>
                                <p className='fs-12 fw-700 text-white-50'>{Intl.DateTimeFormat().resolvedOptions().timeZone}</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-12 d-flex align-items-start mt-3'>
                            <div>
                                <img src={region} alt='region' style={{ height: '18px', width: '18px' }} />
                            </div>
                            <div className='ms-2'>
                                <h1 className='fs-20 fw-700 text-white'>Region</h1>
                                <p className='fs-12 fw-700 text-white-50'>{tournamentDetails?.region}</p>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                        <div className='tier-1'>
                            {this.state.tier1 ?  this.getTierSponsors(this.state.tier1) : null}
                            </div>
                        </div>
                        <div className='col-6 col-lg-3'>
                        <div className='tier-2'>
                            {this.state.tier2 ?  this.getTierSponsors(this.state.tier2) : null}
                            </div>
                        </div>
                        <div className='col-6 col-lg-3'>
                            <div className='tier-2'>
                            {this.state.tier3 ?  this.getTierSponsors(this.state.tier3) : null}
                            </div>
                            </div>
                    </div>

                    <div className='d-flex flex-column my-3'>
                        {/* Organization Logo */}
                        <img src={this.state.tournamentDetails?.organizationDetails?.logo?.url} alt='logo' className='mx-auto rounded-15' style={{ height: '100px', width: '200px' }} />
                        {/* Social Media Details */}
                        <div className="d-flex justify-content-center my-2">
                            <div className="icon-container" style={{ display: `${this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.facebook ? 'content' : 'none'}`, backgroundColor: '#ffb800' }}>
                                <a href={this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.facebook ? this.state.tournamentDetails.organizationDetails.socialMediaDetails.facebook : '#'}>
                                    <RiFacebookFill className="btn-size-20 text-dark" />
                                </a>
                            </div>
                            <div className="icon-container" style={{ display: `${this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.instagram ? 'content' : 'none'}`, backgroundColor: '#ffb800' }}>
                                <a href={this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.instagram ? this.state.tournamentDetails.organizationDetails.socialMediaDetails.instagram : '#'}>
                                    <AiFillInstagram className="btn-size-20" />
                                </a>
                            </div>
                            <div className="icon-container" style={{ display: `${this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.telegram ? 'content' : 'none'}`, backgroundColor: '#ffb800' }}>
                                <a href={this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.telegram ? this.state.tournamentDetails.organizationDetails.socialMediaDetails.telegram : '#'}>
                                    <FaTelegramPlane className="btn-size-20" />
                                </a>
                            </div>
                            <div className="icon-container" style={{ display: `${this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.twitter ? 'content' : 'none'}`, backgroundColor: '#ffb800' }}>
                                <a href={this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.twitter ? this.state.tournamentDetails.organizationDetails.socialMediaDetails.twitter : '#'}>
                                    <FaTwitter className="btn-size-20" />
                                </a>
                            </div>
                            <div className="icon-container" style={{ display: `${this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.whatsapp ? 'content' : 'none'}`, backgroundColor: '#ffb800' }}>
                                <a href={this.state.tournamentDetails?.organizationDetails?.socialMediaDetails?.whatsapp ? this.state.tournamentDetails.organizationDetails.socialMediaDetails.whatsapp : '#'}>
                                    <RiWhatsappFill className="btn-size-20" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
