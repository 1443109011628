import { Component } from 'react';
import '../../styles/betting.css';
//import tournamentLogo from '../../images/image75.png';
import betIc from '../../images/icon/bet-ic.png';
//import betPeopleIc from '../../images/icon/betPeople-ic.png';
//import winCup from '../../images/icon/winCup.png';
import betCreatedIc from '../../images/icon/betCreated-ic.png';
import { defaultPreviewImage, errorHandler, getOnlyDate, updateLoader } from '../../utils/common.utils';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import lossIc from '../../images/icon/loss-ic.png';
import wonIc from '../../images/icon/won-ic.png';
import historyIc from '../../images/icon/history-ic.png';
//import maskIc from '../../images/icon/mask-ic.png';
//import winCupGreen from '../../images/icon/winCupGreen-ic.png';
//import winCupRed from '../../images/icon/winCupRed-ic.png';

import { Dropdown } from 'react-bootstrap';
import { getAllUpcomingBets, getBets, getAllPastBets } from '../../utils/Betting.utils';
import { profile } from '../../utils/localstorage.utils';
import UpcomingMatchesCard from '../common/UpcomingMatchesCard';
import PastBetsMatchCards from '../common/PastBetsMatchCards';
import { ASSETS } from '../../utils/Strings.utils';
import BettingApis from '../../helper/betting.api';

import { FaArrowAltCircleUp, FaArrowAltCircleDown } from 'react-icons/fa';

export default class LivePastBettingPage extends Component {
    bettingApis = new BettingApis();
    constructor(props) {
        super(props);
        this.state = {
            BettingCardView: 'LIVE',
            upcomingBetsDetails: [],
            pastBetsDetails: [],
            betType: 'All',
            bettingStats: null
        };
        getAllUpcomingBets(`?userId=${profile()?.id}`,this.setUpcomingBetsDetails);
        getAllPastBets(`?userId=${profile()?.id}`,this.setPastBetsDetails);
//        getBets(`?userId=${profile()?.id}&betStatus=LIVE&isWon=false`,this.setBettingDetails)
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.getAllBetsStats(profile()?.id);
    }

    setUpcomingBetsDetails = (response) => {
        this.setState({
          upcomingBetsDetails: response
        })
    }

    getAllBetsStats = (params) => { 
        updateLoader(true)
        this.bettingApis.getUserBetsStats(params).then(
            (res) => {
                this.setState({bettingStats : res.data.result})
                updateLoader(false);
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    setPastBetsDetails = (response) => {
        this.setState({
            pastBetsDetails: response
        })
    }

    handleFilter = (type) => {
        switch (type) {
            case 'Wins':
                this.setState({betType: 'Wins'});
                getAllPastBets(`?userId=${profile()?.id}&isWon=${true}`,this.setPastBetsDetails);
                break;
            case 'Losses':
                this.setState({betType: 'Losses'});
                getAllPastBets(`?userId=${profile()?.id}&isWon=${false}`,this.setPastBetsDetails);
                break;
            default:
                this.setState({betType: 'All'});
                getAllPastBets(`?userId=${profile()?.id}`,this.setPastBetsDetails);
                break
        }
    }

    render() {

        const {bettingStats} = this.state;
        return (
            <div className='body-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <marquee className='marquee-text fs-14 fw-800 my-3' width="100%" direction="left" style={{ color: '#8C2FB8' }}>“ Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.”</marquee>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex align-items-center mx-2'>
                                <NavLink to="/betting" className=''>
                                    <HiOutlineChevronLeft className='fs-30 text-white' />
                                </NavLink>
                                <div className='m-auto'>
                                    <button className={` ${this.state.BettingCardView === 'LIVE' ? 'clementine-flat-btn' : 'dim_gray-flat-btn'} fs-14 fw-500 px-3 py-1 rounded rounded-2 border-0 text-white me-2`} type="submit" onClick={() => { this.setState({ BettingCardView: 'LIVE' }) }}>
                                        Live Bets
                                    </button>
                                    <button className={`${this.state.BettingCardView === 'PAST' ? 'clementine-flat-btn' : 'dim_gray-flat-btn'} fs-14 fw-500 px-3 py-1 rounded rounded-2 border-0 text-white ms-2`} type="submit" onClick={() => { this.setState({ BettingCardView: 'PAST' }) }}>
                                        Past Bets
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>


                    {(this.state.BettingCardView === 'LIVE') &&
                        <div className='row mt-3'>
                            <div className='col-6'>
                                <div className='d-flex align-items-center ms-2 mb-2'>
                                    <img src={betCreatedIc} className='btn-size-20' alt=''/>
                                    <p className='fs-16 fw-700 text-white ms-2 mb-0'>Live Bets</p>
                                </div>
                            </div>
                            <div className='col-6'></div>

                            {this.state.upcomingBetsDetails?.length ? 
                                this.state.upcomingBetsDetails.map((betDetails,i)=>{
                                    const tournamentDetails = betDetails.tournament;
                                    if(tournamentDetails && betDetails){
                                      return <UpcomingMatchesCard 
                                        key={i} 
                                        matchesDetails={[betDetails]} 
                                        switchToBets={this.switchToBets} 
                                        tournamentDetails={tournamentDetails}
                                        showDateMonthYear={true}
                                        redirect={true}
                                        history={this.props.history}
                                      />
                                    }
    
                                })
                                :
                                <div className="text-center">
                                    <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                                </div>
                            }

                        </div>
                    }
                    {/* userOverallProfitOrLoss, userOverallLastWeekProfitOrLoss */}
                    {(this.state.BettingCardView === 'PAST') &&
                        <div className='row'>
                            <div className='col-12'>
                                <div className='past_betting-background mt-3 p-4'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className=''>
                                                <div className=''>
                                                    <p className='fs-24 fw-700 text-dark mb-0'>{bettingStats?.userOverallProfitOrLoss.toFixed(2)} <span className='fs-12 text-info'>EC</span> </p>
                                                </div>
                                                <div className=''>
                                                    <p className={`fs-12 fw-700 ${bettingStats?.userOverallLastWeekProfitOrLoss>=0 ? 'text-success' : 'text-danger'} mb-0`}>
                                                        {bettingStats?.userOverallLastWeekProfitOrLoss>=0 ? <FaArrowAltCircleUp className='me-2' /> : <FaArrowAltCircleDown className='me-2' />}{Math.abs(bettingStats?.userOverallLastWeekProfitOrLoss.toFixed(2))} EC <span className='text-black-50'>(From Last Week)</span> 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className=''>
                                                <div className='d-flex mb-1'>
                                                    <img src={betIc} width='16' height='16' alt=''/>
                                                    <p className='fs-12 fw-700 text-info mb-0 ms-2'>TOTAL : {bettingStats?.totalBets}</p>
                                                </div>
                                                <div className='d-flex mb-1'>
                                                    <img src={lossIc} width='16' height='16' alt=''/>
                                                    <p className='fs-12 fw-700 text-danger mb-0 ms-2'>LOSS : {bettingStats?.lostBets}</p>
                                                </div>
                                                <div className='d-flex mb-1'>
                                                    <img src={wonIc} width='16' height='16' alt=''/>
                                                    <p className='fs-12 fw-700 text-success mb-0 ms-2'>WON : {bettingStats?.wonBets}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 align-self-center'>
                                <div className='d-flex align-items-center ms-2 mt-3'>
                                    <img src={historyIc} className='btn-size-20 mb-1' alt=''/>
                                    <p className='fs-16 fw-700 text-white ms-2 mb-0'>History</p>
                                </div>
                            </div>
                            <div className='col-6'>
                                <Dropdown className="select-game-dropdown mt-3">
                                    <Dropdown.Toggle className='fs-14 dropdown-basic w-100 text-start ps-3 height-45'>
                                        {this.state.betType}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu  onClick={(e)=> this.handleFilter(e.target.name)}>
                                        <Dropdown.Item name="" className="text-white">All</Dropdown.Item>
                                        <Dropdown.Item name="Wins" className="text-white">Wins</Dropdown.Item>
                                        <Dropdown.Item name="Losses" className="text-white">Losses</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {this.state.pastBetsDetails?.length ? 
                                this.state.pastBetsDetails?.map((betDetails, i) => { 
                                    const tournamentDetails = betDetails.tournament;
                                    if(tournamentDetails && betDetails){
                                        return(
                                            <PastBetsMatchCards 
                                                key={i} 
                                                matchesDetails={[betDetails]} 
                                                switchToBets={this.switchToBets} 
                                                tournamentDetails={tournamentDetails}
                                                redirect={true}
                                                history={this.props.history}
                                            />
                                        )
                                      }
                                })
                                :
                                <div className="text-center">
                                    <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}
