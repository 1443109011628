import { actionTypes } from "../constants/actionTypes";

const initialState = {}
export const AllGamesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_GAMES:
            return payload;
        default:
            return state;
    }
}
export const AllPublicTournamentsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.PUBLIC_TOURNAMENTS:
            return payload;
        default:
            return state;
    }
}
export const UpdateEcReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.UPDATE_EC:
            return payload;
        default:
            return state;
    }
}