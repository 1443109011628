import React, { Component } from 'react';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai'
import LeftSideBar from '../common/LeftSideBar';
import RightSideBar from '../common/RightSideBar';
import { denPostSearch, denProfileSearch, getDenProfilePicture, getDenProfiles, getPostInteractionsDetails, reportDenPost } from '../../../../utils/Den.utils';
import Image from '../../../common/Image';
import { capitalize, getDetailsObj } from '../../../../utils/common.utils';
import { DEN_EMPTY_SCREENS, PROFILE } from '../../../../utils/Images.utils';
import { NavLink } from 'react-router-dom';
import PostCard from '../common/PostCard';
import { DEN, ERROR, STATE_VARIABLE } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';
import PostActions from '../../../popup/den/PostActions';
import ReportPost from '../common/ReportPost';
import { toast } from 'material-react-toastify';
import UndoReportPost from '../../../common/UndoReportPost';

/**
* @author
* @class DenSearchTab
**/

class DenSearchTab extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            searchString: null,
            searchType: DEN.SEARCH.TYPES.ALL,
            activeTab: DEN.TABS.HOME,
            denProfile: null,
            feedDetails: [],
            showMoreNotificationIndex: null,

            openPostActionModal: false,
            openPostReportModal: false,
            selectedPostIndex: null,
            selectedReportType: null,
            selectedReportTypeDescription: null,
            postToInteract: null,
            selectedActionIndex: null,
            reportedDetails: null,
            detailsObj: null
        };
      }
    

    componentDidMount() {

        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile);
        }
       
        const queryParams = new URLSearchParams(window.location.search);
        const param = queryParams.get('param');
        if(param?.trim().length > 0){
            this.setState({
                searchString: param
            });
            if(param?.trim().length > 2){
                this.denProfileOrPostSearch((this.state.searchType === DEN.SEARCH.TYPES.POST ? true : false), param);
            }

        }
        this.nameInput.focus();
    }

    setCurrentUserDenProfile = (result) =>{
        if(result[0]){
            this.setState({ denProfile: result[0] })
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }

    updateSearchResult = (result) => {
        this.setState({
            feedDetails: result
        })
    }

    removeParam(parameter) {
        var url = document.location.href;
        var urlParts = url.split('?');
    
        if (urlParts.length >= 2) {
          var urlBase = urlParts.shift();
          var queryString = urlParts.join("?");
          var prefix = encodeURIComponent(parameter) + '=';
          var pars = queryString.split(/[&;]/g);
          for (var i = pars.length; i-- > 0;)
            if (pars[i].lastIndexOf(prefix, 0) !== -1)
              pars.splice(i, 1);
          url = urlBase + '?' + pars.join('&');
          window.history.pushState('', document.title, url); // added this line to push the new url directly to url bar .
    
        }
        return url;
    }

    updateSearch = (e) => {
        if(!e.target.value.includes('#')){
            var updatedSearchString = e.target.value; 
            if(updatedSearchString?.trim().length > 2){
                this.denProfileOrPostSearch((this.state.searchType === DEN.SEARCH.TYPES.POST ? true : false), updatedSearchString);
            }

            this.setState({ searchString: e.target.value });
        }
    }

    updateSearchType = (key, val) => {
        this.setState({ feedDetails: [],[key]: val });
        if(this.state.searchString?.length > 2){
            this.denProfileOrPostSearch((val === DEN.SEARCH.TYPES.POST ? true : false), this.state.searchString);
        }
    }

    denProfileOrPostSearch = (isPostSearch, searchString) =>{
        if(isPostSearch){
            denPostSearch(searchString,this.updateSearchResult);
        }else{
            denProfileSearch(this.state.denProfile?._id ,searchString,this.updateSearchResult);
        }
    }

    showHidePostAction = (index, val1, val2) => {

        if(!val1 && !val2){
            this.setState({
                selectedActionIndex: null,
                selectedReportType: null,
                selectedReportTypeDescription: null,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }else{
            this.setState({
                selectedActionIndex: index,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }
    }

    onCheckReportType = (value) =>{
        this.setState({
            selectedReportType: ((this.state.selectedReportType === value)? null : value)
        });
    }

    onChangeReportDescription = (val) =>{
        this.setState({
            selectedReportTypeDescription: val
        })
    }

    onSubmitReport = () =>{
        reportDenPost(this.state.feedDetails[this.state.selectedActionIndex]._id, {type: this.state.selectedReportType, description:this.state.selectedReportTypeDescription }, this.showHidePostAction, this.enableUndoReport);
    }

    enableUndoReport = (report) => {
        console.log(report);
        this.setState({
            reportedDetails: report
        });
        setTimeout(() => {
            this.clearUndoReportPopup();
        }, 10000);
      
    }

    clearUndoReportPopup = () => {
        this.setState({
            reportedDetails: null
        });
    }

    postToInteract = (index) =>{
        this.setState({
            postToInteract: index
        });
    }

    updateProfileDetails = (updatedProfileDetails) =>{
        this.showHidePostAction(null, false, false);
        //updateDenProfileInLocalStorage(updatedProfileDetails);
        this.setState({
            denProfile : updatedProfileDetails
        });
    }

    updatePostDetails = (result,selectedActionIndex, message?) =>{

        if(message){ toast.success(message) }
        const updatedPostDetails = this.state.feedDetails.map((post,i)=>{
            if(post._id === result._id){
                result.from = this.state.feedDetails[i].from;
                return result;
            }else{
                return post;
            }
        });

        this.setState({
            feedDetails: updatedPostDetails
        });
       this.showHidePostAction(null, false, false);
    }

    getView = () =>{
        const {searchString, searchType, feedDetails} = this.state;
        const profileSearch =  searchType !== DEN.SEARCH.TYPES.POST;
        var isRoportingEnabled = (this.state.selectedReportType !== null); 

        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2'>
                        {/* Search Box */}
                        <div className='d-flex my-2'>
                            <div className='purple-field d-flex align-items-center border-0 height-45 flex-grow-1'>
                                <input
                                    type='search'
                                    maxLength={50}
                                    ref={(input) => { this.nameInput = input; }} 
                                    placeholder='search'
                                    className='flex-grow-1 border-0 ps-3 text-white fs-14'
                                    style={{background:'transparent', outline: 'none' }}
                                    onChange={(e) => this.updateSearch(e)}
                                    value={searchString}
                                />
                                <AiOutlineSearch className='btn-size-20 me-2' />
                            </div>
                        </div>
                        <div className="d-flex" style={{ width: '100%' }} role="group" aria-label="Basic example">
                            <button type="button" onClick={() => this.updateSearchType(STATE_VARIABLE.SEARCH_TYPE, DEN.SEARCH.TYPES.ALL)} style={{ opacity: profileSearch ? '1' : '0.4' }} className={`grey-card-2 rounded-1 text-white score-showdown-btn rounds flex-grow-1`}>Profiles</button>
                            <button type="button" onClick={() => this.updateSearchType(STATE_VARIABLE.SEARCH_TYPE, DEN.SEARCH.TYPES.POST)} style={{ opacity: !profileSearch ? '1' : '0.4' }} className={`grey-card-2 rounded-1 text-white score-showdown-btn rounds flex-grow-1`}>Posts</button>
                        </div>
                        
                        {/* Search Type */}
                        {searchType === DEN.SEARCH.TYPES.POST ?
                            <div className='d-flex my-0'>
                                <span className='cursor-pointer px-2' style={STYLE.DEN.SEARCH.ACTIVE} >{capitalize(DEN.SEARCH.TYPES.POST.toLowerCase())}({feedDetails.length})</span>
                            </div>
                        :
                            <div className='d-flex my-0'>        
                                <span className='cursor-pointer px-2' style={ searchType === DEN.SEARCH.TYPES.ALL ? STYLE.DEN.SEARCH.ACTIVE : STYLE.DEN.SEARCH.INACTIVE } onClick={() => this.updateSearchType(STATE_VARIABLE.SEARCH_TYPE, DEN.SEARCH.TYPES.ALL)}>{capitalize(DEN.SEARCH.TYPES.ALL.toLowerCase())}({feedDetails.length})</span>
                                <span className='cursor-pointer px-3' style={ searchType === DEN.SEARCH.TYPES.USER ? STYLE.DEN.SEARCH.ACTIVE : STYLE.DEN.SEARCH.INACTIVE } onClick={() => this.updateSearchType(STATE_VARIABLE.SEARCH_TYPE, DEN.SEARCH.TYPES.USER)}>{capitalize(DEN.SEARCH.TYPES.USER.toLowerCase())}</span>
                                <span className='cursor-pointer px-3' style={ searchType === DEN.SEARCH.TYPES.ORG ? STYLE.DEN.SEARCH.ACTIVE : STYLE.DEN.SEARCH.INACTIVE } onClick={() => this.updateSearchType(STATE_VARIABLE.SEARCH_TYPE, DEN.SEARCH.TYPES.ORG)}>{capitalize(DEN.SEARCH.TYPES.ORG.toLowerCase())}</span>
                                <span className='cursor-pointer px-3' style={ searchType === DEN.SEARCH.TYPES.CLAN ? STYLE.DEN.SEARCH.ACTIVE : STYLE.DEN.SEARCH.INACTIVE } onClick={() => this.updateSearchType(STATE_VARIABLE.SEARCH_TYPE, DEN.SEARCH.TYPES.CLAN)}>{capitalize(DEN.SEARCH.TYPES.CLAN.toLowerCase())}</span>
                            </div>
                        }
                        <hr className='my-0' />
                    </div>
                    {/* Search Results */}
                    {profileSearch ?
                        <div className='post-card-bg p-2 my-3'>
                            {(this.state.feedDetails.length && this.state.searchString ) ?
                                <div className='d-flex flex-column overflow-auto my-3'>
                                    {this.state.feedDetails.map((profile, i) => {
                                        var profilePicture = getDenProfilePicture (profile);
                                        if(searchType === profile.type || searchType === DEN.SEARCH.TYPES.ALL){
                                            return (
                                                <NavLink to={`/den/${this.state.denProfile?._id}/profile-page/${profile._id}`} className='cursor-pointer' key={i}>
                                                    <div className='d-flex align-items-center'>
                                                        <Image src={profilePicture?.url} param={profile.name} alt='' className='rounded-circle hover-zoomin' style={{height:'40px', width:'40px'}}/>
                                                        <div className='flex-grow-1 mx-2'>
                                                            <p className='fs-16 text-white fw-600 my-0'>{capitalize(profile.name)}</p>
                                                            <spam className='fs-12 text-white-50 fw-500'>{profile.profileTag ? profile.profileTag : profile.type}</spam>
                                                        </div>
                                                        <img src={PROFILE.TAG[profile.type]} alt='' className='hover-zoomin' style={{height:'20px', width:'auto'}} />
                                                    </div>
                                                    <hr className='hr-line my-1' />
                                                </NavLink>
                                            )
                                        }
                                    })}
                                </div>
                                :
                                <div className='row'>
                                <div className="column-center-align-div" style={{height:'400px'}}>
                                    <img className="" src={DEN_EMPTY_SCREENS.NO_RESULTS_FOUND} alt={""} />
                                </div>
                            </div>
                            }
                        </div>
                        :
                        <div className='row my-3'>
                            {this.state.feedDetails.length ?
                                this.state.feedDetails.map((post, i) => {
                                var likedUsers = [];    
                                
                                Object.keys(post.likes).map((key,i)=>{ 
                                    likedUsers = likedUsers.concat(post.likes[key]);
                                });

                                const interactions = getPostInteractionsDetails(i, post, likedUsers, this.state.denProfile, this.state.postToInteract, this.state.openSpotlightForPostIndex);
                                const isPrivatePost =   (!post.from[0]?.isPublic && !post.from[0]?.followers?.includes(this.state.denProfile?._id))
                               
                                console.log(`------POST ${i} :: `,isPrivatePost)
                                return (
                                    <div>
                                        <PostCard 
                                            key={i} 
                                            i={i} 
                                            post={post} 
                                            postType={null}
                                            isPrivatePost={isPrivatePost}
                                            denProfile={this.state.denProfile} 
                                            interactions={interactions} 
                                            history={this.props.history}
                                            feedDetails={this.state.feedDetails} 
                                            selectedPostIndex={this.state.selectedPostIndex}
                                            selectedReportType={this.state.selectedReportType}
                                            showMoreNotificationIndex={this.state.showMoreNotificationIndex}
                                            postToInteract={this.state.postToInteract}
                                            showHidePostAction={this.showHidePostAction}
                                            updateProfileDetails={this.updateProfileDetails}
                                            updatePostDetails={this.updatePostDetails}
                                            isLiveStraming={false}
                                        />
                                        {(this.state.reportedDetails && this.state.reportedDetails?.postId  === post._id) && <UndoReportPost reportedDetails={this.state.reportedDetails} clearUndoReportPopup={this.clearUndoReportPopup} />}
                                    </div>
                                )
                                })
                                :
                                <div className='post-card-bg'>
                                    <div className="column-center-align-div" style={{height:'400px'}}>
                                        <img className="" src={DEN_EMPTY_SCREENS.NO_RESULTS_FOUND} alt={""} />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {/* Post Action Div */}
                {this.state.openPostActionModal &&
                    <PostActions 
                        postType={null}
                        show={this.state.openPostActionModal} 
                        denProfile={this.state.denProfile}
                        feedDetails={this.state.feedDetails}
                        updatePostDetails={this.updatePostDetails}
                        updateProfileDetails={this.updateProfileDetails}
                        selectedActionIndex={this.state.selectedActionIndex}
                        showHidePostAction={this.showHidePostAction}
                        onClose={() => this.showHidePostAction(null, false, false)}  
                    />
                }
                {/* Report Post Details */}
                <ReportPost 
                    onSubmitReport={this.onSubmitReport} 
                    showHidePostAction={this.showHidePostAction} 
                    onChangeReportDescription={this.onChangeReportDescription} 
                    onCheckReportType={this.onCheckReportType} 
                    isRoportingEnabled={isRoportingEnabled} 
                    openPostReportModal={this.state.openPostReportModal} 
                    selectedReportType={this.state.selectedReportType} 
                    selectedReportTypeDescription={this.state.selectedReportTypeDescription} 
                />
            </div>
        )
    }

    render() {
        //console.log("\n\n\n ######## onTvSearch :: ",this.props.onTvSearch)
        return(
            <div className='body-section den_page'>
                <div className='container'>
                    <div className='row'>
                        <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                        {this.getView()}
                        <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                    </div>
                </div>
            </div>
        )
    }
}


 DenSearchTab.propTypes = {}
export default DenSearchTab