import React, { Component } from 'react';
import TournamentHeader from '../../../../tournament/common/TournamentHeader';
import winCup from '../../../../../images/icon/winCup.png';
import '../../../../../styles/Tournaments.css'
import MatchesApis from '../../../../../helper/matches.api';
import { errorHandler, getMarginTopForRounds, getMonthAndDate, showHideMatchInvite } from '../../../../../utils/common.utils';
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import { HiUserAdd } from "react-icons/hi";
import { MdRefresh, MdZoomOutMap } from "react-icons/md";
import MatchInvite from '../../../../popup/MatchInvite';
import { Modal } from "react-bootstrap";
import NoRecordFound from '../../../../common/NoRecordFound';

export default class FFABracketGroupRounds extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.tournamentDetails,
      groupMatches: props.groupMatches,
      maxParticipantsPerMatch: props.maxParticipantsPerMatch,
      scale: 1,
      openInvite: false,
      selectedMatch: null
    }
    this.marginTopDiv = 35;

    this.matchApis = new MatchesApis();



  }

  componentWillReceiveProps(newProps) {
    this.setState({
      tournamentDetails: newProps.tournamentDetails,
      groupMatches: newProps.groupMatches
    });
  }

  zoom = (key) => {
    switch (key) {
      case 'In':
        this.setState({ scale: this.state.scale * 1.1 });
        break;
      case 'Out':
        this.setState({ scale: this.state.scale / 1.1 });
        break;
      default:
        this.setState({ scale: 1 });
        break;
    }
  }

  openInviteModal = (e, match) => {
    console.log("match :: ", match)
    this.setState({
      openInvite: true,
      selectedMatch: match
    })
  }

  onClickOpenInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite
    })
  }


  render() {

    console.log(`tournamentDetails.stages[${this.props.index}] :: `, this.state.tournamentDetails)
    console.log("groupMatches :: ", this.state.groupMatches)

    if (this.state.groupMatches && Object.keys(this.state.groupMatches).length !== 0) {
      var marginTopDiv = 0;

      var structure = [4, 2, 1];
      return (
        <div className='w-100 d-flex overflow-auto' style={{ height: '-webkit-fill-available' }}>
          <div className='' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, overflow: 'none', width: 'fit-content' }}>
            {
              Object.keys(this.state.groupMatches).map((key, i) => {
                return (
                  <div className="py-1 px-3" key={key + i}>
                    <button type="button" className={`score-showdown-btn me-2 rounds`}>ROUND {key}</button>
                    <div className='d-flex overflow-auto my-3'>
                      {
                        this.state.groupMatches[key].map((match, index) => {
                          //console.log('match details::', match)
                          var dateAndTime = getMonthAndDate(match.matchSchedule);
                          var players = [];
                          let stageIndex = parseInt(this.props.index)
                          let lastStageThreshold = (stageIndex !== 0) ? parseInt(this.state.tournamentDetails.stages[stageIndex - 1].qualificationThreshold) : 0;
                          let perGrpParticipants = this.state.tournamentDetails?.stages[this.props.index]?.maxParticipantsPerGroup;
                          let perMatchParticipants = this.state.tournamentDetails?.stages[this.props.index]?.maxParticipantsPerMatch;
                          for (let j = 0; j < this.state.tournamentDetails.stages[this.props.index].maxParticipantsPerMatch; j++) {

                            var player = match.participants[j] ? match.participants[j] : null;

                            if (match.resultAdded && player) {
                              player.isWinner = match.finalResult[j].isWinner ? match.finalResult[j].isWinner : false;
                            }

                            players.push(player);

                          }
                          const canParticipate = showHideMatchInvite(match, this.state.tournamentDetails);
                          console.log('canParticipate ---', canParticipate);
                          //console.log('$$$ players arr::', players)
                          return (
                            <div key={`${key}.${index + 1}`} className="tournament-btn pink-skyblue-gradient-btn d-flex flex-column justify-content-start align-items-start flex-shrink-0 me-2 pb-2" style={{ height: 'auto', width: '320px' }} >
                              <span className="match-tag position-absolute">Match {key}.{index + 1}</span>
                              <div className="row ms-0 w-100 mt-1 justify-content-between">
                                <div className="col-5">

                                  <div className="d-flex flex-column">
                                    {players.map((player, ind) => {
                                      if (player !== null) {
                                        return (
                                          <div className="d-flex align-items-center" key={ind}>
                                            <span className="fs-12 fw-bold my-1 text-truncate" key={ind}>
                                              <img src={this.state.tournamentDetails.participantType === 'TEAM' ? players[ind].logo.url : players[ind].profilePicture.url} className='btn-size-15 rounded-circle me-2' alt=''/> {this.state.tournamentDetails.participantType === 'TEAM' ? players[ind].name : players[ind].inGameName}
                                            </span>
                                            {match.resultAdded && player.isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt=''/> : null}
                                          </div>
                                        );
                                      } else {
                                        return <span key={ind} className="player-name my-1 vacant">Vacant</span>
                                      }
                                    })}
                                  </div>

                                </div>

                                {(key == 1 && canParticipate) ?
                                  <div className="col-3 d-flex align-items-center justify-content-center">
                                    <button disabled={(((perGrpParticipants * this.props.selectedGroupIndex) + ((perMatchParticipants * (index + 1))) <= lastStageThreshold))} onClick={(e) => this.openInviteModal(e, match)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-40 mx-2" >
                                      <HiUserAdd className="btn-size-15" />
                                    </button>
                                  </div>
                                  :
                                  null
                                }

                                <div className="col-4 d-flex align-items-center justify-content-end">
                                  <div className="d-flex flex-column float-end">
                                    <span className="player-name my-1">{dateAndTime[0]}</span>
                                    <span className="player-name">{dateAndTime[1]}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>

          {/* Zoom In/Out Section */}
          <div className="icons-box d-flex justify-content-center w-100">
            <div className="icon mx-2" onClick={() => this.zoom('Out')}>
              <ImZoomOut className="zoom-icon" />
            </div>
            <div className="icon mx-2" onClick={() => this.zoom('Reset')}>
              <MdZoomOutMap className="zoom-icon" />
            </div>
            <div className="icon mx-2" onClick={() => this.zoom('In')}>
              < ImZoomIn className="zoom-icon" />
            </div>
          </div>

          {/* Tournament Invitation Section */}
          <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
            <Modal.Body>
              <MatchInvite
                onClickOpenInvite={this.onClickOpenInvite}
                tournamentId={this.state.tournamentDetails?._id}
                groupIndex={this.props.selectedGroupIndex}
                stageId={this.props.index}
                matchId={this.state.selectedMatch?._id}
                gameId={this.state.tournamentDetails?.gameId}
                participantType={this.state.tournamentDetails?.participantType}
                choice="Send Invite"
              />
            </Modal.Body>
          </Modal>
        </div>


      );

    } else {
      return (
        <NoRecordFound />
      );
    }
  }
}

