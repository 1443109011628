
export const VerificationStatus = Object.freeze({
    NOT_VERIFIED: 'NOT_VERIFIED',
    VERIFICATION_REQUESTED: 'VERIFICATION_REQUESTED',
    VERIFIED: 'VERIFIED',
})
export const TournamentStatus = {
    ONGOING : "ONGOING",
    COMPLETED : "COMPLETED",
    CANCELLED : "CANCELLED",
    UPCOMING : "UPCOMING",
    DRAFT : "DRAFT",
    ALL : 'ALL'
}

export const ParticipantType = {
    SINGLE : "SINGLE",
    TEAM : "TEAM",
    BOTH : "BOTH",
}

export const GamingPlatforms = {
    PC : 'PC',
    MOBILE: 'MOBILE',
    NINTENDO : 'SWITCH',
    PS : 'PS',
    XBOX: 'XBOX'
}

export const ListingType = {
    REGULAR : "REGULAR",
    PREMIUM : "PREMIUM",
}

export const CompetitionType = {
    FFA : "FFA",
    DUEL : "DUEL",
    BOTH : "BOTH"
}

export const TournamentVisibility = {
    PUBLIC : "PUBLIC",
    PRIVATE : "PRIVATE"
}

export const PrizePoolType = {
    FIXED : "FIXED",
    CROWDFUNDED : "CROWDFUNDED",
    NONE : ""
}

export const MatchStatus = {
    DRAFT : "DRAFT",
    UPCOMING : "UPCOMING",
    IN_PROGRESS : "IN_PROGRESS",
    COMPLETED : "COMPLETED",
    CANCELLED : "CANCELLED",
    POSTPONED : "POSTPONED",
}

export const CREATE_TOURNAMENT = {
    CATEGORY: 'CATEGORY',
    PARTICIPANTS_TYPE: 'PARTICIPANTS_TYPE',
    COMPETITION_TYPE: 'COMPETITION_TYPE'
}

export const WEBSITE_ENUM = {
    TYPE:{
        INFORMATION_TEXT : "INFORMATION_TEXT",
        STATIC_PAGE: "STATIC_PAGE",
        CONTACT : "CONTACT",
        NEWS : "NEWS",
    },

    SUB_TYPE: {
        CLAN_ORG : "CLAN_ORG",
        STAGE_DETAILS : "STAGE_DETAILS",
        CREATE_TOURNAMENT: "CREATE_TOURNAMENT",
        PRIVANCY_POLICY : "PRIVANCY_POLICY",
        TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
        RULES: "RULES",
        BETTING_RULES: "BETTING_RULES",
        PAYMENTS_OPTIONSY: "PAYMENTS_OPTIONSY",
        DISCLAIMER: "DISCLAIMER",
        CONTACT_ESPOTZ: "CONTACT_ESPOTZ",
        SOCIAL_MEDIA_POLICY: "SOCIAL_MEDIA_POLICY",
        FAQS: "FAQS"
    },

    QUESTIONS: {
        CLAN_DESCRIPTION: "CLAN_DESCRIPTION",
        ORG_DESCRIPTION: "ORG_DESCRIPTION",
        QUALIFICATION_THRESHOLD: "QUALIFICATION_THRESHOLD",
        PLAY_OFF_FORMAT: "PLAY_OFF_FORMAT",
        ENABLE_GRAND_FINALE: "ENABLE_GRAND_FINALE",
        MAX_NO_OF_GROUPS: "MAX_NO_OF_GROUPS",
        CATEGORY: "CATEGORY",
        PARTICIPANTS_TYPE: "PARTICIPANTS_TYPE",
        COMPETITION_TYPE: "COMPETITION_TYPE",
        SET_UP_PRIVACY_POLICY: "SET_UP_PRIVACY_POLICY",
        SET_UP_TERMS_AND_CONDITIONS: "SET_UP_TERMS_AND_CONDITIONS",
        SET_UP_RULES: "SET_UP_RULES",
        SET_UP_BETTING_RULES: "SET_UP_BETTING_RULES",
        SET_UP_PAYMENTS_OPTIONSY: "SET_UP_PAYMENTS_OPTIONSY",
        SET_UP_DISCLAIMER: "SET_UP_DISCLAIMER",
        SET_UP_CONTACT_ESPOTZ: "SET_UP_CONTACT_ESPOTZ",
        SET_UP_SOCIAL_MEDIA_POLICY: "SET_UP_SOCIAL_MEDIA_POLICY",
        CONTACT_US_EMAIL: "CONTACT_US_EMAIL",
        ESPOTZ_TWITTER_LINK: "ESPOTZ_TWITTER_LINK",
        ESPOTZ_TELEGRAM_LINK: "ESPOTZ_TELEGRAM_LINK",
        ESPOTZ_FACEBOOK_LINK: "ESPOTZ_FACEBOOK_LINK",
        ESPOTZ_WHATSAPP_LINK: "ESPOTZ_WHATSAPP_LINK"
    }

};

export const TERMS_AND_CONDITIONS_TYPES = {
    SIGN_UP_PAGE: "SIGN_UP_PAGE",
    LANDING_PAGE: "LANDING_PAGE",
    ENTRY_FEE_PRICE_DETAILS: "ENTRY_FEE_PRICE_DETAILS",
    TOURNAMENT_PUBLISHING: "TOURNAMENT_PUBLISHING",
    CANCEL_REFUND: "CANCEL_REFUND",
    TOURNAMENT_REGISTRATION: "TOURNAMENT_REG",
    SET_BET_FOR_GAME: "SET_BET_FOR_GAME",
    BET_FOR_GAME: "BET_FOR_GAME"
};

export const DEN_PROFILE_TAGS = ['Player', 'Tournament Operator', 'Caster', 'Observer', 'VFX Artist', 'Coach', 'Admin', 'Fan', 'Other'];

export const DEN_POST_REPORT_TYPES = {
    SEXUAL_CONTENT: {
        TYPE: 'SEXUAL_CONTENT',
        NAME: 'Sexual content'
    },
    VIOLENT_OR_REPULSIVE_CONTENT: {
        TYPE: 'VIOLENT_OR_REPULSIVE_CONTENT',
        NAME: 'Violent or repulsive content'
    },
    HATEFUL_OR_ABUSIVE_CONTENT: {
        TYPE: 'HATEFUL_OR_ABUSIVE_CONTENT',
        NAME: 'Hateful or abusive content'
    },
    HARMFUL_OR_DANGEROUS_ACTS: {
        TYPE: 'HARMFUL_OR_DANGEROUS_ACTS',
        NAME: 'Harmful or dangerous acts'
    },
    SPAM_OR_MISLEADING: {
        TYPE: 'SPAM_OR_MISLEADING',
        NAME: 'Spam or misleading'
    },
    CHILD_ABUSE: {
        TYPE: 'CHILD_ABUSE',
        NAME: 'Child abuse'
    },
    OTHER: {
        TYPE: 'OTHER',
        NAME: 'Others'
    }
};

export const  GAMES_NAMES = {
    FORTNITE : 'Fortnite',
    LEAGUE_OF_LEGENDS : 'League Of Legends',
    DOTA_2 : 'Dota 2',
    CS_GO : 'CS:GO',
    PUBG : 'PUBG',
    PUBG_MOBILE : 'PUBG Mobile',
    VALORANT: 'Valorant',
    CALL_OF_DUTY : 'Call Of Duty',
    FREEFIRE : 'FreeFire',
    APEX_LEGENDS : 'Apex Legends'
}

export const COMMISSION_TYPES = {
    ENTRY_FEE_PRICE_POOL: 'ENTRY_FEE_PRICE_POOL',
    WALLET: 'WALLET',
    DEN: 'DEN',
    TOURNAMENT_CANCELLATION: 'TOURNAMENT_CANCELLATION'
}

export const COMMISSION_QUESTION_TYPE = {
    REGULAR_FIXED_PC : 'ESPOTZ_COMMMISSION_ON_RPC',
    REGULAR_CF_TC : 'ESPOTZ_COMMMISSION_ON_TFRT',
    PREMIUM_FIXED_PC : 'ESPOTZ_COMMMISSION_ON_PPC',
    PREMIUM_FIXED_TC : 'ESPOTZ_COMMMISSION_ON_TFPT',
    REGULAR_BEFORE_RS: 'BF_REG_BEGINS',
    REGULAR_BW_RS_TS: 'IN_BW_REG_TOURNAMENT_SD',
    REGULAR_ATFER_TS: 'POST_TOURNAMENT_SD',
    PREMIUM_BEFORE_RS: 'BEFORE_REG_BEGIN',
    PREMIUM_BW_RS_TS: 'IN_BW_REG_TOUR_SD',
    PREMIUM_ATFER_TS: 'POST_TOURNAMENT_SD_PM',
    PLAYER_REFUND: 'PLAYER_REFUND_ET'
}

export const CLAN_ORG_ROLES = {
    OWNER: {NAME: 'Owner', VALUE: 'OWNER'},
    ADMIN: {NAME: 'Admin', VALUE: 'ADMIN'},
    MEMBER: {NAME: 'Member', VALUE: 'MEMBER'},
}

export const USER_DEN_STATS = {
    MATCHES_PLAYED: 'matchPlayed',
    MATCHES_WON: 'matchWin',
    SPOTLIGHT_COLLECTION: 'spotlightCollection',
    MVP_COUNT: 'mvpCount',
    GAME_STATS: 'gameStats',
    TOURNAMENT_COUNT: 'tournamentCount',
    BETTING_STATS: 'bettingStatistics'
}

export const POST_TYPES = {
    SAVED: 'SAVED',
    HIDDEN: 'HIDDEN',
    DRAFT: 'DRAFT',
}

export const CREATE_POST_VIEWS = {
    CREATE_POST: 'CREATE POST',
    FEELING: 'FEELING',
    AUDIENCE: 'AUDIENCE',
    POST_AS: 'POST_AS',
    VIDEO_OPTIONS: 'VIDEO_OPTIONS',
    POLL_OPTIONS: 'POLL_OPTIONS',
}

export const RANKS_ARRAY = ['FIRST_LVL_ACHIEVE', 'SECOND_LVL_ACHIEVE', 'THIRD_LVL_ACHIEVE', 'FORTH_LVL_ACHIEVE', 'FIFTH_LVL_ACHIEVE'];

export const COMMENTS_RANKS_ARRAY = ['COMMENT_FIRST_RANK', 'COMMENT_SECOND_RANK', 'COMMENT_THIRD_RANK', 'COMMENT_FORTH_RANK', 'COMMENT_FIFTH_RANK'];

export const SPOTLIGHT_RANKS_ARRAY = ['SPOTLIGHT_FIRST_RANK', 'SPOTLIGHT_SECOND_RANK', 'SPOTLIGHT_THIRD_RANK', 'SPOTLIGHT_FORTH_RANK', 'SPOTLIGHT_FIFTH_RANK'];

export const RANK_INDEXES = {
    FIRST_LVL_ACHIEVE: 1,
    SECOND_LVL_ACHIEVE: 2,
    THIRD_LVL_ACHIEVE: 3,
    FORTH_LVL_ACHIEVE: 4,
    FIFTH_LVL_ACHIEVE: 5
};