import React, { Component } from "react";
import "../../../../../styles/brackets.css";
import "../../../../../styles/Tournaments.css";
import TournamentHeader from "../../common/TournamentHeader";
import structureIc from '../../../../../images/icon/stageImg.png';
import swapIc from '../../../../../images/icon/swap-ic.png';
import timestampIc from '../../../../../images/icon/timestamp-ic.png';

import winCup from '../../../../../images/icon/winCup.png';

import { Button, Modal } from "react-bootstrap";
import { HiUserAdd } from "react-icons/hi";
import { errorHandler, getMonthAndDate, getTournamentAdditionalData, getTournamentData, months, updateLoader } from "../../../../../utils/common.utils";
import MatchInvite from "../../../../popup/MatchInvite";
import { toast } from 'material-react-toastify';
import TournamentApis from "../../../../../helper/tournament.api";
import MatchesApis from "../../../../../helper/matches.api";
import FlexibleBrackets from "../../../../common/FlexibleBrackets";
import { AiFillPlusCircle } from "react-icons/ai";
import ConfirmationModal from "../../../../popup/ConfirmationModal";
import { WARNING } from "../../../../../utils/Strings.utils";


export default class FFAFlexibleStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.state.tournamentDetails,
      structure: props.state.structure,
      scale: 1.1,
      openInvite: false,
      matchId: -1,
      selectedPlayers: [],
      isSwappingEnabled: false,
      displayRounds: false,
      groupMatches: null,
      selectedGroupe: 0,
      deleteConfirm: {
        msg: <p className="fs-14 fw-600 text-center text-white">{WARNING.DELETE_MATCH}</p>,
        key: ''
      },
      addConfirm: {
        msg: <p className="fs-14 fw-600 text-center text-white">{WARNING.ADD_NEW_MATCH}</p>,
        key: ''
      },
      enableConfirmation: false,
      deleteMatchDetails: null
    };

    this.prevValue = 0;
    this.marginTopDiv = 35;
    this.maxWidth = 0;


    this.tournamentApis = new TournamentApis();
    this.matchesApis = new MatchesApis();

    this.isSwapped = false;
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }





  onClickOpenInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite
    })
  }

  openInviteModal = (e, matchId) => {
    e.preventDefault();
    this.setState({
      openInvite: true,
      matchId: matchId
    })
  }

  enableDisableSwapping = () => {
    this.setState({
      isSwappingEnabled: !this.state.isSwappingEnabled,
      selectedPlayers: []
    });
  }

  handleInputChange = (event, matchDetails, roundNumber, matchNumber) => {
    const target = event.target;
    const isChecked = target.type === 'checkbox' ? target.checked : target.value;
    const playerID = target.id;

    var selectedPlayers = this.state.selectedPlayers;
    if (isChecked) {
      if (selectedPlayers.length == 2) {
        toast.warning("At a time only two players can be swapped");
        target.checked = false;
      } else {
        selectedPlayers.push({
          teamID: playerID,
          matchDetails: matchDetails,
          roundNumber: roundNumber,
          matchNumber: matchNumber
        });
        this.setState({
          selectedPlayers: selectedPlayers
        });
      }
    } else if (selectedPlayers.length > 0) {
      selectedPlayers = selectedPlayers.filter(function (obj) { return obj.teamID !== playerID });
      this.setState({
        selectedPlayers: selectedPlayers
      });
    }

  }

  swapPositions = () => {
    this.isSwapped = true;
    var selectedPlayers = this.state.selectedPlayers;

    if (selectedPlayers[0].matchDetails._id === selectedPlayers[1].matchDetails._id) {
      toast.warning("You can not swipe two players within a same match.");
    } else {
      this.props.updateLoader(true);
      var updatedMatches = [];

      selectedPlayers.forEach((player, index) => {
        var participants = [];
        player.matchDetails.participants.forEach((teamDetails) => { participants.push(teamDetails._id) });
        player.matchDetails.participants = participants;
        selectedPlayers[index] = player;
        updatedMatches.push(player.matchDetails);
      });

      updatedMatches[0].participants = updatedMatches[0].participants.map( (team) => { return team === selectedPlayers[0].teamID ? selectedPlayers[1].teamID : team });
      updatedMatches[1].participants = updatedMatches[1].participants.map( (team) => { return team === selectedPlayers[1].teamID ? selectedPlayers[0].teamID : team });
  
      this.matchesApis.swapParticipants(updatedMatches).then(
        (res: AxiosResponse) => {
          this.enableDisableSwapping();

          this.setState({
            tournamentDetails: res.data.result
          });
          this.props.updateLoader(false);
          toast.success("Participants Swapped Successfully");
        }
      ).catch(
        (err: AxiosError) => {
          this.props.updateLoader(false);
          errorHandler(err);
        }
      );
    }
  }

  editTimeSlot = () => {
    this.props.history.push(`/organization/${this.props.orgId}/tournament/${this.props.id}/overview/structure/tournament-stages/${this.props.index}/slot-details/${this.state.selectedGroupe}`);
  }

  addNewMatch = () => {

    const data = {
      stageIndex: parseInt(this.props.index)
    }

    updateLoader(true);
    this.matchesApis.addMatchToFlexibleStage(this.state.tournamentDetails._id, data).then((res: AxiosResponse) => {
      toast.success("New match added successfully");
      this.updateStageDetails(res.data.result);
      this.onClickOpenConfirm(null)
      updateLoader(false);
    }).catch((reason: AxiosError) => {
      errorHandler(reason)
      updateLoader(false);
    });
  }

  deleteMatch = (matchId?, enableConfirmation?) => {

    if (enableConfirmation) {

      const deleteMatchDetails = {
        tournamentId: this.state.tournamentDetails?._id,
        data: {
          matchId: matchId,
          stageIndex: parseInt(this.props.index)
        }
      }

      this.onClickOpenConfirm(deleteMatchDetails);

    } else {

      updateLoader(true);
      this.matchesApis.deleteFlexibleMatch(this.state.deleteMatchDetails).then(
        (res: AxiosResponse) => {
          this.onClickOpenConfirm(null);
          this.updateStageDetails(res.data.result);
          updateLoader(false);
          toast.success("Match has been deleted successfully");
        }
      ).catch(
        (err: AxiosError) => {
          updateLoader(false);
          errorHandler(err);
          console.log("err - ",err)
        }
      );

    }

  }

  updateStageDetails = (data) => {
    var tournamentDetails = this.state.tournamentDetails;
    tournamentDetails.stages[this.props.index] = data;
    this.setState({
      tournamentDetails: tournamentDetails,
      deleteMatchDetails: null
    });
  }

  onClickOpenConfirm = (deleteMatchDetails) => {
    this.setState({
      enableConfirmation: !this.state.enableConfirmation,
      deleteMatchDetails: this.state.enableConfirmation ? null : deleteMatchDetails
    });
  }
  lastThreeShold = () => {
    let prevIndex = parseInt(this.props?.index) - 1;
    let lastData = this.state.tournamentDetails?.stages[prevIndex]
    let result = parseInt(lastData?.qualificationThreshold) * parseInt(lastData?.numberOfMatches)
    return result?result:0
  }
  render() {
    console.log("this.state.tournamentDetails.stages[this.props.index] :: ",this.state.tournamentDetails.stages[this.props.index])
    if ((this.state.tournamentDetails?.stages?.length > 0) && (this.state.tournamentDetails?.stages[this.props.index])) {

      var groupMatches = this.state.tournamentDetails.stages[this.props.index].groups[0];
      const isTeamPlayer = (this.state.tournamentDetails?.participantType === "TEAM" ? true : false);
      return (
        <section className="body-section" style={{ paddingBottom: '50px' }}>

          {/* Header Section */}
          <div className="container p-2 mt-lg-3">
            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading={this.state.tournamentDetails.stages[this.props.index].name} subHeading={<h1 className="stage-sub-heading text-center">( FFA Flexible Stage )</h1>} history={this.props.history} />
            <div className='d-flex overflow-auto mt-2 add-time justify-content-around'>
              {this.state.selectedPlayers.length == 2 ?
                <button className="add-btn fs-12 text-black me-2 mb-0 fw-600 rounded-15" onClick={this.swapPositions}>
                  <img src={swapIc} className='btn-size-20 me-2 mb-1' alt='' /> Swap Position
                </button>
                :
                <button className="fs-12 text-black me-2 add-btn mb-0 fw-600 rounded-15" onClick={this.enableDisableSwapping}>
                  <img src={swapIc} className='btn-size-20 me-2 mb-1' alt='' /> {this.state.isSwappingEnabled ? "Disable Swapping" : "Enable Swapping"}
                </button>
              }

              <button type="button" onClick={this.editTimeSlot} className="add-btn fs-12 text-black fw-600 rounded-15" >
                <img src={timestampIc} className='btn-size-20 me-2 mb-1' alt=''/> Edit Time Slot
              </button>
            </div>
          </div>

          <div className='w-100 d-flex overflow-auto' style={{ height: '-webkit-fill-available' }}>
            <FlexibleBrackets
              stageDetails={this.state.tournamentDetails.stages[this.props.index]}
              tournamentDetails={this.state.tournamentDetails}
              isSwappingEnabled={this.state.isSwappingEnabled}
              isTournamentOperator={true}
              openInviteModal={this.openInviteModal}
              addNewMatch={this.addNewMatch}
              deleteMatch={this.deleteMatch}
              onClickOpenConfirm={this.onClickOpenConfirm}
              handleInputChange={this.handleInputChange}
              index={parseInt(this.props?.index)}
              bookedSlots={(this.state.tournamentDetails.stages?.length > 1) ? this.lastThreeShold() : 0}
              participantMatch={this.state.tournamentDetails?.stages[parseInt(this.props.index)]?.maxParticipantsPerMatch}
            />
          </div>


          {/* Tournament Invitation Section */}
          <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
            <Modal.Body>
              <MatchInvite
                onClickOpenInvite={this.onClickOpenInvite}
                tournamentId={this.props.id}
                stageId={this.props.index}
                matchId={this.state.matchId}
                gameId={this.state.tournamentDetails?.gameId}
                participantType={this.state.tournamentDetails?.participantType}
                choice="Send Invite"
              />
            </Modal.Body>
          </Modal>

          {/* Delete Match Confirmation Modal  */}
          <Modal show={this.state.enableConfirmation} centered dialogClassName="accept-invite-modal">
            {this.state.deleteMatchDetails ?
              <Modal.Body>
                <ConfirmationModal confirm={this.state.deleteConfirm} onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.deleteMatch} />
              </Modal.Body>
              :
              <Modal.Body>
                <ConfirmationModal confirm={this.state.addConfirm} onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.addNewMatch} />
              </Modal.Body>
            }
          </Modal>

        </section>
      );

    } else {
      return (
        <div>
          <h1>No Data Found</h1>
        </div>
      );
    }

  }
}
