import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { checkDenStatus, getDenCoverPicture, getDenProfilePicture, unFollowDenProfile } from '../../../../utils/Den.utils';
import { DEN_PRIVATE_ASSETS, LOGOS, POST_INTERACTIONS } from '../../../../utils/Images.utils';
import { STYLE } from '../../../../utils/Style.utils';
import Image from '../../../common/Image';
import { AiFillEdit } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { RiUserShared2Line } from 'react-icons/ri';
import { DEN } from '../../../../utils/Strings.utils';
import { Modal } from 'react-bootstrap';
import ImagePreview from '../../../common/ImagePreview';

export default class DenProfileTopSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserDenProfile: props.currentUserDenProfile,
            denProfile: props.denProfile,
            isFollowing: props.isFollowing,
            previewImageDetails: {
                display: false,
                src: LOGOS.USER
            },
        };
    }

    componentWillReceiveProps(newProps){
        this.setState({
            currentUserDenProfile: newProps.currentUserDenProfile,
            denProfile: newProps.denProfile,
            isFollowing: newProps.isFollowing,
        })
    }

    goToEditPage = () => {

        switch (this.state.denProfile?.type) {
            case DEN.SEARCH.TYPES.CLAN:
                this.props.history.push(`/clan/view/${this.state.denProfile?.clanOrOrgId}`);
                break;

            case DEN.SEARCH.TYPES.ORG:
            
                this.props.history.push(`/organization/view/${this.state.denProfile?.clanOrOrgId}`);
                break;
        
            default:
                this.props.history.push(`/den-profile`)
                break;
        }
    }

    previewImage = (src, param) => {
        this.setState({
          previewImageDetails: {
            display: param,
            src: (param ? src : LOGOS.USER)
          }
        });
    }

    render() {

        const {currentUserDenProfile,denProfile}= this.state;
        const {detailsObj} = this.props;

        var profilePicture = denProfile?.type === DEN.SEARCH.TYPES.USER ? denProfile?.profilePicture : detailsObj?.logo;
        var coverPicture = denProfile?.type === DEN.SEARCH.TYPES.USER ? denProfile?.profileCover : detailsObj?.coverImage;
        
        return (
            <div className='den-profile-cover-section'>
                <div className='clanView-banner' onClick={() => this.previewImage((coverPicture?.url ? coverPicture?.url : LOGOS.USER), true)}>
                    <Image className="clanView-background" param={currentUserDenProfile? currentUserDenProfile?.name : "Test"}  src={coverPicture?.url} alt="" />
                    <Image className="clanView-fronImage" param={currentUserDenProfile? currentUserDenProfile?.name : "Test"}  src={coverPicture?.url} alt="" />
                </div>
                <div className='profile-card-bg' style={STYLE.DEN.PROFILE_CARD}>
                    <div className="row p-2 logo-top-margin">
                        <div className="col-lg-2 col-4" onClick={() => this.previewImage((profilePicture?.url ? profilePicture?.url : LOGOS.USER), true)}>
                            <Image src={profilePicture?.url} param={currentUserDenProfile? currentUserDenProfile?.name : "Test"} alt="clan logo" className="organization-logo rounded-circle position-relative" />
                        </div>

                        <div className="col-lg-7 col-8 mt-auto">
                            <div className='d-flex align-items-start'>
                            <div className='my-0 flex-grow-1'>
                                <span className='fs-16 fs-lg-20 fw-600 text-white my-0'>{`${this.state.currentUserDenProfile?.name}${this.state.currentUserDenProfile?.privateStatus?.age ? `, ${this.state.currentUserDenProfile?.age}` : ''}`}</span> <br />
                                {currentUserDenProfile.type === DEN.SEARCH.TYPES.USER && <span className='fs-12 fs-lg-16 fw-600 text-white-50 my-0'><Image src={DEN_PRIVATE_ASSETS[this.state.currentUserDenProfile.gender.toUpperCase()]} className={`${this.state.currentUserDenProfile?.privateStatus?.gender ? 'me-2' : 'd-none'}`} params='TEST' style={{height:'16px', width:'auto'}} /> <Image src={DEN_PRIVATE_ASSETS[this.state.currentUserDenProfile.foodPreference.toUpperCase()]} className={`${this.state.currentUserDenProfile?.privateStatus?.foodPreference ? 'me-2' : 'd-none'}`} params='TEST' style={{height:'16px', width:'auto'}} /> {currentUserDenProfile?.AKA}</span>} <br />
                                {/* {currentUserDenProfile.type === DEN.SEARCH.TYPES.USER && <span className='fs-12 fs-lg-16 fw-600 text-white-50'>{currentUserDenProfile?.profileTag}, {currentUserDenProfile?.miniDescription}, {currentUserDenProfile?.countryCode?.name}</span>} */}
                                {currentUserDenProfile.type === DEN.SEARCH.TYPES.USER && <span className='fs-12 fs-lg-16 fw-600 text-white-50'>{`${currentUserDenProfile?.profileTag}${this.state.currentUserDenProfile?.privateStatus?.country ? `, ${this.state.currentUserDenProfile?.countryCode?.name}` : ''}${this.state.currentUserDenProfile?.privateStatus?.city ? `, ${this.state.currentUserDenProfile?.city}` : ''}`}</span>}
                                {currentUserDenProfile.type !== DEN.SEARCH.TYPES.USER && <span className='fs-12 fs-lg-20 fw-600 text-white-50'><Image src={currentUserDenProfile.type === 'CLAN' ? POST_INTERACTIONS.AS.CLAN : POST_INTERACTIONS.AS.ORG} param={currentUserDenProfile.type === 'CLAN' ? 'CLAN' : 'ORG'} className="me-2" alt='' style={{height:'auto', width: '24px'}} />{currentUserDenProfile.type}</span>}
                            </div>
                            {!denProfile?.blocked?.includes(currentUserDenProfile?._id) && <div className='my-0 mx-2' onClick={()=> { 
                                    if(checkDenStatus(this.state.denProfile)){
                                        this.props.updateState('postActionsType','USER-ACTIONS')
                                        this.props.updateState('openPostActionModal',true);
                                    };
                                    
                                }
                            }><img src={POST_INTERACTIONS.ACTIONS.MORE} className='cursor-pointer' style={{width:'32px', height:'auto', marginTop:'16px'}} alt='' /></div>}
                            </div>
                        </div>
                        <div className='col-lg-3 col-12 mt-auto'>
                            <div className='row'>
                                <div className='col-4 col-lg-12 my-1'>
                                    {this.state.denProfile?._id?.toString() === this.props.params.currentUserDenId ? 

                                        <button className='den-profile-btn' onClick={()=> this.goToEditPage()}>
                                            <AiFillEdit className='me-2' /> Profile
                                        </button>
                                        
                                        :
                                        this.state.denProfile?.followings?.includes(currentUserDenProfile?._id) ?  
                                            <button  onMouseEnter={()=>this.props.updateState('isFollowing', false)}  onMouseOut={()=>this.props.updateState('isFollowing' ,true)}  className={`den-profile-btn ${this.state.isFollowing ? 'following-btn' : 'unfollowing-btn'} hover-zoomin`} onClick={()=> checkDenStatus(denProfile) ? unFollowDenProfile(denProfile?._id, currentUserDenProfile?._id,this.props.followedDenProfile) : null}>{this.state.isFollowing ?  'Following' : 'Unfollow'}</button> 
                                        : 
                                            this.props.getRequestedOrFollowButton(currentUserDenProfile) 
                                    }
                                </div>
                                <div className='col-4 col-lg-6'>
                                    <NavLink to={`/den/${this.props.params.denId}/profile-page/${this.props.params.currentUserDenId}/followers`} className='followers-box p-0 text-white'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FiUser className='me-2' />
                                            <div>
                                                <p className='fs-12 fw-600 my-0'>{currentUserDenProfile?.followers?.length}</p>
                                                <p className='fs-12 fw-600 my-0'>Followers</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className='col-4 col-lg-6'>
                                    <NavLink to={`/den/${this.props.params.denId}/profile-page/${this.props.params.currentUserDenId}/followings`} className='followers-box p-0 text-white'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <RiUserShared2Line className='me-2' />
                                            <div>
                                                <p className='fs-12 fw-600 my-0'>{currentUserDenProfile?.followings?.length}</p>
                                                <p className='fs-12 fw-600 my-0'>Following</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </div>
        )
    }
}
