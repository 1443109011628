import React, { Component } from 'react';
import { ERROR } from '../../utils/Strings.utils';

export default class NoRecordFound extends Component {

    constructor(props) {
        super(props);
        this.state = {
          message: props.message ? props.message : ERROR.NO_RECORD_FOUND,
        };
    }

    render() {
        return (
            <div className='body-section d-flex flex-column align-items-center justify-content-center'>
                <div className='no_record-found-page'>
                </div>
                <div className='text-center mt-3'>
                    <h1 className='fs-22 fw-800'>APOLOGIES!</h1>
                    <p className='fs-16 fw-700'>{this.state.message}</p>
                </div>
            </div>
        );
    }
}