import WalletApi from "../helper/wallet.api";
import { errorHandler, updateLoader } from "./common.utils";


const walletApi = new WalletApi();

export const makeWalletPayment = (data, next) => {
    updateLoader(true);
    walletApi.makeWalletPayment(data).then((res: AxiosResponse) => {
        next(res.data.result);
        updateLoader(false);
    }).catch((err: AxiosError) => {
        updateLoader(false);
        errorHandler(err);
    });
}
