import React, { Component } from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";

import "../../styles/activate-account.css";
import DefaultImage from '../../images/image 65.jpg'

export default class SingleOwnerLeave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: this.props.members,
            firstScreen: true,
            search: '',
            selectedMember: {},
            admin: this.props.members?.admin
        };
    }
    selectMember(data) {
        if(this.state.selectedMember === data){
            this.setState({ selectedMember: {} })
        }else{
            this.setState({ selectedMember: data })
        }
    }
    onSearchChange = (e) => {
        this.setState({ search: e.target.value })
        const unfilteredData = this.props.members.admin;
        let data = unfilteredData.filter(data =>
            data.username.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
        this.setState({ admin: data })
        if(!e.target.value){
            this.setState({ selectedMember: {} })
        }
    }
    assignOwner = () => {
        const data = this.state.members
        data.owner = []
        data.owner.push(this.state.selectedMember)
        let index = this.state.members.admin.map(el=>{return el.id}).indexOf(this.state.selectedMember.id)
        data.admin.splice(index, 1)
        this.props.onClick('assign',data)
    }
    render() {
        return (
            <div className='p-3'>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => { this.props.onClick(false) }} />
                {this.state.firstScreen ?
                    <div>
                        <p className="fw-bold fs-20 text-center text-white mb-3">Confirm</p>
                        {
                            (this.state?.admin && this.state?.admin?.length) ?
                                <div>
                                    <p className="fs-14 fw-600 text-center text-white-50">As an only Owner  of this {this.props.type}, you can choose to assign a owner or deactivate and leave</p>
                                    <div className="d-flex justify-content-center">
                                        <button className="white-flat-btn fw-bold py-2 px-4 m-auto mt-3 d-block" onClick={() => { this.setState({ firstScreen: false }) }} >Assign</button>
                                    </div>
                                </div>
                                :
                                <p className="fs-14 fw-600 text-center text-white-50">As an only Owner  of this {this.props.type}, you can choose to deactivate or delete & leave</p>
                        }


                        <div className="d-flex justify-content-evenly mt-3">
                            <button className="white-flat-btn fw-bold py-2 px-4" onClick={() => { this.props.onClick('deactivate', this.state.members) }} >Deactivate</button>
                            <button className="white-flat-btn fw-bold py-2 px-4" onClick={() => { this.props.onClick('delete') }}>Delete & Leave</button>
                        </div>
                    </div>
                    :
                    <div>
                        <p className="fw-bold fs-20 text-center text-white mb-3">Assign Owner</p>
                        <p className="fs-14 fw-600 text-center text-white-50">Assign From the existing members</p>
                        <div>
                            <input
                                type="text"
                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white text-truncate fs-14 height-45"
                                value={this.state.search}
                                placeholder="Search"
                                id="search"
                                autoComplete="off"
                                onChange={this.onSearchChange}
                            />
                        </div>
                        <div className="epz-member-scroll">
                            {
                                this.state.search ?
                                    this.state?.admin.map((el, i) => {
                                        return (
                                            <div className="row mt-2" key={i}>
                                                <div className="col-2">
                                                    <div className="owner-avatar" style={{ backgroundImage: `url(${el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage})` }}></div>
                                                </div>
                                                <div className="col-8">
                                                    <div className="owner-name fs-16 fw-500 text-white float-start mt-1">{el?.name}</div>
                                                </div>
                                                <div className="col-2">
                                                    <button type="button" className="epz-check" data-dismiss="modal" aria-label="Close" onClick={() => { this.selectMember(el) }}>
                                                        {(this.state.selectedMember?.id === el.id) ?
                                                            <AiOutlineCheck className="check" /> : null}
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            <button disabled={!this.state.selectedMember?.id} className="white-flat-btn fw-bold py-2 px-4 m-auto mt-3 d-block" onClick={() => { this.assignOwner() }} >Assign</button>
                        </div>
                    </div>
                }

            </div>
        )
    }
}
