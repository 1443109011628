import React, { Component } from "react";
import { MdRefresh, MdZoomOutMap } from "react-icons/md";
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import "../../../../../styles/brackets.css";
import "../../../../../styles/Tournaments.css";
import TournamentHeader from "../../common/TournamentHeader";
import structureIc from '../../../../../images/icon/stageImg.png';
import swapIc from '../../../../../images/icon/swap-ic.png';
import timestampIc from '../../../../../images/icon/timestamp-ic.png';
import { NavLink } from 'react-router-dom';

import { Button, Modal } from "react-bootstrap";
import { HiUserAdd } from "react-icons/hi";
import { errorHandler, getMonthAndDate, getTournamentAdditionalData, getTournamentData, months } from "../../../../../utils/common.utils";
import MatchInvite from "../../../../popup/MatchInvite";
import { toast } from 'material-react-toastify';
import TournamentApis from "../../../../../helper/tournament.api";
//import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { IoIosArrowBack, IoIosCheckbox } from "react-icons/io";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { FaLayerGroup } from "react-icons/fa";
import BracketGroupRounds from "./BracketGroupRounds";
import BracketGroupDoubleRounds from "./BracketGroupDoubleRounds";
import SeedingSwitch from "../../common/SeedingSwitch";


export default class BracketGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.state.tournamentDetails,
      structure: props.state.structure,
      scale: 1.1,
      openInvite: false,
      matchId: -1,
      selectedPlayers: [],
      isSwappingEnabled: false,
      displayRounds: false,
      groupMatches: null,
      selectedGroupe: 0,
      isWinningBrackets: true,
      placeOccupied: 0,
      enableSeeding: false,
      selectedGroupeIndex: 0
    };

    this.prevValue = 0;
    this.marginTopDiv = 35;
    this.maxWidth = 0;


    this.tournamentApis = new TournamentApis();

    this.isSwapped = false;
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      tournamentDetails: newProps.state.tournamentDetails,
      structure: newProps.state.structure,
    });


  }

  zoom = (key) => {
    if ((this.state.scale * 1.1) === 1.1) {
      this.maxWidth = 0;
    }
    switch (key) {
      case 'In':
        this.setState({ scale: this.state.scale * 1.1 });
        break;
      case 'Out':
        this.maxWidth = ((this.maxWidth === 0) ? (this.maxWidth + 10) : (this.maxWidth + this.maxWidth + 1));
        this.setState({ scale: this.state.scale / 1.1 });
        break;
      default:
        this.setState({ scale: 1.1 });
        break;
    }
  }

  getMarginTopForRounds = (index) => {
    var marginTop = 0;
    switch (index) {
      case 0:
        marginTop = 0;
        break;
      case 1:
        marginTop = 35;
        break;
      case 2:
        marginTop = 105;
        break;
      default:
        marginTop = ((this.prevValue * 2) + 35);
        break;
    }
    return marginTop;
  }

  calculateMatches = (num, threshold) => {
    var stages = 1;
    var arr = [num];
    while (num !== 1) {
      num = (num / 2);
      arr.push(num);
      stages++;
      if (num === threshold) break;
    }
    return arr;
  }

  onClickOpenInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite
    })
  }

  openInviteModal = (e, groupIndex) => {
    e.preventDefault();
    this.setState({
      openInvite: true,
      matchId: groupIndex//this.state.tournamentDetails.stages[this.props.index].groups[0][1][index]._id
    })
  }

  enableDisableSwapping = () => {
    this.setState({
      isSwappingEnabled: !this.state.isSwappingEnabled,
      selectedPlayers: []
    });
  }

  handleInputChange = (event, groupIndex, player) => {
    const target = event.target;
    const isChecked = target.type === 'checkbox' ? target.checked : target.value;
    const playerID = target.id;

    var selectedPlayers = this.state.selectedPlayers;
    if (isChecked) {

      if (selectedPlayers.length == 2) {
        toast.warning("At a time only two players can be swapped");
        target.checked = false;
      } else {
        let obj = {
          groupIndex: groupIndex,
          playerID: player._id
        };

        selectedPlayers.push(obj);
        this.setState({
          selectedPlayers: selectedPlayers
        });
        target.checked = true;
      }

    } else {
      selectedPlayers = selectedPlayers.filter(function (obj) {
        return obj.playerID !== player._id;
      });
      this.setState({
        selectedPlayers: selectedPlayers
      });

    }

  }

  swapPositions = () => {

    let data = {
      tournamentId: this.props.id,
      stageIndex: parseInt(this.props.index),
      sourceGroupIndex: this.state.selectedPlayers[0].groupIndex,
      sourceParticipantId: this.state.selectedPlayers[0].playerID,
      targetGroupIndex: this.state.selectedPlayers[1].groupIndex,
      targetParticipantId: this.state.selectedPlayers[1].playerID
    }

    if (data.sourceGroupIndex === this.state.selectedPlayers[1].groupIndex) {
      toast.warning("You can not swipe two players within a same group.");
    } else {
      this.isSwapped = true;


      this.props.updateLoader(true);
      this.tournamentApis.swapParticipantsBetweenGroups(data)
        .then((res: AxiosResponse) => {
          var tournamentDetails = this.state.tournamentDetails;
          tournamentDetails.stages[this.props.index].groups[this.state.selectedPlayers[0].groupIndex] = res.data.result.sourceGroup;
          tournamentDetails.stages[this.props.index].groups[this.state.selectedPlayers[1].groupIndex] = res.data.result.targetGroup;
          ;

          this.setState({
            tournamentDetails: tournamentDetails,
            selectedPlayers: [],
            isSwappingEnabled: false
          });
          this.props.updateLoader(false);
          toast.success("Participants Swapped Successfully");
        })
        .catch((err: AxiosError) => {
          this.props.updateLoader(false);
          errorHandler(err);
        });
    }



  };


  refresh = () => {
    setTimeout(() => {
      this.zoom('Reset');
    }, 1000);
  }

  hideOrShowRounds = (index?) => {
    const availableStageIndex = (((this.props.index-1) >= 0) ? (this.props.index-1) : 0);
    let placesToReserve = this.state.tournamentDetails?.stages[availableStageIndex].qualificationThreshold - (this.state.tournamentDetails?.stages[this.props.index].maxParticipantsPerGroup * index)
    // alert(this.state.tournamentDetails?.stages[this.props.index].qualificationThreshold + " - (" + this.state.tournamentDetails?.stages[this.props.index].maxParticipantsPerGroup + " * " + index + ")" + " = " + (this.state.tournamentDetails?.stages[this.props.index - 1].qualificationThreshold - (this.state.tournamentDetails?.stages[this.props.index].maxParticipantsPerGroup * index)))
    if (!this.state.isSwappingEnabled) {
      this.setState({
        selectedGroupe: index,
        placeOccupied: placesToReserve
      })

      var groupMatches;
      if (index !== undefined) {
        groupMatches = this.state.tournamentDetails?.stages[this.props.index].groups[index]

      } else {
        groupMatches = null
      }

      
      this.setState({
        displayRounds: !this.state.displayRounds,
        groupMatches: groupMatches,
        selectedGroupeIndex: index
      });
    }
  }

  editTimeSlot = () => {
    this.props.history.push(`/organization/${this.props.orgId}/tournament/${this.props.id}/overview/structure/tournament-stages/${this.props.index}/slot-details/${this.state.selectedGroupe}`);
  }

  getTeamMembers = (groupDetails, type) => {
    console.log(type+"--- groupDetails : ",groupDetails)
    var arr = [];


    if(type === 'SINGLE'){
      Object.keys(groupDetails).map((key) => {
        groupDetails[key].map((obj) => {
          obj.participants.map((team) => {
            console.log("obj :: ",obj)
            if(obj["resultAdded"] === undefined){
              team.resultAdded =  obj.resultAdded; 
            }
            arr.push(team)
          });
        });
      });
  
      var jsonObject = arr.map(JSON.stringify);
      var uniqueSet = new Set(jsonObject);
      var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
  

      return uniqueArray;
    }else{

      groupDetails.map((match,i) => {
        match.participants.map((team) => { 
          if(team){
            team.resultAdded =  match?.resultAdded;
            arr.push(team) 
          }

        });
      });
      var jsonObject = arr.map(JSON.stringify);
      var uniqueSet = new Set(jsonObject);
      var uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      return uniqueArray;
    

    }



  };

  isAlreadyExist = (id) => {

    var selectedPlayers = this.state.selectedPlayers;
    var result = false;

    selectedPlayers.forEach((team, i) => {
      if (team.playerID === id) {
        result = true
      }
    });

    return result;

  }

  getSingleEliminationsGroups = (stageDetails) => {
    
    const isTeamPlayer = this.state.tournamentDetails?.participantType === "TEAM" ? true : false;
    var groups = [...Array(this.state.tournamentDetails?.stages[this.props.index]?.maxNumberOfGroups)].map((elementInArray, index) => {

      const teamMembers = this.getTeamMembers(this.state.tournamentDetails?.stages[this.props.index].groups[index], 'SINGLE');
      console.log("---- teamMembers :: ",teamMembers)
      const maxParticipantsPerGroup = this.state.tournamentDetails?.stages[this.props.index]?.maxParticipantsPerGroup;
      if (maxParticipantsPerGroup <= 4) {
        return (
          <div className="col-lg-4 col-12" key={index}>
            <div onClick={() => this.hideOrShowRounds(index)} className="card mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn h-auto" style={{ alignItems: 'center', marginLeft: '-5px' }} >
              <span className="match-tag align-self-center py-1" style={{ fontSize: '14px' }}>{stageDetails?.groupsName[index]}</span>

              <div className="d-flex justify-content-between align-items-center w-100 px-2 pb-3">
                <div className="d-flex flex-column">
                  {
                    [...Array(maxParticipantsPerGroup)].map((elementInArray, i) => {
                      if (teamMembers[i]) {
                        return (
                          <div className="d-flex align-items-center" key={i}>
                            {this.state.isSwappingEnabled ?
                              <input className="me-2" type='checkbox' disabled={teamMembers[i]?.resultAdded} id={`${index}.${i}`} onChange={(e) => this.handleInputChange(e, index, teamMembers[i], true)} />
                              :
                              <img src={isTeamPlayer ? teamMembers[i]?.logo?.url : teamMembers[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt=''/>
                              
                            }
                            <span className="player-name my-1 " key={i}> { isTeamPlayer ? teamMembers[i].name : teamMembers[i].inGameName} </span>
                          </div>
                        )
                      } else {
                        return (<span className="player-name my-1 vacant" key={i}> Vacant</span>)
                      }

                    })
                  }
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="col-lg-4 col-12" key={index}>
            <div onClick={() => this.hideOrShowRounds(index)} className="card mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn h-auto" style={{ alignItems: 'center', marginLeft: '-5px' }} >
              <span className="match-tag align-self-center py-1" style={{ fontSize: '14px' }}>{stageDetails?.groupsName[index]}</span>

              <div className="d-flex justify-content-between align-items-center w-100 px-2 pb-3">
                <div className="d-flex flex-column">
                  {
                    [...Array(this.state.isSwappingEnabled ? maxParticipantsPerGroup : 4)].map((elementInArray, i) => {
                      if (teamMembers[i]) {
                        return (
                          <div className="d-flex align-items-center" key={i}>
                            {this.state.isSwappingEnabled ?
                              <input className="me-2" type='checkbox' disabled={teamMembers[i]?.resultAdded} id={`${index}.${i}`} onChange={(e) => this.handleInputChange(e, index, teamMembers[i], true)} />
                              :
                              <img src={isTeamPlayer ? teamMembers[i]?.logo?.url : teamMembers[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt=''/>
                            
                            }
                            <span className="player-name my-1 " key={i}> { isTeamPlayer ? teamMembers[i].name : teamMembers[i].inGameName} </span>
                          </div>
                        )
                      } else {
                        return (<span className="player-name my-1 vacant" key={i}> Vacant</span>)
                      }

                    })
                  }
                  <a className={`more-count ${ this.state.isSwappingEnabled ? 'd-none': null}`}>+{maxParticipantsPerGroup - 4} more</a>

                </div>

              </div>
            </div>
          </div>
        )
      }
    });

    return groups;
  }

  getDoubleEliminationsGroups = (stageDetails) => {
    const isTeamPlayer = this.state.tournamentDetails?.participantType === "TEAM" ? true : false;
    var groups = [...Array(this.state.tournamentDetails?.stages[this.props.index]?.maxNumberOfGroups)].map((elementInArray, index) => {
      
      
      const teamMembers = this.getTeamMembers(this.state.tournamentDetails?.stages[this.props.index].groups[index][0][1], 'Double');
      const maxParticipantsPerGroup = this.state.tournamentDetails?.stages[this.props.index]?.maxParticipantsPerGroup;
      if (maxParticipantsPerGroup <= 4) {
        return (
          <div className="col-lg-4 col-12" key={index}>
            <div onClick={() => this.hideOrShowRounds(index)} className="card mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn h-auto" style={{ alignItems: 'center', marginLeft: '-5px' }} >
              <span className="match-tag align-self-center py-1" style={{ fontSize: '14px' }}>{stageDetails?.groupsName[index]}</span>

              <div className="d-flex justify-content-between align-items-center w-100 px-2 pb-3">
                <div className="d-flex flex-column">
                  {
                    [...Array(maxParticipantsPerGroup)].map((elementInArray, i) => {
                      if (teamMembers[i]) {
                        return (
                          <div className="d-flex align-items-center" key={i}>
                            {this.state.isSwappingEnabled ?
                              <input className="me-2" type='checkbox' disabled={teamMembers[i]?.resultAdded} id={`${index}.${i}`} onChange={(e) => this.handleInputChange(e, index, teamMembers[i], true)} />
                              :
                              <img src={isTeamPlayer ? teamMembers[i]?.logo?.url : teamMembers[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt=''/>
                                    
                            }
                            <span className="player-name my-1 " key={i}> {isTeamPlayer ? teamMembers[i].name : teamMembers[i].inGameName}55</span>
                          </div>
                        )
                      } else {
                        return (<span className="player-name my-1 vacant" key={i}> Vacant</span>)
                      }

                    })
                  }
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="col-lg-4 col-12" key={index}>
            <div onClick={() => this.hideOrShowRounds(index)} className="card mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn h-auto" style={{ alignItems: 'center', marginLeft: '-5px' }} >
              <span className="match-tag align-self-center py-1" style={{ fontSize: '14px' }}>{stageDetails?.groupsName[index]}</span>

              <div className="d-flex justify-content-between align-items-center w-100 px-2 pb-3">
                <div className="d-flex flex-column">
                  {
                    [...Array(this.state.isSwappingEnabled ? maxParticipantsPerGroup : 4)].map((elementInArray, i) => {
                      if (teamMembers[i]) {
                        return (
                          <div className="d-flex align-items-center" key={i}>
                            {this.state.isSwappingEnabled ?
                              <input className="me-2" type='checkbox' disabled={teamMembers[i]?.resultAdded} id={`${index}.${i}`} onChange={(e) => this.handleInputChange(e, index, teamMembers[i], true)} />
                              :
                              <img src={isTeamPlayer ? teamMembers[i]?.logo?.url : teamMembers[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt=''/>
                            
                            }
                            <span className="player-name my-1 " key={i}> { isTeamPlayer ? teamMembers[i].name : teamMembers[i].inGameName} </span>
                          </div>
                        )
                      } else {
                        return (<span className="player-name my-1 vacant" key={i}> Vacant</span>)
                      }

                    })
                  }
                  <a className={`more-count ${ (this.state.isSwappingEnabled || ((maxParticipantsPerGroup - 4) <= 0)) ? 'd-none': null}`}>+{maxParticipantsPerGroup - 4} more</a>

                </div>
              </div>
            </div>
          </div>
        )
      }
    });

    return groups;
  }

  getSwitchButton = () => {
    if (this.state.tournamentDetails?.stages[this.props.index].bracketType !== "SINGLE") {
      if (this.state.isWinningBrackets) {
        return <Button onClick={() => this.setState({ isWinningBrackets: false })} className='score-showdown-btn px-2 fs-14 m-auto'>Show Loosers Bracket</Button>
      } else {
        return <Button onClick={() => this.setState({ isWinningBrackets: true })} className='score-showdown-btn px-2 fs-14 m-auto'>Show Winners Bracket</Button>
      }
    }

  }

  enableDisableSeeding = () =>{
    this.setState({ enableSeeding: !this.state.enableSeeding });
  }

  render() {
    
    if (this.state.tournamentDetails?.stages?.length > 0 && this.state.tournamentDetails?.stages[this.props.index]) {

      var stageDetails = this.state.tournamentDetails?.stages[this.props.index];

      return (
        <section className="body-section" style={{ paddingBottom: '50px' }}>

          {/* Header Section */}
          <div className="p-2 ">
            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading={this.state.tournamentDetails.stages[this.props.index].name} subHeading={<h1 className="stage-sub-heading text-center">( Bracket Groups {this.state.displayRounds ? `-${this.state.structure?.groupsName[this.state.selectedGroupe]}` : `- ${stageDetails.bracketType}`})</h1>} history={this.props.history} />
            <div className='d-flex overflow-auto mt-2 add-time justify-content-around'>
              {this.state.displayRounds ?
                <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.hideOrShowRounds}>
                  <FaLayerGroup className="btn-size-20 me-2 mb-1" /> Back to Groups
                </button>
                :
                (this.state.selectedPlayers.length == 2 ?
                  <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.swapPositions}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> Swap Position
                  </button>
                  :
                  <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.enableDisableSwapping}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> {this.state.isSwappingEnabled ? "Disable Swapping" : "Enable Swapping"}
                  </button>
                )
              }

              <button type="button" onClick={this.editTimeSlot} className="add-btn fs-12 text-black fw-600 rounded-15" >
                <img src={timestampIc} className='btn-size-20 me-2 mb-1' alt=''/> Edit Time Slot
              </button>
            </div>
            {(parseInt(this.props.index) === 0 && this.state.displayRounds) ? <SeedingSwitch enableDisableSeeding={this.enableDisableSeeding} enableSeeding={this.state.enableSeeding} /> : null}
               
            {((this.state.tournamentDetails?.stages[this.props.index].bracketType === "SINGLE") && (this.state.displayRounds)) ?
              this.getSwitchButton()
              :
              null
            }

          </div>

          <div className='container' >
            {this.state.displayRounds ?
              <div id="tournament-date 22">
                {(this.state.tournamentDetails?.stages[this.props.index].bracketType === "SINGLE") ?
                  <BracketGroupRounds enableDisableSeeding={this.enableDisableSeeding} enableSeeding={this.state.enableSeeding} index={this.props.index} tournamentDetails={this.state.tournamentDetails} groupMatches={this.state.groupMatches} updateLoader={this.props.updateLoader} groupName={this.state.structure?.groupsName[this.state.selectedGroupe]} selectedGroupeIndex={this.state.selectedGroupe} placeOccupied={this.state.placeOccupied} />
                  :
                  <BracketGroupDoubleRounds enableDisableSeeding={this.enableDisableSeeding} enableSeeding={this.state.enableSeeding} index={this.props.index} tournamentDetails={this.state.tournamentDetails} isWinningBrackets={this.state.isWinningBrackets ? 0 : 1} groupMatches={this.state.groupMatches} updateLoader={this.props.updateLoader} groupName={this.state.structure?.groupsName[this.state.selectedGroupe]} selectedGroupeIndex={this.state.selectedGroupe} placeOccupied={this.state.placeOccupied} />
                }
              </div>
              :
              <div className="row pt-3 px-3"  >
                {
                  (stageDetails.type === "DUEL" && stageDetails.subType === "Bracket Groups" && stageDetails.bracketType === "DOUBLE" ?
                    this.getDoubleEliminationsGroups(stageDetails)
                    :
                    this.getSingleEliminationsGroups(stageDetails)
                  )

                }
              </div>
            }
          </div>

          {/* Tournament Invitation Section */}
          <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
            <Modal.Body>
              <MatchInvite
                onClickOpenInvite={this.onClickOpenInvite}
                tournamentId={this.props.id}
                stageId={this.props.index}
                matchId={this.state.matchId}
                gameId={this.state.tournamentDetails?.gameId}
                participantType={this.state.tournamentDetails?.participantType}
                choice="Send Invite"
              />
            </Modal.Body>
          </Modal>

        </section>
      );

    } else {
      return (
        <div>
          <h1>No Data Found</h1>
        </div>
      );
    }

  }
}
