import React, { Component } from 'react';
import validator from 'validator';
//icons 
import { FiEye, FiEyeOff, FiEdit, FiRefreshCw } from "react-icons/fi";
import { AiFillDelete, AiFillPlusCircle, AiOutlineLock, AiOutlineMail, AiOutlineMinusCircle, AiOutlinePoweroff } from 'react-icons/ai';
import { FaGlobeAfrica, FaUserAlt, FaUserCircle } from 'react-icons/fa';
import { IoCaretForwardSharp } from 'react-icons/io5';
import { HiOutlineTrash } from "react-icons/hi";
//images
import { toast } from 'material-react-toastify';
import { Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import {SOCIAL_MEDIA_ICONS} from '../../utils/Images.utils';
import Sponsor from '../popup/Sponsor';
import ConfirmationModal from '../popup/ConfirmationModal';
import { errorHandler } from '../../utils/common.utils';

export default class OrgDenStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            extraDetails: SOCIAL_MEDIA_ICONS.MEDIA_ASSETS,
            openSocialMediaModal: false,
            sponsorImage: null,
            openSponsor: false,
            reqErrorMsg: "",
            create: true,
            invalidImage: null,
            sponsorForUpdate: null,
            sponsorIndex: null,
            openConfirm: false,
            deleteSponsorId: null,
            confirm: {},
            denProfile: props.denProfile ? props.denProfile : null,
            socialMedia: props.denProfile?.socialMedia ? props.denProfile?.socialMedia : []
        }
        this.sponsorArray = [];
    }
    componentDidMount() {}

    componentWillReceiveProps(newProps) {
        this.setState({
            denProfile: newProps.denProfile ? newProps.denProfile : null,
            socialMedia: newProps.denProfile?.socialMedia ? newProps.denProfile?.socialMedia : []
        })
    }

    showFields = (e, i, type) => {
        e.preventDefault()
        const newArray = this.state.extraDetails
        let temp = this.state.extraDetails[i]
        temp.isChecked = type
        if (type === false) {
            temp.value = ''
        }
        newArray[i] = temp
        this.setState({ extraDetails: newArray })
    }

    updateDetails = (e, i) => {
        let { extraDetails } = this.state;
        extraDetails[i].value = e.target.value
        this.setState({ extraDetails });
    }

    getAddButton = () => {
        const { extraDetails } = this.state
        const booleanArray = extraDetails.map(detail => detail.isChecked)
        if (booleanArray.includes(true)) {
            return true
        }
        return false
    }

    validateDetails = () => {
        let errors = 0
        const filledFields = this.state.extraDetails
        filledFields.map((field) => {
            if (field.value !== '') {
                // const res = field.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (!field.value.includes(field.id)) {
                    field.error = 'Enter url of respective field';
                    errors += 1
                }
                else if ((validator.isURL(field.value)) && (field.value.includes(field.id))) {
                    field.error = ''
                }
                else {
                    errors += 1
                    field.error = 'Enter a valid url'
                }
            }
        })
        this.setState({ socialMedia: filledFields })
        console.log('errors:', errors)
        if (errors > 0) {
            return false
        } else {
            return true
        }
    }

    addDetails = () => {
        if (this.validateDetails()) {
            const { extraDetails } = this.state
            this.setState({
                openSocialMediaModal: !this.state.openSocialMediaModal
            })
        }
    }

    sponsorDeleteMsg = (name) => {
        return (
          <p className="fs-14 fw-600 text-center text-white-50">
            Are you sure you want to delete {name} from sponsors?
          </p>
        );
    };

    // Toggle Modals
    toggleModal = (key) => {
        this.setState({ [key]: !this.state[key] });
    };

    // To close sponsor modal
    closeSponsor = () => {
        this.setState({
        sponsorForUpdate: null,
        openSponsor: false,
        sponsorImage: null,
        });
    };

    // To Open confirmation popup
    onClickOpenConfirm = (key?, msg?) => {
        const data = {
        msg: msg ? msg : "",
        key: key ? key : "",
        };
        this.setState({
        openConfirm: !this.state.openConfirm,
        confirm: data,
        });
    };

    // Add Sponsor
    addSponsor = (values, logo) => {
        this.setState({
        invalidImage: null,
        });

        const data = {
        name: values.name,
        type: values.type,
        logo: logo,
        isDeleted: false,
        };
        this.sponsorArray.push(data);
        this.setState({ openSponsor: false, sponsorImage: null });
        this.closeSponsor();
    };

    // Update Sponsor
    updateSponsor = (values, logo) => {
        if (!logo) {
        this.setState({ reqErrorMsg: "Upload sponsor image" });
        return false;
        }

        const data = this.state.sponsorForUpdate;
        data.logo = logo;
        data.name = values.name;
        data.type = values.type;
        this.sponsorArray[this.state.sponsorIndex] = data;

        if (values.id) {
        this.props.updateLoader(true);
        this.microWebSiteService.updateSponsor(data).then((res: AxiosResponse) => {
            this.props.updateLoader(false);
            this.closeSponsor();
            toast.success("Sponsor updated successfully");
        }).catch((err: AxiosError) => {
            this.closeSponsor();
            this.props.updateLoader(false);
            errorHandler(err, this.props.logout);
        });
        } else {
        this.closeSponsor();
        }

    };

    // To Delete Sponsor Details
    deleteSponsor = () => {
        this.props.updateLoader(true);
        this.microWebSiteService.deleteSponsor(this.state.deleteSponsorId).then((res: AxiosResponse) => {
        this.sponsorArray.splice(this.state.sponsorIndex, 1);
        this.setState({
            sponsorIndex: null,
            deleteSponsorId: null,
            openConfirm: false,
        });
        this.props.updateLoader(false);
        toast.success("Sponsor deleted succesfully");
        }).catch((err: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
        });
    };

    // To Edit Sponsor Details
    openEditSponsor = (el, i) => {
        const data = el;
        data.index = i;
        this.setState({
        sponsorForUpdate: data,
        sponsorIndex: i,
        sponsorImage: el.logo,
        });
    };

    // Open confirmation modal
    confirmed = (key) => {
        if (key === "deleteSponsor") {
        this.deleteSponsor();
        } else if (key === "deleteTempSponsor") {
        this.sponsorArray.splice(this.state.sponsorIndex, 1);
        this.setState({ openConfirm: false })
        }
    };

    updatePublicStatus = (key, val) => {
        const {denProfile} = this.state;
        const publicStatus = denProfile.publicStatus;
        const updatedStatus = {...publicStatus, [key]: val};
        denProfile.publicStatus = updatedStatus;
        this.setState({denProfile});
    }

    updateDenStatus = (val) => { 
        const {denProfile} = this.state;
        denProfile.isPublic = val;
        this.setState({denProfile})
    }

    submitDenData = () => {
        const {denProfile} = this.state;
        console.log('initial media ::', this.state.socialMedia);
        console.log('final media ::',this.state.socialMedia.filter(el =>  el.isChecked && el.value!==''));
        denProfile['socialMedia'] = this.state.socialMedia.filter(el =>  el.isChecked && el.value!=='');
        denProfile['sponsors'] = this.sponsorArray;
        this.props.setDenData(denProfile, 'Organization den profile updated successfully');
    }

    deleteDenProfile = () => { 
        const {denProfile} = this.state;
        denProfile.isDeleted = true;
        this.props.setDenData(denProfile, 'Organization den profile deleted successfully');
    }

    render() {
        const {denProfile} = this.state;
        //console.log('Social Media ::', this.state.socialMedia);
        return(
            <div className="catalina-blue my-3 p-3">
                <div className="row">

                    <div className="col-12 col-md-6">
                        <NavLink to={`/den/${denProfile?._id}`} style={{color: 'white'}}>
                            <div className="den-gradient-btn d-flex align-items-center px-3 py-1 my-2">
                                <p className="fs-16 fw-500 my-0 flex-grow-1">GO TO DEN PAGE</p>
                                <IoCaretForwardSharp />
                            </div>
                        </NavLink>
                        <NavLink to={`/den/${this.state.denProfile?._id}/blocked-users`} style={{color: 'white'}}>
                            <div className="den-gradient-btn d-flex align-items-center px-3 py-1 my-2">
                                <p className="fs-16 fw-500 my-0 flex-grow-1">BLOCKED USERS</p>
                                <IoCaretForwardSharp />
                            </div>
                        </NavLink>
                        <NavLink to={`/den/${this.state.denProfile?._id}/follow-request`} style={{color: 'white'}}>
                            <div className="den-gradient-btn d-flex align-items-center px-3 py-1 my-2">
                                <p className="fs-16 fw-500 my-0 flex-grow-1">FOLLOW REQUESTS</p>
                                <IoCaretForwardSharp />
                            </div>
                        </NavLink>
                        <NavLink to={`/den/${this.state.denProfile?._id}/posts`} style={{color: 'white'}}>
                            <div className="den-gradient-btn d-flex align-items-center px-3 py-1 my-2">
                                <p className="fs-16 fw-500 my-0 flex-grow-1">POSTS</p>
                                <IoCaretForwardSharp />
                            </div>
                        </NavLink>
                        <NavLink to={`/den/${this.state.denProfile?._id}/top-fans`} style={{color: 'white'}}>
                            <div className="den-gradient-btn d-flex align-items-center px-3 py-1 my-2">
                                <p className="fs-16 fw-500 my-0 flex-grow-1">TOP FANS</p>
                                <IoCaretForwardSharp />
                            </div>
                        </NavLink>

                        {/* Broadcasting */}
                        <div className='my-3'>
                            <div className="d-flex justify-content-center align-items-center my-2">
                                <hr className="hr-line flex-grow-1" />
                                <p className="fs-16 fw-600 text-white-50 my-0 mx-2">Broadcasting</p>
                                <hr className="hr-line flex-grow-1" />
                            </div>
                            {this.state.socialMedia?.map((details, i) => {
                                if (details.value !== '' && details.error === '') {
                                    return (
                                        <div className="my-2" key={i}>
                                            <img src={details.inlineUrl} className="btn-size-20 ms-3 position-absolute" style={{ marginTop: '12px' }} alt="" />
                                            <input
                                                type='text'
                                                placeholder="SocialMedia.com/help"
                                                className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 height-45"
                                                value={details.value}
                                                disabled
                                            />
                                            <AiOutlineMinusCircle className="btn-size-20 me-3 text-white flex-shrink-0 float-end" style={{ marginTop: '-30px' }} onClick={e => { this.showFields(e, i, false) }} />
                                        </div>
                                    )
                                }

                            })}
                            <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-2" onClick={(e) => { e.preventDefault(); this.setState({ openSocialMediaModal: !this.state.openSocialMediaModal }) }}>
                                <button className="btn">
                                    <AiFillPlusCircle className="btn-size-25 text-white" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className='text-start'>
                            <div className="d-flex justify-content-center align-items-center my-2">
                                <hr className="hr-line flex-grow-1" />
                                <p className="fs-16 fw-600 text-white-50 my-0 mx-2">Public Stats</p>
                                <hr className="hr-line flex-grow-1" />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className='fs-20 fw-600'>Tournaments Organized</label>
                                {/* { true ? <FiEye /> : <FiEyeOff /> } */}
                                { denProfile?.publicStatus?.tournamentCount ? <FiEye className='cursor-pointer' onClick={() => this.updatePublicStatus('tournamentCount', false)} /> : <FiEyeOff className='cursor-pointer' onClick={() => this.updatePublicStatus('tournamentCount', true)} /> }
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className='fs-20 fw-600'>Spotlight Collection</label>
                                { denProfile?.publicStatus?.spotlightCollection ? <FiEye className='cursor-pointer' onClick={() => this.updatePublicStatus('spotlightCollection', false)} /> : <FiEyeOff className='cursor-pointer' onClick={() => this.updatePublicStatus('spotlightCollection', true)} /> }
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className='fs-20 fw-600'>Game Stats</label>
                                { denProfile?.publicStatus?.gameStats ? <FiEye className='cursor-pointer' onClick={() => this.updatePublicStatus('gameStats', false)} /> : <FiEyeOff className='cursor-pointer' onClick={() => this.updatePublicStatus('gameStats', true)} /> }
                            </div>
                        </div>
                        {/* Den Status */}
                        <div className="my-2">
                            <label className='fs-20 fw-600'>Den Status</label>
                            <div className="row my-2">
                                <div className="col-6">
                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 border-0 ${denProfile?.isPublic ? 'epz-active-field' : ''}`} onClick={() => this.updateDenStatus(true)}>
                                        <FaGlobeAfrica className="btn-size-20 text-white" />
                                        <h6 className="fs-12 fw-500 text-white mb-0">Public</h6>
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 border-0 ${!denProfile?.isPublic ? 'epz-active-field' : ''}`} onClick={() => this.updateDenStatus(false)}>
                                        <AiOutlineLock className="btn-size-20 text-white" />
                                        <h6 className="fs-12 fw-500 text-white mb-0">Invitational</h6>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Sponsors */}
                        <div className="my-2">
                            <label className='fs-20 fw-600'>Sponsors</label>
                            <div className="row">
                            {this.sponsorArray.map((el, i) => {
                              return (
                                <div className="col-12 col-lg-6" key={i}  >
                                  <div className="addMoreField sponsor-border pt-4 mt-3 pb-4 h-auto">
                                    <div className="col-10 offset-1 d-flex justify-content-center">
                                      <h1 className="fs-14 fw-bold text-white mt-2"> Sponsor {i + 1} </h1>
                                      <FiEdit className="text-white mt-2 ms-3 cursor-pointer" onClick={() => { this.openEditSponsor(el, i); }} />
                                      <HiOutlineTrash className="text-white mt-2 ms-2 cursor-pointer" onClick={() => {
                                        this.onClickOpenConfirm(el?.createdAt ? "deleteSponsor" : "deleteTempSponsor", this.sponsorDeleteMsg(el?.name));
                                        this.setState({
                                          deleteSponsorId: el._id,
                                          sponsorIndex: i,
                                        });
                                      }}
                                      />
                                    </div>
                                    <div className="col-10 offset-1">
                                      <label className="fs-14 fw-bold text-white mt-2 float-start" htmlFor="" > Sponsor Name </label>
                                      <input type="text" value={el?.name} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                    </div>
                                    <div className="col-10 offset-1">
                                      <label className="fs-14 fw-bold text-white mt-2 float-start" htmlFor="" > Sponsor Title </label>
                                      <input type="text" value={el?.type} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                    </div>
                                    <div className="align-self-center mt-3">
                                      <img src={el?.logo?.url} alt="" width="200" height='100' className="border border-2 rounded-10" />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                            <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-2" 
                                onClick={() => {
                                    this.toggleModal("openSponsor");
                                    this.setState({
                                    sponsorForUpdate: {},
                                    sponsorIndex: null,
                                    });
                                }} 
                            >
                                <button className="btn">
                                    <AiFillPlusCircle className="btn-size-25 text-white" />
                                </button>
                                <label className="fs-16 fw-600 text-white my-0 cursor-pointer">Add Sponsor</label>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <button className='white-flat-btn p-2 fw-600 my-2' onClick={this.submitDenData}>Save</button>
                    <button className='white-flat-btn den-delete-btn p-2 fw-600 my-2' onClick={this.deleteDenProfile}>Delete Den Profile</button>
                </div>
                <Modal show={this.state.openSocialMediaModal} centered dialogClassName="social-media-modal">
                    <Modal.Body>
                        <div className="p-3">
                            <p className="fs-18 fw-bold text-white text-center mb-2">Social Media Details</p>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={() => { this.setState({ openSocialMediaModal: !this.state.openSocialMediaModal }) }} />

                            <div className="my-3">
                                {this.state.extraDetails.map((details, i) => {
                                    if (details.isChecked) {
                                        return (
                                            <div className="my-1" key={i}>
                                                <div className="purple-field d-flex align-items-center w-100 border-0 ">
                                                    <img src={details.inlineUrl} className="mx-2" alt="" style={{width: '20px', height: 'auto'}} />
                                                    <input
                                                        type='text' placeholder="SocialMedia.com/help"
                                                        className="text-white fs-14 height-45 border-0 flex-grow-1"
                                                        value={details.value}
                                                        onChange={e => this.updateDetails(e, i)}
                                                        style={{ backgroundColor:'transparent', outline: 'none' }}
                                                    />
                                                    <AiOutlineMinusCircle className="btn-size-20 mx-2 text-white-50" onClick={e => { this.showFields(e, i, false) }} />
                                                </div>
                                                <p className="error-msg">{details.error}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="d-flex flex-wrap justify-content-center my-3">
                                {this.state.extraDetails.map((media, i) => {
                                    return (
                                        <button key={i} className="purple-field border-0 rounded-circle d-flex justify-content-center align-items-center btn-size-52 mb-1 mx-1" onClick={e => { this.showFields(e, i, true) }}>
                                            <img src={media.url} alt={media.id} style={{width: '28px', height: 'auto'}} />
                                        </button>
                                    )
                                })}
                            </div>
                            {this.getAddButton() ? <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" onClick={this.addDetails}>Add</button> : ''}
                        </div>
                    </Modal.Body>
                </Modal>
                {/* SPONSOR MODAL */}
                <Modal show={this.state.openSponsor || this.state.sponsorForUpdate} centered dialogClassName="BioEdit-modal" >
                    <Modal.Body>
                    <Sponsor updateLoader={this.props.updateLoader} updateSponsor={this.updateSponsor} sponsorIndex={this.state.sponsorIndex} addSponsor={this.addSponsor} sponsorArray={this.sponsorArray?.length} sponsorForUpdate={this.state.sponsorForUpdate} onClose={() => { this.setState({ openSponsor: false, sponsorForUpdate: null }) }} />
                    </Modal.Body>
                </Modal>
                {/* CONFIRMATION MODAL */}
              <Modal show={this.state.openConfirm} centered dialogClassName="Confirmation-modal">
                <Modal.Body>
                  <ConfirmationModal confirm={this.state.confirm} onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.confirmed} />
                </Modal.Body>
              </Modal>
            </div>
        )
    }
}