import React, { Component } from 'react';
//images
import allGamesIc from '../../../images/games/allGames-ic.png';
import allGamesGrayIc from '../../../images/games/allGamesGray-ic.png';
//icons
import { POST_INTERACTIONS, PROFILE_PAGE_IMAGES, DEN_EMPTY_SCREENS } from '../../../utils/Images.utils';
import { profile } from '../../../utils/localstorage.utils';
import { blockUnBlockDenProfile, checkDenStatus, followDenProfile, getDenMultiplePosts, getDenPostsForTimeline, getDenProfileAchivements, getDenProfiles, getPostInteractionsDetails, getProfilePagePosts, reportDenPost, unFollowDenProfile, updateDenProfileInLocalStorage } from '../../../utils/Den.utils';
import Image from '../../common/Image';
import PostCard from '../userDenSection/common/PostCard';
import PostSomething from '../userDenSection/common/PostSomething';
import { USER_DEN_STATS } from '../../../utils/enums.utils';
import { toast } from 'material-react-toastify';
import { Modal, Nav } from 'react-bootstrap';
import DenAchievements from '../../popup/DenAchievements';
import PostActions from '../../popup/den/PostActions';
import ReportPost from '../userDenSection/common/ReportPost';
import { IoIosArrowBack, IoIosCloseCircleOutline } from 'react-icons/io';
import { getAllGamesDetails } from '../../../utils/Games.utils';
import ClanLeftTab from '../userDenSection/common/ClanLeftTab';
import ClanDenMembers from '../../popup/den/ClanDenMember';
import DenProfileTopSection from '../userDenSection/common/DenProfileTopSection';
import UserLeftTab from '../userDenSection/common/UserLeftTab';
import { STYLE } from '../../../utils/Style.utils';
import { getTeams } from '../../../utils/Samples.utils';
import { DEN } from '../../../utils/Strings.utils';
import { getAllTeamsFromClan } from '../../../utils/ClanTeam.utils';
import TournamentStats from '../userDenSection/common/TournamentStats';
import UndoReportPost from '../../common/UndoReportPost';
import SkeletonProfilePage from '../../common/SkeletonProfilePage';


export default class UserProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            denProfile: null,
            currentUserDenProfile: null,
            selectedPostIndex: null,
            selectedReportType: null,
            selectedReportTypeDescription: null,
            showMoreNotificationIndex: null,
            postToInteract: null,
            feedDetails: [],
            samplePosts: [],
            openAchievements: false,
            openPostActionModal: false,
            selectedActionIndex: null,
            openPostReportModal: false,
            openSpotlightForPostIndex: null,
            isFollowing: true,
            postActionsType: null, //USER-ACTIONS
            showGameStats: false,
            gamesDetails: [],
            selectedGameId: 'All',
            popupType: DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY,
            allTeams: getTeams,
            denAchivements: null,
            clanDetails: null,
            orgDetails: null,
            userDetails: null,
            reportedDetails: null
        };
        getAllGamesDetails(this.setAllGamesDetails);
    }

    componentDidMount(){
        this.props.showOrHideNav(true);
        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setDenProfile)
        }
    }

    setDenProfile = (result) =>{
        if(result[0]){
            this.setState({
                denProfile: result[0]
            });

            getDenProfiles({profiles:[this.props.match.params.currentUserDenId ]},this.setCurrentUserDenProfile)
        }
    }

    setAllGamesDetails = (response) =>{
        this.setState({ gamesDetails : response });
    }

    updateState = (key,val) => {
        this.setState({
            [key] : val
        })
    }

    setCurrentUserDenProfile=(result)=>{
        try{
            
            //getProfilePagePosts(result[0]._id,this.updatePostOnTimeline);
            this.refreshTimeLine(result[0]._id);
            //getDenMultiplePosts({posts: result[0].post},this.updatePostOnTimeline);
            this.updateState('currentUserDenProfile',result[0]);
            getDenProfileAchivements(result[0]._id, (result)=>{
                this.updateState('denAchivements', result)
            });
          
    
        }catch(err){
            console.log("Error : ",err);
        }

        const denProfileType = result[0].type;
        if(result[0].type){
            switch (denProfileType) {
                case DEN.SEARCH.TYPES.CLAN:
                    this.setState({clanDetails : result[0].clanDetails});
                    getAllTeamsFromClan(result[0].clanOrOrgId, this.setClanDetails);
                    break;
                case DEN.SEARCH.TYPES.ORG:
                    this.setState({orgDetails : result[0].orgDetails});
                    break;
                case DEN.SEARCH.TYPES.USER:
                    this.setState({userDetails : result[0].userDetails});
                    break;
                default:
                    break;
            }
        }
    }

    refreshTimeLine = (denProfileId) => {
        getProfilePagePosts(denProfileId,this.updatePostOnTimeline);
    }
    
    setClanDetails = (response) => {
        //console.log('setClanDetails response ::', response);
        this.setState({allTeams: response})
    }

    updatePostOnTimeline = (posts) =>{
        this.updateState('feedDetails', posts)
    }

    checkStatStatus = (key, val) => { 
        if(!this.state.currentUserDenProfile?.publicStatus[USER_DEN_STATS.GAME_STATS]){
            toast.warning('Game Stats have been set private by the user');
            return false;
        }
        this.updateState(key, val);
    }

    toggleAchievementsPopup = (val1, val2) => { 
        this.setState({
            openAchievements : val1,
            popupType: val2
        })
    }

    showHidePostAction = (index, val1, val2) => {

        if(!val1 && !val2){
            this.setState({
                selectedActionIndex: null,
                selectedReportType: null,
                selectedReportTypeDescription: null,
                openPostActionModal: val1, 
                openPostReportModal: val2,
                postActionsType: null
            })
        }else{
            this.setState({
                selectedActionIndex: index,
                openPostActionModal: val1, 
                openPostReportModal: val2,
                postActionsType: null
            })
        }
    }

    updateProfileDetails = (updatedProfileDetails) =>{
        this.showHidePostAction(null, false, false);
        //updateDenProfileInLocalStorage(updatedProfileDetails);
        if(updatedProfileDetails.pinnedPosts !== this.state.denProfile?.pinnedPosts){
            this.refreshTimeLine(updatedProfileDetails?._id);
        }
        this.setState({
            denProfile : updatedProfileDetails
        });
    }

    onCheckReportType = (value) =>{
        this.setState({
            selectedReportType: ((this.state.selectedReportType === value)? null : value)
        });
    }

    onChangeReportDescription = (val) =>{
        this.setState({
            selectedReportTypeDescription: val
        })
    }

    onSubmitReport = () =>{
        reportDenPost(this.state.feedDetails[this.state.selectedActionIndex]._id, {type: this.state.selectedReportType, description:this.state.selectedReportTypeDescription }, this.showHidePostAction, this.enableUndoReport);
    }

    enableUndoReport = (report) => {
        console.log(report);
        this.setState({
            reportedDetails: report
        });
        setTimeout(() => {
            this.clearUndoReportPopup();
        }, 10000);
      
    }

    clearUndoReportPopup = () => {
        this.setState({
            reportedDetails: null
        });
    }

    postToInteract = (index) =>{
        this.setState({
            postToInteract: index
        });
    }

    updatePostDetails = (result,selectedActionIndex, message?) =>{

        if(message){ toast.success(message) }
        const updatedPostDetails = this.state.feedDetails.map((post,i)=>{
            if(post._id === result._id){
                result.from = this.state.feedDetails[i].from;
                return result;
            }else{
                return post;
            }
        });

        this.setState({
            feedDetails: updatedPostDetails
        });
       this.showHidePostAction(null, false, false);
    }

    followedDenProfile = (result) =>{
        //updateDenProfileInLocalStorage(result)
        this.setState({
            denProfile: result
        });
    }

    getRequestedOrFollowButton = (currentUserDenProfile) =>{
        if(!this.state.denProfile?.followings?.includes(currentUserDenProfile?._id) && !this.state.denProfile?.requested?.includes(currentUserDenProfile?._id) && !this.state.denProfile?.blocked?.includes(currentUserDenProfile?._id)){
            return <button className='den-profile-btn follow-btn hover-zoomin' onClick={()=> checkDenStatus(this.state.denProfile) ? followDenProfile(this.state.denProfile?._id, currentUserDenProfile?._id,this.followedDenProfile) : null}>Follow</button>
        }else if(this.state.denProfile?.blocked?.includes(currentUserDenProfile?._id)){
            return <button className='den-profile-btn request-btn' onClick={()=>checkDenStatus(this.state.denProfile) ? blockUnBlockDenProfile(this.state.denProfile?._id,currentUserDenProfile?._id, false, this.updateProfileDetails) : null} > UnBlock</button>
        }
        else{
            return <button className='den-profile-btn requested-btn'> Requested</button>
        }

    }

    getPrivateScreen = () => { 
        return(
            <div className='col-12 col-lg-9 my-1'>
                <div className='private-den-bg column-center-align-div p-3'>
                    <Image src={POST_INTERACTIONS.ACTIONS.PRIVATE_DEN} params={'private'} className='my-3' style={{width: '60px', height: 'auto'}} />
                    <p className='private-den-heading'>This account is private</p>
                    <p className='private-den-heading private-den-text'>Follow this account to see their posts</p>
                </div>
            </div>
        )
    }

    getFeed = () => { 
        const {currentUserDenProfile, denProfile} = this.state;
        const ownDenProfile = currentUserDenProfile?._id === denProfile?._id;
        var isRoportingEnabled = (this.state.selectedReportType !== null && this.state.selectedReportTypeDescription?.length > 0);
        if(!ownDenProfile && !currentUserDenProfile?.isPublic && !currentUserDenProfile?.followers?.includes(denProfile?._id)){
            return this.getPrivateScreen();
        }

        return(
            <div className='col-12 col-lg-9 my-1'>
                {/* Nav on users profile feed */}
                <div className='row p-2' style={{display: ownDenProfile ? 'block' : 'none'}}>
                    <PostSomething denProfile={this.state.currentUserDenProfile} history={this.props.history}/>
                </div>

                {/* Posts on feed */}
                <div className='row my-1'>

                    {this.state.feedDetails.length ?
                    
                    this.state.feedDetails.map((post, i) => {
            
                        var likes = 0;
                        var likedUsers = [];    
                    
                        Object.keys(post.likes).map((key,i)=>{ 
                            likes += post.likes[key].length 
                            likedUsers = likedUsers.concat(post.likes[key]);
                        });
                
                        const interactions = getPostInteractionsDetails(i, post, likedUsers, denProfile, this.state.postToInteract, this.state.openSpotlightForPostIndex);
                    
                        return (
                            <div>
                            <PostCard 
                                key={i} 
                                i={i}
                                denProfile={denProfile} 
                                post={post}
                                history={this.props.history}
                                postType={null} 
                                interactions={interactions} 
                                feedDetails={this.state.feedDetails} 
                                selectedPostIndex={this.state.selectedPostIndex}
                                selectedReportType={this.state.selectedReportType}
                                showMoreNotificationIndex={this.state.showMoreNotificationIndex}
                                postToInteract={this.state.postToInteract}
                                showHidePostAction={this.showHidePostAction}
                                updateProfileDetails={this.updateProfileDetails}
                                updatePostDetails={this.updatePostDetails}
                                isLiveStraming={false}
                                checkDenStatus={this.checkDenStatus}
                            />
                            {(this.state.reportedDetails && this.state.reportedDetails?.postId  === post._id) && <UndoReportPost updateState={this.updateState} reportedDetails={this.state.reportedDetails}  clearUndoReportPopup={this.clearUndoReportPopup} />}
                            </div>
                        )
                        })
                        :
                        <div className="no-posts-card text-center py-3">
                            <img className="" src={ownDenProfile ? DEN_EMPTY_SCREENS.NO_POSTS : DEN_EMPTY_SCREENS.NO_POSTS_YET} alt={""} />
                            <p className="fs-18 fw-600 opacity-75 my-3">
                                {ownDenProfile ?'Start posting today, and you will be rewarded handsomely with den stars' : 'This account does not have any posts yet.'}
                            </p>
                        </div>
                    }

                </div> 
            </div>
        )
    }

    getGameStats = () => { 
        const {currentUserDenProfile} = this.state;
        return(
            <div className='col-12 col-lg-9 my-1'>
                <div className='' style={STYLE.DEN.PROFILE_CARD}>
                    <div className='row p-2'>
                        <div className='col-2'>
                            <IoIosArrowBack className='cursor-pointer my-0' style={{height: '20px', width: '20px'}}  onClick={()=> this.checkStatStatus('showGameStats', false)} />
                        </div>
                        <div className='col-8'>
                            <p className='fs-16 fs-lg-20 fw-700 text-center my-0'>{`${currentUserDenProfile?.name}`}'s Tournaments</p>
                        </div>
                        <div className='text-end col-2'>
                            <IoIosCloseCircleOutline className='cursor-pointer my-0' style={{height: '20px', width: '20px'}}  onClick={()=> this.checkStatStatus('showGameStats', false)} />
                        </div>
                    </div>
                    {/* Games */}
                    <div className='betting_navbar'>
                        <div className='d-flex overflow-auto my-2'>
                            <h4 className='fs-10 fs-lg-14 cursor-pointer m-3' onClick={() => { this.setState({ selectedGameId: 'All' }) }}>
                                <img src={`${this.state.selectedGameId === "All" ? allGamesIc : allGamesGrayIc}`} height='50' className='flex-shrink-0' alt='' />
                            </h4>
                            {this.state.gamesDetails.map((game,i) =>{
                                if(game.disabledBetLogo){
                                    return (
                                        <h4 className='fs-10 fs-lg-14 cursor-pointer m-3' key={i} onClick={() => { this.setState({ selectedGameId: game._id }) }}>
                                            <img src={this.state.selectedGameId === game._id? game.activeBetLogo.url : game.disabledBetLogo?.url} height='50' className='flex-shrink-0' alt='' />
                                        </h4>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    {/* Tournament stats */}
                    <TournamentStats currentUserDenProfile={currentUserDenProfile} selectedGameId={this.state.selectedGameId}/>
                </div>
            </div>
        )
    }

    renderUsersView = () => {
        const {currentUserDenProfile, denProfile, denAchivements, userDetails} = this.state;
        var isRoportingEnabled = (this.state.selectedReportType !== null); 
        const ownDenProfile = this.props.match.params.denId === this.props.match.params.currentUserDenId;
        return(
            <div className='body-section den_page'>
                <div className='container'>
                    
                    {/* Profile cover */}
                    <DenProfileTopSection 
                        currentUserDenProfile={currentUserDenProfile}
                        history={this.props.history}
                        denProfile={denProfile}
                        updateState={this.updateState}
                        isFollowing={this.state.isFollowing}
                        params={this.props.match.params}
                        getRequestedOrFollowButton={this.getRequestedOrFollowButton}
                        followedDenProfile={this.followedDenProfile}
                        detailsObj={userDetails}
                    />

                    <div className='row my-2'>
                        <UserLeftTab
                            currentUserDenProfile={currentUserDenProfile}
                            denAchivements={denAchivements}
                            checkStatStatus={this.checkStatStatus}
                            toggleAchievementsPopup={this.toggleAchievementsPopup}
                            ownDenProfile={ownDenProfile}
                            detailsObj={userDetails}
                        />
                        {this.state.showGameStats ? this.getGameStats() : this.getFeed()}
                    </div>
                    
                    {/* Post Action Div */}
                    <PostActions 
                        denProfileId = {this.state.currentUserDenProfile?._id}
                        myDenProfileId = {this.state.denProfile?._id}
                        postType={this.state.postActionsType}
                        show={this.state.openPostActionModal} 
                        denProfile={this.state.denProfile}
                        feedDetails={this.state.feedDetails}
                        updateProfileDetails={this.updateProfileDetails}
                        updatePostDetails={this.updatePostDetails}
                        selectedActionIndex={this.state.selectedActionIndex}
                        showHidePostAction={this.showHidePostAction}
                        onClose={() => this.showHidePostAction(null, false, false)}  
                    />

                    {/* Report Post Details */}
                    <ReportPost 
                        onSubmitReport={this.onSubmitReport} 
                        showHidePostAction={this.showHidePostAction} 
                        onChangeReportDescription={this.onChangeReportDescription} 
                        onCheckReportType={this.onCheckReportType} 
                        isRoportingEnabled={isRoportingEnabled} 
                        openPostReportModal={this.state.openPostReportModal} 
                        selectedReportType={this.state.selectedReportType} 
                        selectedReportTypeDescription={this.state.selectedReportTypeDescription} 
                    />

                </div>

                
                {/* Modals */}
                <div className="den-achievements">
                    <Modal show={this.state.openAchievements} centered dialogClassName="">
                        <Modal.Body>
                            <DenAchievements  denId={denProfile?._id} denAchivements={denAchivements} toggleAchievementsPopup={this.toggleAchievementsPopup} heading={this.state.popupType} />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )
    }

    renderClansView = () => {
        const {currentUserDenProfile, denProfile, denAchivements, clanDetails} = this.state;
        const clanMembers = [];
        this.state.currentUserDenProfile?.members?.owner?.map(member => clanMembers.push({...member, role: 'Owner'}));
        if(this.state.currentUserDenProfile?.members?.admin?.length){
            this.state.currentUserDenProfile?.members?.admin?.map(member => clanMembers.push({...member, role: 'Admin'}));
        }

        var isRoportingEnabled = (this.state.selectedReportType !== null); 
        const ownDenProfile = this.props.match.params.denId === this.props.match.params.currentUserDenId;

        return(
            <div className='body-section den_page'>
                <div className='container'>

                    {/* Profile cover */}
                    <DenProfileTopSection 
                        currentUserDenProfile={currentUserDenProfile}
                        history={this.props.history}
                        denProfile={denProfile}
                        updateState={this.updateState}
                        isFollowing={this.state.isFollowing}
                        params={this.props.match.params}
                        getRequestedOrFollowButton={this.getRequestedOrFollowButton}
                        followedDenProfile={this.followedDenProfile}
                        detailsObj={clanDetails}
                    />

                    {/* BOTTOM SECTION */}
                    <div className='container'>
                        <div className='row my-2'>
                            <ClanLeftTab 
                                currentUserDenProfile={this.state.currentUserDenProfile}
                                denAchivements={denAchivements}
                                checkStatStatus={this.checkStatStatus}
                                toggleAchievementsPopup= {this.toggleAchievementsPopup}
                                updateState={this.updateState}
                                allTeams={this.state.allTeams}
                                ownDenProfile={ownDenProfile}
                                detailsObj={clanDetails}
                            />

                            {this.state.showGameStats ? this.getGameStats() : this.getFeed()}

                        </div>
                    </div>
                    <div className="den-achievements">
                        <Modal show={this.state.openAchievements} centered dialogClassName="">
                            <Modal.Body>
                                <DenAchievements  denId={denProfile?._id} denAchivements={denAchivements} toggleAchievementsPopup={this.toggleAchievementsPopup} heading={this.state.popupType}  />
                            </Modal.Body>
                        </Modal>
                    </div>


                    {/* Post Action Div */}
                    <PostActions 
                        denProfileId = {this.state.currentUserDenProfile?._id}
                        postType={this.state.postActionsType}
                        show={this.state.openPostActionModal} 
                        denProfile={this.state.denProfile}
                        feedDetails={this.state.feedDetails}
                        updateProfileDetails={this.updateProfileDetails}
                        updatePostDetails={this.updatePostDetails}
                        selectedActionIndex={this.state.selectedActionIndex}
                        showHidePostAction={this.showHidePostAction}
                        onClose={() => this.showHidePostAction(null, false, false)}  
                    />

                    {/* Report Post Details */}
                    <ReportPost 
                        onSubmitReport={this.onSubmitReport} 
                        showHidePostAction={this.showHidePostAction} 
                        onChangeReportDescription={this.onChangeReportDescription} 
                        onCheckReportType={this.onCheckReportType} 
                        isRoportingEnabled={isRoportingEnabled} 
                        openPostReportModal={this.state.openPostReportModal} 
                        selectedReportType={this.state.selectedReportType} 
                        selectedReportTypeDescription={this.state.selectedReportTypeDescription} 
                    />
                </div>

                {/* Modals */}
                <div>
                    <Modal show={this.state.showMembers} centered dialogClassName="ShowMember-modal">
                        <Modal.Body>
                            <ClanDenMembers 
                                updateStateKey={this.updateState}
                                clanMembers={clanMembers}
                                heading={currentUserDenProfile?.type === 'CLAN' ? 'Clan' : 'Organization'}
                                allTeams={this.state.allTeams}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )
    }
    
    renderOrgView = () => {
        const {currentUserDenProfile, denProfile, denAchivements, orgDetails} = this.state;
        const clanMembers = [];
        this.state.currentUserDenProfile?.members?.owner?.map(member => clanMembers.push({...member, role: 'Owner'}));
        if(this.state.currentUserDenProfile?.members?.admin?.length){
            this.state.currentUserDenProfile?.members?.admin?.map(member => clanMembers.push({...member, role: 'Admin'}));
        }

        var isRoportingEnabled = (this.state.selectedReportType !== null); 
        const ownDenProfile = this.props.match.params.denId === this.props.match.params.currentUserDenId; 

        return(
            <div className='body-section den_page'>
                <div className='container'>

                    {/* Profile cover */}
                    <DenProfileTopSection 
                        currentUserDenProfile={currentUserDenProfile}
                        history={this.props.history}
                        denProfile={denProfile}
                        updateState={this.updateState}
                        isFollowing={this.state.isFollowing}
                        params={this.props.match.params}
                        getRequestedOrFollowButton={this.getRequestedOrFollowButton}
                        followedDenProfile={this.followedDenProfile}
                        detailsObj={orgDetails}
                    />

                    {/* BOTTOM SECTION */}
                    <div className='container'>
                        <div className='row my-2'>
                            <ClanLeftTab 
                                currentUserDenProfile={this.state.currentUserDenProfile}
                                denAchivements={denAchivements}
                                checkStatStatus={this.checkStatStatus}
                                toggleAchievementsPopup= {this.toggleAchievementsPopup}
                                updateState={this.updateState}
                                ownDenProfile={ownDenProfile}
                                detailsObj={orgDetails}
                            />

                            {this.state.showGameStats ? this.getGameStats() : this.getFeed()}

                        </div>
                    </div>
                    <div className="den-achievements">
                        <Modal show={this.state.openAchievements} centered dialogClassName="">
                            <Modal.Body>
                                <DenAchievements  denId={denProfile?._id} denAchivements={denAchivements} toggleAchievementsPopup={this.toggleAchievementsPopup} heading={this.state.popupType}  />
                            </Modal.Body>
                        </Modal>
                    </div>


                    {/* Post Action Div */}
                    <PostActions 
                        denProfileId = {this.state.currentUserDenProfile?._id}
                        postType={this.state.postActionsType}
                        show={this.state.openPostActionModal} 
                        denProfile={this.state.denProfile}
                        feedDetails={this.state.feedDetails}
                        updateProfileDetails={this.updateProfileDetails}
                        updatePostDetails={this.updatePostDetails}
                        selectedActionIndex={this.state.selectedActionIndex}
                        showHidePostAction={this.showHidePostAction}
                        onClose={() => this.showHidePostAction(null, false, false)}  
                    />

                    {/* Report Post Details */}
                    <ReportPost 
                        onSubmitReport={this.onSubmitReport} 
                        showHidePostAction={this.showHidePostAction} 
                        onChangeReportDescription={this.onChangeReportDescription} 
                        onCheckReportType={this.onCheckReportType} 
                        isRoportingEnabled={isRoportingEnabled} 
                        openPostReportModal={this.state.openPostReportModal} 
                        selectedReportType={this.state.selectedReportType} 
                        selectedReportTypeDescription={this.state.selectedReportTypeDescription} 
                    />
                </div>

                {/* Modals */}
                <div>
                    <Modal show={this.state.showMembers} centered dialogClassName="ShowMember-modal">
                        <Modal.Body>
                            <ClanDenMembers  updateStateKey={this.updateState} clanMembers={clanMembers} heading={currentUserDenProfile?.type === 'CLAN' ? 'Clan' : 'Organization'} />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )
    }

    checkDenStatus = () => {
        if(!this.state.denProfile.isActive){
            toast.error('Please re-activate your den profile to perform any action');
            return false;
        }
        return true;
    }

    render(){
        if(this.state.currentUserDenProfile?.type){
            switch (this.state.currentUserDenProfile?.type) {
                case "USER":
                    return this.renderUsersView();
                case "CLAN":
                    return this.renderClansView();
                case "ORG":
                    return this.renderOrgView();
                default:
                    return this.renderUsersView();
            }
        }else{
            return <SkeletonProfilePage />;
        }

    }
}