import { Component } from "react";
import { NavLink } from "react-router-dom";
// Assets
import { AiOutlineCloseCircle } from "react-icons/ai";
import logo from '../../images/eSpotz_logo.png';
// Utils 
import GlobalStrings from "../../utils/GlobalStrings";
// Style
import "../../styles/SignUpPolicies.css";

export default class SignUpPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = { termsAndConditionsCheckbox: false };
  }

  // To accept Terms & Conditions
  updateConditions = (e) => {
    this.setState({
      termsAndConditionsCheckbox: e.target.checked
    });
  }

  // Discard social media signing up process
  handleClose = () => {
    this.props.resetAndUpdateFlag(false,'openSignUpPolicies',false);
  }

  // Initiate the social media sign up process
  initiateSocialMediaSignUp = (e) => {
    e.preventDefault();
    window.open(GlobalStrings.AUTH_URL + this.props.socialMediaType + "?type=SIGNUP", "_self");
  }

  render() {
    const dialogButton = this.state.termsAndConditionsCheckbox ? '' : 'button-disabled'
    return (
      <section className="our-policies">
        <div className="terms-conditions-container">
          <AiOutlineCloseCircle className="close-icon" onClick={this.handleClose} />
          <h1 className="fs-24 text-center text-white mx-auto">Our Policies</h1>
          <p className="dialog-hr-line"></p>
          <img src={logo} alt="logo" className="dialog-logo my-3 mx-auto" />
          <p className="fs-18 text-white my-3">
            First things first... our community is important to us. Make sure you understand our terms
            of service.
          </p>
          <p className="fs-18 text-white my-3">
            We have your potential data and you should too.
          </p>
          <p className="fs-18 text-white my-3">
            Head to our friendly <NavLink to="/policies" className="blue" onClick={this.handleClose}>Policies</NavLink> portal.
          </p>
          <div className="checkbox-container my-3">
            <input type="checkbox" onChange={(e) => this.updateConditions(e)} className="checkbox" />
            <p className="m-0 text-white">
              I confirm that I'm 18+ and agree with the{" "} <NavLink to="/terms-and-conditions" className="blue" onClick={this.handleClose}>Terms & Conditions</NavLink>
            </p>
          </div>
          <button className={`button ${dialogButton}`} disabled={!this.state.termsAndConditionsCheckbox} onClick={(e) => this.initiateSocialMediaSignUp(e)}>Makes sense to me! </button>
        </div>
      </section>
    )
  }
}