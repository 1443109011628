import { toast } from 'material-react-toastify';
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { checkDenStatus } from '../../../../utils/Den.utils';
import { POST_INTERACTIONS, NAVBAR_ASSETS } from '../../../../utils/Images.utils';

import { DEN } from '../../../../utils/Strings.utils';
import SpotLight from '../../../popup/den/SpotLight';

export default class Interactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: props.index,
            interactions: props.interactions,
            styleLogo: props.styleLogo,
            openCommentSection: props.openCommentSection,
            post: props.post,
            denProfile: props.denProfile,
            selectedPostIndex: props.selectedPostIndex,
            postToInteract: props.postToInteract,
            openSpotlight: false
        };
    }

    componentWillReceiveProps(newProps){
        this.setState({
            index: newProps.index,
            interactions: newProps.interactions,
            openCommentSection: newProps.openCommentSection,
            post: newProps.post,
            denProfile: newProps.denProfile,
            selectedPostIndex: newProps.selectedPostIndex,
            postToInteract: newProps.postToInteract
        })
    } 

    sharePost = (post, denProfile) =>{
        if(post.disable.share){
            toast.warning("The share option is disabled on this post") 
        }else if(!post.shares.includes(denProfile._id.toString())){
            this.props.shareDenPost( post?.type === "REPOST" ? post.rePostId : post._id); 
        }
    }

    updateState = (key, val) => {
        this.setState({[key]: val});
        this.props.updateState(key, val);
    }

    showWarning = () => {
        toast.warning('Please complete your den porfile to perform this action');
        return false;
    }

    checkDenProfileStatus = () => {
        if(!this.state.denProfile?.isActive){
            toast.error('Please re-activate your den profile to perform any action');
            return false;
        }else if(this.props.unRegisterDenProfile){
            toast.warning('Please complete your den porfile to perform this action');
            return false;
        }else{
            this.props.history.push(`/den/${this.props.denProfile?._id}/live-streaming/${this.state.post._id}`);
        }
    }

    onClickSpotlight = (post) => {
        console.log("post.from :: ",post.from)
        if(this.state.denProfile?._id === post.from[0]?._id){
            toast.warning("You can not send spotlight to your own post")
        }else{
            if(post.disable.spotlight){
                toast.warning("The spotlights are disabled on this post")
            }else{
                if(this.props.unRegisterDenProfile){
                    this.showWarning();
                }else{
                    this.updateState('openSpotlight',true);
                }
            }
        }
    }

    render() {
        const { interactions, styleLogo, openCommentSection, post, denProfile, openSpotlight } = this.state;
        //var styleLogoTV = styleLogo;

        var styleLogoTV = {...styleLogo, ...{opacity: '50%'}};

        //console.log("styleLogoTV :: ",styleLogoTV) 
        const i = this.state.index;
        return (
            <div className='row py-1 px-0'>
                <div className='col-3 interacion-box justify-content-center' onClick={() => {
                    if(checkDenStatus(denProfile)){
                        post.disable.reaction ? toast.warning("The reactions are disabled on this post") : this.props.updateState( 'selectedPostIndex', (this.state.selectedPostIndex !== i ? i : null))
                    }
                }}>
                    <img src={interactions.spark.logo} alt='' style={styleLogo}/>
                    <p className={`ms-2 fs-16 fw-600 text-white-50 my-0 cursor-pointer ${interactions.spark.color}`}>Spark</p>
                </div>
                <div className='col-3 interacion-box justify-content-center' onClick={() => {
                    if(checkDenStatus(denProfile)){
                        post.disable.comment ? toast.warning("The comments are disabled on this post") : this.props.updateState('postToInteract',(this.state.postToInteract === i ? null : i))
                    }
                }}>
                    <img src={(openCommentSection || post.isCommented) ? POST_INTERACTIONS.HAMMER.ON : interactions.hammer.logo} alt='' style={styleLogo}/>
                    <p className={`ms-2 fs-16 fw-600 text-white-50 my-0 cursor-pointer ${(openCommentSection || post.isCommented) ? 'active-interaction' : null}`}>Hammer</p>
                </div>
                <div className='col-3 interacion-box justify-content-center' onClick={()=> {
                    if(checkDenStatus(denProfile)){
                        this.onClickSpotlight(post)
                    }
                }}>
                    <img src={interactions.spotlight.logo} alt='' style={styleLogo}/>
                    <p className={`ms-2 fs-16 fw-600 text-white-50 my-0 cursor-pointer ${interactions.spotlight.color}`}>Spotlight</p>
                </div>

                {this.props.isLiveStraming || post.type === 'TV' ?
                    <div onClick={this.checkDenProfileStatus} className='col-3 interacion-box justify-content-center cursor-pointer sharings' >
                        <img src={NAVBAR_ASSETS.ASSETS.TV.OFF} alt='' style={styleLogoTV}/>
                    </div>
                :
                    <div className='col-3 interacion-box justify-content-center cursor-pointer sharings' onClick={()=> { 
                        if(checkDenStatus(denProfile)){
                            post.disable.share ? toast.warning("The share option is disabled on this post") : (this.props.unRegisterDenProfile ? this.showWarning() : this.sharePost(post,denProfile))
                        }
                    }}>
                        <img src={interactions.share.logo} alt='' style={styleLogo}/>
                        <p className={`ms-2 fs-16 fw-600 text-white-50 my-0 cursor-pointer ${interactions.share.color}`}>OP</p>
                    </div>
                }
                

                {openSpotlight && <SpotLight enableComment={false} isLiveStreaming={false} type={DEN.SPOTLIGHT.TYPE.POST} parentId={post._id} toUser={post.from[0]?._id} denProfile={denProfile} openSpotlight={openSpotlight} updateState={this.updateState} />}
            </div>
        )
    }
}
