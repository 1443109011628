import { Component } from 'react';

// Images
import latestResultIc from "../../../images/icon/LatestResult-ic.png";
import participantAvtar from "../../../images/participantAvtar.jpg";
import clipBoardList from "../../../images/icon/clipBoardList-ic.png";
import greenChat from "../../../images/icon/greenChat-ic.png";

// icons
import { MdArrowBackIosNew } from 'react-icons/md';

export default class UpcomingMatches extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectMatch: true,
        }
    }

    matchResult = () => {
        const selectMatch = this.state.selectMatch;
        this.setState({
            selectMatch: !selectMatch,
        });
    };

    render() {
        return (
            <section className='tournament-upcoming-matches text-white'>
                <div className='container'>
                    <div className="row">

                        {this.state.selectMatch ?
                            <div className="col-12 mt-2">
                                <span className='d-flex'>
                                    <img src={latestResultIc} className='' alt='' style={{ height: '20px', width: '16px' }} /><h1 className='fs-16 fw-700 ms-2'>Upcoming Matches</h1>
                                </span>
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-lg-4'>
                                        <div className='grey-card mt-2 p-3'>
                                            <div className='d-flex justify-content-between'>
                                                <span>
                                                    <h1 className='fs-16 fw-700'>Spencer's peak <span className='fs-12 fw-bold'>( M 2.1 )</span></h1>
                                                    <h1 className='fs-12 fw-700 text-white-50'>DEC 15 @7:15 PM</h1>
                                                </span>
                                                <button className='green-round-btn rounded-circle fw-600 border border-2 border-dark d-flex justify-content-center align-items-center btn-size-40' onClick={this.matchResult}>
                                                    <img src={clipBoardList} className="" alt='' style={{ width: '20px' }} />
                                                </button>
                                            </div>
                                            <div className='row text-center mt-2'>
                                                <div className='col-5'>
                                                    <img src={participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                    <h5 className='fs-14 text-white fw-500 mt-2'>Elon Musk</h5>
                                                </div>
                                                <div className='col-2 p-0'>
                                                    <h5 className='fs-22 text-white fw-500 mt-1'>0 - 0</h5>
                                                </div>
                                                <div className='col-5'>
                                                    <img src={participantAvtar} className="rounded-circlebtn-size-45" alt='' />
                                                    <h5 className='fs-14 text-white fw-500 mt-2'>Zeff Bezos</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6 col-lg-4'>
                                        <div className='grey-card mt-2 p-3'>
                                            <div className='d-flex justify-content-between'>
                                                <span>
                                                    <h1 className='fs-16 fw-700'>Spencer's peak <span className='fs-12 fw-bold'>( M 2.1 )</span></h1>
                                                    <h1 className='fs-12 fw-700 text-white-50'>DEC 15 @7:15 PM</h1>
                                                </span>
                                                <button className='green-round-btn rounded-circle fw-600 border border-2 border-dark d-flex justify-content-center align-items-center btn-size-40' onClick={this.matchResult}>
                                                    <img src={clipBoardList} className="" alt='' style={{ width: '20px' }} />
                                                </button>
                                            </div>
                                            <div className='row text-center mt-2'>
                                                <div className='col-5'>
                                                    <img src={participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                    <h5 className='fs-14 text-white fw-500 mt-2'>Elon Musk</h5>
                                                </div>
                                                <div className='col-2 p-0'>
                                                    <h5 className='fs-22 text-white fw-500 mt-1'>0 - 0</h5>
                                                </div>
                                                <div className='col-5'>
                                                    <img src={participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                    <h5 className='fs-14 text-white fw-500 mt-2'>Zeff Bezos</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-12 mt-2 p-0">
                                <span className='d-flex ms-2'>
                                    <MdArrowBackIosNew onClick={this.matchResult} /><h1 className='fs-16 fw-700 ms-2'>Let It Go <span className='fs-12 fw-bold text-white-50'>( M 2.1 )</span></h1>
                                </span>
                                <div className='row pt-3'>
                                    <div className='col-lg-6 col-12 grey-card-shadow text-center d-flex justify-content-around align-items-end pt-3'>
                                        <div className=''>
                                            <img src={participantAvtar} className="rounded-circle btns-size-52 mt-2" alt='' style={{ zIndex: '1' }} />

                                            <h5 className='text-white fw-500 fs-16 mt-2'>Elon Musk</h5>
                                            <p className='fs-12 fw-600 text-white-50 mb-0'>0/0/0</p>
                                            <p className='fs-12 fw-600 text-white-50'>0000</p>
                                        </div>
                                        <div className=''>
                                            <h5 className='text-white fw-500 fs-30 mt-1 mb-3'>0 - 8</h5>
                                            <p className='text-white-50 fw-bold mb-0 pt-3 fs-12'>K/D/A</p>
                                            <p className='text-white-50 fw-bold mb-3 fs-12'>Score</p>
                                        </div>
                                        <div className=''>
                                            <img src={participantAvtar} className="rounded-circle btn-size-52 mt-2" alt='' style={{ zIndex: '1' }} />

                                            <h5 className='text-white fw-500 fs-16 mt-2'>Zeff Bezos</h5>
                                            <p className='fs-12 fw-600 text-white-50 mb-0'>0/0/0</p>
                                            <p className='fs-12 fw-600 text-white-50'>0000</p>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-12 px-4 text-center'>
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>TOURNAMENT :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>Grand Pixas</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>DATE :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>DEC 15 @7:15 PM</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>STATUS :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>Incompleted </h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>GAME :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>Valorant</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>STAGE :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>Show Down</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>ROUND :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>Round 2</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>MATCH :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'></h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='position-fixed b-5' style={{ bottom: '60px' }}>
                                        <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40'>
                                            <img src={greenChat} className="react-btn " alt='' height={'20px'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}
