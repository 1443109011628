import { Component } from 'react';
import '../../styles/betting.css';
import allGamesIc from '../../images/games/allGames-ic.png';
import allGamesGrayIc from '../../images/games/allGamesGray-ic.png';
import betCreatedIc from '../../images/icon/betCreated-ic.png';
import espotzLogo from '../../images/eSpotz_logo.png';
import { defaultPreviewImage, errorHandler, getOnlyDate, updateLoader } from '../../utils/common.utils';
import {
    FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, LineShareButton,
    FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LineIcon
} from "react-share";
//import Loader from '../common/Loader';
import loader from '../../images/common/Loader.png'; 
//import discord from '../../images/games/discord.png';
//import instagram from '../../images/games/instagram.png';
import { NavLink } from 'react-router-dom';
//import betChallenge from '../../images/icon/betChallenge.png';
import { getAllUpcomingBets, getBets } from '../../utils/Betting.utils';
import UpcomingMatchesCard from '../common/UpcomingMatchesCard';
import BettingApis from '../../helper/betting.api';
import { profile } from '../../utils/localstorage.utils';
import { getAllGamesDetails } from '../../utils/Games.utils';
import { ASSETS } from '../../utils/Strings.utils';
//import smallDeviceBG from '../../images/betting.png';
//import mediumDeviceBG from '../../images/betting_desktop.png';

export default class Betting extends Component {
    bettingApis = new BettingApis();
    constructor(props) {
        super(props);
        this.state = {
            upcomingBetsDetails: [],
            bettingStats : null,
            gamesDetails: [],
            selectedGameId: 'All'
        };

        getAllUpcomingBets("",this.setUpcomingBetsDetails);
        getAllGamesDetails(this.setAllGamesDetails);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.getAllBetsStats(profile()?.id);
    }

    setAllGamesDetails = (response) =>{
        this.setState({
            gamesDetails : response
        });
    }


    setUpcomingBetsDetails = (response) => {
        //console.log("-- response :: ",response)
        this.setState({
          upcomingBetsDetails: response
        });
    }

    switchToBets= () =>{

    }

    getAllBetsStats = (params) => {
        updateLoader(true)
        this.bettingApis.getUserBetsStats(params).then(
            (res: AxiosResponse) => {
                console.log("response :::: ", res.data.result);
                this.setState({bettingStats : res.data.result})
                updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    render() {
        var todaysLiveBets = [];
        var tomorrowLiveBets = [];
        var futuresLiveBets = [];

        var tomorrowsDate = new Date();
        tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
        
        var futuresDate = new Date();
        futuresDate.setDate(futuresDate.getDate() + 2);
        var upcomingBetsDetails;
        if(this.state.selectedGameId === 'All'){
            upcomingBetsDetails = this.state.upcomingBetsDetails;
        }else{
            upcomingBetsDetails = this.state.upcomingBetsDetails.filter(betDetails => (betDetails.gameId == this.state.selectedGameId));
        }

        upcomingBetsDetails.forEach((betDetails,i)=>{
         
            var matchDate = new Date(betDetails.matchSchedule);
            var todaysDate = new Date();
            if(getOnlyDate(matchDate) == getOnlyDate(todaysDate)){
                todaysLiveBets.push(betDetails)
            }else if(getOnlyDate(matchDate) == getOnlyDate(tomorrowsDate)){
                tomorrowLiveBets.push(betDetails)
            }else{
                futuresLiveBets.push(betDetails)
            }

        });
        return (
            <div className='body-section betting-page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 mt-3 mb-2'>
                            <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#8C2FB8' }}>“ Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.”</marquee>
                        </div>
                        <div className='col-12'>
                            <NavLink to="/livePastBetting" className=''>
                                <div className='betting_main-background d-flex flex-column'>
                                    <div className='d-flex mt-auto p-2'>
                                        <div>
                                            <h5 className='fs-14 fw-700 text-white mb-0'>MY BETS</h5>
                                            <p className='fs-10 fw-600 text-white-50 mb-2'>{this.state.bettingStats?.totalBets} BETS</p>
                                        </div>
                                        <div className='ms-3'>
                                            <p className='fs-10 fw-600 d-flex align-items-center mb-0 sunglow-text'><span className='sunglow_bullet me-1'></span> Live Bets: {this.state.bettingStats?.liveBets}</p>
                                            <p className='fs-10 fw-600 d-flex align-items-center mb-1 lime-green-text'><span className='green_bullet me-1'></span> Completed Bets: {this.state.bettingStats?.pastBets}</p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        {/* <div className='col-4 ps-0'>
                            <NavLink to="/bettingChallenges" className=''>
                                <div className='challenge-card d-flex align-items-center justify-content-center h-100'>
                                    <div className='text-center'>
                                        <img src={betChallenge} className='btn-size-40' alt=''/>
                                        <p className='fs-16 fw-700 text-white mt-3 mb-0'>CHALLENGE</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div> */}
                    </div>
                </div>
                <div className='betting_navbar'>
                    <div className='d-flex overflow-auto mt-4'>
                      
                        <h4 className='fs-10 fs-lg-14 cursor-pointer m-3' onClick={() => { this.setState({ selectedGameId: 'All' }) }}>
                            <img src={`${this.state.selectedGameId === "All" ? allGamesIc : allGamesGrayIc}`} height='50' className='flex-shrink-0' alt='' />
                        </h4>
                        {this.state.gamesDetails.map((game,i) =>{
                            if(game.disabledBetLogo){
                                return (
                                    <h4 className='fs-10 fs-lg-14 cursor-pointer m-3' key={i} onClick={() => { this.setState({ selectedGameId: game._id }) }}>
                                        <img src={this.state.selectedGameId === game._id? game.activeBetLogo.url : game.disabledBetLogo?.url} height='50' className='flex-shrink-0' alt='' />
                                    </h4>
                                )
                            }

                        })}
                    </div>
                </div>

                <div className='container'>
                    
                    <div className='row mt-3'>

                        {/* If no bets are there */}
                        {!upcomingBetsDetails.length && 
                            <div className="text-center">
                                <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                            </div>
                        }

                        {/* Bet Now */}
                        {todaysLiveBets.length > 0 &&
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center ms-2 mb-2'>
                                        <img src={betCreatedIc} className='btn-size-20' alt=''/>
                                        <p className='fs-16 fw-700 text-white ms-2 mb-0'>Bet Now</p>
                                    </div>
                                </div>

                                {todaysLiveBets.map((betDetails,i)=>{
                                    const tournamentDetails = betDetails.tournament;
                                    if(tournamentDetails && betDetails){
                                        return (  
                                            // <NavLink to={`/tournament/${tournamentDetails?._id}?page=BettingZone`} className='' key={i}>
                                            //     <UpcomingMatchesCard matchesDetails={[betDetails]} tournamentDetails={tournamentDetails} switchToBets={this.switchToBets} history={this.props.history} redirect={true} />
                                            // </NavLink>
                                            <UpcomingMatchesCard matchesDetails={[betDetails]} tournamentDetails={tournamentDetails} switchToBets={this.switchToBets} history={this.props.history} redirect={true} />

                                        )
                                    }
                                })}

                                <div className='col-12'>
                                    <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#1F60C1' }}>“ Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.”</marquee>
                                </div>
                            </div>
                        }

                        {/* Tomorrow's bets */}
                        {tomorrowLiveBets.length > 0 &&
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center ms-2 mb-2'>
                                        <img src={betCreatedIc} className='btn-size-20' alt=''/>
                                        <p className='fs-16 fw-700 text-white ms-2 mb-0'>Tomorrow's Bets</p>
                                    </div>
                                </div>

                                {tomorrowLiveBets.map((betDetails,i)=>{
                                    const tournamentDetails = betDetails.tournament;
                                    if(tournamentDetails && betDetails){
                                        return (  
                                            // <NavLink to={`/tournament/${tournamentDetails?._id}?page=BettingZone`} className='' key={i}>
                                            //     <UpcomingMatchesCard matchesDetails={[betDetails]} tournamentDetails={tournamentDetails} switchToBets={this.switchToBets} history={this.props.history} redirect={true} />
                                            // </NavLink>
                                            <UpcomingMatchesCard matchesDetails={[betDetails]} tournamentDetails={tournamentDetails} switchToBets={this.switchToBets} history={this.props.history} redirect={true} />

                                        )
                                    }
                                })}

                                <div className='col-12'>
                                    <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#8C2FB8' }}>“ Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.”</marquee>
                                </div>
                            </div>
                        }

                        {/* Futures's bets */}
                        {futuresLiveBets.length > 0 &&
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center ms-2 mb-2'>
                                        <img src={betCreatedIc} className='btn-size-20' alt=''/>
                                        <p className='fs-16 fw-700 text-white ms-2 mb-0'>{`${futuresDate.getDate()} - ${futuresDate.getMonth()+1} -${futuresDate.getFullYear()}`}</p>
                                    </div>
                                </div>

                                {futuresLiveBets.map((betDetails,i)=>{
                                    const tournamentDetails = betDetails.tournament;
                                    if(tournamentDetails && betDetails){
                                        return (  
                                            // <NavLink to={`/tournament/${tournamentDetails?._id}?page=BettingZone`} className='' key={i}>
                                            //     <UpcomingMatchesCard matchesDetails={[betDetails]} tournamentDetails={tournamentDetails} switchToBets={this.switchToBets} history={this.props.history} redirect={true} />
                                            // </NavLink>
                                            <UpcomingMatchesCard matchesDetails={[betDetails]} tournamentDetails={tournamentDetails} switchToBets={this.switchToBets} history={this.props.history} redirect={true} />

                                        )
                                    }
                                })}

                                <div className='col-12'>
                                    <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#1F60C1' }}>“ Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.” <span className='text-warning px-4'>$$$</span> “Money won is twice as sweet as money earned.”</marquee>
                                </div>
                            </div>
                        }

                    </div>

                    <div className='text-center mt-5'>
                        <img src={espotzLogo} className='' width='200' height='75' alt='' />
                        <div className="mt-3">
                            <WhatsappShareButton className="px-1" url='' quote='' title=''>
                                <WhatsappIcon size={"2rem"} round={true} />
                            </WhatsappShareButton>
                            <FacebookShareButton className="px-1" url='' title=''>
                                <FacebookIcon size={"2rem"} round={true} />
                            </FacebookShareButton>
                            <TelegramShareButton className="px-1" url='' title=''>
                                <TelegramIcon size={"2rem"} round={true} />
                            </TelegramShareButton>
                            <TwitterShareButton className="px-1" url='' title=''>
                                <TwitterIcon size={"2rem"} round={true} />
                            </TwitterShareButton>
                            {/* <TwitterShareButton className="px-1">
                                <img src={discord} alt='' sizes={'2rem'} round={true} />
                            </TwitterShareButton>
                            <TwitterShareButton className="px-1">
                                <img src={instagram} alt='' sizes={'2rem'} round={true} />
                            </TwitterShareButton>*/}
                            <img src={loader} alt='' style={{ height: '28px' }} /> 
                        </div>
                    </div>
                </div>
            </div>
        )
        
    }

    // render() { 
    //     return (
    //         <div className='body-section betting-page'>
    //             <div className='container-fluid px-0'>
    //                 <img src={smallDeviceBG} alt='' className='d-block d-md-none' width='100%' style={{height: '88vh'}} />
    //                 <img src={mediumDeviceBG} alt='' className='d-none d-md-block' width='100%' style={{height: '92vh'}} />
    //             </div>
    //         </div>
    //     );
    // }
}
