import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class MatchesApis {
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };


    updateMatchDetails = (id, data) => {
        return this.init().put(`/private/api/v1/match/updateMatch/${id}`, data);
    }

    editMatchDetails = (id, data) => {
        return this.init().put(`/private/api/v1/match/editMatchDetails/${id}`, data);
    }

    updateMatchResults = (id, data) => {
        return this.init().put(`/private/api/v1/match/updateMatchResults/${id}`, data);
    }


    getMatchDetails = (params?) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/match/getMatches?${searchString}`);
        }
        return this.init().get("/private/api/v1/match/getMatches");
    };

    getMatchDetailsWithParticipants = (params?) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/match/getMatchesWithParticipantObjects?${searchString}`);
        }
        return this.init().get("/private/api/v1/match/getMatchesWithParticipantObjects");
    };


    swapParticipants = (data) => {
        return this.init().post("/private/api/v1/match/swapParticipants", data);
    }


    getMultipleMatches = (data) => {
        return this.init().post("/private/api/v1/match/getMultipleMatches", data);
    }

    addParticipants = (id, data) => {
        return this.init().put(`/private/api/v1/match/addParticipants/${id}`, data);
    }

    addParticipantToRoundRobin = (data) => {
        return this.init().post(`/private/api/v1/match/addParticipantToRoundRobin`, data);
    }

    addParticipants2 = (id, data) => {
        return this.init().put(`/private/api/v1/match/addParticipants2/${id}`, data);
    }

    addParticipantToRoundRobin2 = (data) => {
        return this.init().post(`/private/api/v1/match/addParticipantToRoundRobin2`, data);
    }

    getStageDetails = (tournamentId, stageId) => {
        return this.init().get(`/private/api/v1/match/getStage/${tournamentId}/${stageId}`);
    }
    isParticipantInMatch = (data) => {
        return this.init().post(`/private/api/v1/match/isUserInMatch`, data);
    }
    addDispute = (id, data) => {
        return this.init().put(`/private/api/v1/dispute/createDispute/${id}`, data);
    }
    getDisputs = (params?) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/dispute/getDispute?${searchString}`);
        }
        return this.init().get("/private/api/v1/dispute/getDispute");
    };

    getMyDisputes = (params?) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/dispute/getMyDispute?${searchString}`);
        }
    };
    
    updateDisput = (id, data) => {
        return this.init().put(`/private/api/v1/dispute/updateDispute/${id}`, data);
    }

    addMatchToFlexibleStage = (id, data) =>{
        return this.init().post(`/private/api/v1/tournament/addMatchToFlexibleStage/${id}`, data);

    }

    deleteFlexibleMatch = (deleteMatchDetails) => {
        return this.init().post(`/private/api/v1/tournament/deleteMatchFromFlexibleStage/${deleteMatchDetails.tournamentId}`, deleteMatchDetails.data);
    };

    
    addParticipantToFirstRound = (id, data) => {
        return this.init().put(`/private/api/v1/match/addParticipantToFirstRound/${id}`, data);
    }


    findPlayersOfFirstRound = (id, data) =>{
        return this.init().post(`/private/api/v1/match/findPlayersOfFirstRound/${id}`, data);
    
    }

    shufflePlayersAndAddToFirstRoundMatches = (id, data) =>{
        return this.init().post(`/private/api/v1/match/shufflePlayersAndAddToFirstRoundMatches/${id}`, data);
    }

}