import React, { Component } from 'react';
import { getMyTeams, addParticipantInTournament, getStageDetails } from '../../../../../../utils/Tournament.utils';
import FlexibleBrackets from '../../../../../common/FlexibleBrackets';
import { toast } from 'material-react-toastify';
import { ERROR, WARNING } from '../../../../../../utils/Strings.utils';
import Registration from '../../../Registration';
import { Modal } from "react-bootstrap";
import MatchInvite from '../../../../../popup/MatchInvite';
import { linkWithDiscord, saveCloseRegistration } from '../../../../../../utils/common.utils';
import AcceptInvite from '../../../../../popup/AcceptInvite';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import { profile } from '../../../../../../utils/localstorage.utils';
import Scoreboard from '../../../Scoreboard';

export default class FFAFlexibleStageMatches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stageDetails: props?.stageDetails? props.stageDetails : null ,
            tournamentDetails: props?.tournamentDetails? props.tournamentDetails : null,
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null,
            showRegistrationScreen: false,
            matchIndex: '0.0',
            showScoreBoard: false,
            matchDetails: null,
            matchNo: null
        };

        this.enableLoading = false;
    }

    componentDidMount(){
        saveCloseRegistration(this.closeRegistrationScreen);
    }

    componentWillReceiveProps(newProps) {
        if(!this.enableLoading){
            this.setState({
                stageDetails: newProps?.stageDetails? newProps.stageDetails : this.state.stageDetails,
                tournamentDetails: newProps?.tournamentDetails? newProps.tournamentDetails : this.state.tournamentDetails,
            })
        }
    }

    registerForTournament = (e, matchDetail, matchIndex) =>{
      
        if(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true && this.state.tournamentDetails?.participantType === 'TEAM'){
            this.switchRegistrationScreens(matchDetail,matchIndex);
        }else{

            this.enableLoading = true;
            if(this.state.tournamentDetails?.participantType === 'SINGLE'){
                this.setState({
                    initiateRegistration: true,
                    selectedMatchDetail: matchDetail,
                    selectedMatchIndex: matchIndex
                });
            }else{
                this.setState({
                    selectedMatchDetail: matchDetail
                });
                getMyTeams(this.state.tournamentDetails.gameId, matchDetail, this.addParticipantInTournament, this.selectTeams)
            }
        }
    }   

    switchRegistrationScreens = (matchDetail, matchIndex, teamId?) =>{
        this.setState({
            showRegistrationScreen : !this.state.showRegistrationScreen,
            selectedMatchDetail: matchDetail,
            matchIndex: matchIndex,
            selectedTeams: (teamId ? teamId : null)
        });
    }

    // END :: Tournament Registration Process

    closeRegistrationScreen = () => {
        getStageDetails(this.state.tournamentDetails?._id,this.props.index,this.setData)
    }

    setData = (values) => {
        if (values) {
            this.setState({
                stageDetails: values,
                showRegistrationScreen: false
            });
        }else{
            this.setState({
                showRegistrationScreen: false
            });
        }
    }   

    selectTeams = (success,data) =>{
        if(success){
            this.setState({
                teamsArray: data.teamsArray,
                initiateRegistration: true,
                selectedMatchDetail: data.selectedMatchDetail
            });
        }else{
            toast.warning(ERROR.NO_TEAM_FOR_TOURNAMENT);
        }

    }

    addParticipantInTournament = (matchId, teamId) =>{
        
        if(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true){
            this.switchRegistrationScreens(this.state.selectedMatchDetail,this.state.selectedMatchIndex,teamId);
            this.resetRegistration();
        }else{
            addParticipantInTournament(this.updateStageDetails,this.state.selectedMatchDetail._id, teamId, this.state.stageDetails, this.state.tournamentDetails);
        }

    }

    onClickOpenInvite = (joinTeamId?) => {
        this.setState({
            initiateRegistration: !this.state.initiateRegistration
        });
        if (joinTeamId) {
            addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail?._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails);
        }
        this.resetRegistration();
    }

    resetRegistration = () =>{
        this.setState({
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null
        });
        //this.enableLoading = false;
    }

    updateStageDetails = (response) =>{

        this.setState({
            stageDetails: response,
        });
        this.resetRegistration();
    }

    onClickRegister = () =>{
 
        if(profile()?.discordProfile?.discordUsername){
            return (
                <Modal.Body>
                    <AcceptInvite
                        clearInvitations={this.resetRegistration}
                        tournamentId={this.state.tournamentDetails?._id}
                        addParticipantInTournament={this.addParticipantInTournament}
                        joinTeamRole={null}
                        close={this.resetRegistration}
                        type="add"
                        info={{}}
                        title='Register'
                        role='player'
                        matchId={this.state.selectedMatchDetail?._id}
                        isTournamentInvitation={true}
                    />
                </Modal.Body>
            );
        }else{
            return (
                <Modal.Body>
                    <ConfirmationModal confirm={linkWithDiscord()} onClickOpenConfirm={this.resetRegistration} confirmed={this.confirmed} />
                </Modal.Body>
            );
        }
    }

    confirmed =()=>{
        this.resetRegistration();
        this.props.history.push('/profile');
    }

    hideAndShowScoreBoard = (matchDetails?, matchNo?) =>{
        if(matchDetails?.resultAdded){
            this.setState({
                showScoreBoard: !this.state.showScoreBoard,
                matchDetails: !this.state.showScoreBoard === true ? matchDetails : null,
                matchNo
            });
        }
    }

    render() {
   
     
        if(this.state.stageDetails){
            if(this.state.showRegistrationScreen){
                return (
                    <Registration 
                        tournamentDetails={this.state.tournamentDetails} 
                        stageDetails={this.state.stageDetails}  
                        selectedMatchDetail={this.state.selectedMatchDetail}
                        matchIndex={this.state.matchIndex}
                        selectedTeams={this.state.selectedTeams}
                    />
                )
            }else{
                if(this.state.showScoreBoard){
                    return (
                        <Scoreboard
                            matchDetails={this.state.matchDetails}
                            hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                            tournamentDetails={this.state.tournamentDetails}
                            matchNo={this.state.matchNo}
                        />
                    )
                }else{
                    return (
                        <div>
                            {/* Brackets Structure */}
                            <FlexibleBrackets 
                                stageDetails={this.state.stageDetails} 
                                tournamentDetails={this.state.tournamentDetails}
                                isSwappingEnabled={false}
                                isTournamentOperator={false}
                                openInviteModal={this.registerForTournament}
                                hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                            />
    
                            {/* Tournament Invitation Section */}
                            <Modal centered show={this.state.initiateRegistration} dialogClassName="ClanInvite-modal">
                            {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                                this.onClickRegister()
                                :
                                <Modal.Body>
                                    <MatchInvite
                                        onClickOpenInvite={this.onClickOpenInvite}
                                        teamsArray={this.state.teamsArray}
                                        choice="Join Tournament"
                                        isRoundRobin={false}
                                    />
                                </Modal.Body>
                            }
                            </Modal>
    
                        </div>
    
                    )
                }
            }
        }else{
            return (
                <div>
                    <h1>No Data Found</h1>
                </div>
            );
        }
    }
}