import React, { Component } from 'react';
//images
import profileLogo from '../../../images/den/den-profile-logo.png';
import profileCover from '../../../images/den/cover.png';
//icons
import { AiFillEdit } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { RiUserShared2Line } from 'react-icons/ri';
import Image from '../../common/Image';
import { POST_INTERACTIONS } from '../../../utils/Images.utils';


export default class ClanDenTopTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserDenProfile: props.currentUserDenProfile
        };
    }

    componentDidMount(){}

    componentWillReceiveProps(nextProps){
        this.setState({currentUserDenProfile: nextProps.currentUserDenProfile})
    }

    render() {
        const profileCard  = {background: '#231D36',  borderRadius: '8px' };
        const { currentUserDenProfile} = this.state;
        //console.log(':::', PROFILE_PAGE_IMAGES);
        return(
            <div className='container'>
                <div className='clanView-banner'>
                    <Image src={currentUserDenProfile?.coverImage?.url ? currentUserDenProfile?.coverImage?.url : profileCover} param={`CLAN`} className="clanView-background" alt='' />
                    <Image src={currentUserDenProfile?.coverImage?.url ? currentUserDenProfile?.coverImage?.url : profileCover} param={`CLAN`} className="clanView-fronImage" alt='' />
                </div>
                <div className='profile-card-bg' style={profileCard}>
                    <div className="row p-2 logo-top-margin">
                        <div className="col-lg-2 col-3">
                            <img src={currentUserDenProfile?.logo?.url ? currentUserDenProfile?.logo?.url : profileLogo} alt="clan logo" className="organization-logo rounded-circle position-relative" />
                        </div>

                        <div className="col-lg-7 col-9 mt-auto">
                        <h6 className="fs-20 fs-lg-24 fw-600 text-white mb-0 ms-4 text-truncate">
                            <span className='fs-20 fs-lg-30 fw-600 text-white'>{currentUserDenProfile?.name}</span> <br />
                            <span className='fs-12 fs-lg-20 fw-600 text-white-50'><Image src={POST_INTERACTIONS.AS.CLAN} param={`CLAN`} className="me-2" alt='' style={{height:'auto', width: '24px'}} />Clan</span>
                            {/* <marquee className='marquee-text fs-12 fs-lg-16 fw-600 text-white-50' direction="left">{this.state?.clanData?.name}<span className='text-warning px-4'>$$$</span> {this.state?.clanData?.name}</marquee> */}
                        </h6>
                        </div>
                        <div className='col-lg-3 col-12 mt-auto'>
                            <div className='row'>
                                <div className='col-4 col-lg-12 my-1'>
                                    {true ? 
                                        <button className='den-profile-btn'>
                                            <AiFillEdit className='me-2' /> Profile
                                        </button>
                                        :
                                        true ? <button className='den-profile-btn follow-btn'>Follow</button> : <button className='den-profile-btn following-btn'>Following</button>
                                    }
                                </div>
                                <div className='col-4 col-lg-6'>
                                    <div className='followers-box p-0'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FiUser className='me-2' />
                                            <div>
                                                <p className='fs-12 fw-600 my-0'>{currentUserDenProfile?.followers?.length}</p>
                                                <p className='fs-12 fw-600 my-0'>Followers</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 col-lg-6'>
                                    <div className='followers-box p-0'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <RiUserShared2Line className='me-2' />
                                            <div>
                                                <p className='fs-12 fw-600 my-0'>{currentUserDenProfile?.followings?.length}</p>
                                                <p className='fs-12 fw-600 my-0'>Following</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}