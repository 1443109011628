import React, { Component } from 'react';
import { IoIosArrowBack, IoIosCloseCircleOutline } from "react-icons/io";
import Image from '../../common/Image';
import avatar from '../../../images/participantAvtar.jpg';
import { Button } from 'react-bootstrap';
import { getTeamMembers } from '../../../utils/ClanTeam.utils';

export default class ClanDenMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTeamDetails: false,
            teamMembersDetails: null,
            allTeams: props.allTeams
        }
    }

    componentDidMount() {
        //console.log('Members ::', this.props.members);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ allTeams: nextProps.allTeams })
    }

    getTeamData = (key, val, data?) => { 
        this.setState({ 
            [key]: val,
            teamMembersDetails: data
        });
    }

    getMembers = () => {
        return(
            <div className='text-white p-2 d-flex flex-column' style={{maxHeight: '400px'}}>
                <div className='row'>
                    <div className='col-2'></div>
                    <div className='col-8'>
                        <p className='fs-16 fs-lg-20 fw-700 text-center my-0'>{this.props.heading} members</p>
                    </div>
                    <div className='col-2 text-end'>
                        <IoIosCloseCircleOutline className='cursor-pointer my-0' style={{height: '20px', width: '20px'}} onClick={() => this.props.updateStateKey('showMembers', false)} />
                    </div>
                </div>
                <div className='row my-2 overflow-auto'>
                    {/* members */}
                    {this.props.clanMembers.map((member, i) =>{
                        return(
                            <div className='col-12 my-1' key={i}>
                                <div className='den-members-div p-1'>
                                    <Image src={member?.profilePicture?.url} className='den-members-icon mx-3' params={member?.username} />
                                    <p className='fs-12 fs-lg-16 fw-700 my-0 mx-3 flex-grow-1'>{member?.username}</p>
                                    <p className='fs-12 fs-lg-16 fw-400 my-0 mx-3 opacity-50'>{member?.role}</p>
                                </div>
                            </div>
                        )
                    })}
                    {/* TEAMS */}
                    {this.state.allTeams?.length ? 
                        this.state.allTeams?.map((team, i) => {
                            return(
                                <div className='col-12 my-1' key={i}>
                                    <div className='den-members-div p-1'>
                                        <Image src={team?.logo?.url} className='den-members-icon mx-3' params={team.name} />
                                        <p className='fs-12 fs-lg-16 fw-700 my-0 mx-3 flex-grow-1'>{team.name}</p>
                                        <Button variant='primary' size='sm' onClick={() => this.getTeamData('showTeamDetails', true, team)}>View Details</Button>
                                    </div>
                                </div>
                            )
                        })
                        :
                        null
                    }
                </div>
            </div>     
        )
    }

    getTeamMembersView = () => {
        const {teamMembersDetails} = this.state; 

        const teamMembers = getTeamMembers(teamMembersDetails);

        console.log('Members ::', teamMembers);
        return(
            <div className='text-white p-2 d-flex flex-column' style={{maxHeight: '400px'}}>
                <div className='row'>
                    <div className='col-2'>
                        <IoIosArrowBack className='cursor-pointer my-0' style={{height: '20px', width: '20px'}} onClick={() => this.getTeamData('showTeamDetails', false, null)} />
                    </div>
                    <div className='col-8'>
                        <p className='fs-16 fs-lg-20 fw-700 text-center my-0'>Team members</p>
                    </div>
                    <div className='col-2 text-end'>
                        <IoIosCloseCircleOutline className='cursor-pointer my-0' style={{height: '20px', width: '20px'}} onClick={() => this.props.updateStateKey('showMembers', false)} />
                    </div>
                </div>
                <div className='column-center-align-div'>
                    <Image src={teamMembersDetails?.logo?.url} className='rounded-circle' params={teamMembersDetails?.name} style={{height: '80px', width: '80px'}} />
                    <p className='fs-16 fs-lg-20 fw-700 my-0'>{teamMembersDetails?.name}</p>
                </div>
                <div className='row my-2 overflow-auto'>
                    {/* Members */}
                    {teamMembers.length ? 
                        teamMembers.map((member, i) => {
                            return(
                                <div className='col-12 my-1' key={i}>
                                    <div className='den-members-div p-1'>
                                        <Image src={member.profilePicture.url} className='den-members-icon mx-3' params={member.inGameName} />
                                        <p className='fs-12 fs-lg-16 fw-700 my-0 mx-3 flex-grow-1'>{member.inGameName}</p>
                                        <p className='fs-12 fs-lg-16 fw-400 my-0 mx-3 opacity-50'>{member.role}</p>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <p className='fs-12 fs-lg-16 fw-700 text-center'>This team is currently empty</p>
                    }
                </div>
            </div>     
        )
    }

    render() {
        return this.state.showTeamDetails ? this.getTeamMembersView() : this.getMembers()
    }
}

