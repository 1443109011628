import React, { Component } from 'react';
import Image from '../../../common/Image';
import { FaGlobeAsia } from 'react-icons/fa';
import bioIcon from '../../../../images/icon/bio-icon.png';
import avatar from '../../../../images/participantAvtar.jpg';
import { NavLink } from 'react-router-dom';
import { capitalize } from '../../../../utils/common.utils';
import { PROFILE_PAGE_IMAGES } from '../../../../utils/Images.utils';
import { Accordion, Carousel, Modal } from 'react-bootstrap';
import { DEN } from '../../../../utils/Strings.utils';
import ImageGrid from '../../../common/ImageGrid';
import SideSkeleton from '../../../common/SideSkeleton';
import InnerCircle from './InnerCircle';
import { getDenProfilePicture } from '../../../../utils/Den.utils';

const sponsorDetails = [
    {
        "_id": "62e3acc1dee2e3001374e61a",
        "name": "Sponsor 1",
        "type": "Powered By",
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/5db1aa70-0f23-11ed-8be9-277906d0995e",
            "name": "file",
            "size": 60492,
            "type": "image/jpeg"
        },
        "isDeleted": false,
        "__v": 0,
        "createdAt": "2022-07-29T09:47:45.516Z",
        "updatedAt": "2022-07-29T09:47:45.516Z"
    },
    {
        "_id": "62e3acc1dee2e3001374e61b",
        "name": "Sponsor 2",
        "type": "Co-Powered By",
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/7127d020-0f23-11ed-8be9-277906d0995e",
            "name": "file",
            "size": 73933,
            "type": "image/jpeg"
        },
        "isDeleted": false,
        "__v": 0,
        "createdAt": "2022-07-29T09:47:45.516Z",
        "updatedAt": "2022-07-29T09:47:45.516Z"
    },
    {
        "_id": "62e3acc1dee2e3001374e61c",
        "name": "Sponsor 3",
        "type": "Associate Sponsor",
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/7bafbc60-0f23-11ed-8be9-277906d0995e",
            "name": "file",
            "size": 59950,
            "type": "image/jpeg"
        },
        "isDeleted": false,
        "__v": 0,
        "createdAt": "2022-07-29T09:47:45.516Z",
        "updatedAt": "2022-07-29T09:47:45.516Z"
    },
    {
        "_id": "634e54fe2ba1b1001274908b",
        "name": "Virtoustack",
        "type": "Sponsored By",
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/df1006d0-4eb5-11ed-8f2b-af9194dba777",
            "name": "file",
            "size": 343026,
            "type": "image/jpeg"
        },
        "isDeleted": false,
        "__v": 0,
        "createdAt": "2022-10-18T07:25:50.835Z",
        "updatedAt": "2022-10-18T07:25:50.835Z"
    },
    {
        "_id": "634e54fe2ba1b1001274908c",
        "name": "Barkelys",
        "type": "Co-sponsored By",
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/565862b0-4eba-11ed-8f2b-af9194dba777",
            "name": "file",
            "size": 54133,
            "type": "image/jpeg"
        },
        "isDeleted": false,
        "__v": 0,
        "createdAt": "2022-10-18T07:25:50.835Z",
        "updatedAt": "2022-10-18T07:25:50.835Z"
    },
    {
        "_id": "634e54fe2ba1b1001274908d",
        "name": "Gera",
        "type": "Powered By",
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/147a30c0-4eb6-11ed-8f2b-af9194dba777",
            "name": "file",
            "size": 580437,
            "type": "image/jpeg"
        },
        "isDeleted": false,
        "__v": 0,
        "createdAt": "2022-10-18T07:25:50.835Z",
        "updatedAt": "2022-10-18T07:25:50.835Z"
    }
]

export default class ClanLeftTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserDenProfile: props.currentUserDenProfile,
            denAchivements: props.denAchivements,
            showInnerCircle: false
        }
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            currentUserDenProfile: nextProps.currentUserDenProfile,
            denAchivements: nextProps.denAchivements
        });
    }

    updateState = (obj) => {
        this.setState(obj)
    }

    getClanMembers = () => {
        const clanMembers = [];
        this.state.currentUserDenProfile?.members?.owner?.map(member => clanMembers.push({...member, role: 'Owner'}));
        if(this.state.currentUserDenProfile?.members?.admin?.length){
            this.state.currentUserDenProfile?.members?.admin?.map(member => clanMembers.push({...member, role: 'Admin'}));
        }
        //console.log('clan members:: ', clanMembers);
        return (
            <div className='cursor-pointer profile-members-box p-2 my-3' onClick={() => this.props.updateState('showMembers', true)}>
                <p className='fs-14 fw-700 opacity-75 my-0 text-center'>Members</p>
                {clanMembers?.length >=5 ? 
                    <div className='d-flex justify-content-center align-items-center my-2'>
                        {clanMembers.slice(0, 5)?.map((member,i)=>{
                            return <Image src={member.profilePicture.url} param={member.username ? member.username : 'TEST'} className='rounded-circle' style={i===0 ? {width:'30px',height: '30px'}:{width:'30px', marginLeft:'-15px',height: '30px'}} key={i} />
                        })}
                        <div className='extra-numbers-box d-flex justify-content-center align-items-center' style={{marginLeft:'-15px'}}>+{clanMembers.length-5}</div>
                    </div>
                    :
                    <div className='d-flex justify-content-center align-items-center my-2'>
                        {clanMembers.map((member,i) =>{
                            return (
                                <Image src={member.profilePicture.url} param={member.username ? member.username : 'TEST'} className='rounded-circle' style={i===0 ? {width:'30px',height: '30px'}:{width:'30px', marginLeft:'-15px',height: '30px'}} key={i} />
                            )
                        })}
                    </div>
                }
            </div>
        )
    }

    getSponsors = () => {
        // const tier1 = sponsorDetails?.filter(sponsor => (sponsor.type === 'Powered By') || (sponsor.type === 'Sponsored By'));
        // const tier2 = sponsorDetails?.filter(sponsor => (sponsor.type === 'Co-Powered By') || (sponsor.type === 'Co-sponsored By'));
        // const tier3 = sponsorDetails?.filter(sponsor => (sponsor.type === 'Associate Sponsor') || (sponsor.type === 'Streaming Partner'));
        // console.log(tier1, tier2, tier3);
        return (
            <div className='row'>   
                <Carousel className={`${ sponsorDetails.length > 1 ? '' : 'single'}`}>
                    {sponsorDetails.map((sponsor,i)=>{
                        return(
                            <Carousel.Item key={i} interval={null}>
                                <div className='col-12 text-center'>
                                    <p className='den-sponsor fs-20 my-1'>{sponsor.type}</p>
                                    <p className='fs-16 fw-600 text-white-50 my-0'>{sponsor.name}</p>
                                    <Image src={sponsor.logo.url} param={sponsor.name} alt={sponsor.name} className='' style={{width:'auto', height: 'auto', maxHeight: '210px', maxWidth: '100%'}} />
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </div>
        )
    }

    showHideBioFlag = () => {
        const { currentUserDenProfile} = this.state;
        switch (currentUserDenProfile.type) {
            case DEN.SEARCH.TYPES.USER:
                return currentUserDenProfile?.userDetails?.verificationStatus === 'VERIFIED' ?  true : false;
            case DEN.SEARCH.TYPES.CLAN:
                return currentUserDenProfile?.clanDetails?.verificationStatus === 'VERIFIED' ?  true : false;
            case DEN.SEARCH.TYPES.ORG:
                return currentUserDenProfile?.orgDetails?.verificationStatus === 'VERIFIED' ?  true : false;
            default:
                return currentUserDenProfile?.userDetails?.verificationStatus === 'VERIFIED' ?  true : false;
        }
    }

    getMoreStats = () => {
        const {currentUserDenProfile, denAchivements} = this.state;
        const achivementsUsers = denAchivements?.achivements?.map((achive)=> {return achive.from});

        const topFansUsers = denAchivements?.topFans?.map((achive)=> {return achive.from})

        const innerCircle = this.state.denAchivements?.topFans?.filter((user)=> user.totalSpotlights > 0 ); //?.slice(0, 10);
        const sortedInnerCircle  = innerCircle?.sort((a, b) => parseInt(b.totalSpotlights) - parseInt(a.totalSpotlights));
        const innerCircleProfiles = sortedInnerCircle?.map((topFan)=>{
            return {
                from: topFan.from,
                profilePicture: getDenProfilePicture(topFan.from),
                spotlight: topFan.totalSpotlights
            };
        })
        return(
            <div className="p-0">
                {/* STATS */}
                <div className='d-flex align-items-center'>
                    <p className='fs-16 fw-bold text-white-50 my-0'>Stats</p>
                    <FaGlobeAsia className='mx-2' />
                </div>
                <div className='d-flex align-items-center w-100 my-2'>
                    {/* MATCHES */}
                    <div className={(this.props.ownDenProfile || currentUserDenProfile?.publicStatus?.matchPlayed) ? 'column-center-align-div me-2' : 'd-none'} style={{color: '#FF11E7'}}>
                        <img src={PROFILE_PAGE_IMAGES.MATCHES} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.totalMatches? denAchivements.totalMatches : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>TOTAL</span>
                    </div>
                    {/* WINS */}
                    <div className={this.props.ownDenProfile || (currentUserDenProfile?.publicStatus?.matchWin && currentUserDenProfile?.type === 'CLAN') ? 'column-center-align-div me-2' : 'd-none'} style={{color: '#49FF2C'}}>
                        <img src={PROFILE_PAGE_IMAGES.WINS} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.totalWins? denAchivements.totalWins : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>WINS</span>
                    </div>
                    {/* STARS */}
                    <div className={true ? 'column-center-align-div me-2' : 'd-none'} style={{color: '#E2782C'}}>
                        <img src={PROFILE_PAGE_IMAGES.STAR} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.stars? denAchivements.stars : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>STARS</span>
                    </div>
                    {/* SPOTLIGHT */}
                    <div className={(this.props.ownDenProfile || currentUserDenProfile?.publicStatus?.spotlightCollection) ? 'column-center-align-div' : 'd-none'} style={{color: '#FFE000'}}>
                        <img src={PROFILE_PAGE_IMAGES.SPOTLIGHTS} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.totalSpotLights? denAchivements.totalSpotLights : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>SPOTLIGHT</span>
                    </div>
                </div>
                <p className='fs-16 fw-700 cursor-pointer text-white-50' onClick={()=> this.props.checkStatStatus('showGameStats', true)}>{`Click here to see detailed game stats >`}</p>

                <div className='row'>
                    <div className='col-12'>
                        <div className='profile-stats-1 d-flex justify-content-around align-items-center p-2'>
                            <div className='row'>
                                {/* Achievements */}
                                <div className='col-5'>
                                    <div className='cursor-pointer' onClick={() => this.props.toggleAchievementsPopup(true, DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY)}>
                                    <p className='fs-14 fw-700 opacity-75 my-0'>Achievements</p>
                                    {achivementsUsers.length ? <ImageGrid users={achivementsUsers} /> : <p className='fs-12 fw-600 text-center my-0'>N/A</p>}
                                </div>
                                </div>
                                <div className='col-2'><hr className='vertical-hr-line' /></div>
                                <div className='col-5'>
                                    {/* Top Fans */}
                                    <div className='cursor-pointer' onClick={() => this.props.toggleAchievementsPopup(true, DEN.ACHIEVEMENTS.TYPE.TOP_FAN.KEY)}>
                                        <p className='fs-14 fw-700 opacity-75 my-0'>Top Fans</p>
                                        {topFansUsers.length ? <ImageGrid users={topFansUsers} /> : <p className='fs-12 fw-600 text-center my-0'>N/A</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* INNER CIRCLE */}
                    <div className='col-6 my-2'>
                        <div className='profile-stats-2 p-2 cursor-pointer' style={{minHeight: '80px'}} onClick={() => innerCircleProfiles.length ? this.updateState({showInnerCircle: true}) : null}>
                            {/* <NavLink to={`/den/${this.state.currentUserDenProfile?._id}/inner-circle`} style={{color: 'white'}}> */}
                                <p className='fs-14 fw-700 opacity-75 my-0 text-center'>Inner Circle</p>
                                {innerCircleProfiles.length ? <ImageGrid users={innerCircle.map(user => user.from)} /> : <p className='fs-12 fw-600 text-center my-0'>N/A</p>}
                            {/* </NavLink> */}
                        </div>
                    </div>
                    {/* RANK */}
                    <div className='col-6 my-2'>
                        <div className='profile-stats-3 p-2' style={{minHeight: '80px'}}>
                            <p className='fs-14 fw-700 opacity-75 my-0 text-center'>Profile Rank</p>
                            <p className='fs-16 fw-700 text-center' style={{color: '#00FFD1'}}>{denAchivements?.profileRank? denAchivements.profileRank : null}</p>
                        </div>
                    </div>
                </div>

                {/* BROADCASTING CHANNELS */}
                <div className='my-3'>
                    <p className='fs-16 fw-700 text-white-50'>Broadcasting Channels</p>
                    <div className='row'>
                        {currentUserDenProfile?.socialMedia?.length ? 
                            currentUserDenProfile?.socialMedia?.map((media,i) => {
                                return(
                                    <div className='col-3 my-1' key={i}>
                                        <a href={media.value} target='_blank' rel="noreferrer">
                                            <div className='column-center-align-div me-2'>
                                                <img src={media.denUrl} alt='' className='cursor-pointer' style={{width:'32px' }} />
                                                <span className='fs-10 fw-500 text-white'>{capitalize(media.id)}</span>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                            :
                            <p className='fs-14 fw-500 text-white'>No social media found</p>
                        }
                    </div>
                </div>

                {/* SPONSORS */}
                {currentUserDenProfile?.type === DEN.SEARCH.TYPES.ORG  && this.getSponsors()}
                <Modal centered show={this.state.showInnerCircle}>
                    <InnerCircle updateState={this.updateState} currentUserDenProfile={currentUserDenProfile} innerCircleProfiles={innerCircleProfiles} />
                </Modal>
            </div>
        )
    }

    render() {
        const profileCard  = { background: '#231D36',  borderRadius: '8px' };
        const {currentUserDenProfile, denAchivements} = this.state;
        if(currentUserDenProfile && denAchivements){

        

        const showBioFlag = this.showHideBioFlag();

        return (
            <div className='col-12 col-lg-3'>
                <div className='profile-card-bg p-2' style={profileCard}>
                    <div className='d-flex align-items-center'>
                        <img src={bioIcon} alt='bio' className='me-3' style={{width:'20px'}} />
                        <p className='fs-12 fs-lg-16 fw-600 my-0 flex-grow-1'>Bio</p>
                        <Image src={PROFILE_PAGE_IMAGES.BIOFLAG} param='BIO' alt='star' className='me-3' style={{width:'26px', display: showBioFlag ? 'block' : 'none'}} />
                    </div>
                    {/* BIO */}
                    {/* <p className='fs-14 fw-400 text-white-50 my-2'>{currentUserDenProfile?.bio}</p> */}
                    <p className='fs-14 fw-400 text-white-50 my-2'>{currentUserDenProfile?.type === DEN.SEARCH.TYPES.USER ? currentUserDenProfile?.bio : this.props.detailsObj?.bio}</p>
                    {/* MEMBERS */}
                    {this.getClanMembers()}
                    <section className='den-achievements d-block d-lg-none'>
                        <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className='d-flex align-items-center p-0 my-0 flex-grow-1'>
                                    <p className='fs-14 fw-bold my-0' style={{color:'#AD26FF'}}>More Details</p>
                                    <hr className='flex-grow-1 mx-2'style={{color:'#AD26FF', height:'2px'}} />
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className='p-1'>
                                {this.getMoreStats()}
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </section>
                    <div className='p-0 d-none d-lg-block'>{this.getMoreStats()}</div>
                </div>
            </div>
        )
        }else{
            return (
                <div className='col-12 col-lg-3 my-1'>
                    <SideSkeleton />;
                </div>
            )
        }
    }
}