import { Component } from 'react'
import { AiFillCloseCircle } from "react-icons/ai";

export default class TermsConditions extends Component {
    render() {
        const terms = this.props.terms;
        return (
            <div>
                <div className='social-media-container p-4'>
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-8'>
                            <p className='fs-16 fw-700 text-white text-center m-0'>Terms and Conditions</p>
                        </div>
                        <div className='col-2 px-0 d-flex'>
                            <AiFillCloseCircle className="close-icon ms-auto" onClick={() => { this.props.onClose() }} />
                        </div>
                    </div>
                    
                    

                    {/* <p className='fs-14 fw-400 text-white text-center my-3'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consectetur aliquam dolor id volutpat. Suspendisse neque est, accumsan tempor placerat in, vulputate eu eros.
                        Suspendisse ullamcorper justo non orci ultrices, eget suscipit purus tincidunt.
                        Duis eu tempus eros. In ornare neque id enim laoreet, non mattis ex bibendum. Fusce commodo nulla urna, sit amet scelerisque metus bibendum in.
                    </p> */}
                    <ol className="ps-0 my-3" type='1'>
                        {this.props.terms?.length ? 
                            this.props.terms?.map((el, i) => { 
                                return <li className='fs-14 fw-400 text-white py-0' key={i}>{el.description}</li>
                            })
                            :
                            null
                        }
                    </ol>
                    <div className='d-flex justify-content-evenly mt-3'>
                        <button className='white-flat-btn fw-bold py-2 px-4' onClick={() => this.props.accept()}>
                            Accept
                        </button>
                        <button className='white-flat-btn fw-bold py-2 px-4' onClick={() => this.props.onClose()}>Deny</button>
                    </div>
                </div>
            </div>
        )
    }
}
