import { Component, createRef } from "react";
import { NavLink } from "react-router-dom";
// Assets
import { FiEye } from "react-icons/fi";
import layoutOne from "../../../images/layout1.png";
import layoutTwo from "../../../images/layout2.png";
import layoutThree from "../../../images/layout3.png";
import microSettingIc from "../../../images/microSettingIc.png";
import eyeIc from "../../../images/icon/eye-ic.png";
import { AiOutlineCloudUpload, AiFillCloseCircle, AiOutlineMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { BsArrowRight } from 'react-icons/bs';
// Utils
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import { capitalize, errorHandler, loadCountryCodes, updateLoader, defaultLogo, defaultCover } from "../../../utils/common.utils";
import ColorPicker from '../../../components/common/ColorPicker';
// Services
import { AxiosResponse, AxiosError } from "axios";
import CommonApis from "../../../helper/common.api";
import MicroWebSiteApis from "../../../helper/microwebsite.api";
import OrganizationApis from "../../../helper/organization.api";
// Modals
import { Modal } from "react-bootstrap";
import OrgAddMoreDetails from "../../popup/OrgAddMoreDetails";
import ConfirmationModal from "../../popup/ConfirmationModal";
import Sponsor from "../../popup/Sponsor";
// Layouts
import LayoutOne from "../layouts/LayoutOne";
import LayoutTwo from "../layouts/LayoutTwo";
import LayoutThree from "../layouts/LayoutThree";
import { HEADING, SOCIAL_MEDIA_PLATFORM } from "../../../utils/Strings.utils";

const DELAY = 1500;

export default class MicroWebsiteConfiguration extends Component {

  constructor(props) {

    super(props);
    this.state = {
      orgCustomImage: null,
      customImages: [null, null, null, null, null, null],
      selectLayout: 0,
      logoImage: null,
      bannerImage: null,
      aboutUsImage: null,
      sponsorImage: null,
      selectedLayout: "one",
      openSponsor: false,
      reqErrorMsg: "",
      openAddMoreDetails: false,
      appendDetails: false,
      extraDetails: this.extraDetails,
      microWebSiteData: {},
      create: true,
      invalidImage: null,
      isTime: false,
      sponsorForUpdate: null,
      sponsorIndex: null,
      openConfirm: false,
      deleteSponsorId: null,
      confirm: {},
      openPreview: false,
      previewData: {},
      color: {
        background: '#620ff0',
        primary: '#cfb5fa',
        secondary: '#9a65f6'
      },
      organizationData: {},
      aboutUsText: '',
      countryCodes: []
    };

   

    // Services
    this.commonApi = new CommonApis();
    this.organizationApi = new OrganizationApis();
    this.microWebSiteService = new MicroWebSiteApis();
    // Initials
    this.formikRef = createRef();
    this.organizationId = null;
    this.sponsorForUpdate = {};
    this.formTempData = {};
    this.customImageIndex = 0;
    this.sponsorArray = [];
    //Social media array
    this.extraDetails = [
      { label: SOCIAL_MEDIA_PLATFORM.INSTAGRAM.LABEL, key: SOCIAL_MEDIA_PLATFORM.INSTAGRAM.KEY, value: '', isChecked: false, error: '' },
      { label: SOCIAL_MEDIA_PLATFORM.FACEBOOK.LABEL, key: SOCIAL_MEDIA_PLATFORM.FACEBOOK.KEY, value: '', isChecked: false, error: '' },
      { label: SOCIAL_MEDIA_PLATFORM.TWITTER.LABEL, key: SOCIAL_MEDIA_PLATFORM.TWITTER.KEY, value: '', isChecked: false, error: '' },
      { label: SOCIAL_MEDIA_PLATFORM.TELEGRAM.LABEL, key: SOCIAL_MEDIA_PLATFORM.TELEGRAM.KEY, value: '', isChecked: false, error: '' },
      { label: SOCIAL_MEDIA_PLATFORM.YOUTUBE.LABEL, key: SOCIAL_MEDIA_PLATFORM.YOUTUBE.KEY, value: '', isChecked: false, error: '' },
      { label: SOCIAL_MEDIA_PLATFORM.LOCO.LABEL, key: SOCIAL_MEDIA_PLATFORM.LOCO.KEY, value: '', isChecked: false, error: '' },
      { label: SOCIAL_MEDIA_PLATFORM.TWITCH.LABEL, key: SOCIAL_MEDIA_PLATFORM.TWITCH.KEY, value: '', isChecked: false, error: '' }
    ];
    // Fetch initial values
    this.getOrgData(props.match.params.id.replace("config-", ""));

  }

  componentDidMount() {
    // To enable navigation
    this.props.showOrHideNav(true);
     // To fetch country codes from common utils
     loadCountryCodes(this.setCountryCodes);
    // Reset initial values
    setTimeout(() => { this.setState({ load: true, isTime: true }) }, DELAY);
    if (this.props.match.params.id.includes("config-")) {
      this.setState({ create: false });
      this.getData();
    }
  }

  // To fetch additional organization details
  getOrgData(id) {
    const data = {
      _id: id
    }
    this.organizationApi.getOrganizations(data).then((res: AxiosResponse) => {
      let details = this.state.extraDetails;
      let orgMediaDetails = res.data.result[0].socialMediaDetails;

      details = [
        { label: SOCIAL_MEDIA_PLATFORM.INSTAGRAM.LABEL, key: SOCIAL_MEDIA_PLATFORM.INSTAGRAM.KEY, value: orgMediaDetails.instagram ? orgMediaDetails.instagram : '', isChecked: false, error: '' },
        { label: SOCIAL_MEDIA_PLATFORM.FACEBOOK.LABEL, key: SOCIAL_MEDIA_PLATFORM.FACEBOOK.KEY, value: orgMediaDetails.facebook ? orgMediaDetails.facebook : '', isChecked: false, error: '' },
        { label: SOCIAL_MEDIA_PLATFORM.TWITTER.LABEL, key: SOCIAL_MEDIA_PLATFORM.TWITTER.KEY, value: orgMediaDetails.twitter ? orgMediaDetails.twitter : '', isChecked: false, error: '' },
        { label: SOCIAL_MEDIA_PLATFORM.TELEGRAM.LABEL, key: SOCIAL_MEDIA_PLATFORM.TELEGRAM.KEY, value: orgMediaDetails.telegram ? orgMediaDetails.telegram : '', isChecked: false, error: '' },
        { label: SOCIAL_MEDIA_PLATFORM.YOUTUBE.LABEL, key: SOCIAL_MEDIA_PLATFORM.YOUTUBE.KEY, value: orgMediaDetails.youtube ? orgMediaDetails.youtube : '', isChecked: false, error: '' },
        { label: SOCIAL_MEDIA_PLATFORM.LOCO.LABEL, key: SOCIAL_MEDIA_PLATFORM.LOCO.KEY, value: orgMediaDetails.loco ? orgMediaDetails.loco : '', isChecked: false, error: '' },
        { label: SOCIAL_MEDIA_PLATFORM.TWITCH.LABEL, key: SOCIAL_MEDIA_PLATFORM.TWITCH.KEY, value: orgMediaDetails.twitch ? orgMediaDetails.twitch : '', isChecked: false, error: '' }
      ];

      this.setState({
        logoImage: res.data.result[0].logo,
        bannerImage: res.data.result[0].coverImage,
        organizationData: res.data.result[0],
        extraDetails: details
      });

    }).catch((err: AxiosError) => {
      errorHandler(err, this.props.logout);
    });
  }

  // Sponsor delete confirmation msg
  sponsorDeleteMsg = (name) => {
    return (
      <p className="fs-14 fw-600 text-center text-white-50">
        Are you sure you want to delete {name} from sponsors?
      </p>
    );
  };

  // Fetch initial site values
  getData() {
    var requestBody = {
      organizationId: this.props.match.params.id.replace("config-", ""),
    };
    this.organizationId = this.props.match.params.id.replace("config-", "")
    this.props.updateLoader(true);
    this.microWebSiteService
      .getSingleMicroWebsite(requestBody)
      .then((response: AxiosResponse) => {
        const data = response.data.result[0];
        let customeImg = [];
        for (let i = 0; i < this.state?.customImages.length; i++) {
          if (data?.customImage[i]) {
            customeImg.push(data?.customImage[i]);
          } else {
            customeImg.push(null);
          }
        }
        for (let i = 0; i < this.extraDetails.length; i++) {
          if (data?.contactDetails[this.extraDetails[i].label]) {
            this.extraDetails[i].value =
              data?.contactDetails[this.extraDetails[i].label];
            this.extraDetails[i].isChecked = true;
          }
        }

        this.setState({
          microWebSiteData: data,
          selectedLayout: data?.layout ? data?.layout : this.state.selectedLayout,
          color: data?.color ? data?.color : this.state.color,
          logoImage: data?.logo ? data.logo : null,
          bannerImage: data?.banner ? data.banner : null,
          aboutUsImage: data?.aboutUs?.img ? data.aboutUs.img : null,
          customImages: customeImg,
          extraDetails: this.extraDetails,
          aboutUsText: data?.aboutUs?.bio
        });
        this.sponsorArray = data?.sponsors ? data.sponsors : [];
        this.props.updateLoader(false);
      })
      .catch((reason: AxiosError) => {
        this.props.updateLoader(false);
        if (reason.response?.status === 404) {
          // this.props.updateLoader(false);
        } else {
          errorHandler(reason, this.props.logout);
        }
      });
  }

  // Temp formik value saved
  formValueChange = (e, key) => {
    this.formTempData[key] = e.target.value;
  };

  // Reset Custom images
  resetCustomImage = (e, i) => {
    e.preventDefault();
    var customImagesArray = this.state.customImages;
    customImagesArray[i] = null;
    this.setState({ customImages: customImagesArray });
  };

  // Open upload media
  triggerInputFile = () => this.fileInput.click();
  triggerInputFile2 = () => this.fileInput2.click();
  triggerInputFile3 = (e, index) => {
    e.preventDefault();
    this.customImageIndex = index;
    this.fileInput3.click();
  };
  triggerInputFile4 = () => this.fileInput4.click();
  triggerInputFile5 = () => this.fileInput5.click();

  // Toggle Modals
  toggleModal = (key) => {
    this.setState({
      [key]: !this.state[key],
    });
  };

  // Upload image
  onImageChange = (event, type) => {
    event.preventDefault();

    this.setState({
      invalidImage: null,
    });

    //type : bannerImage, logoImage
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.warning('Please select a valid image type.');
        return false;
      }
      this.setState({
        [type]: URL.createObjectURL(img), //loader image
      });

      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      this.props.updateLoader(true)
      this.commonApi.uploadSingleImg(formData).then((response: AxiosResponse) => {
        this.props.updateLoader(false)
        this.setState({
          [type]: response.data.result, //
          reqErrorMsg: null,
        });
      }).catch((err: AxiosError) => {
        errorHandler(err, this.props.logout);
        this.setState({
          [type]: null,
        });
        this.props.updateLoader(false)
      }); //set null to image and display error message in toast
    }
  };

  // Clear Images
  onClearImage = (event, type) => {
    event.preventDefault();
    this.setState({
      [type]: null,
    });
  };

  // Upload Custom Images
  onCustomImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.warning('Please select a valid image type.');
        return false;
      }
      var arr = this.state.customImages;
      arr[this.customImageIndex] = URL.createObjectURL(img);
      this.setState({
        customImages: arr,
        orgCustomImage: URL.createObjectURL(img),
      });
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      this.props.updateLoader(true);
      this.commonApi.uploadSingleImg(formData).then((response: AxiosResponse) => {
        this.props.updateLoader(false);
        var customImagesArray = this.state.customImages;
        customImagesArray[this.customImageIndex] = response.data.result;
        this.setState({
          customImages: customImagesArray,
        });
      }).catch((err: AxiosError) => {
        console.log(err)
        this.props.updateLoader(false);
      });
    }
  };

  //For re-capcha
  handleChange = (value) => {
    this.setState({ recaptcha: value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };

  // To select Colors
  selectColor = (e, type, index) => {
    e.preventDefault();
    const data = this.state.color;
    data[type] = index;
    this.setState({
      color: data,
    });
  };

  // Toggle Previous
  changeActivePreview = (e) => {
    this.setState({ selectedLayout: e.target.id });
  };

  // To close sponsor modal
  closeSponsor = () => {
    this.setState({
      sponsorForUpdate: null,
      openSponsor: false,
      sponsorImage: null,
    });
  };

  // To Open confirmation popup
  onClickOpenConfirm = (key?, msg?) => {
    const data = {
      msg: msg ? msg : "",
      key: key ? key : "",
    };
    this.setState({
      openConfirm: !this.state.openConfirm,
      confirm: data,
    });
  };

  // Add Sponsor
  addSponsor = (values, logo) => {
    this.setState({
      invalidImage: null,
    });

    const data = {
      name: values.name,
      type: values.type,
      logo: logo,
      isDeleted: false,
    };
    this.sponsorArray.push(data);
    this.setState({ openSponsor: false, sponsorImage: null });
    this.closeSponsor();
  };

  // Update Sponsor
  updateSponsor = (values, logo) => {
    if (!logo) {
      this.setState({ reqErrorMsg: "Upload sponsor image" });
      return false;
    }

    const data = this.state.sponsorForUpdate;
    data.logo = logo;
    data.name = values.name;
    data.type = values.type;
    this.sponsorArray[this.state.sponsorIndex] = data;

    if (values.id) {
      this.props.updateLoader(true);
      this.microWebSiteService.updateSponsor(data).then((res: AxiosResponse) => {
        this.props.updateLoader(false);
        this.closeSponsor();
        toast.success("Sponsor updated successfully");
      }).catch((err: AxiosError) => {
        this.closeSponsor();
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      });
    } else {
      this.closeSponsor();
    }

  };

  // To Delete Sponsor Details
  deleteSponsor = () => {
    this.props.updateLoader(true);
    this.microWebSiteService.deleteSponsor(this.state.deleteSponsorId).then((res: AxiosResponse) => {
      this.sponsorArray.splice(this.state.sponsorIndex, 1);
      this.setState({
        sponsorIndex: null,
        deleteSponsorId: null,
        openConfirm: false,
      });
      this.props.updateLoader(false);
      toast.success("Sponsor deleted succesfully");
    }).catch((err: AxiosError) => {
      this.props.updateLoader(false);
      errorHandler(err, this.props.logout);
    });
  };

  // To Edit Sponsor Details
  openEditSponsor = (el, i) => {
    const data = el;
    data.index = i;
    this.setState({
      sponsorForUpdate: data,
      sponsorIndex: i,
      sponsorImage: el.logo,
    });
  };

  // Open confirmation modal
  confirmed = (key) => {
    if (key === "deleteSponsor") {
      this.deleteSponsor();
    } else if (key === "deleteTempSponsor") {
      this.sponsorArray.splice(this.state.sponsorIndex, 1);
      this.setState({ openConfirm: false })
    }
  };

  // Create website
  onSubmit = (values, isSubmit) => {
    if (this.state.isTime) {
      const data = {
        organizationId: this.props.match.params.id.replace("config-", ""),
        title: values.customHeading,
        layout: this.state.selectedLayout,
        color: this.state?.color,
        logo: this.state?.logoImage ? this.state.logoImage : defaultLogo,
        banner: this.state?.bannerImage ? this.state?.bannerImage : defaultCover,
        customImage: [],
        aboutUs: {
          bio: values.aboutUs,
          img: this.state.aboutUsImage ? this.state.aboutUsImage : defaultCover,
        },
        contactDetails: {
          whatsapp: { code: values.countryCode, number: values.Whatsapp },
        },
        sponsors: this.sponsorArray,
        isDeleted: false,
      };
      this.state.customImages.map((el) => {
        if (el?.url) {
          data?.customImage.push(el);
        }
      });
      this.state.extraDetails.map((el) => {
        if (el.value) {
          data.contactDetails[el.label] = el.value;
        }
      });

      if (isSubmit) {
        this.props.updateLoader(true);
        this.microWebSiteService.createMicroWebsite(data).then((res: AxiosResponse) => {
          toast.success("Micro website created succesfully");
          this.props.updateLoader(false);
          this.props.history.push("/organizations");
        }).catch((err: AxiosError) => {
          this.props.updateLoader(false);
          errorHandler(err, this.props.logout);
        });
      } else {
        data.aboutUs.bio = this.formTempData.bio;
        data.title = this.formTempData.title;
        data.contactDetails.whatsapp = this.formTempData.whatsapp;
        this.setState({
          openPreview: true,
          previewData: data,
        });
      }
    }
  };

  // Update Website
  onUpdate = (values) => {
    global.countryCode = values.countryCode
    const data = {
      organizationId: this.props.match.params.id.replace("config-", ""),
      title: values.customHeading,
      layout: this.state.selectedLayout,
      color: this.state.color,
      logo: this.state?.logoImage ? this.state.logoImage : defaultLogo,
      banner: this.state?.bannerImage ? this.state?.bannerImage : defaultCover,
      customImage: [],
      aboutUs: {
        bio: values.aboutUs,
        img: this.state.aboutUsImage ? this.state.aboutUsImage : defaultCover,
      },
      contactDetails: {
        whatsapp: { code: values.countryCode, number: values.Whatsapp },
      },
      sponsors: this.sponsorArray,
      isDeleted: this.state.microWebSiteData?.isDeleted,
    };
    this.state.customImages.map((el) => {
      if (el?.url) {
        data?.customImage.push(el);
      }
    });
    this.state.extraDetails.map((el) => {
      if (el.value) {
        data.contactDetails[el.label] = el.value;
      }
    });
    this.props.updateLoader(true);
    this.microWebSiteService
      .updateMicroWebsite(this.state.microWebSiteData._id, data)
      .then((res: AxiosResponse) => {
        toast.success("Micro website updated successfully");
        this.props.updateLoader(false);
        this.props.history.push(`/organization/view/${data.organizationId}`);
      })
      .catch((err: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      });
  };

  // Add more details toggle
  toggleAddMoreDetails = (e) => {
    e.preventDefault();
    this.setState({ openAddMoreDetails: !this.state.openAddMoreDetails });
  };

  // Render added details
  renderAddedDetails = (details) => {
    this.setState({ extraDetails: details, openAddMoreDetails: false });
  };

  // Remove extra details
  removeDetails = (event, value) => {
    event.preventDefault();
    const updateArr = this.state.extraDetails;
    let index = this.state.extraDetails
      .map((el) => {
        return el.label;
      })
      .indexOf(value);
    updateArr[index].value = "";
    updateArr[index].isChecked = false;
    this.setState({ extraDetails: updateArr });
  };

  // Show preview before create or update
  openPreview = (value, data) => {
    this.props.showOrHideNav(true);
    this.setState({
      openPreview: value,
      microWebSiteData: data,
    });
  };

  // Get micro-web sites preview
  getPreviewLayout = (previewData, openPreview) => {

    switch (previewData.layout) {
      case "one":
        return <LayoutOne isPreview={true} openPreview={openPreview} microWebSiteData={previewData} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} />
      case "two":
        return <LayoutTwo isPreview={true} openPreview={openPreview} microWebSiteData={previewData} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} />
      default:
        return <LayoutThree isPreview={true} openPreview={openPreview} microWebSiteData={previewData} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} />
    }

  }

  // On color picker color change
  colorOnChange = (key, color) => {
    var colors = this.state.color;
    colors[key] = color.hex;
    this.setState({
      color: colors
    });
  }

  // Update about us details
  updateAboutUs = (e) => {
    this.setState({ aboutUsText: e.target.value })
  }

  // Set fetched country codes in state 
  setCountryCodes = (codes) => {

    if (codes) {
      this.setState({
        countryCodes: codes
      });
    }
  }

  render() {

    const { selectedLayout, invalidImage, openPreview, previewData, invalidLogoImage } = this.state;
    if (this.state.countryCodes) {

      var code;
      var mobile;
      // To set default country code and contact number   
      if (this.state.create) {
        code = this.state.organizationData?.socialMediaDetails?.whatsapp?.code;
        mobile = this.state.organizationData?.socialMediaDetails?.whatsapp?.number;

      } else {
        code = this.state.microWebSiteData?.contactDetails?.whatsapp?.code;
        mobile = this.state.microWebSiteData?.contactDetails?.whatsapp?.number
      }

      return (
        <div>
          {openPreview ?
            this.getPreviewLayout(previewData, this.openPreview)
            :
            <section className="">
              {/* Common head section (back arrow & grid icon) */}
              {!this.state.create && <IoArrowBackCircleOutline className="back-btn" onClick={() => { this.props.history.push(`/organization/view/${this.organizationId}`); }} />}
              {/* Config section */}
              <div className="container p-3">
                <h1 className="fw-bold fs-18 text-white text-center mt-4">
                  <img src={microSettingIc} className="btn-size-20 me-2" alt="micro setting icon"></img>
                  {HEADING.CONFIG_MICRO_WEBSITE}
                </h1>
                {/* Form Section */}
                <Formik
                  innerRef={this.formikRef}
                  enableReinitialize
                  initialValues={{
                    customHeading: this.state.microWebSiteData?.title ? this.state.microWebSiteData?.title : "",
                    aboutUs: this.state.microWebSiteData?.aboutUs?.bio ? this.state.microWebSiteData?.aboutUs?.bio : "",
                    Whatsapp: this.state.microWebSiteData?.contactDetails?.whatsapp ? mobile : mobile,
                    discord: this.state.microWebSiteData?.contactDetails?.discord ? this.state.microWebSiteData?.contactDetails.discord : this.state.organizationData?.socialMediaDetails?.discord,
                    countryCode: this.state.organizationData?.socialMediaDetails?.whatsapp ? code : global?.countryode ? global?.countryode : '+91'
                  }}
                  validationSchema={Yup.object({
                    customHeading: Yup.string().trim().required('Custom heading is Required').max(12, 'Max 12 characters'),
                    aboutUs: Yup.string().trim().max(500, 'Max 500 characters'),
                    Whatsapp: Yup.string().matches(/^[0-9]*$/, 'only numerics allowed').required("Mobile number is required").min(9, "min 9 digits required").max(12, "max 12 digits allowed"),
                    discord: Yup.string().matches(/^.{3,32}#[0-9]{4}$/, 'Invalid Username')
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      if (this.state?.microWebSiteData?._id) {
                        this.onUpdate(values);
                      } else {
                        this.onSubmit(values, true);
                      }
                    }, 400);
                  }}
                  onReset={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      if (this.state.create) {
                        this.onSubmit(values, false);
                      }
                    }, 400);
                  }}
                >
                  <Form className="" autoComplete="off">
                    <div className="row ps-4 pe-4">
                      <div className="col-lg-6 px-lg-5 col-12">
                        <h1 className="fw-600 fs-16 text-white text-start my-3">Select Your Theme</h1>

                        <div className="row justify-content-md-evenly">
                          <div className="col-4 col-md-3">
                            <img src={layoutOne} className={selectedLayout === "one" ? "layout-img layout-border" : "layout-img"} id="one" onClick={this.changeActivePreview} alt="layout one" />
                            {selectedLayout === "one" && (<IoIosCheckmarkCircle className="fs-24 mb-2 select-layout" />)}
                            <NavLink to="/organization/micro-website/layoutOne" target="_blank">
                              <FiEye className="btn-size-20 text-white layout-preview" />
                            </NavLink>
                            <h1 className="fs-14 fw-600 text-white text-center">Layout 1</h1>
                            <p className="fw-600 fs-10 text-center text-white-50">(Default)</p>
                          </div>

                          <div className="col-4 col-md-3">
                            <img src={layoutTwo} className={selectedLayout === "two" ? "layout-img layout-border" : "layout-img"} id="two" onClick={this.changeActivePreview} alt="layout two" />
                            {selectedLayout === "two" && (<IoIosCheckmarkCircle className="fs-24 mb-2 select-layout" />)}
                            <NavLink to="/organization/micro-website/layoutTwo" target="_blank">
                              <FiEye className="btn-size-20 text-white layout-preview" />
                            </NavLink>
                            <h1 className="fs-14 fw-600 text-white text-center">Layout 2</h1>
                          </div>

                          <div className="col-4 col-md-3">
                            <img src={layoutThree} className={selectedLayout === "three" ? "layout-img layout-border" : "layout-img"} id="three" onClick={this.changeActivePreview} alt="layout three" />
                            {selectedLayout === "three" && (<IoIosCheckmarkCircle className="fs-24 mb-2 select-layout" />)}
                            <NavLink to="/organization/micro-website/layoutThree" target="_blank">
                              <FiEye className="btn-size-20 text-white layout-preview" />
                            </NavLink>
                            <h1 className="fs-14 fw-600 text-white text-center">Layout 3</h1>
                          </div>
                        </div>

                        <div className="row my-4">
                          <p className="fw-600 fs-16 text-white text-start mb-1">Select Color</p>
                          <div className="col-4">
                            <h1 className="fs-14 fw-bold mb-2 text-lg-center text-md-center text-start">Background</h1>
                            <div className="col-12 d-flex justify-content-center">
                              <ColorPicker className='btn-size-52 ' uniqueKey={'background'} value={this.state.color.background} colorOnChange={this.colorOnChange} />
                            </div>
                          </div>

                          <div className="col-4">
                            <h1 className="fs-14 fw-bold mb-2 text-lg-center text-md-center text-center">Primary</h1>
                            <div className="col-12 d-flex justify-content-center">
                              <ColorPicker uniqueKey={'primary'} value={this.state.color.primary} colorOnChange={this.colorOnChange} />
                            </div>
                          </div>

                          <div className="col-4">
                            <h1 className="fs-14 fw-bold mb-2 text-lg-center text-md-center text-end">Secondary</h1>
                            <div className="col-12 d-flex justify-content-center">
                              <ColorPicker uniqueKey={'secondary'} value={this.state.color.secondary} colorOnChange={this.colorOnChange} />
                            </div>
                          </div>

                        </div>

                        <div className="row justify-content-lg-center justify-content-md-center">
                          {/* Logo */}
                          <div className="col-lg-4 col-md-4 col-5">
                            <div className="uploadWithPreview">
                              <div className="upload-section">
                                <input type="file" accept=".png, .jpg, .jpeg" id="upload_cover_file" hidden ref={(fileInput2) => (this.fileInput2 = fileInput2)} onChange={(e) => this.onImageChange(e, "logoImage")} name="" className="image-input" />
                                {this.state.logoImage ? (
                                  <div className="text-right flex-column text-end">
                                    <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={(e) => this.onClearImage(e, "logoImage")} />
                                    <div className="after-uploaded-image" onClick={this.triggerInputFile2} >
                                      <img src={this.state?.logoImage?.url} alt="" />
                                    </div>
                                  </div>
                                )
                                  : (
                                    <div className="before-uploaded-panel">
                                      <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                        <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile2} >
                                          <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                          <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Logo</h1>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {invalidLogoImage && <p className="error-msg mwc-image-validation">{invalidLogoImage}</p>}
                              </div>
                            </div>
                          </div>
                          {/* Banner */}
                          <div className="col-lg-5 col-md-5 col-7">
                            <div className="uploadWithPreview">
                              <div className="upload-section">
                                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden ref={(fileInput) => (this.fileInput = fileInput)} onChange={(e) => this.onImageChange(e, "bannerImage")} name="" className="image-input" />
                                {this.state.bannerImage ? (
                                  <div className="text-right flex-column text-end">
                                    <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={(e) => this.onClearImage(e, "bannerImage")} />
                                    <div className="after-uploaded-image" onClick={this.triggerInputFile} >
                                      <img src={this.state?.bannerImage?.url} alt="" />
                                    </div>
                                  </div>
                                )
                                  : (
                                    <div className="before-uploaded-panel">
                                      <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                        <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile}  >
                                          <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                          <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Banner</h1>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {invalidImage && (<p className="error-msg mwc-image-validation"> {invalidImage} </p>)}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                            <h1 className="fs-14 fw-600 text-white mt-2"> Custom Heading*</h1>
                            <Field
                              name="customHeading" type="text"
                              className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                              placeholder="Enter custom heading"
                              onKeyUp={(e) => {
                                this.formValueChange(e, "title");
                              }}
                            />
                            <ErrorMessage component="span" className="error-msg" name="customHeading" />
                          </div>

                          <div className="col-12 mt-4">
                            <div className="row">
                              <div className="col-12">
                                <h1 className="fs-14 fw-bold mt-2"> Upload Custom Images </h1>
                              </div>
                              <input type="file" accept=".png, .jpg, .jpeg" id="upload_cover_file" hidden onChange={this.onCustomImageChange} ref={(fileInput3) => (this.fileInput3 = fileInput3)} name="" className="image-input" />

                              {this.state.customImages.map((img, ind) => (
                                <div className="col-lg-4 col-md-4 col-6 mb-4" key={ind}>
                                  <div className="uploadWithPreview">
                                    <div className="upload-section">
                                      {img ? (
                                        <div className="text-right flex-column text-end">
                                          <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={(e) => this.resetCustomImage(e, ind)} />
                                          <div className="after-uploaded-image" onClick={(e) => this.triggerInputFile3(e, ind)} >
                                            <img src={img.url} alt="" className="" />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="before-uploaded-panel">
                                          <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                            <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={(e) => this.triggerInputFile3(e, ind)}  >
                                              <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 px-lg-5 col-12">
                        <div className="col-12 mt-4">
                          <div className="row">
                            <div className="col-12">
                              <h1 className="fs-14 fw-bold mt-2">About Us</h1>
                            </div>
                            <div className="col-lg-4 offset-lg-4 col-md-4 offset-md-4 col-6 offset-3">
                              <div className="uploadWithPreview">
                                <div className="upload-section">
                                  <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden ref={(fileInput4) => (this.fileInput4 = fileInput4)} onChange={(e) => this.onImageChange(e, "aboutUsImage")} name="" className="image-input" />
                                  {this.state.aboutUsImage ? (
                                    <div className="text-right flex-column text-end">
                                      <AiFillCloseCircle
                                        className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }}
                                        onClick={(e) =>
                                          this.onClearImage(e, "aboutUsImage")
                                        }
                                      />
                                      <div className="after-uploaded-image" onClick={this.triggerInputFile4} >
                                        <img src={this.state.aboutUsImage.url} alt="" />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="before-uploaded-panel">
                                      <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                        <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile4}  >
                                          <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                          <h1 className="fs-12 fw-600 text-white-50 mt-2" htmlFor="exampleInputText">About us Banner</h1>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <p className="fs-12 fw-600 text-white-50 mb-0 float-end">{`${this.state.microWebSiteData?.aboutUs?.bio?.length ? this.state.microWebSiteData?.aboutUs?.bio?.length : this.state.aboutUsText?.length}/500`}</p>
                          <Field
                            name="aboutUs" type="text" as="textarea" rows="6"
                            maxLength={500}
                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 pt-2 text-white"
                            placeholder="Enter About Us description"
                            onKeyUp={(e) => {
                              this.formValueChange(e, "bio");
                              this.updateAboutUs(e)
                            }}
                          />
                          <ErrorMessage component="span" className="error-msg" name="aboutUs" />
                        </div>

                        <div className="col-12 mt-2">

                          <h1 className="fs-14 fw-600 text-white mt-2" >
                            Contact Number (WhatsApp)*
                          </h1>

                          <Field name="countryCode" as='select' className="sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45 mb-1">
                            {this.state?.countryCodes?.map((el, i) => {
                              return <option value={el.code} key={i}>{`${el.code} ${el.name}`}</option>
                            })}
                          </Field>
                          <Field
                            name="Whatsapp" type="text"
                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45 mt-2"
                            placeholder="Enter WhatsApp Number"
                            onKeyUp={(e) => {
                              this.formValueChange(e, "whatsapp");
                            }}
                          />
                          <ErrorMessage component="span" className="error-msg" name="Whatsapp"
                          />
                        </div>

                        <div className="col-12 mt-2">
                          <label htmlFor="userName" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Discord Username</label>
                          <Field
                            name="discord"
                            type="text"
                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                            placeholder="mike#1234"
                          />
                          <ErrorMessage component="span" className="error-msg" name="discord" />
                        </div>

                        {this.state.extraDetails?.map((el, i) => {
                          return ((el.value !== '' && el.error === '') ? (
                            <div className="my-2" key={i}>
                              <h1 className="fs-14 fw-bold mt-2" >{capitalize(el.label)}</h1>
                              <input type="text" className="purple-field d-flex align-items-center w-100 border-0 ps-3 pe-5 text-white height-45" disabled value={el.value} />
                              <AiOutlineMinusCircle className="btn-size-20 fs-16 me-3 text-white flex-shrink-0 float-end" style={{ marginTop: '-30px' }} onClick={(e) => this.removeDetails(e, el.label)} />
                            </div>
                          ) : null)
                        })}
                        <div className="col-12 col-md-6 offset-md-3 mt-3" >
                          <div className="black-flat-btn text-center py-2 rounded-10" onClick={(e) => this.toggleAddMoreDetails(e)}>
                            <button className="bg-transparent border-0">
                              <AiFillPlusCircle className="btn-size-20 text-white" />
                              <h1 className="ms-2 fs-16 fw-600 text-white text-center d-inline">
                                Add More Details
                              </h1>
                            </button>
                          </div>
                        </div>

                        <div className="col-12 mt-3 ">
                          <div className="row">
                            {this.sponsorArray.map((el, i) => {
                              return (
                                <div className="col-lg-6 col-md-6" key={i}  >
                                  <div className="addMoreField sponsor-border pt-4 mt-3 pb-4 h-auto">
                                    <div className="col-10 offset-1 d-flex justify-content-center">
                                      <h1 className="fs-14 fw-bold text-white mt-2"> Sponsor {i + 1} </h1>
                                      <FiEdit className="text-white mt-2 ms-3" onClick={() => { this.openEditSponsor(el, i); }} />
                                      <HiOutlineTrash className="text-white mt-2 ms-2" onClick={() => {
                                        this.onClickOpenConfirm(el?.createdAt ? "deleteSponsor" : "deleteTempSponsor", this.sponsorDeleteMsg(el?.name));
                                        this.setState({
                                          deleteSponsorId: el._id,
                                          sponsorIndex: i,
                                        });
                                      }}
                                      />
                                    </div>
                                    <div className="col-10 offset-1">
                                      <label className="fs-14 fw-bold text-white mt-2 float-start" htmlFor="" > Sponsor Name </label>
                                      <input type="text" value={el?.name} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                    </div>
                                    <div className="col-10 offset-1">
                                      <label className="fs-14 fw-bold text-white mt-2 float-start" htmlFor="" > Sponsor Title </label>
                                      <input type="text" value={el?.type} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                    </div>
                                    <div className="align-self-center mt-3">
                                      <img src={el?.logo?.url} alt="" width="200" height='100' className="border border-2 rounded-10" />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 offset-md-3 mt-3" >
                          <div className="black-flat-btn text-center py-2 rounded-10">
                            <button className="bg-transparent border-0" type="button"
                              onClick={() => {
                                this.toggleModal("openSponsor");
                                this.setState({
                                  sponsorForUpdate: {},
                                  sponsorIndex: null,
                                });
                              }} >
                              <AiFillPlusCircle className="btn-size-20 text-white" />
                              <h1 className="ms-2 fs-16 fw-600 text-white text-center d-inline"> Add Sponsor </h1>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      {this.state.create && (
                        <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-4" type="reset">
                          <img src={eyeIc} className='black-rock btn-size-20' />
                        </button>
                      )}

                      <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-4" type="submit">
                        <BsArrowRight className='black-rock fs-24' />
                      </button>

                    </div>

                  </Form>
                </Formik>
              </div>

              {/* Add sponsor details modal */}
              <Modal show={this.state.openSponsor || this.state.sponsorForUpdate} centered dialogClassName="BioEdit-modal" >
                <Modal.Body>
                  <Sponsor updateLoader={this.props.updateLoader} updateSponsor={this.updateSponsor} sponsorIndex={this.state.sponsorIndex} addSponsor={this.addSponsor} sponsorArray={this.sponsorArray?.length} sponsorForUpdate={this.state.sponsorForUpdate} onClose={() => { this.setState({ openSponsor: false, sponsorForUpdate: null }) }} />
                </Modal.Body>
              </Modal>
              {/* Add more contact details modal */}
              <Modal show={this.state.openAddMoreDetails} centered dialogClassName="AddMoreDetails-modal">
                <Modal.Body>
                  <OrgAddMoreDetails
                    toggleAddMoreDetails={this.toggleAddMoreDetails}
                    renderAddedDetails={this.renderAddedDetails}
                    data={this.state.extraDetails}
                  />
                </Modal.Body>
              </Modal>
              {/* Users confirmation modal */}
              <Modal show={this.state.openConfirm} centered dialogClassName="Confirmation-modal">
                <Modal.Body>
                  <ConfirmationModal
                    confirm={this.state.confirm}
                    onClickOpenConfirm={this.onClickOpenConfirm}
                    confirmed={this.confirmed}
                  />
                </Modal.Body>
              </Modal>

            </section>
          }
        </div>
      );
    } else {
      return (
        <div>
          No record found
        </div>
      );
    }
  }
}