import { Component } from 'react';
import { NavLink } from 'react-router-dom';
// Components 
import CreateClan from '../popup/CreateClan';
// Style
import { Modal } from "react-bootstrap";
// Services
import ClanApis from "../../helper/clan.api";
import AuthApis from '../../helper/auth.api';
// Utils
import { AxiosResponse, AxiosError } from "axios";
import { profile } from '../../utils/localstorage.utils';
import { ellipsis, errorHandler, updateLoader, getWebData } from '../../utils/common.utils';
import { getToken } from "../../utils/localstorage.utils";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toast } from 'material-react-toastify';
import * as Yup from 'yup';
// Assets
import DefaultImage from '../../images/image 65.jpg';
import { IoIosCloseCircleOutline } from "react-icons/io";
import bioIcon from '../../images/icon/bio-icon.png';
import welcomeBackground from '../../images/icon/clan-mobile-welcome-background.png';
import { BsPlusLg } from 'react-icons/bs';
import Image from '../common/Image';
import { WEBSITE_ENUM } from '../../utils/enums.utils';

export default class Clan extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      openCreateClan: false,
      clans: [],
      currentUser: profile()?.id,
      toggleEmail: false,
      bioLimit: 100,
      clanDetailsFetched: false
    };

    this.clanApi = new ClanApis()
    this.authUser = profile();
    this.authApi = new AuthApis();

  }

  componentDidMount() {
    this.props.showOrHideNav(true);
    this.getData()
  }

  setWebsiteData = (res) => {
    console.log('website response', res);
    this.setState({websiteData: res[0]})
  }

  onClickOpenCreateClan = () => {
    if(this.authUser?.block){
      toast.warning('You cannot perform this action as you are blocked by Espotz Admin');
      return false;
    }
    if (this.authUser.email) {
      if (this.authUser.isEmailVerified) {
        this.setState({
          openCreateClan: true
        });
      } else {
        this.props.openPolicies(true);
      }
    } else {
      this.setState({ toggleEmail: true })
    }
  }

  // Updated email address
  updateEmail = (val) => {
    
    const data = {
      email: val.email
    };
    
    updateLoader(true);

    this.authApi.updateProfile(data).then((response: AxiosResponse) => {
      localStorage.setItem('login', JSON.stringify({
        login: true,
        token: getToken(),
        authUser: {
          user: response.data.result
        }
      }));
      this.setState({ toggleEmail: false })
      updateLoader(false);
      toast.success("Email updated successfully !!");
    }).catch((reason: AxiosError) => {
      updateLoader(false);
      errorHandler(reason, this.props.logout);
    });

  }

  // Close clan creation modal
  onClickCloseCreateClan = () => {
    this.setState({
      openCreateClan: false
    });
  }

  // Open create new clan
  onClanCreated = (val) => {
    this.setState({
      openCreateClan: false
    });
    this.getData()
  }

  // Fetch list of clans
  getData() {
    
    updateLoader(true);
    
    this.clanApi.getClans().then((res: AxiosResponse) => {
      var data = res.data['result']
      this.setState({
        clans: data
      });

      this.setState({ clanDetailsFetched: true })
      updateLoader(false);
    }).catch(
    (err: AxiosError) => {

      this.setState({ clanDetailsFetched: true })
      updateLoader(false);
      errorHandler(err, this.props.logout);
    });

  }

  // Display list of clan based on it's count
  getClansList = (clans) => {
    const requiredObject = getWebData();
    //let requiredObject = obj.filter((el) => (el.type === WEBSITE_ENUM.TYPE.INFORMATION_TEXT && el.subType === WEBSITE_ENUM.SUB_TYPE.CLAN_ORG));
    //console.log('requiredObject ::', requiredObject);
    var clans;
    // When no clans available
    if (this.state.clanDetailsFetched) {
      if (this.state.clans?.length === 0) {
        return (
          <div>
            <img src={welcomeBackground} alt="..." className="get-started-background" />
            <div className="position-absolute w-100 text-center" style={{ left: '0px', bottom: '10px' }}>
              <div className='row'>
                <div className='col-12 col-lg-6 text-lg-end pe-lg-5'>
                  <h1 className='fs-24 fw-bold text-white'>WELCOME TO</h1>
                  <h1 className='fs-48 fw-800 text-white mb-4'>CLANS</h1></div>
                <div className='col-12 col-lg-6 text-lg-start ps-lg-5'>
                  <button type="button" className="sky-blue-flat-btn text-white w-50 fw-bold p-2 m-auto mb-2" onClick={this.onClickOpenCreateClan}>Get Started</button>
                  <p className='fs-14 fs-lg-20 fw-bold text-white mb-2'>Create Your Clan Today!</p></div>
              </div>
            </div>
          </div>
        )
      }
      // When one clans available
      else if (this.state.clans?.length === 1) {
          clans = this.state.clans.map((el, i) => {
          return (
            <div className="col-12 col-md-12 col-lg-6 my-2 clanPage_Cards" key={i}>
              <NavLink to={`/clan/view/${el._id}`}>
                <div className={`card clanCard mb-3 ${el.isActive ? null : 'epz-clan-deactivate'}`}>
                  {el.isActive ? null :
                    <div className='d-flex justify-content-center'>
                      <p className="deactivated bg-dark text-white fw-600 px-2 py-2 rounded-10 position-absolute mt-5">Deactivated</p>
                    </div>
                  }
                  {/* <img src={el?.coverImage?.url ? el.coverImage.url : DefaultImage} alt="..." className="clanCardImage" /> */}
                  <Image src={el?.coverImage?.url} param={el.name} className='clanCardImage' />
                  <div className="card-body row">
                    <div className="col-3 col-lg-3">
                      {/* <img src={el?.logo?.url ? el.logo.url : DefaultImage} className="logo-card ms-lg-5" alt='' /> */}
                      <Image src={el?.logo?.url} param={el.name} className='logo-card ms-lg-5' />
                    </div>
                    <div className="col-9 col-lg-9">
                      {el.name?.length <= 20 ? 
                        <h5 className="fs-20 fw-600 text-white ms-4 text-truncate">{el.name}</h5>
                        :
                        <marquee className='marquee-text fs-20 fw-600 text-white' direction="left">{el.name}<span className='text-warning px-4'>$$$</span> {el.name}</marquee>
                      }
                      
                    </div>
                    <div className="col-12 col-lg-11 m-auto">
                      <span className='d-flex align-items-center mb-1'>
                      <img src={bioIcon} className="ms-1 mb-2" alt='' style={{ width: '20px' }} />
                      <p className='fs-14 fw-600 ms-2 mt-1 mb-0 text-white'>Bio</p>
                      </span>

                      <p className="bio-ContentSetting text-white rounded-10 fw-500 fs-14" style={{textAlign:'justify', overflowWrap: 'break-word'}}>{ellipsis(el.bio, 200)} </p>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          );
        });
        return (
          <div className="row clan-grid">
            <h1 className="fs-20 fs-lg-26 fw-bold text-white">Welcome to Clans</h1>
            <p className="fs-14 fs-lg-18 fw-500 text-white-50">{requiredObject.INFORMATION_TEXT?.CLAN_ORG?.CLAN_DESCRIPTION}</p>
            {clans}
            <div className='d-flex justify-content-end'>
              <button className='white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed' style={{ bottom: '60px' }} onClick={this.onClickOpenCreateClan}>
                <BsPlusLg className='text-info' />
              </button>
            </div>
          </div>
        );
      }
      // When two clans available
      else if (this.state.clans?.length === 2) {
        clans = this.state.clans.map((el, i) => {
          return (
            <div className="col-12 col-md-12 col-lg-6 my-2 clanPage_Cards" key={i}>
              <NavLink to={`/clan/view/${el._id}`}>
                <div className={`card clanCard mb-3 ${el.isActive ? null : 'epz-clan-deactivate'}`}>
                  {el.isActive ? null :
                    <div className='d-flex justify-content-center'>
                      <p className="deactivated bg-dark text-white fw-600 px-2 py-2 rounded-10 position-absolute mt-5">Deactivated</p>
                    </div>
                  }
                  {/* <img src={el?.coverImage?.url ? el.coverImage.url : DefaultImage} alt="..." className="clanCardImage" /> */}
                  <Image src={el?.coverImage?.url} param={el.name} className='clanCardImage' />
                  <div className="card-body row">
                    <div className="col-3 col-lg-3">
                      {/* <img src={el?.logo?.url ? el.logo.url : DefaultImage} className="logo-card ms-lg-5" alt='' /> */}
                      <Image src={el?.logo?.url} param={el.name} className='logo-card ms-lg-5' />
                    </div>
                    <div className="col-9 col-lg-9">
                      {el.name?.length <=20 ?
                        <h5 className="fs-20 fw-600 text-white ms-4 text-truncate">{el.name}</h5>
                        :
                        <marquee className='marquee-text fs-20 fw-600 text-white' direction="left">{el.name}<span className='text-warning px-4'>$$$</span> {el.name}</marquee>
                      }
                      
                    </div>
                    <div className="col-12 col-lg-11 m-auto">
                    <span className='d-flex align-items-center mb-1'>
                      <img src={bioIcon} className="ms-1" alt='' style={{ width: '20px' }} />
                      <p className='fs-14 fw-600 ms-2 mt-1 mb-0 text-white'>Bio</p>
                      </span>
                      <p className="bio-ContentSetting text-white rounded-10 fw-500 fs-14" style={{textAlign:'justify',overflowWrap: 'break-word'}}>{ellipsis(el.bio, 100)} </p>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          );
        });
        return (
          <div className="row clan-grid">
            <h1 className="fs-20 fs-lg-26 fw-bold text-white">Welcome to Clans</h1>
            <p className="fs-14 fs-lg-18 fw-500 text-white-50">{requiredObject.INFORMATION_TEXT?.CLAN_ORG?.CLAN_DESCRIPTION}</p>
            {clans}
            <div className='d-flex justify-content-end'>
              <button className='white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed' style={{ bottom: '60px' }} onClick={this.onClickOpenCreateClan}>
                <BsPlusLg className='text-info' />
              </button>
            </div>
          </div>
        );
      }
      // When more than two clans available
      else {
        clans = this.state.clans.map((el, i) => {
          return (
            <div className="col-6 col-md-3 col-lg-2 my-2 clanPage_Cards" key={i}>
              <NavLink to={`/clan/view/${el._id}`}>
                <div className={`clan-card alpha-fortniters ${el.isActive ? null : 'epz-clan-deactivate'}`} style={{ backgroundImage: `url(${el.logo?.url ? el.logo?.url : DefaultImage})` }}>
                  {el.isActive ? null :
                    <div className='d-flex justify-content-center'>
                      <p className="deactivated bg-dark text-white fw-600 px-2 py-2 rounded-10 position-relative mt-7">Deactivated</p>
                    </div>
                  }
                  <div className="clan-card-name text-center px-2 py-2 fs-14 text-white fw-500 mt-auto text-truncate">
                    {el.name?.length <= 20 ? 
                      <h5 className='fs-16 fs-lg-20 fw-600 text-white'>{el.name}</h5>
                      :
                      <marquee className='marquee-text fs-16 fs-lg-20 fw-600 text-white' direction="left">{el.name}<span className='text-warning px-4'>$$$</span> {el.name}</marquee>
                    }
                  </div>
                  
                </div>
              </NavLink>
            </div>
          );
        });
        return (
          <div className="row clan-grid justify-content-center">
            <h1 className="fs-20 fs-lg-26 fw-bold text-white">Welcome to Clans</h1>
            <p className="fs-14 fs-lg-18 fw-500 text-white-50">{requiredObject.INFORMATION_TEXT?.CLAN_ORG?.CLAN_DESCRIPTION}</p>
            {clans}
            <div className='d-flex justify-content-end'>
              <button className='white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed' style={{ bottom: '60px' }} onClick={this.onClickOpenCreateClan}>
                <BsPlusLg className='text-info' />
              </button>
            </div>
          </div>
        );
      }
    }

  }

  render() {
    const openCreateClan = this.state.openCreateClan
    const clanList = this.getClansList();
    //console.log('$$$$ AUTH_USER ::', this.authUser);
    return (
      <div>
        {/* List of clans */}
        <section className="body-section clan-page">
          <div className="container p-3 mt-3 mt-lg-5">
            {clanList}
          </div>
        </section>

        {/* Create new clan */}
        <Modal show={openCreateClan} centered dialogClassName="create-clan-modal">
          <Modal.Body>
            <CreateClan openCreateClan={openCreateClan} onClickCloseCreateClan={this.onClickCloseCreateClan} onClickOpenCreateClan={this.onClickOpenCreateClan} onClanCreated={this.onClanCreated} updateLoader={updateLoader} />
          </Modal.Body>
        </Modal>
        {/* Verify Email */}
        <Modal show={this.state.toggleEmail} centered dialogClassName="CreateClan-modal">
          <Modal.Body>
            <div className="">
              <IoIosCloseCircleOutline className="close-icon position-absolute" onClick={() => { this.setState({ toggleEmail: false }) }} style={{ top: '10px', right: '10px' }} />
              <div className="text-white fw-400 mt-5 text-center">
                There is no email linked with your account. Please enter your email and verify it to proceed.
              </div>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object({
                  email: Yup.string().email("Invalid Email").required("Email is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    this.updateEmail(values)
                    setSubmitting(false);
                  }, 400);
                }}
              >
                <Form className=" m-3" autoComplete="off">

                  <div className="">
                    <label htmlFor="email" className="fs-16 fw-600 text-white">Email</label>
                    <Field name="email" type="text" placeholder="Enter Email" autoComplete="off" className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white height-45" />
                    <ErrorMessage component="span" className="error-msg" name="email" autoComplete="off" />
                  </div>

                  <button type="submit" className="white-flat-btn fw-bold py-2 px-4 m-auto mb-2 d-block mt-4">
                    Add
                  </button>

                </Form>
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
