import React, { Component } from 'react';
import winCup from '../../../../../images/icon/winCup.png';
import '../../../../../styles/Tournaments.css'
import MatchesApis from '../../../../../helper/matches.api';
import { errorHandler, getMonthAndDate } from '../../../../../utils/common.utils';
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import { MdRefresh, MdZoomOutMap } from "react-icons/md";
import NoRecordFound from '../../../../common/NoRecordFound';

export default class RoundRobinRounds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupMatches: props.groupMatches,
            selectedPhase: props.selectedPhase,
            perPhaseMember: props.perPhaseMember,
            roundRobinType: props.roundRobinType,
            isTeamPlayer: props.isTeamPlayer,
            scale: 1
        }

        this.matchApis = new MatchesApis();



    }

    componentWillReceiveProps(newProps) {
        this.setState({
            groupMatches: newProps.groupMatches,
            selectedPhase: newProps.selectedPhase,
            perPhaseMember: newProps.perPhaseMember,
            roundRobinType: newProps.roundRobinType,
            isTeamPlayer: newProps.isTeamPlayer,
        });
    }

    zoom = (key) => {
        switch (key) {
          case 'In':
            this.setState({ scale: this.state.scale * 1.1 });
            break;
          case 'Out':
            this.setState({ scale: this.state.scale / 1.1 });
            break;
          default:
            this.setState({ scale: 1 });
            break;
        }
    }



    render() {
        var {perPhaseMember, selectedPhase, groupMatches} = this.state;
      
        if (groupMatches) {
            return (
                <div className='w-100 d-flex overflow-auto'>
                    <div className='' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, overflow:'none', width:'fit-content'}}>
                        {
                            
                            Object.keys(groupMatches).map((key, i) => {
                                return (
                                    <div className="py-1 px-3" key={i}>
                                        <button type="button" className={`score-showdown-btn me-2 rounds`}>ROUND {key}</button>
                                        <div className='d-flex overflow-auto my-3'>
                                            {
                                                groupMatches[key].map((matchDetail, index) => {
                                                    console.log('match details::', matchDetail)
                                                    var dateAndTime = getMonthAndDate(matchDetail.matchSchedule);
                                       
                                                    var players = [];
                                                    players[0] = matchDetail?.participants[0];
                                                    players[1] = matchDetail?.participants[1];

                                                    if( matchDetail?.resultAdded ){
                                                        [...Array(2)].map((ele,k)=>{
                                                            if(players[k] !== undefined){
                                                                players[k].isWinner = matchDetail?.finalResult[k]?.isWinner;
                                                            }
                                                        });
                                                    }
                                                  
                                                    const windowWidth = window.screen.width-30;
                                                    return (
                                                        <div style={{ height: '74px', width:`${(windowWidth < 400 ?  windowWidth : 400)}px` }} className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start  justify-content-start outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn flex-shrink-0 me-2 "  key={index}>
                                                            <span className="match-tag my-0">Match {key}.{index + 1}</span>
                                                            <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                                                <div className="d-flex flex-column">
                                                                {[...Array(2)].map((ele,i)=>{
                                                                     if(players[i]){
                                                                        return(
                                                                            <span className="player-name my-1" key={`${key}.${index}.${i}`}>
                                                                                <img src={this.state.isTeamPlayer ? players[i]?.logo?.url : players[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt=''/>
                                                                                {this.state.isTeamPlayer ? players[i].name : players[i].inGameName}  {matchDetail.resultAdded && players[i].isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt=''/> : null }
                                                                            </span> 
                                                                        );
                                                                     }else{
                                                                        return <span className="player-name my-1 vacant" key={`${key}.${index}.${i}`}>Vacant</span>
                                                                     }
                                                                })}
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="player-name my-1">{dateAndTime[0]}</span>
                                                                    <span className="player-name">{dateAndTime[1]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    {/* Zoom In/Out Section */}
                    <div className="icons-box d-flex justify-content-center w-100">
                        <div className="icon mx-2" onClick={() => this.zoom('Out')}>
                            <ImZoomOut className="zoom-icon" />
                        </div>
                        <div className="icon mx-2" onClick={() => this.zoom('Reset')}>
                            <MdZoomOutMap className="zoom-icon" />
                        </div>
                        <div className="icon mx-2" onClick={() => this.zoom('In')}>
                            < ImZoomIn className="zoom-icon" />
                        </div>
                    </div>
                </div>
            );

        } else {
            return (
                <NoRecordFound />
            )
        }
    }
}

