import { Component } from 'react'
import TournamentHeader from './common/TournamentHeader';
import { IoSend } from 'react-icons/io5';
import chatIc from '../../../images/icon/chatLobby-ic.png';
import participantAvtar from "../../../images/participantAvtar.jpg";
import { errorHandler, getTournamentData } from '../../../utils/common.utils';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import io from "socket.io-client";
import { profile } from '../../../utils/localstorage.utils';
import ImagePreview from '../../common/ImagePreview';
import { Modal } from 'react-bootstrap';
import MatchesApis from '../../../helper/matches.api';
import CommonApis from '../../../helper/common.api';
import { GrAttachment } from 'react-icons/gr';
import {BiImageAlt} from 'react-icons/bi';
import { remainingTime } from '../../../utils/common.utils';

var socket;

class ChatLobby extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : null,
            owners: props?.tournamentData?._id ? props?.tournamentData.organizationDetails?.members?.owner : null,
            admins: props?.tournamentData?._id ? props?.tournamentData.organizationDetails?.members?.admin : null,
            message: '',
            messages: [],
            role: '',
            uploadImages: [],
            previewImageDetails: {
                display: false,
                src: ''
            },
            matchDetails: {}
        }
        
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
        socket = io(process.env.REACT_APP_SOCKET_URL)
        this.MatchesApis = new MatchesApis()
        this.commonApi = new CommonApis()
        if (props.match.params.matchId) {
            this.getMatchDetails(props.match.params.matchId)
        }
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.checkRole()
        this.getMessages()
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
                owners: values.organizationDetails.members?.owner ? values.organizationDetails.members?.owner : null,
                admins: values.organizationDetails.members?.admin ? values.organizationDetails.members?.admin : null,
            })
            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }

    getMatchDetails = async (matchId) => {
        var data = {
            ids: [matchId]
        }
        this.props.updateLoader(true);
        await this.MatchesApis.getMultipleMatches(data).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                //console.log("Match Data : ", response);
                this.setState({
                    matchDetails: response[0]
                })
                // this.updateMatchDetails(response[0], response[0]['this.state.matchDetails?.participants'], joinTeamId, response[0]['maxParticipantsPerMatch']);
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    checkRole = () => {
        if (this.state?.owners?.includes(profile()?.id)) {
            this.setState({ role: 'Owner' })
        } else if (this.state?.admins?.includes(profile()?.id)) {
            this.setState({ role: 'Admin' })
        } else {
            this.setState({ role: 'Player' })
        }
    }
    getMessages = () => {
        socket.emit('get-data', { matchId: this.props.match.params.matchId }, (data) => {
        })
        socket.on(`data-sent-${this.props.match.params.matchId}`, (e) => {
            this.setState({ messages: e })
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        })
        socket.on(`get-data-error-${this.props.match.params.matchId}`, (e) => {
        })
    }
    
    sendMessage = (e) => {
        this.setState({ message: '' })
        e.preventDefault()
        const messageBody = {
            from: profile()?.id,
            matchId: this.props.match.params.matchId,
            tournamentId: this.props.match.params.id,
            message: this.state.message,
            role: this.state.role,
            messageType: 'TEXT'
        }
        socket.emit('chat-message', messageBody)
        socket.on(`data-saved-${this.props.match.params.matchId}`, (e) => {
            this.getMessages()
        })
        socket.on(`chat-message-error-${this.props.match.params.matchId}`, (e) => {
            this.setState({ message: '' })
        })
    }
    uploadImages = (event) => {
        event.preventDefault()
        console.log("event.target.files?.length - ", event.target.files?.length)
        if (event.target.files?.length && event.target.files?.length < 6) {
            const formData = new FormData();
            for (let i = 0; i < event.target.files?.length; i++) {
                formData.append(i, event.target.files[i])
            }

            this.commonApi.uploadMultipleImg(formData)
                .then((response: AxiosResponse) => {
                    let uploadImages = []
                    for (let i = 0; i < response.data.result?.length; i++) {
                        if (i < 6) {
                            uploadImages.push(response.data.result[i])
                        }
                    }
                    // this.setState({
                    //     uploadImages: uploadImages
                    // });
                    const messageBody = {
                        from: profile()?.id,
                        matchId: this.props.match.params.matchId,
                        tournamentId: this.props.match.params.id,
                        role: this.state.role,
                        messageType: 'IMAGES',
                        images: uploadImages
                    }
                    console.log(JSON.stringify(messageBody))
                    socket.emit('chat-message', messageBody)
                    console.log(JSON.stringify(messageBody))
                    socket.on(`data-saved-${this.props.match.params.matchId}`, (e) => {
                        console.log("calleddddd")
                        this.getMessages()
                    })
                    socket.on(`chat-message-error-${this.props.match.params.matchId}`, (e) => {
                        console.log("error - ", e)
                    })
                })
                .catch((err: AxiosError) => console.log(err));
        }
    }

    triggerInputFile = () => this.fileInput.click();

    previewImage = (src, param) => {

        this.setState({
            previewImageDetails: {
                display: param,
                src: src?.url ? src?.url : null
            }
        });
    }

    render() {
        return (
            <div className="body-section tournament-matches overflow-hidden h-100">
                <div className='position-fixed p-3 w-100' style={{ backgroundColor: '#200e45',zIndex:1 }}>
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={chatIc} heading='Chat Lobby' subHeading={<h1 className="stage-sub-heading text-center mb-0">Time Left: <span className='error-msg'>{remainingTime(this.state.matchDetails?.matchSchedule)}</span></h1>} history={this.props.history} />
                </div>
                <div className="container">
                    <div className="chatWindow">
                        <div className="chat pt-7" id="chatList" style={{ height: `${(window.innerHeight * 80) / 100}px`}}>
                            {
                                this.state.messages.map((el, i) => {
                                    return (
                                        <div key={i} className={`${el?.userDetails?._id === profile().id ? 'self li' : 'other li'}`}>
                                            <div className='row'>
                                                {
                                                    el?.userDetails?._id !== profile().id ?
                                                        <div className='col-2 p-0 text-center'>
                                                            <img src={el?.userDetails?.profilePicture?.url} className="rounded-circle btn-size-32 ms-1" alt='' />
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (el?.messageType?.length && el?.messageType === 'IMAGES') ?
                                                        <div className='col-10' style={{ textAlign: `${(el?.userDetails?._id === profile().id) ? 'right' : 'left'}` }}>
                                                            <div className="msg">
                                                                <p className="text-end mb-0 fs-12 fw-700"><span>{el?.userDetails?.name}</span> <span>~ {el?.role}</span></p>
                                                                <div className='pe-2'>
                                                                    {
                                                                        el?.images.map((img, j) => {
                                                                            return (
                                                                                <img key={j} src={img?.url} onClick={() => this.previewImage(img, true)} alt='' className='mx-1 my-2' style={{width:'162px', height:'162px'}}/>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='col-10'>
                                                            <div className="msg">
                                                                <p className="text-end"><span>{el?.userDetails?.name}</span> <span>~ {el?.role}</span></p>
                                                                <div className="message">{el?.message}</div>
                                                            </div>
                                                        </div>
                                                }
                                                {
                                                    el?.userDetails?._id === profile().id ?
                                                        <div className='col-2 p-0'>
                                                            <img src={el?.userDetails?.profilePicture?.url} className="rounded-circle btn-size-32 ms-1" alt='' />
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className='row' style={{ height: '20px' }} ref={(el) => { this.messagesEnd = el; }}></div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2 col-12 py-3' style={{background: '#200e45', zIndex: '1'}}>
                            <div className="w-100">
                                <div className='row position-relative ' style={{ bottom: '0px' }}>
                                    <div className='col-lg-10 col-8 pe-0'>
                                        <input type="text" id="msg-text" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} className="chat-field-height purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14" />
                                    </div>
                                    <input
                                        name="disputeImages"
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        id="upload_cover_file"
                                        hidden
                                        multiple
                                        ref={fileInput => this.fileInput = fileInput}
                                        onChange={this.uploadImages}
                                    />
                                    <div className='col-lg-1 col-2 pe-0'>
                                        <button className='bg-transparent rounded-circle m-auto btn-size-40 border-0' onClick={this.triggerInputFile}><BiImageAlt className='btn-size-25 text-white' /></button>
                                    </div>
                                    <div className='col-lg-1 col-2 ps-0'>
                                        <button className='chat-send-btn d-flex justify-content-center align-items-center text-white bg-info rounded-circle m-auto btn-size-40 border-0' disabled={!this.state.message} onClick={this.sendMessage}><IoSend className='btn-size-20' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview isDownload={true} history={this.props.history} previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </div>
        )
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(ChatLobby)