import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../styles/TeamView.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TeamApis from "../../helper/teams.api";
import { toast } from 'material-react-toastify';
import { AxiosResponse, AxiosError } from "axios";
import { profile } from "../../utils/localstorage.utils";
import { errorHandler, getTournamentData, updateLoader } from "../../utils/common.utils";
import AcceptInviteImage from '../../images/accept-invite-background.png';
import CommonApis from "../../helper/common.api";
import { GAMES_NAMES } from "../../utils/enums.utils";

class AcceptInvite extends Component {

  constructor(props) {

    super(props);
    this.state = {
      isCoachOrManager: false,
      countryCodes: [],
      teamData: {},
      countryCode: global.countryCode ? global.countryCode : '+91',
      // ### CUSTOM FIELD CHANGES tournamentDetails
      tournamentDetails: null,
      customErrors: {},
      customValues: {}
    };

    this.teamApis = new TeamApis();
    this.authUser = profile();
    this.commonApi = new CommonApis();


    this.getCodes();
    if (this.props.joinTeamId) {
      this.getTeamData(this.props.joinTeamId);
    }
  }
  setCustomValues = (key, value, max) => {
    let customValues = this.state.customValues;
    let customErrors = this.state.customErrors;
    customValues[key] = value;
    if ((value.toString().length) > max) {
      customErrors[key] = `Maxlength should be less than ${max}`
    }else{
      delete customErrors[key]
    }
    this.setState({ customValues: customValues, customErrors: customErrors })
  }
  componentDidMount() {

    // ### CUSTOM FIELD CHANGES IF()
    if (this.props.tournamentId && this.props.tournamentId !== null && this.props.tournamentId !== undefined) {
      getTournamentData(this.props.tournamentId, this.setTournamentDetails)
    }

    if (this.props.joinTeamRole && (this.props.joinTeamRole === "coach" || this.props.joinTeamRole === "manager")) {
      this.setState({ isCoachOrManager: true, countryCode: global.countryCode ? global.countryCode : '+91', });
      var values = {
        userId: this.authUser?.id,
        teamId: this.props.joinTeamId,
        name: this.authUser?.name,
        email: this.authUser?.email,
        inGameName: this.authUser?.username,
        isDeleted: false
      }
      // this.props.updateLoader(true);
      this.createTeamMember(values, []);
    } else {
      this.setState({ isCoachOrManager: false });
    }
  }

  // ### CUSTOM FIELD CHANGES setTournamentDetails()
  setTournamentDetails = (value) => {
    
    if (value) {
      this.setState({ tournamentDetails: value });
    }
  }

  getCodes = () => {
    updateLoader(true);
    this.commonApi.getCountryCodes().then(
      (res: AxiosResponse) => {
        updateLoader(false);
        this.setState({ countryCodes: res.data.result })
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err);
      }
    );
  }

  getTeamData = (id) => {
    const data = {
      _id: id
    }
    updateLoader(true);
    this.teamApis.getTeams(data).then(
      (res: AxiosResponse) => {
        let data = res.data['result'][0];
        this.setState({ teamData: data });
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  onUpdate = (values, customField) => {
    values.isDeleted = this.props.info.isDeleted;
    let mobileNo = { code: values.countryCode, number: values.mobileNo };
    values.mobileNo = mobileNo;
    updateLoader(true);
    this.teamApis.updateTeamMember(this.props.info?._id, values).then(
      (res: AxiosResponse) => {
        updateLoader(false);
        toast.success('Bio updated successfuly')
        this.props.bioChange(res.data.result);
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err);
      }
    );
  };

  onSubmit = (values, customField) => {
    values.isDeleted = false;
    let mobileNo = { code: values.countryCode, number: values.mobileNo };
    values.mobileNo = mobileNo;
    if (this.props.isTournamentInvitation) {
      values.tournamentId = this.props.tournamentId;
    } else {
      values.tournamentId = null;
      values.teamId = this.props.joinTeamId;
    }
  
    if (Object.keys(this.state.customErrors).length === 0) {
      // this.props.updateLoader(true);
      this.createTeamMember(values,customField);
    }

  };

  createTeamMember = (values, customField) => {
    let data = values;
    data.role = this.props.role;
    data.matchId = this.props.matchId;

    // To append custom field and it's respective values in players body
    if (customField && customField.length > 0) {
      data.customFields = [];
      customField.map((field, i) => {
        data.customFields.push({
          key: field.label,
          value: values[field.label]
        });
        delete values[field.label];
      })
    }

    updateLoader(true);
    this.teamApis.createTeamMember(values).then(
      (res: AxiosResponse) => {
        if (this.props.isTournamentInvitation) {
          this.props.addParticipantInTournament(this.props.matchId, res.data.result._id, values.tournamentId)
        } else {
          this.props.joinClanOrTeam(res.data.result._id, "team");
        }
        //this.props.clearInvitations();
        updateLoader(false);
        //this.props.updateLoader(false);
      }
    ).catch(
      (err: AxiosError) => {
        //this.props.clearInvitations();
        updateLoader(false);
        // this.props.updateLoader(false);
        this.props.close();
        errorHandler(err);

      }
    );
  }

  validateInGameId = (value) => {
    var error = '';
    const queryParams = new URLSearchParams(window.location.search);
    const gameName = this.props.isTournamentInvitation ? this.state.tournamentDetails?.gameDetails?.name : this.state.teamData?.gameDetails?.name;
    
    console.log(`Game -------- ${gameName}`);
    if (gameName === GAMES_NAMES.LEAGUE_OF_LEGENDS) { //league-of-legends 
      console.log('Inside League of Legends Game');
      if (value?.search("#") == -1) {
        error = 'InGameId should be in RIOTID#TAGLINE format.';
      }
      console.log('LOL error :::', error);
      return error;
    }
    else if (gameName === GAMES_NAMES.DOTA_2) { //dota-2 
      const regExPattern = /[a-zA-Z !@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      if (result !== null) {
        error = "InGameId should contain just numeric values.";
      }
      else if (value.length !== 17) {
        error = "InGameId should contain 17 numeric characters.";
      }
      
      return error;
    }
    else if (gameName === GAMES_NAMES.CS_GO) { //CS:GO 
      const regExPattern = /[a-zA-Z !@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      if (result !== null) {
        error = "InGameId should contain just numeric values.";
      }
      else if (value.length !== 17) {
        error = "InGameId should contain 17 numeric characters.";
      }
      //console.log('CS:GO error :::', error);
      return error;
    }
    else if (gameName === GAMES_NAMES.VALORANT) {  //valorant
      if (value?.search("#") == -1) {
        error = "InGameId should be in RIOTID#TAGLINE format.";
      }
      
      return error;
    }
    else if (gameName === GAMES_NAMES.PUBG) { //PUBG 
      const regExPattern = /[a-zA-Z !@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      if (result !== null) {
        error = "InGameId should contain just numeric values.";
      }
      else if (value.length !== 17) {
        error = "InGameId should contain 17 numeric characters.";
      }
      
      return error;
    }
    else if (gameName === GAMES_NAMES.FREEFIRE) { //FreeFire
      const regExPattern = /[a-zA-Z !@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      if (result !== null) {
        error = "InGameId should contain just numeric values.";
      }
      else if (value.length !== 9) {
        error = "InGameId should contain 9 numeric characters.";
      }
      
      return error;
    }
    else if (gameName === GAMES_NAMES.CALL_OF_DUTY) { //Call Of Duty
      const regExPattern = /[a-zA-Z !@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      if (result !== null) {
        error = "InGameId should contain just numeric values.";
      }
      else if (value.length !== 19) {
        error = "InGameId should contain 19 numeric characters.";
      }
      
      return error;
    }
    else if (gameName === GAMES_NAMES.FORTNITE) { //FORTNITE
      const regExPattern = /[!@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      if (result !== null) {
        error = "InGameId should contain just alphanumeric characters.";
      }
      else if (value.length !== 32) {
        error = "InGameId should contain 32 numeric characters.";
      }
      
      return error;
    }
    else if (gameName === GAMES_NAMES.PUBG_MOBILE) { //PUBG_MOBILE
      const regExPattern = /[a-zA-Z !@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      if (result !== null) {
        error = "InGameId should contain just numeric values.";
      }
      
      return error;
    }
    else if (gameName === GAMES_NAMES.APEX_LEGENDS) { //Apex Legends 
      const regExPattern = /[!@#$%^&*()_+<>?:{}|;,./`~]/g;
      const result = value.match(regExPattern);
      //console.log(`value-${value} :: result-${result}`);
      if (result !== null) {
        error = "InGameId should contain just alpha-numerics with upper cases.";
      }
      else if (value.length !== 20) {
        error = "InGameId should contain 20 numeric characters.";
      }
      return error;
    }
  }
  setSession = (key, val) => {
    global.countryCode = val
    this.setState({ [key]: val })
  }
  render() {
   const customField = this.state.tournamentDetails?.formConfig.filter((field) => field.isCustom);
  //  const currentGameDetails = this.props.isTournamentInvitation ? this.state.tournamentDetails?.gameDetails : this.state.teamData?.gameDetails;
   
  //  console.log("currentGameDetails--------------------",currentGameDetails);
   
   if (this.props.info?.mobileNo) {
      var code = this.props.info?.mobileNo?.code;
      var mobile = this.props.info?.mobileNo?.number;
    }

    return (
      <>
        {this.state.isCoachOrManager ?
          null
          :
          <div className="accept-invite-modal-page p-3">
            <div className="row">
              <div className="col-lg-6 col-md-6 ">
                <IoIosCloseCircleOutline
                  className="close-icon position-absolute" style={{ right: '10px', top: '25px' }}
                  onClick={() => {
                    this.props.close('openAcceptInvite');
                  }}
                />
                <p className="fs-18 fw-bold text-white text-center">{this.props.title}</p>
                {/* <p className="text-description fs-14 fw-nornal text-white text-center px-4">
              You can join the team by filling in these details.
            </p> */}
                <Formik
                  innerRef={this.formikRef}
                  initialValues={{
                    name: this.props.info?.name ? this.props.info?.name : "",
                    email: this.props.info?.email ? this.props.info?.email : this.authUser.email,
                    mobileNo: this.props.info?.mobileNo ? mobile : "",
                    inGameId: this.props.info?.inGameId ? this.props.info?.inGameId : "",
                    inGameName: this.props.info?.inGameName ? this.props.info?.inGameName : "",
                    inDiscordId: this.authUser?.discordProfile?.discordUsername,
                    countryCode: this.props.info?.mobileNo ? code : this.state.countryCode
                  }}

                  // const phoneRegex = {RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)}

                  validationSchema={Yup.object({
                    name: Yup.string().trim().required("Name is required").max(25, 'Max 25 characters').matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, 'Only alphabets allowed.'),
                    mobileNo: Yup.string().required("Mobile number is required").matches(/^[0-9]*$/, 'only numerics allowed').min(9, "min 9 digits required").max(12, "max 12 digits allowed"),
                    email: Yup.string().email("Invalid Email").required("Email is required"),
                    inGameId: Yup.string().required("Game Id is required"),
                    //.matches(/^[a-zA-Z0-9]*$/g, 'Only alpha numerics without any spaces'),
                    inGameName: Yup.string().required("Game Name is required").max(25, 'Max 25 characters').matches(/^[a-zA-Z0-9]*$/g, 'Only alpha numerics without any spaces'),
                    // inDiscordId: Yup.string().required("Discord Username is required").matches(/^.{3,32}#[0-9]{4}$/, 'Invalid Username'),
                    countryCode: Yup.string()
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      if (this.props.type === 'add') {
                        this.onSubmit(values, customField);
                      } else {
                        this.onUpdate(values, customField);
                      }

                    }, 400);
                  }}
                >

                  <Form className="mx-2 mt-3 d-flex flex-column overflow-auto" autoComplete="off">
                    <div className="mt-2">
                      <label htmlFor="name" className="fs-16 fw-600 text-white mb-1">Full Name</label>
                      <Field
                        name="name" type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                        placeholder="Enter Name"
                      />
                      <ErrorMessage component="span" className="error-msg" name="name" />
                    </div>

                    <div className="mt-2">
                      <label htmlFor="email" className="fs-16 fw-600 text-white mb-1">Email</label>
                      <Field
                        name="email" type="email"
                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                        placeholder="Enter Email"
                      />
                      <ErrorMessage component="span" className="error-msg" name="email" />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="countryCode" className="fs-16 fw-600 text-white mb-1">Country Code</label>
                      <Field name="countryCode" value={this.state.countryCode} as='select' onChange={(e) => this.setSession('countryCode', e.target.value)} className="sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45">
                        {this.state.countryCodes.map((el, i) => {
                          return <option value={el.code} key={i}>{`${el.code} ${el.name}`}</option>
                        })}
                      </Field>
                      <ErrorMessage component="span" className="error-msg" name="countryCode" />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="mobileNo" className="fs-16 fw-600 text-white mb-1">Mobile Number</label>
                      <Field
                        name="mobileNo" type='number'
                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                        placeholder="Enter Mobile Number"
                        min={0} step={1} onWheel={(e) => e.target.blur()}
                      />
                      <ErrorMessage component="span" className="error-msg" name="mobileNo" />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="inGameId" className="fs-16 fw-600 text-white mb-1">In-Game ID</label>
                      <Field
                        name="inGameId" type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                        placeholder="Enter In-Game ID"
                        validate={this.validateInGameId}
                      />
                      <ErrorMessage component="span" className="error-msg" name="inGameId" />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="inGameName" className="fs-16 fw-600 text-white mb-1">In-Game Name</label>
                      <Field
                        name="inGameName" type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                        placeholder="Enter In-Game Name"
                      />
                      <ErrorMessage component="span" className="error-msg" name="inGameName" />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="inDiscordId" className="fs-16 fw-600 text-white mb-1">Discord Username</label>
                      <Field
                        name="inDiscordId" type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                        placeholder="mike#1234"
                        disabled={true}
                      />
                      <ErrorMessage component="span" className="error-msg" name="inDiscordId" />
                    </div>

                    {
                      customField?.map((field, i) => {
                        return (
                          <div className="mt-2" key={i}>
                            <label htmlFor={field.label} className="fs-16 fw-600 text-white mb-1">{field.label}</label>
                            <Field
                              name={field.label} type={field.type}
                              value={this.state.customValues[field.label] ? this.state.customValues[field.label] : ''}
                              onChange={(e) => { this.setCustomValues(field.label, e.target.value, field.maxLength) }}
                              className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                              placeholder={`Enter ${field.label}`}
                            />
                            {this.state.customErrors[field.label] ?
                              <span className="error-msg" >{this.state.customErrors[field.label]}</span> : null
                            }
                          </div>
                        )
                      })
                    }

                    {
                      this.props.type === 'add' ?
                        <div className="d-flex justify-content-center">
                          <button type="submit" disabled={this.state.isDisabled} className="white-flat-btn fw-bold py-2 px-4 mt-3 d-block">
                            Accept
                          </button>
                        </div>
                        :
                        <button type="submit" disabled={this.state.isDisabled} className="white-flat-btn fw-bold py-2 px-4 m-auto mt-3 d-block">
                          Update
                        </button>
                    }
                  </Form>
                </Formik>
              </div>
              <div className="col-lg-6 col-md-6 d-lg-block d-md-block d-none">
                <img src={AcceptInviteImage} alt="login pc" className="w-100 mt-5" />
              </div>
            </div>
          </div>
        }

      </>
    );
  }
}

export default AcceptInvite;
