import React, { Component } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toast } from 'material-react-toastify';
import { DEN } from '../../../../utils/Strings.utils';

export default class DenCommonTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            denProfile: props.denProfile
        }
    }

    componentDidMount() {}

    componentWillReceiveProps(newProps){
        this.setState({denProfile: newProps.denProfile})
    }

    closeDenMenu = () => { 
        this.props.updateState('openMobileSubTabs', true)
    }

    goToEditPage = () => {

        switch (this.state.denProfile?.type) {
            case DEN.SEARCH.TYPES.CLAN:
                this.props.history.push(`/clan/view/${this.state.denProfile?.clanOrOrgId}`);
                break;

            case DEN.SEARCH.TYPES.ORG:
            
                this.props.history.push(`/organization/view/${this.state.denProfile?.clanOrOrgId}`);
                break;
        
            default:
                this.props.history.push(`/den-profile`)
                break;
        }
    }

    pageRedirect = (page) => {
        const {denProfile} = this.state;
        console.log('is active den ::', denProfile.isActive)
        if(!denProfile.isActive){
            toast.error('Please re-activate your den profile to perform any action');
            return false;
        }
        if(page){
            this.props.history.push(`/den/${denProfile?._id}/${page}`);
        }else{
            this.props.history.push(`/den/${this.state.denProfile?._id}`)
        }
    }

    render() {
        return(
            <Modal className="d-flex flex-column w-100" show={this.props.show} dialogClassName="testsss my-0">
                <div className='text-white mt-auto actions-body'>
                <div className='text-center'><AiFillCloseCircle style={{height:'40px', width:'40px', cursor:'pointer'}} onClick={() => this.props.updateState('openMobileSubTabs', false)} /></div>
                <div className='post-action-container py-2'>
                    <p className='cursor-pointer fs-16 fw-600 text-center my-1' onClick={()=>this.goToEditPage()}>PROFILE</p>
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                    <p className='cursor-pointer fs-16 fw-600 text-center my-1' onClick={() => this.pageRedirect('blocked-users')}>BLOCKED USERS</p>
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                    <p className='cursor-pointer fs-16 fw-600 text-center my-1' onClick={() => this.pageRedirect('follow-request')}>FOLLOW REQUESTS</p>
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                    <p className='cursor-pointer fs-16 fw-600 text-center my-1' onClick={() => this.pageRedirect('inner-circle')}>INNER CIRCLE</p>
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                    {/* <p className='cursor-pointer fs-16 fw-600 text-center my-1' onClick={() => this.pageRedirect('achievements')}>ACHIEVEMENTS</p>
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} /> */}
                    <p className='cursor-pointer fs-16 fw-600 text-center my-1' onClick={() => this.pageRedirect('posts')}>POSTS</p>
                </div>
                </div>
            </Modal>
        )
    }
}