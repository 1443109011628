import { Component } from "react";
import { NavLink } from "react-router-dom";
// Utils
import * as Yup from "yup";
import { errorHandler, updateLoader } from "../../utils/common.utils";
import { emailValidation } from "../../utils/Validations";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { AxiosResponse, AxiosError } from "axios";
// Assets
import { AiFillCloseCircle, AiOutlineMail } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { VscLock } from "react-icons/vsc";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { BsCheckCircleFill } from "react-icons/bs";
import SignUpPcImage from '../../images/signup_pc_image.png'
import facebookIcon from '../../images/icon/Facebook.png'
import discordIcon from '../../images/icon/discord.png'
import googleIcon from '../../images/icon/google.png'
import steamIcon from '../../images/icon/steam.png'
import twitchIcon from '../../images/icon/twitch.png'
// Style
import "../../styles/SignUp.css";
// Services
import AuthApis from "../../helper/auth.api";

// Initial state value declaration
const initialSignUpState = {
  showPassword: false,
  isChecked: false,
  termsAndConditionsCheckbox: false,
  joinClanId: null,
  joinTeamId: null,
  joinOrgId: null,
  joinClanOrTeamRole: null,
  joinTournamentId: null,
  stageId: null,
  matchId: null,
  gameId: null,
  groupIndex: null,
  password: '',
  email: '',
  lengthCriteria: false,
  alphabetCriteria: false,
  numberAndSymbolCriteria: false,
  invalidEmail: false
}

export default class SignUp extends Component {

  constructor() {
    super();
    this.state = { ...initialSignUpState };

    this.api = new AuthApis();
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    this.setState({
      joinClanId: queryParams.get('joinClanId'),
      joinTeamId: queryParams.get('joinTeamId'),
      joinOrgId: queryParams.get('joinOrgId'),
      joinClanOrTeamRole: queryParams.get('role'),
      joinTournamentId: queryParams.get('joinTournamentId'),
      stageId: queryParams.get('stageId'),
      matchId: queryParams.get('matchId'),
      gameId: queryParams.get('gameId'),
      groupIndex: queryParams.get('groupIndex')
    });
  }

  // Initiate signing up proccess
  signUp = (value) => {
    value.password = value.password.trim()
    var data = {
      ...value,
      isSSO: false,
      typeOfLogin: ["Manual"]
    };

    updateLoader(true);
    this.api.signUp(data)
      .then((response: AxiosResponse) => {
        this.setState({
          email: null,
          password: null,
        });
        this.handleClose();
        if (!response.data.result.user.isEmailVerified) {
          this.props.resetAndUpdateFlag(true,'openActivateAccount',true);
          if(this.props?.onClickOpenActivateAccount){
            this.props.onClickOpenActivateAccount();
          }
        }
        updateLoader(false);
        this.props.saveToLocalStorage(response.data.result, true);
      }).catch((reason: AxiosError) => {
        updateLoader(false);
        errorHandler(reason);
      });

  }

  // Go to login page
  openLogin = (props) => {
    this.setState({ ...initialSignUpState });
    this.props.resetAndUpdateFlag(true,'openLogin',true);
  }

  // Discard sign up process 
  handleClose = (props) => {
    this.setState({ ...initialSignUpState, socialMediaType: null });
    this.props.resetAndUpdateFlag(false,'openSignUp',false);
  }

  // Display hidden password
  handleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  // Accept Terms And Conditions
  acceptTermsAndConditions = async (param) => {
    global.socialMediaType = param;
    this.props.openPoliciesPopup(param)
    this.setState({ socialMediaType: param })
  }

  // Initiate the joining new team,org or clan's invitation
  initiateInvitation = () => {
    var link;
    if (this.state.joinClanId) {
      link = `/public/api/v1/auth/${this.state.socialMediaType}?type=SIGNUP&joinClanId=${this.state.joinClanId}&role=${this.state.joinClanOrTeamRole}`;
    } else if (this.state.joinTeamId) {
      link = `/public/api/v1/auth/${this.state.socialMediaType}?type=SIGNUP&joinTeamId=${this.state.joinTeamId}&role=${this.state.joinClanOrTeamRole}`;
    } else if (this.state.joinOrgId) {
      link = `/public/api/v1/auth/${this.state.socialMediaType}?type=SIGNUP&joinOrgId=${this.state.joinOrgId}&role=${this.state.joinClanOrTeamRole}`;
    } else {
      var additionalParam = `matchId=${this.state.matchId}&groupIndex=${this.state.groupIndex}`;
      link = `/public/api/v1/auth/${this.state.socialMediaType}?type=SIGNUP&joinTournamentId=${this.state.joinTournamentId}&stageId=${this.state.stageId}&${additionalParam}&gameId=${this.state.gameId}`;
    }
    window.open(process.env.REACT_APP_API_URL + link, "_self");
  }

  // Check if password is strong enough or not
  validatePassword = (password) => {
    if (password.length < 6) {
      this.setState({ lengthCriteria: false })
    } else {
      this.setState({ lengthCriteria: true })
    }

    let alphabetRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
    if (alphabetRegex.test(password)) {
      this.setState({ alphabetCriteria: true })
    } else {
      this.setState({ alphabetCriteria: false })
    }

    let charRegex = new RegExp('^(?=.*\\d)' + '(?=.*[-+_!@#$%^&*., ?]).+$');
    if (charRegex.test(password)) {
      this.setState({ numberAndSymbolCriteria: true })
    } else {
      this.setState({ numberAndSymbolCriteria: false })
    }
  }

  // Append added email and password 
  updateInput = (e, type) => {
    e.preventDefault()
    this.setState({
      [type]: e.target.value
    })
    if (type === 'password') {
      this.validatePassword(e.target.value)
    }
    if (type==='email'){
      let result = emailValidation(e.target.value);
      console.log("Is email Invalid??", result.error)
      this.setState({invalidEmail: result.error})
    }
  }

  render() {
    const { showPassword } = this.state;
    const type = showPassword ? "text" : "password";
    return (
      <div className="signUp-modal-page">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex flex-column pt-4 px-3">
              <div>
                <h1 className="text-heading fs-18 fw-bold text-white text-center">Be a Part of the Battlefield</h1>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '10px', top: '22px' }} onClick={this.handleClose} />
              </div>
              <p className="text-description fs-14 fw-nornal text-white text-center px-4">
                Experience all the feature to the fullest by signing up
              </p>
              <Formik
                enableReinitialize
                initialValues={{
                  email: this.state.email,
                  password: this.state.password,
                  acceptTerms: false
                }}
                validationSchema={Yup.object({
                  email: Yup.string().required('Enter your email')
                  .email('Invalid Email'),
                  password: Yup.string().required('Password required').matches(
                    /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "Conditions does not met"),
                  acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    this.signUp(values)
                    setSubmitting(false);
                  }, 400);
                }}>
                <Form className="d-flex flex-column p-0 m-0" autoComplete="off">
                  {/* Enter Email Field */}
                  <div className="my-2">
                    <label htmlFor='email' className="fs-16 fw-600 text-white mb-1">Email Address</label>
                    <div className="">
                      <AiOutlineMail className="field-icon position-absolute" style={{ marginTop: '12px' }} />
                      <Field name='email' type="email" placeholder="Enter Email" id="email" onKeyUp={(e) => this.updateInput(e, 'email')} className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white height-45" />
                    </div>
                    <ErrorMessage component="span" name="email" className="error-msg" />
                  </div>
                  {/*  Enter Password Field */}
                  <div className="my-2">
                    <label htmlFor="password" className="fs-16 fw-600 text-white mb-1">Password</label>
                    <div className="purple-field d-flex align-items-center">
                      <VscLock className="field-icon position-absolute" />
                      <Field name='password' id="password" placeholder="Enter Password" 
                        type={type} onKeyUp={(e) => this.updateInput(e, 'password')} 
                        className="d-flex align-items-center w-100 border-0 ps-5 text-white height-45"
                        style={{backgroundColor:'transparent', outline:'none'}}
                      />
                      {showPassword && (
                        <FiEye className="field-icon field-eye-icon me-2" onClick={this.handleShowPassword} />
                      )}
                      {!showPassword && (
                        <FiEyeOff className="field-icon field-eye-icon me-2" onClick={this.handleShowPassword} />
                      )}
                    </div>
                    <ErrorMessage component="span" name="password" className="error-msg" />
                  </div>
                  {/* Display error section */}
                  <div className="px-2 mt-2">
                    <div className="d-flex align-items-center">
                      {this.state.lengthCriteria ?
                        <BsCheckCircleFill className="text-success me-1" />
                        :
                        <AiFillCloseCircle className="error-msg me-1" />
                      }
                      <p className={this.state.lengthCriteria ? 'fs-12 fw-600 text-success mb-0' : 'fs-12 fw-600 error-msg mb-0'}>atleast 6 characters</p>
                    </div>
                    <div className="d-flex align-items-center">
                      {this.state.alphabetCriteria ?
                        <BsCheckCircleFill className="text-success me-1" />
                        :
                        <AiFillCloseCircle className="error-msg me-1" />
                      }
                      <p className={this.state.alphabetCriteria ? 'fs-12 fw-600 text-success mb-0' : 'fs-12 fw-600 error-msg mb-0'}>both lower(a-z) and upper case(A-Z)</p>
                    </div>
                    <div className="d-flex align-items-center">
                      {this.state.numberAndSymbolCriteria ?
                        <BsCheckCircleFill className="text-success me-1" />
                        :
                        <AiFillCloseCircle className="error-msg me-1" />
                      }
                      <p className={this.state.numberAndSymbolCriteria ? 'fs-12 fw-600 text-success mb-0' : 'fs-12 fw-600 error-msg mb-0'}>atleast one number(0-9) and a symbol</p>
                    </div>
                  </div>
                  {/* Term and Conditions Section */}
                  <div className="d-flex my-2">
                    <Field type="checkbox" name='acceptTerms' className="mt-1 me-2" />
                    <p className="text-white fs-14 fw-normal">
                      Confirm that you’re 18+ & agree with the{" "}
                      <NavLink to="/terms-and-conditions" className="blue" onClick={this.handleClose}>Terms & Conditions</NavLink> {' & '}
                      <NavLink to="/policies" className="blue" onClick={this.handleClose}>Privacy Policy</NavLink>
                    </p>
                  </div>
                  <div className="d-flex my-0">
                    <ErrorMessage component="span" name="acceptTerms" className="error-msg" />
                  </div>

                  <button type='submit' className='white-flat-btn w-25 fw-bold py-2 m-auto my-3'>
                    Sign Up
                  </button>
                </Form>
              </Formik>
              <div className="d-flex flex-row align-items-center mx-auto my-2" style={{ width: '60%' }}>
                <p className="modal-hr-line mb-0"></p>
                <p className="fs-14 text-white mx-2 mb-0">OR</p>
                <p className="modal-hr-line mb-0"></p>
              </div>
              {/* Accept Term & Conditions */}
              <div className="share-icon-container d-flex justify-content-center mt-2 mb-2">

                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={facebookIcon} alt="facebook icon" width={'20px'} className="" onClick={() => this.acceptTermsAndConditions('facebook')} />
                </div>
                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={googleIcon} alt="google icon" width={'20px'} className="" onClick={() => this.acceptTermsAndConditions('google')} />
                </div>
                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={discordIcon} alt="discord icon" width={'15px'} className="" onClick={() => this.acceptTermsAndConditions('discord')} />
                </div>
                <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img
                    src={twitchIcon} alt="twitch icon" width={'20px'} className="" onClick={() => this.acceptTermsAndConditions('twitch')} />
                </div>
                {/* <div className="martinique-color btn-size-35 rounded-circle border-0 d-flex flex-column justify-content-center align-items-center mx-2">
                  <img src={steamIcon} alt="steam icon" width={'20px'} className="" onClick={() => this.acceptTermsAndConditions('steam')} />
                </div> */}
              </div>

            </div>
            <p className="mobile-text d-lg-none d-block mt-3 mb-0 fs-16  fw-normal text-white">
              Already have an account? <span className="fs-16 fw-bold" onClick={this.openLogin}>Sign In</span>
            </p>
          </div>

          <div className="col-lg-6 d-lg-block d-none">
            <img src={SignUpPcImage} alt="sign up pc" className="sign-up-pc-image" />
            <div className="right-login-text-container">
              <p className="fs-16 text-white mb-0">Already have an account?</p>
              <p className="fs-16 fw-bold text-white mt-1 mb-0 cursor-pointer" onClick={this.openLogin} > Sign In </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}