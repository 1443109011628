import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { CgMenuGridR } from 'react-icons/cg';

class TournamentHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails:this.props.tournamentDetails,
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            tournamentDetails:newProps.tournamentDetails
        })
    }

    render() {

        return (
            <div className=''>
                <IoArrowBackCircleOutline onClick={()=>this.props.history.goBack()} className="back-btn mt-2" />
                <h1 className="clan-heading text-center mb-0">
                <img src={this.props.logo} className="tournament-header-logo me-2 mb-1" alt='' height={'20px'}></img>
                {this.props.heading}
                <NavLink className="text-white" to={{
                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview`,
                    state: { 
                        isConfig: true
                    }
                }}>
                    {this.props.heading !== "Configure Menu" ?
                    <CgMenuGridR className='fs-24 float-end' />
                     : 
                     null
                    }
                </NavLink>
                </h1>
                {this.props.subHeading}

            </div>
        );
    }
}

export default TournamentHeader;