import React, { Component } from 'react';
import TournamentApis from '../../../../helper/tournament.api';
import { errorHandler, getMatchAndParticipantsDetailsForBracketsDoubleElimination, getTournamentAdditionalData, getTournamentData } from "../../../../utils/common.utils";
import BracketGroup from './Duel/BracketGroup';
import DoubleElimination from './Duel/DoubleElimination';
import RoundRobin from './Duel/RoundRobin';
import SingleElimination from './Duel/SingleElimination';
import FFABracketGroup from './FFA/FFABracketGroup';
import FFASingleElimination from './FFA/FFASingleElimination';
import SimpleStage from './FFA/SimpleStage';
//import NoRecordFound from '../../../../images/clip-arts/noRecordFound.png';
import FFAFlexibleStage from './FFA/FFAFlexibleStage';
import NoRecordFound from '../../../common/NoRecordFound';

class BookSlot extends Component {

    constructor(props) {
        super(props);
        this.state = {
          tournamentDetails:null,
          structure: {},
          scale: 1.1,
          openInvite: false,
          matchDetailsAdded: false
        };
    
        this.tournamentApis = new TournamentApis();
        getTournamentAdditionalData(this.props.match.params.id, this.setData);
    

        this.tournamentDetails11 = null;
    }

    componentDidMount(){
        this.props.showOrHideNav(true);
    }

    setData = (values) => {
        
        if (values) {

            this.tournamentDetails11 = values;

            if(values.stages.length > 0){
                this.props.updateLoader(false);
                // console.log("Before Calling...")
                // console.log("values.stages : ",values.stages[this.props.match.params.index])
                var stageDetails = values.stages[this.props.match.params.index];
                getMatchAndParticipantsDetailsForBracketsDoubleElimination(this.props.match.params.id,this.setMatchData,this.props.match.params.index,values.stages);
            }

        } else {
            this.props.history.push('/pageNotFound')
        }
        
    }



  setMatchData = (values) => {
    //console.log("#### Adding Stage Details :::: ",values)
    if (values) {
        var tournamentDetails = this.tournamentDetails11;
        tournamentDetails.stages =values;
        this.setState({
          tournamentDetails: tournamentDetails,
          structure:  tournamentDetails.stages[this.props.match.params.index],
          matchDetailsAdded: true
        });
        
    } else {
        this.props.history.push('/pageNotFound')
    }
    
  }
    
    render() {
      
        if(this.state.matchDetailsAdded){

           // console.log("this.state.structure.subType :: ",this.state.structure.subType)
            if(this.state.structure.type === 'FFA'){
                switch (this.state.structure.subType) {
                    case 'Single Elimination':
                        return <FFASingleElimination state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                    case 'Simple Stage':
                        return <SimpleStage state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                    case 'FFA Bracket Groups':
                        return <FFABracketGroup state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                    default:
                        return <FFAFlexibleStage state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                        
                } 
            }else{
                switch (this.state.structure.subType) {
                    case 'Single Elimination':
                        return <SingleElimination state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                        
                    case 'Double Elimination':
                        return <DoubleElimination  state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                        
                    case 'Round Robin Groups':
                        return <RoundRobin  state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                        
                    default:
                        return <BracketGroup  state={this.state} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} orgId={this.props.match.params?.orgId} index={this.props.match.params.index} id={this.props.match.params.id} history={this.props.history} />
                
                } 
            }
        }else{
            return <NoRecordFound />
        }
    }
}

export default BookSlot;