import React, { Component } from 'react';
import { ellipsis, errorHandler, scoreCardGameURL } from '../../../../utils/common.utils';
//images
import scores from '../../../../images/icon/scores.png';
import orgIc from "../../../../images/icon/org-ic.png";
import crown from "../../../../images/score/escrown.png";
import star from "../../../../images/score/star.png";
import share from '../../../../images/page/share.png'
//components
import { getLeaderBoardDetails } from '../../../../utils/Tournament.utils';
import SoloDuelStandings from './SoloDuelStandings';
import SoloFFAStandings from './SoloFFAStandings';
import TeamDuelStandings from './TeamDuelStandings';
import TeamFFAStandings from './TeamFFAStandings';
import { ASSETS } from '../../../../utils/Strings.utils';
import SponsorCards from '../Scorecards/SponsorCards';

export default class FinalStanding extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails,
            leaderBoard: [],
            leaderBoardDetails: null,
            selectedStageDay: [],
            selectedIndex: 1,
            leaderBoardType: 'STAGE'
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {
        getLeaderBoardDetails(this.props.tournamentDetails._id, this.setLeaderBoardDetails)
    }

    setLeaderBoardDetails = (data) => {
        //console.log('setLeaderBoardDetails ::', data);
        let selectedStageDay = []
        for (var key in data.leaderBoard[0]) {
            let tempData = {};
            tempData = data.leaderBoard[0][key];
            tempData.id = key;
            selectedStageDay.push(tempData)
        }
        this.setState({ 
            leaderBoard: data.leaderBoard,
            leaderBoardDetails: data,
            selectedStageDay: selectedStageDay,
            leaderBoardType: data.leaderBoardType
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    setCurrentStageOrDay = (i) => {
        let selectedStageDay = []
        for (var key in this.state.leaderBoard[i]) {
            let tempData = {};
            tempData = this.state.leaderBoard[i][key];
            tempData.id = key;
            selectedStageDay.push(tempData)
        }
        this.setState({ selectedStageDay: selectedStageDay, selectedIndex: i + 1 })
    }

    getCard = (participant, i) => {
        const tournamentDetails = this.state.tournamentDetails;
        switch (true) {
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'DUEL'):
                return <SoloDuelStandings tournamentDetails={tournamentDetails} leaderBoardType={this.state.leaderBoardType} participant={participant} index={i} />;
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'FFA'):
                return <SoloFFAStandings tournamentDetails={tournamentDetails} leaderBoardType={this.state.leaderBoardType} participant={participant} index={i} />;
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'FFA'):
                return <TeamFFAStandings tournamentDetails={tournamentDetails} leaderBoardType={this.state.leaderBoardType} participant={participant} index={i} />;
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'DUEL'):
                return <TeamDuelStandings tournamentDetails={tournamentDetails} leaderBoardType={this.state.leaderBoardType} participant={participant} index={i} />;
            default:
                return <SoloDuelStandings tournamentDetails={tournamentDetails} leaderBoardType={this.state.leaderBoardType} participant={participant} index={i} />;
        }
    }

    render() {
        const organizationDetails = this.state.tournamentDetails?.organizationDetails;
        
        return (
            <section className='tournament-scoreboard-page text-white'>
                <div className='container d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                        <img src={scores} alt='' />
                        <p className='fs-16 fw-bold my-0 ms-2'>Final Standing</p>
                    </div>
                    {
                        this.state.leaderBoard?.length ?
                            <div>
                                <div className='d-flex overflow-auto my-3'>
                                    {/* <button className='active-btn me-2'>FINAL</button> */}
                                    {this.state.leaderBoard.map((ele, i) => {
                                        return <button className={`${((this.state.selectedIndex-1) === i)?'active-btn':'normal-btn'} me-2`} onClick={() => { this.setCurrentStageOrDay(i) }} key={i}>{(this.state.leaderBoardDetails?.leaderBoardType === 'DAY') ? 'DAY' : 'STAGE'} {i + 1}</button>
                                    })}
                                </div>
                                <div className='standings-card d-flex flex-column p-2 mx-auto'>
                                    <div className='row'>
                                        <div className='col-4 text-start'>
                                            <p className='fs-14 fw-bold my-0' style={{ color: '#FFE420' }}>{this.state.leaderBoardType} {this.state.selectedIndex}</p>
                                        </div>
                                        <div className='col-4 text-center'>
                                            <img src={this.gameUrl} alt='' style={{ zIndex: '1', height: '25px' }} />
                                        </div>
                                        <div className='col-4 text-end'>
                                            <div className='d-flex align-items-center justify-content-end'>
                                                <h5 className='text-white fw-bold fs-8 me-2 mb-0'>{ellipsis(organizationDetails?.name, 12)}</h5>
                                                <img src={organizationDetails ? organizationDetails?.logo?.url : orgIc} className="" alt='' style={{ zIndex: '1', height: '25px' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-grow-1'>
                                        <div className='row'>
                                            {this.state.selectedStageDay.map((participant, i) => {
                                                return this.getCard(participant, i)
                                            })}
                                        </div>
                                    </div>
                                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                </div>
                            </div>
                            :
                            <div className="text-center">
                                <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                            </div>
                    }

                    <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center' type='submit'>
                        <img src={share} alt='share' />
                    </button>

                </div>
            </section>
        )
    }
}