import * as axios from "axios";
import { queryParams } from "../utils/common.utils";
import { getToken } from "../utils/localstorage.utils";

export default class ClanApis {
  
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    getClans = (params ? ) => {
        if (params) {
            const searchString = queryParams(params)
            return this.init().get(`/private/api/v1/clan/getClans?${searchString}`);
        }
        return this.init().get("/private/api/v1/clan/getClans");
    };

    getPublicClans = (params ? ) => {
        if (params) {
            const searchString = queryParams(params)
            return this.init().get(`/public/api/v1/clan/getClans?${searchString}`);
        }
        return this.init().get("/public/api/v1/clan/getClans");
    };

    addNewClan = (data) => {
        return this.init().post("/private/api/v1/clan/createClan", data);
    };
    updateClan = (id, data) => {
        return this.init().put(`/private/api/v1/clan/updateClan/${id}`, data);
    }
    deleteClan = (id) => {
        return this.init().delete(`/private/api/v1/clan/deleteClan/${id}`);
    };

    joinClanOrTeamUsingInvitationLink = (data, type) => {
        return this.init().post(`/private/api/v1/${this.acceptInvitationLink(type)} `, data);
    };
    leaveClanMember = (data) => {
        return this.init().post("/private/api/v1/clan/leaveClan", data);
    }
    leaveTeamByTeamMember = (data) => {
        return this.init().post("/private/api/v1/clan/leaveClanTeamMembers", data);
    }

    acceptInvitationLink = (type) =>{
        console.log("100 type :: ",type)
        switch (type) {
            case "clan":
                return `${type}/addToClanUsingLink`;
            case "team":
                return `${type}/addToTeamUsingLink`;
            case "organization":
                return `${type}/addToOrganization`;
            default:
                return null;
        }
    }

}