import { toast } from 'material-react-toastify';
import { setTournamentDetails } from '../redux/actions/tournametActions';
import { errorHandler, updateLoader } from './common.utils';

import MatchesApis from '../helper/matches.api';
import TeamApis from '../helper/teams.api';
import TournamentApis from '../helper/tournament.api';
import { ParticipantType } from './enums.utils';
import { sendJoinedTournamentNotificationToMembers } from './notification.utils';

const matchesApis = new MatchesApis();
const tournamentApis = new TournamentApis();

export const mapStateToProps = (props) => {
    return {
        tournamentData: props.tournamentData,
    }
}
export const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data) => dispatch(setTournamentDetails(data)),
    }
}

export const  addParticipantInTournament = (updateStageDetails, matchId, participantId, stageDetails, tournamentDetails,stageId?, groupIndex?) => {
  
    var isRoundRobin = (stageDetails.subType === "Round Robin Groups" ? true : false) 
    
    if (isRoundRobin) {
        var data = {
            tournamentId: tournamentDetails._id,
            stageIndex: stageId,
            groupIndex: groupIndex,
            participantId: participantId
        }
        updateLoader(true);
        matchesApis.addParticipantToRoundRobin2(data)
            .then((res: AxiosResponse) => {
                toast.success(res.data.result);
                updateStageDetails(res.data.result)
                updateLoader(false);
            }).catch((reason: AxiosError) => {
                errorHandler(reason)
                updateLoader(false);
            });

    } else {
        var data = {
            participantId: participantId
        }

       
        updateLoader(true);
        matchesApis.addParticipants2(matchId, data)
            .then((res: AxiosResponse) => {
                toast.success("You are participated in tournament successfully");
                updateStageDetails(res.data.result)
                updateLoader(false);
            }).catch((reason: AxiosError) => {
                errorHandler(reason)
                updateLoader(false);
            });
    }
}

export const getMyTeams = (id, matchDetail, addParticipantInTournament, selectTeams) =>{
    
    const teamApis = new TeamApis();

    updateLoader(true);
    const data = {
        gameId: id
    }
    teamApis.getMyTeams(data).then(
        (res: AxiosResponse) => {
            console.log("$$$ RESPONSE :: ",res.data.result)
            if (res.data.result.length > 0) {
                if (res.data.result.length === 1) {
                    addParticipantInTournament(matchDetail._id, res.data.result[0]._id);
                } else {
                    var data = {
                        teamsArray: res.data.result,
                        selectedMatchDetail: matchDetail
                    };
                    selectTeams(true,data);
                }
            } else {
                selectTeams(false,null);
            }
           updateLoader(false);
        }
    ).catch(
        (err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        }
    )
}


export const getTournamentData = (param, setTournamentDetails) => {


    updateLoader(true);
    param.joinTournamentDetails = null;
    tournamentApis.getTournamentsById({ _id: param.joinTournamentId }).then(
        (res: AxiosResponse) => {
            updateLoader(false);
            param.joinTournamentDetails = res.data.result[0]
            setTournamentDetails(param);
        }
    ).catch(
        (err: AxiosError) => {
            updateLoader(false);
            errorHandler(err);
            setTournamentDetails(param);
        }
    )
}

export const getGroupName = (stageDetails, index) =>{
    try {
        return stageDetails?.groupsName[index] ? stageDetails.groupsName[index] : `Group ${index+1}`;
    }catch(error){
        return `Group ${index+1}`;
    }
}

export const getStageDetails = (tournamentId, stageIndex, setData) => {
    updateLoader(true);
    matchesApis.getStageDetails(tournamentId, stageIndex).then(
        (res: AxiosResponse) => {
            updateLoader(false);
            setData(res.data.result);
        }
    ).catch(
        (err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        }
    )
}

export const addParticipantToFirstRound = (param, data,clearParams) =>{
   
    updateLoader(true);
    matchesApis.addParticipantToFirstRound(param.joinTournamentId,data)
    .then((res: AxiosResponse) => {
        toast.success("You are participated in tournament successfully");
        console.log("\n\n\n\n\ res :: ",res.data.result)
        updateLoader(false);
        sendJoinedTournamentNotificationToMembers(param, data)
        clearParams(true);
    }).catch((reason: AxiosError) => {
        console.log(reason)
        updateLoader(false);
        clearParams(false);
        errorHandler(reason);
    });
}

export const addParticipantToFirstRound2 = (tournamentDetails, data,updateStageDetails) =>{
    updateLoader(true);
    matchesApis.addParticipantToFirstRound(tournamentDetails?._id,data)
    .then((res: AxiosResponse) => {
        toast.success("You are participated in tournament successfully.");
        updateLoader(false);
        sendJoinedTournamentNotificationToMembers({joinTournamentDetails: tournamentDetails}, data)
        updateStageDetails(res.data.result);
    }).catch((reason: AxiosError) => {
        console.log(reason)
        updateLoader(false);
        errorHandler(reason);
    });
}


export const getLeaderBoardDetails = (tournamentId, setLeaderBoardDetails) => {
    updateLoader(true);
    tournamentApis.getLeaderBoard(tournamentId)
    .then((res: AxiosResponse) => {
        updateLoader(false);
        setLeaderBoardDetails(res?.data?.result[0]);
    }).catch((reason: AxiosError) => {
        console.log(reason)
        updateLoader(false);
        errorHandler(reason);
    });
}

export const isTeamGame = (tournamentDetails) => {
    return (tournamentDetails?.participantType === "TEAM");
}

