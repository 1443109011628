import { combineReducers } from 'redux';
import { AllGamesReducer, AllPublicTournamentsReducer, UpdateEcReducer } from './commonReducer';
import { tournamentDetailsReducer, updateScoreReducer } from './tournamentRedcer';

const reducers = combineReducers({
    tournamentData: tournamentDetailsReducer,
    gameData: AllGamesReducer,
    publicTornamentData: AllPublicTournamentsReducer,
    updateScore: updateScoreReducer,
    UpdateEc:UpdateEcReducer
})

export default reducers;