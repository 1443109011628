import { Component } from 'react'
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
//components
import TournamentApis from '../../helper/tournament.api'
import { ellipsis, errorHandler, getMonthAndDate, regions, updateLoader } from '../../utils/common.utils';
//images and icons
import '../../styles/PublicTournaments.css'
import '../../styles/Layout.css'
import premium from '../../images/premium-card.png';
import regular from '../../images/regular.png';
import betBadge from '../../images/common/bet_badge.png';
import { AiFillStar, AiOutlineSearch } from "react-icons/ai";
import { Carousel, ProgressBar } from 'react-bootstrap';
import { IoMdArrowDropdown } from 'react-icons/io';
import gameCover from '../../images/League_of_legends.png';
import { NavLink } from 'react-router-dom';
import TeamApis from '../../helper/teams.api';
import MatchesApis from '../../helper/matches.api';
import { toast } from 'material-react-toastify';
import { Modal } from "react-bootstrap";
import MatchInvite from '../popup/MatchInvite';
import AcceptInvite from '../popup/AcceptInvite';

import Pagination from "react-js-pagination";
import { addParticipantToFirstRound, getTournamentData } from '../../utils/Tournament.utils';
import { ASSETS } from '../../utils/Strings.utils';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { profile } from '../../utils/localstorage.utils';
import { getAllGamesDetails } from '../../utils/Games.utils';
import {ParticipantType, ListingType, TournamentVisibility, PrizePoolType, TournamentStatus, GamingPlatforms} from '../../utils/enums.utils'
import TournamentCard from '../organization/tournaments/common/TournamentCard';

export default class Tournament extends Component {


    constructor(props) {
        super(props)

        this.state = {
            tournamentsArray: [],
            searchInput: '',
            showFilters: false,
            acceptTournamentInvite: false,
            teamsArray: [],
            openConfirm: false,
            participantType: this.props?.state?.participantType ? this.props.state.participantType : null,
            param: null,
            totalResults: 0,
            activePage: 1,
            allGames: [],
        }
        this.apiPayload = {}
        this.tournamentApis = new TournamentApis();
        this.teamApis = new TeamApis();
        this.matchesApis = new MatchesApis();
        this.offSet = 30;
        this.pageNo = 0
        getAllGamesDetails(this.setAllGamesDetails)
    }

    componentDidMount() {
        if (this.props.state?.joinTournamentId && this.props.state.stageId && (this.props.state.matchId || this.props.state.groupIndex) && this.props.state.gameId && this.props.state.participantType) {

            this.setState({
                param: {
                    joinTournamentId: this.props.state.joinTournamentId,
                    stageId: this.props.state.stageId,
                    matchId: this.props.state.matchId,
                    gameId: this.props.state.gameId,
                    groupIndex: this.props.state.groupIndex,
                    participantType: this.props.state.participantType,
                    isRoundRobin: this.props.state.isRoundRobin
                }
            });

            // if (this.state.participantType === 'TEAM') {
            //     this.getMyTeams(this.props.state.gameId);
            //     console.log('Tournament getMyTeams called if-1');
            // } else {
            //     this.setState({ acceptTournamentInvite: true })
            //     console.log('Tournament openInvite called if-1');
            // }
        }

        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get('joinTournamentId') && queryParams.get('stageId') && (queryParams.get('matchId') || queryParams.get('groupIndex')) && queryParams.get('gameId') && queryParams.get('participantType')) {
            console.log(`
                joinTournamentId: ${queryParams.get('joinTournamentId')},
                stageId : ${queryParams.get('stageId')},
                matchId : ${queryParams.get('matchId')},
                gameId : ${queryParams.get('gameId')},
                groupIndex : ${queryParams.get('groupIndex')},
                participantType : ${queryParams.get('participantType')},
                isRoundRobin : ${queryParams.get('isRoundRobin')},
            `);

            var param = {
                joinTournamentId: this.props?.state?.joinTournamentId,
                stageId: this.props.state?.stageId,
                matchId: this.props.state?.matchId,
                gameId: this.props.state?.gameId,
                groupIndex: this.props.state?.groupIndex,
                participantType: this.props.state?.participantType,
                isRoundRobin: this.props.state?.isRoundRobin
            }

      

            this.setState({
                param: param
            });
            
        

            getTournamentData(param, this.setTournamentDetails);

            // if (this.state.participantType === 'TEAM') {
            //     this.getMyTeams(this.props.state.gameId);
            // } else {
            //     this.setState({ acceptTournamentInvite: true })
            // }

        }

        this.apiPayload = {
            pageNo: this.pageNo,
            limit: this.offSet
        }
        this.getData(this.apiPayload);
        this.props.showOrHideNav(true);
    }

    setTournamentDetails = (data) =>{
        console.log("##### setTournamentDetails :: ",data.joinTournamentDetails.competitionType)
    //    var param = this.state.param;
    //    param.joinTournamentDetails = data;
        

        if (this.state.participantType === 'TEAM') {
            this.setState({
                param : data
            });
            this.getMyTeams(this.props.state.gameId);
        } else {
            this.setState({
                param : data,
                acceptTournamentInvite: true
            });
        }
    }

    setAllGamesDetails = (response) => {
        this.setState({allGames : response});
    }

    //Get My teams
    getMyTeams = (id) => {
        updateLoader(true);
        const data = {
            gameId: id
        }
        this.teamApis.getMyTeams(data).then(
            (res: AxiosResponse) => {
                if (res.data.result.length > 0) {
                    if (res.data.result.length === 1) {
                        //this.getMatchDetails(this.props.state.matchId, res.data.result[0]._id);
                        //this.updateMatchDetails();
                        console.log("4. addParticipants..........")
                        this.addParticipantInTournament(this.props.state.matchId, res.data.result[0]._id);
                    } else {
                        this.setState({
                            teamsArray: res.data.result,
                            acceptTournamentInvite: true
                        });
                    }
                } else {
                    this.setState({ openConfirm: true })
                }
                this.props.clearInvitations();
                updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.clearInvitations();
                updateLoader(false);
                errorHandler(err);
            }
        )
    }

    clearInvitations = () => {
        this.props.clearInvitations();
        this.setState({
            acceptTournamentInvite: !this.state.acceptTournamentInvite
        });
    }

    getMatchDetails = (matchId, joinTeamId) => {
        const data = {
            _id: matchId
        }

        this.matchesApis.getMatchDetails(data).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                //console.log("Match Data : ", response);
                this.updateMatchDetails(response[0], response[0]['participants'], joinTeamId, response[0]['maxParticipantsPerMatch']);
                updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )

    }

    updateMatchDetails = (matchDetails, participants, joinTeamId, maxParticipantsPerMatch) => {
      //  console.log(`\n matchDetails :: ${matchDetails},\n participants :: ${participants} ,\n joinTeamId :: ${joinTeamId},\n maxParticipantsPerMatch :: ${maxParticipantsPerMatch}`)
      //  console.log('Tournament updateMatchDetails called');
        if (maxParticipantsPerMatch === undefined || participants.length < maxParticipantsPerMatch) {

            participants.push(joinTeamId);

            var data = {
                participants: participants
            };

            updateLoader(true);
            this.matchesApis.updateMatchDetails(matchDetails._id, data)
                .then((res: AxiosResponse) => {
                    toast.success("You are participated in tournament successfully");
                    updateLoader(false);
                    this.props.history.push(`/tournament/${this.props.state.joinTournamentId}`);
                }).catch((reason: AxiosError) => {
                    updateLoader(false);
                    errorHandler(reason);
                });
        } else {
            toast.error("Match slot has been booked already please contact Admin.")
        }

    }

    getData = (params) => {
        params['visibility'] = TournamentVisibility.PUBLIC;
        updateLoader(true);
        this.tournamentApis.getPublicTournaments(params).then(
            (res: AxiosResponse) => {
                var data = res.data['result']
                // let tournamentsArray = [...this.state.tournamentsArray, data];
                // this.pageNo = this.pageNo + 1;
                this.setState({ tournamentsArray: data, totalResults: res.data['totalResults'] })
                updateLoader(false);
                this.apiPayload={};
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    toggleFilterStatus = () => {
        this.setState({ showFilters: !this.state.showFilters })
    }

    onClickOpenInvite = (joinTeamId?) => {
        this.setState({
            acceptTournamentInvite: !this.state.acceptTournamentInvite
        });
        if (joinTeamId) {
            console.log("3. addParticipants..........")
            this.addParticipantInTournament(this.props.state.matchId, joinTeamId);
        }
    }

    joinTournament = (groupIndex, joinTeamId) => {
        let data = {
            tournamentId: this.props.state.joinTournamentId,
            stageIndex: parseInt(this.props.state.stageId),
            groupIndex: parseInt(groupIndex),
            participantId: joinTeamId
        }
        updateLoader(true);
        this.tournamentApis.joinRoundRobinTournament(data)
            .then((res: AxiosResponse) => {
                toast.success("You are participated in tournament successfully");
                updateLoader(false);
                this.props.history.push(`/tournament/${this.props.state.joinTournamentId}`);
            }).catch((reason: AxiosError) => {
                updateLoader(false);
                errorHandler(reason);
            });
    }

    clearParams = (isRedirect?) => {
        
        if(isRedirect){
            this.props.history.push(`/tournament/${this.state.param.joinTournamentId}`);
        }
        this.setState({
            param: null,
            acceptTournamentInvite: false
        });
    }

    getDistributePrizeToDetails = () =>{
        console.log("entryFee-----------------------------",this.state.param?.joinTournamentDetails?.entryFeeAndPrizeDetails?.entryFee )
        console.log("participantType-----------------------------",this.state.param?.joinTournamentDetails?.participantType)
        if(this.state.param?.joinTournamentDetails?.entryFeeAndPrizeDetails?.entryFee && this.state.param?.joinTournamentDetails?.participantType === "SINGLE"){
            console.log("Inside-----------------------------",profile().id)
            return [profile().id]
        }
        return null
    }

    addParticipantInTournament = (matchId, participantId, tournamentId) => {
       
        if(this.state.param?.joinTournamentDetails){
            console.log("joinTournamentDetails :: ",this.state.param.joinTournamentDetails)
            console.log("competitionType :: ",this.state.param.joinTournamentDetails.competitionType);
        }
        console.log("________________________________________________")
        console.log("stageId :: ",this.state.param?.stageId)
        console.log("isRoundRobin :: ",this.state.param?.isRoundRobin)
        console.log("competitionType :: ",this.state.param?.joinTournamentDetails?.competitionType )
        console.log("________________________________________________")
        if(parseInt(this.state.param?.stageId) === 0 && this.state.param?.isRoundRobin !== 'true' && this.state.param?.joinTournamentDetails?.competitionType === "DUEL"){
            console.log("Inside addParticipantToFirstRound IF............")
            var data = { 
                participantId : participantId,
                groupIndex : this.state.param?.groupIndex? parseInt(this.state.param.groupIndex) : null,
                distributePrizeTo : this.getDistributePrizeToDetails()
            }
            addParticipantToFirstRound(this.state.param, data, this.clearParams);


        }else{
            console.log("Inside addParticipants ELSE............")
            if (this.state.param.isRoundRobin === 'true') {
                var data = {
                    tournamentId: this.state.param.joinTournamentId,
                    stageIndex: parseInt(this.state.param.stageId),
                    groupIndex: parseInt(this.state.param.groupIndex),
                    participantId: participantId
                }
    
    
           
                updateLoader(true);
                this.matchesApis.addParticipantToRoundRobin(data)
                    .then((res: AxiosResponse) => {
                        toast.success(res.data.result);
                        updateLoader(false);
                        this.props.history.push(`/tournament/${this.state.param.joinTournamentId}`);
                        this.clearParams();
                    }).catch((reason: AxiosError) => {
                        updateLoader(false);
                        this.clearParams();
                        errorHandler(reason);
                    });
    
            } else {
                var data = {
                    participantId: participantId,
                    distributePrizeTo : this.getDistributePrizeToDetails()
                }
    
    
                updateLoader(true);
                console.log("1. addParticipants..........")
                this.matchesApis.addParticipants(this.state.param.matchId, data)
                    .then((res: AxiosResponse) => {
                        toast.success("You are participated in tournament successfully");
                        updateLoader(false);
                        this.props.history.push(`/tournament/${this.state.param.joinTournamentId}`);
                        this.clearParams();
                    }).catch((reason: AxiosError) => {
                        updateLoader(false);
                        this.clearParams();
                        errorHandler(reason);
                    });
            }
        }
       
    }

    handlePageChange(pageNumber) {
       // console.log(`active page is ${pageNumber}`);
        // const params = {
        //     pageNo: pageNumber - 1,
        //     limit: this.offSet
        // }
        this.apiPayload.pageNo =  pageNumber - 1;
        this.apiPayload.limit =  this.offSet;
        this.getData(this.apiPayload);
        this.setState({ activePage: pageNumber });
    }

    passDetails = (values) => {
        console.log('formik values ::::',values);
        this.setState({ showFilters: false })
        Object.keys(values).map((key, i) => {
            if (values[key] === '') {
                delete values[key];
            }
        });
        //console.log('formik values ::::',values);
        var data = { ...values };
        //console.log('data ::::',data);
        if (data['listingType']) {
            data = { ...data, 'entryFeeAndPrizeDetails.listingType': data['listingType'] };
            delete data['listingType'];
        }
        if (data['prizePoolType']) {
            data = { ...data, 'entryFeeAndPrizeDetails.prizePoolType': data['prizePoolType'] };
            delete data['prizePoolType'];
        }
        if (data['entryFee']) {
            data = { ...data, 'entryFeeAndPrizeDetails.entryFee': JSON.parse(data['entryFee']) };
            delete data['entryFee'];
        }
        if (this.state.searchInput?.length) {
            data = { ...data, searchTerm: 'name', searchValue: this.state.searchInput };
        }
        this.apiPayload['pageNo'] = this.pageNo;
        this.apiPayload['limit'] = this.offSet;
        //console.log('initial apiPayload ::', this.apiPayload);
        for(let key in data){
            this.apiPayload[key] = data[key]
        }
        // this.apiPayload = {...data}
        //data = {...data, pageNo: this.state.activePage - 1, limit: this.offSet}
        //console.log('after apiPayload::', this.apiPayload);
        this.getData(this.apiPayload);
    }

    clearFilters = () => {
        const params = {
            pageNo: this.pageNo,
            limit: this.offSet
        }
        this.getData(params);
        this.setState({ showFilters: false })
    }
    searchFilter = (e) => {
        // let data = {...this.apiPayload}
        this.apiPayload.pageNo =  this.pageNo;
        this.apiPayload.limit =  this.offSet;
        if (e.target.value?.length >= 3) {
            this.apiPayload.searchTerm = 'name'
            this.apiPayload.searchValue = e.target.value
            // data = { searchTerm: 'name', searchValue: e.target.value }
            this.getData(this.apiPayload);
        }else if(e.target.value === ''){
            this.getData(this.apiPayload);
        }else{
            delete this.apiPayload.searchTerm
            delete this.apiPayload.searchValue
        }
        // this.apiPayload = {...data}
        //this.getData(this.apiPayload);
    }

    tournamentSearch = (e) => {
        let searchText = e.target.value;
        var letters = /^[a-zA-Z ]*$/;
        if(e.target.value === ''){
            this.setState({ searchInput: searchText });
            this.searchFilter(e);
        }else{
            if(searchText.match(letters)){
                this.setState({ searchInput: searchText });
                this.searchFilter(e);
            }else{
                toast.warning('Only alphabet with spaces allowed');
            }
        }
    }

    render() {
        const { tournamentsArray, searchInput, param } = this.state;
       // console.log("STAGE :: ",this.state.param?.stageId)
        //console.log('Tournament render...',param);
        return (

            <section className='body-section public-tournaments layout1 pb-0' style={{ backgroundColor: '#200c45' }}>
                {/* <div onClick={()=> this.props.history.goBack()}>
                    <IoArrowBackCircleOutline className="back-btn" />
                </div> */}

                <section className='container mt-lg-5 p-3 mb-5'>
                    <div className='row'>
                        <div className='col-lg-6 offset-lg-3 col-12'>
                            <div className='purple-field d-flex align-items-center'>
                                <input
                                    type='search'
                                    placeholder='search'
                                    onChange={(e)=> this.tournamentSearch(e)}
                                    value={this.state.searchInput}
                                    className='w-100 border-0 ps-3 text-white fs-14 height-45 flex-grow-1'
                                    style={{ backgroundColor: 'transparent', outline: 'none' }} />
                                <AiOutlineSearch className='btn-size-20 me-3 text-white flex-shrink-0' />
                            </div>
                        </div>
                        <div className='col-lg-6 offset-lg-3 col-12 mt-3'>
                            <NavLink to='/tournaments/my-tournaments'>
                                <button className='pink-skyblue-gradient-btn w-100 fw-bold p-3 m-auto mb-2 text-white border-0'>My Tournaments</button>
                            </NavLink>
                        </div>
                    </div>

                    <div className='row justify-content-between'>
                        <div className='col-lg-2 col-6 my-3'>
                            <div className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45 flex-grow-1 justify-content-between" onClick={this.toggleFilterStatus}>
                                <h6 className="my-auto ms-2">Filter</h6>
                                <IoMdArrowDropdown className='mx-2' />
                            </div>
                        </div>
                    </div>

                    <div className='p-2 rounded-3' style={{ backgroundColor: '#493d62', display: (this.state.showFilters) ? 'block' : 'none' }}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                gameId: '',
                                platform: '',
                                isBettingEnabled: '',
                                listingType: '',
                                participantType: '',
                                region: '',
                                entryFee: '',
                                prizePool: '',
                                tournamentStatus: ''
                            }}
                            validationSchema={Yup.object({
                                gameId: Yup.string(),
                                platform: Yup.string(),
                                isBettingEnabled: Yup.string(),
                                listingType: Yup.string(),
                                participantType: Yup.string(),
                                region: Yup.string(),
                                entryFee: Yup.string(),
                                prizePool: Yup.string()
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.passDetails(values)
                                    setSubmitting(false);
                                }, 400);
                            }}>
                            <Form>
                                <div className='row p-2'>
                                    <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                                        <Field name="gameId" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value="">All Game</option>
                                            {this.state.allGames?.map((game, i) => {
                                                return <option value={game._id} key={i}>{game.name}</option>
                                            })}
                                        </Field>
                                    </div>
                                    <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                                        <Field name="platform" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value="">Platform</option>
                                            <option value={GamingPlatforms.PC}>PC</option>
                                            <option value={GamingPlatforms.MOBILE}>Mobile</option>
                                            <option value={GamingPlatforms.NINTENDO}>Switch</option>
                                            <option value={GamingPlatforms.PS}>PS</option>
                                            <option value={GamingPlatforms.XBOX}>X-box</option>
                                        </Field>
                                    </div>
                                    <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                                        <Field name="isBettingEnabled" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value="">Betting</option>
                                            <option value={JSON.stringify(false)}>Disabled</option>
                                            <option value={JSON.stringify(true)}>Enabled</option>
                                        </Field>
                                    </div>
                                    <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                                        <Field name="region" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value="">Region</option>
                                            {regions?.map((region, i) => {
                                                return <option key={i} value={region}>{region}</option>
                                            })}
                                        </Field>
                                    </div>
                                    <div className='col-lg-3 col-md-4 col-12 pe-1 my-1 px-1'>
                                        <Field name="listingType" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value="">Listing Type</option>
                                            <option value={ListingType.REGULAR}>Regular</option>
                                            <option value={ListingType.PREMIUM}>Premium</option>
                                        </Field>
                                    </div>
                                    <div className='col-lg-3 col-md-4 col-12 ps-1 my-1 px-1'>
                                        <Field name="participantType" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value=''>Participant Type</option>
                                            <option value={ParticipantType.SINGLE}>Single</option>
                                            <option value={ParticipantType.TEAM}>Team</option>
                                        </Field>
                                    </div>

                                    <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                                        <Field name="entryFee" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value="">Entry</option>
                                            <option value={JSON.stringify(false)}>Free</option>
                                            <option value={JSON.stringify(true)}>Paid</option>
                                        </Field>
                                    </div>
                                    <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                                        <Field name="prizePoolType" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                            <option value={PrizePoolType.NONE}>Prize Pool</option>
                                            <option value={PrizePoolType.FIXED}>Fixed</option>
                                            <option value={PrizePoolType.CROWDFUNDED}>Crowd Funded</option>
                                        </Field>
                                    </div>
                                </div>
                                <div role="group" className='d-flex justify-content-around text-white'>
                                    <label className='fs-14 fw-500'>
                                        <Field type="radio" name="tournamentStatus" value={TournamentStatus.UPCOMING} className='me-1' />
                                        Open
                                    </label>
                                    <label className='fs-14 fw-500'>
                                        <Field type="radio" name="tournamentStatus" value={TournamentStatus.COMPLETED} className='me-1' />
                                        Past
                                    </label>
                                    <label className='fs-14 fw-500'>
                                        <Field type="radio" name="tournamentStatus" value={TournamentStatus.ONGOING} className='me-1' />
                                        Live
                                    </label>
                                    <label className='fs-14 fw-500'>
                                        <Field type="radio" name="tournamentStatus" value={TournamentStatus.DRAFT} className='me-1' />
                                        Draft
                                    </label>
                                </div>
                                <div className='d-flex justify-content-center w-100 my-2'>
                                    <button className='mx-auto bg-black text-warning border-0 rounded-pill px-3 py-1' type='submit'>Apply</button>
                                    <button className='mx-auto bg-black text-warning border-0 rounded-pill px-3 py-1' type='reset' onClick={this.clearFilters}>Clear</button>
                                </div>
                            </Form>
                        </Formik>
                    </div>

                    <div className="row g-2">
                        {tournamentsArray?.map((tournament, id) => { 
                            return (
                            <div className="col-6 col-md-4 col-lg-2 text-white" key={id}>
                                <NavLink to={`/tournament/${tournament._id}`} style={{ textDecoration: 'none' }}>
                                    <TournamentCard tournament={tournament} id={id} key={id}/>
                                </NavLink>
                            </div>
                            )
                        })}
                        {!this.state.tournamentsArray.length &&
                            <div className="text-center">
                                <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                            </div>
                        }
                    </div>
                    
                    { (this.state.totalResults > this.offSet) ? <Pagination activePage={this.state.activePage} itemsCountPerPage={this.offSet} totalItemsCount={this.state.totalResults} pageRangeDisplayed={5} onChange={this.handlePageChange.bind(this)} className='mt-5' /> : null }

                </section>
                <Modal centered show={this.state.acceptTournamentInvite} dialogClassName="ClanInvite-modal">
                    {this.state.participantType === ParticipantType.SINGLE ?
                        <Modal.Body>
                            <AcceptInvite
                                clearInvitations={this.clearInvitations}
                                tournamentId={this.props.state.joinTournamentId}
                                addParticipantInTournament={this.addParticipantInTournament}
                                joinTeamRole={null}
                                close={this.clearInvitations}
                                type="add"
                                info={{}}
                                title='Accept Invite'
                                role='player'
                                matchId={this.props.state.matchId}
                                isTournamentInvitation={true}
                            />
                        </Modal.Body>
                        :
                        <Modal.Body>
                            <MatchInvite
                                onClickOpenInvite={this.onClickOpenInvite}
                                isPaidTournament={this.state.param?.joinTournamentDetails?.entryFeeAndPrizeDetails?.entryFee}
                                teamsArray={this.state.teamsArray}
                                param={this.state.param}
                                choice="Join Tournament"
                                isRoundRobin={false}
                                clearParams={this.clearParams}
                                joinTournamentDetails={this.state.param?.joinTournamentDetails}
                                history={this.props.history}
                            />
                        </Modal.Body>
                    }
                </Modal>

                <Modal show={this.state.openConfirm} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <h1 className='fs-20 fw-600 error-msg text-center'>Warning</h1>
                            <p className='fs-16 fw-500 text-white text-center mt-3'>There isn't any existing team for this game. Please create one and try again later.</p>
                            <div className='d-flex justify-content-evenly'>
                                <NavLink to='/clans'>
                                    <button className='white-flat-btn fw-bold py-2 px-4'>Go to clans</button>
                                </NavLink>
                                <button className='white-flat-btn fw-bold py-2 px-4' onClick={() => this.setState({ openConfirm: false })}>Close</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>

        )
    }
}
