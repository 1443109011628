import React, { Component } from 'react';
import TournamentHeader from '../../../../tournament/common/TournamentHeader';
import structureIc from '../../../../../images/icon/stageImg.png';
import '../../../../../styles/Tournaments.css'
import MatchesApis from '../../../../../helper/matches.api';
import { errorHandler, getMarginTopForRounds, getMonthAndDate } from '../../../../../utils/common.utils';
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import { HiUserAdd } from "react-icons/hi";
import { MdRefresh, MdZoomOutMap } from "react-icons/md";
import MatchInvite from '../../../../popup/MatchInvite';
import { Modal } from "react-bootstrap";

import NoRecordFound from '../../../../../images/clip-arts/noRecordFound.png';
import DoubleBrackets from '../../../../common/DoubleBrackets';
import Seeding from '../Seeding';
import SeedingSwitch from '../../common/SeedingSwitch';

export default class BracketGroupDoubleRounds extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            tournamentDetails: props.tournamentDetails,
            groupMatches: props.groupMatches,
            enableSeeding: props.enableSeeding,
            scale: 1,
            openInvite: false,
            selectedMatch: null,
        }
        this.marginTopDiv = 35;

        this.matchApis = new MatchesApis();

        
       
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            //groupMatches: newProps.groupMatches,
            tournamentDetails: newProps.tournamentDetails,
            enableSeeding: newProps.enableSeeding
        });
    }

    zoom = (key) => {
        switch (key) {
          case 'In':
            this.setState({ scale: this.state.scale * 1.1 });
            break;
          case 'Out':
            this.setState({ scale: this.state.scale / 1.1 });
            break;
          default:
            this.setState({ scale: 1 });
            break;
        }
      }

      openInviteModal = (e, matchDetails, matchID,matchNo) =>{
        this.setState({
          openInvite: true,
          selectedMatch: matchDetails
        })
      }
      
      onClickOpenInvite = () => {
        this.setState({
          openInvite: !this.state.openInvite
        })
      }

      getWiningBrackets = (marginTopDiv, structure) =>{
        console.log(" &&&&&&&&&&& structure : ",structure);
        console.log("  &&&&&&&&&&& marginTopDiv : ",marginTopDiv);
        return (
            <div className='d-flex pt-3 pe-5' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, overflow:'none', width:'fit-content'}}>                    
                {structure.map((value, i) => {
                
                    marginTopDiv = (marginTopDiv === 0 ? (35) : ((marginTopDiv * 2)));
                    var isFirstRow = (i === 0);
                    this.prevValue = getMarginTopForRounds(i,this.prevValue)
                    return (
                        <div key={i}>
                        <button type="button" className={`score-showdown-btn me-2 rounds ms-${(i === 0) ? 3 : 5}`}>ROUND {i + 1}</button>
                        <div className="d-flex" key={i}>
    
                            <div style={{ marginTop: `${(marginTopDiv - (marginTopDiv / 2) < 28 ? 0 : (marginTopDiv - (marginTopDiv / 2) - 7))}px`, width: '1px', display: `${isFirstRow ? 'none' : 'block'}`}} >
                            {[...Array(value)].map((p, k) => {
                                return <p className="vertical-line" key={k} style={{ height: `${marginTopDiv+2}px`, marginTop: `${k === 0 ? null : marginTopDiv}px` }}></p>
                            })}
                            </div>
    
                            <div className="group-tab" style={{ marginTop: `${this.prevValue}px` }}>
                            <div className="row pe-0">
                                {(i === 0) ?
                                <div className="col-12 pe-0">
                                    {[...Array(value)].map((e, j) => {
                                        if(this.state.groupMatches[this.props.isWinningBrackets][1][j]){
                                            //console.log(`this.state.groupMatches[${this.props.isWinningBrackets}][1]["+j+"] :: `,this.state.groupMatches[this.props.isWinningBrackets][1][j])
                                            var dateAndTime =getMonthAndDate(this.state.groupMatches[this.props.isWinningBrackets][1][j].matchSchedule);
                                            var match_detail= this.state.groupMatches[this.props.isWinningBrackets][1][j];
                                            // console.log(dateAndTime+"=== type of match_detail :: ",(typeof match_detail))
                                            if(typeof match_detail === 'object'){
                                            // console.log("match_detail :: ",match_detail)
                                                var player_1 = match_detail?.participants[0];
                                                var player_2 = match_detail?.participants[1];
                                                const isTeamPlayer = this.state.tournamentDetails?.participantType === "TEAM" ? true : false;
                                                return (
                                                <div className="d-flex pe-0" style={{ alignItems: 'center', marginLeft: '-5px' }} key={j}>
                                                    <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100">
                                                    <span className="match-tag">Match 1.{(j + 1)}</span>
                                                    <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                                        <div className="d-flex flex-column">
                                                        {
                                                            player_1 ?
                                                            <div className="d-flex align-items-center">
                                                            {this.state.isSwappingEnabled ? <input  type='checkbox' id={player_1._id} onChange={(e)=>this.handleInputChange(e,match_detail,1,j)} /> : null }
                                                            <span className="player-name my-1">{ isTeamPlayer ? player_1.name : player_1.inGameName }</span>
                                                            </div>
                                                            :
                                                            <span className="player-name my-1 vacant">Vacant</span>
                
                                                        }
                                                        {
                                                            player_2 ?
                                                            <div className="d-flex align-items-center">
                                                            {this.state.isSwappingEnabled ? <input  type='checkbox' id={player_2._id} onChange={(e)=>this.handleInputChange(e,match_detail,1,j)} /> : null }
                                                            <span className="player-name my-1">{ isTeamPlayer ? player_2.name : player_2.inGameName}</span>
                                                            </div>
                                                            :
                                                            <span className="player-name my-1 vacant">Vacant</span>
                
                                                        }
                                                        </div>
                                                        {this.state.groupMatches[this.props.isWinningBrackets][1][j].participants.length === 2 ? null :
                                                        <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-2" onClick={()=>this.openInviteModal(j)}>
                                                            <HiUserAdd className='btn-size-15'  />
                                                        </button>
                                                        }
                                                        
                                                        <div className="d-flex flex-column">
                                                        <span className="player-name my-1">{dateAndTime[0]}</span>
                                                        <span className="player-name">{dateAndTime[1]}</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <hr className="mid-line" />
                                                </div>
                                                )
                                            }else{
                                                
                                                setTimeout(()=> {
                                                        
                                                this.zoom('Reset');
                                                }, 1000);
                                            
                                            }
                                        }
                                    })}
                                </div>
                                :
                                [...Array(value)].map((e, j) => {
                                    //console.log(`this.state.groupMatches[${this.props.isWinningBrackets}][`+(i+1)+`][`+j+`] :: `,this.state.groupMatches[this.props.isWinningBrackets])
                                    if(this.state.groupMatches[this.props.isWinningBrackets][i+1] && this.state.groupMatches[this.props.isWinningBrackets][i+1][j]){
                                        var dateAndTime =getMonthAndDate(this.state.groupMatches[this.props.isWinningBrackets][i+1][j].matchSchedule)
                                        
                                        return (
                                        <div className="col-12 pe-0" style={{ marginTop: (j === 0 ? '0px' : `${this.prevValue * 2}px`) }} key={j}>
                                            <div className="d-flex" style={{ alignItems: 'center', marginLeft: '-5px'}}>
                                            <hr className="mid-line" />
                                            <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100">
                                                <span className="match-tag">Match {(i + 1) + "." + (j + 1)}</span>
                                                <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                                <div className="d-flex flex-column">
                                                    <span className="player-name my-1">Vacant</span>
                                                    <span className="player-name">Vacant</span>
                                                </div>
                                                <div className="d-flex flex-column align-items-end">
                                                    <span className="player-name my-1">{dateAndTime[0]}</span>
                                                    <span className="player-name">{dateAndTime[1]}</span>
                                                </div>
                                                </div>
                                            </div>
                                            {(structure.length-1) === i ? null : <hr className="mid-line" />}
                                            </div>
                                        </div>
                                        )
                                    }
                                })
                                }
                            </div>
                            </div>
                        </div>
    
                        </div>
                    )
                })}
            </div>
        );
      
      }

      getMarginTopForRounds = (index) => {
        var marginTop = 0;
        switch (index) {
          case 0:
            marginTop = 0;
            break;
          case 1:
            marginTop = 35;
            break;
          case 2:
            marginTop = 105;
            break;
          default:
            marginTop = ((this.prevValue * 2) + 35);
            break;
        }
        return marginTop;
      }

    getLosingBrackets = (marginTopDiv, structure) => {
    console.log(" &&&&&&&&&&& structure : ",structure);
    console.log("  &&&&&&&&&&& marginTopDiv : ",marginTopDiv);
    return (
        <div className='d-flex pt-3 pe-5' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, overflow:'none', width:'fit-content'}}>                    
            {structure.map((value, i) => {
                var isFirstRow = (i === 0);

                if(i%2 === 0){
                    marginTopDiv = (marginTopDiv === 0 ? (35) : ((marginTopDiv * 2)));
                    this.prevValue = (i > 1) ? (this.getMarginTopForRounds(i === 2 ? 1 : i-2)) : (this.getMarginTopForRounds(i));
            
                }

                // marginTopDiv = (marginTopDiv === 0 ? (35) : ((marginTopDiv * 2)));
                // var isFirstRow = (i === 0);
                // this.prevValue = getMarginTopForRounds(i,this.prevValue)
                return (
                    <div key={i}>
                    <button type="button" className={`score-showdown-btn me-2 rounds ms-${(i === 0) ? 3 : 5}`}>ROUND {i + 1}</button>
                    <div className="d-flex" key={i}>

                        <div style={{ marginTop: `${(marginTopDiv - (marginTopDiv / 2) < 28 ? 0 : (marginTopDiv - (marginTopDiv / 2) - 7))}px`, width: '1px', display: `${isFirstRow ? 'none' : 'block'}`}} >
                            {[...Array(value)].map((p, k) => {
                                if(i%2 === 0){
                                return <p className="vertical-line" key={k} style={{ height: `${marginTopDiv+2}px`, marginTop: `${k === 0 ? null : marginTopDiv}px` }}></p>
                                }
                            })}
                        </div>

                        <div className="group-tab" style={{ marginTop: `${this.prevValue}px` }}>
                        <div className="row pe-0">
                            {(i === 0) ?
                            <div className="col-12 pe-0">
                                {[...Array(value)].map((e, j) => {
                                    if(this.state.groupMatches[this.props.isWinningBrackets][1][j]){
                                        //console.log(`this.state.groupMatches[${this.props.isWinningBrackets}][1]["+j+"] :: `,this.state.groupMatches[this.props.isWinningBrackets][1][j])
                                        var dateAndTime =getMonthAndDate(this.state.groupMatches[this.props.isWinningBrackets][1][j].matchSchedule);
                                        var match_detail= this.state.groupMatches[this.props.isWinningBrackets][1][j];
                                        //console.log(dateAndTime+"=== type of match_detail :: ",(typeof match_detail))
                                        if(typeof match_detail === 'object'){
                                            console.log("match_detail :: ",match_detail)
                                            var player_1 = match_detail?.participants[0];
                                            var player_2 = match_detail?.participants[1];
                                            const isTeamPlayer = this.state.tournamentDetails?.participantType === "TEAM" ? true : false;
                                            return (
                                            <div className="d-flex pe-0" style={{ alignItems: 'center', marginLeft: '-5px' }} key={j}>
                                                <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100">
                                                <span className="match-tag">Match 1.{(j + 1)}</span>
                                                <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                                    <div className="d-flex flex-column">
                                                    {
                                                        player_1 ?
                                                        <div className="d-flex align-items-center">
                                                        {this.state.isSwappingEnabled ? <input  type='checkbox' id={player_1._id} onChange={(e)=>this.handleInputChange(e,match_detail,1,j)} /> : null }
                                                        <span className="player-name my-1">{ isTeamPlayer ? player_1.name : player_1.inGameName }</span>
                                                        </div>
                                                        :
                                                        <span className="player-name my-1 vacant">Vacant</span>
            
                                                    }
                                                    {
                                                        player_2 ?
                                                        <div className="d-flex align-items-center">
                                                        {this.state.isSwappingEnabled ? <input  type='checkbox' id={player_2._id} onChange={(e)=>this.handleInputChange(e,match_detail,1,j)} /> : null }
                                                        <span className="player-name my-1">{ isTeamPlayer ? player_2.name : player_2.inGameName}</span>
                                                        </div>
                                                        :
                                                        <span className="player-name my-1 vacant">Vacant</span>
            
                                                    }
                                                    </div>
                                                    {/* {this.state.groupMatches[this.props.isWinningBrackets][1][j].participants.length === 2 ? null :
                                                    <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-2" onClick={()=>this.openInviteModal(j)}>
                                                        <HiUserAdd className='btn-size-15'  />
                                                    </button>
                                                    } */}
                                                    
                                                    <div className="d-flex flex-column">
                                                    <span className="player-name my-1">{dateAndTime[0]}</span>
                                                    <span className="player-name">{dateAndTime[1]}</span>
                                                    </div>
                                                </div>
                                                </div>
                                                <hr className="mid-line" />
                                            </div>
                                            )
                                        }else{
                                            
                                            setTimeout(()=> {
                                                    
                                            this.zoom('Reset');
                                            }, 1000);
                                        
                                        }
                                    }
                                })}
                            </div>
                            :
                            [...Array(value)].map((e, j) => {
                                //console.log(`this.state.groupMatches[${this.props.isWinningBrackets}][`+(i+1)+`][`+j+`] :: `,this.state.groupMatches[this.props.isWinningBrackets])
                                if(this.state.groupMatches[this.props.isWinningBrackets][i+1] && this.state.groupMatches[this.props.isWinningBrackets][i+1][j]){
                                    var dateAndTime =getMonthAndDate(this.state.groupMatches[this.props.isWinningBrackets][i+1][j].matchSchedule)
                                    
                                    return (
                                    <div className="col-12 pe-0" style={{ marginTop: (j === 0 ? '0px' : `${this.prevValue * 2}px`) }} key={j}>
                                        <div className="d-flex" style={{ alignItems: 'center', marginLeft: '-5px'}}>
                                        <hr className="mid-line" />
                                        <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100">
                                            <span className="match-tag">Match {(i + 1) + "." + (j + 1)}</span>
                                            <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                            <div className="d-flex flex-column">
                                                <span className="player-name my-1">Vacant</span>
                                                <span className="player-name">Vacant</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-end">
                                                <span className="player-name my-1">{dateAndTime[0]}</span>
                                                <span className="player-name">{dateAndTime[1]}</span>
                                            </div>
                                            </div>
                                        </div>
                                        {(structure.length-1) === i ? null : <hr className="mid-line" />}
                                        </div>
                                    </div>
                                    )
                                }
                            })
                            }
                        </div>
                        </div>
                    </div>

                    </div>
                )
            })}
        </div>
    );
    
    }

    closeSeeding = (tournamentDetails,updatedStage) =>{
        tournamentDetails.stages[this.props.index] = updatedStage;
        this.props.enableDisableSeeding();
        this.setState({ 
          enableSeeding: false,
          tournamentDetails: tournamentDetails,
          groupMatches: updatedStage.groups[this.props.selectedGroupeIndex]
        });
    }

    enableDisableSeeding = () =>{
        this.setState({ enableSeeding: !this.state.enableSeeding });
    }

    openInviteModal2 = () =>{
        //console.log(this.props.selectedGroupeIndex+"-------INSIDE openInviteModal2 :: ",this.state.groupMatches)
        this.setState({
          openInvite: true,
          matchId: null,
          selectedMatch: this.state.groupMatches[0][1][0]
        });
    }
    
  
    
    render() {
        //console.log("groupMatches :: ",this.state.groupMatches[this.props.isWinningBrackets])
        
        if(this.state.groupMatches[this.props.isWinningBrackets] && Object.keys(this.state.groupMatches[this.props.isWinningBrackets]).length !== 0){
            var marginTopDiv = 0;

            var structure = [];
            Object.keys(this.state.groupMatches[this.props.isWinningBrackets]).map((key, i) => {
                structure[i]= this.state.groupMatches[this.props.isWinningBrackets][key].length;
            });
            //var marginTopDiv = 0;
            // console.log(" BEFORE &&&&&&&&&&& structure : ",structure);
            // console.log(" BEFORE &&&&&&&&&&& marginTopDiv : ",marginTopDiv);
          
            return (
                <div>

                <div>
                
                        {/* Brackets Section */}
                        { this.state.enableSeeding ?
                            <Seeding marginTopDiv='' selectedGroupeIndex={this.props.selectedGroupeIndex} closeSeeding={this.closeSeeding} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.tournamentDetails?.stages[this.props.index]} />
                            : 
                            <DoubleBrackets 
                                structure={structure} 
                                includeThirdPlace={false} 
                                isSwappingEnabled={false} 
                                handleInputChange={this.handleInputChange} 
                                openInviteModal={this.openInviteModal} 
                                stageMatchDetails={this.state.groupMatches} 
                                isTournamentOperator={true}
                                showLoosingBracket = {(this.props.isWinningBrackets === 0 ? false : true)}
                                enabledGrandFinal ={false} 
                                isBracketGroup={true}
                                tournamentDetails={this.state.tournamentDetails}
                                index={this.props.index}
                                selectedGroupeIndex={this.props.selectedGroupeIndex}
                                placeOccupied={this.props.placeOccupied}
                                stageDetails={this.state.tournamentDetails?.stages[this.props.index]}
                                openInviteModal2={this.openInviteModal2}
                                isDoubleBracket={true}
                                marginTopDiv=''
                            />
                        } 

                    {/* Tournament Invitation Section */}
                    <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
                        <Modal.Body>
                        <MatchInvite 
                            onClickOpenInvite= {this.onClickOpenInvite} 
                            tournamentId= {this.state.selectedMatch?.tournamentId}
                            stageId= {this.props.index}
                            matchId= {this.state.selectedMatch?._id}
                            gameId= {this.state.selectedMatch?.gameId}
                            participantType={this.state.tournamentDetails?.participantType}
                            groupIndex={this.props.selectedGroupeIndex}
                            choice= "Send Invite" 
                        />
                        </Modal.Body>
                    </Modal>
                </div>
             
                </div>
            );
    
        }else{
            return(
                <div className=''>
                    <div className='d-flex justify-content-center align-items-center'>
                    <img src={NoRecordFound} className="w-100" alt=''/>
                    </div>
                </div>
            )
        }
    }
}

