import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai';
import { POST_INTERACTIONS } from '../../../utils/Images.utils';

export default class CommentActions extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        commentActionsFor : props.commentActionsFor
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      commentActionsFor : newProps.commentActionsFor
    });
  }


  // Post creator's action on his own comments
  postCreatorActions = () =>{
    return (
      <div className='text-white post-action-container mt-auto actions-body'>
        <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.props.enableEditComment}>
            <img src={POST_INTERACTIONS.ACTIONS.EDIT} alt='report-post' width='32' className='me-3' />
            <p className='fs-16 fw-600 my-0'> Edit Commet</p>
        </div>
        <hr className='my-1' style={{border: '1px solid #6D53DB'}} />

        {this.props.showPinPost && 
          <>
            <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.props.pinUnpinCommentOnPost}>
              <img src={POST_INTERACTIONS.ACTIONS.PIN} alt='download-post' width='32' className='me-3'  />
              <p className='fs-16 fw-600 my-0'> Pin Comment</p>
            </div>
            <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
          </>
        }
      
        <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.props.deleteComment}>
            <img src={POST_INTERACTIONS.ACTIONS.REMOVE} alt='download-post' width='32' className='me-3' />
            <p className='fs-16 fw-600 my-0'> Delete Comment</p>
        </div>
      </div>
    )
  }

  // Post creator's action on others comments
  postCreatorActionsOnOtherComments = () =>{   
    return (
      <div className='text-white post-action-container mt-auto actions-body'>
        {this.props.showPinPost && 
          <>
            <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.props.pinUnpinCommentOnPost}>
              <img src={POST_INTERACTIONS.ACTIONS.PIN} alt='download-post' width='32' className='me-3'  />
              <p className='fs-16 fw-600 my-0'> Pin Comment</p>
            </div>
            <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
          </>
        }
        <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.props.deleteComment}>
            <img src={POST_INTERACTIONS.ACTIONS.REMOVE} alt='download-post' width='32' className='me-3' />
            <p className='fs-16 fw-600 my-0'> Delete Comment</p>
        </div>
      </div>
    )
  }

  // Post commentor's action on his comments
  postCommentersAction = () =>{
    return (
      <div className='text-white post-action-container mt-auto actions-body'>
        <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.props.enableEditComment}>
            <img src={POST_INTERACTIONS.ACTIONS.EDIT} alt='report-post' width='32' className='me-3' />
            <p className='fs-16 fw-600 my-0'> Edit Commet</p>
        </div>
        <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
        <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.props.deleteComment}>
            <img src={POST_INTERACTIONS.ACTIONS.REMOVE} alt='download-post' width='32' className='me-3' />
            <p className='fs-16 fw-600 my-0'> Delete Comment</p>
        </div>
      </div>
    )
  }

  getActionsView = () =>{
    switch (this.state.commentActionsFor) {
      case "Creator":
        return this.postCreatorActions();    
      case "Commenter":
        return this.postCommentersAction();    
      default:
          return this.postCreatorActionsOnOtherComments();
    }
  }
  
  render() {
    return (
      <Modal className="d-flex flex-column w-100" show={this.props.show} dialogClassName="testsss my-0">
        <div className='text-center cursor-pointer'><AiFillCloseCircle onClick={this.props.onClose} style={{height:'40px', width:'40px', color: 'white' }} /></div>
        {this.getActionsView()}
      </Modal>
    )
  }
}
