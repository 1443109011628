import { Component, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'material-react-toastify';

// Api
import CommonApis from "../../helper/common.api";
import OrganizationApis from '../../helper/organization.api';

// Css
import "../../styles/Organization.css";

// Icon
import { AiOutlineCloudUpload, AiFillCloseCircle, AiFillPlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";

// Utils
import { profile } from '../../utils/localstorage.utils'

import AddMoreDetails from "../popup/AddMoreDetails";
import { errorHandler, defaultLogo, defaultCover, loadCountryCodes } from "../../utils/common.utils";
import { capitalize } from "../../utils/common.utils";
import { createAndSendNotification } from "../../utils/notification.utils";
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const DELAY = 1500;

class CreateOrganization extends Component {
    commonApi = new CommonApis()
    organizationApi = new OrganizationApis()
    orgLogoUrl;
    orgCoverUrl;
    images = {
        orgLogo: null,
        orgCover: null
    }
    profile = profile()
    extraDetails = [
        { label: 'instagram', key: 'instagram', value: '', isChecked: false, error: '' },
        { label: 'facebook Gaming', key: 'facebook', value: '', isChecked: false, error: '' },
        { label: 'twitter', key: 'twitter', value: '', isChecked: false, error: '' },
        { label: 'telegram', key: 'telegram', value: '', isChecked: false, error: '' },
        { label: "youtube", key: 'youtube', value: '', isChecked: false, error: '' },
        { label: "loco", key: 'loco', value: '', isChecked: false, error: '' },
        //{ label: "smash", key: 'smash', value: '', isChecked: false, error: ''},
        { label: "twitch", key: 'twitch', value: '', isChecked: false, error: '' }
    ];
    constructor(props) {
        super(props);
        this.state = {
            orgLogo: null,
            orgCover: null,
            addDetails: false,
            recaptcha: "",
            load: false,
            expired: "false",
            appendDetails: false,
            extraDetails: this.extraDetails,
            openPageLayout: false,
            websiteLink: 'https://dev.espotz.online/',
            description: '',
            countryCodes: [],
            countryCode1: global?.countryode ? global.countryCode : '+91',
            countryCode2: global?.countryode ? global.countryCode : '+91',
        };
        this._reCaptchaRef = createRef();
        //this.getCodes();
    }
    componentDidMount() {
        this.props.showOrHideNav(true);
        // setTimeout(() => {
        //     this.setState({ load: true });
        // }, DELAY);
        console.log("global.countryCode app - ", global.countryCode)
        loadCountryCodes(this.setCountryCodes)
        this.setState({
            countryCode1: global?.countryCode ? global.countryCode : '+91',
            countryCode2: global?.countryCode ? global.countryCode : '+91'
        })
    }

    setCountryCodes = (codes) => {
        if (codes) {
            this.setState({ countryCodes: codes, country: JSON.stringify(codes[0])});
        }
    }

    getCodes = () => {
        this.props.updateLoader(true);
        this.commonApi.getCountryCodes().then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                this.setState({ countryCodes: res.data.result })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        );
    }

    //To reset images
    resetLogo = (id) => {
        let key = id
        this.setState({ [key]: null })
        this.images[key] = {}
    }

    //To open files
    triggerInputFile = () => this.fileInput.click()
    triggerInputFile2 = () => this.fileInput2.click()

    //For Reptcha
    handleChange = value => {
        this.setState({ recaptcha: value });

        // if value is null recaptcha expired
        if (value === null) this.setState({ expired: "true" });
    };

    //Upload images
    addImg = (event, key) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                [key]: URL.createObjectURL(img),
            });
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            this.props.updateLoader(true);
            this.commonApi
                .uploadSingleImg(formData)
                .then((response: AxiosResponse) => {
                    this.images[key] = response.data.result;
                    this.props.updateLoader(false);

                })
                .catch((err: AxiosError) => {
                    this.props.updateLoader(false);
                    console.log(err);
                });
        }
    }

    //Create Orginazation
    onSubmit = values => {
        if (!this.images.orgLogo?.url) {
            this.images.orgLogo = defaultLogo;
        }
        if (!this.images?.orgCover?.url) {
            this.images.orgCover = defaultCover;
        }
        // if (!this.state.recaptcha) {
        //     toast.error('Select recaptcha')
        //     return false
        // }
        // reCAPTCHA is commented out for now 
        if (!values.whatsappNo && !values.discord) {
            toast.error('Enter whatsapp number or discord id')
            return false
        }
        const data = {
            logo: this.images.orgLogo,
            coverImage: this.images?.orgCover,
            name: values.organizationName.trim(),
            contactPersonUsername: values.userName,
            contactPersonName: values.contactName,
            contactPersonNumber: { code: this.state.countryCode1, number: values.contactNumber },
            bio: values.description,
            members: {},
            microWebsiteLink: this.state.websiteLink,
            socialMediaDetails: {
                whatsapp: { code: this.state.countryCode2, number: values.whatsappNo },
                discord: values.discord ? values.discord : ''
            }
        }
        this.state.extraDetails.map(el => {
            if (el.value) {
                data.socialMediaDetails[el.key] = el.value
            }
        })
        this.props.updateLoader(true);
        this.organizationApi.addNewOrganization(data).then(
            (res: AxiosResponse) => {
                this.props.history.push(`/organization/micro-website/configuration/${res.data.result._id}`)
                var notification = {
                    type : "ORGANIZATION",
                    heading : "Organization Created 👍",
                    message : `Your Organization '${data.name}' has been successfully created and you have been assigned as owner of your Organization.`,
                    link : `/organization/view/${res.data.result._id}`
                };
                createAndSendNotification(notification, [profile().id])
                 this.props.updateLoader(false);
                toast.success('Organization created successfully');
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    };

    //Get website url on Org Name change
    getMicroWeb = (e) => {
        if ((/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g.test(e.target.value))) {
            const data = {
                name: e.target.value
            }
            this.organizationApi.getMicroWebUrl(data).then(
                (res: AxiosResponse) => {
                    this.setState({ websiteLink: res.data.result })
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
        }
    }

    //Open close add more details
    toggleAddMoreDetails = (e) => {
        e.preventDefault()
        this.setState({ addDetails: !this.state.addDetails })
    }

    //to rander the selected details 
    renderAddedDetails = (details) => {
        this.setState({ extraDetails: details, addDetails: false })
    }

    //add more details
    addMoreDetails = () => {
        this.setState(prevState => ({ addDetails: !prevState.addDetails }))
    }

    //Remove Details
    removeDetails = (event, value) => {
        event.preventDefault()
        const updateArr = this.state.extraDetails
        let index = this.state.extraDetails.map(el => { return el.label }).indexOf(value)
        updateArr[index].value = ''
        updateArr[index].isChecked = false
        this.setState({ extraDetails: updateArr })
    }

    updateBio = (e) => {
        this.setState({ description: e.target.value })
    }

    setSession = (key, val) => {
        global.countryCode = val
        this.setState({ [key]: val })
    }
    render() {
        const { value, load, expired } = this.state || {};

        const { openPageLayout } = this.state
        return (
            <>
                <section className="body-section create-organization">
                    <div className="container mt-lg-5 p-4">
                        <h1 className="fs-20 fw-600 text-white mt-2 mb-4 text-center">Create Organization</h1>

                        <Formik
                            innerRef={this.formikRef}
                            initialValues={{
                                organizationName: "",
                                // websiteLink: "https://dev.espotz.online/",
                                description: "",
                                contactName: this.profile.name,
                                contactNumber: "",
                                userName: this.profile.username,
                                whatsappNo: "",
                                discord: profile()?.discordProfile?.discordUsername ? profile()?.discordProfile?.discordUsername : "",
                                countryCode1: this.state.countryCode1,
                                countryCode2: this.state.countryCode2
                            }}

                            validationSchema={Yup.object({
                                organizationName: Yup.string().trim().required("Organization Name is required").matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, 'Only alphabets allowed').max(50, 'Max 50 characters'),
                                description: Yup.string().trim().max(500, 'Max 500 characters'),
                                contactName: Yup.string().required("Contact Name is required"),
                                contactNumber: Yup.string().matches(/^[0-9]*$/, 'only numerics allowed').required("Mobile number is required").min(9, "min 9 digits required").max(12, "max 12 digits allowed"),
                                userName: Yup.string(),
                                whatsappNo: Yup.string().matches(/^[0-9]*$/, 'only numerics allowed').min(9, "min 9 digits required").max(12, "max 12 digits allowed"),
                                discord: Yup.string().matches(/^.{3,32}#[0-9]{4}$/, 'Invalid Username'),
                                countryCode1: Yup.string(),
                                countryCode2: Yup.string()
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.onSubmit(values)
                                    setSubmitting(false);
                                }, 400);
                            }}
                            onChange={(values) => {
                                this.getMicroWeb(values)
                            }
                            }
                        >
                            <Form className="row create-organization-form" autoComplete="off">
                                <div className="col-lg-6 col-12 px-4">
                                    <div className="row justify-content-center mt-2 mt-lg-3">
                                        <div className="col-5 col-md-4 col-lg-4">
                                            <div className="uploadWithPreview">
                                                <div className="upload-section">
                                                    <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden ref={fileInput => this.fileInput = fileInput} onChange={(e) => this.addImg(e, 'orgLogo')} name="" className="" />
                                                    {
                                                        this.state.orgLogo ?
                                                            <div className="text-right flex-column text-end">
                                                                <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={() => { this.resetLogo('orgLogo') }} />
                                                                <div className="after-uploaded-image" onClick={this.triggerInputFile}>
                                                                    <img src={this.state.orgLogo} alt="" className="" />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="before-uploaded-panel">
                                                                <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                                                    <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile}>
                                                                        <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                                        <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Logo</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-7 col-md-5 col-lg-5">
                                            <div className="uploadWithPreview">
                                                <div className="upload-section">
                                                    <input type="file" accept=".png, .jpg, .jpeg" id="upload_cover_file" hidden onChange={(e) => this.addImg(e, 'orgCover')} ref={fileInput2 => this.fileInput2 = fileInput2} name="" className="" />
                                                    {
                                                        this.state.orgCover ?
                                                            <div className="text-right flex-column text-end">
                                                                <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={() => { this.resetLogo('orgCover') }} />
                                                                <div className="after-uploaded-image" onClick={this.triggerInputFile2}>
                                                                    <img src={this.state.orgCover} alt="" className="" />
                                                                </div>
                                                                {/* <AiFillCloseCircle className="logo-reset-icon" onClick={this.resetCover} /> */}
                                                            </div>
                                                            :
                                                            <div className="before-uploaded-panel">
                                                                <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                                                    <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile2}>
                                                                        <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                                        <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Banner</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="">
                                        <label htmlFor="organizationName" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Organization Name*</label>
                                        <Field
                                            name="organizationName"
                                            type="text"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            placeholder="Enter Organization Name"
                                            onKeyUp={this.getMicroWeb}
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="organizationName" />
                                    </div>
                                    <div className="">
                                        <label htmlFor="websiteLink" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Micro-Website Link </label>
                                        <Field
                                            disabled
                                            name="websiteLink"
                                            type="text"
                                            as="textarea"
                                            rows="3"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14"
                                            placeholder="https://dev.espotz.online/"
                                            value={this.state.websiteLink}
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="websiteLink" />
                                    </div>
                                    <div className="">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="description" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Description</label>
                                            <label htmlFor="description" className="fs-12  fw-600 text-white-50 mb-1 mt-2 mt-lg-3">{`${this.state.description?.length}/500`}</label>
                                        </div>
                                        <Field
                                            name="description"
                                            type="text"
                                            as="textarea"
                                            rows="4"
                                            maxLength={500}
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14"
                                            placeholder="Enter Description"
                                            onKeyUp={(e) => this.updateBio(e)}
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="description" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 px-4">
                                    <div className="d-flex mt-3" style={{ alignItems: 'center' }}>
                                        <hr className="border-1 flex-grow-1" style={{ height: '2px' }} />
                                        <div className="d-flex mx-3" style={{ alignItems: 'center' }}>
                                            <BsTelephoneFill className="btn-size-20 me-2 text-white-50" />
                                            <p className="fs-16 fw-bold text-white-50 mb-0">Contact Details</p>
                                        </div>
                                        <hr className="border-1 flex-grow-1" style={{ height: '2px' }} />
                                    </div>

                                    <div className="">
                                        <label htmlFor="contactName" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Contact Name*</label>
                                        <Field
                                            name="contactName"
                                            type="text"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            placeholder="Enter Contact Name"
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="contactName" />
                                    </div>
                                    <div className="">
                                        <label htmlFor="userName" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Espotz Username*</label>
                                        <Field
                                            name="userName"
                                            type="text"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            placeholder="Enter Espotz Username"
                                            disabled={true}
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="userName" />
                                    </div>
                                    <div className="">
                                        <label htmlFor="contactNumber" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Contact Number*</label>
                                        <Field name="countryCode1" value={this.state.countryCode1} as='select' onChange={(e) => this.setSession('countryCode1', e.target.value)} className="sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45 mb-1">
                                            {this.state.countryCodes.map((el, i) => {
                                                return <option value={el.code} key={i}>{`${el.code} ${el.name}`}</option>
                                            })}
                                        </Field>
                                        <Field
                                            name="contactNumber"
                                            type="text"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            placeholder="Enter Contact Number"
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="contactNumber" />
                                    </div>
                                    <div className="">
                                        <label htmlFor="contactNumber" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">WhatsApp Number*</label>
                                        <Field name="countryCode2" value={this.state.countryCode2} as='select' onChange={(e) => this.setSession('countryCode2', e.target.value)} className="sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45 mb-1">
                                            {this.state.countryCodes.map((el, i) => {
                                                return <option value={el.code} key={i}>{`${el.code} ${el.name}`}</option>
                                            })}
                                        </Field>
                                        <Field
                                            name="whatsappNo"
                                            type="text"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            placeholder="Enter WhatsApp Number"
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="whatsappNo" />
                                    </div>
                                    <div className="">
                                        <label htmlFor="userName" className="fs-16 fw-600 text-white mb-1 mt-2 mt-lg-3">Discord Username</label>
                                        <Field
                                            name="discord"
                                            type="text"
                                            disabled={true}
                                            //validate={this.validateDiscordUsername}
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            placeholder="mike#1234"
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="discord" />
                                    </div>

                                    {this.state.extraDetails.map((el, i) => {
                                        return (
                                            (el.value !== '' && el.error === '') ?
                                                <div className="my-2" key={i}>
                                                    <h1 className="fs-14 fw-600 text-white mt-2">{capitalize(el.label)}</h1>
                                                    <input
                                                        type="text"
                                                        className="purple-field d-flex align-items-center w-100 border-0 pe-5 text-white fs-14 height-45"
                                                        disabled
                                                        value={el.value}
                                                    />
                                                    <AiOutlineMinusCircle className="btn-size-20 me-3 text-white flex-shrink-0 float-end" style={{ marginTop: '-30px' }} onClick={e => this.removeDetails(e, el.label)} />
                                                </div> : null
                                        )
                                    })}

                                    <div className="form-group mt-3 mb-5 black-flat-btn d-flex justify-content-center align-items-center height-45 rounded-10" onClick={e => this.toggleAddMoreDetails(e)}>

                                        <AiFillPlusCircle className="btn-size-25 text-white" />
                                        {/* <label htmlFor="addMore">Add More Details</label> */}
                                    </div>
                                </div>
                                {/* reCAPTCHA is commented out for now */}
                                {/* <div className="form-group mt-3 addCaptcha text-center">
                                    <ReCAPTCHA
                                        style={{ display: "inline-block", }}
                                        theme="dark"
                                        ref={this._reCaptchaRef}
                                        sitekey={TEST_SITE_KEY}
                                        onChange={this.handleChange}
                                    />
                                </div> */}

                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2 position-fixed" style={{ bottom: '60px' }}>Create</button>
                                </div>
                            </Form>
                        </Formik>

                    </div>
                </section>

                <Modal show={this.state.addDetails} centered dialogClassName="AddMoreDetails-modal">
                    <Modal.Body>
                        <AddMoreDetails
                            data={this.state.extraDetails}
                            addMoreDetails={this.addMoreDetails}
                            renderAddedDetails={this.renderAddedDetails}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default CreateOrganization;
