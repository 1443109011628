import * as axios from "axios";
import { queryParams } from "../utils/common.utils";
import { getToken } from "../utils/localstorage.utils";

export default class AdminApis {
  
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    getNotifications = () => {
        return this.init().get("/public/api/v1/notifications/getNotifications");
    };

    getDen = (type) => {
        return this.init().get(`/admin/api/v1/den/getDen?type=${type}`);
    };

}