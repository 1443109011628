import React, { Component } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import { STYLE } from '../../utils/Style.utils';

export default class SideSkeleton extends Component {
    state = {};

    render() {
        return(
            <Card className='profile-card-bg p-2' style={STYLE.DEN.PROFILE_CARD}>
                <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                    <Placeholder xs={6} /> <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={4} /> <Placeholder xs={7} /> <Placeholder xs={12} />
                    <Placeholder xs={7} /> <Placeholder xs={4} />
                </Placeholder>
                </Card.Body>
                <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={3} /> <Placeholder xs={3} /> <Placeholder xs={3} /> <Placeholder xs={3} /> 
                </Placeholder>
                </Card.Body>
                <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={2} /> <Placeholder xs={2} /> <Placeholder xs={2} /> <Placeholder xs={2} /> 
                </Placeholder>
                </Card.Body>
            </Card>
        )
    }
}