import React, { Component } from 'react';
import { AiOutlineClose } from 'react-icons/ai'
import profileLogo from '../../../../images/den/den-profile-logo.png';
import LeftSideBar from '../common/LeftSideBar';
import RightSideBar from '../common/RightSideBar';
import { profile } from '../../../../utils/localstorage.utils';
import { DEN_EMPTY_SCREENS, POST_INTERACTIONS } from '../../../../utils/Images.utils';
import DailySpinWheel from '../common/DailySpinWheel';
import { blockUnBlockDenProfile, getDenProfilePicture, getDenProfiles, updateDenProfileInLocalStorage } from '../../../../utils/Den.utils';
import Image from '../../../common/Image';
import { NavLink } from 'react-router-dom';
import { capitalize, getDetailsObj } from '../../../../utils/common.utils';
import { ASSETS, DEN } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';

/**
* @author
* @class DenBlockedUsers
**/

class DenBlockedUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: DEN.TABS.BLOCKED_USERS,
            denProfile: null,
            blockedUsers: [],
            detailsObj: null
        };
    }

    componentDidMount(){
        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile)
    
        }
    }

    setCurrentUserDenProfile = (result) =>{
        if(result[0]){
            this.setState({
                denProfile: result[0]
            });
            this.getBlockedDenProfiles(result[0]);
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }


    getBlockedDenProfiles = (denProfile) => {
        if(denProfile?.blocked?.length > 0){
            getDenProfiles({ profiles : denProfile.blocked},this.setBlockedDenProfiles)
        }else{
            this.setState({
                blockedUsers: []
            })
        }
    }

    setBlockedDenProfiles = (result) =>{
        this.setState({
            blockedUsers: result
        });
    }

    updateProfileDetails = (denProfile) =>{
        this.setState({
            denProfile: denProfile
        });
        //updateDenProfileInLocalStorage(denProfile)
        this.getBlockedDenProfiles(denProfile);
    }

    getView = () =>{
        return(
            <div className='col-12 col-lg-6'>
                <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2'>
                        <div className='row'>
                            <div className='col-3'></div>
                            <div className='col-6'>
                                <p className='fs-16 text-center fw-700 my-0'>Blocked Users</p>
                            </div>
                            <div className='col-3 text-end'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.history.goBack()} />
                            </div>
                        </div>
                        <hr className='hr-line' />
                        {this.state.blockedUsers?.length>0 ?
                            <div className='row'>
                                {this.state.blockedUsers.map((profile, i) => {
                                    var profilePicture = getDenProfilePicture(profile);
                                    return(
                                        <div className='col-12' key={i}>
                                            <div className='d-flex align-items-center'>
                                                <NavLink to={`/den/profile-page/${profile._id}`}><Image src={profilePicture?.url} param={profile?.name ? profile?.name : "Test"} className='rounded-circle hover-zoomin' style={{height:'40px', width:'40px'}}/></NavLink>
                                                <NavLink to={`/den/profile-page/${profile._id}`} className='flex-grow-1 mx-2'>
                                                    <p className='fs-16 text-white fw-600 my-0'>{capitalize(profile.name)}</p>
                                                    <spam className='fs-12 text-white-50 fw-500'>{profile.profileTag}</spam>
                                                </NavLink>
                                                <button className='normal-post-btn selected-post-btn hover-zoomin' onClick={()=>blockUnBlockDenProfile(this.state.denProfile?._id, profile._id, false, this.updateProfileDetails)} >Unblock</button>
                                            </div>
                                            <hr className='hr-line my-1' />
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <div className='row'>
                                <div className="text-center">
                                    <img className="" src={DEN_EMPTY_SCREENS.NO_BLOCKED_USERS} alt={""} />
                                </div>
                            </div>  
                        }
                    </div>
                </div>
            </div>
        )
    }
    render() {
        return(
            <div className='body-section den_page'>
            <div className='container'>
                <div className='row'>
                    <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                    {this.getView()}
                    <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                </div>
            </div>
        </div>
         
          )
         }
}

export default DenBlockedUsers