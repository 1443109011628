import React, { Component}from 'react';
import { ellipsis, scoreCardGameURL } from '../../../utils/common.utils';
//images
import ppd from '../../../images/score/ppd.png';
import tshirt from '../../../images/score/tshirt.png';
import avatar from '../../../images/score/prize-avtar.png';
import orgIc from "../../../images/icon/org-ic.png";
import star1 from '../../../images/score/star1.png';
import star2 from '../../../images/score/star2.png';
import { Carousel } from 'react-bootstrap';
import { getLeaderBoardDetails, isTeamGame } from '../../../utils/Tournament.utils';
import { ASSETS } from '../../../utils/Strings.utils';
import Image from '../../common/Image';
import SponsorCards from './Scorecards/SponsorCards';

export default class PointsMatrix extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails,
            leaderBoardDetails: null
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {
        getLeaderBoardDetails(this.props.tournamentDetails._id, this.setLeaderBoardDetails)
    }

    setLeaderBoardDetails = (data) => {
        this.setState({
            leaderBoardDetails: data
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getRank1Prize = (rank, prizeDistribution, leaderBoard, isTeamGame) => { 
      
        const organizationDetails = this.state.tournamentDetails?.organizationDetails;
        return(
            <div className='prize-pool-distribution p-2'>
                
                <div className='row'>
                    <div className='col-4'>
                        <p className='fs-14 fw-600 my-0'>FINAL DAY {rank}</p>
                    </div>
                    <div className='col-4 text-center'>
                        <img src={this.gameUrl} className="mt-2" alt='' style={{ zIndex: '1', height: '25px' }} />
                    </div>
                    <div className='col-4'>
                    <div className='d-flex align-items-center'>
                        <h5 className='text-white fw-bold fs-10 mt-2 me-2 mb-0'>{organizationDetails?.name}</h5>
                        <img src={organizationDetails ? organizationDetails?.logo?.url :orgIc} className="mt-2" alt='' style={{ zIndex: '1', height: '30px' }} />
                    </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center my-2'>
                    <img src={isTeamGame ? leaderBoard?.teamDetails?.logo?.url : leaderBoard?.participantDetails?.profilePicture?.url} alt='' className='rank-1-avatar rounded-circle me-2' />
                    <h1 className={`rank-text rank-1`}>#{rank}</h1>
                </div>
                <h1 className={`rank-text text-center fs-24`}>{isTeamGame ? leaderBoard?.teamDetails?.name : leaderBoard?.participantDetails?.inGameName}</h1>
                <div className='d-flex align-items-center'>
                    <hr style={{ border: '1px solid #BFD3D7', opacity: 0.5, flexGrow: 1}} />
                    <p className='my-0 mx-2 text-white-50 fw-bold'>PRIZES</p>
                    <hr style={{ border: '1px solid #BFD3D7', opacity: 0.5, flexGrow: 1}} />
                </div>
                <div className='d-flex justify-content-around align-items-center pt-5'>
                    <div className='d-flex flex-column align-items-center'>
                        <img src={star1} alt='star1' height='40' width='40' />
                        <p className='amount mt-2'>EC {prizeDistribution?.prizeAmount}</p>
                    </div>
                    {prizeDistribution.merchandise &&
                        <>
                            <div className='d-flex flex-column'>
                                <p className='x1'>X</p>
                            </div>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={prizeDistribution.merchandiseImage.url} alt='tshirt' height='40' width='40' />
                                <p className='amount mt-2'> {prizeDistribution.merchandiseHeading}</p>
                            </div>
                        </>
                    }
                    
                </div>
                <SponsorCards tournamentDetails={this.state.tournamentDetails} />
            </div>
        )
    }

    getRank23Prize = (players, isTeamGame) => { 
        const organizationDetails = this.state.tournamentDetails?.organizationDetails;
      
        const participant1 = {
            name: ( isTeamGame ? ellipsis(players[0]['leaderBoards']?.teamDetails?.name, 12) : ellipsis(players[0]['leaderBoards']?.participantDetails?.inGameName, 12) ),
            url: (isTeamGame ? players[0]['leaderBoards']?.teamDetails?.logo?.url : players[0]['leaderBoards']?.participantDetails?.profilePicture?.url)
        };
        
        const participant2 = {
            name: ( isTeamGame ? ellipsis(players[1]['leaderBoards']?.teamDetails?.name, 12) : ellipsis(players[1]['leaderBoards']?.participantDetails?.inGameName, 12) ),
            url: (isTeamGame ? players[1]['leaderBoards']?.teamDetails?.logo?.url : players[1]['leaderBoards']?.participantDetails?.profilePicture?.url)
        };

        return(
            <div className='prize-pool-distribution p-2'>
                <div className='row'>
                    <div className='col-4'>
                        <p className='fs-14 fw-600 my-0'>FINAL DAY</p>
                    </div>
                    <div className='col-4 text-center'>
                        <img src={this.gameUrl} className="mt-2" alt='' style={{ zIndex: '1', height: '25px' }} />
                    </div>
                    <div className='col-4'>
                    <div className='d-flex align-items-center'>
                        <h5 className='text-white fw-bold fs-10 mt-2 me-2 mb-0'>{organizationDetails?.name}</h5>
                        <img src={organizationDetails ? organizationDetails?.logo?.url :orgIc} className="mt-2" alt='' style={{ zIndex: '1', height: '30px' }} />
                    </div>
                    </div>
                </div>
                {/* 2nd rank details */}
                <div className="d-flex justify-content-around">
                    <div>
                        <div className='d-flex justify-content-center align-items-center my-2'>
                            
                            <Image src={participant1.url} param={participant1.name} className='rank-1-avatar rounded-circle me-2' />
                            
                            <h1 className={`rank-text rank-2`}>#2</h1>
                        </div>
                        <h1 className={`rank-text text-center fs-24`}>{participant1.name}</h1>
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        {players[0]['prizeDistributions'].merchandise ?
                            <div>
                                <p className='amount my-0'><img src={star1} alt='star1' height='20' width='20' className='me-2' /> EC {players[0]['prizeDistributions']?.prizeAmount}</p>
                                <p className='x1 fs-24 my-0 text-center' style={{fontSize:'24px'}}>X</p>
                                <p className='amount my-0'><img src={players[0]['prizeDistributions']?.merchandiseImage.url} alt='tshirt' height='40' width='40' className='me-2' /> {players[0]['prizeDistributions']?.merchandiseHeading}</p>
                            </div>
                            :
                            <div className='d-flex  flex-column align-items-center'>
                                <img src={star1} alt='star1' height='40' width='40' />
                                <p className='amount mt-2'>EC {players[0]['prizeDistributions']?.prizeAmount}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <hr style={{ border: '1px solid #BFD3D7', opacity: 0.5, flexGrow: 1}} />
                    <p className='my-0 mx-2 text-white-50 fw-bold'>PRIZES</p>
                    <hr style={{ border: '1px solid #BFD3D7', opacity: 0.5, flexGrow: 1}} />
                </div>
                {/* 3rd rank details */}
                <div className="d-flex justify-content-around">
                    <div>
                        <div className='d-flex justify-content-center align-items-center my-2'>
                            <Image src={participant2.url} param={participant2.name} className='rank-1-avatar rounded-circle me-2' />
                            <h1 className={`rank-text rank-3`}>#3</h1>
                        </div>
                        <h1 className={`rank-text text-center fs-24`}>{participant2.name}</h1>
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        {players[1]['prizeDistributions'].merchandise ?
                            <div>
                                <p className='amount my-0'><img src={star1} alt='star1' height='20' width='20' className='me-2' /> EC {players[1]['prizeDistributions']?.prizeAmount}</p>
                                <p className='x1 fs-24 my-0 text-center' style={{fontSize:'24px'}}>X</p>
                                <p className='amount my-0'><img src={players[1]['prizeDistributions']?.merchandiseImage.url} alt='tshirt' height='40' width='40' className='me-2' /> {players[1]['prizeDistributions']?.merchandiseHeading}</p>
                            </div>
                            :
                            <div className='d-flex  flex-column align-items-center'>
                                <img src={star1} alt='star1' height='40' width='40' />
                                <p className='amount mt-2'>EC {players[1]['prizeDistributions']?.prizeAmount}</p>
                            </div>
                        }
                    </div>
                </div>
                <SponsorCards tournamentDetails={this.state.tournamentDetails} />
            </div>
        )
    }

    getAfter3rdRanksDetails = (prizeDistribution, leaderBoard, isTeamGame) =>{
        var rankDetails = [];
        for (let i = 3; i < prizeDistribution.length; i++) {
            rankDetails.push(
                <div className="" key={i}>
                    <div className='d-flex align-items-center'>
                        <span className='normal-text me-3'>#{4+i}</span>
                        <img className='rounded-circle' src={isTeamGame ? leaderBoard[i]?.teamDetails?.logo?.url : leaderBoard[i]?.participantDetails?.profilePicture?.url}  alt='' height='20' width='20' />
                        <span className='normal-text ms-2 flex-grow-1'>
                        {isTeamGame ? ellipsis(leaderBoard[i]?.teamDetails?.name, 12) : ellipsis(leaderBoard[i]?.participantDetails?.name, 12)}
                        </span>
                        <span className='amount my-0'><img src={star1} alt='star1' height='20' width='20' className='me-2' /> EC  {ellipsis(10000000000, 7)}</span>
                    </div>
                    <hr className='my-1' style={{ border: '1px solid #BFD3D7', opacity: 0.5 }} />
                </div>
            )           
        }
        return rankDetails;
    }

    getPrizePool = (prizeDistribution, leaderBoard, isTeamGame) => {
        console.log('----------------------------------------------------------')
        console.log(`this.prizeDistribution :: `,prizeDistribution)
        console.log(`this.leaderBoard :: `,leaderBoard)
        console.log('----------------------------------------------------------')
     


        const organizationDetails = this.state.tournamentDetails?.organizationDetails;
        
        return(
            <div className='prize-pool-distribution p-2'>
                <div className='text-center d-flex justify-content-between align-items-end mb-3'>
                    <p className='fs-14 fw-600 my-0'>FINAL DAY</p>
                    <div className='mb-auto'>
                        <img src={this.gameUrl} className="mt-2" alt='' style={{ zIndex: '1', height: '25px' }} />
                    </div>
                    <div className='d-flex align-items-center'>
                        <h5 className='text-white fw-bold fs-10 mt-2 me-2 mb-0'>{organizationDetails?.name}</h5>
                        <img src={organizationDetails ? organizationDetails?.logo?.url :orgIc} className="mt-2" alt='' style={{ zIndex: '1', height: '30px' }} />
                    </div>
                </div>
                { this.getAfter3rdRanksDetails(prizeDistribution, leaderBoard, isTeamGame) }
                <SponsorCards tournamentDetails={this.state.tournamentDetails} />
            </div>
        )
    }

    getRankWisePrizeCards = (prizeDistributions,leaderBoard) =>{
        
        var leaderBoards= [];
        var isTeamGame = (this.state.tournamentDetails?.participantType === "TEAM");
        if(leaderBoard && prizeDistributions && prizeDistributions.length > 0 ){

            Object.keys(leaderBoard).forEach(function(key) {
                leaderBoards.push(leaderBoard[key])
            });
            
            //console.log("---- leaderBoardArray :: ",leaderBoards)
            
            switch (prizeDistributions.length) {
                case 1:
                    return  (
                        <Carousel>
                            <Carousel.Item>{this.getRank1Prize(1, prizeDistributions[0], leaderBoards[0], isTeamGame)}</Carousel.Item>
                        </Carousel>
                    );
                case 2:
                    return  (
                        <Carousel>
                            <Carousel.Item>{this.getRank1Prize(1, prizeDistributions[0], leaderBoards[0], isTeamGame)}</Carousel.Item>
                            <Carousel.Item>{this.getRank1Prize(2, prizeDistributions[1], leaderBoards[1], isTeamGame)}</Carousel.Item>
                        </Carousel>
                    );
                case 3:
                    return  (
                        <Carousel>
                            {/* <Carousel.Item>{this.getRank1Prize(1, prizeDistributions[0], leaderBoards[0], isTeamGame)}</Carousel.Item> */}
                            <Carousel.Item>{this.getRank23Prize({
                                0: {prizeDistributions : prizeDistributions[1], leaderBoards : leaderBoards[1]},
                                1: {prizeDistributions : prizeDistributions[2], leaderBoards : leaderBoards[2]},
                            }, isTeamGame)}</Carousel.Item>
                        </Carousel>
                    );
                default:
                    
                    return (
                        <Carousel>
                            <Carousel.Item>{this.getRank1Prize(1, prizeDistributions[0], leaderBoards[0], isTeamGame)}</Carousel.Item>
                            <Carousel.Item>{this.getRank23Prize({
                                0: {prizeDistributions : prizeDistributions[1], leaderBoards : leaderBoards[1]},
                                1: {prizeDistributions : prizeDistributions[2], leaderBoards : leaderBoards[2]},
                            }, isTeamGame)}</Carousel.Item>
                            <Carousel.Item>{this.getPrizePool(prizeDistributions, leaderBoards, isTeamGame)}</Carousel.Item>
                        </Carousel>
                    );
            }
        }else{
            return (
                <section className='prize-distribution-section text-white p-0'>
                    <div className="text-center">
                        <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt=""/>
                    </div>
                </section>
            );
        }

       
    }

    render() {

        // console.log(`this.state.tournamentDetails :: `,this.state.tournamentDetails.entryFeeAndPrizeDetails.prizeDistribution)
        // console.log(`this.state.leaderBoardDetails :: `,this.state.leaderBoardDetails)
        const isTeamGame = this.state.tournamentDetails?.entryFeeAndPrizeDetails;
        const PrizePoolDetails = this.state.tournamentDetails?.entryFeeAndPrizeDetails;

        if(PrizePoolDetails?.prizeDistribution?.length > 0){
            return (
                <section className='prize-distribution-section text-white p-0'>
                    <div className='d-flex align-items-center p-3 mb-3'>
                        <img src={ppd} alt='' />
                        <p className='fs-16 fw-bold my-0 ms-2'>Prize Pool Distribution</p>
                    </div>
               
                    {this.getRankWisePrizeCards(PrizePoolDetails.prizeDistribution, this.state.leaderBoardDetails?.leaderBoard[this.state.leaderBoardDetails.leaderBoard.length -1])}
                </section>
            )
        }else{
            return(
                <section className='prize-distribution-section text-white p-0'>
                    <div className="text-center">
                        <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt=""/>
                    </div>
                </section>
            )
        }
        
    }
}