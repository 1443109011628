import React, { Component } from 'react'
import { FiUsers } from "react-icons/fi";
import { RiUserShared2Line } from "react-icons/ri";
import Image from '../../../common/Image';
import { profile } from '../../../../utils/localstorage.utils';
import { PROFILE_PAGE_IMAGES, LOGOS } from '../../../../utils/Images.utils';
import { toast } from 'material-react-toastify';
import { Modal } from 'react-bootstrap';
import ImagePreview from '../../../common/ImagePreview';


export default class LeftSideBarUnRegistered extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            profile: props.profile ? props.profile : profile(),
            previewImageDetails: {
                display: false,
                src: LOGOS.USER
            },
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            profile: newProps.profile ? newProps.profile : profile()
        });
    }

    getWarning = () => {
        toast.warning('Please complete your den profile to view these tabs.');
        return false;
    }

    previewImage = (src, param) => {
        this.setState({
          previewImageDetails: {
            display: param,
            src: (param ? src : LOGOS.USER)
          }
        });
    }

    render() {
        return (
            <div className='col-lg-3 d-none d-lg-block'>
                <div className='den-left-top-container' style={{background: `url(${PROFILE_PAGE_IMAGES.NONDENBG})`, height: '500px', backgroundSize: 'cover', backgroundPosition:'center'}}>
                    <div className='d-flex flex-column align-items-center'>
                        <div className=''  onClick={() => this.previewImage((this.state.profile?.profilePicture?.url ? this.state.profile?.profilePicture?.url : LOGOS.USER), true)}>
                            <Image param={this.state.username} src={this.state.profile?.profilePicture?.url} alt='' className='profile-logo' />
                        </div>
                        <p className='fs-20 fw-bold my-1'>{this.state.username}</p>
                        <p className='fs-12 text-white-50 fw-bold my-1'>Non-Den User</p>
                    </div>
                    <div className='container flex-grow-1 d-flex flex-column'>
                        <div className='row my-2'>
                            <div className='col-6'>
                                <div className='followers-box p-0 opacity-25'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <FiUsers className='me-2' />
                                        <div>
                                            <p className='fs-12 fw-600 my-0'>0</p>
                                            <p className='fs-12 fw-600 my-0'>Followers</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='followers-box p-0 opacity-25'>
                                <div className='d-flex justify-content-center align-items-center'>
                                        <RiUserShared2Line className='me-2' />
                                        <div>
                                            <p className='fs-12 fw-600 my-0'>0</p>
                                            <p className='fs-12 fw-600 my-0'>Following</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center'>
                            <p className='fs-14 fw-600 text-center w-80'>Complete your den profile today !!</p>
                        </div>
                    </div>
                </div>
                <div className='den-left-bottom-container p-2 my-3'>
                    <p className={`fs-16 fw-bold cursor-pointer den-selected-tab p-2 ps-3`} >HOME</p>
                    <p className={`fs-16 fw-bold cursor-pointer p-2 ps-3`} onClick={this.getWarning}>BLOCKED USERS</p>
                    <p className={`fs-16 fw-bold cursor-pointer p-2 ps-3`} onClick={this.getWarning}>FOLLOW REQUESTS</p>
                    <p className={`fs-16 fw-bold cursor-pointer p-2 ps-3`} onClick={this.getWarning}>INNER CIRCLE</p>
                    <p className={`fs-16 fw-bold cursor-pointer p-2 ps-3`} onClick={this.getWarning}>ACHIEVEMENTS</p>
                    <p className={`fs-16 fw-bold cursor-pointer p-2 ps-3`} onClick={this.getWarning}>POSTS</p>
                </div>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </div>

        )
    }
}
