import { Component } from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
    AiOutlineCloudUpload,
    AiFillCloseCircle
} from "react-icons/ai";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { errorHandler, updateLoader } from '../../utils/common.utils';
import CommonApis from '../../helper/common.api';
import { toast } from "material-react-toastify";
import { SponsorTypes } from '../../utils/common.utils';
import { Toast } from 'bootstrap';

export default class Sponsor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            invalidImage: null,
            reqErrorMsg: null,
            sponsorForUpdate: this.props.sponsorForUpdate,
            sponsorIndex: this.props.sponsorIndex,
            sponsorImage: this.props.sponsorForUpdate.logo
        }
        this.commonApi = new CommonApis();
    }
    triggerInputFile = () => this.fileInput.click();
    //upload image
    onImageChange = (event, type) => {
        event.preventDefault();

        this.setState({
            invalidImage: null,
        });
        
        //type : bannerImage, logoImage
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            console.log(img);
            
            if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
                //this.setState({ invalidImage: "Please select valid image."});
                toast.error('Please select valid image.');
                return false;
            }else{
                this.setState({
                    [type]: URL.createObjectURL(img), //loader image
                });
            }

            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            updateLoader(true);
            this.commonApi
                .uploadSingleImg(formData)
                .then((response: AxiosResponse) => {
                    this.setState({
                        sponsorImage: response.data.result, //
                        reqErrorMsg: null,
                    });
                  updateLoader(false);
                })
                .catch((err: AxiosError) => {
                    errorHandler(err);
                    this.setState({
                        [type]: null,
                    });
                    updateLoader(false)
                }); //set null to image and display error message in toast
        }
    };
    addSponsor = (values) => {
        if (!this.state.sponsorImage) {
            toast.error('Please upload image')
            return false;
        }
        this.props.addSponsor(values, this.state.sponsorImage)
    }
    updateSponsor = (values) => {
        if (!this.state.sponsorImage) {
            toast.error('Please upload image')
            return false;
        }
        this.props.updateSponsor(values, this.state.sponsorImage)
    }
    render() {
        const { invalidImage } = this.state
        return (
            <div className='p-3'>
                <IoIosCloseCircleOutline
                    className="close-icon position-absolute" style={{ right: '15px', top: '15px' }}
                    onClick={() => {
                        this.props.onClose();
                    }}
                />
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: this.state.sponsorForUpdate?.name ? this.state.sponsorForUpdate.name : "", 
                        type: this.state.sponsorForUpdate?.type ? this.state.sponsorForUpdate.type : "Powered By",
                        id: this.state.sponsorForUpdate?._id ? this.state.sponsorForUpdate._id : null
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required("Sponsor name is required").max(25, 'Max 25 characters'),
                        type: Yup.string().required("Sponsor type is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            if (this.state.sponsorForUpdate?.name) {
                                this.updateSponsor(values);
                            } else {
                                this.addSponsor(values);
                            }
                        }, 400);
                    }}
                >
                    <Form className="">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <h1 className="fs-18 fw-600 text-center text-white mb-0" >
                                        Sponsor{" "}
                                        {this.state.sponsorForUpdate?.index ||
                                            this.state.sponsorForUpdate?.index === 0
                                            ? this.state.sponsorForUpdate?.index + 1
                                            : this.props.sponsorArray + 1}
                                    </h1>
                                    {/* <FiEdit className="text-white mt-2 ms-3" />
                          <HiOutlineTrash className="text-white mt-2 ms-2" /> */}
                                </div>
                                <div className="col-12 mt-2">
                                    <h1 className="fs-14 fw-600 text-white mt-2"> Custom Sponsor Name </h1>
                                    <Field
                                        name="name"
                                        type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 pe-5 text-white height-45"
                                        placeholder="Enter Sponsor Name"
                                        autoComplete="off" />
                                    <ErrorMessage component="span" className="error-msg" name="name" />
                                </div>
                                <div className="col-12 mt-2 ">
                                    <h1 className="fs-14 fw-600 text-white mt-2">
                                        Sponsor Tittle
                                    </h1>
                                    <Field name="type" as="select" className="sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45">
                                        {SponsorTypes.map((type, i) =>{ 
                                            return <option value={type} key={i}>{type}</option>
                                        })}
                                    </Field>
                                    <ErrorMessage component="span" className="error-msg" name="type" />
                                </div>

                                <div className="col-7 mx-auto mt-3">
                                    <div className="uploadWithPreview">
                                        <div className="upload-section">
                                            <input type="file" accept=".png, .jpg, .jpeg" id="upload_cover_file" hidden ref={(fileInput) => (this.fileInput = fileInput)} onChange={(e) => this.onImageChange(e, "sponsorImage")} name="" className="image-input" />
                                            {this.state.sponsorImage ? (
                                                <div className="text-right flex-column text-end">
                                                    <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }}
                                                        onClick={(e) =>
                                                            this.setState({ sponsorImage: null })
                                                        }
                                                    />
                                                    <div className="after-uploaded-image" onClick={this.triggerInputFile}  >
                                                        <img src={this.state.sponsorImage.url} alt="" className="after-uploaded-image" />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="before-uploaded-panel">
                                                    <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                                        <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile}  >
                                                            <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <span className="error-msg">
                                                {" "}
                                                {this.state.reqErrorMsg}
                                            </span>
                                            {invalidImage && (
                                                <p className="error-msg mwc-image-validation mt-1">
                                                    {invalidImage}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="white-flat-btn fw-bold d-block m-auto mt-4 py-2 px-4">
                            {this.state.sponsorForUpdate?.name ? "Update" : "Add"}
                        </button>
                    </Form>
                </Formik>
            </div>
        )
    }
}
