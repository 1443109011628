import { Component } from 'react';
import {Carousel} from 'react-bootstrap';
//images
import participantAvtar from "../../../../images/participantAvtar.jpg";
import winnerCrown from "../../../../images/icon/winner-crown.png";
import pubgIc from "../../../../images/icon/pubg-ic.png";
import orgIc from "../../../../images/icon/org-ic.png";
import teamA from "../../../../images/icon/team-a.png";
import teamB from "../../../../images/icon/team-b.png";
import { ellipsis, scoreCardGameURL } from '../../../../utils/common.utils';
import SponsorCards from './SponsorCards';
import CardHeader from './CardHeader';
import DuelForfeitCase from './DuelForfeitCase';

export default class CSGOCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            matchDetails: props.matchDetails,
            tournamentDetails: props.tournamentDetails
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            matchDetails: nextProps.matchDetails,
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getTeamDuelFinalResult=(key) =>{ 
        const tournamentDetails = this.state.tournamentDetails;
        const { matchDetails} = this.state;
        let matchMVP = null;
        let teamMVP = null;
        let player1 = null;
        let player2 = null;
        var p1RoundsWon = 0;
        var p2RoundsWon = 0;
        var p1FirstBlood = 0;
        var p2FirstBlood = 0;
        if(key){
            player1 = this.state.matchDetails?.finalResult[0];
            player2 = this.state.matchDetails?.finalResult[1];
            teamMVP = [...player1.individualScore, ...player2.individualScore].filter(val =>  val.participantDetails?._id === this.state.matchDetails?.teamMVP?._id)[0];
            matchMVP = [...player1.individualScore, ...player2.individualScore].filter(val =>  val.participantDetails?._id === this.state.matchDetails?.matchMVP?._id)[0];
            this.state.matchDetails?.results.map((game, i) => {
                p1RoundsWon  = p1RoundsWon + game[0].noOfRoundsWon;
                p2RoundsWon  = p2RoundsWon + game[1].noOfRoundsWon;
                p1FirstBlood  = p1FirstBlood + game[0].firstBlood;
                p2FirstBlood  = p2FirstBlood + game[1].firstBlood;
            })
            player1.individualScore?.map((person, x)=> {
                person.score = 0;
                person.firstBlood = 0;
            })
            player2.individualScore?.map((person, y)=> {
                person.score = 0;
                person.firstBlood = 0;
            })
            //console.log('inside player1 ::', player1.individualScore);
            //console.log('inside player2 ::', player2.individualScore);
            
            this.state.matchDetails?.results?.map((res,k) => {
                console.log('------', res);
                res[0].individualScore?.map((el, j)=> {
                    //console.log(`player1 ${j+1} kills-${el.kills} & firstBlood-${el.firstBlood}`);
                    player1.individualScore[j].score += el.score;
                    player1.individualScore[j].firstBlood += el.firstBlood;
                    
                })
                res[1].individualScore?.map((el, v)=> {
                    //console.log(`player2 ${v+1} kills-${el.kills} & firstBlood-${el.firstBlood}`);
                    player2.individualScore[v].score += el.score;
                    player2.individualScore[v].firstBlood += el.firstBlood;
                })
            })

        }else{
            player1 = this.state.matchDetails?.results[0][0];
            player2 = this.state.matchDetails?.results[0][1];
            teamMVP = [...player1.individualScore, ...player2.individualScore].filter(val =>  val.participantDetails?._id === this.state.matchDetails?.results[0][0].teamMVP?._id)[0];
            matchMVP = [...player1.individualScore, ...player2.individualScore].filter(val =>  val.participantDetails?._id === this.state.matchDetails?.results[0][0].matchMVP?._id)[0];
            p1RoundsWon = this.state.matchDetails?.results[0][0]?.noOfRoundsWon;
            p2RoundsWon = this.state.matchDetails?.results[0][1]?.noOfRoundsWon;
            p1FirstBlood = this.state.matchDetails?.results[0][0]?.firstBlood;
            p2FirstBlood = this.state.matchDetails?.results[0][1]?.firstBlood;
        }
        console.log('--------------------------------------------');
        console.log('teamMVP ::', teamMVP);
        console.log('matchMVP ::', matchMVP);
        console.log('player1 ::', player1);
        console.log('player2 ::', player2);
        console.log(`p1RoundsWon-${p1RoundsWon}|| p2RoundsWon-${p2RoundsWon} || p1FirstBlood-${p1FirstBlood} p2FirstBlood-${p2FirstBlood}`)
        console.log('--------------------------------------------');
        return(
            <div className='col-12 col-lg-6 offset-lg-3'>
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />
                    <p className='fs-10 fw-bold text-end'>Final Result</p>
                    <div className='d-flex align-items-center'>
                        <div className='col-8 p-0'>
                        <div className='text-center d-flex justify-content-evenly align-items-end'>
                        <div className=''>
                            {player1.isWinner && <span className='winner-shadow'></span>}
                            <span className='d-flex flex-column align-items-center'>
                            {player1.isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                <img src={matchDetails.participants[0].logo.url} className="rounded-circle" alt='' style={{ height: '50px', width: '50px', zIndex: '1' }} />
                            </span>
                            <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[0].name}</h5>
                        </div>
                        <div className='align-self-center'>
                            <p className='text-white fw-700 fs-20 mb-1'>{`${p1RoundsWon}-${p2RoundsWon}`}</p>
                            <p className='text-white-50 fw-700 fs-14 mb-1'>Rounds</p>
                        </div>
                        <div className=''>
                            {player2.isWinner && <span className='winner-shadow'></span>}
                            <span className='d-flex flex-column align-items-center'>
                                {player2.isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                <img src={matchDetails.participants[1].logo.url} className="rounded-circle" alt='' style={{ height: '50px', width: '50px', zIndex: '1' }} />
                            </span>
                            <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[1].name}</h5>
                        </div>
                    </div>
                        </div>
                        <div className='col-4 p-0'>
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={teamMVP ? teamMVP.participantDetails?.profilePicture?.url : participantAvtar} className='btn-size-25 rounded-circle mb-2' alt='' />
                                </div>
                                <div className='col-9'>
                                    <h1 className='fs-12 fw-600 text-white mb-0'>{teamMVP?.participantDetails?.inGameName}</h1>
                                    <p className='fs-10 fw-600 text-warning mb-0' >Team MVP</p>
                                    <p className='fs-10 fw-600 text-white mb-0'>{`${teamMVP.kills}-${teamMVP.deaths}-${teamMVP.assists}`}</p>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-3'>
                                    <img src={matchMVP ? matchMVP.participantDetails?.profilePicture?.url : participantAvtar} className='btn-size-25 rounded-circle mb-2' alt='' />
                                </div>
                                <div className='col-9'>
                                    <h1 className='fs-12 fw-600 text-white mb-0'>{matchMVP?.participantDetails?.inGameName}</h1>
                                    <p className='fs-10 fw-600 text-white-50 mb-0' >Match MVP</p>
                                    <p className='fs-10 fw-600 text-white mb-0'>{`${matchMVP.kills}-${matchMVP.deaths}-${matchMVP.assists}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <table className="table table-borderless mt-2">
                        <thead>
                            <tr className='scorecard_header'>
                                <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                <th scope="col" className='name_header fs-8 fw-600 text-white'>KDA</th>
                                <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                            </tr>
                        </thead>
                        <tbody>
                            {player1.individualScore?.map((player,i) => {
                                var isTeamMVP = teamMVP?.participantDetails._id === player.participantDetails._id;
                                var isMatchMVP = matchMVP?.participantDetails._id === player.participantDetails._id;
                                //console.log(`${player.participantDetails._id} ${isTeamMVP} ${isMatchMVP}`);
                                return (
                                    <tr className='scorecard_body' key={i}>
                                        <td className='fs-8 fw-600 text-white team_body'>{player1.teamDetails?.name}</td>
                                        <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}<span className='fs-8 fw-bold text-warning ms-2' style={{ display: isTeamMVP ? 'content':'none'}}>MVP</span><span className='fs-8 fw-bold ms-2 text-white' style={{ display: isMatchMVP ? 'content':'none'}}>MVP</span></td>
                                        <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                        <td className='fs-8 fw-600 text-white team_score'>{player.score}</td>
                                        <td className='fs-8 fw-600 text-white team_score'>{player.firstBlood}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <table className="table table-borderless mt-2">
                        <thead>
                            <tr className='scorecard_header'>
                                <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                <th scope="col" className='name_header fs-8 fw-600 text-white'>KDA</th>
                                <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                            </tr>
                        </thead>
                        <tbody>
                            {player2.individualScore?.map((player,i) => {
                                var isTeamMVP = teamMVP._id === player.participantDetails._id;
                                var isMatchMVP = matchMVP._id === player.participantDetails._id;
                                //console.log(`${player.participantDetails._id} ${isTeamMVP} ${isMatchMVP}`);
                                return (
                                    <tr className='scorecard_body' key={i}>
                                        <td className='fs-8 fw-600 text-white team_body'>{player2.teamDetails?.name}</td>
                                        <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}<span className='fs-8 fw-bold text-warning ms-2' style={{ display: isTeamMVP ? 'content':'none'}}>MVP</span><span className='fs-8 fw-bold ms-2 text-white' style={{ display: isMatchMVP ? 'content':'none'}}>MVP</span></td>
                                        <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                        <td className='fs-8 fw-600 text-white team_score'>{player.score}</td>
                                        <td className='fs-8 fw-600 text-white team_score'>{player.firstBlood}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            </div>
        )
    }

    getTeamDuel = () => { 
        const tournamentDetails = this.state.tournamentDetails
        const { matchDetails} = this.state;
        if(this.state.matchDetails?.results?.length > 1){
            return (
                <Carousel className='p-0'>
                    <Carousel.Item>{this.getTeamDuelFinalResult(true)}</Carousel.Item>
                    {this.state.matchDetails?.results.map((el, i) => {
                        let p1forfeit = el[0].forfeit ? true : false;
                        let p2forfeit = el[1].forfeit ? true : false;
                        //console.log(`Game-${i+1} p1forfeit-${p1forfeit}, p2forfeit-${p2forfeit}`);
                        if(p1forfeit || p2forfeit){
                            return <Carousel.Item key={i}><DuelForfeitCase game={el} tournamentDetails={tournamentDetails} /></Carousel.Item>
                        }else{
                            const teamMVP = [...el[0].individualScore, ...el[1].individualScore].filter(val =>  val.participantDetails?._id === el[0].teamMVP?._id);
                            const matchMVP = [...el[0].individualScore, ...el[1].individualScore].filter(val =>  val.participantDetails?._id === el[0].matchMVP?._id);
                            return(
                                <Carousel.Item  key={i}>
                                    <div className='col-12 col-lg-6 offset-lg-3'>
                                        <div className='scoreboard-bg-image mt-2 p-3'>
                                            <CardHeader tournamentDetails={tournamentDetails} />
                                            <p className='fs-10 fw-bold text-end'>Game {i+1} of {this.state.matchDetails?.results?.length}</p>
                                            <div className='d-flex align-items-center'>
                                                <div className='col-8 p-0'>
                                                <div className='text-center d-flex justify-content-evenly align-items-end'>
                                                <div className=''>
                                                    {el[0].isWinner && <span className='winner-shadow'></span>}
                                                    <span className='d-flex flex-column align-items-center'>
                                                    {el[0].isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                        <img src={matchDetails.participants[0].logo.url} className="rounded-circle" alt='' style={{ height: '50px', width: '50px', zIndex: '1' }} />
                                                    </span>
                                                    <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[0].name}</h5>
                                                </div>
                                                <div className='align-self-center'>
                                                    <p className='text-white fw-700 fs-20 mb-1'>{`${el[0].noOfRoundsWon}-${el[1].noOfRoundsWon}`}</p>
                                                    <p className='text-white-50 fw-700 fs-14 mb-1'>Rounds</p>
                                                </div>
                                                <div className=''>
                                                    {el[1].isWinner && <span className='winner-shadow'></span>}
                                                    <span className='d-flex flex-column align-items-center'>
                                                        {el[1].isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                        <img src={matchDetails.participants[1].logo.url} className="rounded-circle" alt='' style={{ height: '50px', width: '50px', zIndex: '1' }} />
                                                    </span>
                                                    <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[1].name}</h5>
                                                </div>
                                            </div>
                                                </div>
                                                <div className='col-4 p-0'>
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            <img src={teamMVP[0] ? teamMVP[0].participantDetails?.profilePicture?.url : participantAvtar} className='btn-size-25 rounded-circle mb-2' alt='' />
                                                        </div>
                                                        <div className='col-9'>
                                                            <h1 className='fs-12 fw-600 text-white mb-0'>{el[0].teamMVP.inGameName}</h1>
                                                            <p className='fs-10 fw-600 text-warning mb-0' >Team MVP</p>
                                                            <p className='fs-10 fw-600 text-white mb-0'>{`${teamMVP[0].kills}-${teamMVP[0].deaths}-${teamMVP[0].assists}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-2'>
                                                        <div className='col-3'>
                                                            <img src={matchMVP[0] ? matchMVP[0].participantDetails?.profilePicture?.url : participantAvtar} className='btn-size-25 rounded-circle mb-2' alt='' />
                                                        </div>
                                                        <div className='col-9'>
                                                            <h1 className='fs-12 fw-600 text-white mb-0'>{el[0].matchMVP.inGameName}</h1>
                                                            <p className='fs-10 fw-600 text-white-50 mb-0' >Match MVP</p>
                                                            <p className='fs-10 fw-600 text-white mb-0'>{`${matchMVP[0].kills}-${matchMVP[0].deaths}-${matchMVP[0].assists}`}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <table className="table table-borderless mt-2">
                                                <thead>
                                                    <tr className='scorecard_header'>
                                                        <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                                        <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                                        <th scope="col" className='name_header fs-8 fw-600 text-white'>KDA</th>
                                                        <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                                        <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {el[0].individualScore?.map((player,i) => {
                                                        var isTeamMVP = el[0].teamMVP._id === player.participantDetails._id;
                                                        var isMatchMVP = el[0].matchMVP._id === player.participantDetails._id;
                                                        //console.log(`${player.participantDetails._id} ${isTeamMVP} ${isMatchMVP}`);
                                                        return (
                                                            <tr className='scorecard_body' key={i}>
                                                                <td className='fs-8 fw-600 text-white team_body'>{el[0].teamDetails?.name}</td>
                                                                <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}<span className='fs-8 fw-bold text-warning ms-2' style={{ display: isTeamMVP ? 'content':'none'}}>MVP</span><span className='fs-8 fw-bold ms-2 text-white' style={{ display: isMatchMVP ? 'content':'none'}}>MVP</span></td>
                                                                <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                                                <td className='fs-8 fw-600 text-white team_score'>{player.score}</td>
                                                                <td className='fs-8 fw-600 text-white team_score'>{player.firstBlood}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <table className="table table-borderless mt-2">
                                                <thead>
                                                    <tr className='scorecard_header'>
                                                        <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                                        <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                                        <th scope="col" className='name_header fs-8 fw-600 text-white'>KDA</th>
                                                        <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                                        <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {el[1].individualScore?.map((player,i) => {
                                                        var isTeamMVP = el[0].teamMVP._id === player.participantDetails._id;
                                                        var isMatchMVP = el[0].matchMVP._id === player.participantDetails._id;
                                                        //console.log(`${player.participantDetails._id} ${isTeamMVP} ${isMatchMVP}`);
                                                        return (
                                                            <tr className='scorecard_body' key={i}>
                                                                <td className='fs-8 fw-600 text-white team_body'>{el[1].teamDetails?.name}</td>
                                                                <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}<span className='fs-8 fw-bold text-warning ms-2' style={{ display: isTeamMVP ? 'content':'none'}}>MVP</span><span className='fs-8 fw-bold ms-2 text-white' style={{ display: isMatchMVP ? 'content':'none'}}>MVP</span></td>
                                                                <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                                                <td className='fs-8 fw-600 text-white team_score'>{player.score}</td>
                                                                <td className='fs-8 fw-600 text-white team_score'>{player.firstBlood}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        }
                    })}
                </Carousel>
            )
        }else{
            return this.getTeamDuelFinalResult(false)
        }
        
    }

    getSoloDuelFinalResult = (key) => { 
        const tournamentDetails = this.state.tournamentDetails;
        const { matchDetails} = this.state;
        let matchMVP = null;
        let player1 = null;
        let player2 = null;
        var p1RoundsWon = 0;
        var p2RoundsWon = 0;
        var p1FirstBlood = 0;
        var p2FirstBlood = 0;
        if(key){
            player1 = this.state.matchDetails?.finalResult[0];
            player2 = this.state.matchDetails?.finalResult[1];
            player1.score = 0;
            player2.score = 0;
            matchMVP = this.state.matchDetails?.matchMVP;
            this.state.matchDetails?.results.map((game, i) => {
                p1RoundsWon  = p1RoundsWon + game[0].noOfRoundsWon;
                p2RoundsWon  = p2RoundsWon + game[1].noOfRoundsWon;
                p1FirstBlood  = p1FirstBlood + game[0].firstBlood;
                p2FirstBlood  = p2FirstBlood + game[1].firstBlood;
                player1.score += game[0].score;
                player2.score += game[1].score;
            })
        }else{
            player1 = this.state.matchDetails?.results[0][0];
            player2 = this.state.matchDetails?.results[0][1];
            matchMVP = this.state.matchDetails?.results[0][0].matchMVP;
            p1RoundsWon = this.state.matchDetails?.results[0][0]?.noOfRoundsWon;
            p2RoundsWon = this.state.matchDetails?.results[0][1]?.noOfRoundsWon;
            p1FirstBlood = this.state.matchDetails?.results[0][0]?.firstBlood;
            p2FirstBlood = this.state.matchDetails?.results[0][1]?.firstBlood;
        }
        console.log('--------------------------------------------');
        console.log('matchMVP ::', matchMVP);
        console.log('player1 ::', player1);
        console.log('player2 ::', player2);
        console.log(`p1RoundsWon-${p1RoundsWon}|| p2RoundsWon-${p2RoundsWon} || p1FirstBlood-${p1FirstBlood} p2FirstBlood-${p2FirstBlood}`)
        console.log('--------------------------------------------');
        return(
            <div className='col-12 col-lg-6 offset-lg-3'>
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />
                    <div className='d-flex justify-content-evenly align-items-end'>
                        <div className=''>
                            <div className=''>
                                {/* {matchDetails.finalResult[0].isWinner && <span className='winner-shadow'></span>} */}
                                <span className='d-flex flex-column align-items-center'>
                                    {player1.isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                    <img src={matchDetails.participants[0].profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                                </span>
                                <h5 className='text-white fw-bold fs-10 mt-2 text-center'>{matchDetails.participants[0].inGameName}</h5>
                                {player1.participantDetails._id === matchMVP._id && <p className='text-warning fs-10 fw-bold text-center'>MVP</p>}
                                <table className="table table-borderless mt-2">
                                    <thead>
                                        <tr className='scorecard_header'>
                                            <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                            <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                            <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr className='scorecard_body'>
                                        <td className='fs-8 fw-600 text-white team_body'>{`${player1.kills} - ${player1.deaths}`}</td>
                                        <td className='fs-8 fw-600 text-white'>{`${player1.score}`}</td>
                                        <td className='fs-8 fw-600 text-white team_score'>{`${p1FirstBlood}`}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className=''>
                            <p className='text-white fw-700 fs-14 mb-1'>{`${p1RoundsWon}-${p2RoundsWon}`}</p>
                        </div>
                        <div className=''>
                            {player2.isWinner && <span className='winner-shadow'></span>}
                            <span className='d-flex flex-column align-items-center'>
                                {/* {player2.isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />} */}
                                <img src={matchDetails.participants[1].profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                            </span>
                            <h5 className='text-white fw-bold text-center fs-10 mt-2'>{matchDetails.participants[1].inGameName}</h5>
                            {player2.participantDetails._id === matchMVP._id && <p className='text-warning fs-10 fw-bold text-center'>MVP</p>}
                            <table className="table table-borderless mt-2">
                                <thead>
                                    <tr className='scorecard_header'>
                                        <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                        <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                        <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr className='scorecard_body'>
                                    <td className='fs-8 fw-600 text-white team_body'>{`${player2.kills} - ${player2.deaths}`}</td>
                                    <td className='fs-8 fw-600 text-white'>{`${player2.score}`}</td>
                                    <td className='fs-8 fw-600 text-white team_score'>{`${p2FirstBlood}`}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            </div>
        )
    }

    getSoloDuel = () => {
        const { matchDetails, tournamentDetails} = this.state;
        if(this.state.matchDetails?.results.length > 1){
            return (
                <div className='row'>
                <Carousel>
                    <Carousel.Item>{this.getSoloDuelFinalResult(true)}</Carousel.Item>
                    {matchDetails.results.map((el, i) => {
                        //console.log('el --------',el);
                        let p1forfeit = el[0].forfeit ? true : false;
                        let p2forfeit = el[1].forfeit ? true : false;
                        //console.log(`Game-${i+1} p1forfeit-${p1forfeit}, p2forfeit-${p2forfeit}`);
                        if(p1forfeit || p2forfeit){
                            return <Carousel.Item key={i}><DuelForfeitCase game={el} tournamentDetails={tournamentDetails} /></Carousel.Item>
                        }else{
                            return (
                                <Carousel.Item>
                                    <div className='col-12 col-lg-6 offset-lg-3'>
                                        <div className='scoreboard-bg-image mt-2 p-3'>
                                            <CardHeader tournamentDetails={tournamentDetails} />
                                            <div className='d-flex justify-content-evenly align-items-end'>
                                                <div className=''>
                                                    <div className=''>
                                                        {/* {el[0].isWinner && <span className='winner-shadow'></span>} */}
                                                        <span className='d-flex flex-column align-items-center'>
                                                            {el[0].isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                            <img src={el[0].participantDetails?.profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                                                        </span>
                                                        <h5 className='text-white fw-bold fs-10 mt-2 text-center'>{matchDetails.participants[0].inGameName}</h5>
                                                        {el[0].participantDetails._id === el[0].matchMVP._id && <p className='text-warning fs-10 fw-bold text-center'>MVP</p>}
                                                        <table className="table table-borderless mt-2">
                                                            <thead>
                                                                <tr className='scorecard_header'>
                                                                    <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                                                    <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                                                    <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr className='scorecard_body'>
                                                                <td className='fs-8 fw-600 text-white team_body'>{`${el[0].kills} - ${el[0].deaths}`}</td>
                                                                <td className='fs-8 fw-600 text-white'>{el[0].score}</td>
                                                                <td className='fs-8 fw-600 text-white team_score'>{el[0].firstBlood}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='text-white fw-700 fs-14 mb-1'>{`${el[0].noOfRoundsWon}-${el[1].noOfRoundsWon}`}</p>
                                                </div>
                                                <div className=''>
                                                    {/* {el[1].isWinner && <span className='winner-shadow'></span>} */}
                                                    <span className='d-flex flex-column align-items-center'>
                                                        {el[1].isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                        <img src={el[1].participantDetails?.profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                                                    </span>
                                                    <h5 className='text-white fw-bold text-center fs-10 mt-2'>{matchDetails.participants[1].inGameName}</h5>
                                                    {el[1].participantDetails._id === el[1].matchMVP._id && <p className='text-warning fs-10 fw-bold text-center'>MVP</p>}
                                                    <table className="table table-borderless mt-2">
                                                        <thead>
                                                            <tr className='scorecard_header'>
                                                                <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                                                <th scope="col" className='name_header fs-8 fw-600 text-white'>Score</th>
                                                                <th scope="col" className='score_header fs-8 fw-600 text-white'>First Blood</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className='scorecard_body'>
                                                            <td className='fs-8 fw-600 text-white team_body'>{`${el[1].kills} - ${el[1].deaths}`}</td>
                                                            <td className='fs-8 fw-600 text-white'>{el[1].score}</td>
                                                            <td className='fs-8 fw-600 text-white team_score'>{el[1].firstBlood}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        }
                    })}
                </Carousel>
                </div>
            )
        }else{
            return (
                <div className='row'>
                    {this.getSoloDuelFinalResult(false)}
                </div>
            )
        }
    }

    getCard = () => {
        const tournamentDetails = this.state.tournamentDetails;
        switch (true) {
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'DUEL'):
                return this.getSoloDuel();
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'FFA'):
                return this.getSoloFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'FFA'):
                return this.getTeamFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'DUEL'):
                return this.getTeamDuel();
            default:
                break;
        }
    }  

    render() {
        return (
            <div className="p-0">
                {this.getCard()}
            </div>
        )
    }
}