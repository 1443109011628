import { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
//images, icons
import '../../styles/TournamentPage.css';
import hoodie from '../../images/page/hoodie.png';
import plus from '../../images/page/plus.png';
import { IoIosCloseCircleOutline } from "react-icons/io";


export default class PrizePool extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            tournamentDetails: newProps.tournamentDetails
        });
    }

    handleClose = () => {
        this.props.toggleModalStatus()
    }

    getCurrentStatus = () => {
        const {tournamentDetails} = this.state;
        if (tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolType === 'FIXED'){
            return 100;
        }else{
            const entryFee = tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount;
            const members = tournamentDetails?.teams?.length;
            const prizePool = tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount;
            return `${parseInt(entryFee)*members}/${prizePool}`;
        }
    }

    getPrizePoolStatus = () => {
        const {tournamentDetails} = this.state;
        const entryFee = tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount;
        const members = tournamentDetails?.teams?.length;
        const prizePool = tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount;
        if (tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolType === 'FIXED'){
            return `${prizePool}/${prizePool}`;
        }else{
            
            return `${parseInt(entryFee)*members}/${prizePool}`;
        }
    }

    render() {
        return (
            <div className='rounded-15 p-3' id='tournament-date' style={{background:'#1c1c1c'}}>
                {/* Heading */}
                <div className='row'>
                    <div className='col-3'></div>
                    <div className='col-6'>
                        <h1 className='fs-20 fw-600 text-white text-center my-1'>Prize Pool</h1>
                    </div>
                    <div className='col-3 d-flex align-items-center'>
                        <IoIosCloseCircleOutline onClick={this.handleClose}
                            className='text-white ms-auto me-2 my-1 btn-size-20'/>
                    </div>
                </div>
                {/* Prize Pool */}
                <div className='w-100 my-2'>
                    <p className='f-14 fw-600 text-white my-1'>Prize Amount in <span className='text-primary'>EC</span></p>
                    <ProgressBar variant='warning' now={this.getCurrentStatus()} className='w-100 mx-0' />
                    <div className='d-flex justify-content-between my-1'>
                        <p className='fs-12 text-light fw-600' onClick={this.toggleModalStatus}>Prize Pool</p>
                        <p className='fs-12 text-light fw-600'>{this.getPrizePoolStatus()}</p>
                    </div>
                </div>
                <div className='prizes-collection px-3'>
                    {/* Rank-1 */}
                    {this.state.tournamentDetails?.entryFeeAndPrizeDetails?.prizeDistribution?.length ? 
                        this.state.tournamentDetails?.entryFeeAndPrizeDetails?.prizeDistribution?.map((prize, i) => {
                            if (prize.merchandise){
                                return(
                                    <div className='d-flex flex-column align-items-center mb-2' key={i}>
                                        <p className='fs-14 fw-600 text-white my-2'>Rank {i+1}</p>
                                        <button className='common-bg rounded-15 text-primary fw-700 w-100 border-0 height-52' style={{ height: '52px' }}>EC {prize.prizeAmount}</button>
                                        <img src={plus} alt='' className='mt-2' />
                                        <p className='fs-14 fw-400 text-white my-2'>{prize.merchandiseDescription}</p>
                                        <img src={prize.merchandiseImage.url} alt='' className='rounded-10 border border-2 mt-3' style={{height:'100px', width:'auto'}} />
                                    </div>
                                )
                            }else{
                                return(
                                    <div className='d-flex flex-column align-items-center my-2' key={i}>
                                        <p className='fs-14 fw-600 text-white my-2'>Rank {i+1}</p>
                                        <button className='common-bg rounded-15 text-primary fw-700 w-100 border-0' style={{ height: '52px' }}>EC {prize.prizeAmount}</button>
                                        {/* <img src={plus} alt='' className='mt-2' />
                                        <p className='fs-14 fw-600 text-white my-2'>{prize.merchandiseDescription}</p>
                                        <img src={prize.merchandiseImage.url} alt='' className='rank-prize-image' /> */}
                                    </div>
                                )
                            }
                            
                        })
                        :
                        null
                    } 
                </div>

            </div>
        )
    }
}
