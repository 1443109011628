import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import player from '../../../../../images/player.png';
import * as Yup from "yup";
import { DropdownButton, Dropdown, Button, ButtonGroup, SplitButton } from 'react-bootstrap';
import { getMatchAndParticipantsDetails3, getMatchAndParticipantsDetailsForBracketsDoubleElimination, getMonthAndDate, isExists, updateLoader } from '../../../../../utils/common.utils';
import TournamentFilter from '../../common/TournamentFilter';
import { toast } from 'material-react-toastify';
import { ASSETS } from '../../../../../utils/Strings.utils';
import { Commons } from '../../../../../utils/Images.utils';

export default class MatchesDuels extends Component {

    constructor(props) {
        super(props)
        
        const query = new URLSearchParams(this.props.location.search);
        this.query = query;
        const stage = parseInt(query.get('stage'));
        const group = parseInt(query.get('group'));
        const round = parseInt(query.get('round'));
        
        this.state = {
            tournamentDetails: props.tournamentDetails,
            openMatchesFilter: false,
            stage: props.stageIndex,
            group: 0,
            round: 1,
            stageDetails: props.tournamentDetails?.stages[props.stageIndex],
            isBracketGroup: false,
            selectedGroupIndex: 0,
            showLoader : false
        }
        if (this.props.tournamentDetails?.stages.length > 0) {
            //this.props.updateLoader(true);
            var stageDetails = this.state.stageDetails;
            if (stageDetails.type === "DUEL" && stageDetails.subType === "Bracket Groups" && stageDetails.bracketType === "DOUBLE") {
                this.setState({ isBracketGroup: true });
            }
            getMatchAndParticipantsDetailsForBracketsDoubleElimination(this.props.tournamentDetails._id, this.setMatchData, this.props.stageIndex, this.props.tournamentDetails.stages);

        }

        this.url = new URL(window.location);
        //this.updated = false;
        if(stage > 0 || group > 0 || round > 1){
            
            setTimeout(() => {
                this.displayLoader();
                this.filterMatches(stage, group, round)
            }, 0);  
            setTimeout(() => {
                this.filterMatches(stage, group, round)
                this.displayLoader();
            }, 3500);  
          
        }
    }

    displayLoader = () =>{
        this.setState({
            showLoader : !this.state.showLoader
        })
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        if (preProps.stageIndex !== this.props.stageIndex) {
            var stageDetails = this.state.stageDetails;
            if (stageDetails.type === "DUEL" && stageDetails.subType === "Bracket Groups" && stageDetails.bracketType === "DOUBLE") {
                this.setState({isBracketGroup: true });
                //console.log('Type 1')
            }
            getMatchAndParticipantsDetailsForBracketsDoubleElimination(this.props.tournamentDetails._id, this.setMatchData, this.props.stageIndex, this.props.tournamentDetails.stages);
        }
    }

    componentWillReceiveProps(newProps) {
            this.setState({
                tournamentDetails: newProps.tournamentDetails,
                stage: newProps.stageIndex,
                group: 0,
                round: 1
            });
    }

    setMatchData = (values) => {
        //console.log('setMatchData values :::', values);
        this.props.updateLoader(false);
        if (values) {
            var { tournamentDetails, stageDetails } = this.state;
            tournamentDetails.stages = values;
            stageDetails = values[this.state.stage];
            //console.log('stageDetails :::', stageDetails);
            this.setState({
                tournamentDetails: tournamentDetails,
                stageDetails: stageDetails
            })
            this.props.updateLoader(false);

        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    filterMatches = (stage, group, round) => {
      console.log(`stage :: ${stage} || group :: ${group} || round :: ${round}`)
        this.setState({
            group: group,
            round: round
        });
        this.setRoutParam(stage, group, round)
        this.props.updateStage(stage);
    }

    setRoutParam = (stage, group, round) => {
        this.url.searchParams.set('stage', stage);
        this.url.searchParams.set('group', group);
        this.url.searchParams.set('round', round);
        window.history.replaceState(null, '', this.url.toString());
    }

    goToUpdate = (participant, matchId) => {
        if (!participant) {
            toast.error('Participant yet to join')
        } else {
            this.props.history.push(`/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview/match/Duel/${matchId}/score`)
        }
    }

    getURL = (el) => {
        if (this.state.tournamentDetails.participantType === 'SINGLE') {
            if(el.profilePicture){
                return el.profilePicture.url;
            }
            return player;

        } else {
            return el.logo.url
        }
    }

    getMatches = (tournamentDetails, groups, round) => {
    
        if(isExists(tournamentDetails) && isExists(groups) && isExists(round)) {
            return (
                <div className='row'>
                    {
                        groups[this.state.round].map((match, index) => {
                            const matchTime = match.matchSchedule
                            const scheduledTime = new Date(matchTime)
                            const matchDateTime = getMonthAndDate(match.matchSchedule)
                            const matchYear = scheduledTime.getFullYear()
                            const participants = match.participants
                            const result = match.results
                            //console.log("match :: ", match);
                            // console.log('timezone::', Intl.DateTimeFormat().resolvedOptions().timeZone)
                            const isTeamPlayer = this.state.tournamentDetails?.participantType === "TEAM" ? true : false;
                            return (
                                <div onClick={() => { this.goToUpdate(participants?.length, match._id) }} className='col-12 col-md-6 col-lg-4' key={index}>
                                    <div className='brown-shadow mx-auto p-3 mb-3'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <p className='type'>{tournamentDetails?.competitionType}</p>
                                            </div>
                                            <div className='col-6'>
                                                <h6 className='fs-14 fw-bold text-center'>Match {round}.{index + 1} 
                                                </h6>
                                            </div>
                                            <div className='col-3'></div>
                                        </div>
                                        <div className='row mt-3'>
                                            {
                                                participants ?
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center text-center my-0 w-100'>
                                                            <img src={participants[0] ? this.getURL(participants[0]) : player} className='rounded-circle me-2 btn-size-25' alt='' />
                                                            <div className='d-flex flex-column text-truncate'>
                                                                <h6 className="fs-12 fw-bold my-0 text-truncate">{participants[0] ? ( isTeamPlayer ? participants[0].name : participants[0].inGameName) : '----'}</h6>
                                                                {/* <p className='won my-1'>WON</p> */}
                                                            </div>
                                                            {/* <p className='fs-12 fw-700 text-black bg-white border-0 rounded-1 ms-2 my-0 p-1 align-self-start'>1</p> */}
                                                        </div>
                                                        <div className='d-flex align-items-center text-center mt-2 w-100'>
                                                            <img src={participants[1] ? this.getURL(participants[1]) : player} className='rounded-circle me-2 btn-size-25' alt='' />
                                                            <div className='d-flex flex-column text-truncate'>
                                                                <h6 className="fs-12 fw-bold my-0 text-truncate">{participants[1] ? ( isTeamPlayer ? participants[1].name : participants[1].inGameName) : '----'}</h6>
                                                            </div>
                                                            {/* <p className='fs-12 fw-700 text-black bg-white border-0 rounded-1 ms-2 my-0 p-1 align-self-start'>0</p> */}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className='col-6 align-self-center'>
                                                <h6 className='fs-12 fw-bold text-end'>{match.status}</h6>
                                                <h6 className='fs-10 fw-bold text-end' style={{ opacity: "0.7" }}>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }

    getDoubleBracketGroupsMatches = (tournamentDetails, groups, round) => {
      
        if(isExists(tournamentDetails) && isExists(groups) && isExists(round)) {
            return (
                <div>

                    {/* Filter on Winners and Loosers Matches */}
                    <div className="btn-group filter-btn w-100" role="group" aria-label="Basic example">
                        <button type="button" onClick={()=>this.setState({selectedGroupIndex : 0})} style={{ opacity: this.state.selectedGroupIndex === 0 ? '1' : '0.4' }} className={`btn-primary rounded-1 text-white score-showdown-btn  rounds mb-4 w-50`}>Winners</button>
                        <button type="button" onClick={()=>this.setState({selectedGroupIndex : 1})} style={{ opacity: this.state.selectedGroupIndex === 1 ? '1' : '0.4' }} className={`btn-primary rounded-1 text-white score-showdown-btn  rounds mb-4 w-50`}>Lossers</button>
                    </div>

                    <div>
                        {
                            groups[this.state.selectedGroupIndex][this.state.round].map((match, index) => {
                                if((typeof match) === 'object'){
                                    
                                    const matchTime = match.matchSchedule
                                    const scheduledTime = new Date(matchTime)
                                    const matchDateTime = getMonthAndDate(match.matchSchedule)
                                    const matchYear = scheduledTime.getFullYear()
                                    const participants = match.participants
                                    const result = match.results
                                    //console.log("match :: ", match);
                                    // console.log('timezone::', Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    const isTeamPlayer = this.state.tournamentDetails?.participantType === "TEAM" ? true : false;
                                    return (
                                        <div onClick={() => { this.goToUpdate(participants?.length, match._id) }} className='col-12 col-md-6' key={index}>
                                            <div className='brown-shadow mx-auto p-3 mb-3'>
                                                <div className='row'>
                                                    <div className='col-3'>
                                                        <p className='type'>{tournamentDetails?.competitionType}</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <h6 className='fs-14 fw-bold text-center'>Match {round}.{index + 1}</h6>
                                                    </div>
                                                    <div className='col-3'></div>
                                                </div>
                                                <div className='row mt-3'>
                                                    {
                                                        participants ?
                                                            <div className='col-6'>
                                                                <div className='d-flex align-items-center text-center my-0 w-100'>
                                                                    <img src={participants[0] ? this.getURL(participants[0]) : player} className='rounded-circle me-2 btn-size-25' alt='' />
                                                                    <div className='d-flex flex-column text-truncate'>
                                                                        <h6 className="fs-12 fw-bold my-0 text-truncate">{participants[0] ? ( isTeamPlayer ? participants[0].name : participants[0].inGameName) : '----'}</h6>
                                                                        {/* <p className='won my-1'>WON</p> */}
                                                                    </div>
                                                                    {/* <p className='fs-12 fw-700 text-black bg-white border-0 rounded-1 ms-2 my-0 p-1 align-self-start'>1</p> */}
                                                                </div>
                                                                <div className='d-flex align-items-center text-center mt-2 w-100'>
                                                                    <img src={participants[1] ? this.getURL(participants[1]) : player} className='rounded-circle me-2 btn-size-25' alt='' />
                                                                    <div className='d-flex flex-column text-truncate'>
                                                                        <h6 className="fs-12 fw-bold my-0 text-truncate">{participants[1] ? ( isTeamPlayer ? participants[1].name : participants[1].inGameName) : '----'}</h6>
                                                                    </div>
                                                                    {/* <p className='fs-12 fw-700 text-black bg-white border-0 rounded-1 ms-2 my-0 p-1 align-self-start'>0</p> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    <div className='col-6 align-self-center'>
                                                        <h6 className='fs-12 fw-bold text-end'>{match.status}</h6>
                                                        <h6 className='fs-10 fw-bold text-end' style={{ opacity: "0.7" }}>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>


                </div>
            );
        }
    }

    render() {      
        const { tournamentDetails, group, round, selectedGroupIndex } = this.state;
        const groups = tournamentDetails.stages[this.state.stage]?.groups[group];
        var isBracketGroup = false;

        if (tournamentDetails.stages[this.state.stage].type === "DUEL" && tournamentDetails.stages[this.state.stage].subType === "Bracket Groups" && tournamentDetails.stages[this.state.stage].bracketType === "DOUBLE") {
            isBracketGroup = true;
        }

        if(this.state.showLoader){
            return (
                <div style={{position:'absolute', left:'0', top:'0'}}>
                    {/* Loader Section */}
                    <div className="application-loading-container ">
                        <img className="image" src={Commons.LOADER} alt="" width="120" height="120"></img>
                    </div>
                </div>
            )
        }else{

    
            if(isExists(this.props.stageIndex) && isExists(group) && isExists(groups) && isExists(round) && isExists(selectedGroupIndex)){
                return (
                    <div className='p-3'>
                        <TournamentFilter isBracketGroupsDoubleElimination={isBracketGroup} stageIndex={this.props.stageIndex} state={this.state} selectedGroupIndex={selectedGroupIndex} filterMatches={this.filterMatches} groups={groups} groupIndex={group} />

                        {isBracketGroup ? this.getDoubleBracketGroupsMatches(tournamentDetails, groups, round) : this.getMatches(tournamentDetails, groups, round)}
                    
                    </div>
                );
            }else{
                return (
                    <div className="text-center">
                        <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                    </div>
                )
            }
        }
    }
}