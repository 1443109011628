import React, { Component } from 'react';
import { getAvatarForLetter } from '../../utils/common.utils';
import { ASSETS } from '../../utils/Strings.utils';

export default class Image extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id : props.id ? props.id : null,
            class : props.className ? props.className : null,
            key : props.key ? props.key : null,
            param : props.param ? props.param : null,
            url : props.src ? props.src : null,
            height : props.height ? props.height : null, 
            width : props.width ? props.width : null, 
            style : props.style ? props.style : null,
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            id : newProps.id ? newProps.id : null,
            class : newProps.className ? newProps.className : null,
            key : newProps.key ? newProps.key : null,
            param : newProps.param ? newProps.param : null,
            url : newProps.src ? newProps.src : null,
            height : newProps.height ? newProps.height : null, 
            width : newProps.width ? newProps.width : null,
            style : newProps.style ? newProps.style : null,
        });
    }

    render() {
        var defaultImage;

        try{
            defaultImage = getAvatarForLetter(this.state.param?.charAt(0).toLowerCase()); 
        }catch(err){
            defaultImage = ASSETS.DEFAULT_AVATAR;
        }

        return (
            <img 
                src={this.state.url ? this.state.url : defaultImage} 
                className={this.state.class} 
                id={this.state.id} 
                key={this.state.key} 
                height={this.state.height}
                width={this.state.width}
                alt=''
                style={this.state.style}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=defaultImage
                }}
            />
        );
    }
}
