import { Component } from 'react';
import { Form } from 'react-bootstrap';
import '../../../styles/Layout.css';
import { IoIosCheckmarkCircle } from 'react-icons/io';

//images
import alanWake from '../../../images/image 65.jpg';
import battlefield from '../../../images/login_pc_image.png';
import cs from '../../../images/hogwarts-legacy-1200x675.jpg';
import fortnite from '../../../images/login_pc_image.png';
import Badge from 'react-bootstrap/Badge'
import league from '../../../images/home_banner_image.png';
import outrider from '../../../images/cs_go.png';
import overwatch from '../../../images/hogwarts-legacy-1200x675.jpg';
import valorant from '../../../images/cs_go.png';
import { backgroundColors, ellipsis, errorHandler, regions, remainingTime, secondaryTextColors, textColors, updateLoader } from '../../../utils/common.utils';
import TeamApis from '../../../helper/teams.api';
import MicroWebSiteNav from '../configuration/MicroWebSiteNav';
import MicroWebSiteFooter2 from '../configuration/MicroWebSiteFooter2';
import TournamentApis from '../../../helper/tournament.api';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { NavLink } from 'react-router-dom';
import { ASSETS } from '../../../utils/Strings.utils';
import { getAllGamesDetails } from '../../../utils/Games.utils';


const upcomingTournament = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class LayoutThree extends Component {

  service = new TeamApis();
  TournamentApis = new TournamentApis();

  constructor(props) {
    super(props);
    this.state = {
      microWebSiteData: props.microWebSiteData,
      games: [],
      background: (props.microWebSiteData ? props.microWebSiteData.color.background : 4),
      primary: (props.microWebSiteData ? props.microWebSiteData.color.primary : 4),
      secondary: (props.microWebSiteData ? props.microWebSiteData.color.secondary : 4),
      openNavbar: false,
      tournaments: [],
      selectedGame: null
    };
    if(props.microWebSiteData){
      this.getData(props.microWebSiteData.organizationId);
    }
    getAllGamesDetails(this.setAllGamesDetails);
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  setAllGamesDetails = (response) => {
    this.setState({
      games : response,
      selectedGame : response[0]
    });
  }

  getData = (id) => {
    var data = { organizationId: id }
    updateLoader(true);
    this.TournamentApis.getTournamentsById(data).then(
      (res: AxiosResponse) => {
        var data = res.data['result']
        this.setState({ tournaments: data })
        updateLoader(false);
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  selectYourGame = (e, type, i) => {
    e.preventDefault();
    this.setState({
      [type]: i
    });
  }

  toggleOpenNavbar = () => {
    this.setState({ openNavbar: !this.state.openNavbar })
  }

  // getSponsors = () => {

  //   if (this.state.microWebSiteData.sponsors[0] && this.state.microWebSiteData.sponsors[0] !== null) {
  //     this.state.microWebSiteData.sponsors.map((sponsor, i) => {
  //       return (
  //         <Carousel.Item key={i}>
  //           <div>
  //             <div className="banner-card" style={{ backgroundImage: `url(${sponsor?.logo?.url})` }}></div>
  //             <h6 className="text-center">
  //               <Badge pill bg="none" className=" mt-1" style={{ color: `${this.state.secondary}`, backgroundColor: `${this.state.primary}` }}>{sponsor.type + " : " + sponsor.name}</Badge>
  //             </h6>
  //           </div>
  //         </Carousel.Item>
  //       )
  //     })
  //   }
  // }

  render() {
    return (
      <>
        <MicroWebSiteNav showMenu={this.props.isPreview} path={this.props.path} redirectTo={this.props.redirectTo} state={this.state} microWebSiteData={this.state.microWebSiteData} toggleOpenNavbar={this.toggleOpenNavbar} />

        <section className="layout3" style={{ display: (this.state.openNavbar) ? 'none' : 'block', backgroundColor: `${this.state.background}` }}>
          {this.state.microWebSiteData ?
            <div className="mt-6">{/* Site Section */}
              <div className="clanView-banner">
                <img className="clanView-background" src={this.state.microWebSiteData?.banner?.url ? this.state.microWebSiteData.banner.url : ASSETS.DEFAULT_COVER} width="50" height="50" alt="" />
                <img className="clanView-fronImage"
                  src={this.state.microWebSiteData?.banner?.url ? this.state.microWebSiteData.banner.url : ASSETS.DEFAULT_COVER} alt="" width="50" height="50" />
              </div>

              {/* Tournaments */}
              <section className="tournaments text-center" >
                <h1 className={`fs-20 fw-700 text-center micro-web-site-text-${this.state.primary} my-3`} style={{color: `${this.state.primary}`}}>Upcoming Tournaments</h1>
                {(this.state.tournaments?.length > 0) ?
                  <section className="tournament-section tournament-carousel">
                    <div className="container text-center pt-5">
                      <Carousel
                        responsive={responsive}
                        infinite={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={1500}
                        transitionDuration={500}
                        deviceType={this.props.deviceType}
                      >
                        {this.state.tournaments?.map((tournament, i) => {
                          const timeLeft = remainingTime(tournament?.registrationEndTime);
                          return (
                            <div className="card-container mx-2" key={i}>
                              <NavLink to={`/tournament/${tournament._id}`} style={{textDecoration:'none'}}>
                              <div className="tournament-card">
                                <div className="top-section " style={{ backgroundImage: `url(${tournament?.baner?.url})` }}>
                                  <h1 className="card-name">{tournament?.gameDetails?.name}</h1>
                                </div>
                                <div className="bottom-section p-2 px-3">
                                  <h1 className="fw-bold text-white text-center fs-14">{tournament?.name}</h1>

                                  <div className="d-flex flex-row justify-content-between my-2">
                                    <div className="text-left">
                                      <h1 className="fs-10 text-whitefs-10 text-white">Entry Fee</h1>
                                      <h1 className="fs-10 text-white fw-700" style={{ color: '#03d158' }}>{(tournament.entryFeeAndPrizeDetails?.entryFeeAmount) ? `EC ${tournament.entryFeeAndPrizeDetails.entryFeeAmount}` : 'TBD'}</h1>
                                    </div>
                                    <div className="text-right">
                                      <h1 className="fs-10 text-white">Time Left</h1>
                                      <h1 className="fs-10 text-white fw-700" style={{ color: '#ff453a' }}>{`${timeLeft[0]} ${timeLeft[1]}`}</h1>
                                    </div>
                                  </div>
                                  <p className="fs-8 text-start text-white flex-grow-1">
                                    {ellipsis(tournament?.bio, 200)}
                                  </p>
                                </div>
                              </div>
                              </NavLink>
                            </div>
                          )
                        }
                        )}
                      </Carousel>
                    </div>
                  </section>
                  :
                  <div className="text-center">
                    <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                  </div>}
                <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center my-5"
                  style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}
                  onClick={() => this.props.redirectTo('tournament')}>
                  Browse All Tournaments
                </button>
              </section>

              <section className="container d-flex flex-column mx-auto my-3">
                <h1 className={`fs-20 fw-700 text-center micro-web-site-text-${this.state.primary}`} style={{color: `${this.state.primary}`}}>FIND TOURNAMENTS</h1>
                <h1 className={`fs-16 text-center mb-3 micro-web-site-sc-text-${this.state.secondary}`} style={{color: `${this.state.primary}`}}>1. Choose One Region</h1>
                
                <h1 className={`fs-16 mb-3 text-center micro-web-site-sc-text-${this.state.secondary}`} style={{color: `${this.state.primary}`}}>2. Choose Game</h1>
                <div className='row justify-content-center'>
                  {this.state.games.map((game, i) => {
                    return (
                      <div className='col-lg-3 col-md-4 col-6' key={i}>
                        <div className='details-card d-flex' style={{ backgroundImage: `url(${game.logo.url})` }} onClick={(e) => this.selectYourGame(e, "selectedGame", game)}>
                          {this.state.selectedGame?._id === game?._id ? <IoIosCheckmarkCircle style={{ opacity: 0.7, color: `${this.state.background}` }} className="select-ic select-game" /> : null}
                        </div>
                      </div>
                      )
                  })
                  }
                </div>
                <Form.Select aria-label="Floating label select example" className={`select-form mx-auto`} style={{ color: `${this.state.background}`, backgroundColor: `${this.state.primary}`, border: `1px solid ${this.state.secondary}` }}>
                  {this.state.selectedGame?.regions.map((region, i) => {
                    return (
                      <option className="form-option" key={i} value={region} style={{ color: `${this.state.background}` }}>{region}</option>
                    )
                  })
                  }
                </Form.Select>

                <div className="d-flex flex-column justify-content-center">
                  <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center" style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}>Search</button>
                  <div className="d-flex align-items-center my-3">
                    <p className="border-1 flex-grow-1 my-auto" style={{ backgroundColor: `${this.state.primary}`, height: '1px' }}></p>
                    <p className="fs-14 text-white mx-2 my-0 fw-700" style={{ color: `${this.state.primary}` }}>OR</p>
                    <p className="border-1 flex-grow-1 my-auto" style={{ backgroundColor: `${this.state.primary}`, height: '1px' }}></p>
                  </div>
                  <button
                    className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center"
                    style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}
                    onClick={() => this.props.redirectTo('tournament')}>
                    Browse All Tournaments
                  </button>
                </div>
              </section>

              <section className='container my-3 layout-three-sponsor-carousel'>
                <div className='mt-6'>
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary}`} style={{color: `${this.state.primary}`}}>OUR SPONSORS</h1>
                  {(this.state.microWebSiteData?.sponsors?.length > 0) ?
                    <Carousel 
                    responsive={responsive}
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={1500}
                    transitionDuration={500}
                    deviceType={this.props.deviceType} >
                      {this.state.microWebSiteData.sponsors.map((sponsor, i) => {
                        return (
                          <>
                            <div className="banner-card" key={i} style={{ backgroundImage: `url(${sponsor?.logo?.url})`, height: '200px', backgroundSize: '100% 100%' }}></div>
                            <h6 className="text-center mt-2">
                              <Badge pill bg="none" className=" mt-1 w-100 text-truncate" style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}`, maxWidth: 'fit-content' }}>{sponsor.type + " : " + ellipsis(sponsor.name, 20)}</Badge>
                            </h6>
                          </>
                        )
                      })}
                    </Carousel>

                    :
                    <div className="text-center">
                      <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                    </div>
                  }
                </div>
              </section>

              <section className='container my-3 layout-three-sponsor-carousel'>
                <div className='mt-6'>
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary}`} style={{color: `${this.state.primary}`}}>CUSTOM IMAGES</h1>
                  <Carousel 
                    responsive={responsive}
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={1500}
                    transitionDuration={500}
                    deviceType={this.props.deviceType} >
                      {this.state.microWebSiteData?.customImage.map((el, i) => {
                        return (<div className="banner-card" key={i} style={{ backgroundImage: `url(${el?.url})`, height: '200px', backgroundSize: '100% 100%' }}></div>)
                      })}
                    </Carousel>
                </div>
              </section>

              <MicroWebSiteFooter2 microWebSiteData={this.state.microWebSiteData} backgroundColors={backgroundColors} secondaryTextColors={secondaryTextColors} background={this.state.background} secondary={this.state.secondary} />
              {this.props.isPreview ?
                <button className="close-preview" onClick={() => this.props.openPreview(false, this.state.microWebSiteData)}>Close Preview</button>
                :
                null
              }
            </div>
            :
            <div className="mt-7">{/* Preview Section */}
              <div className="clanView-banner">
                <img className="clanView-background" src={valorant} width="50" height="50" alt="" />
                <img className="clanView-fronImage"
                  src={valorant} alt="" width="50" height="50" />
              </div>
              <div className='container'>
                <section className="d-flex flex-column mx-auto my-3">
                  <h3 className='fs-20 text-white text-center my-2'>Upcoming Tournaments</h3>
                  <section className="tournament-section tournament-carousel">
                      <div className="container text-center pt-5">
                        <Carousel
                          responsive={responsive}
                          infinite={true}
                          swipeable={true}
                          autoPlay={this.props.deviceType !== "mobile" ? true : false}
                          autoPlaySpeed={1500}
                          className='d--lg-flex justify-content-lg-center'
                        // slidesToSlide={1}
                        >     
                        <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3">
                                  </div>
                                </div>
                              </div>
                              <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3">
                                  </div>
                                </div>
                              </div>
                              <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3">
                                  </div>
                                </div>
                              </div>
                              <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3">
                                  </div>
                                </div>
                              </div>
                        </Carousel>
                      </div>
                    </section>
                </section>

                <section className="d-flex flex-column mx-auto my-3">
                  <h1 className='fs-20 text-white text-center my-2'>Find Tournaments</h1>
                  <h1 className='fs-16 text-white text-center mb-3' stye={{ color: '#77b903' }}>1. Choose One Region</h1>
                  <Form.Select aria-label="Floating label select example" className='select-form mx-auto' >
                    <option className="form-option"> valorant</option>
                  </Form.Select>
                  <h1 className='fs-16 text-white text-center mb-3' stye={{ color: '#77b903' }}>2. Choose Game</h1>
                  <div className='row'>
                    <div className='col-lg-3 col-md-4 col-6'>
                      <div className='details-card' style={{ backgroundColor: 'grey' }}>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-6'>
                      <div className='details-card' style={{ backgroundColor: 'grey' }}>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-6'>
                      <div className='details-card' style={{ backgroundColor: 'grey' }} >
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-6'>
                      <div className='details-card' style={{ backgroundColor: 'grey' }}>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column justify-content-center">
                    <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center" >Search</button>
                    <div className="d-flex align-items-center my-3">
                      <p className="border-1 flex-grow-1 my-auto" style={{ height: '1px' }} ></p>
                      <p className="fs-14 text-white mx-2 my-0 fw-700">OR</p>
                      <p className="border-1 flex-grow-1 my-auto" style={{ height: '1px' }}></p>
                    </div>
                    {/* <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center" >Browse All Tournaments</button> */}
                  </div>
                </section>

                <section className='container my-3'>
                  <h1 className='fs-20 text-white text-center text-white my-2'>Sponsor By</h1>
                  <div className='d-flex overflow-auto'>
                    <div className='game-card' style={{ backgroundColor: 'grey' }} ></div>
                    <div className='game-card' style={{ backgroundColor: 'grey' }}></div>
                    <div className='game-card' style={{ backgroundColor: 'grey' }} ></div>
                    <div className='game-card' style={{ backgroundColor: 'grey' }}></div>
                  </div>
                </section>

                <div className="mt-6 pb-6 text-center">
                  <img src={"https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/Screenshot_from_2021-12-07_17-09-15-removebg-preview.png"} alt={""} className="border border-2 border-white rounded-10" style={{ height: '100px' }} />
                </div>
                <div className="wrapper position-fixed">
                  <ul>
                    <li className="facebook btn-size-32 position-relative my-2 rounded-2 cursor-pointer">
                      <i className="fa fa-facebook position-absolute fs-20 text-white" aria-hidden="true"></i>
                    </li>

                    <li className="twitter btn-size-32 position-relative my-2 rounded-2 cursor-pointer">
                      <i className="fa fa-twitter position-absolute fs-20 text-white" aria-hidden="true"></i>
                    </li>

                    <li className="instagram btn-size-32 position-relative my-2 rounded-2 cursor-pointer">
                      <i className="fa fa-instagram position-absolute fs-20 text-white" aria-hidden="true"></i>
                    </li>

                    <li className="google btn-size-32 position-relative my-2 rounded-2 cursor-pointer">
                      <i className="fa fa-google position-absolute fs-20 text-white" aria-hidden="true"></i>
                    </li>

                    <li className="whatsapp btn-size-32 position-relative my-2 rounded-2 cursor-pointer">
                      <i className="fa fa-whatsapp position-absolute fs-20 text-white" aria-hidden="true"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </section>
      </>
    );
  }
}

export default LayoutThree;
