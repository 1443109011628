import React, { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { IoIosCloseCircleOutline } from 'react-icons/io'

export default class AddParticipant extends Component {
    render() {
        return (
            <><div className="">
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '10px', top: '20px' }} onClick={() => { this.props.onClickOpenAddParticipant(false) }} />
                <h5 className="fs-18 fw-bold text-white text-center">Add Participant</h5>
            </div>
                <div className="">

                    <Formik
                        enableReinitialize
                        initialValues={{
                            realName: '',
                            gameName: '',
                            gameId: '',
                            mobileNumber: '',
                            discordId: '',
                            customField: '',
                        }}
                        validationSchema={Yup.object({
                            realName: Yup.string().required('Real Name is Required'),
                            gameName: Yup.string().required('Game Name is Required'),
                            gameId: Yup.string().required('Game ID is Required'),
                            mobileNumber: Yup.string().required('Mobile Number is Required'),
                            discordId: Yup.string().required('Discord ID is Required'),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.onSubmit(values);
                            }, 400);
                        }}
                    >
                        <Form className="mx-2 mt-3" autoComplete="off">

                            <div className="mt-2">
                                <label htmlFor="realName" className="fs-16 fw-600 text-white">Real Name</label>
                                <Field
                                    name="realName"
                                    type="text"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                    placeholder="Enter Real Name"
                                />

                                <ErrorMessage component="span" className="error-msg" name="realName" />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="gameName" className="fs-16 fw-600 text-white">In-Game Name</label>
                                <Field
                                    name="gameName"
                                    type="text"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                    placeholder="Enter In-Game Name"
                                />

                                <ErrorMessage component="span" className="error-msg" name="gameName" />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="gameId" className="fs-16 fw-600 text-white">In-Game ID</label>
                                <Field
                                    name="gameId"
                                    type="text"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                    placeholder="Enter In-Game ID"
                                />

                                <ErrorMessage component="span" className="error-msg" name="gameId" />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="mobileNumber" className="fs-16 fw-600 text-white">Mobile Number</label>
                                <Field
                                    name="mobileNumber"
                                    type="text"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                    placeholder="Enter Mobile Number"
                                />

                                <ErrorMessage component="span" className="error-msg" name="mobileNumber" />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="discordId" className="fs-16 fw-600 text-white">Discord ID</label>
                                <Field
                                    name="discordId"
                                    type="text"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                    placeholder="Enter Discord ID"
                                />

                                <ErrorMessage component="span" className="error-msg" name="discordId" />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="customField" className="fs-16 fw-600 text-white">Custome Field</label>
                                <Field
                                    name="customField"
                                    type="text"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                    placeholder="Enter Custome Field"
                                />

                                <ErrorMessage component="span" className="error-msg" name="customField" />
                            </div>

                            <button type="submit" className="white-flat-btn fw-bold py-2 px-4 m-auto mt-3 d-block">Add</button>
                        </Form>
                    </Formik>
                </div></>
        )
    }
}
