import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PROFILE_PAGE_IMAGES } from '../../utils/Images.utils';
import { RiFacebookFill } from 'react-icons/ri';

/**
* @author
* @class DenSocialMedia
**/

class DenSocialMedia extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        const socialMedia = this.props.socialMedia;
        return(
            <div className='my-3'>
                <p className='fs-16 fw-700 text-white-50'>Broadcasting Channels</p>
                <div className='row'>
                    {socialMedia?.map((media,i) => {
                        if(media.isChecked){
                            return(
                                <div className='col-3 my-1' key={i}>
                                    <a href={media.value} target='_blank' rel="noreferrer">
                                        <div className='column-center-align-div me-2'>
                                            <img src={media.denUrl} alt='' className='cursor-pointer' style={{width:'32px' }} />
                                            <span className='fs-10 fw-500 text-white'>{media.id}</span>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    })
                    }
                </div>
            </div>
        )
    }
}

DenSocialMedia.propTypes = {}
export default DenSocialMedia