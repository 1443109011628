import { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//styles and icons
import { BsInfoCircle } from 'react-icons/bs';
import '../../../../styles/Tournaments.css';
import { FaUserCircle } from 'react-icons/fa';
import { COMMISSION_QUESTION_TYPE } from '../../../../utils/enums.utils';

export default class EntryPaidPrizeCF extends Component {
    constructor(props) {
        super(props)
        this.localObject = props.localObject;
        this.state = {
            kycVerified: true,
            entryFeeAmount: props?.initialValues?.entryFeeAmount ? props?.initialValues?.entryFeeAmount : ( props.localObject?.entryFeeAmount && props.id == props.localObject?.id) ? props.localObject.entryFeeAmount : '',
            maxParticipants: props.maxParticipants,
            entryCommission: props?.initialValues?.entryCommission ? props?.initialValues?.entryCommission : ( props.localObject?.entryCommission && props.id == props.localObject?.id) ? props.localObject.entryCommission : 0,
            espotzCommission: props.commissionValues ? props.commissionValues.questions[COMMISSION_QUESTION_TYPE.REGULAR_CF_TC] : 10,
            buyEC: props?.initialValues?.buyEC ? props?.initialValues?.buyEC : '',
            moneyCollectedPerEntryFee: props?.initialValues?.moneyCollectedPerEntryFee ? props?.initialValues?.moneyCollectedPerEntryFee : '',
            prizePoolAmount: props?.initialValues?.prizePoolAmount ? props?.initialValues?.prizePoolAmount : ''
        }
    }

    componentDidMount() {}

    componentWillReceiveProps(newProps){
        this.setState({
            entryFeeAmount: newProps?.initialValues?.entryFeeAmount ? newProps?.initialValues?.entryFeeAmount : ( newProps.localObject?.entryFeeAmount && newProps.id == newProps.localObject?.id) ? newProps.localObject.entryFeeAmount : '',
            maxParticipants: newProps.maxParticipants,
            entryCommission: newProps?.initialValues?.entryCommission ? newProps?.initialValues?.entryCommission : ( newProps.localObject?.entryCommission && newProps.id == newProps.localObject?.id) ? newProps.localObject.entryCommission : '',
            espotzCommission: 10,
            buyEC: newProps?.initialValues?.buyEC ? newProps?.initialValues?.buyEC : '',
            moneyCollectedPerEntryFee: newProps?.initialValues?.moneyCollectedPerEntryFee ? newProps?.initialValues?.moneyCollectedPerEntryFee : '',
            prizePoolAmount: newProps?.initialValues?.prizePoolAmount ? newProps?.initialValues?.prizePoolAmount : ''
        });
    }

    updateDetails = (e) => {
        e.preventDefault();
        let {maxParticipants, entryCommission, espotzCommission, moneyCollectedPerEntryFee, prizePoolAmount} = this.state;
        moneyCollectedPerEntryFee = (100-entryCommission - espotzCommission)*(e.target.value)/100;
        prizePoolAmount = maxParticipants*moneyCollectedPerEntryFee;
        //console.log(`entryFeeAmount-${e.target.value}  moneyCollectedPerEntryFee-${moneyCollectedPerEntryFee}  prizePoolAmount-${prizePoolAmount}`);
        this.localObject['entryFeeAmount'] = e.target.value;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        this.setState({
            entryFeeAmount: e.target.value,
            moneyCollectedPerEntryFee,
            prizePoolAmount
        })
    }

    calculateFields = (el) => {
        let {entryFeeAmount, maxParticipants, espotzCommission, moneyCollectedPerEntryFee, prizePoolAmount} = this.state;
        moneyCollectedPerEntryFee = (100- el - espotzCommission)*(entryFeeAmount)/100;
        prizePoolAmount = maxParticipants*moneyCollectedPerEntryFee;
        //console.log(`entryCommission-${el}  moneyCollectedPerEntryFee-${moneyCollectedPerEntryFee}  prizePoolAmount-${prizePoolAmount}`);
        this.localObject['entryCommission'] = el;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        this.setState({
            entryCommission: el,
            moneyCollectedPerEntryFee,
            prizePoolAmount
        })
    }

    validateTOCommission = (val) => {
        let error;
        if(val + this.state.espotzCommission > 40){
            error = `Max available commission is ${40 - this.state.espotzCommission}%`;
        }
        this.calculateFields(val);
        return error;
    }

    validateMaxParticipants = (val) => {
        let error;
        if(val >  this.state.maxParticipants ){
            error = `Max participants cannot be more than ${this.state.maxParticipants}`;
        }
        return error;
    }

    passDetails = (obj) => {
        this.props.onSubmitData(obj)
    }

    render() {
        return (
            <section className="">
                <Formik
                    enableReinitialize
                    initialValues={{
                        entryFeeAmount: this.state.entryFeeAmount,
                        maxParticipants: this.props.maxParticipants,
                        entryCommission: this.state.entryCommission,
                        espotzCommission: this.state.espotzCommission,
                        buyEC: this.props?.initialValues?.buyEC ? this.props?.initialValues?.buyEC : '',
                        moneyCollectedPerEntryFee: this.state.moneyCollectedPerEntryFee,
                        prizePoolAmount: 0
                    }}
                    validationSchema={Yup.object({
                        entryFeeAmount: Yup.number().integer().required('Enter Entry Fee Amount').min(0,'Cannot be less than Zero'),
                        maxParticipants: Yup.number().integer(),
                        espotzCommission: Yup.number(),
                        moneyCollectedPerEntryFee: Yup.number().required('*required'),
                        prizePoolAmount: Yup.number()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.passDetails(values)
                            setSubmitting(false);
                        }, 400);
                    }}>
                    <Form className="row mt-3" autoComplete="off">
                        {/* Entry Fee Amount */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Entry Fee Amount</label>
                            <div className="">
                                <Field
                                    name='entryFeeAmount' type="number"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 fw-500 height-45"
                                    placeholder="Enter in EC"
                                    onKeyUp={(e)=>this.updateDetails(e)}
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="entryFeeAmount" className="error-msg" />
                        </div>
                        {/* Max Participants */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Max Participants</label>
                            <div className="">
                                <FaUserCircle className='btn-size-20 ms-2 position-absolute' style={{marginTop:'11px'}} />
                                <Field
                                    type="number" name='maxParticipants'
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-info fs-14 fw-500 height-45"
                                    placeholder="Enter in EC"
                                    validate={this.validateMaxParticipants}
                                    disabled
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="maxParticipants" className="error-msg" />
                        </div>
                        {/* Entry Fee Amount */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Enter Commission %</label>
                            <div className="">
                                <Field
                                    name='entryCommission' type="number"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 fw-500 height-45"
                                    placeholder="Enter in %"
                                    validate={this.validateTOCommission}
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="entryCommission" className="error-msg" />
                        </div>
                        {/* Espotz Commission */}
                        <div className='col-lg-6'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <label className="fs-14 fw-600 text-white">Espotz Commission(in %)</label>
                                <Field
                                    name='espotzCommission' type='number'
                                    className='purple-field d-flex align-items-center w-25 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    placeholder='in %' disabled
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="espotzCommission" className="error-msg" />
                        </div>
                        <div className='col-lg-6 d-flex justify-content-between align-items-center my-3'>
                            <label className="fs-14 fw-600 text-white">Espotz Commission</label>
                            <input
                                className='purple-field d-flex align-items-center w-50 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                value={this.state.espotzCommission*this.state.entryFeeAmount/100}
                                min={0} step={1} onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        {/* Buy EC */}
                        {/* <div>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <div className="d-flex">
                                    <label htmlFor="custom-btn" className="fs-14 fw-600 text-white"> Buy EC </label>
                                    <BsInfoCircle className="btn-size-12 ms-1" />
                                </div>
                                <Field
                                    className='purple-field d-flex align-items-center w-75 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    name='buyEC' type='number'
                                />
                            </div>
                            <ErrorMessage component="span" name="buyEC" className="error-msg" />
                        </div> */}
                        {/*Money Collected Per Entry Fee */}
                        <div className='col-lg-6 my-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="fs-14 fw-600 text-white">Money Collected Per Entry Fee</label>
                                <Field
                                    name='moneyCollectedPerEntryFee' type='number' placeholder='in EC' disabled
                                    className='purple-field d-flex align-items-center w-25 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                />
                            </div>
                            <ErrorMessage component="span" name="moneyCollectedPerEntryFee" className="error-msg" />
                        </div>
                        {/*Total Prize Pool */}
                        {/* <div>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <label className="fs-14 fw-600 text-white">Total Prize Pool</label>
                                <Field
                                    name='prizePoolAmount' type='number' placeholder='in EC'
                                    className='purple-field d-flex align-items-center w-50 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    disabled
                                />
                            </div>
                            <ErrorMessage component="span" name="prizePoolAmount" className="error-msg" />
                        </div> */}
                        <button className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-2'>
                            KYC Verification
                        </button>
                        {/* <button
                            className='tournament-btn category-btn w-100 my-2'
                            type='submit'
                            disabled={this.state.kycVerified ? false : true}
                            style={{ opacity: this.state.kycVerified ? 1 : 0.6 }}>
                            Pay
                        </button> */}
                        <div className='col-lg-6'>
                        <button type='submit' className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-4 mt-lg-2' >
                            Next
                        </button>
                        </div>
                    </Form>
                </Formik>
            </section>
        )
    }
}
