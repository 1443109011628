import { Component } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsArrowLeftShort } from "react-icons/bs";
import participantAvtar from "../../images/participantAvtar.jpg";
import formInfoIc from "../../images/icon/formInfo-ic.png";
import TeamApis from '../../helper/teams.api';
import { AxiosResponse, AxiosError } from "axios";
import { errorHandler, publicPrivateEncryptedString } from "../../utils/common.utils";
import Image from '../common/Image';

export default class   ParticipantDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showTeamDetails: true,
            participants: [],
            selectedPlayer: {}
        }
        this.teamApis = new TeamApis()
    }
    componentDidMount() {
        if (this.props.type === 'TEAM') {
            this.getData()
        } else {
            this.setState({ selectedPlayer: this.props.details, showTeamDetails: false })
        }
    }
    
    getData = () => {
        const data = {
            _id: this.props.details._id
        }
        this.props.updateLoader(true);
        this.teamApis.getTeams(data).then(
            (res: AxiosResponse) => {
                this.setState({ participants: res.data.result[0].members.players })
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        );
    }

    getPublicPrivateEncryptedString = (key,value) => {
        var params = this.props.formConfig.filter((field) => key === field.label)
        if(params[0] && params[0].value === "PRIVATE"){
            return publicPrivateEncryptedString(value);
        }else{
            return value;
        }
    }

    render() {
       
        return (
            <div className="p-3">
                {(!this.state.showTeamDetails && this.props.type === 'TEAM') && <BsArrowLeftShort className="text-white btn-size-25" onClick={() => this.setState({ showTeamDetails: true })} />}
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px' }} onClick={() => this.props.onClose()} />
                {this.state.showTeamDetails ?
                    <div className="row">
                        {
                            this.props.type === 'TEAM' &&
                            <div className='text-center'>
                                {/* <img src={this.props.details.logo.url} className="rounded-circle" alt='team logo' style={{ height: '100px', width: '100px' }} /> */}
                                <Image src={this.props.details.logo.url} param={this.props.details.name} className='rounded-circle' height='100px' width='100px' />
                                <h1 className="text-white fs-20 fw-bold mt-2 mb-3">{this.props.details.name}</h1>
                            </div>
                        }
                        {
                            !this.state.participants?.length &&
                            <div className="text-center text-white fw-500 mt-4 mb-2">
                                Players yet to join
                            </div>
                        }
                        {
                            this.state.participants.map((el, i) => {
                                if (el !== null){
                                    return (
                                        <div className='col-lg-12 col-md-12 col-12 d-block' key={i}>
                                            <div className="row mulberry-purple-card align-items-center py-2 mb-2 mx-1">
                                                <div className='col-3'>
                                                    {/* <img src={el?.profilePicture?.url} className="rounded-circle mx-2 btn-size-32" alt='team logo' /> */}
                                                    <Image src={el?.profilePicture?.url} param={el?.inGameName} className='rounded-circle mx-2 btn-size-32' />
                                                </div>
                                                <div className='col-7'>
                                                    <p className="fs-16 fw-bold text-white my-1 text-truncate">{el?.inGameName}</p>
                                                </div>
                                                <div className='col-2'>
                                                    <button
                                                        className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32 white-round-btn"
                                                        onClick={() => this.setState({ selectedPlayer: el, showTeamDetails: false })} >
                                                        <img src={formInfoIc} className="rounded-none btn-size-15" alt="" />
                                                    </button>
                                                </div>
                                            </div>
    
                                        </div>
                                    )
                                }
                            })
                        }
                        {/* <div>
                            <button className='white-flat-btn fs-16 fw-600 px-3 py-1 d-flex mx-auto'>Ban</button>
                        </div> */}
                    </div>
                    :
                    <div className="">
                        <img src={this.state.selectedPlayer.profilePicture?.url} className="rounded-circle d-block m-auto" alt='team logo' width={'80px'} height={'80px'} />
                        <h1 className="text-white fs-20 fw-600 my-2 w-100 text-truncate text-center">{this.state.selectedPlayer?.inGameName}</h1>
                        {/* Player Details */}
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <p className="fs-12 fw-600 text-white-50 mb-1">DISCORD ID</p>
                                <p className="fs-14 fw-600 text-white mb-3 text-truncate">
                                    {this.props.formConfig? this.getPublicPrivateEncryptedString('Discord ID',this.state.selectedPlayer?.inDiscordId) : this.state.selectedPlayer?.inDiscordId }
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <p className="fs-12 fw-600 text-white-50 mb-1">MOBILE NUMBER</p>
                                <p className="fs-14 fw-600 text-white mb-3 text-truncate">
                                {this.props.formConfig? this.getPublicPrivateEncryptedString('Mobile Number',this.state.selectedPlayer?.mobileNo?.number) : this.state.selectedPlayer?.mobileNo?.number }
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <p className="fs-12 fw-600 text-white-50 mb-1">IN_GAME NAME</p>
                                <p className="fs-14 fw-600 text-white mb-3 text-truncate">
                                    {this.props.formConfig? this.getPublicPrivateEncryptedString('In-Game Name',this.state.selectedPlayer?.inGameName) : this.state.selectedPlayer?.inGameName }
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <p className="fs-12 fw-600 text-white-50 mb-1">IN_GAME ID</p>
                                <p className="fs-14 fw-600 text-white mb-3 text-truncate">
                                    {this.props.formConfig? this.getPublicPrivateEncryptedString('In-Game ID',this.state.selectedPlayer?.inGameId) : this.state.selectedPlayer?.inGameId }
                                </p>
                            </div>

                            <div className="col-lg-12 col-md-12 col-12">
                                <p className="fs-12 fw-600 text-white-50 mb-1">Email Address</p>
                                <p className="fs-14 fw-600 text-white mb-3 text-truncate">
                                    {this.state.selectedPlayer?.email}
                                </p>
                            </div>
                            {/* <div className="my-1">
                                <p className="fs-10 fw-600 text-white-50 mb-2">CUSTOM DETAILS</p>
                                <p className="fs-14 fw-600 text-white">IF ANY</p>
                            </div> */}
                        </div>
                        {/* <button className='white-flat-btn fs-16 fw-600 px-3 py-1 d-block m-auto'>Ban</button> */}
                    </div>
                }
            </div>
        )
    }
}
