import React, { Component } from "react";
import "../../../../../styles/brackets.css";
import "../../../../../styles/Tournaments.css";
import TournamentHeader from "../../common/TournamentHeader";
import structureIc from '../../../../../images/icon/stageImg.png';
import swapIc from '../../../../../images/icon/swap-ic.png';
import timestampIc from '../../../../../images/icon/timestamp-ic.png';

import { Modal } from "react-bootstrap";
import { calculateMatches, errorHandler, getMarginTopForRounds, getMonthAndDate} from "../../../../../utils/common.utils";
import MatchInvite from "../../../../popup/MatchInvite";
import { toast } from 'material-react-toastify';
import MatchesApis from "../../../../../helper/matches.api";
import SingleBrackets from "../../../../common/SingleBrackets";
import Seeding from "../Seeding";
import SeedingSwitch from "../../common/SeedingSwitch";
import NoRecordFound from "../../../../common/NoRecordFound";

export default class SingleElimination extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.state.tournamentDetails,
      structure: props.state.structure,
      stageMatchDetails: props.state.tournamentDetails.stages[this.props.index].groups[0],
      scale: 1.1,
      openInvite: false,
      matchId: -1,
      selectedPlayers: [],
      isSwappingEnabled: false,
      enableSeeding: false
    };
    this.prevValue = 0;
    this.marginTopDiv = 35;
    this.maxWidth = 0;
    this.isSwapped = false;

    this.matchesApis = new MatchesApis();
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  componentWillReceiveProps(newProps) {
      this.setState({
        tournamentDetails: newProps.state.tournamentDetails,
        structure: newProps.state.structure,
      });
  }

  

  onClickOpenInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite
    });
  }

  openInviteModal = (e, matchDetail, matchIndex, matchNo) => {
    e.preventDefault();
    this.setState({
      openInvite: true,
      matchId: matchIndex
    });
  }

  enableDisableSwapping = () => {
    this.setState({
      isSwappingEnabled: !this.state.isSwappingEnabled,
      selectedPlayers: []
    });
  }

  handleInputChange = (event, matchDetails, roundNumber, matchNumber) => {
    const target = event.target;
    const isChecked = target.type === 'checkbox' ? target.checked : target.value;
    const playerID = target.id;

    var selectedPlayers = this.state.selectedPlayers;
    if (isChecked) {
      if (selectedPlayers.length == 2) {
        toast.warning("At a time only two players can be swapped");
        target.checked = false;
      } else {
        selectedPlayers.push({
          teamID: playerID,
          matchDetails: matchDetails,
          roundNumber: roundNumber,
          matchNumber: matchNumber
        });
        this.setState({
          selectedPlayers: selectedPlayers
        });
      }
    } else if (selectedPlayers.length > 0) {
      selectedPlayers = selectedPlayers.filter(function (obj) { return obj.teamID !== playerID });
      this.setState({
        selectedPlayers: selectedPlayers
      });
    }

  }

  swapPositions = () => {
    this.isSwapped = true;
    var selectedPlayers = this.state.selectedPlayers;
    
    if (selectedPlayers[0].matchDetails._id === selectedPlayers[1].matchDetails._id) {
      toast.warning("You can not swipe two players within a same match.");
    }else {
      this.props.updateLoader(true);
      var updatedMatches = [];

      selectedPlayers.forEach((player, index) => {
        var participants = [];
        player.matchDetails.participants.forEach((teamDetails) => { participants.push(teamDetails._id) });
        player.matchDetails.participants = participants;
        selectedPlayers[index] = player;
        updatedMatches.push(player.matchDetails);
      });

      updatedMatches[0].participants = updatedMatches[0].participants.map( (team) => { return team === selectedPlayers[0].teamID ? selectedPlayers[1].teamID : team });
      updatedMatches[1].participants = updatedMatches[1].participants.map( (team) => { return team === selectedPlayers[1].teamID ? selectedPlayers[0].teamID : team });

      this.matchesApis.swapParticipants(updatedMatches).then(
        (res: AxiosResponse) => {
          this.enableDisableSwapping(); 
          console.log("res.data.result.stages[this.props.index].groups[0] : ",res.data.result.stages[this.props.index].groups[0])
          this.setState({
            tournamentDetails: res.data.result,
            stageMatchDetails: res.data.result.stages[this.props.index].groups[0],
          });
          this.props.updateLoader(false);
          toast.success("Participants Swapped Successfully");
        }
      ).catch(
        (err: AxiosError) => {
          this.props.updateLoader(false);
          errorHandler(err);
        }
      );
    }

  }

  editTimeSlot = () => {
    this.props.history.push(`/organization/${this.props.orgId}/tournament/${this.props.id}/overview/structure/tournament-stages/${this.props.index}/slot-details/0`);
  }

  closeSeeding = (tournamentDetails,updatedStage) =>{
    tournamentDetails.stages[this.props.index] = updatedStage;
    console.log("-- closeSeeding :: ",tournamentDetails)
    this.setState({ 
      enableSeeding: false,
      tournamentDetails: tournamentDetails,
      stageMatchDetails: updatedStage.groups[0]
    });
  }

  enableDisableSeeding = () =>{
    this.setState({ enableSeeding: !this.state.enableSeeding });
  }


  openInviteModal2 = () =>{
    this.setState({
      openInvite: true,
      matchId: null
    });
  }


  render() {

    if ((this.state.tournamentDetails?.stages?.length > 0) && (this.state.tournamentDetails.stages[this.props.index])) {
      const showBrackets = ((this.state.tournamentDetails && this.state.tournamentDetails.stages[this.props.index]) ? true : false);
      const enableSeedingFilter = (parseInt(this.props.index) === 0);
      var pairs = 2;
      var threshold = 1;
      if (showBrackets) {
        pairs = (this.state.tournamentDetails.stages[this.props.index].maxParticipants / 2)
        threshold = (this.state.tournamentDetails.stages[this.props.index].qualificationThreshold / 2)
      }

      const pop = ((!this.state.tournamentDetails.stages[this.props.index].includeThirdPlace && this.state.tournamentDetails.stages[this.props.index].qualificationThreshold !== 1) ? true : false)
      var structure = calculateMatches(pairs, threshold, pop, this.state.tournamentDetails.stages[this.props.index].includeThirdPlace, false);

      return (
        <section className="body-section">

          {/* Sub-Header Section */}
          <div className="w-100 p-2 position-fixed pt-lg-3" style={{zIndex:'1', background:'#200e45'}}>
            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading={showBrackets ? this.state.tournamentDetails.stages[this.props.index].name : "Stage Name"} subHeading={<h1 className="stage-sub-heading text-center">( Single Elimination )</h1>} history={this.props.history} />
            <div className='d-flex my-2 add-time justify-content-center'>
              {this.state.selectedPlayers.length == 2 ?
                <button className="add-btn fs-12 text-black me-2 mb-0 fw-600 rounded-15" onClick={this.swapPositions}>
                  <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> Swap Position
                </button>
                :
                <button disabled={this.state.enableSeeding} className="fs-12 text-black me-2 add-btn mb-0 fw-600 rounded-15" onClick={this.enableDisableSwapping}>
                  <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> {this.state.isSwappingEnabled ? "Disable Swapping" : "Enable Swapping"}
                </button>
              }
              <button type="button" onClick={this.editTimeSlot} className="add-btn fs-12 text-black mb-0 fw-600 rounded-15">
                <img src={timestampIc} className='btn-size-20 me-2 mb-1' alt=''/> Edit Time Slot
              </button>
            </div>
            {/* Switch between seeding & brackets structure */}
            {enableSeedingFilter ? <SeedingSwitch enableDisableSeeding={this.enableDisableSeeding} enableSeeding={this.state.enableSeeding} /> : null}
            
          </div>
         
          {/* Brackets Section */}
          { this.state.enableSeeding ?
              <Seeding marginTopDiv='seeding-page' closeSeeding={this.closeSeeding} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.tournamentDetails?.stages[this.props.index]} />
              :
              <SingleBrackets 
                structure={structure} 
                includeThirdPlace={this.state.tournamentDetails?.stages[this.props.index]?.includeThirdPlace} 
                isSwappingEnabled={this.state.isSwappingEnabled} 
                handleInputChange={this.handleInputChange} 
                openInviteModal={this.openInviteModal} 
                stageMatchDetails={this.state.stageMatchDetails} 
                isTournamentOperator={true}
                tournamentDetails={this.state.tournamentDetails}
                index={this.props.index}
                stageDetails={this.state.tournamentDetails?.stages[(parseInt(this.props.index)) -1]}
                openInviteModal2={this.openInviteModal2}
                marginTopDiv={enableSeedingFilter ? 'mt-10' : 'mt-5'}
              /> 
          }

          {/* Tournament Invitation Section */}
          <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
            <Modal.Body>
              <MatchInvite isPaidTournament={this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee} participantType={this.state.tournamentDetails?.participantType} onClickOpenInvite={this.onClickOpenInvite} tournamentId={this.props.id} stageId={this.props.index} matchId={this.state.matchId} gameId={this.state.tournamentDetails?.gameId} choice="Send Invite" />
            </Modal.Body>
          </Modal>

        </section>
      );
    } else {
      return (
        <NoRecordFound />
      );
    }

  }
}
