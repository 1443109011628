import { Component } from 'react'

export default class AgeRestrictionsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
      }

     

    render() {
        return (
            <div className="logout-modal position-fixed rounded-15 p-4">
                <p className="fs-20 fw-700 text-white text-center mb-3">Are you 18+ ?</p>
                <p className="fs-14 fw-600 text-white-50  text-center mb-3">By continuing you agree that your are 18 years old or older.</p>
                <div className="d-flex justify-content-center">
                    <button className="white-flat-btn fw-bold py-1 px-4 m-auto mb-2 me-2" >Yes</button>
                    <button className="white-flat-btn fw-bold py-1 px-4 m-auto mb-2 ms-2" onClick={(e) => this.props.resetAndUpdateFlag(false, 'openRestricitonsModal', false)}>No</button>
                </div>
            </div>
        );
    }
}