import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import stages from "../../../images/icon/stages.png";
import "../../../styles/Tournaments.css";
import TournamentHeader from "./common/TournamentHeader";
import { errorHandler, getTournamentData } from "../../../utils/common.utils";
import TournamentApis from "../../../helper/tournament.api";
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';

class TournamentStages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : {},
      stageTypes: []
    };

    this.tournamentApis = new TournamentApis();

    if (!this.props?.tournamentData?._id) {
      getTournamentData(this.props.match.params.id, this.setData)
    }
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
    this.getData();
  }

  getData = () => {
    this.props.updateLoader(true);
    const data = {
      competitionType: this.state.tournamentDetails?.competitionType
    };

    this.tournamentApis.getStageTypes(data).then(
      (res: AxiosResponse) => {
        this.setState({
          stageTypes: res.data.result
        })
        this.props.updateLoader(false);
      }
    ).catch(
      (err: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  // when data not came from location.state it returns data from API
  setData = (values) => {
    if (values) {
      this.setState({
        tournamentDetails: values,
      })
      this.props.dispatchData(values)
    } else {
      this.props.history.push('/pageNotFound')
    }
  }

  getStagesOptions = () => {



    var stageTypes = this.state.stageTypes;

    if (this.state.tournamentDetails?.stages?.length > 0) {
      var targetPath = this.state.tournamentDetails?.stages[0]?.path;

      if (targetPath === 'ffa-flexible-stage') {
        stageTypes = stageTypes.filter((stage) => stage.path === targetPath);
      } else {
        stageTypes = stageTypes.filter((stage) => stage.path !== 'ffa-flexible-stage');
      }
    }

    return stageTypes.map((stage, i) => {
      return (
        <div className="col-lg-6 col-md-6 col-12" key={i}>
          <div className="category-btn mb-3 rounded-15 py-5">
            <NavLink className="text-white"
              to={{
                pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview/structure/tournament-stages/${stage.path}`,
                state: { isConfig: true, isUpdate: false, path: stage.path },
              }} >
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="fs-20 fw-bold ms-4"> {stage.stageName} </h6>
                <img className="me-3" width={'60px'} src={stage.stageAvatar.url} alt="" />
              </div>
            </NavLink>
          </div>
        </div>
      )
    });

  }

  render() {
    //console.log("this.state.tournamentDetails : ",this.state.tournamentDetails.stages.length ===)
    return (
      <div className="body-section clanView-page tournament-stage">
        <div className="container p-3 mt-lg-3">
          <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={stages} heading="Stage" subHeading={null} history={this.props.history} />
          <div className="row py-3 mt-3 mt-lg-5">
            {this.getStagesOptions()}
          </div>
        </div>
      </div >
    );
  }
}
export default (connect(mapStateToProps, mapDispatchToProps))(TournamentStages)
