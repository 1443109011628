import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class BettingApis {
    queryParams(params) {
        return Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    getBettingStatements = (matchId) => {
        return this.init().get(`/private/api/v1/bettingStatement/getBettingStatements/${matchId}`);
    };


    addUpdateBettingStatementToMatch = (id, data, selectedIndex) => {
        const queryParam = (selectedIndex ? `?_id=${selectedIndex}` : '')
        return this.init().post(`/private/api/v1/bettingStatement/addUpdateBettingStatementToMatch/${id+queryParam}`, data);
    }

    deleteBettingDetails = (statementId) =>{
        return this.init().delete(`/private/api/v1/bettingStatement/deleteBettingDetails/${statementId}`);
    }

    payStatementEC = (matchId,index) => {
        return this.init().get(`/private/api/v1/bettingStatement/payStatementEC/${matchId}?playoffFormatIndex=${index}`);
    };

    getUpcomingBets = (tournamentId) => {
        return this.init().get(`/private/api/v1/bettingStatement/getUpcomingBets/${tournamentId}`);
    };

    getAllUpcomingBets = (queryParam) => {
        return this.init().get(`/private/api/v1/bettingStatement/getAllUpcomingBets${queryParam}`);
    };

    // To get bet added by user
    getUserBets = (queryParam) => {
        return this.init().get(`/private/api/v1/bets/getbets${queryParam}`);
    };

    // To add bet from user side
    createBet = (data) => {
        return this.init().post(`/private/api/v1/bets/createBets`, data);
    }

    updateMultipleBettingResults = (data) => {
        return this.init().post(`/private/api/v1/bettingStatement/updateMultipleBettingResults`, data);
    }
    
    getPastBets = (queryParam) => {
        return this.init().get(`/private/api/v1/bettingStatement/getUserPastBets${queryParam}`);
    };

    getPastBets2 = (queryParam) => {
        return this.init().get(`/private/api/v1/bettingStatement/getUserAllBets${queryParam}`);
    };

    getUserBetsStats = (queryParam) => {
        return this.init().get(`/private/api/v1/bettingStatement/getMyBetStats/${queryParam}`);
    };

    getUserBetsFromTournament = (queryParam) => {
        return this.init().get(`/private/api/v1/bets/getTournamentBets${queryParam}`);
    };

    getUserBetMatchesFromTournament = (queryParam) => {
        return this.init().get(`/private/api/v1/bettingStatement/getAllUpcomingBetsUserId${queryParam}`);
    };

    getAllTournamentBetMatches = (params) => {
        return this.init().get(`/private/api/v1/bettingStatement/getAlltournamentMatchesBets${params}`);
    };

}