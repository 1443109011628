import React, { Component } from 'react';
// Assets
import { RiFacebookFill, RiDiscordFill } from "react-icons/ri";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { FaTelegramPlane, FaTwitch, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import loco from '../../..//images/games/loco.png';

export default class MicroWebSiteContactUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      microWebSiteData: props.microWebSiteData,
      backgroundColors: props.backgroundColors,
      secondaryTextColors: props.secondaryTextColors,
      background: props.background,
      secondary: props.secondary
    };
  }

  render() {
    return (
      <div>
        {/* Official site details */}
        <div className="d-flex justify-content-center mb-10" style={{ marginTop: '60px' }}>
          <img src={this.state.microWebSiteData?.logo?.url ? this.state.microWebSiteData.logo.url : "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/Screenshot_from_2021-12-07_17-09-15-removebg-preview.png"} alt={""} className="border border-2 border-white rounded-10" style={{height:'100px'}} />
        </div>
        {/* Social media contacts */}
        <div className="d-flex justify-content-center">
          {/* Facebook */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails['facebook Gaming'] ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails['facebook Gaming'] ? `//${this.state.microWebSiteData.contactDetails['facebook Gaming']}` : '#'} >
              <RiFacebookFill className="btn-size-20" style={{ color: `${this.state.background}` }} />  
            </Link>
          </div>
          {/* Instagram */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails?.instagram ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails?.instagram ? `//${this.state.microWebSiteData.contactDetails.instagram}` : '#'} >
              <AiFillInstagram className="btn-size-20" style={{ color: `${this.state.background}` }} />  
            </Link>
          </div>
          {/* Telegram */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails?.telegram ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails?.telegram ? `//${this.state.microWebSiteData.contactDetails.telegram}` : '#'} >
              <FaTelegramPlane className="btn-size-20" style={{ color: `${this.state.background}` }} />
            </Link>
          </div>
          {/* Twitter */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails?.twitter ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails?.twitter ? `//${this.state.microWebSiteData.contactDetails.twitter}` : '#'} >
              <FaTwitter className="btn-size-20" style={{ color: `${this.state.background}` }} />
            </Link>
          </div>
          {/* Discord */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails?.telegram ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails?.discord ? `//${this.state.microWebSiteData.contactDetails.discord}` : '#'} >
              <RiDiscordFill className="btn-size-20" style={{ color: `${this.state.background}` }} />
            </Link>
          </div>
          {/* Youtube */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails?.youtube ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails?.youtube ? `//${this.state.microWebSiteData.contactDetails.youtube}` : '#'} >
              <AiFillYoutube className="btn-size-20" style={{ color: `${this.state.background}` }} />
            </Link>
          </div>
          {/* Twitch */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails?.twitch ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails?.twitch ? `//${this.state.microWebSiteData.contactDetails.twitch}` : '#'} >
              <FaTwitch className="btn-size-20" style={{ color: `${this.state.background}` }} />
            </Link>
          </div>
          {/* Loco */}
          <div className="icon-container" style={{ display: `${this.state.microWebSiteData?.contactDetails?.loco ? 'content' : 'none'}`, backgroundColor: `${this.state.secondary}` }}>
            <Link target="_blank" to={this.state.microWebSiteData?.contactDetails?.loco ? `//${this.state.microWebSiteData.contactDetails.loco}` : '#'} >
              <img src={loco} alt="" className="btn-size-20" style={{ color: `${this.state.background}` }} />
            </Link>
          </div>
        </div>
      </div>
    );
  }
  
}