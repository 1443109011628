import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Accordion, Button } from 'react-bootstrap';
//images
import player from '../../../../../../../images/player.png'
import score from '../../../../../../../images/icon/score.png';
import assists from '../../../../../../../images/icon/assists.png';
import kills from '../../../../../../../images/icon/kills.png';
import scores from '../../../../../../../images/icon/scores.png';
import deaths from '../../../../../../../images/icon/deaths.png';
import disputes from '../../../../../../../images/icon/dispute-ic.png'
import refresh from '../../../../../../../images/games/refresh.png'
import streams from '../../../../../../../images/icon/streaming.png';
import comments from '../../../../../../../images/icon/comments.png';
import upload from '../../../../../../../images/score/upload.png';
//components
import { dateFormat, ellipsis, errorHandler, getTournamentAdditionalData } from '../../../../../../../utils/common.utils';
import Switch from "react-switch"
import TournamentHeader from '../../../../common/TournamentHeader';
import MatchesApis from '../../../../../../../helper/matches.api';
import { connect } from 'react-redux';
import { setUpdateScore } from '../../../../../../../redux/actions/tournametActions';
import { Modal } from 'react-bootstrap';
import ParticipantDetails from "../../../../../../popup/ParticipantDetails";
import { toast } from 'material-react-toastify';
import TeamApis from '../../../../../../../helper/teams.api';

const mapStateToProps = (props) => {
    console.log('redux function::', props)
    return {
        score: props.updateScore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data) => dispatch(setUpdateScore(data))
    }
}

class LeagueOfLegends extends Component {
    constructor(props) {
        super(props)
        this.matchesApis = new MatchesApis();
        this.teamApis = new TeamApis()
        this.state = {
            tournamentDetails: props.tournamentDetails,
            disableChat: false,
            matchDetails: props.score?.results ? props.score : null,
            openDetailsModal: false,
            detailsModalObj: {},
            teamTab: '1',
            allTeamsPlayers: [],
            teamMVP: null,
            matchMVP: null,
            results: []
        }
        console.log('MatchesDuelScoreUpdate props ---', props);
        console.log('tournament ID ---', props.id);
        getTournamentAdditionalData(props.id, this.setData);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ tournamentDetails: newProps.tournamentDetails });
    }

    showDetails = (data) => {
        this.setState({
            detailsModalObj: data,
            openDetailsModal: true
        })
    }

    async getMatchDetails(matchId, competitionType, participantType) {
        const gameNo = parseInt(this.props.gameNo)
        console.log("step 2")
        var data = {
            ids: [matchId]
        }
        this.props.updateLoader(true);
        await this.matchesApis.getMultipleMatches(data).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                if (competitionType === 'DUEL' && participantType === 'TEAM') {
                    this.getTeamPlayers(response[0].participants)
                } else if (competitionType === 'DUEL' && participantType === 'SINGLE') {
                    this.setState({ allTeamsPlayers: response[0].participants })
                }
                //console.log("Match Data : ", response);
                this.setState({
                    matchDetails: response[0],
                    results: response[0].results[gameNo],
                    teamMVP : response[0].results[this.props.gameNo][0].teamMVP ? response[0].results[this.props.gameNo][0].teamMVP : null,
                    matchMVP : response[0].results[this.props.gameNo][0].matchMVP ? response[0].results[this.props.gameNo][0].matchMVP : null,

                })
                // this.updateMatchDetails(response[0], response[0]['this.state.matchDetails?.participants'], joinTeamId, response[0]['maxParticipantsPerMatch']);
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    getTeamPlayers = async (participants) => {

        this.props.updateLoader(true);
        let allTeamsParticipants = []
        for (let i = 0; i < participants.length; i++) {
            const data = {
                _id: participants[i]?._id
            }
            await this.teamApis.getTeams(data).then(
                (res: AxiosResponse) => {
                    for (let j = 0; j < res.data.result[0].members.players.length; j++) {
                        allTeamsParticipants.push(res.data.result[0].members.players[j])
                    }
                    if (i === (participants.length - 1)) {
                        this.setState({ allTeamsPlayers: allTeamsParticipants })
                        this.props.updateLoader(false);
                    }
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err);
                }
            );
        }
    }

    setData = (values) => {
        console.log('value ----------------', values);
        if (values) {
            // if (!this.props?.score?.results) {
            //     console.log('step 1')
            this.getMatchDetails(this.props.matchId, values.competitionType, values.participantType);
            // }
            // if (values.participantType === 'TEAM') {

            // }
            this.setState({ tournamentDetails: values });
            this.props.updateLoader(false);
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    updateScore = (data?, key?) => {
        console.log('state ::', this.state);
        if(this.state.matchMVP === null || this.state.matchMVP === false) {
            toast.error('Please select match MVP');
            return false;
        }
        if( this.state.tournamentDetails.participantType === 'TEAM' && (this.state.teamMVP === null || this.state.teamMVP === false)){
            toast.error('Please select team MVP');
            return false;
        }
        if(!data[this.props.gameNo][0].isWinner && !data[this.props.gameNo][1].isWinner){
            toast.error('Please select a winner');
            return false;
        }
        data[this.props.gameNo][0].resultAdded = true;
        data[this.props.gameNo][1].resultAdded = true;
        data[this.props.gameNo][0].matchMVP = this.state.matchMVP;
        data[this.props.gameNo][0].teamMVP = this.state.teamMVP;
        data[this.props.gameNo][1].matchMVP = this.state.matchMVP;
        data[this.props.gameNo][1].teamMVP = this.state.teamMVP;
        let result = { results: data };
        this.props.updateLoader(true);
        this.matchesApis.updateMatchResults(this.props.matchId, result).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                //this.props.history.goBack()
                //this.setState({matchDetails : response})
                this.getMatchDetails(this.props.matchId);
                this.props.updateLoader(false);
                if (key) {
                    this.props.history.goBack();
                    toast.success('Match result updated successfully');
                }
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    tempSaveValuesTeam = (participantIndex, playerIndex, key, value) => {
        console.log(`team ${participantIndex} and player ${playerIndex} has ${key} == ${value}`);
        let results = this.state.results;
        if (key === 'forfeit'){
            results[participantIndex][key] = value;
        }else{
            results[participantIndex].individualScore[playerIndex][key] = parseInt(value)
        }
        console.log(`----------${results}`);
        this.setState({ results: results })
    }

    getURL = (el) => {
        if (this.state.tournamentDetails?.participantType === 'SINGLE') {
            return el.profilePicture.url;
        } else {
            return el.logo.url;
        }
    }

    declareWinner = (key) => {
        console.log(`winner is ${key}`);
        const data = this.state.matchDetails;
        if(key == 0){
            data.results[this.props.gameNo][0].resultAdded = true;
            data.results[this.props.gameNo][0].isWinner = true;
            data.results[this.props.gameNo][1].resultAdded = true;
            data.results[this.props.gameNo][1].isWinner = false;
        }else{
            data.results[this.props.gameNo][0].resultAdded = true;
            data.results[this.props.gameNo][0].isWinner = false;
            data.results[this.props.gameNo][1].resultAdded = true;
            data.results[this.props.gameNo][1].isWinner = true;
        }
        this.setState({matchDetails: data})
    }

    uploadScreenshot = () => {
        toast.warning('Work in progress');
        return false;
    }

    updateSoloDuelsScore = (val, index, key) => {
        //console.log(`player ${index+1} has ${val} ${key}`)
        let results = this.state.results;
        results[index][key] = val;
        //console.log('$$$$ results', results);
        this.setState({results: results});
    }

    render() {
        //console.log('----', this.state.results);
        const gameNo = parseInt(this.props?.gameNo);
        return (
            <section className="body-section match-duel-score-update">
                <div className="container p-3 mb-5 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={score} heading={`${this.props.title}`} subHeading={null} history={this.props.history} />
                    <div className='d-flex justify-content-between my-3'>
                        <h6 className='fs-16 fw-bold'>Show Down</h6>
                        <h6 className='fs-12 fw-bold' style={{ opacity: "0.7" }}>{dateFormat(this.state.matchDetails?.matchSchedule)}</h6>
                    </div>
                    {/* P1 VS P2 */}
                    {this.state.tournamentDetails ?
                        <div className='mulberry-purple-card rounded-10 my-3 p-2' style={{ boxShadow: '-4px -4px 5px rgb(64 67 88 / 25%), 4px 4px 5px rgb(0 0 0 / 25%)' }}>
                            <h6 className='fs-20 fw-bold text-center my-3' >Game {parseInt(this.props?.gameNo) + 1}</h6>
                            <div className='row d-flex justify-content-between align-items-center pb-4'>
                                {typeof this.state.matchDetails?.participants[0] === 'object' ?
                                    <div className='col-4'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <img src={this.state.matchDetails?.participants[0] ? this.getURL(this.state.matchDetails?.participants[0]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                            </div>
                                            <div className='col-12 text-center'>
                                                <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{ellipsis(this.state.tournamentDetails?.participantType === 'SINGLE' ? this.state.matchDetails?.participants[0]?.inGameName : this.state.matchDetails?.participants[0]?.name, 12)}</h6>
                                            </div>
                                            <div className='col-12 text-center'>
                                            {this.state.matchDetails?.results[gameNo][0]?.isWinner ?
                                                <Button variant='success' className='p-0 w-75'>Winner</Button>
                                                :
                                                <Button variant='secondary' className='p-0 w-75' onClick={() => this.declareWinner(0)}>Winner</Button>
                                            }
                                            </div>
                                            <div className='col-12 text-center'>
                                                <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(this.state.matchDetails?.participants[0]) }}>
                                                    <p className='fs-12 fw-500 my-1'>View Details</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-4'>
                                        <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                                    </div>
                                }
                                <div className='col-4'>
                                    <p className='fs-20 text-info fw-bold text-center'>{`${this.state.matchDetails?.results[gameNo][0]?.score} - ${this.state.matchDetails?.results[gameNo][1]?.score}`}</p>
                                </div>
                                {typeof this.state.matchDetails?.participants[1] === 'object' ?
                                    <div className='col-4'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <img src={this.state.matchDetails?.participants[1] ? this.getURL(this.state.matchDetails?.participants[1]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                            </div>
                                            <div className='col-12 text-center'>
                                                <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{ellipsis(this.state.tournamentDetails?.participantType === 'SINGLE' ? this.state.matchDetails?.participants[1]?.inGameName : this.state.matchDetails?.participants[1]?.name, 12)}</h6>
                                            </div>
                                            <div className='col-12 text-center'>
                                            {this.state.matchDetails?.results[gameNo][1]?.isWinner ?
                                                <Button variant='success' className='p-0 w-75'>Winner</Button>
                                                :
                                                <Button variant='secondary' className='p-0 w-75' onClick={() => this.declareWinner(1)}>Winner</Button>
                                            }
                                            </div>
                                            <div className='col-12 text-center'>
                                                <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(this.state.matchDetails?.participants[1]) }}>
                                                    <p className='fs-12 fw-500 my-1'>View Details</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-4'>
                                        <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                                    </div>
                                }
                            </div>

                            {this.state.tournamentDetails.participantType === 'TEAM' &&
                                <div className='d-flex flex-column mb-2'>
                                    <label htmlFor='teamMVP' className="fs-14 fw-600 text-white">Team MVP</label>
                                    <select id='teamMVP' value={JSON.stringify(this.state.teamMVP)} onChange={(e) => { this.setState({ teamMVP: JSON.parse(e.target.value) }) }} name='matchMVP' className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                                        <option value='' >Select player</option>
                                        {
                                            this.state.allTeamsPlayers.map((el, i) => {
                                                return (
                                                    <option value={JSON.stringify(el)} key={i}>{ellipsis(el?.inGameName,12)}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            }

                            <div className='d-flex flex-column mb-2'>
                                <label htmlFor='matchMVP' className="fs-14 fw-600 text-white">Match MVP</label>
                                <select id='matchMVP' value={JSON.stringify(this.state.matchMVP)} onChange={(e) => { this.setState({ matchMVP: JSON.parse(e.target.value) }) }} name='matchMVP' className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                                    <option value='' >Select player</option>
                                    {
                                        this.state.allTeamsPlayers.map((el, i) => {
                                            return (
                                                <option value={JSON.stringify(el)} key={i}>{ellipsis(el?.inGameName, 12)}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                            <div className="d-flex align-items-center">
                                <hr className="border-1 flex-grow-1" />
                                <div className="d-flex mx-3 align-items-center">
                                    <p className="fs-16 fw-bold text-white-50 mb-0">Scan Game Scorecard(OCR)</p>
                                </div>
                                <hr className="border-1 flex-grow-1" />
                            </div>

                            <button className='white-flat-btn text-center w-100 rounded-10 height-45 my-3 fw-600 px-3' onClick={this.uploadScreenshot}>
                                <img src={upload} alt='' className="me-2"/>
                                Upload Screenshot
                            </button>

                        </div>
                        :
                        null
                    }
                    {/* Score Details */}
                    {/* <div className='mulberry-purple-card shadow-none rounded-10 p-3' style={{ border: '1px solid white' }}> */}
                    <h6 className='fs-16 fw-bold text-center my-3'>API Details to fetch results</h6>
                    <div className="d-flex align-items-center">
                        <hr className="border-1 flex-grow-1" />
                        <div className="d-flex mx-3 align-items-center">
                            <p className="fs-16 fw-bold text-white-50 mb-0">Enter Score</p>
                        </div>
                        <hr className="border-1 flex-grow-1" />
                    </div>
                    {(this.state.tournamentDetails && this.state.matchDetails) ?
                        this.state.tournamentDetails?.participantType === 'SINGLE' ?
                        <div>
                            {Object.values(this.state.matchDetails?.results[gameNo]).map((participant, i) => {
                                if (participant !== null) {
                                    //console.log('result---', this.state.matchDetails?.results[gameNo][i]);
                                    console.log('Game ::',this.state.matchDetails?.results[gameNo])
                                    console.log('participant---', participant);
                                    return (
                                            <div key={i} className='rounded-10 border border-1 border-white p-3 mt-5'>
                                                <div className='d-flex justify-content-center'>
                                                    <img src={this.getURL(participant.participantDetails)} alt='' className="rounded-circle btn-size-45" style={{ marginTop: "-40px" }} />
                                                </div>
                                                <h6 className='fs-16 fw-bold text-center my-3 text-truncate'>{ellipsis(participant?.participantDetails?.inGameName, 12)}</h6>
                                                {/* Kills */}
                                                <div className="form-group my-1">
                                                    <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                    <div className="">
                                                        <input type="number" placeholder="Enter Kills"
                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                            min={0} step={1} onWheel={(e) => e.target.blur()}
                                                            required={true}
                                                            value={participant?.kills}
                                                            onChange={(e) => { this.updateSoloDuelsScore(parseInt(e.target.value), i, 'kills')}}
                                                        />
                                                        <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                    </div>
                                                </div>
                                                {/* Deaths */}
                                                <div className="form-group my-1">
                                                    <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                    <div className="">
                                                        <input type="number" placeholder="Enter Deaths"
                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                            min={0} step={1} onWheel={(e) => e.target.blur()}
                                                            value={participant?.deaths}
                                                            onChange={(e) => { this.updateSoloDuelsScore(parseInt(e.target.value), i, 'deaths')}}
                                                        />
                                                        <img src={deaths} alt="deaths" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                    </div>
                                                </div>
                                                <hr className="" style={{ color: '#FF4D4D'}}/>
                                                <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                                                    <input type='checkbox' className='me-2'
                                                        checked={participant.forfeit ? participant.forfeit : false}
                                                        onChange={(e) => { this.updateSoloDuelsScore( e.target.checked, i, 'forfeit')}}
                                                    />
                                                    <label className='fw-bold my-0'>Forfeit {this.state.matchDetails?.participants[i]?.inGameName}</label>
                                                </div>
                                            </div>
                                    )
                                } else {
                                    return (
                                        <div kei={i} className='mulberry-purple-card shadow-none rounded-10 my-3 border-0'>
                                            No Participant Joined
                                        </div>
                                    )
                                }
                            })}
                        </div>
                            :
                            <div>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <h1 className={`${this.state.teamTab === '1' ? 'matches_border-bottom' : null} fs-16 fw-600 text-white text-center pb-2`} onClick={() => { this.setState({ teamTab: '1' }) }}>{ellipsis(this.state.matchDetails?.participants[0] ? this.state.matchDetails?.participants[0]?.name : 'Vacant', 12)}</h1>
                                    </div>
                                    <div className='col-6'>
                                        <h1 className={`${this.state.teamTab === '2' ? 'matches_border-bottom' : null} fs-16 fw-600 text-white text-center pb-2`} onClick={() => { this.setState({ teamTab: '2' }) }}>{ellipsis(this.state.matchDetails?.participants[1] ? this.state.matchDetails?.participants[1]?.name : 'Vacant', 12)}</h1>
                                    </div>

                                </div>

                                {this.state.teamTab === '1' ?
                                    <div className=''>
                                        <hr className="" style={{ color: '#FF4D4D'}}/>
                                        <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                                            <input type='checkbox' className='me-2'
                                                checked={this.state.results[0]?.forfeit ? this.state.results[0]?.forfeit : false}
                                                onChange={(e) => { this.tempSaveValuesTeam(0, 0, 'forfeit', e.target.checked) }}
                                            />
                                            <label className='fw-bold my-0'>Forfeit {ellipsis(this.state.matchDetails?.participants[0]?.name, 12)}</label>
                                        </div>
                                        {this.state.results[0]?.individualScore ?
                                            this.state.results[0]?.individualScore?.map((player, j) => {
                                                console.log(`player ${j} ::`, player)
                                                return (
                                                    <div className="mt-5" autoComplete="off">
                                                        <div className='mulberry-purple-card shadow-none rounded-10 p-3 border border-1 borer-white'>
                                                            <div className="d-flex justify-content-center">
                                                                <img src={player.participantDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt='' />
                                                                <h6 className='fs-16 fw-bold text-center my-3 text-truncate' >{ellipsis(player.participantDetails.inGameName, 12)}</h6>
                                                            </div>

                                                            {/* P1 Kills */}
                                                            <div className="form-group my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                                <div className="">
                                                                    <input type='number'
                                                                        name="kills" placeholder="Enter Kills"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        value={player.kills}
                                                                        onChange={(e) => { this.tempSaveValuesTeam(0, j, 'kills', e.target.value) }}
                                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                                        required
                                                                    />
                                                                    <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                </div>
                                                            </div>
                                                            {/* P1 Deaths */}
                                                            <div className="form-group my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                                <div className="">
                                                                    <input
                                                                        name="deaths" type="number" placeholder="Enter Deaths"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        value={player.deaths}
                                                                        onChange={(e) => { this.tempSaveValuesTeam(0, j, 'deaths', e.target.value) }}
                                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                                    />
                                                                    <img src={deaths} alt="deaths" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                </div>
                                                            </div>
                                                            {/* P1 Assists */}
                                                            <div className="form-group my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Assists</label>
                                                                <div className="">
                                                                    <input
                                                                        name="assists" type="number" placeholder="Enter Assists"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        value={player.assists}
                                                                        onChange={(e) => { this.tempSaveValuesTeam(0, j, 'assists', e.target.value) }}
                                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                                    />
                                                                    <img src={assists} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div>
                                        <hr className="" style={{ color: '#FF4D4D'}}/>
                                        <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                                            <input type='checkbox' className='me-2'
                                                checked={this.state.results[1]?.forfeit ? this.state.results[1]?.forfeit : false}
                                                onChange={(e) => { this.tempSaveValuesTeam(1, 0, 'forfeit', e.target.checked) }}
                                            />
                                            <label className='fw-bold my-0'>Forfeit {ellipsis(this.state.matchDetails?.participants[1]?.name, 12)}</label>
                                        </div>
                                        {this.state.results[1].individualScore ?
                                            this.state.results[1]?.individualScore?.map((player, j) => {
                                                console.log(`player ${j} ::`, player)
                                                return (

                                                    <div className="mt-5" autoComplete="off">
                                                        <div className='mulberry-purple-card shadow-none rounded-10 p-3 border border-1 borer-white'>
                                                            <div className="d-flex justify-content-center">
                                                                <img src={player.participantDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt='' />
                                                                <h6 className='fs-16 fw-bold text-center my-3 text-truncate' >{ellipsis(player.participantDetails.inGameName, 12)}</h6>
                                                            </div>

                                                            {/* P1 Kills */}
                                                            <div className="form-group my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                                <div className="">
                                                                    <input
                                                                        name="kills" type="number" placeholder="Enter Kills"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        value={player.kills}
                                                                        onChange={(e) => { this.tempSaveValuesTeam(1, j, 'kills', e.target.value) }}
                                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                                    />
                                                                    <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                </div>
                                                            </div>
                                                            {/* P1 Deaths */}
                                                            <div className="form-group my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                                <div className="">
                                                                    <input
                                                                        name="deaths" type="number" placeholder="Enter Deaths"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        value={player.deaths}
                                                                        onChange={(e) => { this.tempSaveValuesTeam(1, j, 'deaths', e.target.value) }}
                                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                                    />
                                                                    <img src={deaths} alt="deaths" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                </div>
                                                            </div>
                                                            {/* P1 Assists */}
                                                            <div className="form-group my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Assists</label>
                                                                <div className="">
                                                                    <input
                                                                        name="assists" type="number" placeholder="Enter Assists"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        value={player.assists}
                                                                        onChange={(e) => { this.tempSaveValuesTeam(1, j, 'assists', e.target.value) }}
                                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                                    />
                                                                    <img src={assists} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div>This slot is currently empty</div>
                                        }
                                    </div>
                                }

                            </div>
                        :
                        null
                    }


                    <div className="contact-toggle d-flex align-items-center rounded-10 height-45 my-3">
                        <p className="fs-16 fw-600 mb-0 flex-grow-1 ps-3">Disable Chat</p>
                        <Switch checked={this.state.disableChat}
                            checkedIcon={false} uncheckedIcon={false} className="me-2"
                            onChange={(e) => this.setState({ disableChat: !this.state.disableChat })}
                        />
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <div className='position-relative d-flex justify-content-center'>
                            <div className='text-center'>
                                <button onClick={() => this.updateScore(this.state.matchDetails?.results, true)} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3'>
                                    <img src={refresh} alt="" />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Update</p>
                            </div>

                            <div className='text-center'>
                                <button type='button' disabled={this.state.disableChat} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-2' onClick={() => {
                                    this.props.history.push(
                                        {
                                            pathname: `/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/chatLobby/${this.props.matchId}`,
                                            state: {
                                                orgId: this.props.orgId,
                                                tournamentDetails: this.props.tournamentDetails
                                            }
                                        }
                                    )
                                }}>
                                    <img src={comments} alt="" className='btn-size-25' />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Chat</p>

                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={() => { this.props.history.push(`/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/dispute-management/${this.props.matchId}`) }}>
                                    <img src={disputes} alt="" className='btn-size-25' />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Disputes</p>

                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={() => { this.props.history.push(`/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/streams`) }}>
                                    <img src={streams} alt="" />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Stream</p>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.openDetailsModal} centered dialogClassName="ClanInvite-modal">
                    <Modal.Body>
                        <ParticipantDetails updateLoader={this.props.updateLoader} details={this.state.detailsModalObj} type={this.state.tournamentDetails?.participantType} onClose={() => { this.setState({ openDetailsModal: false }) }} />
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
};

export default (connect(mapStateToProps, mapDispatchToProps))(LeagueOfLegends);

