import { Component } from "react";
//icons
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsFillPlusCircleFill } from "react-icons/bs";
//css
import '../../styles/Organization.css'
import { CLAN_ORG_ROLES } from "../../utils/enums.utils";

class ClanOrgModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.editDataObj ? props.editDataObj.name : '',
            roles: props.editDataObj ? props.editDataObj.roles : [{role: CLAN_ORG_ROLES.OWNER.VALUE, startDate: '', endDate: '', currentRole: false}]
        };
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            name: nextProps.editDataObj?.name,
            role: nextProps.editDataObj?.roles
        })
    }

    addRole = () => { 
        const roles = this.state.roles;
        const newRole = {role: CLAN_ORG_ROLES.OWNER.VALUE, startDate: '', endDate: '', currentRole: false};
        const updatedRoles = [...roles, newRole];
        console.log(updatedRoles);
        this.setState({roles: updatedRoles});
    }

    updateRoleDetails = (i, key, val) => {
        console.log(`i-${i} key-${key} val-${val}`); 
        const {roles} = this.state;
        roles[i][key] = val;
        console.log(roles);
        this.setState({roles: roles});
    }

    updateName = (e) => { this.setState({name: e.target.value});}

    submitData = (e) => { 
        e.preventDefault();
        var data = null;
        const {name, roles} = this.state;
        data ={name, roles};
        this.props.appendData(data);
    }

    render() {
        //console.log('-----------', this.props);
        return (
            <div style={{maxHeight:'500px', overflow: 'auto'}}>
                <form className="p-3" autoComplete="off" onSubmit={this.submitData}>
                    <p className="fs-18 fw-bold text-white text-center mb-2">{this.props.editDataObj ? 'Edit' :'Add'} {this.props.isClanModal ? 'Clan' : 'Organisation'}</p>
                    <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={()=>this.props.handleClanOrgModal(false, false)} />
                    {/* Name */}
                    <div className="d-flex flex-column my-2">
                        <div className="d-flex justify-content-between">
                            <label htmlFor="" className="fs-14 fw-600 text-white">Name*</label>
                            <label htmlFor="" className="fs-12 fw-600 text-white">{`${this.state.name?.length}/50`}</label>
                        </div>
                        <div className="purple-field d-flex align-items-center">
                            <input
                                type="text"
                                className="d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                autoComplete="off"
                                maxLength={50}
                                style={{ backgroundColor:'transparent', outline: 'none' }}
                                required onChange={(e) => this.updateName(e)}
                                value={this.state.name}
                            />
                        </div>
                    </div>
                    {/* Role */}
                    {this.state.roles?.map((role ,i) => {
                        return(
                            <div className="my-2" key={i}>
                                <div className="d-flex flex-column my-2">
                                    <label htmlFor="facebook" className="fs-14 fw-600 text-white">Designation {i+1}*</label>
                                    <select className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 height-45" value={role.role} onChange={(e)=>this.updateRoleDetails(i, 'role', e.target.value)}>
                                        {Object.keys(CLAN_ORG_ROLES).map((key,i)=>  <option key={i} value={CLAN_ORG_ROLES[key].VALUE}>{CLAN_ORG_ROLES[key].NAME}</option>)}
                                    </select>
                                </div>
                                <div className="d-flex d-flex align-items-center">
                                    <input type='checkbox' checked={role.currentRole ? true : false} className="me-3" onChange={(e)=>this.updateRoleDetails(i, 'currentRole', e.target.checked)} />
                                    <label className="fs-12 fw-600 text-white">I am currently positioned at this designation.</label>
                                </div>
                                <div className="d-flex flex-column my-2">
                                    <label htmlFor="facebook" className="fs-14 fw-600 text-white">Start Date*</label>
                                    <div className="purple-field d-flex align-items-center">
                                        <input
                                            type="date" autoComplete="off"
                                            className="d-flex align-items-center w-100 border-0 px-3 text-white fs-14 height-45"
                                            style={{ backgroundColor:'transparent', outline: 'none' }}
                                            required value={role.startDate} max={role.endDate}
                                            onChange={(e)=>this.updateRoleDetails(i, 'startDate', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-column my-2">
                                    <label htmlFor="facebook" className="fs-14 fw-600 text-white">End Date*</label>
                                    <div className="purple-field d-flex align-items-center">
                                        <input
                                            type="date" autoComplete="off"
                                            className="d-flex align-items-center w-100 border-0 px-3 text-white fs-14 height-45"
                                            style={{ backgroundColor:'transparent', outline: 'none' }}
                                            required value={role.currentRole ? null :role.endDate} min={role.startDate}
                                            onChange={(e)=>this.updateRoleDetails(i, 'endDate', e.target.value)}
                                            disabled={role.currentRole ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <div className="my-3">
                        <div className="black-flat-btn d-flex justify-content-center align-items-center height-45 rounded-10 my-2" onClick={this.addRole}>
                            <BsFillPlusCircleFill className="btn-size-20 ms-3 text-white" />
                            <p className="fs-14 fw-500 text-white ms-3 my-auto">Add another role</p>
                        </div>
                    </div>
                    <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto my-3" type="submit">{this.props.editDataObj ? 'Update' :'Save'}</button>
                </form>
            </div>
        )
    }
}

export default ClanOrgModal