import React, { Component } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { capitalize } from '../../../../utils/common.utils'
import { POST_INTERACTIONS } from '../../../../utils/Images.utils';
import welcomePopupGun from '../../../../images/den/interactions/welcome-popup-gun.png';
import { updateDenProfile, updateDenProfileInLocalStorage } from '../../../../utils/Den.utils';

export default class WelcomToDenCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            denProfile: props.denProfile
        }
    }

    componentDidMount() {}

    componentWillReceiveProps(newProps){
        this.setState({denProfile: newProps.denProfile})
    }


    refreshDenProfile = (result) => {
      this.props.updateState('isSparked',true);
      this.props.updateState('denProfile',result);
      //updateDenProfileInLocalStorage(result);
    }

    render() {
        return (
            <div className='den-welcome-container p-2' >
                <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='fs-20 fw-700 my-0'>{`Welcome to Den, ${this.state.denProfile?.name}!!`}</h4>
                    <AiOutlineClose style={{height:'20px', width:'20px', cursor: 'pointer'}} onClick={() => updateDenProfile(this.props.denProfile._id, {isSparked : true},this.refreshDenProfile)} />
                </div>
                <div className='row my-3'>
                    <div className='col-10'>
                        <p className='fs-14 fw-500'>Step into astonishing world of espotz users, enjoy your complemenatary interaction rewards for signing up with us!</p>
                        <div className='d-flex align-items-center'>
                            {Object.keys(POST_INTERACTIONS.SPARKS).map((key,i)=>{
                                return (
                                    <div key={i} className='d-flex flex-column align-items-center me-2' style={{width:'50px'}}>
                                        <img src={POST_INTERACTIONS.SPARKS[key].BONUS} alt='' className='bonus-popup-icon' />
                                        <span className='fs-14 fw-700'>{capitalize(POST_INTERACTIONS.SPARKS[key].NAME)}</span>
                                        <span className='fs-14 fw-600 text-white-50'>{this.props.denProfile.sparks[POST_INTERACTIONS.SPARKS[key].NAME]}</span>
                                    </div>
                                )
                            })}
                            <div className='d-flex flex-column align-items-center me-2' style={{width:'50px'}}>
                                <img src={POST_INTERACTIONS.HAMMER.BONUS} alt='' className='bonus-popup-icon' />
                                <span className='fs-14 fw-700'>Hammer</span>
                                <span className='fs-14 fw-600 text-white-50'>50</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <img src={welcomePopupGun} alt='' style={{height:'100px'}} />
                    </div>
                </div>
            </div>
        )
    }
}
