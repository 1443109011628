import { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import betWhiteIc from '../../../../../../images/icon/betWhite-ic.png';
import ecIc from '../../../../../../images/icon/ec-ic.png';
import winCupBlue from '../../../../../../images/icon/winCupBlue-ic.png';
import instructionIcon from '../../../../../../images/icon/instruction-ic.png';
import TournamentHeader from '../../../common/TournamentHeader';
import { getMatchDetails } from '../../../../../../utils/Betting.utils';
import { errorHandler, getTournamentData, updateLoader } from '../../../../../../utils/common.utils';
import NoRecordFound from '../../../../../../components/common/NoRecordFound';
import BettingStatementCard from '../score/common/BeatingStatementCard';
import { Modal } from 'react-bootstrap';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import BettingApis from '../../../../../../helper/betting.api';
import { toast } from 'material-react-toastify';
import { ParticipantType } from '../../../../../../utils/enums.utils';

export default class PublishStatement extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: null,
            matchDetails: null,
            selectedBettingStatements: [],
            gameDetails: [],
            openConfirmationModal: false, 
            confirmMessage : { msg: null,  key: null }
        }

        this.bettingApis = new BettingApis();

        getMatchDetails(this.props.match.params.matchId,this.setMatchDetails);
        getTournamentData(this.props.match.params.id, this.setData)
    }

    setData = (response) => {
        this.setState({
            tournamentDetails: response
        })
    }


    setMatchDetails =(response)=>{
      
        var selectedBettingStatements = [];
        response?.matchBettingStatements?.map((statement,i)=>{
            if((statement.playoffFormatIndex === parseInt(this.props.match.params.gameId))){
           
                selectedBettingStatements.push({
                    _id: statement._id,
                    statement: statement.bettingStatement,
                    options: statement.choices,
                    bettingPool: statement.bettingPool,
                    entryFees: statement.entryFees
                });
            }
        });
        this.setState({
            matchDetails: response,
            selectedBettingStatements: selectedBettingStatements, 
            openConfirmationModal: false, 
            confirmMessage : { msg: null,  key: null }
        });
    }

     // Get TO's confirmation before delete a statement
     onClickOpenConfirm = (index) => {

        if(index !== null && index !== undefined){
            this.setState({
                openConfirmationModal: true, 
                confirmMessage : {
                    msg: (
                        <p className="fs-14 fw-600 text-center text-white-50">
                            Are you sure you want to delete this statement from this match's betting ?
                        </p>
                    ),
                    key: index
                }
            });
        }else{
            this.setState({
                openConfirmationModal: false, 
                confirmMessage : { msg: null,  key: null }
            });
        }
    }

     // Delete statement after confirmation
     onConfirmed = (index) =>{
        updateLoader(true);
        this.bettingApis.deleteBettingDetails(this.state.selectedBettingStatements[index]._id).then((res: AxiosResponse) => {
            this.setMatchDetails(res.data.result);
            updateLoader(false);
            getMatchDetails(this.props.match.params.matchId,this.setMatchDetails);
            toast.success("Betting statements deleted successfully");
        }).catch((reason: AxiosError) => {
            updateLoader(false);
            errorHandler(reason);
         });
    }

    render() {
        const isTeamGame = this.state.tournamentDetails?.participantType === ParticipantType.TEAM;
        if(this.state.matchDetails && this.state.tournamentDetails){
            return (
                <section>
                <div className='body-section publish-statement-page'>
                    <div className='container mt-3 mt-lg-4'>
                        <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={betWhiteIc} heading="Published Statements" subHeading={null} history={this.props.history} />
                        <div className='row'>
                            <div className='col-12 mt-3 ms-3'>
                                <h4 className='fs-16 fw-700 text-white '>Total Statements : {this.state.selectedBettingStatements?.length}</h4>
                            </div>         
                            {this.state.selectedBettingStatements.map((statement,i)=>{
                                    return  <BettingStatementCard  isPublished={true} onClickEditStatement={this.onClickEditStatement} onClickOpenConfirm={this.onClickOpenConfirm} key={i} index={i+1} statement={statement} isTeamGame={isTeamGame} />
                                })
                            }
                            <div className='col-12 mt-5'>
                                <div className='text-center'>
                                    <img src={instructionIcon} className='' height={'40px'} alt='' />
                                    <p className='fs-12 fs-lg-16 fw-500 mt-3'>Espotz Commission</p>
                                </div>
                                <div className='mt-3'>
                                    <ul>
                                        <li className='fs-12 fs-lg-16 fw-500 text-start'>Commission will be charged by admin on betting pool when Betting pool {'>'} Ticket Collection.</li>
                                        <li className='fs-12 fs-lg-16 fw-500 text-start mt-1'>Additional commission will be charged by admin on betting pool when Betting pool {'<'} Ticket Collection. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.openConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal confirm={this.state.confirmMessage} confirmed={this.onConfirmed} onClickOpenConfirm={this.onClickOpenConfirm} insteadDeactivate={{}} />
                    </Modal.Body>
                </Modal>
             </section>
            )
        }else{
            return  <NoRecordFound />
        }
      
    }
}