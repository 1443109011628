import React, { Component } from 'react';
import LeftSideBar from '../common/LeftSideBar';
import RightSideBar from '../common/RightSideBar';
import { profile } from '../../../../utils/localstorage.utils';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { DEN_EMPTY_SCREENS, INNER_CIRCLE_ASSETS } from '../../../../utils/Images.utils';
import avatar from '../../../../images/participantAvtar.jpg';
import { DEN } from '../../../../utils/Strings.utils';
import { getDenProfileAchivements, getDenProfilePicture, getDenProfiles } from '../../../../utils/Den.utils';
import Image from '../../../common/Image';

//import { exportComponentAsJPEG } from "react-component-export-image";

import html2canvas from "html2canvas";
import { getDetailsObj } from '../../../../utils/common.utils';
import { Modal } from 'react-bootstrap';
import ShareStats from '../../../popup/ShareStats';

export default class DenInnerCircle extends Component {
  constructor(props) {
    super(props);
    this.state = {
        activeTab: DEN.TABS.INNER_CIRCLE,
        denProfile: null,
        denAchivements: null,
        detailsObj: null,
        imageUrl: null,
        openShare: false,
    };
    this.componentRef = React.createRef();
  }

  componentDidMount(){
    if(this.props.match.params.denId){
        getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile)
    }
  }

  setCurrentUserDenProfile = (result) =>{
      if(result[0]){
          this.setState({
              denProfile: result[0]
          });
          getDenProfileAchivements(result[0]._id, (result)=>{
            this.updateState('denAchivements', result)
          });
          getDetailsObj(result[0], this.setDetailsObj)
      }
  }

  setDetailsObj = (res) => {
    if(res){ 
        this.setState({detailsObj: res})
    }
  }

  updateState = (key, val) => {
    this.setState({
      [key] : val
    })
  }

  getTopThreeView = () => {
    const innerCircle = this.state.denAchivements?.topFans?.filter((user)=> user.totalSpotlights > 0 ); //?.slice(0, 10);

    const sortedInnerCircle  = innerCircle?.sort((a, b) => parseInt(b.totalSpotlights) - parseInt(a.totalSpotlights));

    const innerCircleProfiles = sortedInnerCircle?.map((topFan)=>{
        return {
          from: topFan.from,
          profilePicture: getDenProfilePicture(topFan.from),
          spotlight: topFan.totalSpotlights
        };
    })
    if(innerCircleProfiles?.length === 1){
      return(
        <div className='d-flex justify-content-around my-3'>
          {/* RANK 1 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>1</p>
            <Image src={innerCircleProfiles[0]?.profilePicture?.url} param={innerCircleProfiles[0]?.from?.name? innerCircleProfiles[0].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[0]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[0]?.spotlight}</p>
          </div>
        </div>
      )
    }else if(innerCircleProfiles?.length === 2){
      return(
        <div className='d-flex justify-content-around my-3'>
          {/* RANK 1 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>1</p>
            <Image src={innerCircleProfiles[0]?.profilePicture?.url} param={innerCircleProfiles[0]?.from?.name? innerCircleProfiles[0].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[0]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[0]?.spotlight}</p>
          </div>
          {/* RANK 2 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>2</p>
            <Image src={innerCircleProfiles[1]?.profilePicture?.url} param={innerCircleProfiles[1]?.from?.name? innerCircleProfiles[1].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[1]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[1]?.spotlight}</p>
          </div>
        </div>
      )
    }else{
      return(
        <div className='d-flex justify-content-around my-3'>
          {/* RANK 2 */}
          <div className='text-center' style={{marginTop: '50px'}}>
            {/* <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.RANK_DOWN} alt='' className='me-2' style={{width:'13px'}}/>2</p> */}
            <p className='fs-16 fw-700 my-1'>2</p>
            <Image src={innerCircleProfiles[1]?.profilePicture?.url} param={innerCircleProfiles[1]?.from?.name? innerCircleProfiles[1].from?.name : 'Test' } className='inner-circle-rank-2' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[1]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[1]?.spotlight}</p>
          </div>
          {/* RANK 1 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>1</p>
            <Image src={innerCircleProfiles[0]?.profilePicture?.url} param={innerCircleProfiles[0]?.from?.name? innerCircleProfiles[0].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[0]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[0]?.spotlight}</p>
          </div>
          {/* RANK 3 */}
          <div className='text-center' style={{marginTop: '50px'}}>
            {/* <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.RANK_UP} alt='' className='me-2' style={{width:'13px'}}/>3</p> */}
            <p className='fs-16 fw-700 my-1'>3</p>
            <Image src={innerCircleProfiles[2]?.profilePicture?.url} param={innerCircleProfiles[2]?.from?.name? innerCircleProfiles[2].from?.name : 'Test' } className='inner-circle-rank-3' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[2]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[2]?.spotlight}</p>
          </div>
        </div>
      )
    }
  }

  onShare = () =>{
   // exportComponentAsJPEG(this.componentRef)

    const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
      cropPositionTop: 0,
      cropPositionLeft: 0,
      cropWidth: 1400,
      cropHeigth: 1800
    };

    html2canvas(this.componentRef.current).then(canvas => {
      let croppedCanvas = document.createElement("canvas");
      let croppedCanvasContext = croppedCanvas.getContext("2d");

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeigth;

      croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

      const a = document.createElement("a");
      a.href = croppedCanvas.toDataURL();
      this.setState({imageUrl: a.href, openShare: true});
      //a.download = "receipt.png";
      //a.click();
    });
  };

  toggleOpenShare = () => { this.setState({openShare: !this.state.openShare})}

  getView =()=>{
    if(!this.state.denAchivements?.topFans.length){
      return(
        <div className='col-12 col-lg-6'>
          <div className="inner-circle-container text-center py-3">
            <div className='d-flex align-items-center p-2'>
              <MdOutlineArrowBackIosNew className="cursor-pointer me-2" style={{height:'20px'}} onClick={() => this.props.history.goBack()} />
              <p className='fs-24 fw-700 my-0 flex-grow-1 text-start'>Inner Circle</p>
              <button className='den-profile-btn follow-btn' style={{width: 'auto'}} onClick={() => this.onShare()} >
                <img src={INNER_CIRCLE_ASSETS.SHARE} alt='' className='me-2' style={{width:'20px'}}/> Share
              </button>
            </div>
            <img className="my-3" src={DEN_EMPTY_SCREENS.NO_INNER_CIRCLE} alt={""} />
            <p className='fs-20 fw-500 opacity-75 my-3'>Build your game by posting, commenting, liking posts, might get you some new fans.</p>
          </div>
        </div>
      )
    }
    const innerCircle = this.state.denAchivements?.topFans?.filter((user)=> user.totalSpotlights > 0 ); //?.slice(0, 10);

    const sortedInnerCircle  = innerCircle?.sort((a, b) => parseInt(b.totalSpotlights) - parseInt(a.totalSpotlights));

    const innerCircleProfiles = sortedInnerCircle?.map((topFan)=>{
        return {
          from: topFan.from,
          profilePicture: getDenProfilePicture(topFan.from),
          spotlight: topFan.totalSpotlights
        };
    })

    console.log("###########33 innerCircle :: ",innerCircleProfiles)
    return(
      <div className='col-12 col-lg-6'>
          <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
              <div className='inner-circle-container' ref={this.componentRef}>
                <div className='d-flex align-items-center p-2'>
                  <MdOutlineArrowBackIosNew className="cursor-pointer me-2" style={{height:'20px'}} onClick={() => this.props.history.goBack()} />
                  <p className='fs-24 fw-700 my-0 flex-grow-1'>Inner Circle</p>
                  <button className='den-profile-btn follow-btn' style={{width: 'auto'}} onClick={() => this.onShare()} >
                    <img src={INNER_CIRCLE_ASSETS.SHARE} alt='' className='me-2' style={{width:'20px'}}/> Share
                  </button>
                </div>

                <div >
                  { innerCircleProfiles && this.getTopThreeView() }
              
                  <div className='p-2'>
                    {innerCircleProfiles?.map((topFan,i) => {
                      if(i>2){
                        return(
                          <div className='d-flex my-0 px-2 py-1' key={i}>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                              <p className='fs-16 fw-700 my-0'>{i+1}</p>
                              {/* <img src={INNER_CIRCLE_ASSETS.RANK_UP} alt='' className='' style={{width:'13px'}}/> */}
                            </div>
                            <div className='inner-circle-rank-div flex-grow-1 ms-3'>
                            <Image src={topFan?.profilePicture?.url} param={topFan?.from?.name? topFan.from?.name : 'Test' }  className='inner-circle-avatar' />
                              <p className='fs-16 fw-500 my-0 flex-grow-1 ms-3'>{topFan.from.name}</p>
                              <p className='fs-16 fw-500 my-0 me-3' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{topFan.spotlight}</p>
                            </div>
                          </div>
                        )
                      }

                    })}
                    
                  </div>
                </div>
                
              </div>
          </div>
      </div>
      )
  }

  render() {
    return(
      <div className='body-section den_page'>
        <div className='container'>
            <div className='row'>
                <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                {this.getView()}
                <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
            </div>
        </div>
        <Modal centered show={this.state.openShare}>
          <ShareStats imageUrl={this.state.imageUrl} toggleOpenShare={this.toggleOpenShare} />
        </Modal>
      </div>
    )
  }
}
