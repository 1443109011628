import React, { Component } from 'react'
import PropTypes from 'prop-types'
//icons
import { FiEye, FiEyeOff} from "react-icons/fi";
import { VscLock } from "react-icons/vsc";
//components
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

/**
* @author
* @class UpdatePassword
**/

class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewPassword: props.showNewPassword ? props.showNewPassword : false,
            showPassword: props.showPassword ? props.showPassword : false
        };
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            showNewPassword: nextProps.showNewPassword ? nextProps.showNewPassword : false,
            showPassword: nextProps.showPassword ? nextProps.showPassword : false
        })
    }

    render() {
        const { showPassword, showNewPassword } = this.props;
        return(
            <Formik
                innerRef={this.formikRef}
                enableReinitialize
                initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                }}
                validationSchema={Yup.object({
                    oldPassword: Yup.string().required("Old Password is required"),

                    newPassword: Yup.string().required("New Password is required").matches(
                        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        "Password must contain at least 6 characters, one uppercase, one number and one special case character"),

                    confirmPassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        this.props.onSubmit(values)
                        setSubmitting(false);
                    }, 400);
                }}
            >
                <Form className="row" autoComplete="off">

                    <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                        <h1 htmlFor="oldPassword" className="fs-16 fs-lg-20 fw-600 text-white">Old Password</h1>
                        <VscLock className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                        <Field
                            name="oldPassword"
                            type="password"
                            className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 fs-lg-18 height-45"
                            placeholder="Enter Old Password"
                        />
                        <ErrorMessage component="span" className="error-msg" name="oldPassword" />
                    </div>


                    <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                        <h1 htmlFor="newPassword" className="fs-16 fs-lg-20 fw-600 text-white" >New Password</h1>
                        <VscLock className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                        {showNewPassword && (
                            <FiEye className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.props.handleEditToggle('showNewPassword') }} />
                        )}
                        {!showNewPassword && (
                            <FiEyeOff className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.props.handleEditToggle('showNewPassword') }} />
                        )}
                        <Field
                            name="newPassword"
                            type={this.state.showNewPassword ? 'text' : 'password'}
                            className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 fs-lg-18 height-45"
                            placeholder="Enter New Password"
                        />
                        <ErrorMessage component="span" className="error-msg" name="newPassword" />
                    </div>

                    <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                        <h1 htmlFor="confirmPassword" className="fs-16 fs-lg-20 fw-600 text-white">Confirm Password</h1>
                        <VscLock className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                        {showPassword && (
                            <FiEye className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.props.handleEditToggle('showPassword') }} />
                        )}
                        {!showPassword && (
                            <FiEyeOff className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.props.handleEditToggle('showPassword') }} />
                        )}
                        <Field
                            name="confirmPassword"
                            type={showPassword ? 'text' : 'password'}
                            className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 fs-lg-18 height-45"
                            placeholder="Enter Confirm Password"
                        />
                        <ErrorMessage component="span" className="error-msg" name="confirmPassword" />
                    </div>

                    <div className="d-flex my-4">
                        <button className="white-flat-btn fw-bold py-2 px-4 m-auto me-1" type="button" onClick={() =>  this.props.handleEditToggle('updatePassword')}> Back </button>
                        <button type="submit" className="white-flat-btn fw-bold py-2 px-4 m-auto ms-1"> Update </button>
                    </div>
                </Form>
            </Formik>
        )
    }
}

UpdatePassword.propTypes = {}
export default UpdatePassword