import { Component } from 'react';
import {Carousel} from 'react-bootstrap';
//images
import participantAvtar from "../../../../images/participantAvtar.jpg";
import winnerCrown from "../../../../images/icon/winner-crown.png";
import pubgIc from "../../../../images/icon/pubg-ic.png";
import orgIc from "../../../../images/icon/org-ic.png";
import teamA from "../../../../images/icon/team-a.png";
import teamB from "../../../../images/icon/team-b.png";
import star from "../../../../images/score/star.png";
import chicken from "../../../../images/score/chicken.png";
import { ellipsis, scoreCardGameURL } from '../../../../utils/common.utils';
import SponsorCards from './SponsorCards';
import CardHeader from './CardHeader';
import DuelForfeitCase from './DuelForfeitCase';

export default class PUBGCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            matchDetails: props.matchDetails,
            tournamentDetails: props.tournamentDetails
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            matchDetails: nextProps.matchDetails,
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getTeamDuel = () => {
        const { matchDetails, tournamentDetails} = this.state;
        if(matchDetails?.results.length > 1){
            return(
                <Carousel>
                    {this.state.matchDetails?.results.map((el, i) => {
                        let p1forfeit = el[0].forfeit ? true : false;
                        let p2forfeit = el[1].forfeit ? true : false;
                        //console.log(`Game-${i+1} p1forfeit-${p1forfeit}, p2forfeit-${p2forfeit}`);
                        if(p1forfeit || p2forfeit){
                            return <Carousel.Item key={i}><DuelForfeitCase game={el} tournamentDetails={tournamentDetails} /></Carousel.Item>
                        }else{
                        //console.log(`el[0] ::`, el[0]);
                        //console.log('Array :::',[...el[0].individualScore, ...el[1].individualScore]);
                        const teamMVP = [...el[0].individualScore, ...el[1].individualScore].filter(val =>  val.participantDetails?._id === el[0].teamMVP?._id);
                        const matchMVP = [...el[0].individualScore, ...el[1].individualScore].filter(val =>  val.participantDetails?._id === el[0].matchMVP?._id);
                        //console.log('-------',teamMVP, matchMVP);
                        return(
                            <Carousel.Item  key={i}>
                                <div className='col-12 col-lg-6 offset-lg-3'>
                                    <div className='scoreboard-bg-image mt-2 p-3'>
                                        <CardHeader tournamentDetails={tournamentDetails} />
                                        <p className='fs-10 fw-bold text-end'>Game {i+1} of {this.state.matchDetails?.results?.length}</p>

                                        <div className='text-center d-flex justify-content-evenly align-items-end'>
                                            <div className=''>
                                                {el[0].isWinner && <span className='winner-shadow'></span>}
                                                <span className='d-flex flex-column align-items-center'>
                                                {el[0].isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                    <img src={matchDetails.participants[0].logo.url} className="rounded-circle" alt='' style={{ height: '50px', zIndex: '1' }} />
                                                </span>
                                                <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[0].name}</h5>
                                            </div>
                                            <div className='align-self-center'>
                                                <p className='text-white fw-700 fs-14 mb-1'>{`${el[0].score}-${el[1].score}`}</p>
                                            </div>
                                            <div className=''>
                                                {el[1].isWinner && <span className='winner-shadow'></span>}
                                                <span className='d-flex flex-column align-items-center'>
                                                    {el[1].isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                    <img src={matchDetails.participants[1].logo.url} className="rounded-circle" alt='' style={{ height: '50px', zIndex: '1' }} />
                                                </span>
                                                <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[1].name}</h5>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-3 p-0'>
                                                <div className='text-center'>
                                                    <div className=''>
                                                        <img src={teamMVP[0] ? teamMVP[0].participantDetails?.profilePicture?.url : participantAvtar} className='btn-size-25 rounded-circle mb-2' alt='' />
                                                    </div>
                                                    <div className=''>
                                                        <h1 className='fs-10 fw-600 text-white mb-0'>{el[0].teamMVP.inGameName}</h1>
                                                        <p className='fs-8 fw-600 text-warning mb-0' >Team MVP</p>
                                                        <p className='fs-8 fw-600 text-white mb-0'>{`${teamMVP[0].kills}-${teamMVP[0].deaths}-${teamMVP[0].assists}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-9 p-0'>
                                                <table className="table table-borderless">
                                                    <thead>
                                                        <tr className='scorecard_header'>
                                                            <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                                            <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                                            <th scope="col" className='score_header fs-8 fw-600 text-white'>KDA</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {el[0].individualScore?.map((player,i) => {
                                                            //console.log('----------player 1----------', player);
                                                            var isTeamMVP = el[0].teamMVP._id === player.participantDetails._id;
                                                            var isMatchMVP = el[0].matchMVP._id === player.participantDetails._id;
                                                            return (
                                                                <tr className='scorecard_body' key={i}>
                                                                    <td className='fs-8 fw-600 text-white team_body'>{el[0].teamDetails?.name}</td>
                                                                    <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}<span className='fs-8 fw-bold text-warning ms-1' style={{ display: isTeamMVP ? 'content':'none'}}>MVP</span><span className='fs-8 fw-bold ms-2 text-white' style={{ display: isMatchMVP ? 'content':'none'}}>MVP</span></td>
                                                                    <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3 p-0'>
                                                <div className='text-center'>
                                                    <div className=''>
                                                        <img src={matchMVP[0] ? matchMVP[0].participantDetails?.profilePicture?.url : participantAvtar} className='btn-size-25 rounded-circle mb-2' alt='' />
                                                    </div>
                                                    <div className=''>
                                                        <h1 className='fs-10 fw-600 text-white mb-0'>{el[0].matchMVP.inGameName}</h1>
                                                        <p className='fs-8 fw-600 text-warning mb-0' >Match MVP</p>
                                                        <p className='fs-8 fw-600 text-white mb-0'>{`${matchMVP[0].kills}-${matchMVP[0].deaths}-${matchMVP[0].assists}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-9 p-0'>
                                                <table className="table table-borderless">
                                                    <thead>
                                                        <tr className='scorecard_header'>
                                                            <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                                            <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                                            <th scope="col" className='score_header fs-8 fw-600 text-white'>KDA</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {el[1].individualScore?.map((player,i) => {
                                                            //console.log('----------player 2----------', player);
                                                            var isTeamMVP = el[0].teamMVP._id === player.participantDetails._id;
                                                            var isMatchMVP = el[0].matchMVP._id === player.participantDetails._id;
                                                            return (
                                                                <tr className='scorecard_body' key={i}>
                                                                    <td className='fs-8 fw-600 text-white team_body'>{el[1].teamDetails?.name}</td>
                                                                    <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}<span className='fs-8 fw-bold text-warning ms-1' style={{ display: isTeamMVP ? 'content':'none'}}>MVP</span><span className='fs-8 fw-bold ms-2 text-white' style={{ display: isMatchMVP ? 'content':'none'}}>MVP</span></td>
                                                                    <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    }
                })}
                </Carousel>
            )
        }else{
            let p1forfeit = matchDetails.results[0][0].forfeit ? true : false;
            let p2forfeit = matchDetails.results[0][1].forfeit ? true : false;
            //console.log(`Game-${i+1} p1forfeit-${p1forfeit}, p2forfeit-${p2forfeit}`);
            if(p1forfeit || p2forfeit){
                return <DuelForfeitCase game={matchDetails.results[0]} tournamentDetails={tournamentDetails} />
            }else{
                const teamMVP = [...matchDetails.results[0][0].individualScore, ...matchDetails.results[0][1].individualScore].filter(val =>  val.participantDetails?._id === matchDetails.results[0][0].teamMVP?._id);
                const matchMVP = [...matchDetails.results[0][0].individualScore, ...matchDetails.results[0][1].individualScore].filter(val =>  val.participantDetails?._id === matchDetails.results[0][0].matchMVP?._id);
                return(
                    <div className='col-12 col-lg-6 offset-lg-3'>
                        <div className='scoreboard-bg-image mt-2 p-3'>
                            <CardHeader tournamentDetails={tournamentDetails} />
                            <div className='text-center d-flex justify-content-evenly align-items-end'>
                                <div className=''>
                                    {matchDetails.results[0][0].isWinner && <span className='winner-shadow'></span>}
                                    <span className='d-flex flex-column align-items-center'>
                                    {matchDetails.results[0][0].isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                        <img src={matchDetails.participants[0].logo.url} className="rounded-circle" alt='' style={{ height: '50px', zIndex: '1' }} />
                                    </span>
                                    <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[0].name}</h5>
                                </div>
                                <div className='align-self-center'>
                                    <p className='text-white fw-700 fs-14 mb-1'>{`${matchDetails.results[0][0].kills}-${matchDetails.results[0][1].kills}`}</p>
                                </div>
                                <div className=''>
                                    {matchDetails.results[0][1].isWinner && <span className='winner-shadow'></span>}
                                    <span className='d-flex flex-column align-items-center'>
                                        {matchDetails.results[0][1].isWinner && <img src={winnerCrown} className="" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                        <img src={matchDetails.participants[1].logo.url} className="rounded-circle" alt='' style={{ height: '50px', zIndex: '1' }} />
                                    </span>
                                    <h5 className='text-white fw-bold fs-10 mt-2'>{matchDetails.participants[1].name}</h5>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-3'>
                                    <div className='text-center'>
                                        <div className=''>
                                            <img src={teamMVP ? teamMVP[0].participantDetails?.profilePicture.url : participantAvtar} className='btn-size-25 rounded-circle mb-2' alt='' />
                                        </div>
                                        <div className=''>
                                            <h1 className='fs-10 fw-600 text-white mb-0'>{teamMVP[0].participantDetails.inGameName}</h1>
                                            <p className='fs-8 fw-600 text-warning mb-0' >Team MVP</p>
                                            <p className='fs-8 fw-600 text-white mb-0'>{`${teamMVP[0].kills}-${teamMVP[0].deaths}-${teamMVP[0].assists}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-9'>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr className='scorecard_header'>
                                                <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                                <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                                <th scope="col" className='score_header fs-8 fw-600 text-white'>KDA</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {matchDetails.results[0][0].individualScore?.map((player,i) => {
                                                //console.log('----------player 1----------', player);
                                                return (
                                                    <tr className='scorecard_body' key={i}>
                                                        <td className='fs-8 fw-600 text-white team_body'>{matchDetails.results[0][0].teamDetails?.name}</td>
                                                        <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}</td>
                                                        <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <div className='text-center'>
                                        <div className=''>
                                            <img src={matchMVP[0].participantDetails.profilePicture.url} className='btn-size-25 rounded-circle mb-2' alt='' />
                                        </div>
                                        <div className=''>
                                            <h1 className='fs-10 fw-600 text-white mb-0'>{matchMVP[0].participantDetails.inGameName}</h1>
                                            <p className='fs-8 fw-600 text-white-50 mb-0' >Match MVP</p>
                                            <p className='fs-8 fw-600 text-white mb-0'>{`${matchMVP[0].kills}-${matchMVP[0].deaths}-${matchMVP[0].assists}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-9'>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr className='scorecard_header'>
                                                <th scope="col" className='team_header fs-8 fw-600 text-white'>TEAM</th>
                                                <th scope="col" className='name_header fs-8 fw-600 text-white'>NAME</th>
                                                <th scope="col" className='score_header fs-8 fw-600 text-white'>KDA</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {matchDetails.results[0][1].individualScore?.map((player,i) => {
                                                //console.log('----------player 2----------', player);
                                                return (
                                                    <tr className='scorecard_body' key={i}>
                                                        <td className='fs-8 fw-600 text-white team_body'>{matchDetails.results[0][1].teamDetails?.name}</td>
                                                        <td className='fs-8 fw-600 text-white'>{player?.participantDetails?.inGameName}</td>
                                                        <td className='fs-8 fw-600 text-white team_score'>{`${player.kills}-${player.deaths}-${player.assists}`}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                        </div>
                    </div>
                )
            }
        }
    }

    getSoloDuelFinalResult = (key) => { 
        const { matchDetails, tournamentDetails } = this.state;
        let match = null;
        if(key){
            match = this.state.matchDetails?.finalResult
        }else{
            match = this.state.matchDetails?.results[0];
        }
        console.log('match ::',match);
        let p1forfeit = match[0].forfeit ? true : false;
        let p2forfeit = match[1].forfeit ? true : false;
        //console.log(`Game-${i+1} p1forfeit-${p1forfeit}, p2forfeit-${p2forfeit}`);
        if(p1forfeit || p2forfeit){
            return <DuelForfeitCase game={match} tournamentDetails={tournamentDetails} />
        }else{
            return(
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />
                    <div className='d-flex justify-content-evenly align-items-end'>
                        <div className=''>
                            <div className=''>
                                {matchDetails.finalResult[0].isWinner && <span className='winner-shadow'></span>}
                                <span className='d-flex flex-column align-items-center'>
                                    {match[0].isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                    <img src={matchDetails.participants[0].profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                                </span>
                                <h5 className='text-white fw-bold fs-10 mt-2 text-center'>{matchDetails.participants[0].inGameName}</h5>
                                <table className="table table-borderless mt-2">
                                    <thead>
                                        <tr className='scorecard_header'>
                                            <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr className='scorecard_body'>
                                        <td className='fs-8 fw-600 text-white team_body'>{`${match[0].kills} - ${match[0].deaths}`}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className=''>
                            <p className='text-white fw-700 fs-14 mb-1'>{`${matchDetails.finalResult[0].score}-${matchDetails.finalResult[1].score}`}</p>
                        </div>
                        <div className=''>
                            {matchDetails.finalResult[1].isWinner && <span className='winner-shadow'></span>}
                            <span className='d-flex flex-column align-items-center'>
                                {matchDetails.finalResult[1].isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                <img src={matchDetails.participants[1].profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                            </span>
                            <h5 className='text-white fw-bold text-center fs-10 mt-2'>{matchDetails.participants[1].inGameName}</h5>
                            <table className="table table-borderless mt-2">
                                <thead>
                                    <tr className='scorecard_header'>
                                        <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr className='scorecard_body'>
                                    <td className='fs-8 fw-600 text-white team_body'>{`${match[1].kills} - ${match[1].deaths}`}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            )
        }
    }

    getSoloDuel = () => {
        const { matchDetails, tournamentDetails} = this.state;
        if(this.state.matchDetails?.results?.length > 1){
            return (
                <Carousel>
                    <Carousel.Item>{this.getSoloDuelFinalResult(true)}</Carousel.Item>
                    {matchDetails.results.map((el, i) => {
                        let p1forfeit = el[0].forfeit ? true : false;
                        let p2forfeit = el[1].forfeit ? true : false;
                        //console.log(`Game-${i+1} p1forfeit-${p1forfeit}, p2forfeit-${p2forfeit}`);
                        if(p1forfeit || p2forfeit){
                            return <Carousel.Item key={i}><DuelForfeitCase game={el} tournamentDetails={tournamentDetails} /></Carousel.Item>
                        }else{
                            return (
                                <Carousel.Item key={i}>
                                    <div className='col-12 col-lg-6 offset-lg-3'>
                                        <div className='scoreboard-bg-image mt-2 p-3'>
                                            <CardHeader tournamentDetails={tournamentDetails} />
                                            <p className='fs-10 fw-bold text-end'>Game {i+1} of {matchDetails.results?.length}</p>
                                            <div className='d-flex justify-content-evenly align-items-end'>
                                                <div className=''>
                                                    <div className=''>
                                                        {/* {el[0].isWinner && <span className='winner-shadow'></span>} */}
                                                        <span className='d-flex flex-column align-items-center'>
                                                            {el[0].isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                            <img src={el[0].participantDetails?.profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                                                        </span>
                                                        <h5 className='text-white fw-bold fs-10 mt-2 text-center'>{matchDetails.participants[0].inGameName}</h5>
                                                        {el[0].participantDetails._id === el[0].matchMVP._id && <p className='text-warning fs-8 fw-bold text-center my-0'>MVP</p>}
                                                        <table className="table table-borderless mt-2">
                                                            <thead>
                                                                <tr className='scorecard_header'>
                                                                    <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr className='scorecard_body'>
                                                                <td className='fs-8 fw-600 text-white team_body'>{`${el[0].kills} - ${el[0].deaths}`}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='text-white fw-700 fs-14 mb-1'>{`${el[0].kills}-${el[1].kills}`}</p>
                                                </div>
                                                <div className=''>
                                                    {/* {el[1].isWinner && <span className='winner-shadow'></span>} */}
                                                    <span className='d-flex flex-column align-items-center'>
                                                        {el[1].isWinner && <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px', zIndex: '1' }} />}
                                                        <img src={el[1].participantDetails?.profilePicture?.url} className="rounded-circle" alt='' style={{ height: '40px', width:'40px',zIndex: '1' }} />
                                                    </span>
                                                    <h5 className='text-white fw-bold text-center fs-10 mt-2'>{matchDetails.participants[1].inGameName}</h5>
                                                    {el[1].participantDetails._id === el[1].matchMVP._id && <p className='text-warning fs-10 fw-bold text-center'>MVP</p>}
                                                    <table className="table table-borderless mt-2">
                                                        <thead>
                                                            <tr className='scorecard_header'>
                                                                <th scope="col" className='team_header fs-8 fw-600 text-white'>KD</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className='scorecard_body'>
                                                            <td className='fs-8 fw-600 text-white team_body'>{`${el[1].kills} - ${el[1].deaths}`}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        }
                    })}
                </Carousel>
            )
        }else{
            return this.getSoloDuelFinalResult(false)
        }
    }

    getTeamFFAFinalResult = (key) => { 
        const tournamentDetails = this.state.tournamentDetails
        let arr = [];
        if(key){
            arr =  Object.values(this.state.matchDetails?.finalResult).filter(k => k.teamDetails);
        }else{
            arr =  Object.values(this.state.matchDetails?.results[0]).filter(k => k.teamDetails);
        }
        arr =  Object.values(this.state.matchDetails?.results[0]).filter(k => k.teamDetails);
        let sortedArray = arr.sort((a, b) => parseInt(b.score) - parseInt(a.score));
        return (
            <div className='col-12 col-lg-6 offset-lg-3'>
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />
                    <div className='row'>
                        {sortedArray.map((res,j)=> {
                            //console.log('res ::::', res);
                            return(
                                <div className='col-6 p-1' key={j}>
                                    <div className={`d-flex p-1 ${this.props.isBGMI ? 'bgmi_card' : 'freefire_card'}`}>
                                        <div className='me-1'>
                                            <p className='fs-12 fw-700 text-warning'>#{res.rank}</p>
                                        </div>
                                        <div className='d-flex flex-grow-1'>
                                            <img src={res.teamDetails?.logo?.url} className="rounded-circle btn-size-20" alt='' />
                                            <div className='ms-2'>
                                                <h1 className='fs-8 fw-600 text-white mb-0'>{ellipsis(res.teamDetails?.name, 9)}</h1>
                                                <p className='fs-8 fw-600 text-white mb-0'>Kills: {res?.kills}</p>
                                                <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{res?.score}</p>
                                                <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[j]} + K-{res.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column flex-grow-1'>
                                        {res.individualScore.map((player, k) => {
                                            //console.log(`player ${k+1}`,player)
                                            return(
                                                <div className='d-flex justify-content-between'>
                                                    <p className='fs-8 fw-600 text-white mb-0'>{player.participantDetails?.inGameName}</p>
                                                    <p className='fs-8 fw-600 text-white mb-0'>{player.kills}</p>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        
                    </div>
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            </div>
        )
    }

    getTeamFFA = () => {
        const tournamentDetails = this.state.tournamentDetails
        if(this.state.matchDetails?.results.length > 1){
            return (
                <Carousel>
                    <Carousel.Item>{this.getTeamFFAFinalResult(true)}</Carousel.Item>
                    {this.state.matchDetails.results.map((el, i)=> {
                        let arr =  Object.values(el).filter(k => k.teamDetails);
                        let sortedArray = arr.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
                        //console.log('sortedArray :::', sortedArray);
                        return(
                            <Carousel.Item key={i}>
                                <div className='col-12 col-lg-6 offset-lg-3'>
                                    <div className='scoreboard-bg-image mt-2 p-3'>
                                        <CardHeader tournamentDetails={tournamentDetails} />
                                        <div className='row'>
                                        {sortedArray.map((res,j)=> {
                                            //console.log('res ::::', res);
                                            return(
                                                <div className='col-6 p-1' key={j}>
                                                    <div className={`d-flex p-1 ${this.props.isBGMI ? 'bgmi_card' : 'freefire_card'}`}>
                                                        <div className='me-1'>
                                                            <p className='fs-12 fw-700 text-warning my-0'>#{res.rank}</p>
                                                            {res.rank == 1 && <img src={chicken} alt='' height='9' width='12' />}
                                                        </div>
                                                        <div className='d-flex flex-grow-1'>
                                                            <img src={res.teamDetails?.logo?.url} className="rounded-circle btn-size-20" alt='' />
                                                            <div className='ms-2'>
                                                                <p className='fs-8 fw-bold text-white mb-0'>{ellipsis(res.teamDetails?.name, 9)}{res.isWinner && <img src={winnerCrown} alt='' height='10' width='10' className='ms-2' />}</p>
                                                                <p className='fs-8 fw-600 text-white mb-0'>Kills: {res?.kills}</p>
                                                                <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{res?.score}</p>
                                                                <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[j]} + K-{res.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-column flex-grow-1'>
                                                        {res.individualScore.map((player, k) => {
                                                            //console.log(`player ${k+1}`,player)
                                                            return(
                                                                <div className='d-flex justify-content-between' key={k}>
                                                                    <p className='fs-8 fw-600 text-white mb-0'>{player.participantDetails?.inGameName}</p>
                                                                    <p className='fs-8 fw-600 text-white mb-0'>{player.kills}</p>
                                                                </div>
                                                            )
                                                        })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        </div>
                                        <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                
            )
        }else{
            return this.getTeamFFAFinalResult(false);
        }
        
    }

    getSoloFFAFinalResult = (key) => { 
        const tournamentDetails = this.state.tournamentDetails;
        let finalResultsArray = [];
        if(key){
            finalResultsArray = Object.values(this.state.matchDetails?.finalResult).filter(k => k.participantDetails);
        }else{
            finalResultsArray = Object.values(this.state.matchDetails?.results[0]).filter(k => k.participantDetails);
        }
        
        console.log('final results ::', finalResultsArray);
        let sortedArray = finalResultsArray.sort((a, b) => parseInt(b.score) - parseInt(a.score));
        return(
            <div className='col-12 col-lg-6 offset-lg-3'>
                    <div className='scoreboard-bg-image mt-2 p-3'>
                        <CardHeader tournamentDetails={tournamentDetails} />
    
                        <div className='row mt-3'>
                            {sortedArray.map((el,i)=>{
                                return (
                                <div className='col-6 px-1'  key={i}>
                                    <div className={`d-flex p-1 mb-1 ${this.props.isBGMI ? 'bgmi_card' : 'freefire_card'}`}>
                                        <div className='me-1'>
                                            <p className='fs-12 fw-700 text-warning my-0'>#{i+1}</p>
                                            {(key && el.rank == 1) && <img src={chicken} alt='' height='9' width='12' />}
                                        </div>
                                        <div className='d-flex flex-grow-1'>
                                            <img src={el.participantDetails.profilePicture ? el.participantDetails.profilePicture.url : participantAvtar} className="rounded-circle btn-size-20" alt='' />
                                            <div className='ms-2'>
                                                <h1 className='fs-8 fw-600 text-white mb-0'>{el.participantDetails?.inGameName}{el.isWinner && <img src={winnerCrown} alt='' height='10' width='10' className='ms-2' />} </h1>
                                                <p className='fs-8 fw-600 text-white mb-0'>Kills: {el.kills}</p>
                                                {el.isWinner && <p className='fs-8 fw-700 text-success mb-0'>#Winner</p>}
                                            </div>
                                        </div>
                                        <div className=''>
                                            <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{el.score}</p>
                                            <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[i]} + K-{el.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                        <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                    </div>
                </div>
        )
    }

    getSoloFFA = () => {
        const tournamentDetails = this.state.tournamentDetails;
        if(this.state.matchDetails?.results.length > 1){
            return(
                <Carousel>
                    <Carousel.Item>{this.getSoloFFAFinalResult(true)}</Carousel.Item>
                    {this.state.matchDetails?.results.map((game, i) => {
                        console.log(`game ${i+1} --`, game);
                        let arr =  Object.values(game).filter(k => k.participantDetails);
                        let sortedArray = arr.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
                        console.log('sortedArray :::', sortedArray);
                        return(
                            <Carousel.Item key={i}>
                                <div className='col-12 col-lg-6 offset-lg-3'>
                                    <div className='scoreboard-bg-image mt-2 p-3'>
                                        <CardHeader tournamentDetails={tournamentDetails} />
                                        <p className='fs-8 fw-bold text-end'>Game {i+1} of {this.state.matchDetails?.results.length}</p>
                                        <div className='row mt-3'>
                                            {sortedArray.map((res,j)=>{
                                                console.log('res ::', res);
                                                return (
                                                <div className='col-6 px-1'  key={j}>
                                                    <div className={`d-flex p-1 mb-1 ${this.props.isBGMI ? 'bgmi_card' : 'freefire_card'}`}>
                                                        <div className='me-1'>
                                                            <p className='fs-12 fw-700 text-warning my-0'>#{res.rank}</p>
                                                            {res.rank == 1 && <img src={chicken} alt='' height='9' width='12' />}
                                                        </div>
                                                        <div className='d-flex flex-grow-1'>
                                                            <img src={res.participantDetails.profilePicture ? res.participantDetails.profilePicture.url : orgIc} className="rounded-circle btn-size-20" alt='' />
                                                            <div className='ms-2'>
                                                                <p className='fs-8 fw-bold text-white mb-0'>{res.participantDetails?.inGameName}{res.isWinner && <img src={winnerCrown} alt='' height='10' width='10' className='ms-2' />}</p>
                                                                <p className='fs-8 fw-600 text-white mb-0'>Kills: {res.kills}</p>
                                                                {res.isWinner && <p className='fs-8 fw-700 text-success mb-0'>#Winner</p>}
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{res.score}</p>
                                                            <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[j]} + K-{res.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                        <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            )
        }else{
            return this.getSoloFFAFinalResult(false)
        }
        
    }

    getCard = () => {
        const tournamentDetails = this.state.tournamentDetails;
        switch (true) {
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'DUEL'):
                return this.getSoloDuel();
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'FFA'):
                return this.getSoloFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'FFA'):
                return this.getTeamFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'DUEL'):
                return this.getTeamDuel();
            default:
                break;
        }
    }   

    render() {
        return (
            <div className="p-0">
                {this.getCard()}
                {/* This is Freefire ScoreCard. */}
            </div>
        )
    }
}