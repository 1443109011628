
export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const receivedAt = (dateString) => {
    try {

        const currentDate = new Date()
        const receivedDate = new Date(dateString)

        var difference = currentDate - receivedDate;  
        var diffDays = Math.floor(difference / 86400000); // days
        var diffHrs = Math.floor((difference % 86400000) / 3600000) + (24 * diffDays); // hours
        var diffMins = Math.round(((difference % 86400000) % 3600000) / 60000); // minutes
        
        if(diffHrs > 24){
            return [`${diffDays+1} d`];
        }else if(diffHrs > 0){
            return [`${diffHrs} h`];
        }else{
            return [`${diffMins} m`];
        }

    } catch (error) {
        console.log('error::', error)
        return [`TBD`, `TBD`];
    }
}

export const getDateMonthYear = (dateString) => {
    try {
        const matchSchedule = new Date(dateString);
        return [`${matchSchedule.getDate()} ${months[matchSchedule.getMonth()]} ${matchSchedule.getFullYear()}`];
    } catch (error) {
        console.log("Error : ", error)
        return [`TBD`, `TBD`];
    }
}

export const getMonthYear = (date) => {
    try {
        const matchSchedule = new Date(date);
        return [`${months[matchSchedule.getMonth()]}' ${matchSchedule.getFullYear()}`];
    } catch (error) {
        console.log("Error : ", error)
        return 'Present Role';
    }
}