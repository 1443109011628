import { Component } from 'react';
import '../../../styles/Tournaments.css';
import { NavLink } from 'react-router-dom';
import { Modal } from "react-bootstrap";

// icons
import { FiEdit } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import overviewIc from '../../../images/icon/overview-ic.png';
import goLiveIc from '../../../images/icon/golive-ic.png';
import cancel from '../../../images/icon/cancel.png';
import ConfigureMenuComponent from './ConfigureMenuComponent';
import AddParticipant from '../../popup/AddParticipant';
import TournamentApis from '../../../helper/tournament.api';
import { errorHandler } from '../../../utils/common.utils';
import gridIC from '../../../images/icon/grid-ic.png';

import { FaGlobeAsia } from "react-icons/fa";
import TournamentHeader from './common/TournamentHeader';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import { TournamentStatus } from '../../../utils/enums.utils';
import { BiCopy, BiLock } from 'react-icons/bi';
import TournamentShare from '../../popup/TournamentShare';

class TournamentOverview extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openAddParticipant: false,
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : null,
            openShare: false
        };
        this.tournamentApis = new TournamentApis();
        this.tournamentId = null;
        if (props.match.params.id) {
            this.getData(props.match.params.id);
        }
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.tournamentId = this.props.match.params.id;
        // if (!this.props?.tournamentData?._id) {
        //     this.getData();
        // }
    }

    getData = (id) => {
        const data = {
            _id: id
        };

        this.props.updateLoader(true);

        this.tournamentApis.getTournaments(data).then(
            (res: AxiosResponse) => {
                this.props.dispatchData(res.data.result[0])
                this.setState({
                    tournamentDetails: res.data.result[0]
                });
                if (!this.state?.tournamentDetails) {
                    this.props.history.push('/pageNotFound')
                }
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    /*To Open Add participant Popup */
    onClickOpenAddParticipant = () => {
        this.setState({
            openAddParticipant: !this.state.openAddParticipant
        });
    }

    toggleShare = () => {
        this.setState({openShare : !this.state.openShare})
    }

    render() {
        var normalEntrantsCount = this.state.tournamentDetails?.normalEntrantsCount ? this.state.tournamentDetails?.normalEntrantsCount : 0;
        var wildCardEntrantsCount = this.state.tournamentDetails?.wildCardEntrantsCount ? this.state.tournamentDetails?.wildCardEntrantsCount : 0;
        var tournamentStatus = this.state.tournamentDetails?.tournamentStatus;
        const baseUrl = window.location.hostname === 'localhost' ? 'https://localhost:3000' : window.location.origin;
        return (
            <div className="body-section tournament-overview pb-0">
                <div className="container p-3">

                    {this.props.location.state?.isConfig ?
                        // Tournament Overview page
                        <div className='mt-lg-3'>
                            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={gridIC} heading="Configure Menu" subHeading={null} history={this.props.history} />

                            <div className='col-12 mb-3 mt-5'>
                                <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                                    pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview`
                                }}>
                                    <img src={overviewIc} className='btn-size-20 mb-lg-1' alt='' />Overview
                                </NavLink>
                            </div>
                        </div>
                        :
                        // Config Menu page
                        <div className='mt-lg-3'>
                            <h1 className="fs-20 fw-bold text-white text-center mb-4">
                                <img src={overviewIc} className="me-2 btn-size-20 mb-lg-1" alt=''></img>
                                Overview
                            </h1>

                            <div className='row'>
                                <div className='co-lg-8 offset-lg-1 col-md-10 offset-md-1 col-12'>
                                    <div className=' port-gore-card pt-2 mb-3'  >
                                        <div className='row p-3'>
                                            <div className='col-9'>
                                                <h6 className='fw-bold'>{this.state?.tournamentDetails?.name} {this.state?.tournamentDetails?.visibility === 'PUBLIC' ? <FaGlobeAsia className='ms-2 mb-1 text-light' /> : <BiLock className='ms-2 mb-1 text-light' />}</h6>
                                                <p className='fs-14 fw-bold text-white-50 mb-0'>{this.state?.tournamentDetails?.gameDetails?.name}</p>
                                            </div>
                                            <div className='col-3 text-center' style={{display : (tournamentStatus === TournamentStatus.COMPLETED ) ? 'none' : 'block'}}>
                                            {tournamentStatus === TournamentStatus.DRAFT &&
                                                <NavLink to={{
                                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/publish-tournament`,
                                                    state: {
                                                        isConfig: true,
                                                    },
                                                }}
                                                >
                                                    <img src={goLiveIc} className="" style={{ width: "25px" }} alt=''></img>
                                                    <p className='fw-bold text-white-50 mb-0 fs-12'>Go Live</p>
                                                </NavLink>
                                            }
                                            {(tournamentStatus === TournamentStatus.UPCOMING || tournamentStatus === TournamentStatus.ONGOING) &&
                                                <NavLink to={{
                                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/cancel-and-refund`,
                                                    state: {
                                                        isConfig: true,
                                                    },
                                                }}
                                                >
                                                    <img src={cancel} className="" style={{ width: "25px" }} alt=''></img>
                                                    <p className='fw-bold text-white-50 mb-0 fs-12'>Cancel</p>
                                                </NavLink>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='co-lg-8 offset-lg-1 col-md-10 offset-md-1 col-12'>
                                    <div className='port-gore-card pt-2 pb-2'>
                                        <div className='row p-3'>
                                            <div className='col-9'>
                                                <h6 className='fw-bold'>Participants</h6>
                                                <p className='fs-14 mb-0 fw-bold text-white-50'>{`${normalEntrantsCount}`}+{`${wildCardEntrantsCount}`}/{`${this.state.tournamentDetails?.maxParticipant}`}</p>
                                            </div>
                                            <div className='col-3 d-flex flex-column justify-content-center'>
                                                <NavLink className="" to={{
                                                    pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.tournamentId}/overview/participant`
                                                }}>
                                                    <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'><BsArrowRight className='black-rock fs-14' /></span>
                                                </NavLink>
                                            </div>
                                            {this.state.tournamentDetails?.visibility === 'PRIVATE' && 
                                                <div className='col-12 mt-2'>
                                                    <p className='text-white fs-14 fw-bold'>URL :: <BiCopy style={{height:'20px', width:'20px'}} onClick={this.toggleShare} /></p>
                                                    <p className='text-warning fs-14 fw-bold text-break'>{`${baseUrl}/tournament/${this.state.tournamentDetails?._id}`}</p>
                                                </div>
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <ConfigureMenuComponent isConfig={this.props.location.state?.isConfig} tournamentDetails={this.state.tournamentDetails} tournamentId={this.tournamentId} orgId={this.state?.tournamentDetails?.organizationId}  {...this.props} />
                </div>


                <Modal show={this.state.openAddParticipant} centered dialogClassName="add-participant-modal">
                    <Modal.Body>
                        <AddParticipant openAddParticipant={this.state.openAddParticipant}
                            onClickOpenAddParticipant={this.onClickOpenAddParticipant}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openShare} centered dialogClassName="social-media-modal">
                    <Modal.Body>
                        <TournamentShare
                            toggleShare={this.toggleShare}
                            tournamentDetails={this.state.tournamentDetails}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(TournamentOverview)