import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
//components
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import TournamentFilter from '../../common/TournamentFilter';
import { getMonthAndDate } from '../../../../../utils/common.utils';
import { toast } from 'material-react-toastify';
//images, icons
import participantAvtar from '../../../../../images/participantAvtar.jpg';
import groupIc from '../../../../../images/icon/group1-ic.png';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { ASSETS } from '../../../../../utils/Strings.utils';
import { Commons } from '../../../../../utils/Images.utils';

export default class MatchesFFA extends Component {

    constructor(props) {
        super(props)

        const query = new URLSearchParams(this.props.location.search);
        this.query = query;
        const stage = parseInt(query.get('stage'));
        const group = parseInt(query.get('group'));
        const round = parseInt(query.get('round'));

        this.state = {
            tournamentDetails: props.tournamentDetails,
            openMatchesFilter: false,
            stage: props.stageIndex,
            group: 0,
            round: 1
        }

        this.url = new URL(window.location);
      
        if(stage > 0 || group > 0 || round > 1){
            
            setTimeout(() => {
                this.displayLoader();
                this.filterMatches(stage, group, round)
            }, 0);  
            setTimeout(() => {
                this.filterMatches(stage, group, round)
                this.displayLoader();
            }, 3500);  
          
        }
    }

    displayLoader = () =>{
        this.setState({
            showLoader : !this.state.showLoader
        })
    }


    componentWillReceiveProps(newProps) {
        this.setState({
            tournamentDetails: newProps.tournamentDetails,
            stage: newProps.stageIndex,
            group: 0,
            round: 1
        });
    }

    refresh = () => {
        // setTimeout(()=> {
        //     this.setState({
        //         stage: 0,
        //         group: 0,
        //         round: 1
        //     });
        // }, 10);
    }

    filterMatches = (stage, group, round) => {
        console.log(`stage :: ${stage} || group :: ${group} || round :: ${round}`)
        this.setState({
            // stage: stage,
            group: group,
            round: round
        });
        this.setRoutParam(stage, group, round)
        this.props.updateStage(stage);
    }


    setRoutParam = (stage, group, round) => {
        this.url.searchParams.set('stage', stage);
        this.url.searchParams.set('group', group);
        this.url.searchParams.set('round', round);
        window.history.replaceState(null, '', this.url.toString());
    }

    
    goToUpdate = (participant, matchId) => {
        if (!participant) {
            toast.error('Participants yet to join')
        } else {
            this.props.history.push(`/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview/match/FFA/${matchId}/score`)
        }
    }
    getURL = (el) => {
        console.log('Participant Details ------', el);
        console.log('participantType ________', this.state.tournamentDetails?.participantType);
        if (this.state.tournamentDetails?.participantType === 'SINGLE') {
            return el.profilePicture.url
        } else {
            return el.logo.url
        }
    }

    render() {
        const { tournamentDetails, stage, group, round } = this.state;
        const groups = tournamentDetails.stages[this.state.stage]?.groups[group];
        var winnersCount = 0;
        if ((typeof groups[this.state.round][0]) === 'string') {
            this.refresh();
        }
        return (
            <div className='mt-3 mt-lg-3'>

                {(this.state.tournamentDetails === null) || this.state.showLoader ?
                    <div style={{position:'absolute', left:'0', top:'0'}}>
                        {/* Loader Section */}
                        <div className="application-loading-container ">
                            <img className="image" src={Commons.LOADER} alt="" width="120" height="120"></img>
                        </div>
                    </div> 
                    :
                    <div className='row'>
                        {/*  Filter */}
                        <TournamentFilter stageIndex={this.props.stageIndex} state={this.state} filterMatches={this.filterMatches} groups={groups} groupIndex={group} />
                        {
                            groups[this.state.round].map((match, index) => {
                               
                               // console.log("match :: ", match);

                                if((typeof match) === 'object'){
                                    const matchTime = match.matchSchedule
                                    const scheduledTime = new Date(matchTime)
                                    const matchDateTime = getMonthAndDate(match.matchSchedule)
                                    const matchYear = scheduledTime.getFullYear()
                                    const participants = match.participants;
                                    var finalArray = Object.values(match.finalResult);
                                    var winnerArray = [];
                                    finalArray.forEach((participant,k) => {
                                        if(participant.isWinner && (participant.teamDetails || participant.participantDetails)){
                                            if(match.participants[k] !== undefined){
                                                winnerArray.push(match.participants[k]);
                                            }
                                        }
                                    });
                                    return (
                                        <div className='col-lg-4 col-md-6 col-12' onClick={() => { this.goToUpdate(participants?.length, match._id) }} key={index}>
                                            <div className='brown-shadow mx-auto p-3 mb-3 rounded-15'>
                                                <div className='row'>
                                                    <div className='col-3'>
                                                        <p className='type'>{tournamentDetails?.competitionType}</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <h6 className='fw-bold text-center fs-14'>Match {round}.{index + 1}</h6>
                                                    </div>
                                                    <div className='col-3'></div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-6'>
                                                        <h6 className='fs-12 fw-bold'>Winner</h6>
                                                        {match.resultAdded ?
                                                            // <div className='d-flex my-2'>
                                                            //     <img src={participantAvtar} className="rounded-circle btn-size-20" alt='' />
                                                            //     <h6 className="fs-12 fw-bold ms-1 mt-1">Participant 1</h6>
                                                            // </div>
                                                            <div className='d-flex my-2'>
                                                                {winnerArray.map((el, i) => {
                                                                    console.log(`Participant ${i + 1} ::`, el);
                                                                    if(i+1< 4){
                                                                        return <img src={this.getURL(el)} className='btn-size-32 rounded-circle' style={{marginLeft: i>0 ?'-16px':'0px'}} key ={i} alt='' />
                                                                    }
                                                                })}
                                                                {(winnerArray.length > 4) ?
                                                                    <span className="avatar d-inline-flex">
                                                                    <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-32 d-flex justify-content-center align-items-center' style={{marginLeft: '-16px'}}>+{winnerArray.length - 4}</p>
                                                                    </span>
                                                                    : 
                                                                    null
                                                                }
                                                                
                                                            </div>
                                                            :
                                                            <h6 className="fs-12 fw-bold ms-1 mt-1">TBD</h6>
                                                        }
                                                    </div>
                                                    <div className='col-6'>
                                                        <h6 className='fs-12 fw-bold float-end'>{match.status}</h6>
                                                        <h6 className='fs-12 fw-bold float-end' style={{ opacity: "0.7" }}>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h6>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={groupIc} className='mt-1 me-2' height={'20px'} alt='' />
                                                    <p className='fs-18 fw-bold mb-1' style={{ color: '#1A0D3D' }}>{participants.length}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            })
                        }
                    </div>
                }
                <Modal show={this.state.openMatchesFilter} centered dialogClassName="matches-filter-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <div className="">
                                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '10px', top: '25px' }} onClick={() => { this.setState({ openMatchesFilter: !this.state.openMatchesFilter }) }} />
                                <h5 className="fw-bold fs-20 text-center text-white">Add Participant</h5>
                            </div>

                            <Formik
                                initialValues={{
                                    stageName: "",
                                    maxParticipantPerGroup: "",
                                    qualificationThreshold: "",

                                }}

                                validationSchema={Yup.object({
                                    stageName: Yup.string().required("*required"),
                                    maxParticipantPerGroup: Yup.string().required("*required"),
                                    qualificationThreshold: Yup.string().required("*required"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        this.onSubmit(values)
                                        setSubmitting(false);
                                    }, 400);
                                }}

                            >
                                <Form className="createClan-form my-3">
                                    <div className='row'>

                                        <div className="col-6 mt-2">
                                            <h1 className="fs-14 fw-600 text-white mt-2">Stage</h1>
                                            <select className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                                <option value="Stage 1">Stage 1</option>
                                                <option value="Stage 2">Stage 2</option>
                                                <option value="Stage 3">Stage 3</option>
                                                <option value="Stage 4">Stage 4</option>
                                                <option value="Stage 5">Stage 5</option>
                                            </select>
                                        </div>

                                        <div className="col-6 mt-2">
                                            <h1 className="fs-14 fw-600 text-white mt-2">Group Name</h1>
                                            <select className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                                <option value="free">Stormies</option>
                                                <option value="paid">Stormies</option>
                                            </select>
                                        </div>

                                        <div className="col-6 mt-2">
                                            <h1 className="fs-14 fw-600 text-white mt-2">Round Number</h1>
                                            <select className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                                <option value="free">1</option>
                                                <option value="paid">2</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-6'>
                                            <div className="form-group">
                                                <Field
                                                    type="checkbox"
                                                    name='completedChecked'
                                                    id='completedChecked'
                                                    label='Completed'
                                                    className='matches-checkbox'
                                                />
                                                <label htmlFor="qualificationThreshold" className='ms-2' style={{ fontWeight: "100" }}>Completed</label>
                                            </div>
                                        </div>

                                        <div className='col-6'>
                                            <div className="form-group">
                                                <Field
                                                    type="checkbox"
                                                    name='pendingChecked'
                                                    id='pendingChecked'
                                                    label='Pending'
                                                />
                                                <label htmlFor="qualificationThreshold" className='ms-2' style={{ fontWeight: "100" }}>Pending</label>
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <div className="form-group">
                                                <Field
                                                    type="checkbox"
                                                    name='awaitingCheckbox'
                                                    id='awaitingCheckbox'
                                                    label='Awaiting Results'
                                                />
                                                <label htmlFor="qualificationThreshold" className='ms-2' style={{ fontWeight: "100" }}>Awaiting Results</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group my-3 text-center">
                                        <button className="white-btn-shadow fw-bold w-25" style={{ borderRadius: "6px" }}>Apply</button>
                                    </div>

                                </Form>
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}