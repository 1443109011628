import { Component } from 'react';
import { ProgressBar } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import StarRatingComponent from 'react-star-rating-component';

// Images
import playerIc from "../../../images/icon/player-ic.png";
import groupIc from "../../../images/icon/group-ic.png";
import greenRatingIc from "../../../images/icon/greenRating-ic.png";
import participantAvtar from "../../../images/participantAvtar.jpg";

// Icons
import { AiFillCloseCircle } from "react-icons/ai";
import { FaMobile } from "react-icons/fa";
import { errorHandler } from '../../../utils/common.utils';
import { profile } from '../../../utils/localstorage.utils';
import { toast } from 'material-react-toastify';


export default class TournamentRatings extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentUserId: profile()?.id,
            openRateReview: false,
            rating: 100,
            ratings: 0,
            ratingFields: {
                // tournamentManagement: 0,
                prizePoolDistribution: 0,
                streamQuality: 0,
                castingQuality: 0,
                vfxQuality: 0,
                conductionOfTournament: 0,
                punctuality: 0,
                resultDeclaration: 0,
                disputeResolution: 0,
            },
            comment: '',
            participantsReviews: [],
            viewersReviews: [],
            isParticipant: false,
            isTO: false,
            reply: '',
            commentId: null,
            commentIndex: null,
            commentOnParticipant: false
        }
    }
    componentDidMount() {
        let data = {
            tournamentId: this.props.tournamentId,
            isParticipantRating: true
        }
        this.getReviews(data)
    }
    componentWillReceiveProps(newProps) {
        if (newProps.tournamentDetails?.participantUserId?.includes(this.state.currentUserId)) {
            this.setState({ isParticipant: true })
        }
        if (newProps.tournamentDetails?.organizationDetails?.members?.owner?.includes(this.state.currentUserId)) {
            console.log("hhhhhhhhhh")
            this.setState({ isTO: true })
        }
    }
    getReviews = (params) => {
        this.props.TournamentApis.getTournamentRatings(params).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                if (params.isParticipantRating) {
                    this.setState({ participantsReviews: res.data['result'] })
                } else {
                    this.setState({ viewersReviews: res.data['result'] })
                }
                this.setState({ openRateReview: false })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    checkBlankPost = () => {
        if (this.state.ratingFields.prizePoolDistribution || this.state.ratingFields.streamQuality || this.state.ratingFields.castingQuality || this.state.ratingFields.vfxQuality || this.state.ratingFields.conductionOfTournament || this.state.ratingFields.punctuality || this.state.ratingFields.resultDeclaration || this.state.ratingFields.disputeResolution || this.state.comment) {
            return false
        }
        return true
    }
    onStarClick(nextValue, prevValue, name) {
        console.log(nextValue, prevValue, name)
        let data = this.state.ratingFields
        data[name] = nextValue
        this.setState({ ratingFields: data });
    }
    getAllReview = () => {
        this.setState({ rating: 101 })
        let data = {
            tournamentId: this.props.tournamentId,
            isParticipantRating: false
        }
        if (!this.state.viewersReviews.length) {
            this.getReviews(data)
        }
    }
    postReview = () => {
        let data = this.state.ratingFields
        data.review = this.state.comment
        data.tournamentId = this.props.tournamentId
        data.replies = []
        data.isParticipantRating = this.state.isParticipant
        this.props.TournamentApis.createTournamentRating(data).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                let ratingFields = {
                    // tournamentManagement: 0,
                    prizePoolDistribution: 0,
                    streamQuality: 0,
                    castingQuality: 0,
                    vfxQuality: 0,
                    conductionOfTournament: 0,
                    punctuality: 0,
                    resultDeclaration: 0,
                    disputeResolution: 0,
                }
                let participantsReviews = this.state.participantsReviews
                let viewersReviews = this.state.viewersReviews
                if (this.state.isParticipant) {
                    participantsReviews.push(res.data['result'])
                } else {
                    viewersReviews.push(res.data['result'])
                }
                this.setState({ participantsReviews: participantsReviews, viewersReviews: viewersReviews, openRateReview: false, ratingFields: ratingFields, comment: '' })

            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    onReply = () => {
        let participantsReviews = this.state.participantsReviews
        let viewersReviews = this.state.viewersReviews
        let data = {
            reply: this.state.reply,
            userId: profile()?.id,
            name: profile()?.name
        }

        let replies = {
            replies: [data]
        }
        this.props.TournamentApis.updateTournamentRating(this.state.commentId, replies).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                if (this.state.commentOnParticipant === true) {
                    participantsReviews[this.state.commentIndex].replies.push(data)
                } else {
                    viewersReviews[this.state.commentIndex].replies.push(data)
                }
                this.setState({ participantsReviews: participantsReviews, viewersReviews: viewersReviews, commentId: null, commentIndex: null, reply: '' })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    checkEligibility = () => {
        if(this.state.rating == 100 && !this.state.isParticipant){
            toast.warning('Please choose viewes review as you are not a participant.');
            return false
        }
        var idsArray = [];
        if(this.state.participantsReviews?.length){
            this.state.participantsReviews.map(review => {
                if(review.reviewerId){
                    idsArray.push(review.reviewerId);
                }
            })
        }
        if(this.state.viewersReviews?.length){
            this.state.viewersReviews.map(review => {
                if(review.reviewerId){
                    idsArray.push(review.reviewerId);
                }
            })
        }
        if(idsArray.includes(this.state.currentUserId)){
            toast.error('You have already posted your review.');
        }else{
            this.setState({ openRateReview: true }) 
        }
    }

    render() {
        const { ratings } = this.state;
        return (
            <section className='tournament-rules text-white'>
                <div className='container'>
                    <div className="row">
                        <div className='col-12 d-flex justify-content-between'>
                            <div className='b-5'>
                                <button className={`${this.state.rating === 100 ? 'green-round-btn' : 'black-round-btn'} rounded-circle fw-600 border border-2 border-dark me-3 btn-size-40`} onClick={() => { this.setState({ rating: 100 }) }}>
                                    <img src={playerIc} className="" alt='' width={'20px'} />
                                </button>

                                <button className={`${this.state.rating === 101 ? 'green-round-btn' : 'black-round-btn'} rounded-circle fw-600 border border-2 border-dark btn-size-40`} onClick={() => { this.getAllReview() }}>
                                    <img src={groupIc} className="" alt='' width={'20px'} />
                                </button>
                            </div>
                            <div>
                                <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40' onClick={this.checkEligibility}>
                                    <img src={greenRatingIc} className="" alt='' width={'14px'} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {(this.state.rating === 100) &&
                        <div className='row'>
                            {
                                this.state.participantsReviews.map((el, i) => {
                                    return (
                                        <div className='col-12 col-md-6 col-lg-4' key={i}>
                                            <div className='grey-card mt-2 p-4 mt-5' >
                                                <div className="d-flex justify-content-center">
                                                    <img src={el?.reviewerProfilePicture?.url} className="rounded-circle position-absolute btn-size-40" style={{ marginTop: "-43px" }} alt="" />
                                                    <h4 className="fs-14 fw-500">{el?.reviewerName}</h4>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white fw-bold fs-16 mb-0'>Overall Rating</h6>
                                                            </div>
                                                            <div className='col-5 fs-20 mt-1 d-flex justify-content-between'>
                                                                <StarRatingComponent
                                                                    name="rate1"
                                                                    starCount={5}
                                                                    value={el?.overallRating}
                                                                    emptyStarColor="#b4b4b4"
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                    editing={false}
                                                                />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.overallRating}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Tournament Management</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={2000 * 20 / 1000} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>3.5</h6>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Prize Pool Distribution</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.prizePoolDistribution * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.prizePoolDistribution}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Stream Quality</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.streamQuality * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.streamQuality}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Casting Quality</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.castingQuality * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.castingQuality}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>VFX Quality</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.vfxQuality * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.vfxQuality}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Conduction of Tournament</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.conductionOfTournament * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.conductionOfTournament}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Punctuality</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.punctuality * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.punctuality}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Result Declaration</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.resultDeclaration * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.resultDeclaration}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Dispute resolution</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.disputeResolution * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.disputeResolution}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-12 px-0'>
                                                        <h6 className='text-white fw-bold fs-16'>Review</h6>
                                                        <p className='text-white-50 fs-12 fw-500'>{el?.review}</p>
                                                        {
                                                            !el?.review && <p>No Review</p>
                                                        }
                                                        <h6 className='text-white fw-bold fs-16'>Reply</h6>
                                                        {
                                                            el.replies.map((reply, j) => {
                                                                return (
                                                                    <div key={j}>
                                                                        {/* <p className='text-white-50 fs-12 fw-500'>{reply?.name}</p> */}
                                                                        <p className='text-white-50 fs-12 fw-500'>{reply?.reply}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            !el.replies?.length && <p className='text-white-50 fs-12 fw-500'>No Reply</p>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    (el.replies?.length < 1 && this.state.isTO) &&
                                                    <div className='row'>
                                                        <div className='col-12 px-0'>
                                                            <button className="flat-btn border-0 px-4 fw-600" onClick={() => { this.setState({ commentId: el._id, commentIndex: i, commentOnParticipant: true }) }}>Reply</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {
                                !this.state.participantsReviews?.length &&
                                <p className='fs-14 fw-600 d-flex justify-content-center mt-5'>No Reviews by any participant</p>
                            }
                        </div>
                    }

                    {(this.state.rating === 101) &&
                        <div className='row'>
                            {
                                this.state.viewersReviews.map((el, i) => {
                                    return (
                                        <div className='col-12 col-md-6 col-lg-4' key={i}>
                                            <div className='grey-card mt-2 p-4 mt-5' >
                                                <div className="d-flex justify-content-center">
                                                    <img src={el?.reviewerProfilePicture?.url} className="rounded-circle position-absolute btn-size-40" style={{ marginTop: "-45px" }} alt="" />
                                                    <h4 className="fs-14 fw-500">{el?.reviewerName}</h4>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white fw-bold fs-16 mb-0'>Overall Rating</h6>
                                                            </div>
                                                            <div className='col-5 fs-20 mt-1 d-flex justify-content-between'>
                                                                <StarRatingComponent
                                                                    name="rate1"
                                                                    starCount={5}
                                                                    value={el?.overallRating}
                                                                    emptyStarColor="#b4b4b4"
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                    editing={false}
                                                                />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.overallRating}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Stream Quality</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.streamQuality * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.streamQuality}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Casting Quality</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.castingQuality * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.castingQuality}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>VFX Quality</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={el?.vfxQuality * 20} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.vfxQuality}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-12 px-0'>
                                                        <div className='row'>
                                                            <div className='col-7 mt-2'>
                                                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>VFX</h6>
                                                            </div>
                                                            <div className='col-5 mt-2 d-flex justify-content-between'>
                                                                <ProgressBar variant="warning" animated now={2000 * 200 / 5000} className='w-75 mx-0' />
                                                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>3.5</h6>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-12 px-0'>
                                                        <h6 className='text-white fw-bold fs-16'>Review</h6>
                                                        <p className='text-white-50 fs-12 fw-500'>{el?.review}</p>
                                                        {
                                                            !el?.review && <p >No Review</p>
                                                        }
                                                        <h6 className='text-white fw-bold fs-16'>Reply</h6>
                                                        {
                                                            el.replies.map((reply, j) => {
                                                                return (
                                                                    <div key={j}>
                                                                        {/* <p className='text-white-50 fs-12 fw-500'>{reply?.name}</p> */}
                                                                        <p className='text-white-50 fs-12 fw-500'>{reply?.reply}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            !el.replies?.length && <p className='text-white-50 fs-12 fw-500'>No Reply</p>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    (el.replies?.length < 1 && this.state.isTO) &&
                                                    <div className='row'>
                                                        <div className='col-12 px-0'>
                                                            <button className="flat-btn border-0 px-4 fw-600" onClick={() => { this.setState({ commentId: el._id, commentIndex: i, commentOnParticipant: false }) }}>Reply</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                !this.state.viewersReviews?.length &&
                                <p className='fs-14 fw-600 d-flex justify-content-center mt-5'>No Reviews by any Viewer</p>
                            }
                        </div>
                    }
                </div>

                <Modal centered show={this.state.openRateReview} dialogClassName="rate-review-modal grey-modal-bg">
                    <Modal.Body>
                        <div className='p-4'>
                            <div className='row '>
                                <div className='col-12 mb-3'>
                                    <p className="fw-bold fs-20 text-center text-white mb-0 position-absolute" style={{ right: '30%' }}>Rate & Review</p>
                                    <AiFillCloseCircle className="btn-size-20 text-white float-end me-3 mt-1" onClick={() => { this.setState({ openRateReview: false }) }} />
                                </div>

                                <div className='col-8 mt-1 p-0'>
                                    <h6 className='text-white fw-600 fs-14 mb-0'>Stream Quality</h6>
                                </div>
                                <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                    <StarRatingComponent
                                        name="streamQuality"
                                        starCount={5}
                                        value={this.state.ratingFields.streamQuality}
                                        emptyStarColor="#b4b4b4"
                                        onStarClick={this.onStarClick.bind(this)}
                                    />
                                </div>

                                <div className='col-8 mt-1 p-0'>
                                    <h6 className='text-white fw-600 fs-14 mb-0'>Casting Quality</h6>
                                </div>
                                <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                    <StarRatingComponent
                                        name="castingQuality"
                                        starCount={5}
                                        value={this.state.ratingFields.castingQuality}
                                        emptyStarColor="#b4b4b4"
                                        onStarClick={this.onStarClick.bind(this)}
                                    />
                                </div>

                                <div className='col-8 mt-1 p-0'>
                                    <h6 className='text-white fw-600 fs-14 mb-0'>VFX</h6>
                                </div>
                                <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                    <StarRatingComponent
                                        name="vfxQuality"
                                        starCount={5}
                                        value={this.state.ratingFields.vfxQuality}
                                        emptyStarColor="#b4b4b4"
                                        onStarClick={this.onStarClick.bind(this)}
                                    />
                                </div>
                                {
                                    (this.state.isParticipant && this.state.rating === 100) &&
                                    <div className="col-12">
                                        <div className="row">
                                            <div className='col-8 mt-1 p-0'>
                                                <h6 className='text-white fw-600 fs-14 mb-0'>Prize Pool Distribution</h6>
                                            </div>
                                            <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                                <StarRatingComponent
                                                    name="prizePoolDistribution"
                                                    starCount={5}
                                                    value={this.state.ratingFields.prizePoolDistribution}
                                                    emptyStarColor="#b4b4b4"
                                                    onStarClick={this.onStarClick.bind(this)}
                                                />
                                            </div>
                                            <div className='col-8 mt-1 p-0'>
                                                <h6 className='text-white fw-600 fs-14 mb-0'>Conduction Of Tournament</h6>
                                            </div>
                                            <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                                <StarRatingComponent
                                                    name="conductionOfTournament"
                                                    starCount={5}
                                                    value={this.state.ratingFields.conductionOfTournament}
                                                    emptyStarColor="#b4b4b4"
                                                    onStarClick={this.onStarClick.bind(this)}
                                                />
                                            </div>
                                            <div className='col-8 mt-1 p-0'>
                                                <h6 className='text-white fw-600 fs-14 mb-0'>Punctuality</h6>
                                            </div>
                                            <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                                <StarRatingComponent
                                                    name="punctuality"
                                                    starCount={5}
                                                    value={this.state.ratingFields.punctuality}
                                                    emptyStarColor="#b4b4b4"
                                                    onStarClick={this.onStarClick.bind(this)}
                                                />
                                            </div>
                                            <div className='col-8 mt-1 p-0'>
                                                <h6 className='text-white fw-600 fs-14 mb-0'>Result Declaration</h6>
                                            </div>
                                            <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                                <StarRatingComponent
                                                    name="resultDeclaration"
                                                    starCount={5}
                                                    value={this.state.ratingFields.resultDeclaration}
                                                    emptyStarColor="#b4b4b4"
                                                    onStarClick={this.onStarClick.bind(this)}
                                                />
                                            </div>
                                            <div className='col-8 mt-1 p-0'>
                                                <h6 className='text-white fw-600 fs-14 mb-0'>Dispute Resolution</h6>
                                            </div>
                                            <div className='col-4 p-0' style={{ fontSize: '20px' }}>
                                                <StarRatingComponent
                                                    name="disputeResolution"
                                                    starCount={5}
                                                    value={this.state.ratingFields.disputeResolution}
                                                    emptyStarColor="#b4b4b4"
                                                    onStarClick={this.onStarClick.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 px-0'>
                                    <h6 className='text-white fw-600 fs-14'>Comment</h6>
                                </div>
                                <div className='col-12 px-0'>
                                    <div className="mb-3">
                                        <textarea className="dark-gray-card border-0 w-100 text-white fs-12 p-3" id="exampleFormControlTextarea1" value={this.state.comment} onChange={(e) => { this.setState({ comment: e.target.value }) }} rows="3" placeholder='Write review...'></textarea>
                                    </div>
                                </div>
                            </div>
                            <button disabled={this.checkBlankPost()} className='flat-btn fs-16 border-0 px-4 fw-600 d-flex mx-auto py-2' onClick={this.postReview}>Post</button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal centered show={this.state.commentId} dialogClassName="rate-review-modal grey-modal-bg">
                    <div className='div'>
                        <AiFillCloseCircle className="btn-size-20 text-white float-end me-3 mt-1" onClick={() => { this.setState({ commentId: null, reply: '', commentIndex: null }) }} />
                        <div className='col-12 px-0'>
                            <h6 className='text-white fw-bold fs-16'>Reply</h6>
                        </div>
                        <div className='col-12 px-0'>
                            <div className="mb-3">
                                <textarea className="form-control border-0 text-white" id="exampleFormControlTextarea1" rows="2" style={{ backgroundColor: '#232323' }} value={this.state.reply} onChange={(e) => { this.setState({ reply: e.target.value }) }}></textarea>
                            </div>
                        </div>
                        <div className='col-12 px-0'>
                            <button className="flat-btn border-0 px-4 fw-600" onClick={this.onReply}>Send</button>
                        </div>
                    </div>
                </Modal>
            </section>
        )
    }
}