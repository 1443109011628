import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Accordion } from 'react-bootstrap';

//images
import player from '../../../../../../../images/player.png'
import score from '../../../../../../../images/icon/score.png';
import assists from '../../../../../../../images/icon/assists.png';
import kills from '../../../../../../../images/icon/kills.png';
import scores from '../../../../../../../images/icon/scores.png';
import deaths from '../../../../../../../images/icon/deaths.png';
import disputes from '../../../../../../../images/icon/dispute-ic.png'
import refresh from '../../../../../../../images/games/refresh.png'
import streams from '../../../../../../../images/icon/streaming.png';
import comments from '../../../../../../../images/icon/comments.png';

//components
import { dateFormat, errorHandler, getTournamentAdditionalData } from '../../../../../../../utils/common.utils';
import Switch from "react-switch"
import TournamentHeader from '../../../../common/TournamentHeader';
import MatchesApis from '../../../../../../../helper/matches.api';
import { connect } from 'react-redux';
import { setUpdateScore } from '../../../../../../../redux/actions/tournametActions';
import { Modal } from 'react-bootstrap';
import ParticipantDetails from "../../../../../../popup/ParticipantDetails";
import { toast } from 'material-react-toastify';

const mapStateToProps = (props) => {
    console.log('redux function::', props)
    return {
        score: props.updateScore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data) => dispatch(setUpdateScore(data))
    }
}
class MatchesDuelScoreUpdate extends Component {

    constructor(props) {
        super(props)
        this.matchesApis = new MatchesApis();
        this.state = {
            tournamentDetails: props.tournamentDetails,
            disableChat: false,
            matchDetails: props.score?.results ? props.score : null,
            openDetailsModal: false,
            detailsModalObj: {}
        }
        if (!this.props?.score?.results) {
            console.log('step 1')
            this.getMatchDetails(this.props.matchId);
        }
        console.log('MatchesDuelScoreUpdate props ---', props);
        console.log('tournament ID ---', props.id);
        getTournamentAdditionalData(props.id, this.setData);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ tournamentDetails: newProps.tournamentDetails });
    }
    showDetails = (data) => {
        this.setState({
            detailsModalObj: data,
            openDetailsModal: true
        })
    }
    async getMatchDetails(matchId) {
        console.log("step 2")
        var data = {
            ids: [matchId]
        }
        this.props.updateLoader(true);
        await this.matchesApis.getMultipleMatches(data).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                //console.log("Match Data : ", response);
                this.setState({
                    matchDetails: response[0]
                })
                // this.updateMatchDetails(response[0], response[0]['this.state.matchDetails?.participants'], joinTeamId, response[0]['maxParticipantsPerMatch']);
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    setData = (values) => {
        console.log('value ----------------', values);
        if (values) {
            this.setState({ tournamentDetails: values });
            this.props.updateLoader(false);
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    updateScore = (data?, key?) => {
        let result = { results: data };
        this.props.updateLoader(true);
        this.matchesApis.updateMatchResults(this.props.matchId, result).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                //this.props.history.goBack()
                //this.setState({matchDetails : response})
                this.getMatchDetails(this.props.matchId);
                this.props.updateLoader(false);
                if (key){
                    this.props.history.goBack();
                    toast.success('Match result updated successfully');
                }
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    submitPlayerScore = (val, i, j) => {
        val.score = (val.kills * 5) + (val.assists * 1);
        //console.log('Game Number ::', parseInt(this.props?.gameNo) + 1)
        const results = this.state.matchDetails?.results
        //console.log('result object::', results)
        //console.log(`i=${i} -- j=${j}`)
        //console.log('scores object::', val)
        // let participants = [];
        // this.state.matchDetails?.participants.map(el => participants.push(el._id))
        // console.log('participants ---', participants);
        let targetBody = results[parseInt(this.props?.gameNo)][i].individualScore[j];
        //console.log('target ::::', { ...targetBody, ...val });
        let target = { ...targetBody, ...val };
        const { matchDetails } = this.state;
        matchDetails.results[parseInt(this.props?.gameNo)][i].individualScore[j] = target;
        matchDetails.results[parseInt(this.props?.gameNo)][i].resultAdded = true;
        this.setState({ matchDetails });
        //console.log('match results ::',matchDetails.results);
        this.updateScore(matchDetails.results, false);
    }

    submitSoloPlayerScore = (val, i) => {
        val.score = (val.kills * 5) + (val.assists * 1);
        //console.log('Game Number ::', parseInt(this.props?.gameNo) + 1)
        const results = this.state.matchDetails?.results
        //console.log('result object::', results)
        // console.log(`i=${i}`)
        // console.log('scores object::', val)
        // let participants = [];
        // this.state.matchDetails?.participants.map(el => participants.push(el._id))
        // console.log('participants ---', participants);
        let targetBody = results[parseInt(this.props?.gameNo)][i];
        //console.log('target ::::', { ...targetBody, ...val });
        let target = { ...targetBody, ...val, resultAdded: true };
        const { matchDetails } = this.state;
        matchDetails.results[parseInt(this.props?.gameNo)][i] = target;
        this.setState({ matchDetails });
        //console.log('match results ::',matchDetails.results);
        this.updateScore(matchDetails.results, false);
    }

    getURL = (el) => {
        // console.log(' step4 Participant Details ------', el);
        // console.log('participantType ________', this.state.tournamentDetails?.participantType);
        if (this.state.tournamentDetails?.participantType === 'SINGLE') {
            //console.log('participant URL ::', el.profilePicture.url);
            return el.profilePicture.url;
        } else {
            //console.log('team URL ::', el.logo.url);
            return el.logo.url;
        }
    }

    render() {
        const gameNo = parseInt(this.props?.gameNo);
        console.log('step3 matchDetails----------------', this.state.matchDetails);
        return (
            <section className="body-section match-duel-score-update">
                <div className="container p-3 mb-5 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={score} heading="Score Update" subHeading={null} history={this.props.history} />
                    <div className='d-flex justify-content-between my-3'>
                        <h6 className='fs-16 fw-bold'>Show Down</h6>
                        <h6 className='fs-12 fw-bold' style={{ opacity: "0.7" }}>{dateFormat(this.state.matchDetails?.matchSchedule)}</h6>
                    </div>
                    {/* P1 VS P2 */}
                    {this.state.tournamentDetails ?
                        <div className='mulberry-purple-card rounded-10 my-3 p-2' style={{boxShadow: '-4px -4px 5px rgb(64 67 88 / 25%), 4px 4px 5px rgb(0 0 0 / 25%)'}}>
                            <h6 className='fs-20 fw-bold text-center my-3' >Game {parseInt(this.props?.gameNo) + 1}</h6>
                            <div className='row d-flex justify-content-between align-items-center pb-4'>
                                {typeof this.state.matchDetails?.participants[0] === 'object' ?
                                    <div className='col-5'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <img src={this.state.matchDetails?.participants[0] ? this.getURL(this.state.matchDetails?.participants[0]) : player} className='my-2 rounded-circle btn-size-52' />
                                            </div>
                                            <div className='col-12 text-center'>
                                                <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{this.state.tournamentDetails?.participantType === 'SINGLE' ? this.state.matchDetails?.participants[0]?.inGameName : this.state.matchDetails?.participants[0]?.name}</h6>
                                            </div>
                                            {/* {this.state.matchDetails?.results[gameNo][0]?.resultAdded ?
                                                this.state.matchDetails?.results[gameNo][0]?.isWinner ?
                                                    <p className='text-success text-center my-1 fs-14 fw-600'>WON</p>
                                                    :
                                                    <p className='text-danger text-center my-1 fs-14 fw-600'>LOST</p>
                                                :
                                                null
                                            } */}
                                            <div className='col-12 text-center'>
                                                <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(this.state.matchDetails?.participants[0]) }}>
                                                    <p className='fs-14 fw-500 my-1'>View Details</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-5'>
                                        <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                                    </div>
                                }
                                <div className='col-2'>
                                    <p className='fs-24 text-info fw-bold text-center'>VS</p>
                                </div>
                                {typeof this.state.matchDetails?.participants[1] === 'object' ?
                                    <div className='col-5'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <img src={this.state.matchDetails?.participants[1] ? this.getURL(this.state.matchDetails?.participants[1]) : player} className='my-2 rounded-circle btn-size-52' />
                                            </div>
                                            <div className='col-12 text-center'>
                                                <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{this.state.tournamentDetails?.participantType === 'SINGLE' ? this.state.matchDetails?.participants[1]?.inGameName : this.state.matchDetails?.participants[1]?.name}</h6>
                                            </div>
                                            {/* {this.state.matchDetails?.results[gameNo][1]?.resultAdded ?
                                                this.state.matchDetails?.results[gameNo][1]?.isWinner ?
                                                    <p className='text-success text-center my-1 fs-14 fw-600'>WON</p>
                                                    :
                                                    <p className='text-danger text-center my-1 fs-14 fw-600'>LOST</p>
                                                :
                                                null
                                            } */}
                                            <div className='col-12 text-center'>
                                                <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(this.state.matchDetails?.participants[1]) }}>
                                                    <p className='fs-14 fw-500 my-1'>View Details</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-5'>
                                        <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                                    </div>
                                }
                            </div>

                        </div>
                        :
                        null
                    }
                    {/* Score Details */}
                    {/* <div className='mulberry-purple-card shadow-none rounded-10 p-3' style={{ border: '1px solid white' }}> */}
                    <h6 className='fs-16 fw-bold text-center my-3'>API Details to fetch results</h6>
                    <div className="d-flex align-items-center">
                        <hr className="border-1 flex-grow-1" />
                        <div className="d-flex mx-3 align-items-center">
                            <p className="fs-16 fw-bold text-white-50 mb-0">Enter Score</p>
                        </div>
                        <hr className="border-1 flex-grow-1" />
                    </div>
                    {this.state.tournamentDetails ?
                        this.state.tournamentDetails?.participantType === 'SINGLE' ?
                            <Accordion>
                                {this.state.matchDetails?.participants?.map((participant, i) => {
                                    if (participant !== null) {
                                        console.log('result---',participant.inGameName,this.state.matchDetails?.results[gameNo][i])
                                        return (
                                            <Accordion.Item key={i} eventKey={i} className='mulberry-purple-card shadow-none rounded-10 my-3 border-0'>
                                                <Accordion.Header className='text-truncate'>Enter <span className='mx-1 text-truncate'> {participant?.inGameName} </span> Score</Accordion.Header>
                                                <Accordion.Body style={{ background: '#170d2a' }}>
                                                    <Formik
                                                        enableReinitialize
                                                        initialValues={{
                                                            score: this.state.matchDetails?.results[gameNo][i].score,
                                                            kills: this.state.matchDetails?.results[gameNo][i].kills,
                                                            deaths: this.state.matchDetails?.results[gameNo][i].deaths,
                                                            assists: this.state.matchDetails?.results[gameNo][i].assists,
                                                            isMVP: this.state.matchDetails?.results[gameNo][i].isMVP,
                                                            declareWinner: false
                                                        }}
                                                        validationSchema={Yup.object({
                                                            score: Yup.number().required('Enter Score').min(0,'cannot be negative'),
                                                            kills: Yup.number().required('Enter Kills').min(0,'cannot be negative'),
                                                            deaths: Yup.number().required('Enter Death').min(0,'cannot be negative'),
                                                            assists: Yup.number().required('Enter Assists').min(0,'cannot be negative'),
                                                            isMVP: Yup.bool(),
                                                            declareWinner: Yup.bool()
                                                        })}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                            setTimeout(() => {
                                                                this.submitSoloPlayerScore(values, i)
                                                                setSubmitting(false);
                                                            }, 400);
                                                        }}
                                                    >
                                                        <Form className="mt-3" autoComplete="off">
                                                            <div className='mulberry-purple-card shadow-none rounded-10 p-3 border border-1 border-white'>
                                                                <h6 className='fs-16 fw-bold text-center my-3 text-truncate'>{participant?.inGameName}</h6>
                                                                {/* Score */}
                                                                {/* <div className="form-group my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Score</label>
                                                                <div className="">
                                                                    <Field
                                                                        name="score" type="number" placeholder="Enter Score"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                    />
                                                                    <img src={scores} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                </div>
                                                                <ErrorMessage component="span" className="error-msg" name="score" />
                                                            </div> */}
                                                                {/* Kills */}
                                                                <div className="form-group my-1">
                                                                    <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                                    <div className="">
                                                                        <Field name="kills" type="number" placeholder="Enter Kills"
                                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        />
                                                                        <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                    </div>
                                                                    <ErrorMessage component="span" className="error-msg" name="kills" />
                                                                </div>
                                                                {/* Deaths */}
                                                                <div className="form-group my-1">
                                                                    <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                                    <div className="">
                                                                        <Field name="deaths" type="number" placeholder="Enter Deaths"
                                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        />
                                                                        <img src={deaths} alt="deaths" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                    </div>
                                                                    <ErrorMessage component="span" className="error-msg" name="deaths" />
                                                                </div>
                                                                {/* Assists */}
                                                                <div className="form-group my-1">
                                                                    <label className='fs-14 fw-600 text-white mb-1'>Assists</label>
                                                                    <div className="">
                                                                        <Field name="assists" type="number" placeholder="Enter Assists"
                                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        />
                                                                        <img src={assists} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                    </div>
                                                                    <ErrorMessage component="span" className="error-msg" name="assists" />
                                                                </div>
                                                                <div className='my-1'>
                                                                    <Field name='isMVP' type='checkbox' className='me-2' />
                                                                    <label className='fs-14 fw-600 text-white mb-1'>Is MVP</label>
                                                                </div>
                                                                <button className='contact-toggle d-flex align-items-center rounded-10 height-45 my-3 mx-auto text-white px-3' type='submit'>Submit Score</button>
                                                            </div>
                                                        </Form>
                                                    </Formik>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    } else {
                                        return (
                                            <Accordion.Item eventKey={i} className='mulberry-purple-card shadow-none rounded-10 my-3 border-0'>
                                                <Accordion.Header>No Participant Joined</Accordion.Header>
                                                <Accordion.Body style={{ background: '#170d2a' }}></Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }
                                })}
                            </Accordion>
                            :
                            <Accordion>
                                {this.state.matchDetails?.participants?.map((team, i) => {
                                    console.log(`team ${i} details ::`, team);
                                    if (team?.members?.players?.length === 0) {
                                        return (
                                            <Accordion.Item key={i} eventKey={i} className='mulberry-purple-card shadow-none rounded-10 my-3 border-0'>
                                                <Accordion.Header>{team?.name}</Accordion.Header>
                                                <Accordion.Body style={{ background: '#170d2a' }}>
                                                    No players in this team
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    } else {
                                        return (
                                            <Accordion.Item key={i} eventKey={i} className='mulberry-purple-card shadow-none rounded-10 my-3 border-0'>
                                                <Accordion.Header>{team?.name}</Accordion.Header>
                                                <Accordion.Body style={{ background: '#170d2a' }}>
                                                    {/* <div className='my-1'>
                                                        <input  type='checkbox' className='me-2' />
                                                        <label className='fs-14 fw-600 text-white mb-1'>Declare {team?.name} as Winner</label>
                                                    </div> */}
                                                    {this.state.matchDetails?.results[0][i]?.individualScore?.map((player, j) => {
                                                        console.log(`player ${i, j} ::`, player)
                                                        return (
                                                            <Formik  key={j}
                                                                enableReinitialize
                                                                initialValues={{
                                                                    score: player?.score,
                                                                    kills: player?.kills,
                                                                    deaths: player?.deaths,
                                                                    assists: player?.assists,
                                                                    isMVP: player?.isMVP
                                                                }}
                                                                validationSchema={Yup.object({
                                                                    score: Yup.number().required('Enter Score').min(0,'cannot be negative'),
                                                                    kills: Yup.number().required('Enter Kills').min(0,'cannot be negative'),
                                                                    deaths: Yup.number().required('Enter Death').min(0,'cannot be negative'),
                                                                    assists: Yup.number().required('Enter Assists').min(0,'cannot be negative'),
                                                                    isMVP: Yup.bool()
                                                                })}
                                                                onSubmit={(values, { setSubmitting }) => {
                                                                    setTimeout(() => {
                                                                        this.submitPlayerScore(values, i, j)
                                                                        setSubmitting(false);
                                                                    }, 400);
                                                                }}
                                                            >
                                                                <Form className="mt-3" autoComplete="off">
                                                                    <div className='mulberry-purple-card shadow-none rounded-10 p-3 border border-1 borer-white'>
                                                                        <h6 className='fs-16 fw-bold text-center my-3 text-truncate' >{player.participantDetails.inGameName}</h6>
                                                                        {/* P1 Score */}
                                                                        {/* <div className="form-group my-1">
                                                                    <label className='fs-14 fw-600 text-white mb-1'>Score</label>
                                                                    <div className="">
                                                                        <Field
                                                                            name="score" type="number" placeholder="Enter Score"
                                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        />
                                                                        <img src={scores} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                    </div>
                                                                    <ErrorMessage component="span" className="error-msg" name="score" />
                                                                </div> */}
                                                                        {/* P1 Kills */}
                                                                        <div className="form-group my-1">
                                                                            <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                                            <div className="">
                                                                                <Field
                                                                                    name="kills" type="number" placeholder="Enter Kills"
                                                                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                                />
                                                                                <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                            </div>
                                                                            <ErrorMessage component="span" className="error-msg" name="kills" />
                                                                        </div>
                                                                        {/* P1 Deaths */}
                                                                        <div className="form-group my-1">
                                                                            <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                                            <div className="">
                                                                                <Field
                                                                                    name="deaths" type="number" placeholder="Enter Deaths"
                                                                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                                />
                                                                                <img src={deaths} alt="deaths" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                            </div>
                                                                            <ErrorMessage component="span" className="error-msg" name="deaths" />
                                                                        </div>
                                                                        {/* P1 Assists */}
                                                                        <div className="form-group my-1">
                                                                            <label className='fs-14 fw-600 text-white mb-1'>Assists</label>
                                                                            <div className="">
                                                                                <Field
                                                                                    name="assists" type="number" placeholder="Enter Assists"
                                                                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                                />
                                                                                <img src={assists} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                            </div>
                                                                            <ErrorMessage component="span" className="error-msg" name="assists" />
                                                                        </div>
                                                                        <div className='my-1'>
                                                                            <Field name='isMVP' type='checkbox' className='me-2' />
                                                                            <label className='fs-14 fw-600 text-white mb-1'>Is MVP</label>
                                                                        </div>
                                                                        <button className='contact-toggle d-flex align-items-center rounded-10 height-45 my-3 mx-auto text-white px-3' type='submit'>Submit Score</button>
                                                                    </div>
                                                                </Form>
                                                            </Formik>
                                                        )
                                                    })}
                                                    
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }

                                })}
                            </Accordion>
                        :
                        null
                    }


                    <div className="contact-toggle d-flex align-items-center rounded-10 height-45 my-3">
                        <p className="fs-16 fw-600 mb-0 flex-grow-1 ps-3">Disable Chat</p>
                        <Switch checked={this.state.disableChat}
                            checkedIcon={false} uncheckedIcon={false} className="me-2"
                            onChange={(e) => this.setState({ disableChat: !this.state.disableChat })}
                        />
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <div className='position-relative d-flex justify-content-center'>
                            <div className='text-center'>
                                <button onClick={()=>this.updateScore(this.state.matchDetails?.results, true)} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3'>
                                    <img src={refresh} alt="" />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Update</p>
                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' disabled={this.state.disableChat} style={{ opacity: this.state.disableChat ? 0.7 : 1 }}>
                                    <img src={comments} alt="" />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Chat</p>
                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3'>
                                    <img src={disputes} alt="" style={{ height: '30px', width: '30px' }} />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Disputes</p>
                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3'>
                                    <img src={streams} alt="" />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Stream</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.openDetailsModal} centered dialogClassName="ClanInvite-modal">
                    <Modal.Body>
                        <ParticipantDetails updateLoader={this.props.updateLoader} details={this.state.detailsModalObj} type={this.state.tournamentDetails?.participantType} onClose={() => { this.setState({ openDetailsModal: false }) }} />
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(MatchesDuelScoreUpdate)