import React, { Component } from 'react';
//images
import profileLogo from '../../../../images/den/den-profile-logo.png';
//icons
import PropTypes from 'prop-types';

import { POST_INTERACTIONS, PROFILE_PAGE_IMAGES } from '../../../../utils/Images.utils';
import Image from '../../../common/Image';
import ClanDenTopTab from '../../commonTabs/ClanDenTopTab';
import { IoIosArrowBack, IoIosCloseCircleOutline } from 'react-icons/io';

/**
* @author
* @class DenTopFans
**/

class DenTopFans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount(){}

    render() {
        const profileCard  = { background: '#231D36',  borderRadius: '8px' };
        //console.log(':::', PROFILE_PAGE_IMAGES);
        return(
            <div className='body-section den_page'>
                <ClanDenTopTab />
                {/* BOTTOM SECTION */}
                <div className='container'>
                    <div className='row my-2'>
                        <div className='col-12 p-3'>
                            <div className='d-flex flex-column'>
                                <div className='post-card-bg p-2' style={profileCard}>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <IoIosArrowBack className='cursor-pointer my-0' style={{height: '20px', width: '20px'}} onClick={() => this.props.history.goBack()} />
                                        </div>
                                        <div className='col-8'><p className='fs-16 text-center fw-700 my-0'>Top Fans</p></div>
                                        <div className='col-2 text-end'>
                                            <IoIosCloseCircleOutline className='cursor-pointer my-0' style={{height: '20px', width: '20px'}} onClick={() => this.props.history.goBack()} />
                                        </div>
                                    </div>
                                    <hr className='' style={{border: '1px solid rgba(173, 38, 255, 0.25)'}} />
                                    <div className='row'>
                                        {[...Array(5)].map((el, i) => {
                                            return(
                                            <div className='col-12' key={i}>
                                                <div className='d-flex align-items-center'>
                                                    <img src={profileLogo} alt='' className='' style={{height:'40px', width:'40px'}}/>
                                                    <div className='flex-grow-1 mx-2'>
                                                        <p className='fs-16 fw-600 my-0'>Manoj</p>
                                                        <spam className='fs-12 text-white-50 fw-500'>Gamer</spam>
                                                    </div>
                                                    <Image src={POST_INTERACTIONS.SHARE.OFF} params={'share'} className='cursor-pointer' style={{height:'auto', width:'28px'}} />
                                                    <p className='fs-18 fw-600 text-white-50 my-0 mx-2'>OP</p>
                                                </div>
                                                <hr className='hr-line my-1' />
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } 
}


DenTopFans.propTypes = {}
export default DenTopFans