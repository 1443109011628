import { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiFillCloseCircle, AiOutlineCloudUpload } from "react-icons/ai";
import Switch from "react-switch"
import CommonApis from '../../../../helper/common.api';

export default class Merchandise extends Component {
    commonApi = new CommonApis()
    constructor(props) {
        super(props);
        this.state = {
            isMerchandise: props.merchandiseDetails?.data ? props.merchandiseDetails?.data.merchandise : false,
            details: {
                prizeAmount: props.merchandiseDetails?.data ? parseInt(props.merchandiseDetails?.data.prizeAmount) : '',
                merchandiseHeading: props.merchandiseDetails?.data ? props.merchandiseDetails?.data.merchandiseHeading : '',
                merchandiseDescription: props.merchandiseDetails?.data ? props.merchandiseDetails?.data.merchandiseDescription : '',
            },
            merchandiseImage: props.merchandiseDetails?.data ? props.merchandiseDetails?.data.merchandiseImage : null
        }
    }
    updatePrizeDetails = (e, type) => {
        e.preventDefault()
        let details = this.state.details
        details[type] = e.target.value
        this.setState({ details: details })
    }

    onBannerChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                merchandiseImage: URL.createObjectURL(img)
            });
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            this.props.updateLoader(true);
            this.commonApi.uploadSingleImg(formData)
                .then((response: AxiosResponse) => {
                    this.setState({
                        merchandiseImage: response.data.result
                    });
                    this.props.updateLoader(false);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    this.props.updateLoader(false);
                });
        }
    };
    triggerInputFile = () => this.fileInput.click()

    render() {
        return (
            <div>
                <div className='p-3'>
                    <p className='fs-16 fw-600 text-center text-white mb-0'>Rank {this.props.merchandiseDetails.rank}</p>
                    <AiFillCloseCircle className="close-icon position-absolute btn-size-20" style={{ right: '15px', top: '15px' }} onClick={this.props.onClose} />
                    <Formik
                        enableReinitialize
                        initialValues={{
                            prizeAmount: this.state.details?.prizeAmount ? this.state.details.prizeAmount : '',
                            merchandise: this.state.isMerchandise,
                            merchandiseHeading: this.state.details?.merchandiseHeading ? this.state.details.merchandiseHeading : '',
                            merchandiseDescription: this.state.details?.merchandiseDescription ? this.state.details.merchandiseDescription : '',
                            merchandiseImage: this.state.merchandiseImage
                        }}
                        validationSchema={Yup.object({
                            prizeAmount: Yup.number().required('Enter Prize Amount').min(1, 'Cannot be less than 1 EC'),
                            merchandiseHeading: (this.state.isMerchandise) ? Yup.string().required('*required') : Yup.string(),
                            merchandiseDescription: (this.state.isMerchandise) ? Yup.string().required('*required') : Yup.string(),
                            merchandiseImage: (this.state.isMerchandise) ? Yup.mixed().required('*required') : Yup.mixed(),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.props.merchandiseDetails?.data ?
                                    this.props.onUpdate(this.props.merchandiseDetails.rank, values) :
                                    this.props.onAdd(values)
                                setSubmitting(false);
                            }, 400);
                        }}>
                        <Form className="mx-3  mt-2" autoComplete="off">
                            {/* Prize Amount */}
                            <div className="my-1">
                                <label htmlFor='customField' className="fs-14 fw-600 text-white">Prize Amount in EC*</label>
                                <div className="">
                                    <Field
                                        name='prizeAmount' type="number"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 height-45 fw-500"
                                        placeholder="Enter in EC"
                                        onChange={e => this.updatePrizeDetails(e, 'prizeAmount')}
                                        min={1} step={1} onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                                <ErrorMessage component="span" name="prizeAmount" className="error-msg" />
                            </div>
                            <div className="catalina-blue my-3 height-45 d-flex align-items-center my-3">
                                <p className="fs-16 fw-600 text-white flex-grow-1 my-auto ms-2">Merchandise</p>
                                <Switch onChange={e => this.setState({ isMerchandise: !this.state.isMerchandise })} checked={this.state.isMerchandise} checkedIcon={false} uncheckedIcon={false} className="me-2" />
                            </div>
                            <div className='p-0' style={{ display: (this.state.isMerchandise) ? 'block' : 'none' }}>
                                {/* Merchandise Heading */}
                                <div className="my-1">
                                    <label htmlFor='customField' className="fs-14 fw-600 text-white">Merchandise Heading</label>
                                    <div className="">
                                        <Field
                                            name='merchandiseHeading' type="text"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            placeholder="Enter Heading"
                                            onChange={e => this.updatePrizeDetails(e, 'merchandiseHeading')}
                                        />
                                    </div>
                                    <ErrorMessage component="span" name="merchandiseHeading" className="error-msg" />
                                </div>
                                {/* Merchandise Description */}
                                <div className="my-1">
                                    <label htmlFor='customField' className="fs-14 fw-600 text-white">Merchandise Description</label>
                                    <Field
                                        name="merchandiseDescription" type="text"
                                        as="textarea" rows="3"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14"
                                        placeholder="Enter Description"
                                        onChange={e => this.updatePrizeDetails(e, 'merchandiseDescription')}
                                    />
                                    <ErrorMessage component="span" className="error-msg" name="merchandiseDescription" />
                                </div>
                                {/* Merchandise Image */}
                                <label htmlFor='customField' className="fs-14 fw-600 text-white">Merchandise Image</label>
                                
                                <div className="row d-flex justify-content-center m-2">
                                    {/* Banner */}
                                    <div className="col-8 col-md-6">
                                        <div className="uploadWithPreview">
                                            <div className="upload-section">
                                                <input type="file" accept=".png, .jpg, .jpeg" id="upload_cover_file" hidden onChange={this.onBannerChange} ref={fileInput => this.fileInput = fileInput} name="" className="image-input" />
                                                {
                                                    this.state.merchandiseImage ?
                                                        <div className="text-right flex-column text-end" >
                                                            <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={() => { this.setState({ tournamentBanner: null, bannerURL: '' }) }} />
                                                            <div className="after-uploaded-image" onClick={this.triggerInputFile}>
                                                                <img src={this.state.merchandiseImage?.url ? this.state.merchandiseImage?.url : this.state.merchandiseImage} alt="" />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="before-uploaded-panel">
                                                            <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                                                <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile}>
                                                                    <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                                    <h1 className="fs-16 fw-600 text-white-50 mb-0" htmlFor="exampleInputText">Image</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <ErrorMessage component="span" className="error-msg" name="merchandiseImage" />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button className='white-flat-btn d-block fw-bold py-1 px-4 m-auto' type='submit'>
                                    {this.props.merchandiseDetails?.data ? 'Update' : 'Add'}
                                </button>
                            </div>
                        </Form>
                    </Formik>

                </div>
            </div>
        )
    }
}
