import React, { Component } from "react";
import { MdZoomOutMap } from "react-icons/md";
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import "../../../../../styles/brackets.css";
import "../../../../../styles/Tournaments.css";
import TournamentHeader from "../../common/TournamentHeader";
import structureIc from '../../../../../images/icon/stageImg.png';
import swapIc from '../../../../../images/icon/swap-ic.png';
import timestampIc from '../../../../../images/icon/timestamp-ic.png';
import { NavLink } from 'react-router-dom';

import { Button, Modal } from "react-bootstrap";
import { HiUserAdd } from "react-icons/hi";
import { errorHandler, getMatchAndParticipantsDetailsForBracketsDoubleElimination, getMonthAndDate, getTournamentAdditionalData, getTournamentData, months } from "../../../../../utils/common.utils";
import MatchInvite from "../../../../popup/MatchInvite";
import { toast } from 'material-react-toastify';
import MatchesApis from "../../../../../helper/matches.api";
import DoubleBrackets from "../../../../common/DoubleBrackets";
import Seeding from "../Seeding";
import SeedingSwitch from "../../common/SeedingSwitch";
import NoRecordFound from "../../../../common/NoRecordFound";
//import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export default class DoubleElimination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.state.tournamentDetails,
      structure: {},
      stageMatchDetails: props.state.tournamentDetails.stages[this.props.index].groups,
      scale: 1.1,
      openInvite: false,
      matchId: -1,
      selectedPlayers: [],
      isSwappingEnabled: false,
      isWinningBrackets: true,
      enableSeeding: false
    };

    this.matchesApis = new MatchesApis();

    this.isSwapped = false;
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
    if (this.props?.id) {
      //this.props.updateLoader(true);
     // getTournamentAdditionalData(this.props.id, this.setData)
      console.log('getTournamentAdditionalData called');
    }
  }

  setData = (values) => {
    console.log('setData called');
    console.log('setData values::', values);
    if (values) {
      this.setState({
        tournamentDetails: values,
        structure: values.stages[this.props.index]
      });
      this.props.updateLoader(false);
      console.log('group index ::', this.props.index);
      getMatchAndParticipantsDetailsForBracketsDoubleElimination(values._id, this.setMatchData, this.props.index, values.stages);

      //getMatchAndParticipantsDetails(values.stages[this.props.index],this.setMatchData);

    } else {
      //this.props.updateLoader(false);
      this.props.history.push('/pageNotFound')
    }

  }


  setMatchData = (values) => {
    console.log('setMatchData called');
    console.log('values --', values)
    if (values) {
      var tournamentDetails = this.state.tournamentDetails;
      tournamentDetails.stages = values;
      this.setState({
        tournamentDetails: tournamentDetails
      })
      //this.props.updateLoader(false);

    } else {
      //this.props.updateLoader(false);
      this.props.history.push('/pageNotFound');
      console.log('Not found 2 called');
    }

  }

  calculateMatches = (num, threshold) => {
    //console.log("is Winning :: ",this.state.isWinningBrackets)
    var stages = 1;
    var arr = [num];
    while (num !== 1) {
      num = (num / 2);
      arr.push(num);
      stages++;
      if (num === threshold) break;
    }
    if (this.state.isWinningBrackets) {
      arr.push(1)
      return arr;
    } else {
      arr.shift();

      var structure2 = [];
      arr.forEach((val, i) => {
        structure2.push(val);
        structure2.push(val);
      });
      return structure2;
    }
  }

  onClickOpenInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite
    })
  }

  openInviteModal = (e, matchDetail, matchIndex,matchNo) => {
    e.preventDefault();
    this.setState({
      openInvite: true,
      matchId: matchIndex
    });
  }

  enableDisableSwapping = () => {
    this.setState({
      isSwappingEnabled: !this.state.isSwappingEnabled,
      selectedPlayers: []
    });
  }

  handleInputChange = (event, matchDetails, roundNumber, matchNumber) => {
    const target = event.target;
    const isChecked = target.type === 'checkbox' ? target.checked : target.value;
    const playerID = target.id;

    var selectedPlayers = this.state.selectedPlayers;
    if (isChecked) {
      if (selectedPlayers.length == 2) {
        toast.warning("At a time only two players can be swapped");
        target.checked = false;
      } else {
        selectedPlayers.push({
          teamID: playerID,
          matchDetails: matchDetails,
          roundNumber: roundNumber,
          matchNumber: matchNumber
        });
        this.setState({
          selectedPlayers: selectedPlayers
        });
      }
    } else if (selectedPlayers.length > 0) {
      selectedPlayers = selectedPlayers.filter(function (obj) { return obj.teamID !== playerID });
      this.setState({
        selectedPlayers: selectedPlayers
      });
    }

  }

  swapPositions = () => {
    this.isSwapped = true;

    var selectedPlayers = this.state.selectedPlayers;
    if (selectedPlayers[0].matchDetails._id === selectedPlayers[1].matchDetails._id) {
      toast.warning("You can not swipe two players within a same match.");
    } else {
      this.props.updateLoader(true);
      var updatedMatches = [];

      selectedPlayers.forEach((player, index) => {
        var participants = [];
        player.matchDetails.participants.forEach((teamDetails) => { participants.push(teamDetails._id) });
        player.matchDetails.participants = participants;
        selectedPlayers[index] = player;
        updatedMatches.push(player.matchDetails);
      });


      updatedMatches[0].participants = updatedMatches[0].participants.map(function (team) { return team === selectedPlayers[0].teamID ? selectedPlayers[1].teamID : team });
      updatedMatches[1].participants = updatedMatches[1].participants.map(function (team) { return team === selectedPlayers[1].teamID ? selectedPlayers[0].teamID : team });


      this.matchesApis.swapParticipants(updatedMatches).then(
        (res: AxiosResponse) => {
          this.enableDisableSwapping();

          this.setState({
            tournamentDetails: res.data.result,
            stageMatchDetails: res.data.result.stages[this.props.index].groups,
          });

          this.props.updateLoader(false);

          toast.success("Participants Swapped Successfully");
        }
      ).catch(
        (err: AxiosError) => {
          this.props.updateLoader(false);
          errorHandler(err);
        }
      );


    }
  }

  editTimeSlot = () => {
    this.props.history.push(`/organization/${this.props.orgId}/tournament/${this.props.id}/overview/structure/tournament-stages/${this.props.index}/slot-details/${this.state.isWinningBrackets ? 0 : 1}`);
  }

  enableDisableSeeding = () =>{
    this.setState({ enableSeeding: !this.state.enableSeeding });
  }

  closeSeeding = (tournamentDetails,updatedStage) =>{
    tournamentDetails.stages[this.props.index] = updatedStage;
    this.setState({ 
      enableSeeding: false,
      tournamentDetails: tournamentDetails,
      stageMatchDetails: updatedStage.groups,
    });
  }


  openInviteModal2 = () =>{
    this.setState({
      openInvite: true,
      matchId: null
    });
  }

  render() {

    const showBrackets = ((this.state.tournamentDetails && this.state.tournamentDetails.stages[this.props.index]) ? true : false);
    var pairs = 2;
    var threshold = 1

    if (showBrackets) {
      pairs = (this.state.tournamentDetails.stages[this.props.index].maxParticipants / 2)
      threshold = (this.state.tournamentDetails.stages[this.props.index].qualificationThreshold / 2)
 
      var structure = this.calculateMatches(pairs, threshold);
      console.log((parseInt(this.props.index) === 0)+"88 this.props.index :: ",this.props.index)
    
      const showLoosingBracket = this.state.isWinningBrackets;
      const enableSeedingFilter = (parseInt(this.props.index) === 0);
      return (
        <section className="body-section clanView-page">

          {/* Header Section */}
          <div className="w-100 p-2 position-fixed pt-lg-3" style={{ zIndex: '1', background: '#200e45' }}>
            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading={showBrackets ? this.state.tournamentDetails.stages[this.props.index].name : "Stage Name"} subHeading={<h1 className="stage-sub-heading text-center">( Double Elimination )</h1>} history={this.props.history} />
            <div className='d-flex my-2 add-time justify-content-center'>
              {this.state.selectedPlayers.length == 2 ?
                <button className="add-btn fs-12 text-black me-2 mb-0 fw-600 rounded-15" onClick={this.swapPositions}>
                  <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> Swap Position
                </button>
                :
                <button disabled={this.state.enableSeeding} className="add-btn fs-12 text-black me-2 mb-0 fw-600 rounded-15" onClick={this.enableDisableSwapping}>
                  <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> {this.state.isSwappingEnabled ? "Disable Swapping" : "Enable Swapping"}
                </button>
              }
              <button type="button" onClick={this.editTimeSlot} className="add-btn fs-12 text-black mb-0 fw-600 rounded-15">
                <img src={timestampIc} className='btn-size-20 me-2 mb-1' alt=''/> Edit Time Slot
              </button>
            </div>
        
            {/* Switch between seeding & brackets structure */}
            {enableSeedingFilter ? <SeedingSwitch enableDisableSeeding={this.enableDisableSeeding} enableSeeding={this.state.enableSeeding} /> : null}
              
          </div>

          {/* Brackets and Seeding Tabs */}
          {this.state.enableSeeding ?
              <Seeding marginTopDiv='seeding-page' closeSeeding={this.closeSeeding} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.tournamentDetails?.stages[this.props.index]} />
              :
              <DoubleBrackets 
                structure={structure} 
                includeThirdPlace={this.state.tournamentDetails?.stages[this.props.index]?.includeThirdPlace} 
                isSwappingEnabled={this.state.isSwappingEnabled} 
                handleInputChange={this.handleInputChange} 
                openInviteModal={this.openInviteModal} 
                stageMatchDetails={this.state.stageMatchDetails} 
                isTournamentOperator={true}
                showLoosingBracket = {showLoosingBracket}
                enabledGrandFinal ={this.state.tournamentDetails?.stages[this.props.index]?.enabledGrandFinal} 
                tournamentDetails={this.state.tournamentDetails}
                addMarginTop={true}
                index={this.props.index}
                stageDetails={this.state.tournamentDetails?.stages[(parseInt(this.props.index)) -1]}
                openInviteModal2={this.openInviteModal2}
                marginTopDiv={enableSeedingFilter ? 'seeding-page' : 'mt-13'}
              /> 
          }

          {/* Tournament Invitation Section */}
          <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
            <Modal.Body>
              <MatchInvite
                onClickOpenInvite={this.onClickOpenInvite}
                tournamentId={this.props.id}
                stageId={this.props.index}
                matchId={this.state.matchId}
                gameId={this.state.tournamentDetails?.gameId}
                participantType={this.state.tournamentDetails?.participantType}
                choice="Send Invite"
              />
            </Modal.Body>
          </Modal>

        </section>
      );

    }else {
      return (
        <NoRecordFound />
      );
    }
  }
}
