import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../styles/activate-account.css";
import ClanApis from "../../helper/clan.api";
import { AxiosResponse, AxiosError } from "axios";
import { toast } from 'material-react-toastify';
import { errorHandler } from "../../utils/common.utils";
import bioIcon from '../../images/icon/bio-icon.png';

class BioEdit extends Component {
    clanApi = new ClanApis()
    constructor(props) {
        super(props);
        this.state = {
            bio: this.props.clanData.bio
        };
    }


    updateBio = (e) => {
        this.setState({ bio: e.target.value })
    }
    onSubmit = () => {
        const data = this.props.clanData
        if (this.state.bio.trim().length === 0) {
            toast.warning('Bio cannot be empty')
            return false
        }else if(this.state.bio.trim().length > 500){
            toast.warning('Max 500 characters allowed')
            return false
        }
        else{
            data.bio = this.state.bio
        this.props.updateLoader(true);
        data.members.creator = data?.members?.creator.id;
        data.members.owner = data?.members?.owner?.map(el => { return el.id })
        data.members.admin = data?.members?.admin?.map(el => { return el.id })
        this.clanApi.updateClan(this.props.clanData._id, data).then(
            (res: AxiosResponse) => {
                this.props.bioChange()
                toast.success('Bio Updated')
                this.props.onClickOpenBioEdit()
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
        }
        
    }
    render() {
        return (
            <>
                <div className="p-3">
                    <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={()=>{this.props.onClickOpenBioEdit()}} />
                    <p className="fw-bold fs-20 text-center text-white mb-0">
                    <img src={bioIcon} className="mb-1 me-1" alt='' style={{ width: '17px' }} />
                        Bio</p>

                    <form >
                        <textarea className="martinique-card fs-12 fw-600 p-3 mt-3 text-white w-100" rows="6" maxLength="500" value={this.state.bio} onChange={this.updateBio}/>

                        <button className="white-flat-btn fw-bold py-2 px-4 d-block m-auto mt-3" type="button"  onClick={this.onSubmit}>Update</button>
                    </form>
                </div>
            </>
        )
    }
}

export default BioEdit