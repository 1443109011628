import { Component } from 'react';
//Components
import LayoutOne from '../layouts/LayoutOne';
import LayoutTwo from '../layouts/LayoutTwo';
import LayoutThree from '../layouts/LayoutThree';
import MicroWebsiteAboutUs from './MicroWebSiteAboutUs';
import MicroWebSiteTournaments from './MicroWebSiteTournaments';

class MicroWebSiteHome extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            microWebSiteData : props.microWebSiteData,
            page : props.microWebSiteData.layout
        };
    }

    componentDidMount(){
        
    }

    getLayout =(page)=> {
        switch(page) {
            case 'one':
                return <LayoutOne isPreview={false} redirectTo={this.redirectTo} path={this.props.path} showOrHideNav={this.props.showOrHideNav} microWebSiteData={this.state.microWebSiteData} />;
            case 'two':
                return <LayoutTwo isPreview={false} redirectTo={this.redirectTo} path={this.props.path} showOrHideNav={this.props.showOrHideNav} microWebSiteData={this.state.microWebSiteData} />;
            case 'three':
                return <LayoutThree isPreview={false} redirectTo={this.redirectTo} path={this.props.path} showOrHideNav={this.props.showOrHideNav} microWebSiteData={this.state.microWebSiteData} />;
            case 'tournament':
                return <MicroWebSiteTournaments redirectTo={this.redirectTo} path={this.props.path} showOrHideNav={this.props.showOrHideNav} microWebSiteData={this.state.microWebSiteData} />;     
            case 'about':
                return <MicroWebsiteAboutUs redirectTo={this.redirectTo} path={this.props.path} showOrHideNav={this.props.showOrHideNav} microWebSiteData={this.state.microWebSiteData} />;      
            default:
                return this.getLayout(this.state.microWebSiteData.layout) 

        }
    }

    redirectTo = (path) =>{
        // var data = this.state.microWebSiteData;
        // data.layout = path
        this.setState({
            page : path
        });
    }

    
    render() {
        const layout = this.getLayout(this.state.page);
        return(
          <div className="App">
            {layout}
          </div>
        );
      }
}

export default MicroWebSiteHome;
