import React, { Component } from 'react';
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import { MdZoomOutMap } from "react-icons/md";
import { FaUserPlus } from "react-icons/fa";
import { zoomBrackets } from '../../utils/common.utils';

export default class ZoomButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scale: props.scale, 
            maxWidth: props.maxWidth,  
        };
    }

    // Updated values for zooming ratio
    componentWillReceiveProps(newProps) {
        this.setState({
            scale: newProps.scale? newProps.scale : this.state.scale,
            maxWidth: newProps.maxWidth? newProps.maxWidth : this.state.maxWidth,
        })
    }

    render() {
        return (
            <div className="icons-box d-flex justify-content-center w-100">
                {/* To Zoom Out */}
                <div className="icon mx-2" onClick={() => zoomBrackets('Out',this.state.scale, this.state.maxWidth, this.props.setZoomState)}>
                    <ImZoomOut className="zoom-icon" />
                </div>
                {/* To Reset Zoom */}
                <div className="icon mx-2" onClick={() => zoomBrackets('Reset',this.state.scale, this.state.maxWidth, this.props.setZoomState)}>
                    <MdZoomOutMap className="zoom-icon" />
                </div>
                {/* To Zoom In */}
                <div className="icon mx-2" onClick={() => zoomBrackets('In',this.state.scale, this.state.maxWidth, this.props.setZoomState)}>
                    <ImZoomIn className="zoom-icon" />
                </div>
                {(this.props.isTournamentOperator && this.props.stageIndex === 0) ? 
                    <div className="icon mx-2" onClick={() => this.props.openInviteModal2()}>
                        <FaUserPlus className="zoom-icon" />
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}