import { Component } from 'react';
import { NavLink } from 'react-router-dom';
// Styles
import '../../styles/Home.css';
import 'react-multi-carousel/lib/styles.css';
// Service
import TeamApis from '../../helper/teams.api';
import TournamentApis from '../../helper/tournament.api';
// Utils
import { ellipsis, errorHandler, remainingTime, updateLoader } from '../../utils/common.utils';
import Carousel from 'react-multi-carousel';
import { connect } from 'react-redux';
import { setAllGames, setPublicTournaments } from '../../redux/actions/commonActions';
// Assets
import logo from '../../images/eSpotz_logo.png';
import BettingArenaImage from '../../images/betting_arena_image.png';
import { MdEmail } from 'react-icons/md';
import homepageTeam from '../../../src/images/icon/homepage-team.png';
import homepageRegister from '../../../src/images/icon/homepage-register.png';
import homePageRole from '../../../src/images/icon/homepage-role.png';
import homepageVerification from '../../../src/images/icon/homepage-verification.png';
import homepageCW from '../../../src/images/icon/homepage-cw.png';
import espotzCard from '../../../src/images/espotz-card.png';
import labelHpmepage from '../../images/home/label-homepage.png';


import {FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton,
  FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon } from "react-share";


//import { RWebShare } from "react-web-share";


//To set global props
const mapStateToProps = (props) => {
  return {
    games: props.gameData,
    tournamentsdata: props.publicTornamentData
  }
}

//Dispatch props redux
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchData: (data, key) => {
      switch (key) {
        case 'game':
          dispatch(setAllGames(data));
          break;
        case 'tournaments':
          dispatch(setPublicTournaments(data));
          break;
        default:
          break;
      }

    },
  }
}


// To make add responsiveness
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

// To make banners responsive
const responsiveBanner = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      games: [],
      tournaments: []
    };

    this.tournamentApis = new TournamentApis();
    this.teamApi = new TeamApis();

  }

  componentDidMount() {
    // console.log("this.props.isAuthenticated - ", this.props.isAuthenticated)
    this.props.showOrHideNav(true);
    // if (this.props ? .tournamentsdata ? .length) {
    //     this.setState({ tournaments: this.props.tournamentsdata });
    // } else {
    //     this.getData();
    // }
    // if (this.props ? .games ? .length) {
    //     this.setState({ games: this.props.games });
    // } else {
    //     this.fetchGames();
    // }
  }

  // To open sign in modal
  signIn = () => {
    this.props.openLogin('openLogin');
  }

  // To open sign up modal
  signUp = () => {
    this.props.openLogin('openSignUp');
  }

  // To redirect on clan page
  getStarted = () => {
    if (this.props.history) {
      this.props.history.push('/clans');
    }
  }

  // Fetch all games
  fetchGames = () => {
    updateLoader(true);
    this.teamApi.getGames().then((res: AxiosResponse) => {
      this.setState({
        games: res.data.result
      });
      this.props.dispatchData(res.data.result, 'game')
      updateLoader(false);
    }).catch((reason: AxiosError) => {
      updateLoader(false);
      errorHandler(reason);
    });
  }

  //Get public tournament
  // getData = () => {
  //     updateLoader(true);
  //     this.tournamentApis.getPublicTournaments().then((res: AxiosResponse) => {
  //         this.setState({
  //             tournaments: res.data.result
  //         });
  //         this.props.dispatchData(res.data.result, 'tournaments')
  //         updateLoader(false);
  //     }).catch((err: AxiosError) => {
  //         updateLoader(false);
  //         errorHandler(err, this.props.logout);
  //     });
  // }

  getBetaScreen = () => {
    return (
      <div className="body-section home-page pb-0" >
        <div className='float-end mt-lg-5 mt-md-4 mt-3 me-lg-5 me-md-4 me-3' style={{display: this.props.isAuthenticated ? 'none' : 'block'}}>
          <button type="button" className="fw-bold py-2 fs-16 rounded-10 px-4" style={{ border: '1px solid #32ADE6', color: '#18ACFE', backgroundColor: '#163A67' }} onClick={this.signIn}>
            Access
          </button>
          <button type="button" className="fw-bold py-2 fs-16 rounded-10 px-4 text-white ms-lg-4 ms-md-3 mt-md-0 mt-2 mt-lg-0 border-0 ms-2" style={{ backgroundColor: '#18ACFE' }} onClick={this.signUp}>
            Enroll
          </button>
        </div>
        <div className='label-homepage'>
          <img src={labelHpmepage} alt="Homepage" />
        </div>
        <div className='ms-lg-5 ms-md-4 ms-3'>
          <h4 className='fs-16 fw-700' style={{ color: '#3C7B8F' }}>LET’S BUILD IT TOGETHER</h4>
          <div className="mb-2">
            <NavLink to='/report-bug'>
              <span className='fs-16 fw-600 text-white my-3'>Report a Bug</span>
            </NavLink>
          </div>
          <div className="">
            <NavLink to='/request-feature'>
              <span className='fs-16 fw-600 text-white'>Request a feature</span>
            </NavLink>
          </div>
          
        </div>
        </div>
    )
  }

  getWebisitesHomePage = () => {
    return (
      <div className="body-section home-page pb-0" >
        <section className="bannerSection pt-4">
          <div className="container">
            <div className="row">
              <div className="col-7 col-lg-6 d-lg-block d-md-block d-none">
                <div className="row">
                  <div className='col-12 col-lg-12'>
                    <h1 className="fs-16 fw-600 text-white banner-heading text-uppercase">
                      The <span className="fw-600 " style={{ color: '#32ADE6' }}>next generation</span> <br /> of E-Sports  is Here
                    </h1>
                  </div>
                  <div className='col-12 col-lg-12'>
                    <p className="mt-3 fs-16 text-white-50 fw-600 banner-description">
                      Create your team, compete with others and earn real money.
                      Push your going limits. Start playing now!
                    </p>
                  </div>

                  <div className='col-12 col-lg-3'>
                    <p className="mt-lg-3 fs-16 text-white-50 fw-600 banner-description">
                      125445 +
                      <span className="fw-600 " style={{ color: '#32ADE6' }}> Players </span>
                    </p>
                  </div>

                  <div className='col-12 col-lg-3'>
                    <p className="mt-lg-3 fs-16 text-white-50 fw-600 banner-description">
                      7644  +
                      <span className="fw-600 " style={{ color: '#32ADE6' }}> Matches</span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  {this.props.isAuthenticated ?
                    <div className="bannerSection-btn mt-2 col-12 col-lg-6">
                      <button type="button" className="mt-2 fw-bold fs-16 bg-transparent w-100 rounded-15 py-2" style={{ color: '#18ACFE', border: '1px solid #18ACFE' }} onClick={this.getStarted}>
                        Get Started
                      </button>
                    </div>
                    :
                    <div className="mt-2 col-12 col-lg-7 d-lg-flex">
                      <button type="button" className="mt-2 fw-bold py-2 fs-16 w-100 rounded-15 px-4 bg-black" style={{ border: '1px solid #18ACFE', color: '#18ACFE' }} onClick={this.signIn}>
                        Sign In
                      </button>
                      <button type="button" className="mt-2 fw-bold text-white ms-lg-4 py-2 fs-16 border-0 w-100 rounded-15 px-4" style={{ backgroundColor: '#18ACFE' }} onClick={this.signUp}>
                        Sign Up
                      </button>
                    </div>
                  }
                </div>
              </div>

              <div className="col-5 text-center my-auto d-lg-block d-md-block d-none" >
                <div className="main-box d-flex p-0 float-end">
                  <div className="d-flex flex-column justify-content-end">
                    <div className="div-1"></div>
                  </div>
                  <div className="d-flex flex-column" style={{ flexGrow: '1' }}>
                    <div className="div-2"></div>
                    <Carousel responsive={responsiveBanner}
                      infinite={true}
                      autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      autoPlaySpeed={2000}
                      className='banner-carousel'
                      arrows={false}
                      showDots={false}
                    >
                      {this.state.games?.map((game, i) => {
                        return <img src={game.logo.url} key={i} className="d-block w-100" alt="First slide" />
                      })}
                    </Carousel>
                    <div className="div-2 div-3"></div>
                  </div>
                  <div className="d-flex">
                    <div className="div-1"></div>
                  </div>
                </div>
              </div>

              <div className='col-12 d-lg-none d-md-none d-block'>
                <h1 className="fs-26 fw-600 text-white banner-heading text-uppercase">
                  The <span className="fw-600 " style={{ color: '#32ADE6' }}>next generation</span> of E-Sports  is Here
                </h1>
              </div>

              <div className='col-12 d-lg-none d-md-none d-block'>
                <p className="my-3 fs-14 text-white-50 fw-600 banner-description">
                  Push your going limits. Start playing now!
                </p>
              </div>

              <div className='col-6 d-lg-none d-md-none d-block'>
                <p className="mt-lg-3 ms-4 fs-20 text-white-50 fw-600 banner-description">
                  125445 + <br />
                  <span className="fs-16 fw-600 " style={{ color: '#32ADE6' }}> Players </span>
                </p>
                <p className="mt-lg-3 ms-4 fs-20 text-white-50 fw-600 banner-description">
                  7644  + <br />
                  <span className="fs-16 fw-600 " style={{ color: '#32ADE6' }}> Matches</span>
                </p>
              </div>

              <div className='col-6 d-lg-none d-md-none d-block'>
                <div className="main-box d-flex p-0">
                  <div className="d-flex flex-column justify-content-end">
                    <div className="div-1"></div>
                  </div>
                  <div className="d-flex flex-column" style={{ flexGrow: '1' }}>
                    <div className="div-2"></div>
                    <Carousel responsive={responsiveBanner} infinite={true} autoPlay={this.props.deviceType !== "mobile" ? true : false} autoPlaySpeed={2000} className='banner-carousel' arrows={false} >
                      {this.state.games?.map((game, i) => {
                        return <img src={game.logo.url} key={i} className="d-block w-100" alt="First slide" />
                      })}
                    </Carousel>
                    <div className="div-2 div-3"></div>
                  </div>
                  <div className="d-flex">
                    <div className="div-1"></div>
                  </div>
                </div>
              </div>

              <div className='col-12 d-lg-none d-md-none d-block'>
                {this.props.isAuthenticated ?
                  <div className="bannerSection-btn mt-2">
                    <button type="button" className="mt-2 fw-bold fs-16 bg-transparent w-100 rounded-15 py-2" style={{ color: '#18ACFE', border: '1px solid #18ACFE' }} onClick={this.getStarted}>
                      Get Started
                    </button>
                  </div>
                  :
                  <div className="mt-2 d-flex">
                    <button type="button" className="mt-2 me-1 fw-bold py-3 fs-16 w-100 rounded-15 px-4 bg-transparent" style={{ border: '1px solid #18ACFE', color: '#18ACFE' }} onClick={this.signUp}>
                      Sign Up
                    </button>
                    <button type="button" className="mt-2 ms-1 fw-bold text-white ms-lg-4 py-3 fs-16 border-0 w-100 rounded-15 px-4" style={{ backgroundColor: '#18ACFE' }} onClick={this.signIn}>
                      Sign In
                    </button>
                  </div>
                }
              </div>

            </div>
          </div>
        </section>
        <section className="gameSection  pt-5">
          <div className="container text-center pt-5">
            <p className="fs-16 text-center text-white fw-400">GAMES</p>
            <h1 className="home-section-subheading fs-18 text-center text-white fw-600 mt-2 mx-3">
              Browse through Multiple Games that you can Compete In!
            </h1>
            <div>

            </div>
            <div className="row mt-5 mx-auto justify-content-center">
              {this.state.games.map((game, i) =>
                <div key={i} className='col-6 col-md-4 col-lg-3 mb-5 clanPage_Cards'>
                  <div className="clan-card league-of-legends" style={{ backgroundImage: `url(${game.logo.url})` }}>
                    <h1 className="clan-card-name text-center px-2 py-2 fs-14 text-white fw-500 mt-auto text-truncate">{game.name}</h1>

                  </div>
                </div>
              )}

            </div>
            <div className='row'>
              <div className="mt-2 col-12 col-lg-6 offset-lg-3">
                <button type="button" className="mt-2 fw-bold fs-14 bg-transparent w-75 rounded-10 py-3 px-4" style={{ color: '#18ACFE', border: '1px solid #18ACFE' }}>
                  View More Games
                </button>
              </div>
            </div>

          </div>
        </section>
        <section className="tournament-section tournament-carousel">
          <div className="container text-center pt-5">
            <p className="fs-16 text-center text-white fw-400">TOURNAMENTS</p>
            <h1 className="home-section-subheading fs-18 text-center text-white fw-600 mt-2 mx-3">
              Participate in Multiple Tournaments and Test Your Skills
            </h1>

            <Carousel responsive={responsive} infinite={true} swipeable={true} autoPlay={this.props.deviceType !== "mobile" ? true : false} autoPlaySpeed={1500} >
              {this.state.tournaments?.map((tournament, i) => {
                const timeLeft = remainingTime(tournament?.registrationEndTime);
                  return (
                    <div className="card-container mx-2" key={i}>
                      <div className="tournament-card w-100">
                        <div className="top-section " style={{ backgroundImage: `url(${tournament?.baner?.url})` }}>
                          <h1 className="card-name">{tournament?.gameDetails?.name}</h1>
                        </div>
                        <div className="bottom-section p-2 px-3">
                          <h1 className="fw-bold text-white text-center fs-14">{tournament?.name}</h1>

                          <div className="d-flex flex-row justify-content-between my-2">
                            <div className="text-left">
                              <h1 className="fs-10 text-whitefs-10 text-white">Entry Fee</h1>
                              <h1 className="fs-10 text-white fw-700" style={{ color: '#03d158' }}>{(tournament.entryFeeAndPrizeDetails?.entryFeeAmount) ? `Rs ${tournament.entryFeeAndPrizeDetails.entryFeeAmount}` : 'TBD'}</h1>
                            </div>
                            <div className="text-right">
                              <h1 className="fs-10 text-white">Time Left</h1>
                              <h1 className="fs-10 text-white fw-700" style={{ color: '#ff453a' }}>{`${timeLeft[0]} ${timeLeft[1]}`}</h1>
                            </div>
                          </div>
                          <p className="fs-8 text-start text-white flex-grow-1 w-100">
                            {ellipsis(tournament?.bio, 200)}
                          </p>                                    
                        </div>
                      </div>
                    </div>        
                  )
                  }
                )}
            </Carousel>

            <div className='row'>
              <div className="bannerSection-btn mt-5 col-12 col-lg-6 offset-lg-3">
                <button type="button" className="mt-2 fw-bold fs-14 w-75 rounded-10 py-3 px-4 text-white border-0" style={{ backgroundColor: '#18ACFE' }}>
                  <NavLink className="nav-link-btn" to="/tournaments">
                    Browse More Tournaments
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="clan-section ">
          <div className="container text-center py-5 my-5 home-page-section-bg">
            <p className="fs-16 text-center text-white fw-400">CLANS</p>
            <h1 className="home-section-subheading fs-18 text-center text-white fw-600 mt-2 mx-3">
              Be a Part of a Clan
            </h1>
            <div className="row mt-5 px-3">
              <div className="col-md-3 col-6 mb-4">
                <img src={homepageTeam} className='homepage-ic' height='160px' alt='' />
                <p className="mt-5 text-start fw-400 fs-16 text-white banner-description">Teams</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  You can have upto multiple teams with multiple games and each team can have unique or the same players.
                </p>
              </div>

              <div className="col-md-3 col-6 mb-4 text-center">
                <img src={homepageRegister} className='homepage-ic' height='160px' alt='' />
                <p className="mt-5 text-start fw-400 fs-16 text-white banner-description">Registering</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  Registering for tournaments could not be any easier, any member of the Clan can register for a tournament.
                </p>
              </div>

              <div className="col-md-3 col-6 mb-4 text-center">
                <img src={homePageRole} className='homepage-ic' height='160px' alt='' />
                <p className="mt-5 text-start fw-400 fs-16 text-white banner-description">Roles</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  There are multiple unique roles for each Clan member ranging from Owner, Admin to Team Members.
                </p>
              </div>

              <div className="col-md-3 col-6 mb-4 text-center">
                <img src={homepageVerification} className='homepage-ic' height='160px' alt='' />
                <p className="mt-5 text-start fw-400 fs-16 text-white banner-description">Verification</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  You can get your Clan verified so that your Clan is unique and any other Clan cannot pick a name of your Clan anymore.
                </p>
              </div>
            </div>

            <div className='row'>
              <div className="bannerSection-btn mt-2 col-12 col-lg-6 offset-lg-3">
                <button type="button" className="mt-2 fw-bold fs-14 w-75 rounded-10 py-3 px-4 text-white border-0" style={{ backgroundColor: '#18ACFE' }}>
                  <NavLink className="nav-link-btn" to="/clans">
                    Browse Your Own Clans
                  </NavLink>
                </button>
              </div>
            </div>


          </div>
        </section>
        <section className="organization-section ">
          <div className="container text-center py-5 my-5 home-page-section-bg">
            <p className="fs-16 text-center text-white fw-400">ORGANIZATIONS</p>
            <h1 className="home-section-subheading fs-18 text-center text-white fw-600 mt-2 mx-3">
              Build an Organization and Start your Own Tournaments
            </h1>
            <div className="row mt-5 px-3">
              <div className="col-6 col-md-4 mb-4 text-center">
                <img src={homepageCW} className='homepage-ic' height='160px' alt='' />
                <p className="mt-5 text-start fw-400 fs-16 text-white banner-description">Custom Website</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  Your Organization will have it’s own Micro-Website where you can customize
                  content according to your liking.
                </p>
              </div>

              <div className="col-6 col-md-4 mb-4 text-center">
                <img src={homePageRole} className='homepage-ic' height='160px' alt='' />
                <p className="mt-5 text-start fw-400 fs-16 text-white banner-description">Roles</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  There are multiple unique roles for each Clan member ranging from Owner, Admin to Team Members.
                </p>
              </div>

              <div className="col-6 col-md-4 mb-4 text-center">
                <img src={homepageVerification} className='homepage-ic' height='160px' alt='' />
                <p className="mt-5 text-start fw-400 fs-16 text-white banner-description">Verification</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  You can get your Clan verified so that your Clan is unique and any other Clan cannot pick a name of your Clan anymore.
                </p>
              </div>
            </div>

            <div className='row'>
              <div className="bannerSection-btn mt-2 col-12 col-lg-6 offset-lg-3">
                <button type="button" className="mt-2 fw-bold fs-14 w-75 rounded-10 py-3 px-4 text-white border-0" style={{ backgroundColor: '#18ACFE' }}>
                  <NavLink className="nav-link-btn" to="/organizations">
                    Create an Organization
                  </NavLink>
                </button>
              </div>
            </div>


          </div>
        </section>
        <section className="espotzDen-section ">
          <div className="container text-center py-5 my-5 home-page-section-bg">
            <p className="fs-16 text-center text-white fw-400">ESPOTZ DEN</p>
            <h1 className="fs-18 text-center text-white fw-600 mt-2 mx-3">
              Connect with other Likeminded Gamers and Compete Together!
            </h1>
            <div className="row mt-5 mb-5 px-3">
              <div className="col-6 col-md-4 mb-4 order-2">
                <div className="clans-card-image"></div>
                <p className="mt-3 text-start fw-400 fs-16 text-white banner-description">Heading</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nibh tortor tristique cum tortor enim. Sapien amet feugiat.
                </p>
              </div>

              <div className="col-6 col-md-4 mb-4 order-3">
                <div className="clans-card-image"></div>
                <p className="mt-3 text-start fw-400 fs-16 text-white banner-description">Heading</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nibh tortor tristique cum tortor enim. Sapien amet feugiat.
                </p>
              </div>

              <div className="col-md-4 d-flex justify-content-center mb-4 order-1 order-md-4">
                <div className="espotz-den-right-container"></div>
              </div>
            </div>

            <div className='row'>
              <div className="bannerSection-btn mt-2 col-12 col-lg-6 offset-lg-3">
                <button type="button" className="mt-2 fw-bold fs-14 w-75 rounded-10 py-3 px-4 text-white border-0" style={{ backgroundColor: '#18ACFE' }}>Explore Espotz Den</button>
              </div>
            </div>

          </div>
        </section>
        <section className="bettingArena-section ">
          <div className="container text-center py-5 my-5 home-page-section-bg">
            <p className="fs-16 text-center text-white fw-400">BATTLING ARENA</p>
            <h1 className="home-section-subheading fs-18 text-center text-white fw-600 mt-2 mx-3">
              Bet on E-Sports Games and Hunt For Exciting Bounty!
            </h1>
            <div className="row mt-5 mb-5 px-3">
              <div className="col-6 col-md-4 mb-4 order-2">
                <div className="clans-card-image"></div>
                <p className="mt-3 text-start fw-400 fs-16 text-white banner-description">Heading</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nibh tortor tristique cum tortor enim. Sapien amet feugiat.
                </p>
              </div>

              <div className="col-6 col-md-4 mb-4 order-3">
                <div className="clans-card-image"></div>
                <p className="mt-3 text-start fw-400 fs-16 text-white banner-description">Heading</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50 banner-description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nibh tortor tristique cum tortor enim. Sapien amet feugiat.
                </p>
              </div>

              <div className="col-md-4 order-1 mb-4 order-md-4">
                <img src={BettingArenaImage}
                  className="betting-arena-image w-100" alt='' />
              </div>
            </div>

            <div className='row'>
              <div className="bannerSection-btn mt-2 col-12 col-lg-6 offset-lg-3">
                <button type="button" className="mt-2 fw-bold fs-14 w-75 rounded-10 py-3 px-4 text-white border-0" style={{ backgroundColor: '#18ACFE' }}>Explore Betting Arena</button>
              </div>
            </div>
          </div>
        </section>
        <section className="espotzCurrency-section">
          <div className="container text-center py-5 my-5 home-page-section-bg">
            <p className="fs-16 text-center text-white fw-400">ESPOTZ CURRENCY</p>
            <h1 className="home-section-subheading fs-18 text-center text-white fw-600 mt-2 mx-3">
              Bet on Espotz game and Hunt for Exciting Bounty!
            </h1>
            <div className="row mt-5 espotzCurrency-row px-3">
              <div className="col-md-6 currency-card mx-auto">
                <img src={espotzCard} className="espotz-card" alt='' />
              </div>

              <div className="col-6 col-md-3 text-center">
                <p className="mt-3 text-start fw-400 fs-16 text-white">Uniqueness</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50">
                  The EC is unique to Espotz Gaming.EC ensures seamless process of organizing tournaments and much more.
                </p>
              </div>

              <div className="col-6 col-md-3 text-center">
                <p className="mt-3 text-start fw-400 fs-16 text-white">Live Wallet</p>
                <p className="mt-3 text-start fw-400 fs-16 text-white-50">
                  Live Wallet is a navbar feature with live balances, so you don’t need to visit wallet everytime when looking into the balances.
                </p>
              </div>
            </div>

            <div className='row'>
              <div className="bannerSection-btn mt-5 col-12 col-lg-6 offset-lg-3">
                <button type="button" className="mt-2 fw-bold fs-14 w-75 rounded-10 py-3 px-4 text-white border-0" style={{ backgroundColor: '#18ACFE' }}>
                  <NavLink className="nav-link-btn" to="/singleElimination">
                    Explore our Currency
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid rounded-0 text-center pt-5 pb-md-5 mt-5 home-page-section-bg">
            <div className='row pb-4'>
              <div className="col-lg-4 text-start ps-lg-5 mb-4">
                <div className='row'>
                  <div className="col-lg-12">
                    <NavLink to="/responsibleGaming" className='text-white'> <h6>RESPONSIBLE GAMING</h6></NavLink>
                  </div>
                  <div className="col-lg-12">
                    <NavLink to="/bettingRules" className='text-white'><h6>BETTING RULES</h6></NavLink>
                  </div>
                  <div className="col-lg-12">
                    <NavLink to="/socialMediaPolicy" className='text-white'><h6>SOCIA MEDIA POLICY</h6></NavLink>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <img src={logo} className='' height='50px' alt='' />
                <h6 className='my-4 text-white-50'><MdEmail className='text-white fs-24 pb-1' /> Support @espotz.online</h6>

                <div className="text-center mb-4">

                  <WhatsappShareButton className="px-2">
                    <WhatsappIcon size={"2rem"} round={true} />
                  </WhatsappShareButton>

                  <FacebookShareButton className="px-2">
                    <FacebookIcon size={"2rem"} round={true} />
                  </FacebookShareButton>

                  <TelegramShareButton className="px-2">
                    <TelegramIcon size={"2rem"} round={true} />
                  </TelegramShareButton>

                  <TwitterShareButton className="px-2">
                    <TwitterIcon size={"2rem"} round={true} />
                  </TwitterShareButton>
                </div>

                <h6 className='fs-18 text-info fw-bold'>
                  <NavLink to="/termAndConditions" className=''>Terms and Conditions</NavLink>   |  <NavLink to="/privacyPolicy" className=''>Privacy Policy</NavLink></h6>
              </div>

              <div className="col-lg-4 text-end pe-lg-5 mt-4">
                <div className='row'>
                  <div className="col-lg-12">
                    <NavLink to="/news" className='text-white'><h6>NEWS</h6></NavLink>
                  </div>
                  <div className="col-lg-12">
                    <NavLink to="/paymentOptions" className='text-white'><h6>PAYMENT OPTIONS</h6></NavLink>
                  </div>
                  <div className="col-lg-12">
                    <NavLink to="/disclaimer" className='text-white'><h6>DISCLAIMER</h6></NavLink>
                  </div>
                  <div className="col-lg-12">
                    <NavLink to="/faqs" className='text-white'><h6>FAQS</h6></NavLink>
                  </div>
                  <div className="col-lg-12">
                    <NavLink to="/report-bug" className='text-white'><h6>Report a Bug</h6></NavLink>
                  </div>
                  <div className="col-lg-12">
                    <NavLink to="/request-feature" className='text-white'><h6>Request a feature</h6></NavLink>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
                  
    );
  }

  render() {
    return this.getBetaScreen();
  }
}

export default (connect(mapStateToProps, mapDispatchToProps))(Home)