export const getNumberFormat = (num) => {
    if(num>0 && num<1000){
        return num
    }else if(num>1000 && num<10000){
        return num.toString()[0]+'.'+num.toString()[1]+ 'k'
    }else if(num>10000 && num<100000){
        return num.toString()[0]+ num.toString()[1]+ '.' + num.toString()[1] + 'k';
    }else if(num>100000 && num<1000000){
            return num.toString()[0]+ num.toString()[1]+ num.toString()[2]+ '.' + num.toString()[1] + 'k';
    }else if(num>1000000){
        return num.toString()[0]+'.'+num.toString()[1]+ 'm'
    }
}