import winningNotificationLogo from '../images/notification/winning.png';
import spotlightNotificationLogo from '../images/notification/spotlight.png';
import bettingNotificationLogo from '../images/notification/betting.png';
import organizationNotificationLogo from '../images/notification/organization.png';
import tournamentNotificationLogo from '../images/notification/tournament.png';
import clanNotificationLogo from '../images/notification/clan.png';
import teamNotificationLogo from '../images/notification/team.png';
import denNotificationLogo from '../images/notification/den.png';
import walletNotificationLogo from '../images/notification/wallet.png';
import timeNotificationLogo from '../images/notification/time.png';
import adminNotificationLogo from '../images/notification/admin.png';
import defaultNotificationLogo from '../images/notification/default.png';
import loaderImage from "../images/common/Loader.png";

import bombInline from '../images/den/interactions/bomb-inline.png';
import sparkInline from '../images/den/interactions/spark-inline.png';
import gunInline from '../images/den/interactions/gun-inline.png';
import panInline from '../images/den/interactions/pan-inline.png';
import fireInline from '../images/den/interactions/fire-inline.png';

import happy from '../images/den/emojis/Happy.png';
import blessed from '../images/den/emojis/Blessed.png';
import op from '../images/den/emojis/OP.png';
import dancing from '../images/den/emojis/Dancing.png';
import rich from '../images/den/emojis/Rich.png';
import pro from '../images/den/emojis/Pro.png';
import angry from '../images/den/emojis/Angry.png';
import chill from '../images/den/emojis/Chill.png';
import excited from '../images/den/emojis/Excited.png';
import party from '../images/den/emojis/Party.png';
import winning from '../images/den/emojis/Winning.png';
import revenge from '../images/den/emojis/Revenge.png';
// Off Action Icons
import spark from '../images/den/interactions/spark.png';
import hammer from '../images/den/interactions/hammer.png';
import spotlight from '../images/den/interactions/spotlight.png';
import share from '../images/den/interactions/share.png';
// On Action Icons
import sparkActive from '../images/den/interactions/spark-active.png';
import hammerActive from '../images/den/interactions/hammer-active.png';
import spotlightActive from '../images/den/interactions/spotlight-active.png';
import shareActive from '../images/den/interactions/share-active.png';

import selected from '../images/den/create-post/selected.png';
import send from '../images/den/create-post/send.png';
import unselected from '../images/den/create-post/unselected.png';
import globe from '../images/den/create-post/public.png';
import followers from '../images/den/create-post/followers.png';
import clan from '../images/den/create-post/clan.png';
import org from '../images/den/create-post/org.png';
import user from '../images/den/create-post/user.png';
import proceed from '../images/den/create-post/proceed.png';
import shorts from '../images/den/create-post/shorts.png';
import embeded from '../images/den/create-post/embeded.png';

import bombBonus from '../images/den/interactions/bonus-bomb.png';
import sparkBonus from '../images/den/interactions/bonus-spark.png';
import fireBonus from '../images/den/interactions/bonus-fire.png';
import panBonus from '../images/den/interactions/bonus-pan.png';
import hammerBonus from '../images/den/interactions/bonus-hammer.png';
import gunBonus from '../images/den/interactions/bonus-gun.png';
//profile-page
import bets from '../images/den/profile-page/bets.png';
import betWins from '../images/den/profile-page/bet-wins.png';
import bioFlag from '../images/den/profile-page/bio-flag.png';
import feed1 from '../images/den/profile-page/feed1.png';
import feed2 from '../images/den/profile-page/feed2.png';
import flag from '../images/den/profile-page/flag.png';
import tournaments from '../images/den/profile-page/tournaments.png';
import matches from '../images/den/profile-page/matches.png';
import mvp from '../images/den/profile-page/mvp.png';
import spotlights from '../images/den/profile-page/spotlight.png';
import star from '../images/den/profile-page/star.png';
import winRatio from '../images/den/profile-page/win-ratio.png';
import wins from '../images/den/profile-page/wins.png';
import nonDenBg from '../images/den/profile-page/non-den-bg.png';

import discord from '../images/den/profile-page/discord.png';
import fbGaming from '../images/den/profile-page/fbgaming.png';
import loco from '../images/den/profile-page/loco.png';
import instagram from '../images/den/profile-page/instagram.png';
import telegram from '../images/den/profile-page/telegram.png';
import twitch from '../images/den/profile-page/twitch.png';
import twitter from '../images/den/profile-page/twitter.png';
import youtube from '../images/den/profile-page/youtube.png';
//spin-wheel
import bombSpin from '../images/den/spin-assets/bomb.png';
import sparkSpin from '../images/den/spin-assets/spark.png';
import fireSpin from '../images/den/spin-assets/fire.png';
import panSpin from '../images/den/spin-assets/pan.png';
import hammerSpin from '../images/den/spin-assets/hammer.png';
import gunSpin from '../images/den/spin-assets/gun.png';
//inner-circle
import crown from '../images/den/inner-circle/es-crown.png';
import esStar from '../images/den/inner-circle/es-star.png';
import esShare from '../images/den/inner-circle/share.png';
import rankDown from '../images/den/inner-circle/rank-down.png';
import rankUp from '../images/den/inner-circle/rank-up.png';
// Media
import video from '../images/den/create-post/video.png';
import image from '../images/den/create-post/image.png';
// Actions
import blockPost from '../images/den/icons/block.png';
import downloadPost from '../images/den/icons/download.png';
import editPost from '../images/den/icons/edit.png';
import opPost from '../images/den/icons/op.png';
import pinPost from '../images/den/icons/pin.png';
import removePost from '../images/den/icons/remove.png';
import reportPost from '../images/den/icons/report.png';
import savePost from '../images/den/icons/save.png';
import spotlightPost from '../images/den/icons/spotlight.png';
import unhidePost from '../images/den/icons/unhide.png';
import hidePost from '../images/den/icons/hide.png';
import privateDen from '../images/den/icons/private-den.png';
import dropdown from '../images/den/icons/dropdown.svg';
//social-media-icons
import fbGamingBlock from '../images/social-media/fbGaming.png';
import fbGamingInline from '../images/social-media/fbGaming-inline.png';
import instagramBlock from '../images/social-media/instagram.png';
import instagramInline from '../images/social-media/instagram-inline.png';
import locoBlock from '../images/social-media/loco.png';
import locoInline from '../images/social-media/loco-inline.png';
import telegramBlock from '../images/social-media/telegram.png';
import telegramInline from '../images/social-media/telegram-inline.png';
import twitchBlock from '../images/social-media/twitch.png';
import twitchInline from '../images/social-media/twitch-inline.png';
import twitterBlock from '../images/social-media/twitter.png';
import twitterInline from '../images/social-media/twitter-inline.png';
import youtubeBlock from '../images/social-media/youtube.png';
import youtubeInline from '../images/social-media/youtube-inline.png';
import broadcast from '../images/social-media/broadcast.png'
// Profile Tags
import userTag from '../images/den/create-post/user.png';
import orgTag from '../images/den/create-post/public.png';
import clanTag from '../images/den/create-post/clan.png';
import moreActions from '../images/den/icons/more.png';
import postLoad from '../images/den/gif/post-loading.gif';

// Spotlight Starts
import oneStar from '../images/den/spotlight/stars/1.png';
import twoStar from '../images/den/spotlight/stars/2.png';
import threeStar from '../images/den/spotlight/stars/3.png';
import fourStar from '../images/den/spotlight/stars/4.png';
import fiveStar from '../images/den/spotlight/stars/5.png';

import oneStarLogo from '../images/den/spotlight/logo/1.png';
import twoStarLogo from '../images/den/spotlight/logo/2.png';
import threeStarLogo from '../images/den/spotlight/logo/3.png';
import fourStarLogo from '../images/den/spotlight/logo/4.png';
import fiveStarLogo from '../images/den/spotlight/logo/5.png';

//user logos
import orgIc from '../images/icon/org-ic.png';
import avatar from '../images/participantAvtar.jpg';
//Navbar
import betOn from '../images/navbar/bet-active.png';
import betOff from '../images/navbar/bet.png';
import clanOn from '../images/navbar/clan-active.png';
import clanOff from '../images/navbar/clan.png';
import denOn from '../images/navbar/den-active.png';
import denOff from '../images/navbar/den.png';
import orgOn from '../images/navbar/org-active.png';
import orgOff from '../images/navbar/org.png';
import notificationOn from '../images/navbar/notification-active.png';
import notificationOff from '../images/navbar/notification.png';
import playOn from '../images/navbar/play-active.png';
import playOff from '../images/navbar/play.png';
import tvOn from '../images/navbar/tv-active.png';
import tvOff from '../images/navbar/tv.png';
//Bottom Nav icons
import navBet from '../images/nav-icons/bet.png';
import navBetActive from '../images/nav-icons/betActive.png'; 
import navClan from '../images/nav-icons/clan.png';
import navClanActive from '../images/nav-icons/clanActive.png';
import navDen from '../images/nav-icons/den.png';
import navDenActive from '../images/nav-icons/denActive.png';
import navEC from '../images/nav-icons/ec.png';
import navECActive from '../images/nav-icons/ecActive.png';
import navMsg from '../images/nav-icons/msg.png';
import navMsgActive from '../images/nav-icons/msgActive.png';
import navOrg from '../images/nav-icons/org.png';
import navOrgActive from '../images/nav-icons/orgActive.png';
import navTournament from '../images/nav-icons/tournament.png';
import navTournamentActive from '../images/nav-icons/tournamentActive.png';
import navTv from '../images/nav-icons/tv.png';
import navTvActive from '../images/nav-icons/tvActive.png';


import pinnedComment from '../images/den/icons/pinnedComment.png';
//EMPTY SCREENS
import noBlockedUsers from '../images/den/empty-screens/blocked-users.svg';
import noPosts from '../images/den/empty-screens/empty-posts.svg';
import noFollowers from '../images/den/empty-screens/followers.svg';
import noFollowing from '../images/den/empty-screens/following.svg';
import noFollowRequests from '../images/den/empty-screens/follow-requests.svg';
import noTopFanComments from '../images/den/empty-screens/top-fans-comment.svg';
import noTopFanSpotlight from '../images/den/empty-screens/top-fans-spotlight.svg';
import noInnerCircle from '../images/den/empty-screens/inner-circle.svg';
import noPostsYet from '../images/den/empty-screens/no-posts-yet.svg';
import zeroPosts from '../images/den/empty-screens/no-posts.svg';
import noAchievementComment from '../images/den/empty-screens/achievements-comment.svg';
import noAchievementSpotlight from '../images/den/empty-screens/achievements-spotlight.svg';
import noResultFound from '../images/den/empty-screens/no-results.svg';
import unAuth from '../images/den/empty-screens/unauth.svg';
import unAuthDesktop from '../images/den/empty-screens/unauth-desktop.svg';
//den profile
import male from '../images/den/male.png';
import female from '../images/den/female.png';
import nonveg from '../images/den/nonveg.png';
import veg from '../images/den/veg.png';

export const BACKGROUNDS = {
    UNAUTH: unAuth,
    UNAUTH_DESKTOP: unAuthDesktop
}

export const NotificationImages = {
    WINNING : winningNotificationLogo,
    BETTING : bettingNotificationLogo,
    SPOTLIGHT : spotlightNotificationLogo,
    ORGANIZATION : organizationNotificationLogo,
    TOURNAMENT : tournamentNotificationLogo,
    CLAN : clanNotificationLogo,
    TEAM : teamNotificationLogo,
    DEN : denNotificationLogo,
    WALLET : walletNotificationLogo,
    TIME : timeNotificationLogo,
    ADMIN: adminNotificationLogo,
    DEFAULT : defaultNotificationLogo
};

export const Commons = {
    LOADER : loaderImage
}

export const ACTION = {
    SELECTED : selected,
    UNSELECTED : unselected
}

export const DEN = {
    SPOTLIGHT: {
        STARS: [oneStar, twoStar, threeStar, fourStar, fiveStar],
        LOGO: {
            "1" : oneStarLogo, 
            "2" : twoStarLogo, 
            "3" : threeStarLogo, 
            "4" : fourStarLogo, 
            "5" :fiveStarLogo
        },
    }
}

export const POST_INTERACTIONS = {
    SPARKED: sparkActive,
    SPARKS: {
        SPARK: {
            NAME: 'spark',
            LOGO: sparkInline,
            BONUS: sparkBonus
        },
        BOMB: {
            NAME: 'bomb',
            LOGO: bombInline,
            BONUS: bombBonus
        },
        PAN: {
            NAME: 'pan',
            LOGO: panInline,
            BONUS: panBonus
        },
        GUN: {
            NAME: 'gun',
            LOGO: gunInline,
            BONUS: gunBonus
        },
        FIRE: {
            NAME: 'fire',
            LOGO: fireInline,
            BONUS: fireBonus

        }
    },
    SPARK:{
        ON: sparkActive,
        OFF: spark
    },
    HAMMER:{
        ON: hammerActive,
        OFF: hammer,
        BONUS: hammerBonus
    },
    SPOTLIGHT:{
        ON: spotlightActive,
        OFF: spotlight
    },
    SHARE:{
        ON: shareActive,
        OFF: share,
    },
    FEELINGS: {
        HAPPY: { NAME: 'Happy', LOGO: happy},
        BLESSED: { NAME: 'Blessed', LOGO: blessed},
        OP: { NAME: 'OP', LOGO: op},
        DANCING: { NAME: 'Dancing', LOGO: dancing},
        RICH: { NAME: 'Rich', LOGO: rich},
        PRO: { NAME: 'Pro', LOGO: pro},
        ANGRY: { NAME: 'Angry', LOGO: angry},
        CHILL: { NAME: 'Chill', LOGO: chill},
        EXITED: { NAME: 'Exited', LOGO: excited},
        PARTY: { NAME: 'Party', LOGO: party},
        WINNING: { NAME: 'Winning', LOGO: winning},
        REVENGE: { NAME: 'Revenge', LOGO: revenge},
    },
    AUDIENCE : {
        PUBLIC: globe,
        FOLLOWERS: followers
    },
    AS:{
        CLAN: clan,
        ORG: org,
        USER: user
    },
    PROCEED: proceed,
    VIDEO:{
        SHORT: shorts,
        EMBEDED: embeded
    },
    ACTIONS:{
        REPORT: reportPost,
        DOWNLOAD: downloadPost,
        REMOVE: removePost,
        PIN: pinPost,
        HIDE: hidePost,
        UNHIDE: unhidePost,
        SAVE: savePost,
        EDIT: editPost,
        MORE: moreActions,
        BLOCK: blockPost,
        OP: opPost,
        SPOTLIGHT: spotlightPost,
        PRIVATE_DEN: privateDen,
        SEND: send,
        DROPDOWN: dropdown
    },
    COMMENT:{
        PINNED: pinnedComment
    }
}

export const PROFILE_PAGE_IMAGES = {
    BETS: bets,
    BETWINS: betWins,
    BIOFLAG: bioFlag,
    FEED1: feed1,
    FEED2: feed2,
    FLAG: flag,
    TOURNAMENTS: tournaments,
    MATCHES: matches,
    MVP: mvp,
    SPOTLIGHTS: spotlights,
    STAR: star,
    WINRATIO: winRatio,
    WINS: wins,
    DISCORD: discord,
    FBGAMING: fbGaming,
    LOCO: loco,
    INSTAGRAM: instagram,
    TELEGRAM: telegram,
    TWITCH: twitch,
    TWITTER: twitter,
    YOUTUBE: youtube,
    NONDENBG: nonDenBg
}

export const MEDIA = {
    VIDEO: video,
    IMAGE: image
}

export const GIF = {
    POST_LOAD: postLoad
}

export const PROFILE = {
    TAG: {
        USER: userTag,
        ORG: orgTag,
        CLAN: clanTag
    }
}

export const SPIN_WHEEL_ASSETS = {
    BG_COLORS : ["#007171", "#367D46", "#9A4040", "#817433", "#34A24F", "#4D367F"],
    PRIZES: ['Hammer', 'Bomb', 'Fire', 'Gun', 'Spark', 'Pan'],
    PRIZE_ASSETS: {
        HAMMER: {NAME: 'Hammer', LOGO: hammerSpin, QUANTITY: 40},
        BOMB: {NAME: 'Bomb', LOGO: bombSpin, QUANTITY: 30},
        FIRE: {NAME: 'Fire', LOGO: fireSpin, QUANTITY: 100},
        GUN: {NAME: 'Gun', LOGO: gunSpin, QUANTITY: 75},
        SPARK: {NAME: 'Spark', LOGO: sparkSpin, QUANTITY: 90},
        PAN: {NAME: 'Pan', LOGO: panSpin, QUANTITY: 50},
    }
}

export const INNER_CIRCLE_ASSETS = {
    CROWN: crown,
    SPOTLIGHT: esStar,
    SHARE: esShare,
    RANK_UP: rankUp,
    RANK_DOWN: rankDown
}

export const SOCIAL_MEDIA_ICONS = {
    BROADCAST: {url : broadcast},
    MEDIA_ASSETS: [
        { id: 'instagram', value: '', isChecked: false, url: instagramBlock, error: '', inlineUrl: instagramInline, denUrl: instagram},
        { id: 'facebook', value: '', isChecked: false, url: fbGamingBlock, error: '', inlineUrl: fbGamingInline, denUrl: fbGaming},
        { id: 'twitter', value: '', isChecked: false, url: twitterBlock, error: '', inlineUrl: twitterInline, denUrl: twitter},
        { id: 'twitch', value: '', isChecked: false, url: twitchBlock, error: '', inlineUrl: twitchInline, denUrl: twitch},
        { id: 'telegram', value: '', isChecked: false, url: telegramBlock, error: '', inlineUrl: telegramInline, denUrl: telegram},
        { id: 'youtube', value: '', isChecked: false, url: youtubeBlock, error: '', inlineUrl: youtubeInline, denUrl: youtube},
        { id: 'loco', value: '', isChecked: false, url: locoBlock, error: '', inlineUrl: locoInline, denUrl: loco}
    ]
}

export const LOGOS = {
    USER: avatar,
    ORG: orgIc
}

export const NAVBAR_ASSETS = {
    ASSETS: {
        BET: {
            NAME: 'bet',
            ON: betOn,
            OFF: betOff
        },
        CLAN: {
            NAME: 'clan',
            ON: clanOn,
            OFF: clanOff
        },
        DEN: {
            NAME: 'den',
            ON: denOn,
            OFF: denOff
        },
        ORG: {
            NAME: 'org',
            ON: orgOn,
            OFF: orgOff
        },
        NOTIFICATION: {
            NAME: 'notifications',
            ON: notificationOn,
            OFF: notificationOff
        },
        TOURNAMENT: {
            NAME: 'tournament',
            ON: playOn,
            OFF: playOff
        },
        TV: {
            NAME: 'tv',
            ON: tvOn,
            OFF: tvOff
        }
    }
}

export const BOTTOM_NAVBAR_ASSETS = {
    ASSETS: {
        BET: {
            NAME: 'bet',
            ON: navBetActive,
            OFF: navBet
        },
        CLAN: {
            NAME: 'clan',
            ON: navClanActive,
            OFF: navClan
        },
        DEN: {
            NAME: 'den',
            ON: navDenActive,
            OFF: navDen
        },
        ORG: {
            NAME: 'org',
            ON: navOrgActive,
            OFF: navOrg
        },
        NOTIFICATION: {
            NAME: 'notifications',
            ON: navMsgActive,
            OFF: navMsg
        },
        TOURNAMENT: {
            NAME: 'tournament',
            ON: navTournamentActive,
            OFF: navTournament
        },
        TV: {
            NAME: 'tv',
            ON: navTvActive,
            OFF: navTv
        },
        EC: {
            NAME: 'EC',
            ON: navECActive,
            OFF: navEC
        }
    }
}

export const DEN_EMPTY_SCREENS = {
    NO_BLOCKED_USERS: noBlockedUsers,
    NO_POSTS: noPosts,
    NO_FOLLOWERS: noFollowers,
    NO_FOLLOWING: noFollowing,
    NO_FOLLOW_REQUESTS: noFollowRequests,
    NO_TOP_FAN_COMMENTS: noTopFanComments,
    NO_TOP_FAN_SPOTLIGHT: noTopFanSpotlight,
    NO_INNER_CIRCLE: noInnerCircle,
    NO_POSTS_YET: noPostsYet,
    NO_POST_FOUND: zeroPosts,
    NO_ACHIEVEMENT_COMMENT: noAchievementComment,
    NO_ACHIEVEMENT_SPOTLIGHT: noAchievementSpotlight,
    NO_RESULTS_FOUND: noResultFound
}

export const DEN_PRIVATE_ASSETS = {
    MALE: male,
    FEMALE:female,
    NONVEG: nonveg,
    VEG: veg,
}
