import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { getMonthAndDate } from '../../../../utils/common.utils'
import { ListingType, ParticipantType } from '../../../../utils/enums.utils'
import { ProgressBar } from 'react-bootstrap'
import betBadge from '../../../../images/common/bet_badge.png';

/**
* @author
* @class TournamentCard
**/

class TournamentCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tournament: props.tournament,
            id: props.id
        }
    }

    componentDidMount(){}

    componentWillReceiveProps(nextProps) {
        this.setState({
            tournament: nextProps.tournament,
            id: nextProps.id
        })
    }

    getCard = () => {
        const {tournament} = this.state;
        const {participantType, tournamentEndTime, tournamentStartTime, maxParticipant, entryFeeAndPrizeDetails } = tournament;
        const startDate = getMonthAndDate(tournamentStartTime);
        const endDate = getMonthAndDate(tournamentEndTime);
        const cardBg = (entryFeeAndPrizeDetails?.listingType === ListingType.PREMIUM) ? 't-card-premium' : 't-card-regular';
        const detailsStyle = (entryFeeAndPrizeDetails?.listingType === ListingType.PREMIUM) ? 't-body-premium' : 't-body-regular';
        const participant_Type = (participantType === ParticipantType.SINGLE ? 'Solo' : 'Team');

        return (

            <div className={`${cardBg} tournament-card m-lg-2 m-md-2 flex-grow-1 px-2`}>
                <div className="d-flex flex-column h-100">
                    <div className="tournament-game-card" style={{ backgroundImage: `url(${tournament?.baner?.url})` }}>
                        <img src={betBadge} alt='' height='39px' width='26px' style={{display: tournament.isBettingEnabled ? 'block' : 'none'}} />
                        <div className='flex-grow-1'></div>
                        <div className="d-flex justify-content-between w-100 p-1">
                            <span className="fs-10 text-dark bg-white px-2 py-1 fw-700 rounded-pill">{tournament.gameDetails.name}</span>
                            {/* <span className="name"> <AiFillStar style={{ color: '#ffb800' }} /> 4.9</span> */}
                        </div>
                    </div>
                    <div className={`${detailsStyle} px-2`}>
                        {tournament.name?.length <= 15 ? 
                            <h1 className="text-white fs-14 fw-600 text-center my-2">{tournament.name}</h1>
                            :
                            <marquee className='text-white fs-14 fw-600 text-center my-2' direction="left">{tournament?.name}<span className='text-warning px-4'>$$$</span> {tournament?.name}</marquee>
                        }
                        
                        <h1 className="text-warning fs-10 text-center my-2 text-truncate">By {tournament?.organizationDetails?.name}</h1>
                        {/* {this.getSponsors(tournament.sponsorDetails)} */}
                        <ProgressBar now={(tournament.teams.length / maxParticipant) * 100} className='w-100 mx-0' />
                        <div className="d-flex justify-content-between w-100 my-1 mx-0" style={{ width: '90%', margin: 'auto' }}>
                            <p className="spots">Spots</p>
                            <p className="spots text-white">{`${tournament.teams.length}/${maxParticipant}`}</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                            <div>
                                <p className="fs-10 text-white fw-500 mb-1">Entry Fee</p>
                                <p className="fs-10 text-success fw-bold mb-1">{`EC ${entryFeeAndPrizeDetails.entryFeeAmount ? entryFeeAndPrizeDetails.entryFeeAmount : '0'}`}</p>
                            </div>
                            <div className="" style={{ textAlign: 'end' }}>
                                <p className="fs-10 text-white fw-500 mb-1">Prize Pool</p>
                                <p className="fs-10 text-primary fw-bold mb-1">{(entryFeeAndPrizeDetails?.prizePoolAmount) ? `EC ${entryFeeAndPrizeDetails.prizePoolAmount}` : 'TBD'}</p>
                            </div>
                            
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                            <p className="fs-10 text-white fw-bold mb-1" >Type:</p>
                            <p className="fs-10 text-white fw-500 mb-1" >{participant_Type}</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                            <p className="fs-10 text-white fw-bold mb-1" >Pool Type:</p>
                            <p className="fs-10 text-white fw-500 mb-1" >{!entryFeeAndPrizeDetails.entryFee ? 'None' : entryFeeAndPrizeDetails.prizePoolType}</p>
                        </div>

                        <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                        <p className="fs-10 text-white fw-bold mb-1" >{`From ${startDate[0]} - ${endDate[0]}`}</p>
                    </div>
                </div>
            </div>

        )
    }

    render() {
        if(this.state.tournament){
            return this.getCard()
        }else{
            return null;
        }
    }
}


TournamentCard.propTypes = {}
export default TournamentCard