export const getToken = () => {
    return JSON.parse(localStorage.getItem('login'))?.token
}

export const isLoggedIn = () => {
    return !!localStorage.getItem('login')
}

export const profile = () => {
    return JSON.parse(localStorage.getItem('login'))?.authUser?.user
}
export const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}
export const getItem = (key) => {
    try {
        const item = localStorage.getItem(key);

        return JSON.parse(item);
    } catch (e) {
        return null;
    }
}
export const removeItem = (key) => {
    localStorage.removeItem(key);
}

export const clear = () => {
    localStorage.clear();
}