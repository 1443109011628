import React, { Component } from 'react'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//icons
import { FiPlus } from "react-icons/fi";
import stages from "../../../../images/icon/stages.png";
import { AiFillPlusCircle, AiOutlineDelete } from "react-icons/ai";
import '../../../../styles/Tournaments.css';
import TournamentHeader from '../common/TournamentHeader';
import { IoInformationCircleOutline } from "react-icons/io5";
import { errorHandler, generateRoundRobinGroups, getMatchesStructure, getTournamentData, getWebData, is_Natural, PlayoffFormats } from '../../../../utils/common.utils';

import { AxiosResponse, AxiosError } from "axios";
import { toast } from 'material-react-toastify';
import { Modal } from 'react-bootstrap';
import ConfirmationModal from '../../../popup/ConfirmationModal';
import { AiFillDelete, AiOutlineCheck } from 'react-icons/ai';
import TournamentApis from '../../../../helper/tournament.api';
import { isPrime, isRightGroupNo, roundRobinMaxThreshold, validateMaxParticipant } from '../../../../utils/Validations';
import { WARNING } from '../../../../utils/Strings.utils';
import ReactTooltip from 'react-tooltip';
import { BsInfoCircle } from 'react-icons/bs';

export default class DuelRoundRobinGroups extends Component {

    constructor(props) {
        super(props)
        const queryParams = new URLSearchParams(window.location.search);
        let localData = JSON.parse(localStorage.getItem('duel-round-robin-group'))
        this.state = {
            tournamentDetails: null,
            openConfirmationModal: false,
            openEditConfirmationModal: false,
            stageName: (!queryParams.get('isUpdate') && localData?.stageName && (localData?.tournamentId === props.match.params.id)) ? localData.stageName : '',
            maxParticipant: (!queryParams.get('isUpdate') && localData?.maxParticipant && (localData?.tournamentId === props.match.params.id)) ? localData.maxParticipant : '',
            maxNumberOfGroups: (!queryParams.get('isUpdate') && localData?.maxNumberOfGroups && (localData?.tournamentId === props.match.params.id)) ? localData.maxNumberOfGroups : '',
            threshold: (!queryParams.get('isUpdate') && localData?.threshold && (localData?.tournamentId === props.match.params.id)) ? localData.threshold : '',
            updatedMatches: false,
            minParticipant: null,
            roundRobinType: (!queryParams.get('isUpdate') && localData?.roundRobinType && (localData?.tournamentId === props.match.params.id)) ? localData.roundRobinType : 'SINGLE',
            playoffFormat: (!queryParams.get('isUpdate') && localData?.playoffFormat && (localData?.tournamentId === props.match.params.id)) ? localData.playoffFormat : 1,
            groups: (!queryParams.get('isUpdate') && localData?.groups && (localData?.tournamentId === props.match.params.id)) ? localData.groups : []
        }
        this.localObject = {
            stageName: (!queryParams.get('isUpdate') && localData?.stageName && (localData?.tournamentId === props.match.params.id)) ? localData.stageName : '',
            maxParticipant: (!queryParams.get('isUpdate') && localData?.maxParticipant && (localData?.tournamentId === props.match.params.id)) ? localData.maxParticipant : '',
            maxNumberOfGroups: (!queryParams.get('isUpdate') && localData?.maxNumberOfGroups && (localData?.tournamentId === props.match.params.id)) ? localData.maxNumberOfGroups : '',
            threshold: (!queryParams.get('isUpdate') && localData?.threshold && (localData?.tournamentId === props.match.params.id)) ? localData.threshold : '',
            roundRobinType: (!queryParams.get('isUpdate') && localData?.roundRobinType && (localData?.tournamentId === props.match.params.id)) ? localData.roundRobinType : 'SINGLE',
            playoffFormat: (!queryParams.get('isUpdate') && localData?.playoffFormat && (localData?.tournamentId === props.match.params.id)) ? localData.playoffFormat : 1,
            groups: (!queryParams.get('isUpdate') && localData?.groups && (localData?.tournamentId === props.match.params.id)) ? localData.groups : [],
            tournamentId: this.props.match.params.id
        }
        this.props.updateLoader(true);
        getTournamentData(this.props.match.params.id, this.setData)

        this.tournament = new TournamentApis();

        this.isUpdate = queryParams.get('isUpdate');
        this.index = queryParams.get('index');
        this.editIsUpdate = false;
        this.editStages = [];
        this.isValuesChanged = false;
        this.currentStages = null;
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
                stageName: values?.stages[this.index]?.name ? values?.stages[this.index].name : this.state.stageName,
                maxParticipant: values?.stages[this.index]?.maxParticipants ? values?.stages[this.index]?.maxParticipants : this.state.maxParticipant,
                maxNumberOfGroups: values?.stages[this.index]?.maxNumberOfGroups ? values?.stages[this.index]?.maxNumberOfGroups : this.state.maxNumberOfGroups,
                threshold: values?.stages[this.index]?.qualificationThreshold ? values?.stages[this.index]?.qualificationThreshold : this.state.threshold,
                roundRobinType: values?.stages[this.index]?.roundRobinType ? values?.stages[this.index]?.roundRobinType : this.state.roundRobinType,
                playoffFormat: values.stages[this.index]?.playoffFormat ? values.stages[this.index]?.playoffFormat : this.state.playoffFormat,
                groups: values.stages[this.index]?.groupsName ? values.stages[this.index].groupsName : this.state.groups,
                minParticipant: values?.stages?.length ? values?.stages[(values?.stages?.length > 1 && this.isUpdate) ? values?.stages?.length - 2 : values?.stages?.length - 1].qualificationThreshold : null
            });
            this.props.updateLoader(false);
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }
    updateValues = (e, type) => {
        e.preventDefault()
        if (!this.isUpdate) {
            this.localObject[type] = e.target.value;
            localStorage.setItem('duel-round-robin-group', JSON.stringify(this.localObject))
        }
        this.setState({
            [type]: e.target.value,
            updatedMatches: true
        })
    }
    groupNameChange = (val, i) => {
        let groups = this.state.groups
        groups[i] = val
        this.setState({ groups: groups })
        if (!this.isUpdate) {
            this.localObject['groups'] = groups;
            localStorage.setItem('duel-bracket-groups', JSON.stringify(this.localObject))
        }
    }
    updateGroupCount = (e, param) => {
        console.log("e.target.value - ", e.target.value)
        console.log("e.currentTarget.value - ", e.currentTarget.value)
        if (!this.isUpdate) {
            this.localObject[param] = e.target.value;
            localStorage.setItem('duel-round-robin-group', JSON.stringify(this.localObject))
        }
        this.setState({
            [param]: e.target.value
        })
        if (param === 'stageName') {
            this.setState({
                [param]: e.target.value
            })
        } else {
            if (e.currentTarget.value) {
                this.param = e.currentTarget.value;
            }

            this.setState({
                [param]: (e.currentTarget.value ? parseInt(e.currentTarget.value) : ''),
                updatedMatches: true
            });
        }
    }

    onSubmit = (values) => {

        var isUpdate;
        if (this.isUpdate === null) {
            isUpdate = false;
        } else {
            isUpdate = true;
        }
        var groupsName = [];
        console.log("---values :: ", values)
        for (let index = 0; index < this.state.groups?.length; index++) {
            groupsName.push(this.state.groups[index]);
        }

        var stage = {
            level: isUpdate ? this.currentStages[this.index].level : ((this.state.tournamentDetails?.stages?.length) + 1),
            name: values.stageName,
            type: 'DUEL',
            subType: 'Round Robin Groups',
            path: "duel-round-robin-group",
            status: 'UPCOMING',
            maxParticipants: parseInt(values.maxParticipant),
            maxParticipantsPerGroup: (parseInt(values.maxParticipant) / parseInt(values.maxNumberOfGroups)),
            maxNumberOfGroups: parseInt(values.maxNumberOfGroups),
            participants: 0,
            qualificationThreshold: parseInt(values.qualificationThreshold),
            playoffFormat: (values.playoffFormat ? parseInt(values.playoffFormat) : 1),
            roundRobinType: (values.roundRobinType ? values.roundRobinType : 'SINGLE'), // AKA Round Robin Type
            groupsName: groupsName
        }

        if (isUpdate && !this.state.updatedMatches) {
            stage.groups = this.currentStages[this.index].groups;
        }
        else {
            stage.groups = getMatchesStructure(stage, 3);
        }

        this.editIsUpdate = isUpdate;
        this.editStages = [stage];
        let previousStageData = this.state.tournamentDetails?.stages[this.index]
        if (isUpdate) {
            if (
                previousStageData.name !== values.stageName ||
                previousStageData.maxParticipants !== values.maxParticipant ||
                previousStageData.maxNumberOfGroups !== values.maxNumberOfGroups ||
                previousStageData.qualificationThreshold !== values.qualificationThreshold ||
                previousStageData.playoffFormat !== stage.playoffFormat ||
                previousStageData.roundRobinType !== stage.roundRobinType
            ) {
                this.isValuesChanged = true;
            }
        }
        console.log(this.index + " ## AFTER STAGE :: ", (this.state.tournamentDetails?.stages.length - 1) + " -gg- ", (parseInt(this.index) !== parseInt((this.state.tournamentDetails?.stages.length - 1))));

        //console.log("currentStage :: ",this.currentStages[this.index])
        if (isUpdate && (parseInt(this.index) !== parseInt((this.state.tournamentDetails?.stages.length - 1))) && this.isValuesChanged) {
            this.setState({ openEditConfirmationModal: true })
        } else {
            this.addOrUpdate([stage], isUpdate);
        }
    }

    addOrUpdate = (stages, isUpdate) => {
        if (this.state.tournamentDetails?.participantUserId?.length && this.isUpdate) {
            toast.error('Cannot update the stage after participants added in tournament')
        } else {
            this.props.updateLoader(true);
            this.tournament.addOrUpdateStage(this.props.match?.params?.id, stages, isUpdate)
                .then((res: AxiosResponse) => {

                    this.props.updateLoader(false);
                    if (isUpdate) {

                        this.props.history.push(`/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure`);
                        toast.success(`Stage updated successfully`);

                    } else {
                        localStorage.removeItem('duel-round-robin-group')
                        var index = this.state.tournamentDetails?.stages?.length == 0 ? 0 : this.state.tournamentDetails?.stages?.length;
                        this.props.history.push(`/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure/tournament-stages/${index}/slot-details/0`);
                        toast.success(`Stage created successfully`);
                    }
                }).catch((reason: AxiosError) => {

                    this.props.updateLoader(false);
                    errorHandler(reason);
                });
        }
    }

    deleteStage = (index) => {
        console.log("index : " + index + " ::  TID : " + this.props.match?.params?.id)
        var data = {
            tournamentId: this.props.match?.params?.id,
            stage: index
        }
        // var stages = this.state.tournamentDetails.stages;
        // stages.splice(index, 1);
        // this.update(stages,"deleted");
        this.props.updateLoader(true)
        this.tournament.deleteStage(data)
            .then((res: AxiosResponse) => {
                this.props.updateLoader(false);
                this.props.history.push({
                    pathname: `/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure`,
                    state: {
                        orgId: res.data.result.organizationId,
                        tournamentDetails: res.data.result
                    }
                });
                toast.success(`Stage ${parseInt(index) + 1} deleted successfully`)

            }).catch((reason: AxiosError) => {

                this.props.updateLoader(false);
                errorHandler(reason);
            });
    }
    //open confirmation popup
    onClickOpenConfirm = () => {

        this.setState({
            openConfirmationModal: !this.state.openConfirmationModal,
        });
    };
    noOfgroup = (e) => {
        let number = parseInt(e.target.value)
        this.setState({ maxNumberOfGroups: number })
        let groups = []
        if (e.target.value) {
            for (let i = 0; i < number; i++) {
                groups.push(`Group ${i + 1}`)
            }
            this.setState({ groups: groups })
        }
        if (!this.isUpdate) {
            this.localObject['groups'] = groups;
            this.localObject['maxNumberOfGroups'] = e.target.value;
            localStorage.setItem('duel-bracket-groups', JSON.stringify(this.localObject))
        }

    }
    grpNoValidation = (val) => {
        let error;
        let number = parseFloat(val)
        let participants = (this.state.maxParticipant / number)
        if (!is_Natural(number)) {
            error = `Invalid input value`
        } else if ((participants % 2) !== 0) {
            error = `Group number should form even number of participants in group`;
        } else if (number >= this.state.maxParticipant) {
            error = `Group number should be less than max participant`;
        }
        console.log(error)
        return error;
    }
    maxParticipantValidation = (e) => {
        this.setState({ maxParticipant: e })
        let error;
        let number = parseFloat(e)
        if (!is_Natural(number)) {
            error = `Invalid input value`
        } else if (!validateMaxParticipant(this.state.tournamentDetails?.maxParticipant, number)) {
            error = `Max participant should be less than ${this.state.tournamentDetails?.maxParticipant}`
            // } else if (isPrime(number)) {
            //     error = `Max participants should not be a prime number`
            // } 
        } else if ((number % 2) !== 0) {
            error = `Max participants should be a even number`
        }
        if (this.index !== '0') {
            if ((number < this.state.minParticipant)) {
                error = `Max participants should not be less than ${this.state.minParticipant}`
            }
        }
        return error;
    }
    thresholdValidation = (e) => {
        this.setState({ threshold: e })
        let error;
        let number = parseFloat(e)
        if (this.state.maxNumberOfGroups && this.state.maxParticipant && e) {
            let participantPerGroup = this.state.maxParticipant / this.state.maxNumberOfGroups
            let maxThreshold = (participantPerGroup - 1) * this.state.maxNumberOfGroups
            console.log("maxThreshold - ", maxThreshold)
            if (!is_Natural(number)) {
                error = `Invalid input value`
            } else if ((number % this.state.maxNumberOfGroups) !== 0) {
                error = `Threshold should be multiple of ${this.state.maxNumberOfGroups}`
            } else if (maxThreshold < number) {
                error = `Threshold should be less than ${maxThreshold + 1}`
            } else if (number < this.state.maxNumberOfGroups) {
                error = `Threshold should be greter than ${this.state.maxNumberOfGroups}`
            }
        }
        return error;
    }

    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
            <ReactTooltip id={id} place={place} effect="solid" type={type}>
                {text}
            </ReactTooltip>
        )
    }

    render() {
        this.currentStages = this.state.tournamentDetails ? this.state.tournamentDetails.stages : false;
        const isUpdated = ((this.isUpdate && this.currentStages) ? true : false);
        let type = this.state.tournamentDetails?.participantType === 'TEAM' ? 'Teams' : 'Participants';


        // console.log('Stage Details :: ',(isUpdated ? this.currentStages[this.index]:""));

        let initialStageValues = {
            stageName: this.state.stageName,
            maxParticipant: this.state.maxParticipant,
            maxNumberOfGroups: this.state.maxNumberOfGroups,
            qualificationThreshold: this.state.threshold,
            roundRobinType: this.state.roundRobinType,
            playoffFormat: this.state.playoffFormat
        };

        if (this.currentStages[this.index]?.groupsName) {
            this.currentStages[this.index]?.groupsName.map((name, i) => {
                initialStageValues['group' + i] = this.currentStages[this.index]?.groupsName[i]
            })
        }

        let requiredObject = getWebData();

        // console.log("$$ initialStageValues :: ",initialStageValues);

        return (
            <section className="body-section duel-round-robin tooltip-text" style={{ paddingBottom: '50px' }}>
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={stages} heading={`${isUpdated ? "Update" : "Add"} Stage Details`}
                        subHeading={<h1 className="stage-sub-heading text-center">(Round Robin Groups)</h1>}
                        history={this.props.history} />

                    <Formik
                        enableReinitialize
                        initialValues={initialStageValues}

                        validationSchema={Yup.object({
                            stageName: Yup.string().required("*Stage name is required"),
                            maxParticipant: Yup.number().required('Max participants required')
                                .min(2, 'Minimum participant should be 2'),
                            // .test('maxParticipant', `Max participant should be less than ${this.state.tournamentDetails?.maxParticipant}`, () => validateMaxParticipant(this.state.tournamentDetails?.maxParticipant, this.state.maxParticipant))
                            // .test('maxParticipant', `Max participants should not be a prime number`, () => !isPrime(this.state.maxParticipant)),
                            maxNumberOfGroups: Yup.string().required("*Number of group required"),
                            // .test('group-number', `Group number should be divisor of ${this.state.maxParticipant}`, () => isRightGroupNo(this.state.maxParticipant, this.state.maxNumberOfGroups)),
                            qualificationThreshold: Yup.number().required("*Threshold required")
                                .min(1, 'Minimum Threshold should be 1')
                            // .test('threshold', `Threshold should be less than ${(this.state.maxParticipant / this.state.maxNumberOfGroups) + 1}`, () => roundRobinMaxThreshold(this.state.maxNumberOfGroups, this.state.maxParticipant, this.state.threshold)),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.onSubmit(values)
                                setSubmitting(false);
                            }, 400);
                        }}

                    >
                        <Form className="row duel-round-robin-form m-1 mt-lg-5" autoComplete='off'>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="stageName" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Stage Name</label>
                                <Field name="stageName" type="text" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    onKeyUp={(e) => this.updateGroupCount(e, 'stageName')} placeholder="Enter Stage Name"
                                    min={1} step={1} onWheel={(e) => e.target.blur()}
                                />
                                <ErrorMessage component="span" className="error-msg" name="stageName" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="maxParticipant" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Max {type}</label>

                                <a href="#" title="Maximum No. of Participants that can Participate in Tournament or Stage" className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field name="maxParticipant" type="number" validate={this.maxParticipantValidation} onKeyUp={(e) => this.updateValues(e, 'maxParticipant')}
                                    // validate={this.isPrime}
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" placeholder="Enter Max Participants" 
                                    min={1} step={1} onWheel={(e) => e.target.blur()} />
                                <ErrorMessage component="span" className="error-msg" name="maxParticipant"
                                />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="roundRobinType" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Round Robin Type</label>
                                <Field name="roundRobinType" value={this.state.roundRobinType} onChange={(e) => this.updateValues(e, 'roundRobinType')} as="select" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                    <option value="SINGLE" >Normal (Single)</option>
                                    <option value="DOUBLE">Double Round Robin</option>
                                    <option value="TRIPLE">Triple Round Robin</option>
                                </Field>
                                <ErrorMessage component="span" className="error-msg" name="type" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="maxNumberOfGroups" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Max Number of Groups</label>

                                <a href="#" title={requiredObject.INFORMATION_TEXT?.STAGE_DETAILS?.MAX_NO_OF_GROUPS} className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field name="maxNumberOfGroups"
                                    type="number"
                                    validate={this.grpNoValidation}
                                    onChange={e => this.noOfgroup(e)}
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" placeholder="Enter Max Number of Groups"
                                    min={1} step={1} onWheel={(e) => e.target.blur()} />
                                <ErrorMessage component="span" className="error-msg" name="maxNumberOfGroups" />
                            </div>

                            {
                                this.state.maxNumberOfGroups ?
                                    <div className='participant-form border rounded-10 my-4 px-4 '>
                                        <div className="pb-2">
                                            <div className="col-10 offset-1 d-flex justify-content-center">
                                                <h1 className="fs-14 position-absolute px-3" style={{ marginTop: "-8px", backgroundColor: "rgb(32, 14, 69)" }}> Group List</h1>
                                            </div>


                                            {[...Array(this.state.maxNumberOfGroups ? this.state.maxNumberOfGroups : 0)].map((group, index) => {
                                                return (
                                                    <div className="my-3" key={index}>
                                                        <label htmlFor="qualificationThreshold" className="fs-14 fw-600 text-white mb-1">Name of Group {index + 1}</label>
                                                        <Field
                                                            name={`group` + index}
                                                            type="text"
                                                            value={this.state.groups[index]}
                                                            onChange={(e) => { this.groupNameChange(e.target.value, index) }}
                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                            placeholder={`Enter Name of Group ${index + 1}`}
                                                        />
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                    : null
                            }



                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="qualificationThreshold" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Qualification Threshold</label>

                                <a href="#" title={requiredObject.INFORMATION_TEXT?.STAGE_DETAILS?.QUALIFICATION_THRESHOLD} className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field name="qualificationThreshold" type="number" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    placeholder="Enter Qualification Threshold" validate={this.thresholdValidation} onChange={(e) => this.updateValues(e, 'threshold')}
                                    min={1} step={1} onWheel={(e) => e.target.blur()}
                                />
                                <ErrorMessage component="span" className="error-msg" name="qualificationThreshold" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="playoffFormat" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Playoff Format</label>

                                <a href="#" title={requiredObject.INFORMATION_TEXT?.STAGE_DETAILS?.PLAY_OFF_FORMAT} className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field value={this.state.playoffFormat} onChange={(e) => this.updateValues(e, 'playoffFormat')} name="playoffFormat" as="select" id="playOff" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                    {
                                        Object.keys(PlayoffFormats).map(function (key, i) {
                                            return <option key={i} value={PlayoffFormats[key]}>{key}</option>
                                        })
                                    }
                                </Field>
                                <ErrorMessage component="span" className="error-msg" name="playoffFormat" />
                            </div>
                            <div className="mt-5 d-flex justify-content-center">
                                {isUpdated ?
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45">
                                            <AiOutlineCheck className="fs-24" />
                                        </button>
                                        <button type="button" onClick={(e) => this.onClickOpenConfirm(e)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45">
                                            <AiFillDelete className="fs-24" />
                                        </button>
                                    </div>
                                    :
                                    <button type="submit" className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-45">
                                        <FiPlus className="fs-24" />
                                    </button>
                                }


                            </div>
                        </Form>
                    </Formik>
                </div>
                <Modal show={this.state.openConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={
                                {
                                    msg: (
                                        (parseInt(this.index) === parseInt((this.state.tournamentDetails?.stages.length - 1))) ?
                                            <p className="fs-14 fw-600 text-center text-white-50">
                                                Are you sure you want to remove <span className="error-msg">{isUpdated ? this.currentStages[this.index]?.name : ""}</span> from the stages ?
                                            </p> :
                                            <p className="fs-14 fw-600 text-center text-white-50">
                                                Next stages will be deleted on delete of <span className="error-msg">{isUpdated ? this.currentStages[this.index]?.name : ""}</span> stage. Are you sure you want to edit this stage?
                                            </p>
                                    ),
                                    key: (isUpdated ? this.index : null)
                                }
                            }
                            onClickOpenConfirm={this.onClickOpenConfirm}
                            confirmed={this.deleteStage} />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openEditConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={{
                                msg: (
                                    <p className="fs-14 fw-600 text-center text-white-50">
                                        {WARNING.NEXT_STAGE_WILL_BE_DELETED}
                                    </p>
                                ),
                                key: (isUpdated ? this.index : null)
                            }}
                            onClickOpenConfirm={() => { this.setState({ openEditConfirmationModal: false }) }}
                            confirmed={() => { this.addOrUpdate(this.editStages, this.editIsUpdate) }} />
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}
