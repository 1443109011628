import React, { Component } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { CREATE_POST_VIEWS } from '../../../../../utils/enums.utils';
import { ACTION, POST_INTERACTIONS } from '../../../../../utils/Images.utils';

export default class PostAudienceSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPublicDenPost: props.isPublicDenPost,
         };
    }

    updateState = (key, val) => {
        this.setState({
            [key] : val
        });
    }


 
    render() {
        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{height:'82vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2 flex-grow-1'>
                        <div className='row px-2'>
                            <div className='col-2'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)} />
                            </div>
                            <div className='col-8'>
                                <p className='fs-16 text-center fw-700 my-0'>Edit Audience</p>
                            </div>
                            <div className='col-2'>
                            <p className={`cursor-pointer fs-16 fw-600 my-0 mx-1`} style={{ color:'#AD26FF'}} onClick={() => this.props.backToMainView('currentView',CREATE_POST_VIEWS.CREATE_POST, 'isPublicDenPost',this.state.isPublicDenPost)}>Done</p>
                            </div>
                        </div>
                        <hr className='hr-line' />
                        <p className='fs-16 fw-500'>Who can see your post?</p>
                        <p className='fs-14 fw-500 my-0'>Your post will appear in your feed, on your profile and in search results</p>
                        <hr className='hr-line' />
                        <div className='d-flex align-items-center my-2'>
                            <img src={POST_INTERACTIONS.AUDIENCE.PUBLIC} alt='' className='' style={{ height:'30px'}}/>
                            <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <p className='fs-16 fw-500 my-0'>Public</p>
                                <p className='fs-12 fw-500 text-white-50 my-0'>Anyone on Espotz Den</p>
                            </div>
                            {this.state.isPublicDenPost? 
                                <img src={ACTION.SELECTED} alt='' className='' style={{ height:'16px'}} />
                                :
                                <img src={ACTION.UNSELECTED} alt='' className='' style={{ height:'16px'}} onClick={() => this.updateState('isPublicDenPost',true)}/>
                            }
                        </div>
                        <div className='d-flex align-items-center my-2'>
                            <img src={POST_INTERACTIONS.AUDIENCE.FOLLOWERS} alt='' className='' style={{ height:'28px'}} />
                            <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <p className='fs-16 fw-500 my-0'>Followers</p>
                                <p className='fs-12 fw-500 text-white-50 my-0'>Your Followers on Espotz Deb</p>
                            </div>
                            {!this.state.isPublicDenPost ? 
                                <img src={ACTION.SELECTED} alt='' className='cursor-pointer' style={{ height:'16px'}} />
                                :
                                <img src={ACTION.UNSELECTED} alt='' className='cursor-pointer' style={{ height:'16px'}} onClick={() => this.updateState('isPublicDenPost',false)} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
