import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import { capitalize } from '../../../utils/common.utils';
import { blockDenProfile, blockUnBlockDenProfile, downloadPostAssets, enableDisabelDenPostActions, pinDenPost, saveDenPost, updateDenPost } from '../../../utils/Den.utils';
import { POST_INTERACTIONS, PROFILE_PAGE_IMAGES } from '../../../utils/Images.utils';
import { DEN, SUCCESS } from '../../../utils/Strings.utils';
import TournamentShare from '../TournamentShare';
import avatar from '../../../images/participantAvtar.jpg';
import Image from '../../common/Image';
import { toast } from 'material-react-toastify';
import SpotLight from './SpotLight';
import { NavLink } from 'react-router-dom';

export default class PostActions extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      openSpotlight: false,
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    this.setState({openAction: nextProps.show})
  }

  updateState = (key, val) => {
    this.setState({
      [key] : val
    })
  }


  getActionsView = () =>{


    if(this.props.postType){

      switch (this.props.postType) {
        case DEN.POST_TYPES.SAVED:
          return(
            <div className='text-white post-action-container mt-auto actions-body'>
                <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>saveDenPost(this.props.denProfile?._id, this.props.feedDetails[this.props.selectedActionIndex]._id, false, this.props.updateProfileDetails)}>
                    <img src={POST_INTERACTIONS.ACTIONS.SAVE} alt='report-post' width='32' className='me-3' />
                    <p className='fs-16 fw-600 my-0'> Unsave</p>
                </div>
            </div>
          );
        case DEN.POST_TYPES.HIDDEN:
          return(
            <div className='text-white post-action-container mt-auto actions-body'>
                <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>updateDenPost(this.props.feedDetails[this.props.selectedActionIndex]?._id, {isHidden: !this.props.feedDetails[[this.props.selectedActionIndex]]?.isHidden}, this.props.updatePostDetails, (this.props.feedDetails[this.props.selectedActionIndex]?.isHidden ? SUCCESS.POS_HAS_BEEN_UNHIDE : SUCCESS.POS_HAS_BEEN_HIDE))}>
                    <img src={POST_INTERACTIONS.ACTIONS.UNHIDE} alt='report-post' width='32' className='me-3' />
                    <p className='fs-16 fw-600 my-0'> Un-Hide</p>
                </div>
                <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>updateDenPost(this.props.feedDetails[this.props.selectedActionIndex]?._id, {isDeleted: true}, this.props.updatePostDetails, SUCCESS.POS_HAS_BEEN_DELETED)}>
                    <img src={POST_INTERACTIONS.ACTIONS.REMOVE} alt='report-post' width='32' className='me-3' />
                    <p className='fs-16 fw-600 my-0'> Remove</p>
                </div>
            </div>
          );
        case "USER-ACTIONS":
          return(
            <div className='text-white post-action-container mt-auto actions-body'>
              {this.props.myDenProfileId !== this.props.denProfileId &&
                <>
                  <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>this.setState({openSpotlight: true, openAction: false})}>
                      <img src={POST_INTERACTIONS.ACTIONS.SPOTLIGHT} alt='report-post' width='32' className='me-3' />
                      <p className='fs-16 fw-600 my-0'> Send Spotlight</p>
                  </div>
               
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                    <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>blockUnBlockDenProfile(this.props.denProfile?._id,this.props.denProfileId, true, this.props.updateProfileDetails)}>
                        <img src={POST_INTERACTIONS.ACTIONS.BLOCK} alt='report-post' width='32' className='me-3' />
                        <p className='fs-16 fw-600 my-0'> Block Profile</p>
                    </div>
                </>
              }
                <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                <div className='cursor-pointer d-flex align-items-center m-2' onClick={this.toggleShare}>
                    <img src={POST_INTERACTIONS.ACTIONS.OP} alt='report-post' width='32' className='me-3' />
                    <p className='fs-16 fw-600 my-0'> Share Profile</p>
                </div>
            </div>
          );
        default:
          return null;
          
      }

    }else{
      return(
        <div className='text-white post-action-container mt-auto actions-body'>
            { this.props.denProfile?.savedPosts?.includes(this.props.feedDetails[this.props.selectedActionIndex]?._id) ? 
                <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>saveDenPost(this.props.denProfile?._id, this.props.feedDetails[this.props.selectedActionIndex]._id, false, this.props.updateProfileDetails)}>
                    <img src={POST_INTERACTIONS.ACTIONS.SAVE} alt='save-post' width='32' className='me-3' />
                    <p className='fs-16 fw-600 my-0'> Unsave</p>
                </div>
                : 
                <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>saveDenPost(this.props.denProfile?._id, this.props.feedDetails[this.props.selectedActionIndex]._id, true, this.props.updateProfileDetails)}>
                    <img src={POST_INTERACTIONS.ACTIONS.SAVE} alt='save-post' width='32' className='me-3' />
                    <p className='fs-16 fw-600 my-0'> Save</p>
                </div>
            }

            {this.props.feedDetails[this.props.selectedActionIndex]?.from[0]?._id ===  this.props.denProfile?._id &&
                <>
                <>
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                    <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>updateDenPost(this.props.feedDetails[this.props.selectedActionIndex]?._id, {isHidden: !this.props.feedDetails[[this.props.selectedActionIndex]]?.isHidden}, this.props.updatePostDetails, (this.props.feedDetails[this.props.selectedActionIndex]?.isHidden ? SUCCESS.POS_HAS_BEEN_UNHIDE : SUCCESS.POS_HAS_BEEN_HIDE))}>
                        <img src={POST_INTERACTIONS.ACTIONS.HIDE} alt='hide-post' width='32' className='me-3' />
                        <p className='fs-16 fw-600 my-0'> {this.props.feedDetails[this.props.selectedActionIndex]?.isHidden ? 'Un-Hide' : 'Hide'}</p>
                    </div>
                </>
                <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                { this.props.denProfile?.pinnedPosts?.includes(this.props.feedDetails[this.props.selectedActionIndex]?._id) ? 
                    <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>pinDenPost(this.props.denProfile?._id, this.props.feedDetails[this.props.selectedActionIndex]._id, false, this.props.updateProfileDetails)}>
                        <img src={POST_INTERACTIONS.ACTIONS.PIN} alt='save-post' width='32' className='me-3' />
                        <p className='fs-16 fw-600 my-0'> Un-Pin Post</p>
                    </div>
                    : 
                    <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>pinDenPost(this.props.denProfile?._id, this.props.feedDetails[this.props.selectedActionIndex]._id, true, this.props.updateProfileDetails)}>
                        <img src={POST_INTERACTIONS.ACTIONS.PIN} alt='save-post' width='32' className='me-3' />
                        <p className='fs-16 fw-600 my-0'> Pin Post</p>
                    </div>
                }
                </>
                
            }

            { this.props.feedDetails[this.props.selectedActionIndex]?.from[0]?._id !==  this.props.denProfile?._id && 
              <>
                <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                <div className='cursor-pointer d-flex align-items-center m-2' onClick={() => this.props.showHidePostAction(this.props.selectedActionIndex, false, true)}>
                  <img src={POST_INTERACTIONS.ACTIONS.REPORT} alt='report-post' width='32' className='me-3' />
                  <p className='fs-16 fw-600 my-0'> Report</p>
                </div>
              </>
            }
            <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
            <div className='cursor-pointer d-flex align-items-center m-2' onClick={()=>downloadPostAssets(this.props.feedDetails[this.props.selectedActionIndex], this.props.onClose)}>
                <img src={POST_INTERACTIONS.ACTIONS.DOWNLOAD} alt='download-post' width='32' className='me-3' />
                <p className='fs-16 fw-600 my-0'> Download</p>
            </div>

            {((this.props.feedDetails[this.props.selectedActionIndex]?.from[0]?._id ===  this.props.denProfile?._id) && this.props.feedDetails[this.props.selectedActionIndex]?.type !== 'REPOST' && this.props.feedDetails[this.props.selectedActionIndex]?.type !== 'POLL') && 
              <NavLink to={`/den/${this.props.denProfile?._id}/create-post/${this.props.feedDetails[this.props.selectedActionIndex]?._id}?isEditPost=true`}>
                  <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                  <div className='cursor-pointer d-flex align-items-center m-2 ' >
                      <img src={POST_INTERACTIONS.ACTIONS.EDIT} alt='hide-post' width='32' className='me-3' />
                      <p className='fs-16 fw-600 my-0 text-white'> Edit Post </p>
                  </div>
              </NavLink>
            }
            {this.props.feedDetails[this.props.selectedActionIndex]?.from[0]?._id ===  this.props.denProfile?._id && 
                Object.keys(DEN.INTERACTIONS.DISABLE).map((key,i)=>{
                  const isDisable = this.props.feedDetails[this.props.selectedActionIndex]?.disable[DEN.INTERACTIONS.DISABLE[key]];
                  const data = {
                    action : DEN.INTERACTIONS.DISABLE[key],
                    isDisable : !isDisable
                  };
                  return (
                    <div key={i}>
                        <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                        <div className='cursor-pointer d-flex align-items-center m-2 ' onClick={()=>enableDisabelDenPostActions(this.props.feedDetails[this.props.selectedActionIndex]?._id, data, this.props.updatePostDetails)}>
                            <img src={POST_INTERACTIONS.ACTIONS[isDisable? 'UNHIDE' : 'HIDE']} alt='hide-post' width='32' className='me-3' />
                            <p className='fs-16 fw-600 my-0 '> {isDisable ? 'Enable' : 'Disable'} {capitalize(DEN.INTERACTIONS.DISABLE[key])}</p>
                        </div>
                    </div>
                  )
                })
            }
        </div>
      )
    }
   
  }

  toggleShare = () => {
    this.setState({openShare : !this.state.openShare})
    this.props.onClose()
  }

  toggleSpotlight = () => {
    this.setState({openSpotlight : !this.state.openSpotlight})
    this.props.onClose()
  }
  
  render() {
    return (
      <>
      {/* Action Pop-up */}
      <Modal className="d-flex flex-column w-100" show={this.state.openAction} dialogClassName="testsss my-0">
        <div className='text-center cursor-pointer'><AiFillCloseCircle onClick={this.props.onClose} style={{height:'40px', width:'40px', color: 'white' }} /></div>
        {this.getActionsView()}
      </Modal>
      {/* Share Pop-up */}
      <Modal show={this.state.openShare} centered dialogClassName="social-media-modal">
          <Modal.Body>
              <TournamentShare
                  toggleShare={this.toggleShare}
                  denProfileId={this.props.denProfileId}
                  isProfileShare={true}
              />
          </Modal.Body>
      </Modal>
      {/* Spotlight Pop-up */}
      {this.state.openSpotlight && <SpotLight enableComment={false} isLiveStreaming={false} type={DEN.SPOTLIGHT.TYPE.USER} parentId={this.props.denProfileId} denProfile={this.props.denProfile} toUser={this.props.denProfileId}  openSpotlight={this.state.openSpotlight} updateState={this.updateState} close={this.props.onClose}/>}
      </>
    )
  }
}
