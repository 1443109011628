import React, { Component } from 'react'
import { capitalize } from '../../../../utils/common.utils'
import { likeDenPost, likeDenPostComment } from '../../../../utils/Den.utils'
import { POST_INTERACTIONS } from '../../../../utils/Images.utils'
import { profile } from '../../../../utils/localstorage.utils';

export default class SparksOnComments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comment: props.comment,
            denProfile: props.denProfile
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            comment: newProps.comment,
            denProfile: newProps.denProfile
        })
    }


    render() {

        const {denProfile} = this.state;
        
        return (
            <div className={`${true ? 'interacion-icons-box cursor-pointer' : 'd-none'}`} style={{zIndex: '1'}}>
           
                { Object.keys(POST_INTERACTIONS.SPARKS).map((key,i)=>{
                   return(
                        <div className='d-flex flex-column align-items-center hover-zoomin' key={i}>
                            <img src={POST_INTERACTIONS.SPARKS[key].LOGO} alt='spark' style={{width:'35px', height:'35px'}} onClick={()=>{ likeDenPostComment(this.state.comment._id, {denId: this.state.denProfile?._id,type: POST_INTERACTIONS.SPARKS[key].NAME}, this.props.updateLikedPostDetails, this.props.updateProfileDetails)}} />
                            <span className='fs-14 fw-600 text-white-50'>{capitalize(POST_INTERACTIONS.SPARKS[key].NAME)}</span>
                            <span className='fs-12 fw-600 text-white-50'>{denProfile?.sparks?.[POST_INTERACTIONS.SPARKS[key].NAME]}</span>
                        </div>
                    )
                })}
            </div>
        )
    }
}
