import { Component } from 'react';
import { Form } from 'react-bootstrap'
import { RiFacebookFill, RiWhatsappFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import MicroWebSiteNav from '../configuration/MicroWebSiteNav';
import Badge from 'react-bootstrap/Badge';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import '../../../styles/Layout.css';
import { errorHandler, regions, textColors, backgroundColors, secondaryTextColors, ellipsis, remainingTime, updateLoader } from '../../../utils/common.utils';
import TeamApis from '../../../helper/teams.api';
import MicroWebSiteContactUs from '../configuration/MicroWebSiteContactUs';
import TournamentApis from '../../../helper/tournament.api';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { NavLink } from 'react-router-dom';
import { ASSETS } from '../../../utils/Strings.utils';
import { getAllGamesDetails } from '../../../utils/Games.utils';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class LayoutOne extends Component {

  service = new TeamApis();
  TournamentApis = new TournamentApis();

  constructor(props) {
    super(props);
    this.state = {
      microWebSiteData: props.microWebSiteData,
      games: [],
      background: (props.microWebSiteData ? props.microWebSiteData.color.background : 4),
      primary: (props.microWebSiteData ? props.microWebSiteData.color.primary : 4),
      secondary: (props.microWebSiteData ? props.microWebSiteData.color.secondary : 4),
      openNavbar: false,
      tournaments: [],
      selectedGame: null,
    };
    if (props.microWebSiteData) {
      this.getData(props.microWebSiteData.organizationId);
    }
    getAllGamesDetails(this.setAllGamesDetails);
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  setAllGamesDetails = (response) => {
    this.setState({
      games : response,
      selectedGame : response[0]
    });
  }

  getData = (id) => {
    var data = { organizationId: id }
    updateLoader(true);
    this.TournamentApis.getTournamentsById(data).then(
      (res: AxiosResponse) => {
        var data = res.data['result']
        this.setState({ tournaments: data })
        updateLoader(false);
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  selectYourGame = (e, type, i) => {
    e.preventDefault();
    this.setState({
      [type]: i
    });
  }

  toggleOpenNavbar = () => {
    this.setState({ openNavbar: !this.state.openNavbar })
  }

  // getSponsors = () => {

  //   if (this.state.microWebSiteData.sponsors[0] && this.state.microWebSiteData.sponsors[0] !== null) {
  //     this.state.microWebSiteData.sponsors.map((sponsor, i) => {
  //       return (
  //         <Carousel.Item key={i}>
  //           <div>
  //             <div className="banner-card" style={{ backgroundImage: `url(${sponsor?.logo?.url})` }}>

  //             </div>
  //             <h6 className="text-center">
  //               <Badge pill bg="none" className=" mt-1" style={{ color: `${this.state.secondary}`, backgroundColor: `${this.state.primary}` }}>{sponsor.type + " : " + ellipsis(sponsor.name, 20)}</Badge>
  //             </h6>
  //           </div>
  //         </Carousel.Item>
  //       )
  //     })
  //   }

  // }

  render() {
    return (
      <div>
        {/* Site Navigation Bar */}
        <MicroWebSiteNav showMenu={this.props.isPreview} path={this.props.path} redirectTo={this.props.redirectTo} state={this.state} microWebSiteData={this.state.microWebSiteData} toggleOpenNavbar={this.toggleOpenNavbar} />


        {/* Site Body */}
        <section className="layout layout1 pt-6" style={{ display: (this.state.openNavbar) ? 'none' : 'block', backgroundColor: `${this.state.background}` }}>
          {this.state.microWebSiteData ?
            <>
              <div className="clanView-banner">
                <img className="clanView-background" src={this.state.microWebSiteData?.banner?.url ? this.state.microWebSiteData.banner.url : ASSETS.DEFAULT_COVER} width="50" height="50" alt="" />
                <img className="clanView-fronImage"
                  src={this.state.microWebSiteData?.banner?.url ? this.state.microWebSiteData.banner.url : ASSETS.DEFAULT_COVER} alt="" width="50" height="50" />
              </div>
              <div className='container'>{/* Site Section */}
                <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary}`} style={{color: `${this.state.primary}`}}>FIND TOURNAMENTS</h1>
                <h1 className={`fs-16 text-center mb-3 micro-web-site-sc-text-${this.state.secondary}`} style={{color: `${this.state.primary}`}}>1. Choose One Region</h1>
                <Form.Select aria-label="Floating label select example" className={`select-form mx-auto`} style={{ color: `${this.state.primary}`, backgroundColor: `${this.state.secondary}`, border: `1px solid ${this.state.secondary}` }}>
                  {this.state.selectedGame?.regions.map((region, i) => {
                    return (
                      <option className="form-option" key={i} value={region} style={{ color: `${this.state.primary}` }}>{region}</option>
                    );
                  })}
                </Form.Select>
                <h1 className={`fs-16 text-center mb-3 micro-web-site-sc-text-${this.state.secondary}`} style={{color: `${this.state.primary}`}}>2. Choose Game</h1>
                <div className="row justify-content-center">
                  {this.state.games.map((game, i) => {
                    console.log('micro web link game ::', game)
                    return (
                      <div className="col-6 col-md-4 col-lg-3" key={i}>
                        <div className="game-card" style={{ backgroundImage: `url(${game?.logo?.url})` }} onClick={(e) => this.selectYourGame(e, "selectedGame", game)}>
                          {this.state.selectedGame?._id === game._id ? <IoIosCheckmarkCircle style={{ opacity: 0.7, color: `${this.state.background}` }} className="select-ic select-game" /> : null}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* <div className="d-flex flex-column justify-content-center">
                  <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center" style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}>Search</button>
                  <div className="d-flex seperator-line-container my-3">
                    <p className="modal-hr-line border-1 flex-grow-1 my-auto" style={{ backgroundColor: `${this.state.primary}` }}></p>
                    <p className="fs-14 text-white mx-2 my-0 fs-800" style={{ color: `${this.state.primary}` }}>OR</p>
                    <p className="modal-hr-line  border-1 flex-grow-1 my-auto" style={{ backgroundColor: `${this.state.primary}` }}></p>
                  </div>
                  <button
                    className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center"
                    style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}
                    onClick={() => this.props.redirectTo('tournament')}>
                    Browse All Tournaments
                  </button>
                </div> */}

                {/* Tournaments */}
                <section className="tournaments d-flex flex-column mx-auto my-3">
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary} my-3`} style={{color: `${this.state.primary}`}}>Upcoming Tournaments</h1>
                  {(this.state.tournaments?.length > 0) ?

                    <section className="tournament-section tournament-carousel">
                      <div className="container text-center pt-5">
                        <Carousel
                          responsive={responsive}
                          infinite={true}
                          autoPlay={this.props.deviceType !== "mobile" ? true : false}
                          autoPlaySpeed={1500}
                          // className='d--lg-flex justify-content-lg-center'
                          transitionDuration={500}
                          deviceType={this.props.deviceType}

                        >
                          {this.state.tournaments?.map((tournament, i) => {
                            const timeLeft = remainingTime(tournament?.registrationEndTime);
                            return (
                              <div className="card-container mx-2" key={i}>
                                <NavLink to={`/tournament/${tournament._id}`} style={{textDecoration:'none'}}>
                                  <div className="tournament-card">
                                    <div className="top-section " style={{ backgroundImage: `url(${tournament?.baner?.url})` }}>
                                      <h1 className="card-name">{tournament?.gameDetails?.name}</h1>
                                    </div>
                                    <div className="bottom-section p-2 px-3">
                                      <h1 className="fw-bold text-white text-center fs-14">{tournament?.name}</h1>

                                      <div className="d-flex flex-row justify-content-between my-2">
                                        <div className="text-left">
                                          <h1 className="fs-10 text-whitefs-10 text-white">Entry Fee</h1>
                                          <h1 className="fs-10 text-white fw-700" style={{ color: '#03d158' }}>{(tournament.entryFeeAndPrizeDetails?.entryFeeAmount) ? `EC ${tournament.entryFeeAndPrizeDetails.entryFeeAmount}` : 'TBD'}</h1>
                                        </div>
                                        <div className="text-right">
                                          <h1 className="fs-10 text-white">Time Left</h1>
                                          <h1 className="fs-10 text-white fw-700" style={{ color: '#ff453a' }}>{`${timeLeft[0]} ${timeLeft[1]}`}</h1>
                                        </div>
                                      </div>
                                      <p className="fs-8 text-start text-white flex-grow-1">
                                        {ellipsis(tournament?.bio, 200)}
                                      </p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            )
                          }
                          )}
                        </Carousel>
                      </div>
                    </section>
                    :
                    <div className="text-center">
                      <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                    </div>}
                  <button
                    className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center my-5"
                    style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}
                    onClick={() => this.props.redirectTo('tournament')}>
                    Browse All Tournaments
                  </button>
                </section>

                <div className='mt-7'>
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary}`} style={{color: `${this.state.primary}`}}>OUR SPONSORS</h1>
                  {(this.state.microWebSiteData?.sponsors?.length > 0) ?
                    <Carousel
                    // swipeable={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={1500}
                    transitionDuration={500}
                    deviceType={this.props.deviceType}
                    >
                      {this.state.microWebSiteData.sponsors.map((sponsor, i) => {
                        return (
                          <div key={i} className='mx-2'>
                            <div className="banner-card" style={{ backgroundImage: `url(${sponsor?.logo?.url})` }}>
                            </div>
                            <h6 className="text-center mt-2">
                              <Badge pill bg="none" className=" mt-1 w-100 text-truncate" style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}`, maxWidth: 'fit-content' }}>{sponsor.type + " : " + sponsor.name}</Badge>
                            </h6>
                          </div>
                        )
                      }
                      )}
                    </Carousel>
                    :
                    <div className="text-center">
                      <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                    </div>
                  }
                </div>

                <section className="tournaments d-flex flex-column mx-auto my-3">
                  <section className="tournament-section tournament-carousel">
                    <div className="container text-center pt-5">
                      <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary}`} style={{color: `${this.state.primary}`}}>CUSTOM IMAGES</h1>
                      <Carousel
                        responsive={responsive}
                        infinite={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={1500}
                        transitionDuration={500}
                        deviceType={this.props.deviceType}
                      >
                      
                          {this.state.microWebSiteData?.customImage?.map((el,i) => {
                            return (
                              <div className="banner-card mx-2" style={{ backgroundImage: `url(${el.url})`, backgroundPosition: 'center'}} key={i}></div>
                            )
                          })}
                      </Carousel>
                    </div>
                  </section>
                </section>


                {/* Social Media Contacts */}
                <MicroWebSiteContactUs microWebSiteData={this.state.microWebSiteData} backgroundColors={backgroundColors} secondaryTextColors={secondaryTextColors} background={this.state.background} secondary={this.state.secondary} />
                {this.props.isPreview ?
                  <button className="close-preview" onClick={() => this.props.openPreview(false, this.state.microWebSiteData)}>Close Preview</button>
                  :
                  null}
              </div>
            </>
            :
            <>
              <div className="clanView-banner">
                <img className="clanView-background" style={{ backgroundColor: '#505050' }} alt="" />
                <img className="clanView-fronImage" style={{ backgroundColor: '#505050' }} alt="" />
              </div>

              <div className='container'>
                <h1 className="fs-20 text-white text-center mt-4">FIND TOURNAMENTS</h1>
                <h1 className="fs-16 text-white text-center mb-3">1. Choose One Region</h1>
                <Form.Select aria-label="Floating label select example" className="select-form mx-auto">
                  <option>ASIA</option>
                  <option className="form-option" value="1">ASIA</option>
                  <option className="form-option" value="2">EUROPE</option>
                  <option className="form-option" value="3">NORTH AMERICA</option>
                </Form.Select>
                <h1 className="fs-16 text-white text-center mb-3">2. Choose Game</h1>
                <div className="row">
                  <div className="col-6 col-md-4 col-lg-3">
                    <div className="game-card"></div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3">
                    <div className="game-card"></div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3">
                    <div className=" game-card"></div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3">
                    <div className="game-card"></div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3">
                    <div className="game-card"></div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3">
                    <div className="game-card"></div>
                  </div>
                </div>

                <div className="d-flex flex-column justify-content-center">
                  <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center">Search</button>
                  <div className="d-flex align-items-center my-3">
                    <p className="modal-hr-line my-auto" style={{ backgroundColor: '#ffb800' }}></p>
                    <p className="fs-14 text-white mx-2 my-0" style={{ color: '#ffb800' }}>OR</p>
                    <p className="modal-hr-line my-auto" style={{ backgroundColor: '#ffb800' }}></p>
                  </div>
                  {/* <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center">Browse All Tournaments</button> */}
                </div>

                <section className="tournaments d-flex flex-column mx-auto my-3">
                  <h1 className={`fs-20 text-center my-3`}>Upcoming Tournaments</h1>

                  <section className="tournament-section tournament-carousel">
                    <div className="container text-center pt-5">
                      <Carousel
                        responsive={responsive}
                        infinite={true}
                        swipeable={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={1500}
                        className='d--lg-flex justify-content-lg-center'
                      // slidesToSlide={1}
                      >
                        <div className="card-container mx-2">
                          <div className="tournament-card w-100">
                            <div className="top-section " style={{ backgroundColor: 'grey' }}>
                            </div>
                            <div className="bottom-section p-2 px-3">
                            </div>
                          </div>
                        </div>
                        <div className="card-container mx-2">
                          <div className="tournament-card w-100">
                            <div className="top-section " style={{ backgroundColor: 'grey' }}>
                            </div>
                            <div className="bottom-section p-2 px-3">
                            </div>
                          </div>
                        </div>
                        <div className="card-container mx-2">
                          <div className="tournament-card w-100">
                            <div className="top-section " style={{ backgroundColor: 'grey' }}>
                            </div>
                            <div className="bottom-section p-2 px-3">
                            </div>
                          </div>
                        </div>
                        <div className="card-container mx-2">
                          <div className="tournament-card w-100">
                            <div className="top-section " style={{ backgroundColor: 'grey' }}>
                            </div>
                            <div className="bottom-section p-2 px-3">
                            </div>
                          </div>
                        </div>
                      </Carousel>
                    </div>
                  </section>
                  <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center my-5">
                    Browse All Tournaments
                  </button>
                </section>

                <div className='mt-7'>
                  <h1 className="fs-20 text-white text-center">OUR SPONSORS</h1>

                  <Carousel
                    responsive={responsive} infinite={true} swipeable={true} autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={1500} className='d--lg-flex justify-content-lg-center'
                  >
                    <div>
                      <div className="banner-card" style={{ backgroundColor: 'grey' }}>
                      </div>
                      <h6 className="text-center mt-2">
                        <Badge pill bg="none" className=" mt-1 w-100 text-truncate" style={{ color: '', backgroundColor: '', maxWidth: 'fit-content' }}>sponosr name</Badge>
                      </h6>
                    </div>
                    <div>
                      <div className="banner-card" style={{ backgroundColor: 'grey' }}>
                      </div>
                      <h6 className="text-center mt-2">
                        <Badge pill bg="none" className=" mt-1 w-100 text-truncate" style={{ color: '', backgroundColor: '', maxWidth: 'fit-content' }}>sponosr name</Badge>
                      </h6>
                    </div>
                    <div>
                      <div className="banner-card" style={{ backgroundColor: 'grey' }}>
                      </div>
                      <h6 className="text-center mt-2">
                        <Badge pill bg="none" className=" mt-1 w-100 text-truncate" style={{ color: '', backgroundColor: '', maxWidth: 'fit-content' }}>sponosr name</Badge>
                      </h6>
                    </div>
                    <div>
                      <div className="banner-card" style={{ backgroundColor: 'grey' }}>
                      </div>
                      <h6 className="text-center mt-2">
                        <Badge pill bg="none" className=" mt-1 w-100 text-truncate" style={{ color: '', backgroundColor: '', maxWidth: 'fit-content' }}>sponosr name</Badge>
                      </h6>
                    </div>
                  </Carousel>
                </div>

                <div className="mt-6 text-center">
                  <img src={"https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/Screenshot_from_2021-12-07_17-09-15-removebg-preview.png"} alt={""} className="border border-2 border-white rounded-10" style={{ height: '100px' }} />
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <div className="icon-container">
                    <RiFacebookFill className="btn-size-20" />
                  </div>
                  <div className="icon-container">
                    <AiFillInstagram className="btn-size-20" />
                  </div>
                  <div className="icon-container">
                    <FaTelegramPlane className="btn-size-20" />
                  </div>
                  <div className="icon-container">
                    <FaTwitter className="btn-size-20" />
                  </div>
                  <div className="icon-container">
                    <RiWhatsappFill className="btn-size-20" />
                  </div>
                </div>
              </div>
            </>
          }
        </section>
      </div>
    );
  }
}

export default LayoutOne;
