import React, { Component } from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { dateFormat } from '../../utils/common.utils';

export default class TransactionDetails extends Component {
    constructor(props) {
      super(props)
      this.state = {
        transactionDetails: props.transactionDetails
      }
    }

  render() {
    const {transactionDetails} = this.state
    return (
        <div className='p-3'>
        <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px' }} onClick={() => this.props.toggleTransactionModal()} />
        <p className="fs-20 fw-bold text-center text-white mb-3">Transaction Details</p>

        <div className="row">
            <div className="col-lg-6 col-12">
                <p className="fs-10 fw-600 text-white-50 my-0">RFERENCE ID</p>
                <p className="fs-14 fw-600 text-white mb-2">5965555</p>
            </div>
            <div className="col-lg-6 col-12">
                <p className="fs-10 fw-600 text-white-50 my-0">AMOUNT</p>
                <p className="fs-14 fw-600 text-white mb-2">{transactionDetails.transactionAmount} EC</p>
            </div>
            <div className="col-lg-6 col-12">
                <p className="fs-10 fw-600 text-white-50 my-0">STATUS</p>
                <p className={`fs-14 fw-600 text-success mb-2 ${transactionDetails.transactionStatus === 'SUCCESS' ? 'text-success' : 'text-danger'}`}>{transactionDetails.transactionStatus}</p>
            </div>
            <div className="col-lg-6 col-12">
                <p className="fs-10 fw-600 text-white-50 my-0">TRANSACRION</p>
                <p className="fs-14 fw-600 text-white mb-2">{transactionDetails._id}</p>
            </div>
            <div className="col-lg-6 col-12">
                <p className="fs-10 fw-600 text-white-50 my-0">DATE</p>
                <p className="fs-14 fw-600 text-white mb-2">{dateFormat(transactionDetails.transactionDateAndTime)}</p>
            </div>
            <div className="col-lg-6 col-12">
                <p className="fs-10 fw-600 text-white-50 my-0">REMARKS</p>
                <p className="fs-14 fw-600 text-white mb-2">Debited by Grand Pixas</p>
            </div>
        </div>
    </div>
    )
  }
}
