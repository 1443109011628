import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../styles/activate-account.css";

class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectMsg: true
        };
    }
    componentDidMount(){
    }

    ConfirmationMsg = () => {
        const { selectMsg } = this.state
        this.setState({
            selectMsg: !selectMsg
        })
    }

    render() {
        return (
            <>
                <div className="p-3">
                    <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => { this.props.onClickOpenConfirm() }} />
                    <p className="fs-20 fw-bold text-center text-white mb-3">Confirm</p>
                    {this.props.confirm?.msg}
                    <div className="d-flex justify-content-center mt-3">
                        <button className="white-flat-btn fs-16 text-dark fw-600 border-0 py-2 px-4 me-1" onClick={() => { this.props.confirmed(this.props.confirm?.key) }} >Yes</button>
                        <button className="white-flat-btn fs-16 text-dark fw-600 border-0 py-2 px-4 ms-1" onClick={() => { this.props.onClickOpenConfirm() }}>No</button>
                    </div>

                    {
                        this.props.confirm?.key === 'deleteClan' && this.props.clanStatus ?
                            <div>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex align-items-center my-3">
                                        <p className="border-1 flex-grow-1 my-auto" style={{height:'1px'}}></p>
                                        <p className="fs-14 text-white mx-2 my-0 fw-700">OR</p>
                                        <p className="border-1 flex-grow-1 my-auto" style={{height:'1px'}}></p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-link" onClick={() => { this.props.insteadDeactivate() }}>Deactivate</button>
                                </div>
                            </div>
                            : null
                    }

                </div>
            </>
        )
    }
}

export default ConfirmationModal