import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import { errorHandler, getTournamentData } from '../../../utils/common.utils';
// Images
import participantAvtar from "../../../images/participantAvtar.jpg";
import formInfoIc from "../../../images/icon/formInfo-ic.png";
// Icons
import { MdOutlineGroups } from 'react-icons/md';
import { FaSearch } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";

import ParticipantDetails from '../../popup/ParticipantDetails';
import TournamentApis from '../../../helper/tournament.api';
import Image from '../../common/Image';



class Participants extends Component {
    constructor(props) {
        super(props);
        this.unfilterList = []
        this.tournamentApis = new TournamentApis();
        this.state = {
            tournamentDetails: props?.tournamentDetails ? props?.tournamentDetails : {},
            participantList: [],
            openDetailsModal: false,
            detailsModalObj: {}
        };
    }

    componentDidMount() {
        if (!this.props?.tournamentDetails) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
        this.getParticipant()
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            })
            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }

    getParticipant = () => {
        this.props.updateLoader(true);
        this.tournamentApis.getParticipantsOfTournament(this.props.tournamentId).then(
            (res: AxiosResponse) => {
                if (res.data.result?.length) {
                    this.setState({
                        participantList: res.data.result
                    })
                    this.unfilterList = res.data.result
                }

                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    showDetails = (data) => {
        this.setState({
            detailsModalObj: data,
            openDetailsModal: true
        })
    }
    
    render() {
      
      return (
            <section className='tournament-participants text-white'>
                <div className="container">
            
            <div className="row mt-3">
              {
                this.state.participantList?.length ?
                  null
                  :
                  <div className="text-center fw-500 mt-4">
                    Participants yet to join
                  </div>
              }
            </div>

            {
              this.state.tournamentDetails?.participantType === 'TEAM' ?
                <div className="row pt-3">
                  {/* Search by Team */}
                  {
                    this.state.participantList?.map((el, i) => {
                      return (
                        <div className="col-lg-4 col-md-6 col-12 mb-2" key={i}>
                          <div className="green-card mt-2 mt-lg-4 p-2 d-flex align-content-center align-items-center rounded-10">
                            <div className="col-2">
                              {/* <img src={el?.logo?.url} className="rounded ms-3" width={'45px'} height={'45px'} alt="" /> */}
                              <Image src={el?.logo?.url} param={el?.name} className='rank-1-avatar rounded-circle' height='45px' width='45px' />
                            </div>
                            <div className="col-8 ps-4">
                              <h6 className="fs-16 fw-bold mb-0 text-truncate">{el?.name}</h6>
                              <p className="fs-14 fw-600 text-white-50 mb-1"><HiUserGroup className="fs-16 me-2 mb-1" />{el.members?.players?.length}</p>
                              {/* <span className="avatar">
                                  <img
                                    src={participantAvtar}
                                    style={{ width: "30px", height: "30px" }}
                                    alt=""
                                  />
                                  <img
                                    src={participantAvtar}
                                    style={{ width: "30px", height: "30px" }}
                                    alt=""
                                  />
                                </span> */}
                              {/* <span className="avatar d-inline-flex">
                                            <p>+{this.state?.clanData?.members?.owner?.length - 4}</p>
                                        </span> */}
                            </div>
                            <div className="col-2">
                              <span
                                className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32 participant-round-btn"
                                onClick={() => { this.showDetails(el) }}
                              >
                                <img src={formInfoIc} className="rounded-none" style={{ width: "15px" }} alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                </div>
                :
                <div className="row">
                  {/* Search by Participant */}
                  {this.state.participantList?.map((el, i) => (
                    <div className="col-lg-4 col-md-6 col-12 mb-2" key={i}>
                      <div
                        className="green-card mt-2 mt-lg-4 p-2 d-flex align-content-center align-items-center rounded-10">
                        <div className="col-2">
                          {/* <img src={el?.profilePicture?.url} className="rounded-circle ms-3" width={'40px'} height={'40px'} alt="" /> */}
                          <Image src={el?.profilePicture?.url} param={el?.inGameName} className='rounded-circle ms-3' height='40px' width='40px' />
                        </div>
                        <div className="col-8">
                          <h6 className="ms-3 mt-2 text-truncate">{el?.inGameName}</h6>
                        </div>
                        <div className="col-2">
                          <span className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32 participant-round-btn"
                            onClick={() => { this.showDetails(el) }} >
                            <img src={formInfoIc} className="rounded-none" width={'15px'} alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
            }
          </div>
          <Modal show={this.state.openDetailsModal} centered dialogClassName="Participant-details-modal">
          <Modal.Body>
            <ParticipantDetails formConfig={this.state.tournamentDetails?.formConfig} updateLoader={this.props.updateLoader} details={this.state.detailsModalObj} type={this.state.tournamentDetails.participantType} onClose={() => { this.setState({ openDetailsModal: false }) }} />
          </Modal.Body>
        </Modal>
            </section>
        )
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(Participants)
