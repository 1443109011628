import React, { Component } from 'react';
import TournamentHeader from '../../../../tournament/common/TournamentHeader';
import structureIc from '../../../../../images/icon/stageImg.png';
import '../../../../../styles/Tournaments.css'
import MatchesApis from '../../../../../helper/matches.api';
import { errorHandler, getMarginTopForRounds, getMonthAndDate } from '../../../../../utils/common.utils';
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import { HiUserAdd } from "react-icons/hi";
import { MdRefresh, MdZoomOutMap } from "react-icons/md";
import MatchInvite from '../../../../popup/MatchInvite';
import { Modal } from "react-bootstrap";
import SingleBrackets from '../../../../common/SingleBrackets';
import Seeding from "../Seeding";
import SeedingSwitch from "../../common/SeedingSwitch";
import NoRecordFound from '../../../../common/NoRecordFound';

export default class BracketGroupRounds extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            tournamentDetails: props.tournamentDetails,
            groupMatches: props.groupMatches,
            enableSeeding: props.enableSeeding,
            scale: 1,
            openInvite: false,
            selectedMatch: null
        }
        this.marginTopDiv = 35;

        this.matchApis = new MatchesApis();

        
       
    }

    componentWillReceiveProps(newProps) {
        this.setState({
          //  groupMatches: newProps.groupMatches,
            tournamentDetails: newProps.tournamentDetails,
            enableSeeding: newProps.enableSeeding
        });
    }

    zoom = (key) => {
        switch (key) {
          case 'In':
            this.setState({ scale: this.state.scale * 1.1 });
            break;
          case 'Out':
            this.setState({ scale: this.state.scale / 1.1 });
            break;
          default:
            this.setState({ scale: 1 });
            break;
        }
    }

    openInviteModal = (e, matchDetail, matchIndex, matchNo) => {
        e.preventDefault();
        console.log("tournamentDetails :: ",this.state.tournamentDetails)
        this.setState({
            openInvite: true,
            matchId: matchIndex,
            selectedMatch: matchDetail
        });
    }

     
      
    onClickOpenInvite = () => {
        this.setState({
            openInvite: !this.state.openInvite
        })
    }
  
    closeSeeding = (tournamentDetails,updatedStage) =>{
        tournamentDetails.stages[this.props.index] = updatedStage;
        this.props.enableDisableSeeding();
        this.setState({ 
          enableSeeding: false,
          tournamentDetails: tournamentDetails,
          groupMatches: updatedStage.groups[this.props.selectedGroupeIndex]
        });
    }

    enableDisableSeeding = () =>{
        this.setState({ enableSeeding: !this.state.enableSeeding });
    }

    openInviteModal2 = () =>{
        console.log("INSIDE openInviteModal2 :: ",this.state.groupMatches[1][0])
        this.setState({
          openInvite: true,
          matchId: null,
          selectedMatch: this.state.groupMatches[1][0]
        });
    }
    

    render() {
       

        if(this.state.groupMatches && Object.keys(this.state.groupMatches).length !== 0){
            var structure = [];
            Object.keys(this.state.groupMatches).map((key, i) => {
                structure[i]= this.state.groupMatches[key].length;
            });
            var marginTopDiv = 0;
        //    console.log("----PP----- this.state.tournamentDetails :: ",this.state.tournamentDetails);
            //var structure = [4,2,1];
            return (
                <div>
                {/* <div id="test" className="w-100 position-fixed pt-lg-3" style={{zIndex:'1', background:'#200e45'}}>
                    {(parseInt(this.props.index) === 0) ? <SeedingSwitch enableDisableSeeding={this.enableDisableSeeding} enableSeeding={this.state.enableSeeding} /> : null}
                                
                </div> */}
                <div className='d-flex overflow-auto '>
                    
                   
                   { this.state.enableSeeding ?
                        <Seeding marginTopDiv='' selectedGroupeIndex={this.props.selectedGroupeIndex} closeSeeding={this.closeSeeding} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.tournamentDetails?.stages[this.props.index]} />
                    :
                       <SingleBrackets 
                            structure={structure} 
                            includeThirdPlace={this.state.tournamentDetails?.stages[this.props.index]?.includeThirdPlace} 
                            isSwappingEnabled={false} 
                            handleInputChange={this.handleInputChange} 
                            openInviteModal={this.openInviteModal} 
                            stageMatchDetails={this.state.groupMatches} 
                            isTournamentOperator={true}
                            isBracketGroup={true}
                            tournamentDetails={this.state.tournamentDetails}
                            index={this.props.index}
                            selectedGroupeIndex={this.props.selectedGroupeIndex}
                            placeOccupied={this.props.placeOccupied}
                            stageDetails={this.state.tournamentDetails?.stages[this.props.index]}
                            openInviteModal2={this.openInviteModal2}
                            marginTopDiv=''
                        /> 
                   }
                    
                  

                    {/* Tournament Invitation Section */}
                    <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
                        <Modal.Body>
                        <MatchInvite 
                            onClickOpenInvite= {this.onClickOpenInvite} 
                            tournamentId= {this.state.selectedMatch?.tournamentId}
                            stageId= {this.props.index}
                            matchId= {this.state.selectedMatch?._id}
                            gameId= {this.state.selectedMatch?.gameId}
                            groupIndex={this.props.selectedGroupeIndex}
                            participantType={this.state.tournamentDetails?.participantType}
                            choice= "Send Invite" 
                        />
                        </Modal.Body>
                    </Modal>
                </div>
                </div>
                
            );
    
        }else{
            return(
                <NoRecordFound />
            )
        }
    }
}

