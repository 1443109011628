import React, { Component } from 'react';
import * as Yup from "yup";
import { DropdownButton, Dropdown, Button, ButtonGroup, SplitButton } from 'react-bootstrap';
import { getGroupName } from '../../../../utils/Tournament.utils';
import { isExists } from '../../../../utils/common.utils';

export default class TournamentFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: props.state.tournamentDetails,
            stage: props.stageIndex,
            group: props.state.group,
            round: props.state.round,
            selectedGroupIndex: props?.state?.selectedGroupIndex? props.state.selectedGroupIndex :null
        }

    }

    componentWillReceiveProps(newProps){
        this.setState({
            tournamentDetails:newProps.state.tournamentDetails,
            stage: newProps.stageIndex,
            group: newProps.state.group,
            round: newProps.state.round,
            selectedGroupIndex: newProps?.state?.selectedGroupIndex? newProps.state.selectedGroupIndex :this.state.selectedGroupIndex
        })
    }

   

    render() {
       if(this.state){
       
        return (

                <div className='d-flex my-2 filter-btn split-btn mb-3 justify-content-around' style={{ flexWrap: 'wrap', fontSize: "10px !important" }}>
                    <SplitButton as={ButtonGroup} key={1} id={`dropdown-button-drop-${1}`} size="sm" variant="primary" title={`Stage ${this.state.stage + 1}`} className='mx-2 my-1 flex-shrink-0' >
                        {this.state.tournamentDetails?.stages?.map((stage, index) => {
                            return <Dropdown.Item eventKey={index} key={index} onClick={() => this.props.filterMatches(index,0,1)}>Stage {index + 1}</Dropdown.Item>
                        })}
                    </SplitButton>

                    <SplitButton as={ButtonGroup} key={2} id={`dropdown-button-drop-${2}`} size="sm" variant="primary" title={getGroupName(this.state.tournamentDetails?.stages[this.state.stage],this.props.groupIndex)} className='mx-2 my-1 flex-shrink-0' >
                        {this.state.tournamentDetails?.stages[this.state.stage]?.groups?.map((group, index) =>
                            {
                                return <Dropdown.Item eventKey={index} key={index} onClick={() => this.props.filterMatches(this.state.stage, index,1)}>{getGroupName(this.state.tournamentDetails?.stages[this.state.stage],index)}</Dropdown.Item>
                            })
                        }
                    </SplitButton>

                    { this.props.isBracketGroupsDoubleElimination ? 
                 
                        // Brackets Groups Round Filter
                        <SplitButton as={ButtonGroup} key={3} id={`dropdown-button-drop-${3}`} size="sm" variant="primary" title={`Round ${this.state.round}`} className='mx-2 my-1 flex-shrink-0' >
                            {
                                
                                // Object.keys(this.state.tournamentDetails?.stages[this.state.stage]?.groups[this.state.group][this.props.selectedGroupIndex]).map((param, index) => {
                                //     return <Dropdown.Item eventKey={param} key={param} onClick={() => this.props.filterMatches(this.state.stage,this.props.groupIndex, param)}>Round {param}</Dropdown.Item>
                                // })
                                Object.keys(this.state.tournamentDetails?.stages[this.state.stage]?.groups[this.state.group][this.props.selectedGroupIndex ? this.props.selectedGroupIndex : 0]).map((param, index) => {
                                    return <Dropdown.Item eventKey={param} key={param} onClick={() => this.props.filterMatches(this.state.stage,this.props.groupIndex, param)}>Round {param}</Dropdown.Item>
                                })
                            }
                        </SplitButton>
                    :
                        <SplitButton as={ButtonGroup} key={3} id={`dropdown-button-drop-${3}`} size="sm" variant="primary" title={`Round ${this.state.round}`} className='mx-2 my-1 flex-shrink-0 ' >
                            {
                                Object.keys(this.state.tournamentDetails?.stages[this.state.stage]?.groups[this.state.group]).map((key, index) => {
                                    return <Dropdown.Item eventKey={key} key={key} onClick={() => this.props.filterMatches(this.state.stage,this.props.groupIndex, index+1)}>Round {index+1}</Dropdown.Item>
                                })
                            }
                        </SplitButton>
                    }
                </div>


            );
        }else{
            return null;
        }
    }
}