import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../styles/activate-account.css";
import CommonApis from "../../helper/common.api";
import TeamApis from "../../helper/teams.api";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'material-react-toastify';
import { AxiosResponse, AxiosError } from "axios";
import { capitalize, ellipsis, errorHandler } from '../../utils/common.utils'
import { AiFillCamera } from "react-icons/ai";

class CreateTeam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teamLogo: null,
            teamLogoUrl: this.props.clan.logo,
            isDisabled: false,
            teamApi: new TeamApis(),
            commonApi: new CommonApis(),
            games: this.props.games,
            clan: this.props.clan,
            participate: false
        };
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                teamLogo: URL.createObjectURL(img)
            });
            const formData = new FormData();
            formData.append('file', event.target.files[0])
            this.props.updateLoader(true);
            this.state.commonApi.uploadSingleImg(formData)
                .then((response: AxiosResponse) => {
                    this.setState({ teamLogoUrl: response.data.result })
                    this.props.updateLoader(false);
                })
                .catch((err: AxiosError) => { 
                    console.log(err);
                    toast.error(err.response.data.error);
                    this.props.updateLoader(false);
                });
        }
    };

    onSubmit = (values) => {
        const data = {
            clanId: this.state.clan._id,
            gameId: values.game,
            name: values.name,
            isClanLogo : values.isClanLogo,
            logo: this.state.teamLogoUrl,
            members: {},
            isDeleted: false
        }
        this.setState({
            isDisabled: true
        });
        this.props.updateLoader(true);
        this.state.teamApi.addNewTeam(data)
            .then(
                (response: AxiosResponse) => {
                    toast.success('Team Created Successfuly')
                    this.setState({
                        teamLogo: null,
                        isDisabled: false,
                    });
                    if (this.state.participate) {
                        this.setState({ participate: false });
                        this.props.joinTeam(response.data.result._id, values.role, "Team Profile");
                    }
                    this.props.onClickOpenCreateTeam(true)
                    this.props.updateLoader(false);
                }
            ).catch(
                (err: AxiosError) => {
                    this.setState({ participate: false });
                    this.props.updateLoader(false);
                    this.setState({
                        teamLogo: null,
                        isDisabled: false,
                    });
                    errorHandler(err);
                }
            )
    }

    triggerInputFile = () => this.fileInput.click();

    onChangeParticipation = (e) => {
        e.preventDefault();
        if (!e.target.checked) {
            this.setState({
                selectedRole: null
            });
        }
        this.setState({ participate: e.target.checked });
    }

    render() {
        const teamMembers = ['players', 'substitutes', 'coach', 'manager']
        const games = this.props.games;
        return (
            <div className="create-team-page p-4">
                <div className="row">
                    <div className="col-lg-6 col-md-6 pe-md-4">
                        <div className="p-3">
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => { this.props.onClickOpenCreateTeam(false) }} />
                            <h5 className="text-heading fs-18 fw-bold text-white text-center mb-1">Create Team</h5>
                            {/* <p className="CreateClan-description">Talent wins games,  but teamwork
                        and intelligence win championships</p> */}
                        </div>

                        <div className="">
                            <div className="d-flex">
                                <div className="mx-auto">

                                    <input type="file" accept=".png, .jpg, .jpeg" id="upload_file" hidden onChange={this.onImageChange} ref={fileInput => this.fileInput = fileInput} name="" className="image-input" />

                                    <div className="d-flex p-0 mx-auto">
                                        <img src={this.state.teamLogoUrl?.url} alt="" className="m-auto border border-2 rounded-circle " style={{ height: '100px',width:'100px' }} />
                                        <div className='cam-container bg-white position-absolute btn-size-25 rounded-circle d-flex align-items-center justify-content-center' style={{marginLeft:'75px', marginTop:'70px'}}>
                                            <AiFillCamera className="text-dark" id="upload_file" onClick={this.triggerInputFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h1 className="fw-600 fs-16 text-white text-center mt-1" htmlFor="exampleInputText">Team Logo</h1>

                            <Formik
                                enableReinitialize
                                initialValues={{
                                    name: '',
                                    game: '',
                                    role: teamMembers[0],
                                    isClanLogo : false
                                }}
                                validationSchema={Yup.object({
                                    name: Yup.string().required('Team Name is Required').max(50, 'Max 50 characters').matches(/(\d|\w)/, 'Special characters not allowed.'),
                                    game: Yup.string().required('Game is Required'),
                                    isClanLogo: Yup.bool()
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        this.onSubmit(values)
                                    }, 400);
                                }}
                            >
                                <Form className="mt-3" autoComplete="off">
                                    <div className=" d-flex justify-content-center align-items-center">
                                        <Field name="isClanLogo" type="checkbox"/>
                                        <label htmlFor="isClanLogo" className="fs-16 fw-600 text-white my-0 ms-2">Use Clan Logo</label>
                                    </div>
                                    <div className="">
                                        <label htmlFor="name" className="fs-16 fw-600 text-white mb-1">Team Name</label>
                                        <Field
                                            name="name" type="text"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                            placeholder="Enter Team Name" />
                                        <ErrorMessage component="span" className="error-msg" name="name" />
                                        {/* <small id="TextHelp" className="form-text text-warning">Name available!</small> */}
                                    </div>

                                    <div className="mt-3">
                                        <label htmlFor="game" className="fs-16 fw-600 text-white mb-1">Game</label>
                                        <div className="select-game-dropdown dropdown-arrow">
                                            <Field name="game" as="select" className="dropdown-basic fs-14 py-2 px-3 border-0 w-100 text-white height-45" >
                                                <option value=''>Select Game</option>
                                                {
                                                    games.map((el, i) => {
                                                        return (
                                                            <option key={i} value={el._id}>{ellipsis(el.name, 28)}</option>
                                                        )
                                                    })
                                                }
                                            </Field>
                                        </div>
                                        <ErrorMessage component="span" className="error-msg" name="game" />
                                    </div>

                                    {this.state.participate ?
                                        <>
                                            <div className="w-100 mt-2">
                                                <label className="fw-600 fs-14 text-white">
                                                    <input type="checkbox" name="participate" className="me-2"
                                                        onChange={(e) => this.onChangeParticipation(e)} checked={true} />
                                                    Make my self a team member
                                                </label>
                                            </div>
                                            <div className="mt-3">
                                                <label htmlFor="game" className="fs-16 fw-600 text-white">Select Role</label>
                                                <div className="select-game-dropdown dropdown-arrow">
                                                    <Field name="role" as="select" className="dropdown-basic fs-14 py-2 px-3 border-0 w-100 text-white height-45">
                                                        {
                                                            teamMembers.map((type, i) => {
                                                                return (
                                                                    <option key={i} value={type}  >{capitalize(type)}</option>
                                                                )
                                                            })
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="w-100 mt-2 text-start">
                                            <label className="fw-600 fs-14 text-white">
                                                <input type="checkbox" name="participate" className="me-2"
                                                    onChange={(e) => this.onChangeParticipation(e)} checked={false} />
                                                Make my self a team member
                                            </label>
                                        </div>

                                    }

                                    <button type="submit" disabled={this.state.isDisabled} className="white-flat-btn d-block fs-14 fw-600 py-2 px-4 m-auto mt-3 mb-2">Create</button>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-lg-block d-none"></div>
                </div>
            </div>
        )
    }
}

export default CreateTeam