import { Component } from 'react';

// Images
import winnerCrown from "../../../images/icon/winner-crown.png";
import share from '../../../images/page/share.png';

// Icons
import { MdArrowBackIosNew } from 'react-icons/md';
import { getMonthAndDate, scoreCardGameURL } from '../../../utils/common.utils';

//Components
import ValorantCard from './Scorecards/ValorantCard';
import CSGOCard from './Scorecards/CSGOCard';
import FortniteCard from './Scorecards/FortniteCard';
import FreefireCard from './Scorecards/FreefireCard';
import LeagueOfLegendsCard from './Scorecards/LeagueOfLegendsCard';
import PUBGCard from './Scorecards/PUBGCard';
import CardHeader from './Scorecards/CardHeader';
import SponsorCards from './Scorecards/SponsorCards';
import {CompetitionType, GAMES_NAMES} from '../../../utils/enums.utils';

export default class Scoreboard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            matchDetails: props.matchDetails,
            tournamentDetails: props.tournamentDetails,
            matchNo: props.matchNo,
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            matchDetails: nextProps.matchDetails,
            tournamentDetails: nextProps.tournamentDetails,
            matchNo: nextProps.matchNo,
        })
    }

    getScoreCard = () => {
        const {matchDetails, tournamentDetails} = this.state;
        const gameDetails = tournamentDetails?.gameDetails;

        if((tournamentDetails.competitionType === CompetitionType.DUEL) && (matchDetails.participants?.length === 1)){
            return this.getByCard(); 
        }

        switch (gameDetails.name) {
            case GAMES_NAMES.VALORANT:
                return <ValorantCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} />;
            case GAMES_NAMES.CS_GO:
                return <CSGOCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} />;
            case GAMES_NAMES.FORTNITE:
                return <FortniteCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} winnersText={'Victory Royale'} />;
            case GAMES_NAMES.APEX_LEGENDS:
                return <FortniteCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} winnersText={'Champion'} />;
            case GAMES_NAMES.FREEFIRE:
                return <FreefireCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} />;
            case GAMES_NAMES.CALL_OF_DUTY:
                return <FreefireCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} />;
            case GAMES_NAMES.LEAGUE_OF_LEGENDS:
                return <LeagueOfLegendsCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} />;
            case GAMES_NAMES.DOTA_2:
                return <LeagueOfLegendsCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} />;
            case GAMES_NAMES.PUBG:
                return <PUBGCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} isBGMI={false} />;
            case GAMES_NAMES.PUBG_MOBILE:
                return <PUBGCard tournamentDetails={this.state.tournamentDetails} matchDetails={this.state.matchDetails} isBGMI={true} />;
            default:
                break;
        }
    }
    
    getByCard = () => { 
        const { matchDetails, tournamentDetails} = this.state;
        return(
            <div className='col-12 col-lg-6 offset-lg-3'>
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />

                    <div className='forfeit-bg my-1'>
                        <span className='fs-10 fw-800 my-1'>Unusual Circumstances</span>
                        <div className='d-flex justify-content-center'>
                            <div className='d-flex flex-column align-items-center mx-2'>
                                <img src={winnerCrown} className="mb-1" alt='' style={{ height: '15px',width: '15px', zIndex: '1' }} />
                                <img src={tournamentDetails.participantType === 'TEAM' ? matchDetails.participants[0]?.logo?.url : matchDetails.participants[0]?.profilePicture?.url} className="rounded-circle" alt='' style={{ height: '50px', width:'50px',zIndex: '1' }} />
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <span className='text-success fs-10 fw-800 my-1'>Name - {tournamentDetails.participantType === 'TEAM' ? matchDetails.participants[0]?.name : matchDetails?.participants[0]?.inGameName}</span>
                                <span className='text-warning fs-10 fw-800 my-1'>{`Winner`}</span>
                            </div>
                        </div>
                        
                        <span className='my-1 text-center'>When there is an odd number of total particpants, the member with no oppponent will be qualified to the next round/stage</span>
                        <p className='fs-10 fw-bold text-white-50 text-center my-1'>For more info, visit the rule section of this tournament</p>
                    </div>
                    
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            </div>
        )
    }

    render() {
        const time = getMonthAndDate(this.state.matchDetails?.matchSchedule);
        return (
            <section className='tournament-scoreboard-page text-white'>
                <div className='container'>
                    <div className="row">
                        <div className='ps-2 d-flex justify-content-between'>
                            <div className='d-flex '>
                                <MdArrowBackIosNew onClick={() => this.props.hideAndShowScoreBoard(this.state.matchDetails)} className='' />
                                <span className='fs-16 fw-700 ms-1'>Wanna Smack It?({this.state.matchNo})</span>
                            </div>
                            <div>
                                <span className='fs-12 fw-bold text-white-50'>{`${time[0]} @ ${time[1]}`}</span>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='row'>
                                {this.getScoreCard()}
                                <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center' type='submit'>
                                    <img src={share} alt='share' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
