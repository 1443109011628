import { Component } from 'react';

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink } from 'react-router-dom';
import Switch from "react-switch"
import { Accordion } from 'react-bootstrap';

import TournamentHeader from '../../../../common/TournamentHeader';
import participantAvtar from "../../../../../../../images/participantAvtar.jpg";
import participants from '../../../../../../../images/icon/participants.png';
import disputeIc from '../../../../../../../images/icon/dispute-ic.png';
import chatIc from '../../../../../../../images/icon/chat-ic.png';
import streamColorIc from '../../../../../../../images/icon/streamColor-ic.png';
import refreshIc from '../../../../../../../images/icon/refresh-ic.png';
import scoreIc from '../../../../../../../images/icon/score-ic.png';
import player from '../../../../../../../images/player.png'
import score from '../../../../../../../images/icon/score.png';
import assists from '../../../../../../../images/icon/assists.png';
import kills from '../../../../../../../images/icon/kills.png';
import scores from '../../../../../../../images/icon/scores.png';
import deaths from '../../../../../../../images/icon/deaths.png';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../../../../../utils/Tournament.utils';
import MatchesApis from '../../../../../../../helper/matches.api';
import { dateFormat, errorHandler } from '../../../../../../../utils/common.utils';
import { toast } from 'material-react-toastify';


class MatchesFFAScoreUpdate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails,
            disableChat: false,
            matchDetails: null,
            gameDetails: [],
            gameNo: parseInt(props.match.params.gameNo)
        }
        this.matchesApis = new MatchesApis();
        this.getMatchDetails(props.match.params.matchId);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ tournamentDetails: newProps.tournamentDetails });
    }

    async getMatchDetails(matchId) {
        var data = {
            ids: [matchId]
        }
        this.props.updateLoader(true);
        await this.matchesApis.getMultipleMatches(data).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                this.setState({
                    matchDetails: response[0],
                    gameDetails: Object.values(response[0].results[this.state.gameNo])
                })
                this.props.dispatchData(response[0])
                // this.updateMatchDetails(response[0], response[0]['this.state.matchDetails?.participants'], joinTeamId, response[0]['maxParticipantsPerMatch']);
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    updateScore = (data?, key?) => {
        let result = { results: data };
        this.matchesApis.updateMatchResults(this.props.matchId, result).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                this.props.updateLoader(false);
                if (key){
                    this.props.history.goBack();
                    toast.success('Match result updated successfully');
                }
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    getURL = (el) => {
        // console.log('Participant Details ------', el);
        // console.log('participantType ________', this.state.tournamentDetails?.participantType);
        if (this.state.tournamentDetails?.participantType === 'SINGLE') {
            return el.participantDetails.profilePicture.url
        } else {
            return el.logo.url
        }
    }

    updatePlayerScore = (val, i) => {
        val.score = (val.kills * 5) + (val.assists * 1);
        const results = this.state.matchDetails?.results;
        let targetBody = results[parseInt(this.state.gameNo)][i];
        let target = { ...targetBody, ...val, resultAdded: true };
        const { matchDetails, gameDetails } = this.state;
        matchDetails.results[parseInt(this.state.gameNo)][i] = target;
        gameDetails[i] = target;
        this.setState({ matchDetails, gameDetails });
        this.updateScore(this.state.matchDetails?.results, false)
    }

    submitPlayerScore = (val, i, j) => {
        val.score = (val.kills * 5) + (val.assists * 1);
        //console.log('Game Number ::', parseInt(this.state.gameNo) + 1)
        const results = this.state.matchDetails?.results
        // console.log('result object::', results)
        // console.log(`i=${i} -- j=${j}`)
        // console.log('scores object::', val)
        let targetBody = results[parseInt(this.state.gameNo)][i].individualScore[j];
        //console.log('target ::::', { ...targetBody, ...val });
        let target = { ...targetBody, ...val };
        let { matchDetails, gameDetails } = this.state;
        matchDetails.results[parseInt(this.state.gameNo)][i].individualScore[j] = target;
        matchDetails.results[parseInt(this.state.gameNo)][i].resultAdded = true;
        gameDetails = Object.values(matchDetails.results[parseInt(this.state.gameNo)]);
        this.setState({ matchDetails, gameDetails });
        this.updateScore(this.state.matchDetails?.results, false)
    }

    render() {
        return (
            <div className="body-section match-ffa-score-update">
                <div className="container p-3 mb-5 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={scoreIc} heading="Score Match 1" subHeading={null} history={this.props.history} />

                    <div className='d-flex justify-content-between mt-3 mb-2'>
                        <h1 className="fs-16 fw-bold">Show down</h1>
                        <h1 className="stage-sub-heading">{dateFormat(this.state.matchDetails?.matchSchedule)}</h1>
                    </div>

                    <div className="row pt-3">
                        {(this.state.tournamentDetails && this.state.matchDetails) ?
                            this.state.tournamentDetails.participantType === 'SINGLE' ?
                                this.state.gameDetails?.map((el, i) => {
                                    if (el.participantDetails) {
                                        return (
                                            <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                                <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                                    <div className="d-flex justify-content-center">
                                                        <img src={this.getURL(el)} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                                        <h4 className="mt-3 fs-20">{el.participantDetails.inGameName}</h4>
                                                    </div>

                                                    <Formik
                                                        enableReinitialize
                                                        initialValues={{
                                                            //score: el.score,
                                                            kills: el.kills,
                                                            deaths: el.deaths,
                                                            assists: el.assists,
                                                            isMVP: el.isMVP,
                                                            declareWinner: false
                                                        }}
                                                        validationSchema={Yup.object({
                                                            //score: Yup.number().required('Enter Score').min(0, 'cannot be negative'),
                                                            kills: Yup.number().required('Enter Kills').min(0, 'cannot be negative'),
                                                            deaths: Yup.number().required('Enter Death').min(0, 'cannot be negative'),
                                                            assists: Yup.number().required('Enter Assists').min(0, 'cannot be negative'),
                                                            isMVP: Yup.bool(),
                                                            declareWinner: Yup.bool()
                                                        })}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                            setTimeout(() => {
                                                                this.updatePlayerScore(values, i)
                                                                setSubmitting(false);
                                                            }, 400);
                                                        }}
                                                    >
                                                        <Form className="mt-3" autoComplete="off">
                                                            {/* <div className="form-group my-1">
                                                            <label className='fs-14 fw-600 text-white mb-1'>Score</label>
                                                            <div className="">
                                                                <Field
                                                                    name="score" type="number"
                                                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                    placeholder="Enter score"
                                                                />
                                                            </div>
                                                            <ErrorMessage component="span" className="error-msg" name="score" />
                                                        </div> */}
                                                            <div className="my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                                <div className="">
                                                                    <Field
                                                                        name="kills" type="number"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        placeholder="Enter kills"
                                                                    />
                                                                </div>
                                                                <ErrorMessage component="span" className="error-msg" name="kills" />
                                                            </div>
                                                            <div className="my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                                <div className="">
                                                                    <Field
                                                                        name="deaths" type="number"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        placeholder="Enter deaths"
                                                                    />
                                                                </div>
                                                                <ErrorMessage component="span" className="error-msg" name="deaths" />
                                                            </div>
                                                            <div className="my-1">
                                                                <label className='fs-14 fw-600 text-white mb-1'>Assists</label>
                                                                <div className="">
                                                                    <Field
                                                                        name="assists" type="number"
                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        placeholder="Enter assists"
                                                                    />
                                                                </div>
                                                                <ErrorMessage component="span" className="error-msg" name="assists" />
                                                            </div>
                                                            <div className='my-1'>
                                                                <Field name='isMVP' type='checkbox' className='me-2' />
                                                                <label className='fs-14 fw-600 text-white mb-1'>Is MVP</label>
                                                            </div>
                                                            {/* <div className='my-1'>
                                                                <Field name='declareWinner' type='checkbox' className='me-2' />
                                                                <label className='fs-14 fw-600 text-white mb-1'>Declare {el.participantDetails.name} as Winner</label>
                                                            </div> */}
                                                            <div className='d-flex justify-content-center my-2'>
                                                                <button className='contact-toggle d-flex align-items-center rounded-10 height-45 my-3 mx-auto text-white px-3' type='submit'>{el.resultAdded ? 'Update Score' : 'Submit Score'}</button>
                                                            </div>
                                                        </Form>
                                                    </Formik>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                :
                                <Accordion>
                                    {this.state.matchDetails?.participants?.map((team, i) => {
                                        console.log(`team ${i} details ::`, team);
                                        if (team?.members?.players?.length === 0) {
                                            return (
                                                <Accordion.Item key={i} eventKey={i} className='mulberry-purple-card shadow-none rounded-10 my-3 border-0'>
                                                    <Accordion.Header>{team?.name}</Accordion.Header>
                                                    <Accordion.Body style={{ background: '#170d2a' }}>
                                                        No players in this team
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        } else {
                                            return (
                                                <Accordion.Item key={i} eventKey={i} className='mulberry-purple-card shadow-none rounded-10 my-3 border-0'>
                                                    <Accordion.Header>{team?.name}</Accordion.Header>
                                                    <Accordion.Body style={{ background: '#170d2a' }}>
                                                        {/* <div className='my-1'>
                                                            <input type='checkbox' className='me-2' />
                                                            <label className='fs-14 fw-600 text-white mb-1'>Declare {team?.name} as Winner</label>
                                                        </div> */}
                                                        {this.state.matchDetails?.results[this.state.gameNo][i]?.individualScore?.map((player, j) => {
                                                            console.log(`player ${i, j} ::`, player)
                                                            return (
                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={{
                                                                        score: player?.score,
                                                                        kills: player?.kills,
                                                                        deaths: player?.deaths,
                                                                        assists: player?.assists,
                                                                        isMVP: player?.isMVP
                                                                    }}
                                                                    validationSchema={Yup.object({
                                                                        score: Yup.number().required('Enter Score').min(0, 'cannot be negative'),
                                                                        kills: Yup.number().required('Enter Kills').min(0, 'cannot be negative'),
                                                                        deaths: Yup.number().required('Enter Death').min(0, 'cannot be negative'),
                                                                        assists: Yup.number().required('Enter Assists').min(0, 'cannot be negative'),
                                                                        isMVP: Yup.bool()
                                                                    })}
                                                                    onSubmit={(values, { setSubmitting }) => {
                                                                        setTimeout(() => {
                                                                            this.submitPlayerScore(values, i, j)
                                                                            setSubmitting(false);
                                                                        }, 400);
                                                                    }}
                                                                >
                                                                    <Form className="mt-3" autoComplete="off" key={j}>
                                                                        <div className='mulberry-purple-card shadow-none rounded-10 p-3 border border-0 borer-white'>
                                                                            <h6 className='fs-16 fw-bold text-center my-3' >{player?.participantDetails?.inGameName}</h6>
                                                                            {/* P1 Score */}
                                                                            {/* <div className="form-group my-1">
                                                                    <label className='fs-14 fw-600 text-white mb-1'>Score</label>
                                                                    <div className="">
                                                                        <Field
                                                                            name="score" type="number" placeholder="Enter Score"
                                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                        />
                                                                        <img src={scores} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                    </div>
                                                                    <ErrorMessage component="span" className="error-msg" name="score" />
                                                                </div> */}
                                                                            {/* P1 Kills */}
                                                                            <div className="form-group my-1">
                                                                                <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                                                <div className="">
                                                                                    <Field
                                                                                        name="kills" type="number" placeholder="Enter Kills"
                                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                                    />
                                                                                    <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                                </div>
                                                                                <ErrorMessage component="span" className="error-msg" name="kills" />
                                                                            </div>
                                                                            {/* P1 Deaths */}
                                                                            <div className="form-group my-1">
                                                                                <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                                                <div className="">
                                                                                    <Field
                                                                                        name="deaths" type="number" placeholder="Enter Deaths"
                                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                                    />
                                                                                    <img src={deaths} alt="deaths" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                                </div>
                                                                                <ErrorMessage component="span" className="error-msg" name="deaths" />
                                                                            </div>
                                                                            {/* P1 Assists */}
                                                                            <div className="form-group my-1">
                                                                                <label className='fs-14 fw-600 text-white mb-1'>Assists</label>
                                                                                <div className="">
                                                                                    <Field
                                                                                        name="assists" type="number" placeholder="Enter Assists"
                                                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                                                    />
                                                                                    <img src={assists} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                                                </div>
                                                                                <ErrorMessage component="span" className="error-msg" name="assists" />
                                                                            </div>
                                                                            <div className='my-1'>
                                                                                <Field name='isMVP' type='checkbox' className='me-2' />
                                                                                <label className='fs-14 fw-600 text-white mb-1'>Is MVP</label>
                                                                            </div>
                                                                            <button className='contact-toggle d-flex align-items-center rounded-10 height-45 my-3 mx-auto text-white px-3' type='submit'>{this.state.matchDetails?.results[0][i]?.resultAdded ? 'Update Score' : 'Submit Score'}</button>
                                                                        </div>
                                                                    </Form>
                                                                </Formik>
                                                            )
                                                        })}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }

                                    })}
                                </Accordion>
                            : null
                        }

                    </div>

                    {/* Chat Options */}

                    <div className='row'>
                        <div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12'>
                            <div className="contact-toggle d-flex align-items-center rounded-10 height-45 my-3">
                                <p className="fs-16 fw-600 mb-0 flex-grow-1 ps-3">Disable Chat</p>
                                <Switch checked={this.state.disableChat}
                                    checkedIcon={false} uncheckedIcon={false} className="me-2"
                                    onChange={(e) => this.setState({ disableChat: !this.state.disableChat })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <div className='position-fixed d-flex justify-content-center'>

                            <div className='text-center'>
                                <button type='submit' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={()=>this.updateScore(this.state.matchDetails?.results, true)}>
                                    <img src={refreshIc} alt="" height={'25px'} />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Update</p>
                            </div>

                            <div className='text-center'>
                                <NavLink to={
                                    {
                                        pathname: `/organization/${this.props.orgId}/tournament/${this.props.tournamentId}/overview/chatLobby`,
                                        state: {
                                            orgId: this.props.orgId,
                                            tournamentDetails: this.props.tournamentDetails
                                        }
                                    }
                                }>
                                    <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' disabled={this.state.disableChat} style={{ opacity: this.state.disableChat ? 0.7 : 1 }}>
                                        <img src={chatIc} alt="" height={'25px'} />
                                    </button>
                                    <p className="fs-12 fw-bold text-white mt-1">Chat</p>
                                </NavLink>
                            </div>

                            <div className='text-center'>
                                <NavLink to={
                                    {
                                        pathname: `/organization/${this.props.orgId}/tournament/${this.props.tournamentId}/overview/dispute-management`,
                                        state: {
                                            orgId: this.props.orgId,
                                            tournamentDetails: this.props.tournamentDetails
                                        }
                                    }
                                }>
                                    <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3'>
                                        <img src={disputeIc} alt="" className='btn-size-32' />
                                    </button>
                                    <p className="fs-12 fw-bold text-white mt-1">Disputes</p>
                                </NavLink>
                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3'>
                                    <img src={streamColorIc} alt="" className='btn-size-20' />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Stream</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(MatchesFFAScoreUpdate)