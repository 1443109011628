import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class NotificationsApi {
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    createNotification = (data) => {
        return this.init().post(`/private/api/v1/notifications/createnotification`, data); 
    };

    sendNotificationToMultipleUsers = (data) => {
        return this.init().post(`/private/api/v1/notifications/sendNotificationToMultipleUsers`, data); 
    };

    getMyNotification = () => {
        return this.init().get(`/private/api/v1/notifications`);
    };

    addOrUpdateNotificationLog = (data) => {
        return this.init().post(`/private/api/v1/notifications/addOrUpdateNotificationLog`, data); 
    };

    deleteNotificationLog = (notificationId) => {
        var queryParams = "";
        if(notificationId){
            queryParams = `?notificationId=${notificationId}`;
        }
        return this.init().delete(`/private/api/v1/notifications/deleteNotificationLog${queryParams}`); 
    };

    updateNotificationLogStatus = (data) => {
        return this.init().post(`/private/api/v1/notifications/updateNotificationLogStatus`, data); 
    };

    createAndSendNotification = (data) => {
        return this.init().post(`/private/api/v1/notifications/createAndSendNotification`, data); 
    };


}       