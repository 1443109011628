import { Component } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import StarRatingComponent from 'react-star-rating-component';
import { ProgressBar } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import participantAvtar from "../../../images/participantAvtar.jpg";


import pastIc from '../../../images/icon/past-ic.png';
import { AiFillCloseCircle } from "react-icons/ai";

// Images
import playerIc from "../../../images/icon/player-ic.png";
import groupIc from "../../../images/icon/group-ic.png";
import greenRatingIc from "../../../images/icon/greenRating-ic.png";
import TournamentApis from '../../../helper/tournament.api';
import { errorHandler } from '../../../utils/common.utils';
import { profile } from '../../../utils/localstorage.utils';



export default class Rating extends Component {

  constructor(props) {
    super(props)
    this.state = {
      rating: 100,
      ratings: 0,
      participantsReviews: [],
      viewersReviews: [],
      reply: '',
      commentId: null,
      commentIndex: null,
      commentOnParticipant: false,
      selectedSort: 'Newest'
    }
    this.tournamentApis = new TournamentApis();
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
    let data = {
      tournamentId: this.props.match.params.id,
      isParticipantRating: true,
      sortBy: 'createdAt',
      orderBy: 'desc'
    }
    this.getReviews(data)
  }
  getReviews = (params) => {
    this.tournamentApis.getTournamentRatings(params).then(
      (res: AxiosResponse) => {
        this.props.updateLoader(false);
        if (params.isParticipantRating) {
          this.setState({ participantsReviews: res.data['result'] })
        } else {
          this.setState({ viewersReviews: res.data['result'] })
        }
        this.setState({ openRateReview: false })
      }
    ).catch(
      (err: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }
  getAllReview = () => {
    this.setState({ rating: 101 })
    let data = {
      tournamentId: this.props.match.params.id,
      isParticipantRating: false,
      sortBy: 'createdAt',
      orderBy: 'desc'
    }
    if (!this.state.viewersReviews.length) {
      this.getReviews(data)
    }
  }
  onReply = () => {
    let participantsReviews = this.state.participantsReviews
    let viewersReviews = this.state.viewersReviews
    let data = {
      reply: this.state.reply,
      userId: profile()?.id,
      name: profile()?.name
    }

    let replies = {
      replies: [data]
    }
    this.tournamentApis.updateTournamentRating(this.state.commentId, replies).then(
      (res: AxiosResponse) => {
        this.props.updateLoader(false);
        if (this.state.commentOnParticipant === true) {
          participantsReviews[this.state.commentIndex].replies.push(data)
        } else {
          viewersReviews[this.state.commentIndex].replies.push(data)
        }
        this.setState({ participantsReviews: participantsReviews, viewersReviews: viewersReviews, commentId: null, commentIndex: null, reply: '' })
      }
    ).catch(
      (err: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }
  onRoleChangeHandler = (e) => {
    this.setState({
      selectedSort: e.target.name,
    });
    if (e.target.name === 'Newest') {
      const data = {
        tournamentId: this.props.match.params.id,
        isParticipantRating: this.state.rating === 100 ? true : false,
        sortBy: 'createdAt',
        orderBy: 'desc'
      }
      this.getReviews(data)
    } else {
      const data = {
        tournamentId: this.props.match.params.id,
        isParticipantRating: this.state.rating === 100 ? true : false,
        sortBy: 'createdAt',
        orderBy: 'asc'
      }
      this.getReviews(data)
    }
  };
  render() {
    const { ratings } = this.state;
    return (
      <section className="body-section rating-page">
        <div className="container px-4 pt-3">
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h1 className="fs-20 fw-600 text-white text-center"><img src={pastIc} className='btn-size-20' /> Rating Review</h1>
            <Dropdown className="mb-2 select-game-dropdown">
              <Dropdown.Toggle className='dropdown-basic fs-12 fw-600 px-3 py-2 game-fs team-dropdown-arrow' style={{ width: '100px' }}>{this.state.selectedSort}</Dropdown.Toggle>
              <Dropdown.Menu onClick={(e) => this.onRoleChangeHandler(e)}>
                <Dropdown.Item href="#" name='Newest' className='text-white'>Newest</Dropdown.Item>
                <Dropdown.Item href="#" name='Oldest' className='text-white'>Oldest</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>


          <div className="row">
            <div className='col-12'>
              <button className={`${this.state.rating === 100 ? 'white-round-btn' : 'black-round-btn'} rounded-circle fw-600 border border-2 border-dark me-3 btn-size-40`} onClick={() => { this.setState({ rating: 100 }) }}>
                <img src={playerIc} className="react-btn " alt='' width={'20px'} />
              </button>

              <button className={`${this.state.rating === 101 ? 'white-round-btn' : 'black-round-btn'} rounded-circle fw-600 border border-2 border-dark btn-size-40`} onClick={() => { this.getAllReview() }}>
                <img src={groupIc} className="react-btn " alt='' width={'20px'} />
              </button>
            </div>
          </div>
          {(this.state.rating === 100) &&
            <div className="row">
              {
                this.state.participantsReviews.map((el, i) => {
                  return (
                    <div className='col-12 col-md-6 col-lg-4' key={i}>
                      <div className='mulled-wine mt-2 p-4 mt-5'>
                        <div className="d-flex justify-content-center">
                          <img src={el?.reviewerProfilePicture?.url} className="rounded-circle position-absolute btn-size-40" style={{ marginTop: "-45px" }} alt="" />
                          <h4 className="fs-14 fw-500">{el?.reviewerName}</h4>
                        </div>

                        <div className='row'>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white fw-bold fs-16 mb-0'>Overall Rating</h6>
                              </div>
                              <div className='col-5 mt-1 d-flex justify-content-between' style={{ fontSize: '20px' }}>
                                <StarRatingComponent
                                  name="rate1"
                                  starCount={5}
                                  value={el?.overallRating}
                                  emptyStarColor="#b4b4b4"
                                  editing={false}
                                />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.overallRating}</h6>
                              </div>
                            </div>
                          </div>

                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Prize Pool Distribution</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" now={el?.prizePoolDistribution * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.prizePoolDistribution}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Stream Quality</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.streamQuality * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.streamQuality}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Casting Quality</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.castingQuality * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.castingQuality}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>VFX Quality</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.vfxQuality * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.vfxQuality}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Conduction of Tournament</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.conductionOfTournament * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.conductionOfTournament}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Punctuality</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.punctuality * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.punctuality}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Result Declaration</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.resultDeclaration * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.resultDeclaration}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Dispute resolution</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.disputeResolution * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.disputeResolution}</h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row mt-3'>
                          <div className='col-12 px-0'>
                            <h6 className='text-white fw-bold fs-16'>Review</h6>
                            <p className='text-white-50 fs-12 fw-500'>{el?.review}</p>
                            {
                              !el?.review && <p>No Review</p>
                            }
                            {
                              (el.replies?.length < 1) ?
                                <div className='row'>
                                  <div className='col-12'>
                                    <button className="flat-btn border-0 px-4 fw-600" onClick={() => { this.setState({ commentId: el._id, commentIndex: i, commentOnParticipant: true }) }}>Reply</button>
                                  </div>
                                </div> :
                                <div>
                                  <h6 className='text-white fw-bold fs-16'>Reply</h6>
                                  {
                                    el.replies.map((reply, j) => {
                                      return (
                                        <div key={j}>
                                          {/* <p className='text-white-50 fs-12 fw-500'>{reply?.name}</p> */}
                                          <p className='text-white-50 fs-12 fw-500'>{reply?.reply}</p>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {
                !this.state.participantsReviews?.length &&
                <p className='fs-14 fw-600 d-flex justify-content-center mt-5'>No Reviews by any participant</p>
              }
            </div>
          }

          {(this.state.rating === 101) &&
            <div className="row">
              {
                this.state.viewersReviews.map((el, i) => {
                  return (
                    <div className='col-12 col-md-6 col-lg-4' key={i}>
                      <div className='mulled-wine mt-2 p-4 mt-5'>
                        <div className="d-flex justify-content-center">
                          <img src={el?.reviewerProfilePicture?.url} className="rounded-circle position-absolute btn-size-40" style={{ marginTop: "-45px" }} alt="" />
                          <h4 className="fs-14 fw-500">{el?.reviewerName}</h4>
                        </div>

                        <div className='row'>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white fw-bold fs-16 mb-0'>Overall Rating</h6>
                              </div>
                              <div className='col-5 mt-1 d-flex justify-content-between' style={{ fontSize: '20px' }}>
                                <StarRatingComponent
                                  name="rate1"
                                  starCount={5}
                                  value={el?.overallRating}
                                  emptyStarColor="#b4b4b4"
                                  editing={false}
                                />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.overallRating}</h6>
                              </div>
                            </div>
                          </div>

                          {/* <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Betting</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={2000 * 20 / 1000} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>1.7</h6>
                              </div>
                            </div>
                          </div> */}
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Stream Quality</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.streamQuality * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.streamQuality}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>Casting Quality</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.castingQuality * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.castingQuality}</h6>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 px-0'>
                            <div className='row'>
                              <div className='col-7 mt-2'>
                                <h6 className='text-white-50 text-start fw-bold mb-0 fs-12'>VFX Quality</h6>
                              </div>
                              <div className='col-5 mt-2 d-flex justify-content-between'>
                                <ProgressBar variant="warning" animated now={el?.vfxQuality * 20} className='w-75 mx-0' />
                                <h6 className='text-white-50 fw-bold mb-0 fs-12 align-self-center'>{el?.vfxQuality}</h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row mt-3'>
                          <div className='col-12 px-0'>
                            <h6 className='text-white fw-bold fs-16'>Review</h6>
                            <p className='text-white-50 fs-12 fw-500'>{el?.review}</p>
                            {
                              !el?.review && <p>No Review</p>
                            }

                            {
                              (el.replies?.length < 1) ?
                                <div className='row'>
                                  <div className='col-12'>
                                    <button className="flat-btn border-0 px-4 fw-600" onClick={() => { this.setState({ commentId: el._id, commentIndex: i, commentOnParticipant: false }) }}>Reply</button>
                                  </div>
                                </div> :
                                <div>
                                  <h6 className='text-white fw-bold fs-16'>Reply</h6>
                                  {
                                    el.replies.map((reply, j) => {
                                      return (
                                        <div key={j}>
                                          {/* <p className='text-white-50 fs-12 fw-500'>{reply?.name}</p> */}
                                          <p className='text-white-50 fs-12 fw-500'>{reply?.reply}</p>
                                        </div>
                                      )
                                    })
                                  }
                                </div>

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {
                !this.state.viewersReviews?.length &&
                <p className='fs-14 fw-600 d-flex justify-content-center mt-5'>No Reviews by any Viewer</p>
              }
            </div>
          }
        </div>
        <Modal centered show={this.state.commentId} dialogClassName="rate-review-modal grey-modal-bg">
          <div className='div'>
            <AiFillCloseCircle className="btn-size-20 text-white float-end me-3 mt-1" onClick={() => { this.setState({ commentId: null, reply: '', commentIndex: null }) }} />
            <div className='col-12 px-0'>
              <h6 className='text-white fw-bold fs-16'>Reply</h6>
            </div>
            <div className='col-12 px-0'>
              <div className="mb-3">
                <textarea className="form-control border-0 text-white" id="exampleFormControlTextarea1" rows="2" style={{ backgroundColor: '#232323' }} value={this.state.reply} onChange={(e) => { this.setState({ reply: e.target.value }) }}></textarea>
              </div>
            </div>
            <div className='col-12 px-0'>
              <button className="flat-btn border-0 px-4 fw-600" onClick={this.onReply}>Send</button>
            </div>
          </div>
        </Modal>
      </section>
    )
  }
}
