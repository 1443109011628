import React, { Component } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import {
    FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton,
    FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon
} from "react-share";
import loader from '../../images/common/Loader.png';
import { toast } from "material-react-toastify";
import { MdOutlineContentCopy } from 'react-icons/md';
import { dateFormat } from '../../utils/common.utils';

export default class TournamentShare extends Component {

    constructor(props) {
        super(props)
        this.state = {
            invitationLink: null,
            tournamentDetails: props.tournamentDetails ? props.tournamentDetails : null
        }
    }

    componentDidMount() {
        var inviteLink;

        const baseUrl = window.location.hostname === 'localhost' ? 'https://localhost:3000' : window.location.origin;

        if(this.props.isProfileShare){
            inviteLink = `${baseUrl}/den/profile-page/${this.props.denProfileId}`
        }else{
            inviteLink = `${baseUrl}/tournament/${this.props.tournamentDetails?._id}`
        }
        this.setState({ invitationLink:inviteLink })
    }

    componentWillReceiveProps(newProps){
        this.setState({
            tournamentDetails : newProps.tournamentDetails ? newProps.tournamentDetails : null
        });
    }

    copyLinkToClipBoard = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(this.state.invitationLink);
        toast.success("Tournament link has been copied to the clipboard.");
    }

    shareTournament = () => {
        const tournamentDetails = this.state.tournamentDetails;
        var inviteText;
        if(this.props.isProfileShare){
            inviteText = `Hey, do checkout this interesting profile that I have found in Espotz Den Platform for Gamers like you and me!`;
        }else{
            inviteText = `Visit Espotz Tournament with name-${tournamentDetails?.name}, participation type -${tournamentDetails?.participantType}, competition type-${tournamentDetails?.competitionType}`;
            if(tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount){
                inviteText = inviteText + ` with entry fee ${tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount}EC having prize pool amount ${tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount}EC. Registration starts at ${dateFormat(tournamentDetails?.registrationStartTime)}`
            }else{
                inviteText = inviteText + `.Registration starts at ${dateFormat(tournamentDetails?.registrationStartTime)}`
            }
        }
        return (
            <div className='p-3'>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={this.props.toggleShare} />

                <p className="fs-20 fw-600 text-center text-white mb-0">Share</p>
                <div id="link-section" className='mt-2'>
                    <div className="d-flex align-items-center">
                        <input
                            type="text"
                            value={this.state.invitationLink ? this.state.invitationLink : ""}
                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                            disabled
                            style={{ paddingRight: '35px' }}
                        />
                        <MdOutlineContentCopy className="text-white me-3 position-absolute" style={{ right: '10px' }} onClick={(e) => this.copyLinkToClipBoard(e)} />
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <div className="d-flex align-items-center w-50 mb-0">
                            <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                            <p className="fs-14 fw-normal text-white-50 mx-2 my-0">OR</p>
                            <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                        </div>
                    </div>
                    <div className="text-center">
                        <WhatsappShareButton className="px-1" quote={inviteText} url={this.state.invitationLink} title={inviteText}>
                            <WhatsappIcon size={"2rem"} round={true} />
                        </WhatsappShareButton>

                        <FacebookShareButton className="px-1" title={inviteText} url={this.state.invitationLink}>
                            <FacebookIcon size={"2rem"} round={true} />
                        </FacebookShareButton>

                        <TelegramShareButton className="px-1" title={inviteText} url={this.state.invitationLink}>
                            <TelegramIcon size={"2rem"} round={true} />
                        </TelegramShareButton>

                        <TwitterShareButton className="px-1" title={inviteText} url={this.state.invitationLink}>
                            <TwitterIcon size={"2rem"} round={true} />
                        </TwitterShareButton>
                        <img src={loader} className="px-1" alt='' style={{ height: '28px' }} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.shareTournament()}
            </div>
        );
    }
}


