import React, { Component } from 'react';
//images
import player from '../../../../../../../images/player.png'
import score from '../../../../../../../images/icon/score.png';
import assists from '../../../../../../../images/icon/assists.png';
import kills from '../../../../../../../images/icon/kills.png';
import scores from '../../../../../../../images/icon/scores.png';
import deaths from '../../../../../../../images/icon/deaths.png';
import disputes from '../../../../../../../images/icon/dispute-ic.png'
import refresh from '../../../../../../../images/games/refresh.png'
import streams from '../../../../../../../images/icon/streaming.png';
import comments from '../../../../../../../images/icon/comments.png';
import upload from '../../../../../../../images/score/upload.png';
//components
import { dateFormat, ellipsis, errorHandler, getTournamentAdditionalData } from '../../../../../../../utils/common.utils';
import Switch from "react-switch"
import TournamentHeader from '../../../../common/TournamentHeader';
import MatchesApis from '../../../../../../../helper/matches.api';
import TeamApis from '../../../../../../../helper/teams.api';
import { connect } from 'react-redux';
import { setUpdateScore } from '../../../../../../../redux/actions/tournametActions';
import { Button, Modal } from 'react-bootstrap';
import ParticipantDetails from "../../../../../../popup/ParticipantDetails";
import { toast } from 'material-react-toastify';
import { IoIosPeople } from 'react-icons/io';
import FFAParticipantDetails from '../../../../../../popup/FFAParticipantsDetails';

const mapStateToProps = (props) => {
    //console.log('redux function::', props)
    return {
        score: props.updateScore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data) => dispatch(setUpdateScore(data))
    }
}

class PUBG extends Component {
    constructor(props) {
        super(props)
        this.matchesApis = new MatchesApis();
        this.teamApis = new TeamApis();
        this.state = {
            tournamentDetails: props.tournamentDetails,
            disableChat: false,
            matchDetails: props.score?.results ? props.score : null,
            openDetailsModal: false,
            detailsModalObj: {},
            teamTab: '1',
            allTeamsPlayers: [],
            teamMVP:null,
            matchMVP:null,
            ranksArray: [],
        }
        // if (!this.props?.score?.results) {
        //     this.getMatchDetails(this.props.matchId);
        // }
        getTournamentAdditionalData(props.id, this.setData);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ tournamentDetails: newProps.tournamentDetails });
    }

    showDetails = (data) => {
        this.setState({
            detailsModalObj: data,
            openDetailsModal: true
        })
    }

    async getMatchDetails(matchId,competitionType,participantType) {
        var data = {
            ids: [matchId]
        }
        this.props.updateLoader(true);
        await this.matchesApis.getMultipleMatches(data).then(
            (res: AxiosResponse) => {
                var response = res.data['result']
                if (competitionType === 'DUEL' && participantType === 'TEAM') {
                    this.getTeamPlayers(response[0].participants);
                    this.setState({ 
                        matchDetails: response[0],
                        teamMVP : response[0].results[this.props.gameNo][0].teamMVP ? response[0].results[this.props.gameNo][0].teamMVP : null,
                        matchMVP : response[0].results[this.props.gameNo][0].matchMVP ? response[0].results[this.props.gameNo][0].matchMVP : null,
                    })
                }else if(competitionType === 'DUEL' && participantType === 'SINGLE'){
                    this.setState({ 
                        matchDetails: response[0],
                        allTeamsPlayers: response[0].participants,
                        teamMVP : response[0].results[this.props.gameNo][0].teamMVP ? response[0].results[this.props.gameNo][0].teamMVP : null,
                        matchMVP : response[0].results[this.props.gameNo][0].matchMVP ? response[0].results[this.props.gameNo][0].matchMVP : null,
                    })
                }else{
                    let arr = []
                    console.log('game details ----', response[0].results[this.props.gameNo]); //
                    Object.keys(response[0]?.results[this.props.gameNo]).map((par, i) => {
                        console.log(`participant ${i+1} details ::`, response[0]?.results[this.props.gameNo]?.[par]);
                        let participant = response[0].results[this.props.gameNo]?.[par];
                        if(participant.rank && !participant.forfeit){
                            arr.push (participant.rank);
                        }else{
                            arr.push(null);
                        }
                    })
                    console.log('final rank array ::', arr);
                    this.setState({
                        matchDetails: response[0], ranksArray: arr
                    });
                }
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    getTeamPlayers = async (participants) => {
        this.props.updateLoader(true);
        let allTeamsParticipants = []
        for (let i = 0; i < participants.length; i++) {
            const data = {
                _id: participants[i]?._id
            }
            await this.teamApis.getTeams(data).then(
                (res: AxiosResponse) => {
                    for (let j = 0; j < res.data.result[0].members.players.length; j++) {
                        allTeamsParticipants.push(res.data.result[0].members.players[j])
                    }
                    if (i === (participants.length - 1)) {
                        this.setState({ allTeamsPlayers: allTeamsParticipants })
                        this.props.updateLoader(false);
                    }
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err);
                }
            );
        }
    }

    setData = (values) => {
        //console.log('value ----------------', values);
        if (values) {
            this.setState({ tournamentDetails: values });
            this.getMatchDetails(this.props.matchId, values.competitionType, values.participantType);
            this.props.updateLoader(false);
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    updateScore = (data?) => {
        if(this.state.tournamentDetails?.competitionType === 'DUEL'){
            if(this.state.matchMVP === null || this.state.matchMVP === false ){
                toast.error('Please select match MVP');
                return false;
            }
            if(this.state.tournamentDetails.participantType === 'TEAM' && (this.state.teamMVP === null || this.state.teamMVP === false)){
                toast.error('Please select team MVP');
                return false;
            }
            if(!data[this.props.gameNo][0].isWinner && !data[this.props.gameNo][1].isWinner){
                toast.error('Please select a winner');
                return false;
            }
            data[this.props.gameNo][0].resultAdded = true;
            data[this.props.gameNo][1].resultAdded = true;
            data[this.props.gameNo][0].matchMVP = this.state.matchMVP;
            data[this.props.gameNo][0].teamMVP = this.state.teamMVP;
            data[this.props.gameNo][1].matchMVP = this.state.matchMVP;
            data[this.props.gameNo][1].teamMVP = this.state.teamMVP;
        }else{
            var isTeamGame = this.state.tournamentDetails?.participantType === 'TEAM' ? true : false;
            var errors = 0
            let game = [];
            if(isTeamGame){
                game  = Object.values(data[this.props.gameNo]).filter(val => val.teamDetails);
            }else{
                game  = Object.values(data[this.props.gameNo]).filter(val => val.participantDetails);
            }
            //console.log('game', game);
            game.map((el, i) => {
                console.log(`team ${i+1} rank = ${el.rank}`);
                if(!el.rank && !el.forfeit){
                    //console.log('-----------', el);
                    errors += 1;
                    toast.warning(`Select rank of ${isTeamGame ? el.teamDetails?.name : el.participantDetails?.inGameName}`);
                }
            })
            if(errors > 0){
                return false;
            }
        }
        let result = { results: data };
        this.props.updateLoader(true);
        this.matchesApis.updateMatchResults(this.props.matchId, result).then(
            (res: AxiosResponse) => {
                this.getMatchDetails(this.props.matchId);
                this.props.updateLoader(false);
                this.props.history.goBack();
                toast.success('Match result updated successfully');
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    updateSoloFFAScore = (e, player, key) => {
        const gameNo = parseInt(this.props?.gameNo);
        //console.log(`player ${player} has ${e.target.value} ${key}`);
        const {ranksArray} = this.state;
        const data = this.state.matchDetails;
        if (key === 'forfeit'){
            data.results[gameNo][player][key] = e.target.checked;
        }else if (key === 'rank'){
            data.results[gameNo][player][key] = parseInt(e.target.value);
            ranksArray.splice(player, 1, parseInt(e.target.value));
        }else{
            data.results[gameNo][player][key] = parseInt(e.target.value);
        }
        data.results[gameNo][player].resultAdded = true;
        this.setState({matchDetails: data})
    }

    updateTeamFFAScore = (e, team, player?, key?) => {
        const gameNo = parseInt(this.props?.gameNo);
        //console.log(`team ${team} player ${player} has ${e.target.value} ${key}`);
        const data = this.state.matchDetails;
        const {ranksArray} = this.state;
        console.log('initial array ::', ranksArray);
        data.results[gameNo][team].resultAdded = true;
        if (key === 'rank'){
            data.results[gameNo][team][key] = parseInt(e.target.value);
            ranksArray.splice(team, 1, parseInt(e.target.value))
        }else if (key === 'forfeit'){
            data.results[gameNo][team][key] = e.target.checked;
        }else{
            data.results[gameNo][team].individualScore[player][key] = parseInt(e.target.value);
        }
        //console.log('game stats ::', data.results[gameNo][team]);
        console.log('final array ::', ranksArray);
        this.setState({ matchDetails: data});
    }
    
    getURL = (el) => {
        //console.log(`el values -------------${el}`);
        if (this.state.tournamentDetails?.participantType === 'SINGLE') {
            return el.profilePicture.url;
        } else {
            return el.logo.url;
        }
    }

    getSoloFFA = () => {
        const gameNo = parseInt(this.props?.gameNo);
        const game = Object.values(this.state.matchDetails?.results[gameNo]);
        return (
            <div className="row">
                {
                    game.map((el, i) => {
                        //console.log(`player ${i+1}`, el)
                        if (el.participantDetails) {
                            return (
                                <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                    <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                        <div className="d-flex justify-content-center">
                                            <img src={el?.participantDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                            <h4 className="mt-3 fs-20">{ellipsis(el.participantDetails.inGameName, 12)}</h4>
                                        </div>
                                        <form className='mt-3' autoComplete='off'>
                                            <div className="form-group my-1">
                                                <label className='fs-14 fw-600 text-white mb-1'>Rank</label>
                                                <select as='select' value={el.rank ? el.rank : ''} onChange={(e) => this.updateSoloFFAScore(e, i, 'rank')} className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                                                    <option value=''>Choose Rank</option>
                                                    {[...Array(this.state.matchDetails?.participants?.length)].map((ele, i) => {
                                                        return <option value={i+1} key={i} disabled={this.state.ranksArray.includes(i+1) ? true : false}>{i+1}</option>
                                                    })}
                                                </select> 
                                            </div>
                                            {/* Kills */}
                                            <div className="form-group my-1">
                                                <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                <div className="">
                                                    <input
                                                        value={el.kills ? el.kills : ''} type="number" placeholder="Enter Kills"
                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                        onChange={(e) => this.updateSoloFFAScore(e, i, 'kills')}
                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                    />
                                                    <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                </div>
                                            </div>
                                            <hr className="" style={{ color: '#FF4D4D'}}/>
                                            <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                                                <input type='checkbox' checked={el.forfeit ? el.forfeit : false} className='me-2' onChange={(e) => this.updateSoloFFAScore(e, i, 'forfeit')} />
                                                <label className='fw-bold my-0'>Forfeit</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        )
    }

    getTeamFFA = () => {
        const gameNo = parseInt(this.props?.gameNo);
        const game = Object.values(this.state.matchDetails?.results[gameNo]);
        return (
            <div className='row'>
                {game.map((el, i) => {
                    if(el.individualScore){
                        return (
                            <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                    <div className="d-flex justify-content-center">
                                        <img src={el?.teamDetails?.logo?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                        <p className="mt-2 fs-14 fw-600">{ellipsis(el?.teamDetails?.name, 12)}</p>
                                    </div>
                                    <p className="mt-1 mb-1 fs-12 text-white text-center">{`Team Kills: ${el?.kills ? el?.kills : 0}`}</p>
                                    <div className="form-group my-1">
                                    <label className='fs-14 fw-600 text-white mb-1'>Rank</label>
                                        <select as='select' value={el.rank ? el.rank : ''} onChange={(e) => this.updateTeamFFAScore(e, i, 0, 'rank')} className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                                            <option>Choose Rank</option>
                                            {[...Array(this.state.matchDetails?.participants?.length)].map((ele, i) => {
                                                return <option value={i+1} key={i} disabled={this.state.ranksArray.includes(i+1) ? true : false}>{i+1}</option>
                                            })}
                                        </select> 
                                    </div>
                                    {el.individualScore.map((val, j) => {
                                        return (
                                            <div key={j}>
                                                <div className="d-flex flex-row align-items-center mt-3">
                                                    <hr className="flex-grow-1" />
                                                    <img src={val?.participantDetails?.profilePicture.url} className="rounded-circle mx-2 btn-size-32" alt='' />
                                                    <hr className="flex-grow-1" />
                                                </div>
                                                <p className="text-center fs-12 text-white mb-2">{ellipsis(val?.participantDetails?.inGameName, 12)}</p>
                                                <div className="form-group my-1">
                                                    <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                    <div className="">
                                                        <input
                                                            value={val.kills ? val.kills : ''} type="number" placeholder="Enter Kills"
                                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                            onChange={(e) => this.updateTeamFFAScore(e, i, j, 'kills')}
                                                            min={0} step={1} onWheel={(e) => e.target.blur()}
                                                        />
                                                        <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                     <hr className="" style={{ color: '#FF4D4D'}}/>
                                    <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                                        <input type='checkbox' checked={el.forfeit ? el.forfeit : false} className='me-2' onChange={(e) => this.updateTeamFFAScore(e, i, 0, 'forfeit')} />
                                        <label className='fw-bold my-auto'>Forfeit {ellipsis(el?.teamDetails?.name, 12)}</label>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    getSoloDuel = () => {
        const gameNo = parseInt(this.props?.gameNo);
        const game = Object.values(this.state.matchDetails?.results[gameNo]);
        return (
            <div>
                {this.getParticipantsCard()}
                <div className="row">
                {
                    game.map((el, i) => {
                        //console.log(`player ${i+1}`, el)
                        if (el.participantDetails) {
                            return (
                                <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                    <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                        <div className="d-flex justify-content-center">
                                            <img src={el?.participantDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                            <h4 className="mt-3 fs-20">{ellipsis(el.participantDetails.inGameName, 12)}</h4>
                                        </div>
                                        <form className='mt-3' autoComplete='off'>
                                            {/* Kills */}
                                            <div className="form-group my-1">
                                                <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                                <div className="">
                                                    <input
                                                        value={el.kills} type="number" placeholder="Enter Kills"
                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                        onChange={(e) => this.updateSoloFFAScore(e, i, 'kills')}
                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                    />
                                                    <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                </div>
                                            </div>
                                            {/* Deaths */}
                                            <div className="form-group my-1">
                                                <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                                <div className="">
                                                    <input
                                                        value={el.deaths} type="number" placeholder="Enter deaths"
                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                        onChange={(e) => this.updateSoloFFAScore(e, i, 'deaths')}
                                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                                    />
                                                    <img src={deaths} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                                </div>
                                            </div>
                                            <hr className="" style={{ color: '#FF4D4D'}}/>
                                            <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                                                <input type='checkbox' checked={el.forfeit ? el.forfeit : false} className='me-2' onChange={(e) => this.updateSoloFFAScore(e, i, 'forfeit')} />
                                                <label className='fw-bold my-0'>Forfeit</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
            </div>
        )
    }

    getTeamPlayersCards = (playerScores, i) => { 
        return (
            <div>
                {
                    playerScores.individualScore?.map((player, j) => {
                        return (
                            <form className="mt-5" autoComplete="off" key={j}>
                                <div className='shadow-none rounded-10 p-3 border border-1 borer-white'>
                                    <div className="d-flex justify-content-center">
                                        <img src={player.participantDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt='' />
                                        <h6 className='fs-16 fw-bold text-center my-3 text-truncate' >{ellipsis(player.participantDetails.inGameName, 12)}</h6>
                                    </div>

                                    {/* P1 Kills */}
                                    <div className="form-group my-1">
                                        <label className='fs-14 fw-600 text-white mb-1'>Kills</label>
                                        <div className="">
                                            <input
                                                value={player.kills} type="number" placeholder="Enter Kills"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                onChange={(e)=> this.updateTeamFFAScore(e, i, j, 'kills')}
                                                min={0} step={1} onWheel={(e) => e.target.blur()}
                                            />
                                            <img src={kills} alt="kills" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                        </div>
                                    </div>
                                    {/* P1 Deaths */}
                                    <div className="form-group my-1">
                                        <label className='fs-14 fw-600 text-white mb-1'>Deaths</label>
                                        <div className="">
                                            <input
                                                value={player.deaths} type="number" placeholder="Enter Deaths"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                onChange={(e)=> this.updateTeamFFAScore(e, i, j, 'deaths')}
                                                min={0} step={1} onWheel={(e) => e.target.blur()}
                                            />
                                            <img src={deaths} alt="deaths" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                        </div>
                                    </div>
                                    {/* P1 Assists */}
                                    <div className="form-group my-1">
                                        <label className='fs-14 fw-600 text-white mb-1'>Assists</label>
                                        <div className="">
                                            <input
                                                value={player.assists} type="number" placeholder="Enter Assists"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                onChange={(e)=> this.updateTeamFFAScore(e, i, j, 'assists')}
                                                min={0} step={1} onWheel={(e) => e.target.blur()}
                                            />
                                            <img src={assists} alt="score" className='me-4 float-end' style={{ marginTop: '-30px' }} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    })
                }
            </div>
        )
    }

    getTeamDuel = () => {
        const gameNo = parseInt(this.props?.gameNo);
        return (
            <div>
                {this.getParticipantsCard()}
                <div className='row mt-3'>
                    <div className='col-6'>
                        <h1 className={`${this.state.teamTab === '1' ? 'matches_border-bottom' : null} fs-16 fw-600 text-white text-center pb-2`} onClick={() => { this.setState({ teamTab: '1' }) }}>{ellipsis(this.state.matchDetails?.participants[0] ? this.state.matchDetails?.participants[0]?.name : 'Vacant', 12) }</h1>
                    </div>
                    <div className='col-6'>
                        <h1 className={`${this.state.teamTab === '2' ? 'matches_border-bottom' : null} fs-16 fw-600 text-white text-center pb-2`} onClick={() => { this.setState({ teamTab: '2' }) }}>{ellipsis(this.state.matchDetails?.participants[1] ? this.state.matchDetails?.participants[1]?.name : 'Vacant', 12) }</h1>
                    </div>

                </div>

                {this.state.teamTab === '1' ?
                    <div className=''>
                        <hr className="" style={{ color: '#FF4D4D'}}/>
                        <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                            <input type='checkbox' className='me-2'
                                checked={this.state.matchDetails?.results[gameNo][0]?.forfeit ? this.state.matchDetails?.results[gameNo][0]?.forfeit : false}
                                onChange={(e) => this.updateTeamFFAScore(e, 0, 0, 'forfeit')}
                            />
                            <label className='fw-bold my-0'>Forfeit {ellipsis(this.state.matchDetails?.participants[0]?.name, 12)}</label>
                        </div>
                        {this.state.matchDetails?.results[gameNo][0].individualScore ?
                            this.getTeamPlayersCards(this.state.matchDetails?.results[gameNo][0], 0)
                            :
                            null
                        }
                    </div> 
                    :
                    <div>
                        <hr className="" style={{ color: '#FF4D4D'}}/>
                        <div className="d-flex justify-content-center align-items-center" style={{ color: '#FF4D4D'}}>
                            <input type='checkbox' className='me-2'
                                checked={this.state.matchDetails?.results[gameNo][1]?.forfeit ? this.state.matchDetails?.results[gameNo][1]?.forfeit : false}
                                onChange={(e) => this.updateTeamFFAScore(e, 1, 0, 'forfeit')}
                            />
                            <label className='fw-bold my-0'>Forfeit {ellipsis(this.state.matchDetails?.participants[1]?.name, 12)}</label>
                        </div>
                        {this.state.matchDetails?.results[gameNo][1].individualScore ?
                            this.getTeamPlayersCards(this.state.matchDetails?.results[gameNo][1], 1)
                            :
                            <div>This slot is currently empty</div>
                        }
                    </div>
                }
                
            </div>
        )
    }

    getScoreCard = () => {
        const {tournamentDetails} = this.state;

        switch (true) {
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'DUEL'):
                return this.getSoloDuel();
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'FFA'):
                return this.getSoloFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'FFA'):
                return this.getTeamFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'DUEL'):
                return this.getTeamDuel();
            default:
                break;
        }
    }

    declareWinner = (key) => {
        //console.log(`winner is ${key}`);
        const data = this.state.matchDetails;
        if(key == 0){
            data.results[this.props.gameNo][0].resultAdded = true;
            data.results[this.props.gameNo][0].isWinner = true;
            data.results[this.props.gameNo][1].resultAdded = true;
            data.results[this.props.gameNo][1].isWinner = false;
        }else{
            data.results[this.props.gameNo][0].resultAdded = true;
            data.results[this.props.gameNo][0].isWinner = false;
            data.results[this.props.gameNo][1].resultAdded = true;
            data.results[this.props.gameNo][1].isWinner = true;
        }
        this.setState({matchDetails: data})
    }

    getParticipantsCard = () => {
        const gameNo = parseInt(this.props?.gameNo);
        return (
            <div className='mulberry-purple-card rounded-10 my-3 p-2' style={{boxShadow: '-4px -4px 5px rgb(64 67 88 / 25%), 4px 4px 5px rgb(0 0 0 / 25%)'}}>
                <h6 className='fs-20 fw-bold text-center my-3' >Game {parseInt(this.props?.gameNo) + 1}</h6>
                <div className='row d-flex justify-content-between align-items-center pb-4'>
                    {typeof this.state.matchDetails?.participants[0] === 'object' ?
                        <div className='col-4'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <img src={this.state.matchDetails?.participants[0] ? this.getURL(this.state.matchDetails?.participants[0]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                </div>
                                <div className='col-12 text-center'>
                                    <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{ellipsis(this.state.tournamentDetails?.participantType === 'SINGLE' ? this.state.matchDetails?.participants[0]?.inGameName : this.state.matchDetails?.participants[0]?.name, 12)}</h6>
                                </div>
                                <div className='col-12 text-center'>
                                {this.state.matchDetails?.results[gameNo][0]?.isWinner ?
                                    <Button variant='success' className='p-0 w-75'>Winner</Button>
                                    :
                                    <Button variant='secondary' className='p-0 w-75' onClick={() => this.declareWinner(0)}>Winner</Button>
                                }
                                </div>
                                <div className='col-12 text-center'>
                                    <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(this.state.matchDetails?.participants[0]) }}>
                                        <p className='fs-12 fw-500 my-1'>View Details</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-4'>
                            <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                        </div>
                    }
                    <div className='col-4'>
                        <p className='fs-20 text-info fw-bold text-center'>{`${this.state.matchDetails?.results[this.props.gameNo][0]?.kills ? this.state.matchDetails?.results[this.props.gameNo][0]?.kills : 0} - ${this.state.matchDetails?.results[this.props.gameNo][1]?.kills ? this.state.matchDetails?.results[this.props.gameNo][1]?.kills : 0}`}</p>
                    </div>
                    {typeof this.state.matchDetails?.participants[1] === 'object' ?
                        <div className='col-4'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <img src={this.state.matchDetails?.participants[1] ? this.getURL(this.state.matchDetails?.participants[1]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                </div>
                                <div className='col-12 text-center'>
                                    <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{ellipsis(this.state.tournamentDetails?.participantType === 'SINGLE' ? this.state.matchDetails?.participants[1]?.inGameName : this.state.matchDetails?.participants[1]?.name, 12)}</h6>
                                </div>
                                <div className='col-12 text-center'>
                                {this.state.matchDetails?.results[gameNo][1]?.isWinner ?
                                    <Button variant='success' className='p-0 w-75'>Winner</Button>
                                    :
                                    <Button variant='secondary' className='p-0 w-75' onClick={() => this.declareWinner(1)}>Winner</Button>
                                }
                                </div>
                                <div className='col-12 text-center'>
                                    <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(this.state.matchDetails?.participants[1]) }}>
                                        <p className='fs-12 fw-500 my-1'>View Details</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-4'>
                            <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                        </div>
                    }
                </div>

                {this.state.tournamentDetails.participantType === 'TEAM' &&
                    <div className='d-flex flex-column mb-2'>
                        <label htmlFor='teamMVP' className="fs-14 fw-600 text-white">Team MVP</label>
                        <select id='teamMVP' value={JSON.stringify(this.state.teamMVP)} onChange={(e)=>this.setState({teamMVP: JSON.parse(e.target.value)})} name='matchMVP' className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                            <option value='' >Select player</option>
                            {
                                this.state.allTeamsPlayers.map((el, i) => {
                                    return (
                                        <option value={JSON.stringify(el)} key={i}>{ellipsis(el?.inGameName, 12)}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                }

                <div className='d-flex flex-column mb-2'>
                    <label htmlFor='matchMVP' className="fs-14 fw-600 text-white">Match MVP</label>
                    <select id='matchMVP' value={JSON.stringify(this.state.matchMVP)} onChange={(e)=>{this.setState({matchMVP:JSON.parse(e.target.value)})}} name='matchMVP' className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                        <option value='' >Select player</option>
                        {
                            this.state.allTeamsPlayers.map((el, i) => {
                                return (
                                    <option value={JSON.stringify(el)} key={i}>{ellipsis(el?.inGameName, 12)}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="d-flex align-items-center">
                    <hr className="border-1 flex-grow-1" />
                    <div className="d-flex mx-3 align-items-center">
                        <p className="fs-16 fw-bold text-white-50 mb-0">Scan Game Scorecard(OCR)</p>
                    </div>
                    <hr className="border-1 flex-grow-1" />
                </div>

                <button className='white-flat-btn text-center w-100 rounded-10 height-45 my-3 fw-600 px-3' onClick={this.uploadScreenshot}>
                    <img src={upload} alt='' className="me-2"/>
                    Upload Screenshot
                </button>

            </div>
        )
    }

    uploadScreenshot = () => {
        toast.warning('Work in progress');
        return false;
    }

    getOCRCard = () => {
        return(
            <div className='mulberry-purple-card rounded-10 my-3 p-2' style={{boxShadow: '-4px -4px 5px rgb(64 67 88 / 25%), 4px 4px 5px rgb(0 0 0 / 25%)'}}>
                <h1 className='fs-20 fw-bold text-white text-center' style={{}}>FFA</h1>
                <div className='d-flex justify-content-between'>
                    <div>
                    {(this.state?.matchDetails && this.state?.tournamentDetails) ?
                            this.state?.matchDetails?.participants.map((el, i) => {
                              if (i < 4) {
                                  //console.log(`participant ${i+1} details -- ${el}`)
                                return (
                                  <span className="avatar" key={i}>
                                    <img className='btn-size-32 border border-1' src={this.getURL((el))} width="50" height="50" alt="profile" />
                                  </span>
                                );
                              }
                              return false;
                            }) : ''}
                    {(this.state?.matchDetails && this.state?.matchDetails?.participants?.length > 4) ?
                        <span className="avatar d-inline-flex">
                            <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-32 d-flex justify-content-center align-items-center'>+{this.state?.matchDetails?.participants?.length - 4}</p>
                        </span>
                        : 
                        null
                    }
                    </div>
                    <div className='d-flex align-items-center me-2'>
                        <IoIosPeople style={{height:'24px', width:'24px'}} />
                        <span className='fs-16 fw-bold ms-2'>{this.state.matchDetails?.participants?.length}</span>
                    </div>
                </div>
                <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2 w-100' onClick={() => this.showDetails(this.state.matchDetails?.participants)}>
                    <p className='fs-16 fw-600 my-1'>View Details</p>
                </button>
                <div className="d-flex align-items-center">
                    <hr className="border-1 flex-grow-1" />
                    <div className="d-flex mx-3 align-items-center">
                        <p className="fs-16 fw-bold text-white-50 mb-0">Scan Game Scorecard(OCR)</p>
                    </div>
                    <hr className="border-1 flex-grow-1" />
                </div>

                <button className='white-flat-btn text-center w-100 rounded-10 height-45 my-3 fw-600 px-3' onClick={this.uploadScreenshot}>
                    <img src={upload} alt='' className="me-2"/>
                    Upload Screenshot
                </button>
            </div>
        )
    }

    render() {
        return (
            <section className="body-section match-duel-score-update">
                <div className="container p-3 mb-5 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={score} heading={`${this.props.title}`} subHeading={null} history={this.props.history} />
                    <div className='d-flex justify-content-between my-3'>
                        <h6 className='fs-16 fw-bold'>Show Down</h6>
                        <h6 className='fs-12 fw-bold' style={{ opacity: "0.7" }}>{dateFormat(this.state.matchDetails?.matchSchedule)}</h6>
                    </div>
                    <h1 className='fs-16 fw-bold text-center text-white'>GAME {parseInt(this.props.gameNo) + 1}</h1>
                    {this.state.tournamentDetails?.competitionType === 'FFA' && this.getOCRCard()}
                    <div className="d-flex align-items-center">
                        <hr className="border-1 flex-grow-1" />
                        <div className="d-flex mx-3 align-items-center">
                            <p className="fs-16 fw-bold text-white-50 mb-0">Enter Score</p>
                        </div>
                        <hr className="border-1 flex-grow-1" />
                    </div>
                    {(this.state.tournamentDetails && this.state.matchDetails) ?
                        this.getScoreCard()
                        :
                        null
                    }

                    <div className="contact-toggle d-flex align-items-center rounded-10 height-45 my-3">
                        <p className="fs-16 fw-600 mb-0 flex-grow-1 ps-3">Disable Chat</p>
                        <Switch checked={this.state.disableChat}
                            checkedIcon={false} uncheckedIcon={false} className="me-2"
                            onChange={(e) => this.setState({ disableChat: !this.state.disableChat })}
                        />
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <div className='position-relative d-flex justify-content-center'>
                            <div className='text-center'>
                                <button onClick={()=>this.updateScore(this.state.matchDetails?.results)} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3'>
                                    <img src={refresh} alt="" />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Update</p>
                            </div>

                            <div className='text-center'>
                                <button type='button' disabled={this.state.disableChat} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-2' onClick={() => {
                                    this.props.history.push(
                                        {
                                            pathname: `/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/chatLobby/${this.props.matchId}`,
                                            state: {
                                                orgId: this.props.orgId,
                                                tournamentDetails: this.props.tournamentDetails
                                            }
                                        }
                                    )
                                }}>
                                    <img src={comments} alt="" className='btn-size-25' />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Chat</p>

                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={() => { this.props.history.push(`/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/dispute-management/${this.props.matchId}`) }}>
                                    <img src={disputes} alt="" className='btn-size-25' />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Disputes</p>

                            </div>

                            <div className='text-center'>
                                <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={() => { this.props.history.push(`/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/streams`) }}>
                                    <img src={streams} alt="" />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Stream</p>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.openDetailsModal} centered dialogClassName="ClanInvite-modal">
                    <Modal.Body>
                        {this.state.tournamentDetails?.competitionType === 'FFA' ?
                            <FFAParticipantDetails updateLoader={this.props.updateLoader} details={this.state.detailsModalObj} type={this.state.tournamentDetails?.participantType} onClose={() => { this.setState({ openDetailsModal: false }) }} />
                            :
                            <ParticipantDetails updateLoader={this.props.updateLoader} details={this.state.detailsModalObj} type={this.state.tournamentDetails?.participantType} onClose={() => { this.setState({ openDetailsModal: false }) }} />

                        }
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
};

export default (connect(mapStateToProps, mapDispatchToProps))(PUBG);

