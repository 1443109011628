import { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//styles and icons
import { BsInfoCircle } from 'react-icons/bs';
import '../../../../styles/Tournaments.css';
import { FaUserCircle } from 'react-icons/fa';
import { COMMISSION_QUESTION_TYPE } from '../../../../utils/enums.utils';

export default class PremiumFreeFixed extends Component {
    constructor(props) {
        super(props)
        this.localObject = props.localObject;
        this.state = {
            kycVerified: true,
            prizePoolAmount: this.props?.initialValues?.prizePoolAmount ? this.props?.initialValues?.prizePoolAmount : ( props.localObject?.prizePoolAmount && props.id == props.localObject?.id) ? props.localObject.prizePoolAmount : '',
            espotzCommission: props.commissionValues ? props.commissionValues.questions[COMMISSION_QUESTION_TYPE.PREMIUM_FIXED_PC] : 10,
            ecToBeDeposited: 0
        }
    }

    componentDidMount() {
        const { prizePoolAmount, espotzCommission } = this.state
        let depositAmount = (100 + espotzCommission) * prizePoolAmount / 100
        this.setState({ ecToBeDeposited: depositAmount })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            prizePoolAmount: newProps?.initialValues?.prizePoolAmount ? newProps?.initialValues?.prizePoolAmount : ( newProps.localObject?.prizePoolAmount && newProps.id == newProps.localObject?.id) ? newProps.localObject.prizePoolAmount : '',
            espotzCommission: newProps?.initialValues?.espotzCommission ? newProps?.initialValues?.espotzCommission : 10,
            ecToBeDeposited: newProps?.initialValues?.ecToBeDeposited ? newProps?.initialValues?.ecToBeDeposited : 0
        });
    }

    updateDetails = (e) => {
        e.preventDefault()
        const { espotzCommission } = this.state
        let amount = e.target.value
        let depositAmount = (100 + espotzCommission) * amount / 100;
        this.localObject['prizePoolAmount'] = e.target.value;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        this.setState({
            prizePoolAmount: amount,
            ecToBeDeposited: depositAmount
        })
    }

    validatePricePool = (val) => {
        let error;
        if (this.state.ecToBeDeposited > this.props.balance) {
            error = `Your available wallet balance is ${this.props.balance}`
        }
        return error;
    }

    passDetails = (obj) => {
        this.props.onSubmitData(obj)
    }
    render() {
        return (
            <section className="">
                <Formik
                    enableReinitialize
                    initialValues={{
                        prizePoolAmount: this.props?.initialValues?.prizePoolAmount ? this.props?.initialValues?.prizePoolAmount : '',
                        espotzCommission: this.props?.initialValues?.espotzCommission ? this.props?.initialValues?.espotzCommission : 10,
                        //buyEC: this.props?.initialValues?.buyEC?this.props?.initialValues?.buyEC:'',
                    }}
                    validationSchema={Yup.object({
                        prizePoolAmount: Yup.number().integer().required('Enter Prize Pool Amount').min(0, 'Cannot be less than Zero'),
                        espotzCommission: Yup.number().integer().required('Enter Espotz Commission').min(0, 'Cannot be less than Zero')
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.passDetails(values)
                            setSubmitting(false);
                        }, 400);
                    }}>
                    <Form className="row mt-3" autoComplete="off">
                        {/* Prize Pool */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Prize Pool Amount</label>
                            <div className="">
                                <Field
                                    name='prizePoolAmount' type="number" placeholder="Enter in EC"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 fw-500 height-45"
                                    onKeyUp={(e) => this.updateDetails(e)}
                                    validate={this.validatePricePool}
                                    min={1} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="prizePoolAmount" className="error-msg" />
                        </div>
                        {/* Espotz Commission */}
                        <div className="col-lg-6 my-2">
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <label className="fs-14 fw-600 text-white">Espotz Commission(in %)</label>
                                <Field
                                    name='espotzCommission' type='number' placeholder='in %' disabled
                                    className='purple-field d-flex align-items-center w-25 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="espotzCommission" className="error-msg" />
                        </div>
                        {/* EC to be deposited */}
                        <div className='col-lg-6 d-flex justify-content-between align-items-center my-3'>
                            <label className="fs-14 fw-600 text-white">EC To Be Deposited </label>
                            <input
                                className='purple-field d-flex align-items-center w-50 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                disabled value={this.state.ecToBeDeposited}
                                min={0} step={1} onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <span>
                            <div className='col-lg-6'>
                                <button className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-2'>
                                    KYC Verification
                                </button>
                            </div>
                            {/* <button
                            className='tournament-btn category-btn w-100 my-2'
                            type='submit'
                            disabled={this.state.kycVerified ? false : true}
                            style={{ opacity: this.state.kycVerified ? 1 : 0.6 }}>
                            Pay
                        </button> */}
                            <div className='col-lg-6'>
                                <button type='submit' className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-4 mt-lg-2' >
                                    Next
                                </button>
                            </div>
                            </span>
                    </Form>
                </Formik>
            </section>
        )
    }
}
