import { Component } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
//styles, images
import '../../../styles/TournamentPage.css'
import diamond from '../../../images/games/diamond.png'
import share from '../../../images/games/share.png'
import swords from '../../../images/games/swords.png'
import dollar from '../../../images/games/dollar.png'
import rank1 from '../../../images/page/rank1.png'
import rank2 from '../../../images/page/rank2.png'
import rank3 from '../../../images/page/rank3.png'
import participantAvtar from "../../../images/participantAvtar.jpg";
import mobile from '../../../images/page/mobile-icon.png'
import nintendo from '../../../images/page/nintendo-icon.png'
import pc from '../../../images/page/pc-icon.png'
import ps from '../../../images/page/ps-icon.png'
import xbox from '../../../images/page/xbox-icon.png'

//components
import { ellipsis, errorHandler, getMonthAndDate, remainingTime, updateLoader } from '../../../utils/common.utils';
import PrizePool from '../../popup/PrizePool';
import { profile } from '../../../utils/localstorage.utils'
import TournamentShare from '../../popup/TournamentShare'
import MatchesApis from '../../../helper/matches.api'
import { PATH } from '../../../utils/Strings.utils'
import { toast } from "material-react-toastify";
import { TournamentStatus } from '../../../utils/enums.utils'

export default class TournamentHeader extends Component {
   
    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: this.props.tournamentDetails,
            openPrizePool: false,
            openShare: false,
            matchDetails: null
        }

        this.matchDetailsCalled = false;
        this.matchesApis = new MatchesApis();
        this.authUser = profile();
    }

    componentDidMount() {
        if(((this.props.tournamentDetails?.stages[0]?.subType === "Bracket Groups") && (this.props.tournamentDetails?.stages[0]?.bracketType === "DOUBLE"))){
            this.getMatchDetails(this.props.tournamentDetails?.stages[0]?.groups[0][0][1][0]);
        }else if(this.props.tournamentDetails?.stages[0]?.groups[0][1][0]){
            this.getMatchDetails(this.props.tournamentDetails?.stages[0]?.groups[0][1][0]);
        }
       
    }

    getMatchDetails = (id) =>{
        try{
            this.matchDetailsCalled = true;
                updateLoader(true);
                const data = {
                    _id: id
                }
        
                this.matchesApis.getMatchDetails(data).then(
                    (res: AxiosResponse) => {
                        var response = res.data['result']
                        this.setState({
                            matchDetails: response[0]
                        })
                        updateLoader(false);
                    }
                ).catch(
                    (err: AxiosError) => {
                        updateLoader(false);
                        errorHandler(err);
                    }
                )   
        }catch(err){
            console.log("Error : ",err)
            updateLoader(false)
        }
    }



    componentWillReceiveProps(newProps) {
        this.setState({ tournamentDetails: newProps.tournamentDetails });
        if(newProps.tournamentDetails?.stages[0]?.groups[0][1][0] && !this.matchDetailsCalled){
            this.getMatchDetails(newProps.tournamentDetails?.stages[0]?.groups[0][1][0]);
        }
    }

    toggleModalStatus = () => {
        this.setState({ openPrizePool: !this.state.openPrizePool })
    }

    getPlatformUrl = (key) => {
        switch (key) {
            case 'MOBILE':
                return mobile
            case 'PC':
                return pc
            case 'PLAYSTATION':
                return ps
            case 'XBOX':
                return xbox
            default:
                return nintendo
        }
    }

    getCurrentStatus = () => {
        const {tournamentDetails} = this.state;
        if (tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolType === 'FIXED'){
            return 100;
        }else{
            const entryFee = tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount;
            const members = tournamentDetails?.teams?.length;
            const moneyCollectedPerEntryFee = tournamentDetails?.entryFeeAndPrizeDetails?.moneyCollectedPerEntryFee
            const prizePool = tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount;
            return `${parseInt(moneyCollectedPerEntryFee)*members*100/prizePool}`;
        }
    }

    getPrizePoolStatus = () => {
        const {tournamentDetails} = this.state;
        const moneyCollectedPerEntryFee = tournamentDetails?.entryFeeAndPrizeDetails?.moneyCollectedPerEntryFee
        const members = tournamentDetails?.teams?.length;
        const prizePool = tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount;
        if (tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolType === 'FIXED'){
            return `${prizePool}/${prizePool}`;
        }else{
            
            return `${parseInt(moneyCollectedPerEntryFee)*members}/${prizePool}`;
        }
    }

    toggleShare = () => {
        this.setState({openShare : !this.state.openShare})
    }

    registerForTournament = (e,matchDetails,id,number) => {
        console.log("------- registerForTournament :: ",this.props.registerForTournament)
        if(this.props.registerForTournament){
            this.props.registerForTournament(e,matchDetails,id,number);
        }else{
            toast.warning("Please go to matches and select in which match you want to participate")
        }

    }

    getOnGoingView = () => {
        const { tournamentDetails } = this.state;
        const timeLeft = remainingTime(tournamentDetails?.registrationEndTime);
        const members = tournamentDetails?.teams?.length;
        const currentFilling = (members*100)/tournamentDetails?.maxParticipant;
        const isAlreadyRegistered = this.state.tournamentDetails?.participantUserId?.includes(this.authUser?.id);
        return(
            <div className='col-lg-6 col-12'>
                {/* For Upcoming Tournaments */}
                <div className=''>
                    <button disabled={isAlreadyRegistered} className={`w-100 flat-btn cursor-pointer border-0 outline-none height-45 mt-3 ${isAlreadyRegistered ? 'opacity-50' : 'opacity-100'}`} onClick={(e)=>this.registerForTournament(e,this.state.matchDetails,this.state.matchDetails?._id,null)}>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-4 d-flex justify-content-center align-items-center'>
                                <img src={swords} alt='swords' className='me-2' />
                                <p className='fs-16 fw-600 text-white my-0'>{isAlreadyRegistered ? 'Registered' : 'Register'}</p>
                            </div>
                            <section className='reg-btn d-flex align-items-center col-4'>
                                {/* <ProgressBar animated now={now} variant='success' label={`${now}%`} className='w-100 mx-0' style={{ height: '20px !important' }} /> */}
                            </section>
                        </div>
                    </button>
                    <section className='reg-btn d-flex align-items-center my-1'>
                        <ProgressBar now={currentFilling} variant='success' label={`${currentFilling}%`} className='w-100 mx-0'/>
                    </section>
                    <h1 className='fs-12 fw-700 error-msg text-center my-1'>{`${timeLeft[0]} ${timeLeft[1]}`}</h1>
                    <div className='row my-3'>
                        <div className='col-4 d-flex justify-content-center align-items-center'>
                            <img src={dollar} className='me-3' alt='dollar' />
                            <p className='fs-12 fw-700 text-white my-0'>{(tournamentDetails?.entryFeeAndPrizeDetails) ? tournamentDetails?.entryFeeAndPrizeDetails.entryFeeAmount + ' EC' : 'Free'} </p>
                        </div>
                        <div className='col-8 d-flex justify-content-center align-items-center'>
                            {/* <img src={mode} className='me-3' alt='dollar' /> */}
                            {tournamentDetails?.platform?.map((type, i) => {
                                return <img src={this.getPlatformUrl(type)} key={i} className='me-1' alt={type} style={{ height: '20px', width: '20px' }} />
                            })}
                            <p className='fs-12 fw-700 text-white my-0'>{tournamentDetails?.gameDetails?.name}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getWinnersView = () => {
        const { tournamentDetails } = this.state;
        const startTime = getMonthAndDate(tournamentDetails?.tournamentStartTime);
        const endTime = getMonthAndDate(tournamentDetails?.tournamentEndTime);
        const timeLeft = remainingTime(tournamentDetails?.registrationEndTime);
        const members = tournamentDetails?.teams?.length;
        const currentFilling = (members*100)/tournamentDetails?.maxParticipant;
        const isAlreadyRegistered = this.state.tournamentDetails?.participantUserId?.includes(this.authUser?.id);
        return(
            <div className='col-lg-6 col-12'>
                <div className='row'>
                    <div className='col-2'></div>
                    <div className='col-8'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center my-2'>
                                <img src={rank1} alt='' className='multiple-winners me-3' />
                                <img src={participantAvtar} alt='' className='rounded-circle me-2' style={{ height: '20px', width: '20px' }} />
                                <p className='fs-20 fw-bold text-white-50 my-1'>Jay Kaushik</p>
                            </div>
                            <div className='d-flex align-items-center my-2'>
                                <img src={rank2} alt='' className='multiple-winners me-3' />
                                <img src={participantAvtar} alt='' className='rounded-circle me-2' style={{ height: '20px', width: '20px' }} />
                                <p className='fs-20 fw-bold text-white-50 my-1'>Raj Kumar</p>
                            </div>
                            <div className='d-flex align-items-center my-2'>
                                <img src={rank3} alt='' className='multiple-winners me-3' />
                                <img src={participantAvtar} alt='' className='rounded-circle me-2' style={{ height: '20px', width: '20px' }} />
                                <p className='fs-20 fw-bold text-white-50 my-1'>Smith Williams</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'></div>
                </div>
            </div>
        )
    }

    renderViewByStatus = () => {
        switch (this.state.tournamentDetails?.tournamentStatus) {
            case TournamentStatus.COMPLETED:
                return this.getWinnersView();
            default:
                return this.getOnGoingView();
        }
    }

    render() {
       
        const { tournamentDetails } = this.state;
        const startTime = getMonthAndDate(tournamentDetails?.tournamentStartTime);
        const endTime = getMonthAndDate(tournamentDetails?.tournamentEndTime);
        const timeLeft = remainingTime(tournamentDetails?.registrationEndTime);
        const members = tournamentDetails?.teams?.length;
        const currentFilling = (members*100)/tournamentDetails?.maxParticipant;
        const isAlreadyRegistered = this.state.tournamentDetails?.participantUserId?.includes(this.authUser?.id);
        return (
            <section className='page-header px-3 pt-lg-5 pt-3'>

                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-12'>
                            <div className='d-flex w-100'>
                                <div className=''>
                                    <img src={tournamentDetails?.baner?.url} alt='banner' className='banner' />
                                </div>
                                <div className='mx-2'>
                                    {tournamentDetails?.name?.length <= 15 ?
                                        <h1 className='text-white fw-700 fs-20'>{tournamentDetails?.name}</h1>
                                        :
                                        <marquee className='marquee-text text-white fw-700 fs-20' direction="left">{tournamentDetails?.name}<span className='text-warning px-4'>$$$</span> {tournamentDetails?.name}</marquee>
                                    }
                                    
                                    <p className='fs-12 text-white-50 fw-600'>{startTime[0]} - {endTime[0]}</p>
                                </div>
                                <div className='me-1'>
                                    <img src={diamond} alt='diamond' />
                                </div>
                                <div className='ms-auto'>
                                    <img src={share} alt='share' style={{ cursor: 'pointer' }} onClick={this.toggleShare} />
                                </div>
                            </div>
                            <div className='w-100 my-3' style={{display: this.state.tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount ? 'block' : 'none'}}>
                                <ProgressBar variant="warning" now={this.getCurrentStatus()} className='w-100 mx-0' />
                                <div className='d-flex justify-content-between my-1'>
                                    <p className='fs-12 text-light fw-600 cursor-pointer' onClick={this.toggleModalStatus}>Prize Pool</p>
                                    <p className='fs-12 text-light fw-600'>{this.getPrizePoolStatus()}</p>
                                </div>
                            </div>
                        </div> 
                        {/* For Upcoming Tournaments */} 
                        <div className='col-lg-6 col-12'>
                            <div className=''>
                                <button disabled={isAlreadyRegistered} className={`w-100 flat-btn cursor-pointer border-0 outline-none height-45 mt-3 ${isAlreadyRegistered ? 'opacity-50' : 'opacity-100'}`} onClick={(e)=>this.registerForTournament(e,this.state.matchDetails,this.state.matchDetails?._id,null)}>
                                    <div className='row'>
                                        <div className='col-4'></div>
                                        <div className='col-4 d-flex justify-content-center align-items-center'>
                                            <img src={swords} alt='swords' className='me-2' />
                                            <p className='fs-16 fw-600 text-white my-0'>{isAlreadyRegistered ? 'Registered' : 'Register'}</p>
                                        </div>
                                        <section className='reg-btn d-flex align-items-center col-4'>
                                            {/* <ProgressBar animated now={now} variant='success' label={`${now}%`} className='w-100 mx-0' style={{ height: '20px !important' }} /> */}
                                        </section>
                                    </div>
                                </button>
                                <section className='reg-btn d-flex align-items-center my-1'>
                                    <ProgressBar now={currentFilling} variant='success' label={`${currentFilling}%`} className='w-100 mx-0'/>
                                </section>
                                <h1 className='fs-12 fw-700 error-msg text-center my-1'>{`${timeLeft[0]} ${timeLeft[1]}`}</h1>
                                <div className='row my-3'>
                                    <div className='col-4 d-flex justify-content-center align-items-center'>
                                        <img src={dollar} className='me-3' alt='dollar' />
                                        <p className='fs-12 fw-700 text-white my-0'>{(tournamentDetails?.entryFeeAndPrizeDetails) ? tournamentDetails?.entryFeeAndPrizeDetails.entryFeeAmount + ' EC' : 'Free'} </p>
                                    </div>
                                    <div className='col-8 d-flex justify-content-center align-items-center'>
                                        {/* <img src={mode} className='me-3' alt='dollar' /> */}
                                        {tournamentDetails?.platform?.map((type, i) => {
                                            return <img src={this.getPlatformUrl(type)} key={i} className='me-1' alt={type} style={{ height: '20px', width: '20px' }} />
                                        })}
                                        <p className='fs-12 fw-700 text-white my-0'>{tournamentDetails?.gameDetails?.name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* For Past Tournaments and Single Winner */}
                        {/* Hidden for now */}
                        <div className='col-lg-6 col-12 d-none'>
                            <div className='d-flex justify-content-center my-3'>
                                <img src={rank1} alt='' className='single-winner' />
                                <div className='d-flex flex-column align-items-center ms-2'>
                                    <img src={participantAvtar} alt='' className='rounded-circle' style={{ height: '40px', width: '40px' }} />
                                    <p className='fs-24 fw-bold text-white-50 my-1'>Jay Kaushik</p>
                                </div>
                            </div>
                        </div>
                        {/* Past Tournament with multiple winners */}
                        {/* Hidden for now */}
                        <div className='col-lg-6 col-12 d-none'>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-8'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center my-2'>
                                            <img src={rank1} alt='' className='multiple-winners me-3' />
                                            <img src={participantAvtar} alt='' className='rounded-circle me-2' style={{ height: '20px', width: '20px' }} />
                                            <p className='fs-20 fw-bold text-white-50 my-1'>Jay Kaushik</p>
                                        </div>
                                        <div className='d-flex align-items-center my-2'>
                                            <img src={rank2} alt='' className='multiple-winners me-3' />
                                            <img src={participantAvtar} alt='' className='rounded-circle me-2' style={{ height: '20px', width: '20px' }} />
                                            <p className='fs-20 fw-bold text-white-50 my-1'>Raj Kumar</p>
                                        </div>
                                        <div className='d-flex align-items-center my-2'>
                                            <img src={rank3} alt='' className='multiple-winners me-3' />
                                            <img src={participantAvtar} alt='' className='rounded-circle me-2' style={{ height: '20px', width: '20px' }} />
                                            <p className='fs-20 fw-bold text-white-50 my-1'>Smith Williams</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2'></div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.openPrizePool} centered dialogClassName="success-modal">
                    <Modal.Body>
                        <PrizePool
                            toggleModalStatus={this.toggleModalStatus}
                            tournamentDetails={this.state.tournamentDetails}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openShare} centered dialogClassName="social-media-modal">
                    <Modal.Body>
                        <TournamentShare
                            toggleShare={this.toggleShare}
                            tournamentDetails={this.state.tournamentDetails}
                        />
                    </Modal.Body>
                </Modal>
            </section>

        )
    }
}
