import React, { Component } from 'react';
import { POST_INTERACTIONS } from '../../utils/Images.utils';
import { STYLE } from '../../utils/Style.utils';
import Image from '../../components/common/Image';
import { Button } from 'react-bootstrap';
import { undoDenPostReport } from '../../utils/Den.utils';

export default class UndoReportPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postId: props.postId,
            denId: props.denId,
            reportedDetails: props.reportedDetails
        };
    }

    componentWillReceiveProps(newProps){
        this.setState({
            postId: newProps.postId,
            denId: newProps.denId,
            reportedDetails: newProps.reportedDetails
        });
    }

    render() {
        return (
            <div className='d-flex px-2 my-1' style={STYLE.DEN.PROFILE_CARD}>
                <div className='d-flex p-2'><Image src={POST_INTERACTIONS.ACTIONS.REPORT} param={'REPORT'} className='' style={{ height: '22px', width: 'auto'}} /></div>
                <div className='flex-grow-1 p-2'>
                    <p className='fs-14 fw-600 text-white my-0'>Post Reported</p>
                    <p className='fs-14 fw-500 text-white-50 my-0'>You won’t see this post on your main feed. It is under review by Epotz.</p>
                </div>
                <div className='d-flex align-items-start p-2'><button className='den-profile-btn follow-btn' onClick={()=>undoDenPostReport(this.state.reportedDetails?._id,this.props.clearUndoReportPopup)}>Undo</button></div>
            </div>
        );
    }
}