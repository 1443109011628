import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { isValidString } from '../../../../../../../utils/common.utils';
import { toast } from 'material-react-toastify';

export default class BettingStatementForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bettingStatements: props.bettingStatements ? props.bettingStatements : [],
            selectedBettingStatement: 0,
            isUpdate: props.selectedIndex !== null ? true : false
        }
    }

    componentDidMount() {
        if (this.props.selectedIndex !== null) {
            let index = this.props.bettingStatements.findIndex(statement => statement._id === this.props.statementId);
            console.log(this.props.bettingStatements + "---index :: ", index)
            this.setState({
                selectedBettingStatement: index,
                isUpdate: true
            });
        }
    }


    onSubmit = (value) => {
        if (value.options === 'CUSTOM') {
            var choices = [];
            var optionsAreValid = true;
            [...Array(4)].map((key, i) => {
                if (isValidString(value[`option${i}`])) {
                    choices.push(value[`option${i}`]);
                    delete value[`option${i}`];
                } else {
                    optionsAreValid = false;
                }
            });

            if (optionsAreValid) {
                value.choices = choices;
                console.log("$$$$$$$$$$4 ", this.state.isUpdate, this.props.selectedBettingStatements[this.props.selectedIndex])
                this.props.addUpdateStatement(value, this.state.isUpdate, this.props.selectedBettingStatements[this.props.selectedIndex]?._id)
            } else {
                toast.warning("Please enter all valid options");
            }
        } else {
            this.props.addUpdateStatement(value, this.state.isUpdate, this.props.selectedBettingStatements[this.props.selectedIndex]?._id)
        }

    }

    selectedBettingStatement = (e) => {
        e.preventDefault();
        this.setState({
            selectedBettingStatement: e.target.value
        });
    }

    render() {
       
        const optionType = this.state.bettingStatements[this.state.selectedBettingStatement].type;
        const defaultOptions = this.props.selectedBettingStatements[this.props.selectedIndex]?.options;

        if (this.state.bettingStatements.length > 0) {
            return (
                <div className="p-3 px-md-5 px-lg-5">
                    <IoIosCloseCircleOutline
                        className="close-icon position-absolute" style={{ right: '15px' }}
                        onClick={() => this.props.showOrHideAddStatementModal()}
                    />
                    <Formik
                        enableReinitialize
                        initialValues={{
                            statement: this.state.selectedBettingStatement,
                            options: optionType,
                            entryFees: (this.state.isUpdate && this.props.selectedBettingStatements[this.props.selectedIndex]) ? this.props.selectedBettingStatements[this.props.selectedIndex].entryFees : '0',
                            bettingPool: (this.state.isUpdate && this.props.selectedBettingStatements[this.props.selectedIndex]) ? this.props.selectedBettingStatements[this.props.selectedIndex].bettingPool : '0',
                            option0: (this.state.isUpdate && defaultOptions) ? defaultOptions[0] : null,
                            option1: (this.state.isUpdate && defaultOptions) ? defaultOptions[1] : null,
                            option2: (this.state.isUpdate && defaultOptions) ? defaultOptions[2] : null,
                            option3: (this.state.isUpdate && defaultOptions) ? defaultOptions[3] : null,
                        }}

                        validationSchema={Yup.object({
                            statement: Yup.string(),
                            options: Yup.string(),
                            entryFees: Yup.number().required("Entry fee required").min(0, 'Entry fee shuold be greter than 0').max(100000000, 'Max allowed value is 100,000,000'),
                            bettingPool: Yup.number().required("Betting pool required").min(0, 'Betting pool shuold be greter than 0').max(100000000, 'Max allowed value is 100,000,000'),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.onSubmit(values)
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        <Form className="mt-3" autoComplete='off'>
                            <div className="">
                                <label htmlFor="statement" className="fs-14 fw-600 text-white">Statement</label>
                                <Field name="statement" onChange={(e) => this.selectedBettingStatement(e)} as="select" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                    {this.state.bettingStatements.map((statement, i) => {
                                        return <option value={i} key={i} onClick={(e) => this.selectedBettingStatement(e, i)}> {statement.statement}</option>
                                    })}
                                </Field>
                                <ErrorMessage component="span" className="error-msg" name="statement" />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="statement" className="fs-14 fw-600 text-white">Options</label>
                                {optionType === 'CUSTOM' ?
                                    [...Array(4)].map((key, i) => {
                                        return (
                                            <Field
                                                key={i}
                                                name={`option${i}`}
                                                type="text"
                                                placeholder={`Option ${i + 1}`}
                                                className="purple-field mt-2 d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            />
                                        )
                                    })
                                    :
                                    <Field
                                        name="options"
                                        type="text"
                                        disabled
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    />
                                }
                                <ErrorMessage component="span" className="error-msg" name="options" />
                            </div>

                            <div className="mt-2">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className=''>
                                        <label htmlFor="entryFees" className="fs-14 fw-600 text-white">Entry Fees</label>
                                    </div>
                                    <Field name="entryFees" type="number" placeholder="Add EC" 
                                        className="purple-field d-flex align-items-center border-0 ps-3 text-info fs-14 height-45"
                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                                <ErrorMessage component="span" className="error-msg" name="entryFees" />
                            </div>

                            <div className="mt-2">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className=''>
                                        <label htmlFor="bettingPool" className="fs-14 fw-600 text-white">Betting pool</label>
                                    </div>
                                    <Field name="bettingPool" type="number" placeholder="Add EC"
                                        className="purple-field d-flex align-items-center border-0 ps-3 text-info fs-14 height-45"
                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                                <ErrorMessage component="span" className="error-msg" name="bettingPool" />
                            </div>
                            <button type="submit" className="white-flat-btn d-flex fw-bold py-1 px-4 m-auto mt-3" >
                                {this.state.isUpdate ? 'Update' : 'Add'}
                            </button>
                        </Form>
                    </Formik>
                </div>
            );
        } else {
            return (
                <div className="p-3 px-md-5 px-lg-5">
                    <p className='fs-16 fw-700 text-white text-center m-0'>Warning!</p>

                    <p className='fs-14 fw-400 text-white text-center my-3'>
                        There is no active betting statement for this game. Please connect to Espotz admin for more details.
                    </p>
                    <div className='d-flex justify-content-evenly mt-3'>
                        <button className='white-flat-btn fw-bold py-2 px-4' onClick={() => this.props.showOrHideAddStatementModal()}> Close </button>
                    </div>
                </div>
            )
        }

    }
}