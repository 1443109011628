import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
// Components
import Policies from '../components/common/Polices';
import TermsAndConditions from '../components/common/TermsAndConditions';
import Profile from '../components/common/Profile';
import NotFound from '../components/error/NotFound';
import Home from '../components/home/Home';
import Clan from '../components/clan/Clan';
import ClanView from '../components/clan/ClanView';
import TeamView from '../components/team/TeamView';
import EmailVerification from '../components/auth/EmailVerification';
import CreateOrganization from '../components/organization/CreateOrganization';
import OrganizationView from '../components/organization/OrganizationView';
import Organization from '../components/organization/Organization';
import MicroWebsiteConfiguration from '../components/micro-website/configuration/MicroWebsiteConfiguration.js';
import LayoutOne from '../components/micro-website/layouts/LayoutOne';
import LayoutTwo from '../components/micro-website/layouts/LayoutTwo';
import LayoutThree from '../components/micro-website/layouts/LayoutThree';
import Tournaments from '../components/organization/tournaments/Tournaments'
import CreateTournament from '../components/organization/tournaments/CreateTournament.js';
import EntryFeePrizeDetails from '../components/organization/tournaments/EntryFeeForms/EntryFeePrizeDetails';
import TournamentOverview from '../components/organization/tournaments/TournamentOverview.js';
import TournamentParticipant from '../components/organization/tournaments/TournamentParticipant.js';
import ParticipantForm from '../components/organization/tournaments/ParticipantForm.js';
import GeneralSetting from '../components/organization/tournaments/GeneralSetting.js';
import TournamentStreams from '../components/organization/tournaments/TournamentStreams.js';
import RegistrationForm from '../components/organization/tournaments/RegistrationForm.js';
import TournamentSponsor from '../components/organization/tournaments/TournamentSponsor.js';
import TournamentStructure from '../components/organization/tournaments/TournamentStructure.js';
import TournamentStages from '../components/organization/tournaments/TournamentStages.js';
import SlotDetails from '../components/organization/tournaments/forms/SlotDetails.js';
import CancelAndRefund from '../components/organization/tournaments/CancelAndRefund.js';
import PublishTournament from '../components/organization/tournaments/PublishTournament.js';
import FinalStanding from '../components/organization/tournaments/FinalStanding.js';
import FSingleElimination from '../components/organization/tournaments/forms/FSingleElimination.js';
import FBracketGroups from '../components/organization/tournaments/forms/FBracketGroups.js';
import FSimpleStage from '../components/organization/tournaments/forms/FSimpleStage.js';
import DuelBracketGroups from '../components/organization/tournaments/forms/DuelBracketGroups.js';
import DuelDoubleElimination from '../components/organization/tournaments/forms/DuelDoubleElimination.js';
import DuelRoundRobinGroups from '../components/organization/tournaments/forms/DuelRoundRobinGroups.js';
import DuelSingleElimination from '../components/organization/tournaments/forms/DuelSingleElimination.js';
import DisputeManagement from '../components/organization/tournaments/DisputeManagement';
import ChatLobby from '../components/organization/tournaments/ChatLobby';
import BookSlot from '../components/organization/tournaments/brackets/BookSlot';
import Tournament from '../components/tournament/Tournament';
import TournamentHome from '../components/tournament/pages/TournamentHome';
import Matches from '../components/organization/tournaments/overview/matches/Matches.js';
import Score from '../components/organization/tournaments/overview/matches/score/Score';
import ScoreUpdate from '../components/organization/tournaments/overview/matches/score/Update/ScoreUpdate';
import FFlexibleStage from '../components/organization/tournaments/forms/FFlexibleStage';
import Betting from '../components/Betting/Betting';
import Wallet from '../components/Wallet/Wallet';
import TournamentsRating from '../components/organization/ratings/TournamentsRating';
import Rating from '../components/organization/ratings/Rating';
import UserChatLobby from '../components/tournament/pages/UserChatLobby';
import BettingRules from '../components/home/BettingRules';
import Disclaimer from '../components/home/Disclaimer';
import Faqs from '../components/home/Faqs';
import News from '../components/home/News';
import PaymentOptions from '../components/home/PaymentOptions';
import PrivacyPolicy from '../components/home/PrivacyPolicy';
import ResponsibleGaming from '../components/home/ResponsibleGaming';
import SocialMediaPolicy from '../components/home/SocialMediaPolicy';
import TermAndConditions from '../components/home/TermAndConditions';
import MyTournament from '../components/organization/tournaments/MyTournament';
import MyTournamentDetails from '../components/organization/tournaments/MyTournamentDetails';
import DenProfilePage from '../components/Den/userDenSection/DenProfilePage';
import LivePastBettingPage from '../components/Betting/LivePastBettingPage';
import Challenges from '../components/Betting/Challenges';
import PublishStatement from '../components/organization/tournaments/overview/matches/betting/PublishStatement'
import Notification from '../components/Notifications/Notifications'
import MatchBetting from '../components/organization/tournaments/overview/matches/betting/MatchBetting';
import ReportBug from '../components/common/ReportBug';
import RequestFeature from '../components/common/RequestFeature';

// User Den Section 
import Den from '../components/Den/userDenSection/Den';
import DenCreatePost from '../components/Den/userDenSection/denTabs/DenCreatePost';
import DenBlockedUsers from '../components/Den/userDenSection/denTabs/DenBlockedUsers';
import DenFollowRequests from '../components/Den/userDenSection/denTabs/DenFollowRequests';
import DenInnerCircle from '../components/Den/userDenSection/denTabs/DenInnerCircle';
import DenAchievements from '../components/Den/userDenSection/denTabs/DenAchievements';
import DenPostsTab from '../components/Den/userDenSection/denTabs/DenPostsTab';
import DenSearchTab from '../components/Den/userDenSection/denTabs/DenSearchTab';
import DenPostInteractions from '../components/Den/userDenSection/denTabs/DenPostInteractions';

import UserProfilePage from '../components/Den/profilePages/UserProfilePage';
import DenTopFans from '../components/Den/userDenSection/common/DenTopFans';
import FollowersAndFollowings from '../components/Den/userDenSection/common/FollowersAndFollowings';
import DenTV from '../components/TV/DenTV';
import DenPostLiveStream from '../components/TV/DenPostLiveStream';
import ClanOrOrgWallet from '../components/Wallet/ClanOrOrgWallet';

class AuthRoutes extends Component {

    constructor(props) {
        super(props);
        this.state = props.state;
    }

    render() {
        return (
            <Switch>
                {/* Home */}
                <Route exact path="/" render={(props) => (this.state.emailVerificationToken ? <EmailVerification showOrHideNav={this.props.showOrHideNav} {...props} /> : <Home openLogin={this.props.openLogin} isAuthenticated={this.state.isAuthenticated} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />)} />
                <Route exact path="/home" render={(props) => <Home openLogin={this.props.openLogin} isAuthenticated={this.state.isAuthenticated} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                <Route exact path="/bettingRules" render={(props) => <BettingRules updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/disclaimer" render={(props) => <Disclaimer updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/faqs" render={(props) => <Faqs updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/news" render={(props) => <News updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/paymentOptions" render={(props) => <PaymentOptions updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/privacyPolicy" render={(props) => <PrivacyPolicy updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/responsibleGaming" render={(props) => <ResponsibleGaming updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/socialMediaPolicy" render={(props) => <SocialMediaPolicy updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/termAndConditions" render={(props) => <TermAndConditions updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                {/* Clan */}
                <Route exact path="/clans" render={(props) => <Clan logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/clan/view/:id" render={(props) => <ClanView logout={this.props.logout} joinTeamAfterCreation={this.props.joinTeamAfterCreation} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/clan/team/view/:id" render={(props) => <TeamView logout={this.props.logout} parentUpdate={this.props.parentUpdate} joinTeamAfterCreation={this.props.joinTeamAfterCreation} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                
                {/* Organization */}
                <Route exact path="/organizations" render={(props) => <Organization state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/create" render={(props) => <CreateOrganization logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />"
                <Route exact path="/organization/view/:id" render={(props) => <OrganizationView logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />"
                <Route exact path="/organization/micro-website/configuration/:id" render={(props) => <MicroWebsiteConfiguration logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/micro-website/layoutOne" render={(props) => <LayoutOne updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/micro-website/layoutTwo" render={(props) => <LayoutTwo updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/micro-website/layoutThree" render={(props) => <LayoutThree updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
               
                {/* Tournament */}
                <Route exact path="/tournaments" render={(props) => <Tournament clearInvitations={this.props.clearInvitations} state={this.state} logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournaments/:type?" render={(props) => <Tournaments logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/create/:id?" render={(props) => <CreateTournament logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />"
                <Route exact path="/organization/:orgId/tournament/:id/overview" render={(props) => <TournamentOverview logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/publish-tournament" render={(props) => <PublishTournament updateLoader={this.props.updateLoader} logout={this.props.logout} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/cancel-and-refund" render={(props) => <CancelAndRefund updateLoader={this.props.updateLoader} logout={this.props.logout} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournaments-ratings" render={(props) => <TournamentsRating logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />"
                <Route exact path="/organization/tournament/ratings/:id" render={(props) => <Rating logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />"
                <Route exact path="/tournaments/my-tournaments" render={(props) => <MyTournament clearInvitations={this.props.clearInvitations} state={this.state} logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/tournaments/my-tournaments/:id" render={(props) => <MyTournamentDetails clearInvitations={this.props.clearInvitations} state={this.state} logout={this.props.logout} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                <Route exact path="/organization/:orgId/tournament/:id/overview/participant" render={(props) => <TournamentParticipant updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure" render={(props) => <TournamentStructure updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                <Route exact path="/organization/:orgId/tournament/:id/overview/matches/:type" render={(props) => <Matches updateLoader={this.props.updateLoader} location={this.props.location} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/match/:type/:matchId/score" render={(props) => <Score updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                
                <Route exact path="/organization/:orgId/tournament/:id/overview/match/:type/:matchId/betting" render={(props) => <MatchBetting showOrHideNav={this.props.showOrHideNav} {...props} />} />
                
                <Route exact path="/organization/:orgId/tournament/:id/overview/matches/:type/:matchId/score/update/:gameNo" render={(props) => <ScoreUpdate updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/match/:type/:matchId/score/publish-statement/:gameId" render={(props) => <PublishStatement updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                <Route exact path="/organization/:orgId/tournament/:id/overview/sponsors" render={(props) => <TournamentSponsor updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/entry-fee-prize-details" render={(props) => <EntryFeePrizeDetails updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/streams" render={(props) => <TournamentStreams showOrHideNav={this.props.showOrHideNav} {...props} updateLoader={this.props.updateLoader} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/final-standing" render={(props) => <FinalStanding showOrHideNav={this.props.showOrHideNav} {...props} updateLoader={this.props.updateLoader} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/registration-form" render={(props) => <RegistrationForm showOrHideNav={this.props.showOrHideNav} {...props} updateLoader={this.props.updateLoader} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/general-setting" render={(props) => <GeneralSetting updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/participant-form" render={(props) => <ParticipantForm updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/chatLobby/:matchId" render={(props) => <ChatLobby updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/dispute-management/:matchId?" render={(props) => <DisputeManagement updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages" render={(props) => <TournamentStages updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/ffa-single-elimination" render={(props) => <FSingleElimination updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/ffa-bracket-groups" render={(props) => <FBracketGroups updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/ffa-simple-stage" render={(props) => <FSimpleStage updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/ffa-flexible-stage" render={(props) => <FFlexibleStage updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/duel-bracket-groups" render={(props) => <DuelBracketGroups updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/duel-double-elimination" render={(props) => <DuelDoubleElimination updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/duel-round-robin-group" render={(props) => <DuelRoundRobinGroups updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/duel-single-elimination" render={(props) => <DuelSingleElimination updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/:index/book-slot" render={(props) => <BookSlot updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />"


                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/:index/slot-details/:groupIndex" render={(props) => <SlotDetails updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/tournament/:id" render={(props) => <TournamentHome updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/tournament/:id/chatlobby/:type/:matchId" render={(props) => <UserChatLobby updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                {/* Betting */}
                <Route exact path="/betting" render={(props) => <Betting state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/livePastBetting" render={(props) => <LivePastBettingPage state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/bettingChallenges" render={(props) => <Challenges state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />



                {/* -------------- DEN START------------------- */}


                {/* CLAN AND ORG*/}
                <Route exact path="den/:denId/top-fans" render={(props) => <DenTopFans storeCollector={() => this.props.storeCollector(null, null)} state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} discordProfile={this.props.discordProfile} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/clan/wallet/:id" render={(props) => <ClanOrOrgWallet state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/wallet/:id" render={(props) => <ClanOrOrgWallet state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                

                {/* USER */}
                <Route exact path="/den/:denId" render={(props) => <Den state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/create-post/:draftPostId" render={(props) => <DenCreatePost state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/search" render={(props) => <DenSearchTab state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/posts" render={(props) => <DenPostsTab state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/achievements" render={(props) => <DenAchievements state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/inner-circle" render={(props) => <DenInnerCircle state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/follow-request" render={(props) => <DenFollowRequests state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/blocked-users" render={(props) => <DenBlockedUsers state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/feed/:type/:id" render={(props) => <DenPostInteractions state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                
                <Route exact path="/den-profile" render={(props) => <DenProfilePage storeCollector={() => this.props.storeCollector(null, null)} state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} discordProfile={this.props.discordProfile} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/profile-page/:currentUserDenId" render={(props) => <UserProfilePage storeCollector={() => this.props.storeCollector(null, null)} state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} discordProfile={this.props.discordProfile} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/profile-page/:currentUserDenId/:followType" render={(props) => <FollowersAndFollowings storeCollector={() => this.props.storeCollector(null, null)} state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} discordProfile={this.props.discordProfile} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                {/* DEN TV */}
                <Route exact path="/den/:denId/live-streaming" render={(props) => <DenTV state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/live-streaming/:postId" render={(props) => <DenPostLiveStream state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                {/* -------------- DEN END------------------- */}

                {/* Wallet */}
                <Route exact path="/wallet" render={(props) => <Wallet state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                
                {/* Notifications */}
                <Route exact path="/notifications" render={(props) => <Notification state={this.state} logout={this.props.logout} openPolicies={this.props.openPolicies} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                {/* Common */}
                <Route exact path="/profile" render={(props) => <Profile storeCollector={() => this.props.storeCollector(null, null)} updateLoader={this.props.updateLoader} discordProfile={this.props.discordProfile} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} logout={this.props.logout} {...props} />} />
                <Route exact path="/report-bug" render={(props) => <ReportBug storeCollector={() => this.props.storeCollector(null, null)} updateLoader={this.props.updateLoader} discordProfile={this.props.discordProfile} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} logout={this.props.logout} {...props} />} />
                <Route exact path="/request-feature" render={(props) => <RequestFeature storeCollector={() => this.props.storeCollector(null, null)} updateLoader={this.props.updateLoader} discordProfile={this.props.discordProfile} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} logout={this.props.logout} {...props} />} />
                <Route exact path="/policies" render={(props) => <Policies updateLoader={this.props.updateLoader} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/terms-and-conditions" render={(props) => <TermsAndConditions updateLoader={this.props.updateLoader} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/pageNotFound" render={(props) => <NotFound updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route render={(props) => <NotFound updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
            </Switch>
        );
    }
}

export default AuthRoutes;