import { Component } from 'react';

// Images
import latestResultIc from "../../../images/icon/LatestResult-ic.png";
import participantAvtar from "../../../images/participantAvtar.jpg";
import clipBoardList from "../../../images/icon/clipBoardList-ic.png";
import greenChat from "../../../images/icon/greenChat-ic.png";
import { DropdownButton, Dropdown, Button, ButtonGroup, SplitButton } from 'react-bootstrap';

// icons
import { MdArrowBackIosNew } from 'react-icons/md';
import MatchesApis from '../../../helper/matches.api';
import { getMonthAndDate } from '../../../utils/common.utils';
import { profile } from '../../../utils/localstorage.utils';

import stages from "../../../images/icon/stages.png";
import DuelsSingleEliminationMatches from './matches/brackets/duel/DuelsSingleEliminationMatches';
import DuelsDoubleEliminationMatches from './matches/brackets/duel/DuelsDoubleEliminationMatches';
import DuelsRoundRobinMatches from './matches/brackets/duel/DuelsRoundRobinMatches';
import DuelsSingleBracketGroups from './matches/brackets/duel/DuelsSingleBracketGroups';
import DuelsDoubleBracketGroups from './matches/brackets/duel/DuelsDoubleBracketGroups';
import FFASingleEliminationMatches from './matches/brackets/ffa/FFASingleEliminationMatches';
import FFASimpleStageMatches from './matches/brackets/ffa/FFASimpleStageMatches';
import FFABracketGroupMatches from './matches/brackets/ffa/FFABracketGroupMatches';
import FFAFlexibleStageMatches from './matches/brackets/ffa/FFAFlexibleStageMatches';
import Scoreboard from './Scoreboard';



export default class StageMatches extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectMatch: true,
            tournamentDetails: this.props?.tournamentDetails ? this.props.tournamentDetails : null,
            stageIndex: this.props?.stageIndex ? this.props.stageIndex : null,
            tournamentId: this.props?.tournamentId ? this.props.tournamentId : null,
            stageDetails: this.props?.stageDetails ? this.props.stageDetails : null,
            isMatchDetailsAdded: true,
            groupIndex: 0,
            roundIndex: 1,
            selectedMatches: [],
            matchDetails: null,
            inMatchRole: '',
            showMatchDetails: false
        }
        this.MatchesApis = new MatchesApis()
    }

    componentDidMount() {
        // console.log("_________________________________________________________");
        // console.log("stageIndex :: ", this.props.stageIndex);

        // console.log("tournamentId :: ", this.props.tournamentId);

        if ((this.props.stageIndex || this.props.stageIndex === 0) && this.props.tournamentId && this.props.stageDetails) {
            // when we get response set isMatchDetailsAdded: true
            this.setState({ stageDetails: this.props.stageDetails });
            this.getStageDetails(this.props.tournamentId, this.props.stageIndex)
        }

        // console.log("stageDetails :: ", this.props.stageDetails);
        // console.log("_________________________________________________________");
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            tournamentDetails: newProps?.tournamentDetails ? newProps.tournamentDetails : this.state.tournamentDetails,
            stageIndex: newProps?.stageIndex ? newProps.stageIndex : this.state.stageIndex,
            tournamentId: newProps?.tournamentId ? newProps.tournamentId : this.state.tournamentId,
            //stageDetails: newProps?.stageDetails ? newProps.stageDetails : this.state.stageDetails,
        });
    }

    componentDidUpdate(previous) {
        if (previous.stageIndex !== this.props.stageIndex) {
            this.setState({ stageDetails: this.props.stageDetails });
            this.getStageDetails(this.props.tournamentId, this.props.stageIndex)
        }
    }

    getStageDetails = (tournamentId, stageIndex) => {
        this.MatchesApis.getStageDetails(tournamentId, stageIndex).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false)
                let selectedMatches = res.data.result.groups[this.state.groupIndex][this.state.roundIndex];
                
                this.setState({ stageDetails: res.data.result, selectedMatches: selectedMatches })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false)
            }
        )
    }

    getMatchesStructure = () =>{
        if(this.state.stageDetails){
            if(this.state.stageDetails.type === "DUEL"){
                switch (this.state.stageDetails.subType) {
                    
                    case 'Single Elimination':
                        return <DuelsSingleEliminationMatches setGetAlertMethod={this.props.setGetAlertMethod} index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
    
                    case 'Double Elimination':
                        return <DuelsDoubleEliminationMatches setGetAlertMethod={this.props.setGetAlertMethod} index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} groupIndex={this.state.groupIndex} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
                    
                    case 'Round Robin Groups':
                        return <DuelsRoundRobinMatches index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} groupIndex={this.state.groupIndex} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
                        
                    default:
                        if(this.state.stageDetails.type  === "DUEL" && this.state.stageDetails.subType === "Bracket Groups" && this.state.stageDetails.bracketType === "DOUBLE"){
                            return <DuelsDoubleBracketGroups setGetAlertMethod={this.props.setGetAlertMethod} index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} groupIndex={this.state.groupIndex} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />                        
                        }else{
                            return <DuelsSingleBracketGroups setGetAlertMethod={this.props.setGetAlertMethod} index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} groupIndex={this.state.groupIndex} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
                        }
                }
            }else{
                switch (this.state.stageDetails.subType) {
                    case 'Single Elimination':
                        return <FFASingleEliminationMatches index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
                    
                    case 'Simple Stage':
                        return <FFASimpleStageMatches index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
                     
                    case 'FFA Bracket Groups' :
                        return <FFABracketGroupMatches index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
                    
                    default:
                        return <FFAFlexibleStageMatches index={this.props.stageIndex} tournamentDetails={this.state.tournamentDetails} stageDetails={this.state.stageDetails} history={this.props.history} alterView={this.alterView} showMatchDetails={this.state.showMatchDetails} />
                }
            }
        }


    }

    alterView = () => { 
        this.setState({showMatchDetails: !this.state.showMatchDetails})
    }
 
    render() {
        return (
            <section className='tournament-upcoming-matches text-white'>
                 <span className='d-flex ms-4'>
                    <img src={stages} className='' alt='' style={{ height: '20px', width: '16px' }} /><h1 className='fs-16 fw-700 ms-2'>{this.state.stageDetails?.name}</h1>
                </span>

                <div className='container'>
                    {this.state.showMatchDetails ? <Scoreboard /> : this.getMatchesStructure()}
                </div>
            </section>
        )
    }
}
