import React, { Component } from 'react';
import { FiEdit } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import TournamentApis from '../../../helper/tournament.api';
import { errorHandler, getTournamentData } from '../../../utils/common.utils';
import { toast } from 'material-react-toastify';
import settingIc from '../../../images/icon/setting-ic.png';
import TournamentHeader from './common/TournamentHeader';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { HiOutlineTrash } from "react-icons/hi";
import ConfirmationModal from '../../popup/ConfirmationModal';
import { mandatoryRules } from '../../../utils/common.utils';

class GeneralSetting extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isDisabled: false,
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : null,
            currentRule: '',
            rulesArray: props?.tournamentData?.rules?.length ? props?.tournamentData.rules : [],
            currentRuleIndex: null,
            openConfirm: false,
            confirm: {
                msg: '',
                key: ''
            }
        };
        this.tournamentApis = new TournamentApis;
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
    }


    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
                rulesArray: values.rules
            })
            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }
    ruleDeleteMsg = () => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50">
                Are you sure you want to delete this rule ?
            </p>
        );
    };

    onClickOpenConfirm = (key?, msg?) => {
        const data = {
            msg: msg ? msg : "",
            key: key ? key : "",
        };
        this.setState({
            openConfirm: !this.state.openConfirm,
            confirm: data,
        });
    };
    editRules = () => {
        this.setState({
            isDisabled: false
        })
    }
    addRule = () => {
        let data = this.state.rulesArray;
        data.push(this.state.currentRule)
        this.setState({ rulesArray: data, currentRule: '', toggleRule: false, currentRuleIndex: null })
    }
    updateRule = () => {
        let data = this.state.rulesArray;
        data[this.state.currentRuleIndex] = this.state.currentRule
        this.setState({ rulesArray: data, currentRule: '', toggleRule: false, currentRuleIndex: null })
    }
    deleteRule = (i) => {
        let data = this.state.rulesArray;
        data.splice(i, 1)
        this.setState({ rulesArray: data, openConfirm: false })
    }
    onSubmit = () => {
        var val = {
            rules: this.state.rulesArray
        }
        this.props.updateLoader(true);
        this.tournamentApis.updateTournaments(this.props.match.params.id, val)
            .then(
                (res: AxiosResponse) => {
                    this.props.updateLoader(false);
                    this.props.dispatchData(res.data.result)
                    toast.success("Tournament rules updated successfully");
                    this.props.history.push({
                        pathname: `/organization/${res.data.result.organizationId}/tournament/${this.props.match.params.id}/overview`
                    });
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )

    }

    render() {

        return (
            <div className="body-section tournament-rule-page">
                <div className="container p-3 mb-5 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={settingIc} heading="Rules" subHeading={null} history={this.props.history} />
                    {/* Rules */}
                    <h1 className='fs-16 fw-600 text-white my-3'>Rules</h1>
                    {this.state.rulesArray?.length?
                    <div className='purple-field d-flex align-items-center border-0 pt-3 px-1 text-white-50 fs-14'>
                        <ul className='ps-4 py-2'>
                            {
                                this.state.rulesArray?.map((el, i) => {
                                    return (
                                        <li key={i} className='fw-400'>{el}
                                            <span>
                                                <FiEdit className="text-white mx-2" onClick={() => { this.setState({ currentRuleIndex: i, currentRule: el, toggleRule: true }) }} />
                                                <HiOutlineTrash className="text-white" onClick={() => { this.onClickOpenConfirm(i, this.ruleDeleteMsg()) }} />
                                            </span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>:null
                }
                    <div className="col-lg-6 col-md-6 col-12 black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-3" onClick={(e) => { e.preventDefault() }}>
                        <button className="btn" onClick={() => { this.setState({ toggleRule: true }) }}>
                            <AiFillPlusCircle className="btn-size-25 text-white" />
                        </button>
                    </div>
                    {/* Mandatory Rules */}
                    <h1 className='fs-16 fw-600 text-white my-3'>Mandatory Rules</h1>
                    <div className='purple-field d-flex align-items-center border-0 pt-3 px-1 text-white-50 fs-14'>
                        <ul className='fw-500 ps-4 py-2'>
                            {
                                mandatoryRules.map((el, i) => {
                                    return (
                                        <li className='fw-400' key={i}>{el}</li>    
                                    )
                                })
                            }
                        </ul>
                    </div>


                    
                </div>
                <div className="position-fixed w-100 text-center" style={{bottom:'60px'}}>
                        <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-45" type="button">
                            <BsArrowRight className='fs-24' onClick={this.onSubmit} />
                        </button>
                    </div>
                <Modal show={this.state.toggleRule} centered dialogClassName="tournament-rule-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <h4 className='fs-16 text-white fw-600 text-center'>{this.state.currentRuleIndex !== null ? 'Update Rule' : 'Add Rule'}</h4>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => { this.setState({ toggleRule: false, currentRuleIndex: null, currentRule: '' }) }} />
                            <textarea className="martinique-card fs-12 fw-600 p-3 mt-3 text-white w-100" rows="4" maxLength="500" value={this.state.currentRule} onChange={(e) => { this.setState({ currentRule: e.target.value }) }}/>
                            {
                                this.state.currentRuleIndex !== null ?
                                    <button disabled={!this.state.currentRule} className="white-flat-btn w-25 fw-bold p-1 d-block m-auto mt-3" type="button" onClick={this.updateRule}>Update</button>
                                    :
                                    <button disabled={!this.state.currentRule} className="white-flat-btn w-25 fw-bold p-1 d-block m-auto mt-3" type="button" onClick={this.addRule}>Add</button>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openConfirm} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={this.state.confirm}
                            onClickOpenConfirm={this.onClickOpenConfirm}
                            confirmed={this.deleteRule}
                        />
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(GeneralSetting)