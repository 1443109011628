import { Component } from 'react';
import "../../styles/points.css";
import { getWebData } from '../../utils/common.utils';

export default class Policies extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
    }

    this.websiteData = getWebData();

  }

  render() {

    const privacyPolicies = this.websiteData?.STATIC_PAGE?.PRIVANCY_POLICY?.SET_UP_PRIVACY_POLICY?.split("&&");
    
    return(
 
    <section className="body-section">      
      <div className="p-3 d-flex flex-column mx-auto notifications" >
        <div className="row text-center">
            <div className="col-12">
              <h1 className="fs-20 fw-bold text-white mb-2 mt-3">Privacy Policy</h1>
            </div>
        </div>
        <div className='row '>
          <ol className='text-white'>
            {privacyPolicies?.map((policy,i)=>{
              return <li key={i}>{policy}</li>
            })}
          </ol>
        </div>
      </div>
    </section>
    );
  }
}