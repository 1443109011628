import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class WalletApi {
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    getWalletInfo = (params, type? ) => {
        if (type) {
            const searchString = this.queryParams(params);
            return this.init().get(`/private/api/v1/transactions/getWalletInfo/${params}?transactionType=${type}`);
        }
        return this.init().get(`/private/api/v1/transactions/getWalletInfo/${params}`);
    };

    getWalletInfo1 = (id, params) => {
        const searchString = this.queryParams(params);
        return this.init().get(`/private/api/v1/transactions/getWalletInfo/${id}?${searchString}`);
    };

    walletDeposit = (data) => {
        return this.init().post(`/private/api/v1/transactions/depositEC`, data); 
    };

    walletWithdraw = (data) => {
        return this.init().post(`/private/api/v1/transactions/withdrawEC`, data); 
    };

    makeWalletPayment = (data) => {
        return this.init().post(`/private/api/v1/walletPayments/makeWalletPayment`, data); 
    };

    
}       