import React, { Component } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import { BiLock } from 'react-icons/bi';
import { FaGlobeAsia } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import bioIcon from '../../../../images/icon/bio-icon.png';
import avatar from '../../../../images/participantAvtar.jpg';
import { getMonthYear } from '../../../../utils/dateTime.utils';
import { getDenProfileAchivements, getDenProfilePicture } from '../../../../utils/Den.utils';
import { USER_DEN_STATS } from '../../../../utils/enums.utils';
import { PROFILE_PAGE_IMAGES } from '../../../../utils/Images.utils';
import { DEN } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';
import DenSocialMedia from '../../../common/DenSocialMedia';
import Image from '../../../common/Image';
import ImageGrid from '../../../common/ImageGrid';
import SideSkeleton from '../../../common/SideSkeleton';
import InnerCircle from './InnerCircle';

export default class UserLeftTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUserDenProfile: props.currentUserDenProfile,
            denAchivements: props.denAchivements,
            showInnerCircle: false
        }
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({ 
            currentUserDenProfile: nextProps.currentUserDenProfile,
            denAchivements: nextProps.denAchivements
        });
    }

    updateState = (obj) => {
        this.setState(obj)
    }

    showHideBioFlag = () => {
        const { currentUserDenProfile} = this.state;
        switch (currentUserDenProfile.type) {
            case DEN.SEARCH.TYPES.USER:
                return currentUserDenProfile?.userDetails?.verificationStatus === 'VERIFIED' ?  true : false;
            case DEN.SEARCH.TYPES.CLAN:
                return currentUserDenProfile?.clanDetails?.verificationStatus === 'VERIFIED' ?  true : false;
            case DEN.SEARCH.TYPES.ORG:
                return currentUserDenProfile?.orgDetails?.verificationStatus === 'VERIFIED' ?  true : false;
            default:
                return currentUserDenProfile?.userDetails?.verificationStatus === 'VERIFIED' ?  true : false;
        }
    }

    getMoreStats = () => {
        const { currentUserDenProfile, denAchivements} = this.state;
        const achivementsUsers = denAchivements?.achivements?.map((achive)=> {return achive.from});

        const topFansUsers = denAchivements?.topFans?.map((achive)=> {return achive.from})

        const innerCircle = denAchivements?.topFans?.filter((user)=> user.totalSpotlights > 0 ); //?.slice(0, 10);
        const sortedInnerCircle  = innerCircle?.sort((a, b) => parseInt(b.totalSpotlights) - parseInt(a.totalSpotlights));
        const innerCircleProfiles = sortedInnerCircle?.map((topFan)=>{
            return {
                from: topFan.from,
                profilePicture: getDenProfilePicture(topFan.from),
                spotlight: topFan.totalSpotlights
            };
        })
        return(
            <div className="p-0">
                {/* PUBLIC STATS */}
                <div className='d-flex align-items-center'>
                    <p className='fs-16 fw-bold text-white-50 my-0'>Stats</p>
                    <FaGlobeAsia className='mx-2' />
                </div>
                <div className='d-flex justify-content-between align-items-center w-100 my-2'>
                    {/* MATCHES */}
                    <div className={(this.props.ownDenProfile || currentUserDenProfile?.publicStatus[USER_DEN_STATS.MATCHES_PLAYED]) ? 'column-center-align-div' : 'd-none'} style={{color: '#FF11E7'}}>
                        <img src={PROFILE_PAGE_IMAGES.MATCHES} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.totalMatches? denAchivements.totalMatches : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>TOTAL</span>
                    </div>
                    {/* WINS */}
                    <div className={(this.props.ownDenProfile || currentUserDenProfile?.publicStatus[USER_DEN_STATS.MATCHES_WON]) ? 'column-center-align-div' : 'd-none'} style={{color: '#49FF2C'}}>
                        <img src={PROFILE_PAGE_IMAGES.WINS} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.totalWins? denAchivements.totalWins : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>WINS</span>
                    </div>
                    {/* MVP */}
                    <div className={(this.props.ownDenProfile || currentUserDenProfile?.publicStatus[USER_DEN_STATS.MVP_COUNT]) ? 'column-center-align-div' : 'd-none'} style={{color: '#1CADFF'}}>
                        <img src={PROFILE_PAGE_IMAGES.MVP} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.totalMVP? denAchivements.totalMVP : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>TIMES</span>
                    </div>
                    {/* STARS */}
                    <div className={true ? 'column-center-align-div' : 'd-none'} style={{color: '#E2782C'}}>
                        <img src={PROFILE_PAGE_IMAGES.STAR} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.stars? denAchivements.stars : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>STARS</span>
                    </div>
                    {/* SPOTLIGHT */}
                    <div className={(this.props.ownDenProfile || currentUserDenProfile?.publicStatus[USER_DEN_STATS.SPOTLIGHT_COLLECTION]) ? 'column-center-align-div' : 'd-none'} style={{color: '#FFE000'}}>
                        <img src={PROFILE_PAGE_IMAGES.SPOTLIGHTS} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>{denAchivements?.totalSpotLights? denAchivements.totalSpotLights : 0}</span>
                        <span className='fs-12 fw-600 opacity-50'>SPOTLIGHT</span>
                    </div>
                </div>
                <p className='fs-16 fw-700 cursor-pointer text-white-50' onClick={()=> this.props.checkStatStatus('showGameStats', true)}>{`Click here to see detailed game stats >`}</p>

                <div className='row'>
                    <div className='col-12'>
                        <div className='profile-stats-1 d-flex justify-content-around align-items-center p-2'>
                            {/* Achievements */}
                            <div className='cursor-pointer' onClick={() =>this.props.toggleAchievementsPopup(true, DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY)}>
                                <p className='fs-14 fw-700 opacity-75 my-0'>Achievements</p>
                                {achivementsUsers.length ? <ImageGrid users={achivementsUsers} /> : <p className='fs-12 fw-600 text-center my-0'>N/A</p>}
                            </div>
                            <hr className='vertical-hr-line' />
                            {/* Top Fans */}
                            <div className='cursor-pointer' onClick={() =>this.props.toggleAchievementsPopup(true, DEN.ACHIEVEMENTS.TYPE.TOP_FAN.KEY)}>
                                <p className='fs-14 fw-700 opacity-75 my-0'>Top Fans</p>
                                {topFansUsers.length ? <ImageGrid users={topFansUsers} /> : <p className='fs-12 fw-600 text-center my-0'>N/A</p>}
                            </div>
                        </div>
                    </div>
                    <div className='col-6 my-2'>
                        <div className='profile-stats-2 p-2 cursor-pointer' style={{minHeight: '80px'}} onClick={() => innerCircleProfiles.length ? this.updateState({showInnerCircle: true}) : null}>
                            {/* <NavLink to={`/den/${this.state.currentUserDenProfile?._id}/inner-circle`} style={{color: 'white'}}> */}
                                <p className='fs-14 fw-700 opacity-75 my-0 text-center'>Inner Circle</p>
                                {innerCircleProfiles.length ? <ImageGrid users={innerCircle.map(user => user.from)} /> : <p className='fs-12 fw-600 text-center my-0'>N/A</p>}
                            {/* </NavLink> */}
                        </div>
                    </div>
                    <div className='col-6 my-2'>
                        <div className='profile-stats-3 p-2' style={{minHeight: '80px'}}>
                            <p className='fs-14 fw-700 opacity-75 my-0 text-center'>Profile Rank</p>
                            <p className='fs-16 fw-700 text-center' style={{color: '#00FFD1'}}>#{denAchivements?.profileRank? denAchivements.profileRank : null}</p>
                        </div>
                    </div>
                </div>

                {/* PRIVATE STATS */}
                <div className='d-flex align-items-center'>
                    <p className='fs-16 fw-bold text-white-50 my-0'>{`Tournaments & Bets`}</p>
                    <BiLock className='mx-2' />
                </div>
                <div className='d-flex justify-content-around align-items-center w-100 my-2'>
                    {/* TOURNAMENTS */}
                    <div className={currentUserDenProfile?.privateStatus[USER_DEN_STATS.TOURNAMENT_COUNT] ? 'column-center-align-div' : 'd-none'} style={{color: '#1CADFF'}}>
                        <img src={PROFILE_PAGE_IMAGES.FLAG} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>450</span>
                        <span className='fs-12 fw-600 opacity-50'>TOTAL</span>
                    </div>
                    {/* BETS */}
                    <div className={currentUserDenProfile?.privateStatus[USER_DEN_STATS.BETTING_STATS] ? 'column-center-align-div' : 'd-none'} style={{color: '#66FFA3'}}>
                        <img src={PROFILE_PAGE_IMAGES.BETS} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>36</span>
                        <span className='fs-12 fw-600 opacity-50'>BETS</span>
                    </div>
                    {/* WINS */}
                    <div className={currentUserDenProfile?.privateStatus[USER_DEN_STATS.BETTING_STATS] ? 'column-center-align-div' : 'd-none'} style={{color: '#49FF2C'}}>
                        <img src={PROFILE_PAGE_IMAGES.WINS} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>166</span>
                        <span className='fs-12 fw-600 opacity-50'>WINS</span>
                    </div>
                    {/* WIN-RATIO */}
                    <div className={currentUserDenProfile?.privateStatus[USER_DEN_STATS.BETTING_STATS] ? 'column-center-align-div' : 'd-none'} style={{color: '#FFA149'}}>
                        <img src={PROFILE_PAGE_IMAGES.WINRATIO} alt='' className='' style={{width:'32px' }} />
                        <span className='fs-12 fw-600'>3.2</span>
                        <span className='fs-12 fw-600 opacity-50'>W/R</span>
                    </div>
                </div>
                
                {/* CLANS */}
                <div className='my-2'>
                    <label className='fs-20 fw-600'>Clans</label>
                    
                    <div className='my-3'>
                        {currentUserDenProfile?.myClan?.map((clan, i) => {
                            
                            return (
                                <div className='den-clan-container' key={i}>
                                    <div className='d-flex align-items-center mb-2'>
                                        <p className='fs-16 fw-600 my-0 flex-grow-1'>Clan #{i+1}</p>
                                    </div>
                                    <p className='fs-14 fw-600'>Name: {clan.name}</p>
                                    {clan.roles?.map(((role, j) => {
                                        return (
                                            <div className="d-flex align-items-start my-0" key={j}>
                                                <div className='d-flex flex-column align-items-center my-0'>
                                                    <div className='clan-dot-container'></div>
                                                    <div className='ver-line my-1' style={{display : (j+1 === clan.roles?.length) ? 'none' : 'block'}}></div>
                                                </div>
                                                <div className='mx-2 my-0'>
                                                    <p className='fs-14 fw-600 my-0'>{role.role}</p>
                                                    <p className='fs-10 fw-600 my-0'>{getMonthYear(role.startDate)} - {role.endDate !== '' ? getMonthYear(role.endDate) : 'Now' }</p>
                                                </div>
                                            </div>
                                        )
                                    }))}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* ORGANIZATIONS */}
                <div className='my-2'>
                    <label className='fs-20 fw-600'>My Organizations</label>
                    <div className='my-3'>
                        {currentUserDenProfile?.myOrganization?.map((org, i) => {
                            return (
                                <div className='den-clan-container' key={i}>
                                    <div className='d-flex align-items-center mb-2'>
                                    <p className='fs-16 fw-600 my-0 flex-grow-1'>Organization #{i+1}</p>
                                    </div>
                                    <p className='fs-14 fw-600'>Name: {org.name}</p>
                                    {org.roles?.map(((role, j) => {
                                        return (
                                            <div className="d-flex align-items-start my-0" key={j}>
                                                <div className='d-flex flex-column align-items-center my-0'>
                                                    <div className='org-dot-container'></div>
                                                    <div className='ver-line-org my-1' style={{display : (j+1 === org.roles?.length) ? 'none' : 'block'}}></div>
                                                </div>
                                                <div className='mx-2 my-0'>
                                                    <p className='fs-14 fw-600 my-0'>{role.role}</p>
                                                    <p className='fs-10 fw-600 my-0'>{getMonthYear(role.startDate)} - {role.endDate !== '' ? getMonthYear(role.endDate) : 'Now' }</p>
                                                </div>
                                            </div>
                                        )
                                    }))}

                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* BROADCASTING CHANNELS */}
                <DenSocialMedia socialMedia={currentUserDenProfile?.socialMedia} />
                <Modal centered show={this.state.showInnerCircle}>
                    <InnerCircle updateState={this.updateState} currentUserDenProfile={currentUserDenProfile} innerCircleProfiles={innerCircleProfiles} />
                </Modal>
            </div>
        )
    }


    render() {
        const { currentUserDenProfile, denAchivements} = this.state;
        if(currentUserDenProfile && denAchivements){

        const showBioFlag = this.showHideBioFlag();
        
        return (
            <div className='col-12 col-lg-3 my-1'>
                <div className='profile-card-bg p-2' style={STYLE.DEN.PROFILE_CARD}>
                    <div className='d-flex align-items-center'>
                        <img src={bioIcon} alt='bio' className='me-3' style={{width:'20px'}} />
                        <p className='fs-12 fs-lg-16 fw-600 my-0 flex-grow-1'>Bio</p>
                        <Image src={PROFILE_PAGE_IMAGES.BIOFLAG} param='BIO' alt='star' className='me-3' style={{width:'26px', display: showBioFlag ? 'block' : 'none'}} />
                    </div>
                    {/* BIO */}
                    <p className='fs-14 fw-400 text-white-50'>{currentUserDenProfile?.bio}</p>
                    <section className='den-achievements d-block d-lg-none'>
                        <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className='d-flex align-items-center p-0 my-0 flex-grow-1'>
                                    <p className='fs-14 fw-bold my-0' style={{color:'#AD26FF'}}>More Details</p>
                                    <hr className='flex-grow-1 mx-2'style={{color:'#AD26FF', height:'2px'}} />
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className='p-1'>
                                {this.getMoreStats()}
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </section>
                    <div className='p-o d-none d-lg-block'>{this.getMoreStats()}</div>
                </div>
            </div>
            
        )}else{
            return (
                <div className='col-12 col-lg-3 my-1'>
                    <SideSkeleton />;
                </div>
            )
        }
    }
}