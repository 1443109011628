import { Component } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../styles/activate-account.css";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "material-react-toastify";
import {capitalize} from '../../utils/common.utils';
import instagram from '../../images/games/instagram.png';
import discord from '../../images/games/discord.png';
import loader from '../../images/common/Loader.png';
import {
  FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, LineShareButton,
  FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LineIcon
} from "react-share";
import { MdOutlineContentCopy } from "react-icons/md";

class ClanInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectInvite: true,
      selectedRole: (this.props.inviteType === 'clan') ? (this.props.isOwner ? "owner" : "admin") : this.props.inviteAs,
      invitationLink: null,
      inviteType: this.props.inviteType
    };
  }

  linkSectionContinue = () => {
    const baseUrl = window.location.hostname === 'localhost' ? 'https://localhost:3000' : window.location.origin;

    let invitationLink;
    if (this.props.inviteType === 'clan') {
      invitationLink = `${baseUrl}/?joinClanId=${this.props.clanId}&role=${this.state.selectedRole}`;
    } else {
      invitationLink = `${baseUrl}/?joinTeamId=${this.props.teamId}&role=${this.state.selectedRole}`;
    }
    this.setState({
      invitationLink: invitationLink,
    });
    const selectInvite = this.state.selectInvite;
    this.setState({
      selectInvite: !selectInvite,
    });
  };

  onRoleChangeHandler = (e) => {
    this.setState({
      selectedRole: e.target.name,
    });
  };

  copyLinkToClipBoard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.invitationLink);
    toast.success("Invitation link has been copied to the clipboard.");
  }

  renderSocailShare = () => {
    let inviteText = `Join Espotz ${this.props.inviteType} named ${this.props.name} as ${this.state.selectedRole} using this link :`;

    return (
      <div>
        <div className="d-flex justify-content-center my-2">
          <div className="d-flex align-items-center w-50 mb-0">
            <p className="flex-grow-1 bg-white my-auto" style={{height:'1px'}}></p>
            <p className="fs-14 fw-normal text-white-50 mx-2 my-0">OR</p>
            <p className="flex-grow-1 bg-white my-auto" style={{height:'1px'}}></p>
          </div>
        </div>
        <div className="text-center">

          <WhatsappShareButton className="px-1" url={this.state.invitationLink} quote={inviteText} title={inviteText}>
            <WhatsappIcon size={"2rem"} round={true} />
          </WhatsappShareButton>

          <FacebookShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
            <FacebookIcon size={"2rem"} round={true} />
          </FacebookShareButton>

          <TelegramShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
            <TelegramIcon size={"2rem"} round={true} />
          </TelegramShareButton>

          <TwitterShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
            <TwitterIcon size={"2rem"} round={true} />
          </TwitterShareButton>

          {/* <TwitterShareButton className="px-1">
            <img src={discord} alt='' sizes={'2rem'} round={true} />
          </TwitterShareButton>

          <TwitterShareButton className="px-1">
            <img src={instagram} alt='' sizes={'2rem'} round={true} />
          </TwitterShareButton> */}

          <TwitterShareButton className="px-1">
            <img src={loader} alt='' style={{height:'28px'}} />
          </TwitterShareButton>
        </div>
      </div>
    )
  }
  render() {
    return (
      <>
        <div className="p-3">
          {
            this.props.clanId ?
              <IoIosCloseCircleOutline className="close-icon position-absolute" style={{right:'25px',top:'20px'}} onClick={() => { this.props.onClick() }} />
              :
              <IoIosCloseCircleOutline className="close-icon position-absolute" style={{right:'25px',top:'20px'}} onClick={() => { this.props.onClick('openClanInvite') }} />
          }

          <p className="fs-20 fw-600 text-center text-white mb-0">Invite</p>
          <p className="fs-12 fw-500 text-center text-white-50 mb-2">Step {this.state.selectInvite ? 1 : 2} of 2</p>
          {(this.props.inviteType === 'clan') ?
            <div>
              {this.state.selectInvite ? (

                <>
                  <div id="invite-section">
                    <p className="fw-600 fs-14 text-center text-white-50 mt-4 mb-1">
                      Please select the role you would like to invite
                    </p>
                    <form>
                      <Dropdown className="mb-2 select-game-dropdown">
                        <Dropdown.Toggle className='dropdown-basic w-100 text-start ps-3'>
                          {capitalize(this.state.selectedRole)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu onClick={(e) => this.onRoleChangeHandler(e)}>
                          {
                            this.props.isOwner && <Dropdown.Item name="owner" className="text-white">Owner</Dropdown.Item>
                          }
                          <Dropdown.Item name="admin" className="text-white">Admin</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" type="submit" onClick={this.linkSectionContinue}>
                        Continue
                      </button>
                    </form>
                  </div>
                </>
              ) : (
                <div id="link-section">
                  <BsArrowLeftShort className="position-absolute btn-size-32 text-white" style={{left:'10px', top:'15px'}} onClick={this.linkSectionContinue} />
                  <p className="fw-600 fs-12 text-center text-white-50 mt-4">You can Invite the {`${this.state.selectedRole} `}
                    for this clan by sharing the given link below.</p>
                  <p className="fs-16 fw-600 text-white mb-1">Copy Link</p>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      autoComplete="off"
                      className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                      placeholder="https://espotz.online/#Heree"
                      value={this.state.invitationLink}
                      onChange={(e) => { }}
                      style={{ paddingRight:'35px' }}
                    />
                      <MdOutlineContentCopy className="text-white me-3 position-absolute" style={{right:'10px'}} onClick={(e) => this.copyLinkToClipBoard(e)}/>
                  </div>
                  {this.renderSocailShare()}
                </div>
              )}
            </div>
            :
            <div>
              {this.state.selectInvite ?
                <div id="invite-section">
                  <p className="fw-600 fs-14 text-center text-white-50 mt-4 mb-1">Please select the role you would
                    like to invite</p>
                  <form>
                    <Dropdown className="select-game-dropdown">
                      <Dropdown.Toggle className="dropdown-basic w-100 text-start ps-3">
                        {capitalize(this.state.selectedRole)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu onClick={(e) => this.onRoleChangeHandler(e)}>
                        <Dropdown.Item name="players">Player</Dropdown.Item>
                        <Dropdown.Item name="substitutes">Substitute</Dropdown.Item>
                        <Dropdown.Item name="manager">Manager</Dropdown.Item>
                        <Dropdown.Item name="coach">Coach</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" type="submit" onClick={this.linkSectionContinue}>
                      Continue
                    </button>
                  </form>
                </div>
                :
                <div id="link-section">
                  <BsArrowLeftShort className="position-absolute text-white btn-size-25" style={{top:'20px', left:'20px'}} onClick={this.linkSectionContinue} />
                  <p className="fw-600 fs-12 text-center text-white-50 mt-4">You can Invite the {`${this.state.selectedRole} `}
                    for this {this.state.inviteType} by sharing the given link below.</p>
                  <p className="fs-16 fw-600 text-white mb-1">Copy Link</p>
                  <div className="d-flex align-items-center ">
                    <input
                      type="text"
                      autoComplete="off"
                      className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                      placeholder="https://espotz.online/#Heree"
                      value={this.state.invitationLink}
                      onChange={(e) => { }}
                      style={{ paddingRight:'35px' }}
                    />
                    <MdOutlineContentCopy className="text-white me-3 position-absolute" style={{right:'10px'}} onClick={(e) => this.copyLinkToClipBoard(e)} />
                  </div>
                  {this.renderSocailShare()}
                </div>
              }
            </div>
          }

        </div>
      </>
    );
  }
}

export default ClanInvite;
