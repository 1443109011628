import React, { Component } from 'react';

import { AiOutlineClose } from 'react-icons/ai'


import { IoIosArrowBack } from 'react-icons/io';

import bombInline from '../../../images/den/interactions/bomb-inline.png';
import user from '../../../images/den/create-post/user.png';
import { POST_INTERACTIONS } from '../../../utils/Images.utils';
import { getDenPostById, getDenProfiles, getPostCommentedUsersByID } from '../../../utils/Den.utils';
import Image from '../../common/Image';
import LeftSideBar from '../../Den/userDenSection/common/LeftSideBar';
import RightSideBar from '../../Den/userDenSection/common/RightSideBar';
import { DEN } from '../../../utils/Strings.utils';
import { getDetailsObj } from '../../../utils/common.utils';

export default class DenInteractions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interactionType: 'ALL',
            reactedProfiles: null,
            filteredProfiles: [],
            allUsers: null,
            activeTab: DEN.TABS.HOME,
            denProfile: null,
            detailsObj: null
        };
    }


    componentDidMount(){
        if(this.props.denId){
            getDenProfiles({profiles:[this.props.denId ]},this.setCurrentUserDenProfile)
    
        }
    }

    setCurrentUserDenProfile = (result) =>{
        if(result[0]){
            this.setState({
                denProfile: result[0]
            });
            if(this.props.id){
                if(this.props.type === "post"){
                    console.log(" this.props.type :: ",this.props.type)
                    getDenPostById(this.props.id, this.setPostDetails);
                }else{
                    getPostCommentedUsersByID(this.props.id, this.setDenPostCommentProfiles);
                }
    
            }
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }
    
    setDenPostCommentProfiles = (result) =>{
        var allUsers = [];
        {Object.keys(result).map((key) => { allUsers = allUsers.concat(result[key])})}
        console.log("allUsers :: ",allUsers)
        this.setState({
            reactedProfiles: result,
            filteredProfiles: allUsers
        });
    }



    setPostDetails = (postToInteract) =>{
        console.log("\n\n\n\n\n result :: ",postToInteract)

        var allUsers = [];
        {Object.keys(POST_INTERACTIONS.SPARKS).map((key) => { allUsers = allUsers.concat(postToInteract.likes[POST_INTERACTIONS.SPARKS[key].NAME])})}
        console.log("allUsers :: ",postToInteract.likes)
        this.setState({
            reactedProfiles: postToInteract.likes,
            allUsers: allUsers
        });

        getDenProfiles({profiles: allUsers},this.setDenProfiles);
    }

    setDenProfiles = (profiles) => {
        this.setState({
            filteredProfiles: profiles
        });
    }

    updateInteractionType = (val) => {
        if(val === 'ALL'){
            getDenProfiles({profiles: this.state.allUsers},this.setDenProfiles);
        }else{
            getDenProfiles({profiles: this.state.reactedProfiles[val]},this.setDenProfiles);
        }
        this.setState({ interactionType: val });
    }

    getView = () =>{
        const {interactionType} = this.state;
        const normalSearch = {fontSize: '16px', fontWeight: 400, color: 'white'};
        const activeSearch = {fontSize: '16px', fontWeight: 400, color: '#AD26FF', borderBottom: '1px solid #AD26FF'};
        return(
                <div className='post-card-bg p-2'>
                    <div className='row px-2 my-2'>
                        <div className='col-2 text-white'>
                            <IoIosArrowBack onClick={()=>this.props.close()} style={{height:'20px', width:'20px', cursor:'pointer'}} />
                        </div>
                        <div className='col-8'>
                            <p className='fs-16 text-center text-white fw-700 my-0'>Interactions</p>
                        </div>
                        <div className='col-2 text-end  text-white'>
                            <AiOutlineClose onClick={()=>this.props.close()} style={{height:'20px', width:'20px', cursor:'pointer'}} />
                        </div>
                    </div>
               
                    {/* Search Type */}
                    <div className='d-flex my-0 overflow-scroll'>
                        <span className='cursor-pointer p-2 flex-shrink-0' style={ interactionType === 'ALL' ? activeSearch : normalSearch } onClick={() => this.updateInteractionType('ALL')}>All {this.state.allUsers?.length}</span>
                        
                        {Object.keys(POST_INTERACTIONS.SPARKS).map((key, i) => {
                            return (
                                <span className='d-flex align-items-center cursor-pointer px-3 py-2' style={ interactionType === POST_INTERACTIONS.SPARKS[key].NAME ? activeSearch : normalSearch } onClick={() => this.updateInteractionType(POST_INTERACTIONS.SPARKS[key].NAME)} key={i}>
                                    <img src={POST_INTERACTIONS.SPARKS[key].LOGO} alt={POST_INTERACTIONS.SPARKS[key].NAME} style={{height: '24px' }} className='me-2' />
                                    {this.state.reactedProfiles ? this.state.reactedProfiles[POST_INTERACTIONS.SPARKS[key].NAME]?.length : 0}
                                </span>
                            )
                        })}
                        
                    </div>
                    <hr className='my-0' />
                    {/* Search Results */}
                    <div className='d-flex flex-column overflow-auto my-3' style={{height: '500px'}}>
                        
                        {this.state.filteredProfiles.map((profile, i) => {
                            return (
                                <div className='' key={i}>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex' key={i}>
                                            <Image param={profile.name} src={profile?.profilePicture?.url}  alt='' className='rounded-circle' style={{ height:'32px', width:'32px' }}/>
                                            {this.getInteractionLogo((this.state.interactionType === "ALL"),profile._id)}
                                            {/* <img src={this.state.interactionType === "ALL" ? this.getInteractionLogo(profile._id): POST_INTERACTIONS.SPARKS[this.state.interactionType.toUpperCase()].LOGO} alt='' className='rounded-circle mt-auto' style={{ height:'16px', width:'16px', marginLeft:'-10px'}} /> */}
                                        </div>
                                        <div className='flex-grow-1 mx-2'>
                                            <p className='fs-16 fw-600 my-0 text-white'>{profile.name}</p>
                                            <spam className='fs-12 text-white-50 fw-500'>Gamer</spam>
                                        </div>
                                        <img src={user} alt='' className='' style={{height:'auto', width:'12px'}} />
                                    </div>
                                    <hr className='hr-line my-1' />
                                </div>
                            )
                        })}

                    </div>
                </div>
                  
          )
         
    }

    getInteractionLogo = (isShowAll, id) => {
        

        if(isShowAll){

            var logo = Object.keys(this.state.reactedProfiles).map((key, i) =>{

                // console.log("reactedProfiles :: ",this.state.reactedProfiles[key])
     
                 if(this.state.reactedProfiles[key].includes(id)){
     
                 console.log("id :: ",id)
     
                 console.log("key :: ",POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO)
     
                    //return POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO;
                    return <img src={POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO} alt='' className='rounded-circle mt-auto' style={{ height:'16px', width:'16px', marginLeft:'-10px'}} />

                 }
     
                 console.log("_______________________________________________")
            });

            return logo;

        }else{
            return <img src={POST_INTERACTIONS.SPARKS[this.state.interactionType.toUpperCase()].LOGO} alt='' className='rounded-circle mt-auto' style={{ height:'16px', width:'16px', marginLeft:'-10px'}} />

        }

                                        
        
    

        //return bombInline;
    }

    getCommentsInteractionsView = () =>{
        const {interactionType} = this.state;
        const normalSearch = {fontSize: '16px', fontWeight: 400, color: 'white'};
        const activeSearch = {fontSize: '16px', fontWeight: 400, color: '#AD26FF', borderBottom: '1px solid #AD26FF'};
        return(

            <div className='post-card-bg p-2'>
                    <div className='row px-2 my-2'>
                        <div className='col-2'>
                            <IoIosArrowBack onClick={()=>this.props.close()} style={{height:'20px', width:'20px', cursor:'pointer'}} />
                        </div>
                        <div className='col-8 text-white'>
                            <p className='fs-16 text-center text-white fw-700 my-0'>Interactions</p>
                        </div>
                        <div className='col-2 text-end text-white'>
                            <AiOutlineClose onClick={()=>this.props.close()} style={{height:'20px', width:'20px', cursor:'pointer'}} />
                        </div>
                    </div>
                    
                    {/* Search Type */}
                    <div className='d-flex my-0 overflow-scroll'>
                        <span className='cursor-pointer p-2 flex-shrink-0' style={ interactionType === 'ALL' ? activeSearch : normalSearch } onClick={() => this.updateInteractionType('ALL')}>All {this.state.allUsers?.length}</span>
                        
                        {Object.keys(POST_INTERACTIONS.SPARKS).map((key, i) => {
                            return (
                                <span className='d-flex align-items-center cursor-pointer px-3 py-2' style={ interactionType === POST_INTERACTIONS.SPARKS[key].NAME ? activeSearch : normalSearch } onClick={() => this.updateInteractionType(POST_INTERACTIONS.SPARKS[key].NAME)} key={i}>
                                    <img src={POST_INTERACTIONS.SPARKS[key].LOGO} alt={POST_INTERACTIONS.SPARKS[key].NAME} style={{height: '24px' }} className='me-2' />
                                    {this.state.reactedProfiles ? this.state.reactedProfiles[POST_INTERACTIONS.SPARKS[key].NAME]?.length : 0}
                                </span>
                            )
                        })}
                        
                    </div>
                    <hr className='my-0' />
                    {/* Search Results */}
                    <div className='d-flex flex-column overflow-auto my-3' style={{height: '500px'}}>
                        
                        {this.state.filteredProfiles.map((profile, i) => {
                            return (
                                <div className='' key={i}>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex' key={i}>
                                            <Image param={profile.name} src={profile?.profilePicture?.url}  alt='' className='rounded-circle' style={{ height:'32px', width:'32px' }}/>
                                            <img src={this.state.interactionType === "ALL" ? bombInline: POST_INTERACTIONS.SPARKS[this.state.interactionType.toUpperCase()].LOGO} alt='' className='rounded-circle mt-auto' style={{ height:'16px', width:'16px', marginLeft:'-10px'}} />
                                        </div>
                                        <div className='flex-grow-1 mx-2'>
                                            <p className='fs-16  text-white fw-600 my-0'>{profile.name}</p>
                                            <spam className='fs-12 text-white-50 fw-500'>Gamer</spam>
                                        </div>
                                        <img src={user} alt='' className='' style={{height:'auto', width:'12px'}} />
                                    </div>
                                    <hr className='hr-line my-1' />
                                </div>
                            )
                        })}

                    </div>
                </div>
                  

          )
         
    }

    render() {
        if(this.props.type === "post"){
            return this.getView();
        }else{
            return this.getCommentsInteractionsView();
        }
    }
}

