import React, { Component } from 'react';
import { AiFillCopyrightCircle } from "react-icons/ai";


export default class TournamentEntryDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            myTeamDetails: props.myTeamDetails,
            teamDetails: props.teamDetails,
            tournamentDetails: props.tournamentDetails
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            myTeamDetails: newProps.myTeamDetails,
            teamDetails: newProps.teamDetails,
            tournamentDetails: newProps.tournamentDetails
        });
    }

    getArray = () => {
        var arr = [];
        if (this.state.tournamentDetails?.participantType === 'TEAM'){
            let {members} = this.state.myTeamDetails;
            //console.log('captain present ---',this.state.myTeamDetails?.members?.captain);
            //console.log('players present ---',this.state.myTeamDetails?.members?.players && this.state.myTeamDetails?.members?.players?.length > 0);
            //console.log('coach present ---',this.state.myTeamDetails?.members?.players && this.state.myTeamDetails?.members?.players?.length > 0);
            //console.log('manager present ---',this.state.myTeamDetails?.members?.manager && this.state.myTeamDetails?.members?.manager?.length > 0);
            if (members.captain){
                arr.push(members.captain)
            }
            if(this.state.myTeamDetails?.members?.players && this.state.myTeamDetails?.members?.players?.length > 0){
                arr= [...arr, ...members.players]
            }
            if(this.state.myTeamDetails?.members?.coach && this.state.myTeamDetails?.members?.coach?.length > 0){
                arr= [...arr, ...members.coach]
            }
            if(this.state.myTeamDetails?.members?.manager && this.state.myTeamDetails?.members?.manager?.length > 0){
                arr= [...arr, ...members.manager]
            }
            //console.log('members array :::', arr);

        }
        return arr;
    }

    render() {
        let membersArray = this.getArray();

        return (
            <div>
                {(this.state.teamDetails === 'rooster') &&
                    <div className='col-12'>

                        <div className="reAssign-section">
                            <h5 className="mt-3 mb-4 fs-16 fs-lg-20 fw-600">Team Line-up</h5>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className="fs-14 fs-lg-18 fw-600 text-white mb-1">PLAYERS</h6>
                                <hr className='mx-2 flex-grow-1 opacity-100' />
                            </div>
                            <div className="row mt-1">
                                {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-3'>
                                        <div className='mulberry-purple-card d-flex align-items-center py-2'>
                                            <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                                <img className="rounded-circle btn-size-32 border border-1 mx-3" src={this.state.myTeamDetails?.profilePicture?.url} alt="owner-avatar" />
                                                {this.state.myTeamDetails?.inGameName}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className='col-12 col-md-6 col-lg-4 mb-3'>
                                            {this.state.myTeamDetails?.members?.captain &&
                                                <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2'>
                                                    <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                                        <img className="rounded-circle btn-size-32 border border-1 mx-3" src={this.state.myTeamDetails?.members?.captain?.profilePicture?.url} alt="owner-avatar" />
                                                        {this.state.myTeamDetails?.members?.captain?.inGameName}
                                                    </div>
                                                    <div className="col-2">
                                                        <AiFillCopyrightCircle className='btn-size-20' />
                                                    </div>
                                                </div>
                                            }

                                            {(this.state.myTeamDetails?.members?.players && this.state.myTeamDetails?.members?.players?.length > 0) &&
                                                this.state.myTeamDetails?.members?.players.map((player, i) => {
                                                    if (player !== null){
                                                        return (
                                                            <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2' key={i}>
                                                                <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                                                    <img className="rounded-circle btn-size-32 border border-1 mx-3" src={player.profilePicture?.url} alt="owner-avatar" />
                                                                    {player?.inGameName}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className='d-flex align-items-center mt-3'>
                                            <h6 className="fs-14 fs-lg-18 fw-600 text-white mb-1">COACH & MANAGERS</h6>
                                            <hr className='mx-2 flex-grow-1 opacity-100' />
                                        </div>
                                        <div className="row mt-1">
                                            {/* <div className='col-12 col-md-6 col-lg-4 mb-3'>
                                                <div className="mulberry-purple-card d-flex align-items-center py-2">
                                                    <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                                        <img className="rounded-circle btn-size-32 border border-1 mx-3" src={DefaultImage} alt="owner-avatar" />
                                                        John Snow
                                                    </div>
                                                </div>
                                            </div> */}
                                            {(this.state.myTeamDetails?.members?.coach && this.state.myTeamDetails?.members?.coach?.length > 0) &&
                                                this.state.myTeamDetails?.members?.coach.map((player, i) => {
                                                    return (
                                                        <div className='col-12 col-md-6 col-lg-4 mb-2' key={i}>
                                                        <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2' key={i}>
                                                            <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                                                <img className="rounded-circle btn-size-32 border border-1 mx-3" src={player.profilePicture?.url} alt="owner-avatar" />
                                                                {player?.inGameName}
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {(this.state.myTeamDetails?.members?.manager && this.state.myTeamDetails?.members?.manager?.length > 0) &&
                                                this.state.myTeamDetails?.members?.manager.map((player, i) => {
                                                    return (
                                                        <div className='col-12 col-md-6 col-lg-4 mb-2' key={i}>
                                                        <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2' key={i}>
                                                            <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                                                <img className="rounded-circle btn-size-32 border border-1 mx-3" src={player.profilePicture?.url} alt="owner-avatar" />
                                                                {player?.inGameName}
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {(this.state.teamDetails === 'formDetails') &&
                <div className="row mt-1">
                    {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                    <div className="col-lg-4 col-md-6 col-12 mb-5">
                        <div className="participant-form border rounded-10  m-3 pt-4 px-3">
                            <div className="d-flex justify-content-center">
                                <img src={this.state.myTeamDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-47px" }} alt="" />
                                <h4 className="mt-3">{this.state.myTeamDetails?.inGameName}</h4>
                            </div>
                            <form className="px-3 pb-3">
                                <div className=" my-1">
                                    <label className="fs-14 fw-600 text-white mb-1">Real Name</label>
                                    <input
                                        disabled type="text" 
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                        value={this.state.myTeamDetails?.name}
                                    />
                                </div>

                                <div className=" my-1">
                                    <label className="fs-14 fw-600 text-white mb-1">Email address</label>
                                    <input 
                                        disabled type="email"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                        value={this.state.myTeamDetails?.email}
                                    />
                                </div>

                                <div className=" my-1">
                                    <label className="fs-14 fw-600 text-white mb-1">In-Game Name</label>
                                    <input 
                                        disabled type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                        value={this.state.myTeamDetails?.inGameName}
                                    />
                                </div>

                                <div className=" my-1">
                                    <label className="fs-14 fw-600 text-white mb-1">In-Game ID</label>
                                    <input 
                                        disabled type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                        value={this.state.myTeamDetails?.inGameId}
                                    />
                                </div>

                                <div className=" my-1">
                                    <label className="fs-14 fw-600 text-white mb-1">Mobile Number</label>
                                    <input 
                                        disabled type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                        value={this.state.myTeamDetails?.mobileNo}
                                    />
                                </div>

                                <div className=" my-1">
                                    <label className="fs-14 fw-600 text-white mb-1">Discord ID</label>
                                    <input 
                                        disabled type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                        value={this.state.myTeamDetails?.inDiscordId}
                                    />
                                </div>

                                <div className=" my-1">
                                    <label className="fs-14 fw-600 text-white mb-1">Custom Detail</label>
                                    <input disabled type="text" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" />
                                </div>
                            </form>
                        </div>
                    </div>
                    :
                    <div>
                        {membersArray.length > 0 &&
                           membersArray?.map((member, i) => {
                               console.log('array member ----',member);
                               if (member !== null){
                               return(
                                <div className="col-lg-4 col-md-6 col-12 mb-5" key={i}>
                                <div className="participant-form border rounded-10  m-3 pt-4 px-3">
                                    <div className="d-flex justify-content-center">
                                        <img src={member.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-47px" }} alt="" />
                                        <h4 className="mt-3">{member?.inGameName}</h4>
                                    </div>
                                    <form className="px-3 pb-3">
                                        <div className=" my-1">
                                            <label className="fs-14 fw-600 text-white mb-1">Real Name</label>
                                            <input
                                                disabled type="text" 
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={member?.name}
                                            />
                                        </div>
        
                                        <div className=" my-1">
                                            <label className="fs-14 fw-600 text-white mb-1">Email address</label>
                                            <input 
                                                disabled type="email"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={member?.email}
                                            />
                                        </div>
        
                                        <div className=" my-1">
                                            <label className="fs-14 fw-600 text-white mb-1">In-Game Name</label>
                                            <input 
                                                disabled type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={member?.inGameName}
                                            />
                                        </div>
        
                                        <div className=" my-1">
                                            <label className="fs-14 fw-600 text-white mb-1">In-Game ID</label>
                                            <input 
                                                disabled type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={member?.inGameId}
                                            />
                                        </div>
        
                                        <div className=" my-1">
                                            <label className="fs-14 fw-600 text-white mb-1">Mobile Number</label>
                                            <input 
                                                disabled type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={member?.mobileNo}
                                            />
                                        </div>
        
                                        <div className=" my-1">
                                            <label className="fs-14 fw-600 text-white mb-1">Discord ID</label>
                                            <input 
                                                disabled type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={member?.inDiscordId}
                                            />
                                        </div>
        
                                        <div className=" my-1">
                                            <label className="fs-14 fw-600 text-white mb-1">Custom Detail</label>
                                            <input disabled type="text" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" />
                                        </div>
                                    </form>
                                </div>
                                </div>
                               )
                            }
                           }) 
                        }
                    </div>}
                </div>  
                    
                }
            </div>
        )
    }
}
