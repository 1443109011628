import React, { Component } from "react";
import "../../../../../styles/brackets.css";
import "../../../../../styles/Tournaments.css";
import TournamentHeader from "../../common/TournamentHeader";
import structureIc from "../../../../../images/icon/stageImg.png";
import swapIc from "../../../../../images/icon/swap-ic.png";
import timestampIc from "../../../../../images/icon/timestamp-ic.png";

import { Modal } from "react-bootstrap";
import { HiUserAdd } from "react-icons/hi";
import { errorHandler } from "../../../../../utils/common.utils";
import MatchInvite from "../../../../popup/MatchInvite";
import { toast } from "material-react-toastify";
import RoundRobinRounds from "./RoundRobinRounds";
import { FaLayerGroup } from "react-icons/fa";
import TournamentApis from "../../../../../helper/tournament.api";
import { IoIosCheckbox } from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';

export default class RoundRobin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.state.tournamentDetails,
      structure: props.state.structure,
      scale: 1.1,
      openInvite: false,
      matchId: -1,
      selectedPlayers: [],
      isSwappingEnabled: false,
      displayRounds: false,
      groupMatches: null,
      selectedGroupe: 0,
      selectedPhase: 1
    };

    this.isSwapped = false;
    this.prevValue = 0;
    this.marginTopDiv = 35;
    this.maxWidth = 0;

    this.tournamentApis = new TournamentApis();

  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      tournamentDetails: newProps.state.tournamentDetails,
      structure: newProps.state.structure,
    });
  }

  zoom = (key) => {
    if (this.state.scale * 1.1 === 1.1) {
      this.maxWidth = 0;
    }
    switch (key) {
      case "In":
        this.setState({ scale: this.state.scale * 1.1 });
        break;
      case "Out":
        this.maxWidth =
          this.maxWidth === 0
            ? this.maxWidth + 10
            : this.maxWidth + this.maxWidth + 1;
        this.setState({ scale: this.state.scale / 1.1 });
        break;
      default:
        this.setState({ scale: 1.1 });
        break;
    }
  };

  calculateMatches = (num, threshold) => {
    var stages = 1;
    var arr = [num];
    while (num !== 1) {
      num = num / 2;
      arr.push(num);
      stages++;
      if (num === threshold) break;
    }
    return arr;
  };

  onClickOpenInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite,
    });
  };

  openInviteModal = (e, groupIndex) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      openInvite: true,
      matchId: groupIndex,
    });
  };

  enableDisableSwapping = () => {
    this.setState({
      isSwappingEnabled: !this.state.isSwappingEnabled,
      selectedPlayers: [],
    });
  };

  handleInputChange = (event, groupIndex, player, isChecked) => {

    var selectedPlayers = this.state.selectedPlayers;
    if (isChecked) {
      const target = event.target;
      const playerID = target.id;

      if (selectedPlayers.length == 2) {
        toast.warning("At a time only two players can be swapped");
        target.checked = false;
      } else {
        let obj = {
          groupIndex: groupIndex,
          playerID: player._id
        };

        selectedPlayers.push(obj);
        this.setState({
          selectedPlayers: selectedPlayers
        });
        target.checked = true;
      }

    } else {
      selectedPlayers = selectedPlayers.filter(function (obj) {
        return obj.playerID !== player._id;
      });
      this.setState({
        selectedPlayers: selectedPlayers
      });

    }


  };

  swapPositions = () => {
    this.isSwapped = true;
    let data = {
      tournamentId: this.props.id,
      stageIndex: parseInt(this.props.index),
      sourceGroupIndex: this.state.selectedPlayers[0].groupIndex,
      sourceParticipantId: this.state.selectedPlayers[0].playerID,
      targetGroupIndex: this.state.selectedPlayers[1].groupIndex,
      targetParticipantId: this.state.selectedPlayers[1].playerID
    }

    if (data.sourceGroupIndex === this.state.selectedPlayers[1].groupIndex) {
      toast.warning("You can not swipe two players within a same group.");
    } else {
      this.isSwapped = true;
      console.log("---- swapPositions :: ", data)

      this.props.updateLoader(true);
      this.tournamentApis.swapParticipantsBetweenGroups(data)
        .then((res: AxiosResponse) => {

          console.log("res : ", res.data.result)
          var tournamentDetails = this.state.tournamentDetails;
          tournamentDetails.stages[this.props.index].groups[this.state.selectedPlayers[0].groupIndex] = res.data.result.sourceGroup;
          tournamentDetails.stages[this.props.index].groups[this.state.selectedPlayers[1].groupIndex] = res.data.result.targetGroup;


          this.setState({
            tournamentDetails: tournamentDetails,
            selectedPlayers: [],
            isSwappingEnabled: false
          });
          this.props.updateLoader(false);
          toast.success("Participants Swapped Successfully");
        })
        .catch((err: AxiosError) => {
          this.props.updateLoader(false);
          errorHandler(err);
        });
    }

  };


  hideOrShowRounds = (e, index?) => {
    e.preventDefault();
    if (!this.state.isSwappingEnabled) {
      this.setState({
        selectedGroupe: index,
      });

      var groupMatches;
      if (index !== undefined) {
        groupMatches = this.state.tournamentDetails?.stages[this.props.index].groups[index];
      } else {
        groupMatches = null;
      }
      console.log("GROUP :: ", groupMatches);
      this.setState({
        displayRounds: !this.state.displayRounds,
        groupMatches: groupMatches,
      });
    }
  };

  editTimeSlot = () => {
    this.props.history.push(`/organization/${this.props.orgId}/tournament/${this.props.id}/overview/structure/tournament-stages/${this.props.index}/slot-details/${this.state.selectedGroupe}`);
  };

  getTeamMembers = (groupDetails) => {
    var arr = [];
    Object.keys(groupDetails).map((key) => {
      groupDetails[key].map((obj) => {
        obj.participants.map((team) => { 
          team.resultAdded =  obj.resultAdded;
          arr.push(team) 
        });
      });
    });

    var jsonObject = arr.map(JSON.stringify);
    var uniqueSet = new Set(jsonObject);
    var uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    return uniqueArray;
  };

  isAlreadyExist = (id) => {

    var selectedPlayers = this.state.selectedPlayers;
    var result = false;

    selectedPlayers.forEach((team, i) => {
      if (team.playerID === id) {
        result = true
      }
    });

    return result;

  }

  renderPhaseOptions() {
    const roundRobinType = this.state.tournamentDetails?.stages[this.props.index].roundRobinType;

    console.log("roundRobinType : ", roundRobinType)
    if (roundRobinType !== 'SINGLE') {
      return (
        <div className='d-flex overflow-auto mt-2 justify-content-between'>
          {
            [...Array(roundRobinType === 'DOUBLE' ? 2 : 3)].map((param, i) => {
              const isSelected = this.state.selectedPhase === (i + 1);
              return (
                <button type="button" className="score-showdown-btn3 me-2 w-100" onClick={() => this.setState({ selectedPhase: (i + 1) })} key={i} style={{ opacity: (isSelected ? 1 : 0.5), fontSize: (isSelected ? '' : '15px') }}>
                  Phase {i + 1}
                </button>
              );
            })
          }
        </div>
      );
    }

  }


  render() {

    const stageDetails = this.state.tournamentDetails?.stages[this.props.index];
    const isTeamPlayer = this.state.tournamentDetails?.participantType === "TEAM" ? true : false;
    let stageIndex = parseInt(this.props.index)
    let lastStageThreshold = (stageIndex !== 0) ? parseInt(this.state.tournamentDetails.stages[stageIndex - 1].qualificationThreshold) : 0;
   
    if (stageDetails) {
      return (
        <section className="body-section d-flex flex-column" style={{ paddingBottom: "50px" }}>
          {/* Header Section */}
          <div className="p-2">
            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading={this.state.tournamentDetails.stages[this.props.index].name} subHeading={<h1 className="stage-sub-heading text-center">( Round Robin {this.state.displayRounds ? "Rounds" : ""})</h1>} history={this.props.history} />
            <div className="d-flex overflow-auto mt-2 add-time justify-content-around">
              {this.state.displayRounds ?
                <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={(e) => this.hideOrShowRounds(e)} >
                  <FaLayerGroup className="btn-size-20 me-2 mb-1" />Back to Groups
                </button>
                :
                (this.state.selectedPlayers.length == 2 ?
                  <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.swapPositions}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> Swap Position
                  </button>
                  :
                  <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.enableDisableSwapping}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt=''/> {this.state.isSwappingEnabled ? "Disable Swapping" : "Enable Swapping"}
                  </button>
                )

              }

              <button type="button" onClick={this.editTimeSlot} className="add-btn fs-12 text-black fw-600 rounded-15" >
                <img src={timestampIc} className="btn-size-20 me-2 mb-1" alt='' />{" "}  Edit Time Slot
              </button>

            </div>
          </div>


          {this.state.displayRounds ?
            <div>
              <RoundRobinRounds roundRobinType={stageDetails.roundRobinType} perPhaseMember={(stageDetails.maxParticipants / stageDetails.maxNumberOfGroups)} selectedPhase={this.state.selectedPhase} groupMatches={this.state.groupMatches} updateLoader={this.props.updateLoader} />
            </div>
            :
            <div className="container">
              <div className="row pt-3 px-3">
                {[...Array(stageDetails?.maxNumberOfGroups)].map((elementInArray, index) => {
                  const teamMembers = this.getTeamMembers(stageDetails.groups[index]);
                  const maxParticipantsPerGroup = stageDetails.maxParticipantsPerGroup;
                 
                  return (
                    <div className="col-lg-4 col-12" key={index}>
                     <div className="card mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn h-auto" onClick={(e) => this.hideOrShowRounds(e, index)} style={{ marginLeft: "-5px" }} key={index} >
                        <span className="match-tag align-self-center py-1" style={{ fontSize: "14px" }} > {stageDetails.groupsName[index]}</span>

                        <div className="row px-2 pb-3 w-100">
                          <div className="col-5">
                            <div className="d-flex flex-column">
                              {
                                [...Array((this.state.isSwappingEnabled || maxParticipantsPerGroup<= 4) ? maxParticipantsPerGroup : 4)].map((elementInArray, i) => {
                                  
                                  if (teamMembers[i]) {
                                    var isChecked = this.isAlreadyExist(teamMembers[i]._id);
                                    console.log("--- isChecked :: ", isChecked)
                                    return (
                                      <div className="d-flex align-items-center" key={i}>
                                        {this.state.isSwappingEnabled ?
                                          (isChecked ?
                                            <IoIosCheckbox className="checkedItem" key={`${index}.${i}`} id={`${index}.${i}`} onClick={(e) => this.handleInputChange(e, index, teamMembers[i], false)} />
                                            :
                                            <input className="me-2" type='checkbox' disabled={teamMembers[i]?.resultAdded} id={`${index}.${i}`} onChange={(e) => this.handleInputChange(e, index, teamMembers[i], true)} />
                                          )
                                          :
                                          <img src={isTeamPlayer ? teamMembers[i]?.logo?.url : teamMembers[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt=''/>
                                        }
                                        <span className="player-name my-1 ms-1 text-truncate" key={i}> { isTeamPlayer ? teamMembers[i].name : teamMembers[i].inGameName} </span>
                                      </div>
                                    )
                                  } else {
                                    return (<span className="player-name my-1 vacant" key={i}> Vacant</span>)
                                  }
                                })
                              }
                              <a className={`more-count ${ (this.state.isSwappingEnabled || ((maxParticipantsPerGroup - 4) <= 0)) ? 'd-none': null}`}>+{maxParticipantsPerGroup - 4} more</a>
            
                            </div>
                          </div>

                           <div className="col-2 align-self-center">
                             {(maxParticipantsPerGroup != teamMembers?.length) ?
                               <button disabled={maxParticipantsPerGroup * (index + 1) <= lastStageThreshold} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" onClick={(e) => this.openInviteModal(e, index)} >
                                 <HiUserAdd className="btn-size-15" />
                              </button>
                              :
                               null
                             }
                           </div>
                      </div>
                    </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {/* Tournament Invitation Section */}
          <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal" >
            <Modal.Body>
              <MatchInvite
                onClickOpenInvite={this.onClickOpenInvite}
                tournamentId={this.props.id}
                stageId={this.props.index}
                groupIndex={this.state.matchId}
                gameId={this.state.tournamentDetails?.gameId}
                participantType={this.state.tournamentDetails?.participantType}
                choice="Send Invite"
                isRoundRobin={true}
                isTeamPlayer={isTeamPlayer}
              />
            </Modal.Body>
          </Modal>
        </section>
      );
    } else {
      return <div></div>;
    }

  }
}
