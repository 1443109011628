import { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import TournamentApis from '../../../helper/tournament.api';
import { AxiosResponse, AxiosError } from 'axios';
import {  errorHandler, getMonthAndDate } from '../../../utils/common.utils';
import gameCover from '../../../images/League_of_legends.png';
import participants from "../../../images/participantAvtar.jpg";
import winnerCup from "../../../images/icon/winner-cup.png";
import StarRatingComponent from 'react-star-rating-component';
import { IoArrowBackCircleOutline, IoCalendarClearOutline } from 'react-icons/io5';
import { MdOutlineFullscreenExit } from 'react-icons/md';
import closeCalender from '../../../images/icon/close-calender.png';
import { ASSETS } from '../../../utils/Strings.utils';
import { TournamentStatus } from '../../../utils/enums.utils';
import '../../../styles/PublicTournaments.css'
import '../../../styles/Layout.css'
import TournamentCard from './common/TournamentCard';

export default class MyTournament extends Component {

    constructor(props) {
        super(props);
        this.state = {
            TournamentType: 'ALL',
            myTournaments: [],
            showCalender: false,
        };
        this.tournamentApis = new TournamentApis();
        this.getMyTournaments('ALL');
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    onSelectTournamentType = (e) => {
        this.setState({ TournamentType: e.target.name })
        this.getMyTournaments(e.target.name)
    }

    getMyTournaments = async (val) => {
        let data = {};
        if(val !== 'ALL') {
            data = { tournamentStatus: val };
        }
        if(this.props.location.state?.fromOrg){
            data.organizationId = this.props.location.state.organizationId;
            this.props.updateLoader(true);
            await this.tournamentApis.getTournaments(data).then(
                (res: AxiosResponse) => {
                    this.props.updateLoader(false);
                    this.setState({ myTournaments: res.data.result });
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
        }else if(this.props.location.state?.fromClan){
            data.clanId = this.props.location.state.clanId;
            this.props.updateLoader(true);
            await this.tournamentApis.getClanTournaments(data).then(
                (res: AxiosResponse) => {
                    this.props.updateLoader(false);
                    this.setState({ myTournaments: res.data.result });
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
        }else{
            this.props.updateLoader(true);
            await this.tournamentApis.getMyTournaments(data).then(
                (res: AxiosResponse) => {
                    this.props.updateLoader(false);
                    this.setState({ myTournaments: res.data.result });
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
        }
    }

    toggleCalender = () => this.setState((currentState) => ({ showCalender: !currentState.showCalender }));

    //Static data for cards
    staticDataCards = () => { 
        return(
            <div className='row g-2 mt-3'>
                <div className='col-6 col-md-4 col-lg-2 text-white'>
                    <div className='t-card-premium tournament-card m-lg-2 m-md-2 flex-grow-1 px-2 rounded-10'>
                        <div className="d-flex flex-column h-100 py-2">
                            <div className="game " style={{ backgroundImage: `url(${gameCover})`, height: '100px', backgroundRepeat: 'round' }}>
                                <div className="d-flex justify-content-between w-100 p-1">
                                    <span className="fs-10 text-dark bg-white px-2 py-1 fw-700 rounded-pill">CS:GO</span>
                                    {/* <span className="name"> <AiFillStar style={{ color: '#FFB800' }} /> 4.9</span> */}
                                </div>
                            </div>
                            <div className='t-body-premium px-2'>
                                <h1 className="text-white fs-14 fw-600 text-center my-2">Grand Pixas</h1>
                                <h1 className="text-center fs-10 text-center">By Bluethorns</h1>
                                <div className="d-flex justify-content-around my-4" >
                                    <div className='text-center'>
                                        <img src={winnerCup} className='btn-size-40' alt=''/>
                                    </div>
                                    <div className='text-center'>
                                        <img src={participants} className='btn-size-25 rounded-circle' alt=''/>
                                        <p className="fs-10 text-white-50 fw-600 mb-1" >Jay Kaushik</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1" >
                                    <p className="fs-10 text-white fw-bold mb-1" >Type:</p>
                                    <p className="fs-10 text-white fw-500 mb-1" >Solo</p>
                                </div>
                                <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                                <p className="fs-10 text-white fw-bold mb-1" >From 2 Aug - 3 Aug</p>
                                <div className='text-center'>
                                    <h1 className="text-center fs-10 mt-2 mb-0">Rating</h1>
                                    <StarRatingComponent name="rate1" starCount={5} emptyStarColor="#B4B4B4" editing={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-md-4 col-lg-2 text-white'>
                    <div className='t-card-regular tournament-card m-lg-2 m-md-2 flex-grow-1 px-2 rounded-10'>
                        <div className="d-flex flex-column h-100 py-2">
                            <div className="game " style={{ backgroundImage: `url(${gameCover})`, height: '100px', backgroundRepeat: 'round' }}>
                                <div className="d-flex justify-content-between w-100 p-1">
                                    <span className="fs-10 text-dark bg-white px-2 py-1 fw-700 rounded-pill">CS:GO</span>
                                    {/* <span className="name"> <AiFillStar style={{ color: '#FFB800' }} /> 4.9</span> */}
                                </div>
                            </div>
                            <div className='t-body-regular px-2'>
                                <h1 className="text-white fs-14 fw-600 text-center my-2">Grand Pixas</h1>
                                <h1 className="text-center fs-10 text-center">By Bluethorns</h1>
                                <div className="d-flex justify-content-around my-4" >
                                    <div className='text-center'>
                                        <img src={winnerCup} className='btn-size-40' alt=''/>
                                    </div>
                                    <div className='text-center'>
                                        <img src={participants} className='btn-size-25 rounded-circle' alt=''/>
                                        <p className="fs-10 text-white-50 fw-600 mb-1" >Jay Kaushik</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1" >
                                    <p className="fs-10 text-white fw-bold mb-1" >Type:</p>
                                    <p className="fs-10 text-white fw-500 mb-1" >Solo</p>
                                </div>
                                <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                                <p className="fs-10 text-white fw-bold mb-1" >From 2 Aug - 3 Aug</p>
                                <div className='text-center'>
                                    <h1 className="text-center fs-10 mt-2 mb-0">Rating</h1>
                                    <StarRatingComponent name="rate1" starCount={5} emptyStarColor="#B4B4B4" editing={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render() {
        return (
            <section className='body-section public-tournaments layout1 pb-0' style={{ backgroundColor: '#200c45' }}>
                <div onClick={()=> this.props.history.goBack()}>
                    <IoArrowBackCircleOutline className="back-btn" />
                </div>
                <div className='container p-3 mb-5 mt-lg-5'>
                    <div className='row'>
                        <div className='col-6 offset-3' >
                            <Dropdown className="select-game-dropdown">
                                <Dropdown.Toggle className='fs-14 dropdown-basic w-100 text-start ps-3 height-45'>
                                    {TournamentStatus[this.state.TournamentType]}
                                </Dropdown.Toggle>
                                <Dropdown.Menu onClick={(e) => this.onSelectTournamentType(e)}>
                                    <Dropdown.Item name="ALL" name2="ALL" className="text-white">ALL</Dropdown.Item>
                                    <Dropdown.Item name="ONGOING" name2="LIVE" className="text-white">ONGOING</Dropdown.Item>
                                    <Dropdown.Item name="COMPLETED" name2="COMPLETED" className="text-white">COMPLETED</Dropdown.Item>
                                    <Dropdown.Item name="UPCOMING" name2="UPCOMING" className="text-white">UPCOMING</Dropdown.Item>
                                    <Dropdown.Item name="DRAFT" name2="DRAFT" className="text-white">DRAFT</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {/* <div className='col-3' >
                            <div className='mulberry-purple-card height-45 float-end d-flex justify-content-center align-items-center' style={{ width: '45px' }} onClick={this.toggleCalender}>
                                {this.state.showCalender ?
                                    <img src={closeCalender} className='btn-size-25' alt=''/>
                                    :
                                    <IoCalendarClearOutline className='fs-24' />
                                }
                            </div>
                        </div> */}
                    </div>

                    {this.state.showCalender &&
                        <div className='mulberry-purple-card mt-3 pb-3'>
                            <Dropdown className="bg-transparent mt-3">
                                <Dropdown.Toggle className='bg-transparent border-0 w-100 text-start ps-3 height-45' style={{ textAlignLast: 'center' }}>
                                    Jan 2022
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item name="Feb 2022" className="text-black">Feb 2022</Dropdown.Item>
                                    <Dropdown.Item name="March 2022" className="text-black">March 2022</Dropdown.Item>
                                    <Dropdown.Item name="April 2022" className="text-black">April 2022</Dropdown.Item>
                                    <Dropdown.Item name="May 2022" className="text-black">May 2022</Dropdown.Item>
                                    <Dropdown.Item name="June 2022" className="text-black">June 2022</Dropdown.Item>
                                    <Dropdown.Item name="July 2022" className="text-black">July 2022</Dropdown.Item>
                                    <Dropdown.Item name="August 2022" className="text-black">August 2022</Dropdown.Item>
                                    <Dropdown.Item name="Sept 2022" className="text-black">Sept 2022</Dropdown.Item>
                                    <Dropdown.Item name="Oct 2022" className="text-black">Oct 2022</Dropdown.Item>
                                    <Dropdown.Item name="Nov 2022" className="text-black">Nov 2022</Dropdown.Item>
                                    <Dropdown.Item name="Dec 2022" className="text-black">Dec 2022</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className='px-3'>
                                <table className="table table-borderless text-center">
                                    <thead>
                                        <tr className='text-white-50 fs-10'>
                                            <th className='pe-0 pb-0'>M</th>
                                            <th className='pe-0 pb-0'>T</th>
                                            <th className='pe-0 pb-0'>W</th>
                                            <th className='pe-0 pb-0'>T</th>
                                            <th className='pe-0 pb-0'>F</th>
                                            <th className='pe-0 pb-0'>S</th>
                                            <th className='pe-0 pb-0'>S</th>
                                            <th className='pe-0 pb-0'>M</th>
                                            <th className='pe-0 pb-0'>T</th>
                                            <th className='pe-0 pb-0'>W</th>
                                            <th className='pe-0 pb-0'>T</th>
                                            <th className='pe-0 pb-0'>F</th>
                                            <th className='pe-0 pb-0'>S</th>
                                            <th className='pe-0 pb-0'>S</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='text-white fw-600 fs-10'>
                                            <td className='pe-0 pb-0'>1</td>
                                            <td className='pe-0 pb-0'>2</td>
                                            <td className='pe-0 pb-0'>3</td>
                                            <td className='pe-0 pb-0'>4</td>
                                            <td className='pe-0 pb-0'>5</td>
                                            <td className='pe-0 pb-0'>6</td>
                                            <td className='pe-0 pb-0'>7</td>
                                            <td className='pe-0 pb-0'>8</td>
                                            <td className='pe-0 pb-0'>9</td>
                                            <td className='pe-0 pb-0'>10</td>
                                            <td className='pe-0 pb-0'>11</td>
                                            <td className='pe-0 pb-0'>12</td>
                                            <td className='pe-0 pb-0'>13</td>
                                            <td className='pe-0 pb-0'>14</td>
                                        </tr>
                                        <tr className='text-white fw-600 fs-10'>
                                            <td className='pe-0 pb-0'>15</td>
                                            <td className='pe-0 pb-0'>16</td>
                                            <td className='pe-0 pb-0'>17</td>
                                            <td className='pe-0 pb-0'>18</td>
                                            <td className='pe-0 pb-0'>19</td>
                                            <td className='pe-0 pb-0'>20</td>
                                            <td className='pe-0 pb-0'>21</td>
                                            <td className='pe-0 pb-0'>22</td>
                                            <td className='pe-0 pb-0'>23</td>
                                            <td className='pe-0 pb-0'>24</td>
                                            <td className='pe-0 pb-0'>25</td>
                                            <td className='pe-0 pb-0'>26</td>
                                            <td className='pe-0 pb-0'>27</td>
                                            <td className='pe-0 pb-0'>28</td>
                                        </tr>
                                        <tr className='text-white fw-600 fs-10'>
                                            <td className='pe-0 pb-0'>29</td>
                                            <td className='pe-0 pb-0'>30</td>
                                            <td className='pe-0 pb-0'>31</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='row'>
                                <div className='col-6 ps-4 ps-lg-5' id='tournament-date'>
                                    <p className='fs-12 fw-600 text-warning mb-0'>Wednesday</p>
                                    <p className='fs-20 fw-500 text-white mb-0'>6</p>
                                    <div className='overflow-auto' >
                                        <div className='' style={{ height: '75px' }}>
                                            <p className='fs-12 fw-600 text-danger mb-1'><span className='height-45 bg-danger me-2' style={{ width: '10px' }}>|</span> Grand Pixas</p>
                                            <p className='fs-12 fw-600 text-info mb-1'><span className='height-45 bg-info me-2' style={{ width: '2px' }}>|</span> Di Nectar</p>
                                            <p className='fs-12 fw-600 text-danger mb-1'><span className='height-45 bg-danger me-2' style={{ width: '10px' }}>|</span> Grand Pixas</p>
                                            <p className='fs-12 fw-600 text-info mb-1'><span className='height-45 bg-info me-2' style={{ width: '2px' }}>|</span> Di Nectar</p>
                                            <p className='fs-12 fw-600 text-danger mb-1'><span className='height-45 bg-danger me-2' style={{ width: '10px' }}>|</span> Grand Pixas</p>
                                            <p className='fs-12 fw-600 text-info mb-1'><span className='height-45 bg-info me-2' style={{ width: '2px' }}>|</span> Di Nectar</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 pe-4' id='tournament-date'>
                                    <div className='overflow-auto' >
                                        <div className='' style={{ height: '125px' }}>
                                            <p className='fs-12 fw-600 text-white-50 mb-1'>FRIDAY, 8 MAY</p>
                                            <p className='fs-12 fw-600 text-success mb-1'><span className='height-45 bg-success me-2' style={{ width: '10px' }}>|</span> World Championship X5</p>
                                            <p className='fs-12 fw-600 text-white-50 mb-1 mt-2'>FRIDAY, 8 MAY</p>
                                            <p className='fs-12 fw-600 text-warning mb-1'><span className='height-45 bg-warning me-2' style={{ width: '10px' }}>|</span> Ronnie’s Temp</p>
                                            <p className='fs-12 fw-600 text-info mb-1'><span className='height-45 bg-info me-2' style={{ width: '10px' }}>|</span> Morningstar Valey</p>
                                            <p className='fs-12 fw-600 text-white-50 mb-1 mt-2'>FRIDAY, 8 MAY</p>
                                            <p className='fs-12 fw-600 text-warning mb-1'><span className='height-45 bg-warning me-2' style={{ width: '10px' }}>|</span> Ronnie’s Temp</p>
                                            <p className='fs-12 fw-600 text-info mb-1'><span className='height-45 bg-info me-2' style={{ width: '10px' }}>|</span> Morningstar Valey</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row g-2 mt-3">
                        {/* Tournaments */}
                        {
                            this.state.myTournaments.length ?
                            this.state.myTournaments?.map((tournament, id) => {
                                return (
                                    <div className="col-6 col-md-4 col-lg-2 text-white" key={id}>
                                        <NavLink to={`/tournaments/my-tournaments/${tournament._id}`} style={{ textDecoration: 'none' }}>
                                            <TournamentCard tournament={tournament} id={id} key={id}/>
                                        </NavLink>
                                    </div>
                                )
                                
                            })
                            :
                            <div className="text-center">
                                <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                            </div>
                        
                        }
                    </div>
                </div>
            </section>
        )
    }
}
