import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class DenApi {
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    /*****************************************[  Den Profile  ]*****************************************************/
    
    getDenProfiles = (data) => {
        console.log("\n\n\n\n getDenProfiles.DATA :: ", data)
        return this.init().post(`/private/api/v1/denProfile/getDenProfiles`,data);
    };

    updateDenProfileDetails = (id, data) =>{
        return this.init().put(`/private/api/v1/denProfile/updateDenProfile/${id}`, data);
    }

    denProfileSearch = (denId, searchString) => {
        return this.init().get(`/private/api/v1/denProfile/search/${denId}/${searchString}`);
    }

    followDenProfile = (denId, profileId) => {
        return this.init().post(`/private/api/v1/denProfile/action/${denId}/follow/${profileId}`);
    }

    unFollowDenProfile = (denId, profileId) => {
        return this.init().post(`/private/api/v1/denProfile/action/${denId}/unfollow/${profileId}`);
    }

    cancelRequest = (denId, profileId) => {
        return this.init().post(`/private/api/v1/denProfile/action/${denId}/cancelRequest/${profileId}`);
    }

    acceptRequest = (denId, profileId) => {
        return this.init().post(`/private/api/v1/denProfile/action/${denId}/acceptRequest/${profileId}`);
    }

    blockUnBlockDenProfile = (denId, profileId, isBlocked) => {
        return this.init().post(`/private/api/v1/denProfile/action/${denId}/${isBlocked ? 'block' : 'unblock'}/${profileId}`);
    }

    updateLastSpinDetails = (denId, data) => {
        return this.init().post(`/private/api/v1/denProfile/updateLastSpinDetails/${denId}`,data);
    }

    /*****************************************[  Den POST's   ]*****************************************************/
    createDenPost = (data) =>{
        return this.init().post(`/private/api/v1/denPosts/createDenPost`, data);
    }

    draftDenPost = (data) =>{
        return this.init().post(`/private/api/v1/denPosts/draftDenPost`, data);
    }

    updateDenPost = (postId, data) =>{
        return this.init().put(`/private/api/v1/denPosts/updateDenPost/${postId}`, data);
    }

    
    getDenPostsForTimeline = (data) => {
        if(data){
            return this.init().get(`/private/api/v1/denPosts/getDenPosts`,data);
        }else{
            return this.init().get(`/private/api/v1/denPosts/getDenPosts`);
        }

    };

    getPostLikedUsersByID = (postId) => {
        return this.init().get(`/private/api/v1/denPosts/getPostLikedUsersByID/${postId}`);
    }

    getDenPostById = (postId) => {
        return this.init().get(`/private/api/v1/denPosts/getDenPostById/${postId}`);
    };

    getDenPostsByType = (denId, type) => {
        return this.init().get(`/private/api/v1/denPosts/getDenPostsByType/${denId}/${type}`);
    };

    getDenMultiplePosts = (posts) => {
        return this.init().post(`/private/api/v1/denPosts/getDenMultiplePosts`,posts);
    };

    getProfilePagePosts = (denId) => {
        return this.init().get(`/private/api/v1/denPosts/getProfilePagePosts/${denId}`);
    };

    likeDenPost = (postId, data) => {
        return this.init().put(`/private/api/v1/denPosts/likeDenPost/${postId}`, data);
    };

    reportDenPost = (postId, data) => {
        return this.init().post(`/private/api/v1/denPosts/reportDenPost/${postId}`, data);
    };

    undoDenPostReport = (reportId) => {
        return this.init().put(`/private/api/v1/denPosts/undoDenPostReport/${reportId}`);
    };


    saveDenPost = (denId, postId, isSave) => {
        return this.init().put(`/private/api/v1/denPosts/savePost/${denId}/${isSave}/${postId}`);
    };

    pinDenPost = (denId, postId, isPin) => {
        return this.init().put(`/private/api/v1/denPosts/pinPost/${denId}/${isPin}/${postId}`);
    };

    shareDenPost = (denId,postId) => {
        return this.init().put(`/private/api/v1/denPosts/shareDenPost/${denId}/${postId}`);
    };

    updateAndShareDraftPost = (postId, data) => {
        return this.init().put(`/private/api/v1/denPosts/updateAndShareDraftPost/${postId}`,data);
    };

    choosePollOption = (denId, postId, option) => {
        return this.init().put(`/private/api/v1/denPosts/choosePollOption/${denId}/${postId}/${option}`);
    };

    enableDisabelDenPostActions = (postId, data) =>{
        return this.init().put(`/private/api/v1/denPosts/disableAction/${postId}`,data);
    }

    getDenPostsLeaderBoard = () =>{
        return this.init().get(`/private/api/v1/denPosts/getDenPostsLeaderBoard`);
    }
    //Feed for registered den users
    getFeedPosts = (denId, searchString) =>{
        return this.init().get(`/private/api/v1/denPosts/getFeedPosts/${denId}?${searchString}`);
    }
    //Feed Tv Post for registered den users
    getFeedTvPosts = (denId, searchString) =>{
        return this.init().get(`/private/api/v1/denPosts/getFeedTvPosts/${denId}?search=${searchString}`);
    }
    //Public feed for unregistered den users
    getPublicFeedPosts = (searchString) =>{
        return this.init().get(`/private/api/v1/denPosts/getFeedPublicPosts?${searchString}`);
    }
    /*************************************[  Den Post Comments   ]************************************************/


    getPostComments = (postId) => {
        return this.init().get(`/private/api/v1/comments/getPostComments/${postId}`);
    };

    updateComment= (commentId,data) => {
        return this.init().put(`/private/api/v1/comments/updateComment/${commentId}`,data);
    };

    replyOnPostOrComment = (comment) => {
        return this.init().post(`/private/api/v1/comments/createComment`,comment);
    };

    likeDenPostComment = (commentId, data) => {
        return this.init().put(`/private/api/v1/comments/likeComment/${commentId}`, data);
    };

    getPostCommentedUsersByID = (commentId) => {
        return this.init().get(`/private/api/v1/comments/getPostCommentedUsersByID/${commentId}`);
    };

    deletePostCommentById = (commentId) => {
        return this.init().delete(`/private/api/v1/comments/deleteComment/${commentId}`);
    };

    deleteCommentOnPostOrReply = (commentId,data) => {
        return this.init().put(`/private/api/v1/comments/deleteCommentOnPostOrReply/${commentId}`,data);
    };

    pinComment = (commentId,data) => {
        return this.init().put(`/private/api/v1/comments/pinComment/${commentId}`,data);
    };

    denPostSearch = (searchString) => {
        return this.init().get(`/private/api/v1/denPosts/search/${searchString}`);
    }

    denLiveStreamSearch = (denId, searchString) => {   
        return this.init().get(`/private/api/v1/denPosts/denLiveStreamSearch/${denId}?${searchString}`);
    }


    /*******************************************[  Spotlight  ]******************************************************/

    sendSpotlightToDenProfile = (data) => {
        return this.init().post(`/private/api/v1/denSpotlight/sendSpotlightToDenProfile`, data);
    };

     /*******************************************[  Achievements  ]******************************************************/

    getDenProfileAchivements = (denId) => {
        console.log("\n\n\n\n ###### getDenProfileAchivements [DENID] :: ",denId)
        return this.init().get(`/private/api/v1/denAchivement/getDenProfileAchivements/${denId}`);
    };

    getDenProfileGameStats = (id, type) => {
        return this.init().get(`/private/api/v1/denAchivement/getDenProfileGameStats/${type}/${id}`);
    };




    /*******************************************[  Common  ]******************************************************/

    updateDenProfile = (data) => {
        return this.init().post(`/private/api/v1/auth/updateProfile`, data);
    };
    getCurrentProfile = (id) => {
        return this.init().get(`/private/api/v1/auth/getCurrentUser`);
    };

  


}       