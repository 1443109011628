import { Component } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsArrowLeftShort } from "react-icons/bs";
import participantAvtar from "../../images/participantAvtar.jpg";
import formInfoIc from "../../images/icon/formInfo-ic.png";
import TeamApis from '../../helper/teams.api';
import { AxiosResponse, AxiosError } from "axios";
import { errorHandler } from "../../utils/common.utils";

export default class   FFAParticipantDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showTeamDetails: true,
            participants: [],
            selectedPlayer: {}
        }
        this.teamApis = new TeamApis()
    }
    componentDidMount() {
        this.setState({ participants: this.props.details, showTeamDetails: false })
    }
    getData = () => {
        const data = {
            _id: this.props.details._id
        }
        this.props.updateLoader(true);
        this.teamApis.getTeams(data).then(
            (res: AxiosResponse) => {
                this.setState({ participants: res.data.result[0].members.players })
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        );
    }
    render() {
        return (
            <div className="p-3">
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px' }} onClick={() => this.props.onClose()} />
                <div className="row mt-3">
                    <h1 className="fs-20 fw-600 text-white text-center mb-3">Participants</h1>
                        {
                            this.state.participants.map((el, i) => {
                                if (el !== null){
                                    return (
                                        <div className='col-lg-12 col-md-12 col-12 d-block' key={i}>
                                            <div className="row mulberry-purple-card align-items-center py-2 mb-2 mx-1">
                                                <div className='col-3'>
                                                    <img src={this.props.type === 'TEAM' ? el?.logo?.url : el?.profilePicture?.url} className="rounded-circle mx-2 btn-size-32" alt='team logo' />
                                                </div>
                                                <div className='col-7'>
                                                    <p className="fs-16 fw-bold text-white my-1 text-truncate">{this.props.type === 'TEAM' ? el?.name : el?.inGameName}</p>
                                                </div>
                                                {/* <div className='col-2'>
                                                    <button
                                                        className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32 white-round-btn"
                                                        onClick={() => this.setState({ selectedPlayer: el, showTeamDetails: false })} >
                                                        <img src={formInfoIc} className="rounded-none btn-size-15" alt="" />
                                                    </button>
                                                </div> */}
                                            </div>
    
                                        </div>
                                    )
                                }
                            })
                        }
                        {/* <div>
                            <button className='white-flat-btn fs-16 fw-600 px-3 py-1 d-flex mx-auto'>Ban</button>
                        </div> */}
                    </div>
                    
            </div>
        )
    }
}
