import { Component } from 'react';
import { NavLink } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { Carousel, ProgressBar } from 'react-bootstrap';
//images and icons
import mobile from '../../../images/page/mobile-icon.png';
import nintendo from '../../../images/page/nintendo-icon.png';
import pc from '../../../images/page/pc-icon.png';
import ps from '../../../images/page/ps-icon.png';
import xbox from '../../../images/page/xbox-icon.png';
import gameCover from '../../../images/League_of_legends.png';
import participantAvtar from "../../../images/participantAvtar.jpg";
import DefaultImage from '../../../images/image 65.jpg';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiFillCopyrightCircle } from "react-icons/ai";
import descriptionIc from '../../../images/icon/description-ic.png';
import ecIc from '../../../images/icon/ec-ic.png';
import desktopIc from '../../../images/icon/desktop-ic.png';
import matchesIc from '../../../images/icon/matches-ic.png';
import { IoDiamondOutline, IoArrowBackCircleOutline } from 'react-icons/io5';
import groupIc from '../../../images/icon/group1-ic.png';
//components
import TournamentApis from '../../../helper/tournament.api';
import MatchesApis from '../../../helper/matches.api';
import TeamApis from '../../../helper/teams.api';
import TournamentEntryDetails from './TournamentEntryDetails';
import { AxiosResponse, AxiosError } from 'axios';
import { ellipsis, errorHandler, getMonthAndDate } from '../../../utils/common.utils';
import TournamentCard from './common/TournamentCard';
import '../../../styles/PublicTournaments.css'
import '../../../styles/Layout.css'

export default class MyTournamentDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tournamentView: 100,
            now: 60,
            MatchesType: 'UPCOMING',
            selectMatches: 'UPCOMING',
            teamDetails: 'rooster',
            tournamentDetails: null,
            matches: [],
            myTeamDetails: null
        };
        this.tournamentApis = new TournamentApis();
        this.matchesApis = new MatchesApis();
        this.teamsApis = new TeamApis();
        if (props.match.params.id) {
            this.getTournamentData(props.match.params.id);
            this.getMatches(props.match.params.id, this.state.MatchesType);
            this.getMyTeam(props.match.params.id);
        }
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    onSelectMatchesType = (e) => {
        this.setState({ MatchesType: e.target.name })
        this.getMatches(this.props.match.params.id, e.target.name)
    }

    getTournamentData = (id) => {
        const data = { _id: id };
        this.props.updateLoader(true);
        this.tournamentApis.getTournamentsById(data).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                //this.props.dispatchData(res.data.result)
                this.setState({ tournamentDetails: res.data.result[0] })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    getMatches = async (id, type) => {

        const data = { tournamentId: id, status: type };
        this.props.updateLoader(true);
        await this.matchesApis.getMatchDetailsWithParticipants(data).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                //this.props.dispatchData(res.data.result)
                this.setState({ matches: res.data.result })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    getMyTeam = (id) => {
        const data = { tournamentId: id };
        this.props.updateLoader(true);
        this.teamsApis.getMyParticipatedTeam(data).then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                //this.props.dispatchData(res.data.result)
                this.setState({ myTeamDetails: res.data.result })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    getSponsors = () => {
        const sponsors = this.state.tournamentDetails?.sponsorDetails;
        if (sponsors.length === 0) {
            return <h1 className="text-white fs-10 text-center">No Sponsors</h1>
        }
        return (
            <section className='sponsor-names-carousel'>
                <Carousel>
                    {sponsors.map((sponsor, i) => {
                        return (
                            <Carousel.Item key={i}>
                                <h1 className="text-center fs-10 text-center">{sponsor.type}: {sponsor.name}</h1>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </section >
        )
    }

    getPlatformUrl = (key) => {
        switch (key) {
            case 'MOBILE':
                return mobile
            case 'PC':
                return pc
            case 'PS':
                return ps
            case 'XBOX':
                return xbox
            default:
                return nintendo
        }
    }

    getURL = (el) => {
        if (this.state.tournamentDetails.participantType === 'SINGLE') {
            return el.profilePicture.url
        } else {
            return el.logo.url
        }
    }

    getCurrentStatus = () => {
        const {tournamentDetails} = this.state;
        if (tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolType === 'FIXED'){
            return 100;
        }else{
            const entryFee = tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount;
            const members = tournamentDetails?.teams?.length;
            const moneyCollectedPerEntryFee = tournamentDetails?.entryFeeAndPrizeDetails?.moneyCollectedPerEntryFee
            const prizePool = tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount;
            return `${parseInt(moneyCollectedPerEntryFee)*members*100/prizePool}`;
        }
    }

    getPrizePoolStatus = () => {
        const {tournamentDetails} = this.state;
        const moneyCollectedPerEntryFee = tournamentDetails?.entryFeeAndPrizeDetails?.moneyCollectedPerEntryFee
        const members = tournamentDetails?.teams?.length;
        const prizePool = tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount;
        if (tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolType === 'FIXED'){
            return `${prizePool}/${prizePool}`;
        }else{
            
            return `${parseInt(moneyCollectedPerEntryFee)*members}/${prizePool}`;
        }
    }

    render() {
        const startDate = getMonthAndDate(this.state.tournamentDetails?.tournamentStartTime);
        const endDate = getMonthAndDate(this.state.tournamentDetails?.tournamentEndTime);
        const cardBg = (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.listingType === 'PREMIUM') ? 't-card-premium' : 't-card-regular'
        const detailsStyle = (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.listingType === 'PREMIUM') ? 't-body-premium' : 't-body-regular';
        return (
            <section className='body-section my-tournament-details layout1'>
                <div className='container mt-4 mt-lg-5'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h4 className='fs-20 fs-lg-24 fw-700'>{ellipsis(this.state.tournamentDetails?.name, 20)}</h4>
                            <p className='fs-16 fs-lg-20 fw-600'>{`${startDate[0]} - ${endDate[0]}`}</p>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex justify-content-around justify-content-md-evenly justify-content-lg-evenly mt-2'>
                                <h4 className={` ${this.state.tournamentView === 100 ? 'border-bottom' : null} fs-16 fs-lg-20 cursor-pointer`} onClick={() => { this.setState({ tournamentView: 100 }) }}>Information</h4>
                                <h4 className={` ${this.state.tournamentView === 101 ? 'border-bottom' : null} fs-16 fs-lg-20 cursor-pointer`} onClick={() => { this.setState({ tournamentView: 101 }) }}>Matches</h4>
                                <h4 className={` ${this.state.tournamentView === 102 ? 'border-bottom' : null} fs-16 fs-lg-20 cursor-pointer`} onClick={() => { this.setState({ tournamentView: 102 }) }}>Entry Details</h4>
                            </div>
                        </div>
                    </div>


                    {(this.state.tournamentView === 100 && this.state.tournamentDetails) &&
                        <div className='row mt-3'>
                            <div className='col-lg-2 offset-lg-1 col-md-4 col-6'>
                                <TournamentCard tournament={this.state.tournamentDetails} id={1} />
                            </div>

                            <div className='col-lg-6 offset-lg-1 col-md-8 col-6'>
                                <div className=''>
                                    <h4 className='fs-16 fs-lg-20 fw-700 mb-2'>{ellipsis(this.state.tournamentDetails?.name, 20)} <IoDiamondOutline /></h4>
                                    <p className='fs-10 fs-lg-14 fw-600 text-white-50 mb-4'>{`From ${startDate[0]} - ${endDate[0]}`}</p>
                                </div>

                                <div className='w-100 my-3' style={{display: this.state.tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount ? 'block' : 'none'}}>
                                    <ProgressBar variant="warning" now={this.getCurrentStatus()} className='w-100 mx-0' />
                                    <div className='d-flex justify-content-between my-1'>
                                        <p className='fs-12 text-light fw-600' onClick={this.toggleModalStatus}>Prize Pool</p>
                                        <p className='fs-12 text-light fw-600'>{this.getPrizePoolStatus()}</p>
                                    </div>
                                </div>

                                <div className='d-lg-flex justify-content-lg-evenly d-md-flex justify-content-md-evenly mt-lg-3 mt-md-3'>
                                    <div className='d-flex my-4'>
                                        <img src={ecIc} className='me-3' height='17px' alt='' />
                                        <p className='fs-12 fs-lg-16 fw-600'>{(this.state.tournamentDetails?.entryFeeAndPrizeDetails) ? this.state.tournamentDetails?.entryFeeAndPrizeDetails.entryFeeAmount + ' EC' : 'Free'}</p>
                                    </div>

                                    <div className='d-flex my-4'>
                                        {/* <img src={desktopIc} className='me-3' height='17px' /> */}
                                        {this.state.tournamentDetails?.platform?.map((type, i) => {
                                            return <img src={this.getPlatformUrl(type)} key={i} className='me-1' alt={type} style={{ height: '20px', width: '20px' }} />
                                        })}
                                        <p className='fs-12 fs-lg-16 fw-600 ms-2'>{this.state.tournamentDetails?.gameDetails?.name}</p>
                                    </div>
                                </div>

                            </div>
                            <div className='col-12 d-block d-md-none d-lg-none'>
                                <h1 className='fs-16 fs-lg-20 fw-600 mt-3'> <img src={descriptionIc} className='mb-1' height='17px' alt='' /> Description</h1>
                                <p className='fs-14 fs-lg-18 fw-400'>{this.state.tournamentDetails?.bio}</p>
                            </div>
                        </div>
                    }

                    {(this.state.tournamentView === 101) &&
                        <div>
                            <div className='row'>
                                <div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12'>
                                    <Dropdown className="select-game-dropdown mt-3">
                                        <Dropdown.Toggle className='fs-14 dropdown-basic w-100 text-start ps-3 height-45'>
                                            {this.state.MatchesType}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu onClick={(e) => this.onSelectMatchesType(e)}>
                                            <Dropdown.Item name="UPCOMING" className="text-white" onClick={() => { this.setState({ selectMatches: 'UPCOMING' }); }}>UPCOMING</Dropdown.Item>
                                            {/* <Dropdown.Item name="DRAFT" className="text-white" onClick={() => { this.setState({ selectMatches: 'DRAFT' }) }}>DRAFT</Dropdown.Item> */}
                                            <Dropdown.Item name="COMPLETED" className="text-white" onClick={() => { this.setState({ selectMatches: 'COMPLETED' }); }}>COMPLETED</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className='row mt-lg-4'>
                                {this.state.tournamentDetails?.competitionType === 'FFA' ?
                                    this.state.matches.length ?
                                        this.state.matches?.map((match, i) => {
                                            const matchTime = match.matchSchedule;
                                            const scheduledTime = new Date(matchTime);
                                            const matchDateTime = getMonthAndDate(match.matchSchedule);
                                            const matchYear = scheduledTime.getFullYear();
                                            return (
                                                <div className='col-lg-4 col-md-6 col-12 mt-3' key={i}>
                                                    <div className='mulberry-purple-card p-3'>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <p className='type'>{this.state.tournamentDetails?.competitionType}</p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <h6 className='fw-bold text-center fs-14 fs-lg-18'>Match {i + 1}</h6>
                                                            </div>
                                                            <div className='col-3'></div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-6'>
                                                                <h6 className='fs-12 fs-lg-16 fw-bold'>Winner</h6>
                                                                {match.status === 'COMPLETED' ?
                                                                    <div className='d-flex my-2'>
                                                                        <img src={participantAvtar} className="rounded-circle btn-size-20" alt='' />
                                                                        <h6 className="fw-bold ms-1 mt-1" style={{ fontSize: "11px" }}>Participant 1</h6>
                                                                    </div>
                                                                    :
                                                                    <h6 className="fw-bold ms-1 mt-1" style={{ fontSize: "11px" }}>TBD</h6>}
                                                            </div>
                                                            <div className='col-6'>
                                                                <h6 className='fs-12 fs-lg-16 fw-bold float-end'>{match.status}</h6>
                                                                <h6 className='fs-12 fs-lg-16 fw-bold float-end' style={{ opacity: "0.7" }}>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <img src={groupIc} className='mt-1 me-2' height={'20px'} alt='' />
                                                            <p className='fs-18 fs-lg-24 fw-bold mb-1'>{match.participants.length}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) :
                                        null

                                    :
                                    this.state.matches.length ?
                                        this.state.matches?.map((match, i) => {
                                            const matchTime = match.matchSchedule;
                                            const scheduledTime = new Date(matchTime);
                                            const matchDateTime = getMonthAndDate(match.matchSchedule);
                                            const matchYear = scheduledTime.getFullYear();
                                            const participants = match.participants;
                                            const result = match.results;
                                            return (
                                                <div className='col-lg-4 col-md-6 col-12 mt-3' key={i}>
                                                    <div className="mulberry-purple-card py-3">
                                                        <div className='ms-3 mb-3'>
                                                            <h1 className='fs-16 fs-lg-20 fw-700'>Show Down <span className='fs-12 fw-bold'>(M {i + 1} )</span></h1>
                                                            <h1 className='fs-12 fs-lg-16 fw-700 text-white-50'>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h1>
                                                        </div>
                                                        <div className='row justify-content-around text-center mt-2'>
                                                            <div className='col-5'>
                                                                <img src={participants[0] ? this.getURL(participants[0]) : participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                                <h5 className='fs-14 text-white fw-500 mt-2 text-truncate'>{participants[0] ? participants[0].name : 'Yet To Join'}</h5>
                                                            </div>
                                                            <div className='col-2 p-0'>
                                                                <h5 className='fs-22 text-white fw-500 mt-1'>0 - 0</h5>
                                                            </div>
                                                            <div className='col-5'>
                                                                <img src={participants[1] ? this.getURL(participants[1]) : participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                                <h5 className='fs-14 text-white fw-500 mt-2 text-truncate'>{participants[1] ? participants[1].name : 'Yet To Join'}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        :
                                        null}
                            </div>
                        </div>
                    }

                    {(this.state.tournamentView === 102) &&
                        <div className='col-12 mt-3'>
                            <h1 className='fs-16 fs-lg-20'><img src={matchesIc} className='mb-1' height='15px' /> Registration</h1>

                            <div className='row'>
                                <div className='col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12'>
                                    <div className='purple-field p-3 d-flex justify-content-between'>
                                        <div className=''>
                                            <h1 className='fs-16 fs-lg-20 fw-700'>Stage 1</h1>
                                            <p className='fs-12 fs-lg-16 text-white-50 fw-600 mb-0'>NOV 20 - DEC 20</p>
                                        </div>
                                        <div className=''>
                                            <h1 className='text-end fs-12 fs-lg-16 text-white-50 fw-600'>M 2.2</h1>
                                            <p className='text-end fs-12 fs-lg-16 text-white-50 fw-600 mb-0'>DEC 15 @7:15 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='d-flex justify-content-evenly my-3'>
                                    <h1 className={`${this.state.teamDetails === 'rooster' ? 'border-bottom' : null} fs-16 fs-lg-20 fw-700`} onClick={() => { this.setState({ teamDetails: 'rooster' }) }}> Rooster</h1>
                                    <h1 className={`${this.state.teamDetails === 'formDetails' ? 'border-bottom' : null} fs-16 fs-lg-20 fw-700`} onClick={() => { this.setState({ teamDetails: 'formDetails' }) }}>Form Details</h1>
                                </div>

                                <TournamentEntryDetails tournamentDetails={this.state.tournamentDetails} myTeamDetails={this.state?.myTeamDetails} teamDetails={this.state.teamDetails} />



                            </div>
                        </div>
                    }
                </div>
            </section>
        )
    }
}
