import React, { Component } from 'react';
import { IoIosArrowBack, IoIosCloseCircleOutline } from 'react-icons/io';
import avatar from '../../images/participantAvtar.jpg';
import hammer from '../../images/den/interactions/hammer.png';
import spotlight from '../../images/den/interactions/spotlight.png';
import { FaShareAlt } from 'react-icons/fa';
import Image from '../common/Image';
import { getDenProfilePicture } from '../../utils/Den.utils';
import { DEN } from '../../utils/Strings.utils';
import { getDenAchievementStats } from '../../utils/common.utils';


export default class DenAchievements extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentView: 100,
            denAchivements: props.denAchivements,
            selectedUser: null,
        }
        this.denStats = getDenAchievementStats();
        //console.log('denStats ::', this.denStats);
    }

    componentDidMount() {}

    componentWillReceiveProps(newProps){
        this.setState({
            denAchivements: newProps.denAchivements
        })
    }

    spotlightAchievements = () => { 

        var userProfileTags = [];
        if(this.props.heading === DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY && this.state.denAchivements?.achivements?.length > 0){
            userProfileTags = this.state.denAchivements.achivements.filter((tag)=> tag.spotlightTag !== null);
        }else if(this.props.heading === DEN.ACHIEVEMENTS.TYPE.TOP_FAN.KEY && this.state.denAchivements?.topFans?.length > 0){
            userProfileTags = this.state.denAchivements.topFans.filter((tag)=> tag.spotlightTag !== null);
        }
        userProfileTags = userProfileTags?.sort((a, b) => parseInt(b.totalSpotlights) - parseInt(a.totalSpotlights));
        console.log(" popupType :: ",this.props.heading);

        return(
            <div className="spotlight-bg-1 d-flex flex-column p-2">
                <div className='row'>
                    <div className='col-2'></div>
                    <div className='col-8'><p className="fs-20 fw-700 text-center my-0">{ DEN.ACHIEVEMENTS.TYPE[this.props.heading].VALUE }</p></div>
                    <div className='col-2 text-end'><IoIosCloseCircleOutline className="cursor-pointer mx-2" style={{height:'20px', width:'20px'}} onClick={() => this.props.toggleAchievementsPopup(false, DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY)} /></div>
                </div>
                <p className="fs-14 fw-700 opacity-50 my-0 text-center">Spotlight</p>
                <div className="d-flex justify-content-between px-2">
                    <p className="fs-14 fw-700">Spotlight</p>
                    <p className="fs-14 fw-700">Previous : <span className="cursor-pointer" style={{color: '#FFD700'}} onClick={() => this.switchView(null, 100)}>Comments</span></p>
                </div>
                <div className='d-flex flex-column overflow-auto'>
                    {userProfileTags.map((tag, i) => {
                        var profilePicture = getDenProfilePicture(tag.from);
                        return (
                            <div key={i}>
                                <div className='d-flex align-items-center px-2 my-0 cursor-pointer' onClick={() => this.switchView(tag, 103)}>
                                    <p className='fs-16 fw-600 my-0 text-white mx-3'>{i+1}</p>
                                    <Image src={profilePicture?.url} param={tag.from?.name? tag.from?.name : 'Test' } className='achievements-avatar mx-2' />
                                    <div className='d-flex flex-column flex-grow-1'>
                                        <span className='fs-16 fw-700 text-white my-0'>{tag.from.name}</span>
                                        <span className='fs-12 fw-700 opacity-50 my-0'>{tag.spotlightTag}</span>
                                    </div>
                                    <img src={spotlight} alt='' className='' style={{ width: '16px', height: 'auto' }}/>
                                    <p className='fs-12 fw-700 my-0 mx-3'>{tag.totalSpotlights}</p>
                                </div>
                                <hr className='my-0' />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    commentsAchievements = () => { 

        var userProfileTags = [];

        if(this.props.heading === DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY && this.state.denAchivements?.achivements?.length > 0){
            userProfileTags = this.state.denAchivements.achivements.filter((tag)=> tag.commentTag !== null);
        }else if(this.props.heading === DEN.ACHIEVEMENTS.TYPE.TOP_FAN.KEY && this.state.denAchivements?.topFans?.length > 0){
            userProfileTags = this.state.denAchivements.topFans.filter((tag)=> tag.commentTag !== null);
        }
        userProfileTags = userProfileTags?.sort((a, b) => parseInt(b.totalComments) - parseInt(a.totalComments));

        return(
            <div className="hammer-bg-1 d-flex flex-column p-2">
                <div className='row'>
                    <div className='col-2'></div>
                    <div className='col-8'><p className="fs-20 fw-700 text-center my-0">{ DEN.ACHIEVEMENTS.TYPE[this.props.heading].VALUE }</p></div>
                    <div className='col-2 text-end'><IoIosCloseCircleOutline className="cursor-pointer mx-2" style={{height:'20px', width:'20px'}} onClick={() => this.props.toggleAchievementsPopup(false, DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY)} /></div>
                </div>
                <p className="fs-14 fw-700 opacity-50 my-0 text-center">Comments</p>
                <div className="d-flex justify-content-between px-2">
                    <p className="fs-14 fw-700">Comments</p>
                    <p className="fs-14 fw-700">Next : <span className="cursor-pointer" style={{color: '#9AD9ED'}} onClick={() => this.switchView(null, 102)}>Spotlight</span></p>
                </div>
                <div className='d-flex flex-column overflow-auto'>
                    {userProfileTags.map((tag, i) => {
                        var profilePicture = getDenProfilePicture(tag.from);
                        return (
                            <div key={i}>
                                <div className='d-flex align-items-center px-2 my-0 cursor-pointer' onClick={() => this.switchView(tag, 101)}>
                                    <p className='fs-16 fw-600 my-0 text-white mx-3'>{i+1}</p>
                                    <Image src={profilePicture?.url} param={tag.from?.name? tag.from?.name : 'Test' }  className='achievements-avatar mx-2' />
                                    <div className='d-flex flex-column flex-grow-1'>
                                        <span className='fs-16 fw-700 text-white my-0'>{tag.from.name}</span>
                                        <span className='fs-12 fw-700 opacity-50 my-0'>{tag.commentTag}</span>
                                    </div>
                                    <img src={hammer} alt='' className='' style={{ width: '16px', height: 'auto' }}/>
                                    <p className='fs-12 fw-700 my-0 mx-3'>{tag.totalComments}</p>
                                </div>
                                <hr className='my-0' />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    screen2 = () => {
        const {selectedUser} = this.state; 
        var profilePicture = getDenProfilePicture(selectedUser.from);
        //console.log('selectedUser::',selectedUser);
        var currentRankIndex = 0;
        var nextRank;
        if(selectedUser.from.type === 'CLAN'){
            currentRankIndex = this.denStats.RANK_CRITERIA_BY_TYPE.CLANS.indexOf(selectedUser.commentTag);
            nextRank = currentRankIndex < 4 ? this.denStats.RANK_CRITERIA_BY_TYPE.CLANS[currentRankIndex +1] : 'NA'
        }else if(selectedUser.from.type === 'ORG'){
            currentRankIndex = this.denStats.RANK_CRITERIA_BY_TYPE.ORG.indexOf(selectedUser.commentTag);
            nextRank = currentRankIndex < 4 ? this.denStats.RANK_CRITERIA_BY_TYPE.ORG[currentRankIndex +1] : 'NA'
        }else{
            currentRankIndex = this.denStats.RANK_CRITERIA_BY_TYPE.DEN_USERS.indexOf(selectedUser.commentTag);
            nextRank = currentRankIndex < 4 ? this.denStats.RANK_CRITERIA_BY_TYPE.DEN_USERS[currentRankIndex +1] : 'NA'
        }
        // console.log('currentRankIndex ::', currentRankIndex);
        // console.log('nextRank ::', nextRank);

        return(
            <div className="hammer-bg-1 d-flex flex-column p-2">
                <div className='d-flex justify-content-between align-items-center mb-0'>
                    <IoIosArrowBack className="cursor-pointer mx-2" style={{height:'20px', width:'20px'}} onClick={() => this.switchView(null, 100)} />
                    <p className="fs-20 fw-700 text-center my-0">Premium Fan</p>
                    <IoIosCloseCircleOutline className="cursor-pointer mx-2" style={{height:'20px', width:'20px'}} onClick={() => this.props.toggleAchievementsPopup(false, DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY)} />
                </div>
                <p className="fs-14 fw-700 opacity-50 my-0 text-center">of</p>
                
                <div className="column-center-align-div">
                    <Image src={profilePicture?.url} param={selectedUser.from?.name? selectedUser.from?.name : 'Test' } alt='' className='rounded-circle' style={{width:'80px', height:'80px'}} />
                    <div className="d-flex align-items-center my-2">
                        <p className="fs-24 fw-700 text-white my-0">{selectedUser.from?.name}</p>
                        <FaShareAlt className="cursor-pointer mx-2" style={{height: '24px', color: '#fff'}} />
                    </div>
                    <p className='fs-14 fw-700 opacity-75 my-0'>{`You are a ${selectedUser.commentTag} fan`}</p>
                    <div className='d-flex justify-content-center my-2'>
                        <div className="text-center me-2">
                            <p className="fs-20 fw-700 my-0" style={{color: '#A4FF04'}}>{selectedUser.totalComments}</p>
                            <p className="fs-16 fw-700 text-white my-0">Hammered</p>
                        </div>
                        <div className="text-center ms-2">
                            <p className="fs-20 fw-700 my-0" style={{color: '#A4FF04'}}>{nextRank}</p>
                            <p className="fs-16 fw-700 text-white my-0">Next Rank</p>
                        </div>
                    </div>
                    <p className='fs-16 fw-700 opacity-50 text-center' style={{width: '80%'}}>
                        {currentRankIndex < 4 ? 
                            `You need ${this.denStats?.COMMENTS_RANK_VALUES[currentRankIndex+1] - selectedUser.totalComments} more comments to reach ${nextRank} Rank`
                            :
                            'Congratulations!! You have reached maximum rank.'
                        }
                    </p>
                </div>
            </div>
        )
    }

    screen4 = () => {
        const {selectedUser} = this.state; 
        var profilePicture = getDenProfilePicture(selectedUser.from);
        //console.log('selectedUser::',selectedUser);
        var currentRankIndex = 0;
        var nextRank;
        if(selectedUser.from.type === 'CLAN'){
            currentRankIndex = this.denStats.RANK_CRITERIA_BY_TYPE.CLANS.indexOf(selectedUser.spotlightTag);
            nextRank = currentRankIndex < 4 ? this.denStats.RANK_CRITERIA_BY_TYPE.CLANS[currentRankIndex +1] : 'NA'
        }else if(selectedUser.from.type === 'ORG'){
            currentRankIndex = this.denStats.RANK_CRITERIA_BY_TYPE.ORG.indexOf(selectedUser.spotlightTag);
            nextRank = currentRankIndex < 4 ? this.denStats.RANK_CRITERIA_BY_TYPE.ORG[currentRankIndex +1] : 'NA'
        }else{
            currentRankIndex = this.denStats.RANK_CRITERIA_BY_TYPE.DEN_USERS.indexOf(selectedUser.spotlightTag);
            nextRank = currentRankIndex < 4 ? this.denStats.RANK_CRITERIA_BY_TYPE.DEN_USERS[currentRankIndex +1] : 'NA'
        }
        // console.log('currentRankIndex ::', currentRankIndex);
        // console.log('nextRank ::', nextRank);
        return(
            <div className="spotlight-bg-2 d-flex flex-column p-2">
                <div className='d-flex justify-content-between align-items-center mb-0'>
                    <IoIosArrowBack className="cursor-pointer mx-2" style={{height:'20px', width:'20px'}} onClick={() => this.switchView(null, 102)} />
                    <p className="fs-20 fw-700 text-center my-0">Spotlight</p>
                    <IoIosCloseCircleOutline className="cursor-pointer mx-2" style={{height:'20px', width:'20px'}} onClick={() => this.props.toggleAchievementsPopup(false, DEN.ACHIEVEMENTS.TYPE.ACHIEVEMENTS.KEY)} />
                </div>
                <p className="fs-14 fw-700 opacity-50 my-0 text-center">To</p>
                
                <div className="column-center-align-div">
                    <Image src={profilePicture?.url} param={selectedUser.from?.name? selectedUser.from?.name : 'Test' } alt='' className='rounded-circle' style={{width:'80px', height:'80px'}} />
                    <div className="d-flex align-items-center my-2">
                        <p className="fs-24 fw-700 text-white my-0">{selectedUser.from?.name}</p>
                        <FaShareAlt className="cursor-pointer mx-2" style={{height: '24px', color: '#fff'}} />
                    </div>
                    <p className='fs-14 fw-700 opacity-75 my-0'>{`You are a ${selectedUser.spotlightTag} fan`}</p>
                    <div className='d-flex justify-content-center my-2'>
                        <div className="text-center me-2">
                            <p className="fs-20 fw-700 my-0" style={{color: '#A4FF04'}}>{selectedUser.totalSpotlights}</p>
                            <p className="fs-16 fw-700 text-white my-0">Spotlight</p>
                        </div>
                        <div className="text-center ms-2">
                            <p className="fs-20 fw-700 my-0" style={{color: '#A4FF04'}}>{nextRank}</p>
                            <p className="fs-16 fw-700 text-white my-0">Next Rank</p>
                        </div>
                    </div>
                    <p className='fs-16 fw-700 opacity-50 text-center' style={{width: '80%'}}>
                        {currentRankIndex < 4 ? 
                            `You need ${this.denStats?.SPOTLIGHT_RANK_VALUES[currentRankIndex+1] - selectedUser.totalSpotlights} more comments to reach ${nextRank} Rank`
                            :
                            'Congratulations!! You have reached maximum rank.'
                        }
                    </p>
                </div>
            </div>
        )
    }

    switchView = (profile,val) => { 
        this.setState({selectedUser: profile, currentView : val})
    }

    getView = () => {
        const {currentView} = this.state;
        switch (currentView) {
            case 100:
                return this.commentsAchievements();
            case 101:
                return this.screen2();
            case 102:
                return this.spotlightAchievements();
            case 103:
                return this.screen4();
            default:
                return this.commentsAchievements();
        }
    }

    render() {
        return this.getView()
    }
}