import React, { Component } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiLink } from 'react-icons/bi';
import { isValidString } from '../../../../../utils/common.utils';
import { CREATE_POST_VIEWS } from '../../../../../utils/enums.utils';
import { ACTION, POST_INTERACTIONS } from '../../../../../utils/Images.utils';

export default class PostVideoSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            streamingLink: props.streamingLink ? props.streamingLink : {
                url: "",
                key: "live/streaming",
                size: 50000,
                type: "video/link"
            }
         };
    }

    updateState = (key, val) => {
        let streamingLink = {
            url: val,
            key: "live/streaming",
            size: 50000,
            type: "video/link"
        }
        this.setState({
            [key] : streamingLink
        });
        this.props.updateState(key, isValidString(val) ? streamingLink : null);
    }

    triggerInputFile2 = () => this.fileInput2.click();

 
    render() {

        const isStreamingLinkAdded = isValidString(this.state.streamingLink.url)
        console.log("####### isStreamingLinkAdded :: ",isStreamingLinkAdded)

        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{height:'82vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2 flex-grow-1'>
                        <div className='row px-2'>
                            <div className='col-2'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)} />
                            </div>
                            <div className='col-8'>
                                <p className='fs-16 text-center fw-700 my-0'>Video Options</p>
                            </div>
                            <div className='col-2'>
                                <p className='cursor-pointer fs-16 fw-600 my-0 mx-1' style={{ color:'#AD26FF'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)}>Done</p>
                            </div>
                        </div>
                        <hr className='hr-line' />
                        <p className='fs-16 fw-500'>Who can see your post?</p>
                        <p className='fs-14 fw-500 my-0'>Your post will appear in your feed, on your profile and in search results</p>
                        <hr className='hr-line' />

                        <div className='d-flex align-items-center my-2'>
                            <img src={POST_INTERACTIONS.VIDEO.SHORT} alt='' className='opacity-75' style={{ height:'22px', width:'32px'}} />
                            <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <p className='fs-16 fw-500 my-0'>Upload Shorts</p>
                                <p className='fs-12 fw-500 text-white-50 my-0'>Short video of max 15 seconds</p>
                            </div>
                            <input type="file" id="upload_file" accept=".mp4, .mov, .wmv .mkv .avi .webm" hidden ref={fileInput2 => this.fileInput2 = fileInput2} name="" className="" onChange={this.props.uploadVideo} />
                            <img src={POST_INTERACTIONS.PROCEED} alt='' className='cursor-pointer' style={{ height:'24px'}} onClick={this.triggerInputFile2} />

                        </div>

                        <div className='d-flex align-items-center my-2'>
                            <img src={POST_INTERACTIONS.VIDEO.EMBEDED} alt='' className='opacity-75' style={{ height:'22px', width:'32px'}} />
                            <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <p className='fs-16 fw-500 my-0'>Embed</p>
                                <p className='fs-12 fw-500 text-white-50 my-0'>In-app streaming through link</p>
                            </div>
                        </div>

                        <p className='fs-16 fw-700'>Stream’s Link</p>

                        <div className='den-input-container d-flex align-items-center p-2'>
                            <BiLink className='mx-2' />
                            <input type='url' className='fs-14 fw-400 text-white-50 flex-grow-1' placeholder='Please enter link' style={{ background: 'transparent', outline: 'none', border: 'none'}}
                                onChange={(e) => this.updateState('streamingLink', e.target.value)}
                                defaultValue= {this.state.streamingLink.url}
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}
