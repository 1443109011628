import * as axios from "axios";
import { queryParams } from "../utils/common.utils";
import { getToken } from "../utils/localstorage.utils";

export default class CommonApis {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    uploadSingleImg = (data) => {
        return this.init().post(`/public/api/v1/assets/uploadSingleImage`, data);
    };

    uploadMultipleImg = (data) => {
        return this.init().post("/public/api/v1/assets/uploadMultipleImages", data);
    };

    uploadMultipleVideo = (data) => {
        return this.init().post("/public/api/v1/assets/uploadVideo", data);
    };

    getCurrentProfile = (id) => {
        return this.init().get(`/private/api/v1/auth/getCurrentUser`);
    };

    getCountryCodes = () => {
        return this.init().get(`/public/api/v1/masterData/getCountryCodes?limit=242&orderBy=asc&sortBy=name`);
    };

    getClanOrTeamOrOrgDetails = (params) => {
        const searchString = queryParams(params)
        return this.init().get(`private/api/v1/tournament/getClanOrTeamOrOrgDetails?${searchString}`);
    };

    createFeatureRequest = (data) => {
        //console.log('createFeatureRequest ::', data);
        return this.init().post(`/public/api/v1/featureRequest/createFeatureRequest`, data);
    };

    createBugReport = (data) => {
        //console.log('createBugReport ::', data);
        return this.init().post(`/public/api/v1/reportBug/createReportBug`, data);
    };

    getWebData = (params) => { //changed from private to public
        const searchString = queryParams(params)
        return this.init().get(`public/api/v1/websitePages/getwebsite?${searchString}`);
    }

    getWebData1 = () => { //changed from private to public
        return this.init().get(`public/api/v1/websitePages/getwebsite`);
    }

    getCancelReasons = () => {
        return this.init().get(`private/api/v1/tournamentCancellation/getTournamentCancellation`);
    }

    getAdminData = () => { //changed from private to public
        return this.init().get(`public/api/v1/adminData/adminData`);
    }
}