import React, { Component } from 'react';
import { RiDiscordFill } from "react-icons/ri";

class MicroWebSiteFooter2 extends Component {


    constructor(props) {
        super(props);
        this.state = {
            microWebSiteData: props.microWebSiteData,
            backgroundColors: props.backgroundColors,
            secondaryTextColors: props.secondaryTextColors,
            background: props.background,
            secondary: props.secondary
        };
    }

    render() {
        return (
            <div>
                {/* Official site details */}
                <div className="mt-6 pb-6 text-center">
                    <img src={this.state.microWebSiteData?.logo?.url ? this.state.microWebSiteData.logo.url : "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/Screenshot_from_2021-12-07_17-09-15-removebg-preview.png"} alt={""} className="border border-2 border-white rounded-10" style={{height:'100px'}}/>
                </div>
                {/* Social media contacts */}
                <div className="wrapper">
                    <ul>
                        <li className="facebook" style={{ display: `${this.state.microWebSiteData.contactDetails.facebook ? 'content' : 'none'}` }}>
                            <a href={this.state.microWebSiteData?.contactDetails?.facebook}>
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                        </li>

                        <li className="twitter" style={{ display: `${this.state.microWebSiteData.contactDetails.twitter ? 'content' : 'none'}` }}>
                            <a href={this.state.microWebSiteData?.contactDetails?.twitter}>
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>

                        <li className="instagram" style={{ display: `${this.state.microWebSiteData.contactDetails.instagram ? 'content' : 'none'}` }}>
                            <a href={this.state.microWebSiteData?.contactDetails?.instagram}>
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                        </li>

                        <li className="telegram" style={{ display: `${this.state.microWebSiteData.contactDetails.telegram ? 'content' : 'none'}` }}>
                            <a href={this.state.microWebSiteData?.contactDetails?.telegram}>
                                <i className="fa fa-telegram" aria-hidden="true"></i>
                            </a>
                        </li>

                        <li className="twitter" style={{ display: `${this.state.microWebSiteData.contactDetails.discord ? 'content' : 'none'}` }}>
                            <a href={this.state.microWebSiteData?.contactDetails?.discord}>
                                <RiDiscordFill className="fa fa-whatsapp" aria-hidden="true" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default MicroWebSiteFooter2;
