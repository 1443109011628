import React, { Component } from 'react'
import bioIcon from '../../../../images/icon/bio-icon.png';
import { FiUsers } from "react-icons/fi";
import { RiUserShared2Line } from "react-icons/ri";
import { AiFillEdit} from 'react-icons/ai';
import Image from '../../../common/Image';
import { NavLink } from 'react-router-dom';
import { profile } from '../../../../utils/localstorage.utils';
import { DEN } from '../../../../utils/Strings.utils';
import { getDenCoverPicture, getDenProfilePicture } from '../../../../utils/Den.utils';
import { LOGOS } from '../../../../utils/Images.utils';
import { Modal } from 'react-bootstrap';
import ImagePreview from '../../../common/ImagePreview';
import { toast } from 'material-react-toastify';
import SideSkeleton from '../../../common/SideSkeleton';

export default class LeftSideBar extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.activeTab,
            denProfile: props.denProfile,
            previewImageDetails: {
                display: false,
                src: LOGOS.USER
            },
        };
        
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            activeTab: newProps.activeTab,
            denProfile: newProps.denProfile
        });
    }

    goToEditPage = () => {

        switch (this.state.denProfile?.type) {
            case DEN.SEARCH.TYPES.CLAN:
                this.props.history.push(`/clan/view/${this.state.denProfile?.clanOrOrgId}`);
                break;

            case DEN.SEARCH.TYPES.ORG:
            
                this.props.history.push(`/organization/view/${this.state.denProfile?.clanOrOrgId}`);
                break;
        
            default:
                this.props.history.push(`/den-profile`)
                break;
        }
    }

    previewImage = (src, param) => {
        this.setState({
          previewImageDetails: {
            display: param,
            src: (param ? src : LOGOS.USER)
          }
        });
    }

    pageRedirect = (page) => {
        const {denProfile} = this.state;
        if(!denProfile.isActive){
            toast.error('Please re-activate your den profile to perform any action');
            return false;
        }
        if(page){
            this.props.history.push(`/den/${denProfile?._id}/${page}`);
        }else{
            this.props.history.push(`/den/${denProfile?._id}`)
        }
    }

    render() {

    const {denProfile} = this.state;
    const {detailsObj} = this.props;

    if(denProfile){
        var profilePicture = denProfile?.type === DEN.SEARCH.TYPES.USER ? denProfile?.profilePicture : detailsObj?.logo;
        var coverPicture = denProfile?.type === DEN.SEARCH.TYPES.USER ? denProfile?.profileCover : detailsObj?.coverImage;
        return (
            <div className='col-lg-3 d-none d-lg-block'>
                <div className='den-left-top-container' style={{minHeight: '150px'}}>
                    <div className='' onClick={() => this.previewImage((coverPicture?.url ? coverPicture?.url : LOGOS.USER), true)}>
                        <Image src={coverPicture?.url} param={this.state.denProfile?.name} alt='' className='profile-cover' />
                    </div>
                    <div className='d-flex flex-column align-items-center' style={{marginTop: '-40px'}}>
                        <div className='' onClick={() => this.previewImage((profilePicture?.url ? profilePicture?.url : LOGOS.USER), true)}>
                            <Image param={this.state.denProfile?.name} src={profilePicture?.url} alt='' className='profile-logo' />
                        </div>
                        <NavLink className='text-white' to={`/den/${this.state.denProfile?._id}/profile-page/${this.state.denProfile?._id}`}><p className='fs-20 fw-bold my-1'>{`${this.state.denProfile?.name}${this.state.denProfile?.privateStatus?.age ? `, ${this.state.denProfile?.age}` : ''}`}</p></NavLink>
                        <p className='fs-12 text-white-50 fw-bold my-0'>{this.state.denProfile?.profileTag}</p>
                        {/* <p className='fs-12 text-white-50 fw-bold my-0'>{this.state.denProfile?.miniDescription}</p> */}
                        <p className='fs-12 text-white-50 fw-bold my-0'>{this.state.denProfile?.email}</p>
                    </div>
                    <div className='container'>
                        <button className='den-profile-btn hover-zoomin my-1' onClick={()=>this.goToEditPage()}>
                            <AiFillEdit className='me-2' /> Profile
                        </button>
                        <div className='row my-2'>
                            <div className='col-6'>
                                <div className='followers-box p-0'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <FiUsers className='me-2' />
                                        <div>
                                            {/* <p className='fs-12 fw-600 my-0'>{this.state.denProfile?.followers?.length}</p> */}
                                            <p className='fs-12 fw-600 my-0'>{this.state.denProfile?.followers?.length} Followers</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='followers-box p-0'>
                                <div className='d-flex justify-content-center align-items-center'>
                                        <RiUserShared2Line className='me-2' />
                                        <div>
                                            {/* <p className='fs-12 fw-600 my-0'>{this.state.denProfile?.followings?.length}</p> */}
                                            <p className='fs-12 fw-600 my-0'>{this.state.denProfile?.followings?.length} Following</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className='d-flex align-items-center mb-1 mt-3'>
                            <img src={bioIcon} className="ms-1" alt='' style={{ width: '20px' }} />
                            <p className='fs-14 fw-600 ms-2 mt-1 mb-0 text-white'>Bio</p>
                        </span>
                        <p className="text-white rounded-10 fw-500 fs-14" style={{textAlign:'justify', overflowWrap: 'break-word'}}>
                            {denProfile?.type === DEN.SEARCH.TYPES.USER ? denProfile?.bio : detailsObj?.bio}
                        </p>
                    </div>
                </div>
                <div className='den-left-bottom-container p-2 my-3'>
                    <p className={`fs-16 fw-bold cursor-pointer ${this.state.activeTab === 'HOME' ? 'den-selected-tab' :''} p-2 ps-3 my-1`} onClick={() => this.pageRedirect()}>HOME</p>
                    <p className={`fs-16 fw-bold cursor-pointer ${this.state.activeTab === 'BLOCKED USERS' ? 'den-selected-tab' :''} p-2 ps-3 my-1`} onClick={() => this.pageRedirect('blocked-users')}>BLOCKED USERS</p>
                    <p className={`fs-16 fw-bold cursor-pointer ${this.state.activeTab === 'FOLLOW REQUESTS' ? 'den-selected-tab' :''} p-2 ps-3 my-1`} onClick={() => this.pageRedirect('follow-request')}>FOLLOW REQUESTS {this.state.denProfile?.requestedBy?.length >0 ? `( ${this.state.denProfile?.requestedBy?.length} )`:"" }</p>
                    <p className={`fs-16 fw-bold cursor-pointer ${this.state.activeTab === 'INNER CIRCLE' ? 'den-selected-tab' :''} p-2 ps-3 my-1`} onClick={() => this.pageRedirect('inner-circle')}>INNER CIRCLE</p>
                    {/* <p className={`fs-16 fw-bold cursor-pointer ${this.state.activeTab === 'ACHIEVEMENTS' ? 'den-selected-tab' :''} p-2 ps-3 my-1`} onClick={() => this.pageRedirect('achievements')}>ACHIEVEMENTS</p> */}
                    <p className={`fs-16 fw-bold cursor-pointer ${this.state.activeTab === 'POSTS' ? 'den-selected-tab' :''} p-2 ps-3 my-1`} onClick={() => this.pageRedirect('posts')}>POSTS</p>
                </div>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </div>
    
        )
    }else{
        return(
            <div className='col-lg-3 d-none d-lg-block'><SideSkeleton /></div>
        )
    }
  }
}
