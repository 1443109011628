import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import InProgress from '../../images/common/Inprogress.png';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import {BsCreditCard2Back} from 'react-icons/bs'



export default class PaymentOptions extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    render() {
        return (
            <div className='body-section d-flex'>
                <NavLink to="/home">
                    <IoArrowBackCircleOutline className="back-btn" />
                </NavLink>
                <div className='container'>
                    <h4 className='text-center mt-2'><BsCreditCard2Back/> Payment Options</h4>
                    <div className='d-flex justify-content-center align-items-center h-75'>
                        <img src={InProgress} className="w-50" alt='' />
                    </div>
                </div>
            </div>
        );
    }
}
