import React, { Component } from 'react';

export default class SeedingSwitch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enableSeeding : props.enableSeeding
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            enableSeeding: newProps.enableSeeding
        });
    }


    render() {
        return (
            <div className="container d-flex mt-3" style={{ width: '100%' }} role="group" aria-label="Basic example">
                <button type="button" onClick={() => this.props.enableDisableSeeding()} style={{ width: '50%', opacity: !this.state.enableSeeding ? '1' : '0.4' }} className={`grey-card-2 rounded-1 text-white score-showdown-btn  rounds `}>Structure</button>
                <button type="button" onClick={() => this.props.enableDisableSeeding()} style={{ width: '50%', opacity: this.state.enableSeeding ? '1' : '0.4' }} className={`grey-card-2 rounded-1 text-white score-showdown-btn  rounds `}>Seeding</button>
            </div>
        );
    }
}