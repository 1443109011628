import { Component } from 'react';
import { getWebData } from '../../utils/common.utils';
import { RiQuestionnaireFill } from 'react-icons/ri';
import { Accordion } from 'react-bootstrap';


export default class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.websiteData = getWebData();
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    render() {

        const questions = this.websiteData?.STATIC_PAGE?.FAQS;
        return (
            <section className="body-section">      
                <div className="p-3 d-flex flex-column mx-auto notifications" >
                    <div className="row text-center">
                        <div className="col-12">
                        <h1 className="fs-20 fw-bold text-white mb-2 mt-3"> <RiQuestionnaireFill style={{color: 'white'}} />  FAQ's </h1>
                        </div>
                    </div>
                    <div className='row faq'>
                        <div >
                            <Accordion defaultActiveKey="0" flush>
                                {questions?.map((ques,i)=>{
                                    return (
                                        <Accordion.Item eventKey={i} key={i} className='mt-3' >
                                            <Accordion.Header className='fs-14'>{ques.QUESTION}</Accordion.Header>
                                            <Accordion.Body className='fs-14 fw-600 text-white-50'>{ques.ANSWER}</Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
