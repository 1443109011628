import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AiFillCloseCircle, AiFillPlusCircle, AiOutlineCaretDown, AiOutlineClose } from 'react-icons/ai'

//images
import user from '../../../../images/den/create-post/user.png';
import video from '../../../../images/den/create-post/video.png';
import image from '../../../../images/den/create-post/image.png';
import feeling from '../../../../images/den/create-post/feeling.png';
import poll from '../../../../images/den/create-post/poll.png';

import { POST_INTERACTIONS } from '../../../../utils/Images.utils';
import { getDetailsObj, isValidString, updateLoader } from '../../../../utils/common.utils';
import { toast } from 'material-react-toastify';
import CommonApis from '../../../../helper/common.api';
import { DEN, POST_FEELINGS } from '../../../../utils/Strings.utils';
import { createAndDraftDenPost, createAndShareDenPost, getDenPostById, getDenProfilePicture, getDenProfiles, updateAndShareDraftPost, updateDenPost, updateDenProfileInLocalStorage } from '../../../../utils/Den.utils';
import PostPollSection from '../common/createPost/PostPollSection';
import DenPoll from '../common/DenPoll';
import PostFeelingSection from '../common/createPost/PostFeelingSection';
import Image from '../../../common/Image';
import PostAudienceSection from '../common/createPost/PostAudienceSection';
import PostAsSection from '../common/createPost/PostAsSection';
import PostVideoSection from '../common/createPost/PostVideoSection';
import { profile } from '../../../../utils/localstorage.utils';
import LeftSideBar from '../common/LeftSideBar';
import RightSideBar from '../common/RightSideBar';
import { CREATE_POST_VIEWS } from '../../../../utils/enums.utils';
import liveStreamImage from  '../../../../images/den/live-stream.png'

/**
* @author
* @class DenCreatePost
**/


class DenCreatePost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentView: CREATE_POST_VIEWS.CREATE_POST,
            isPublicDenPost: true,
            postAs: 'SELF',
            chooseOrg: false,
            selectedOrg: '',
            description: '',
            images: [],
            selectedFeeling: '',
            selectedEmoji: null,
            pollData: null,
            denProfile: null,
            draftPostId: null,
            editPostDetails: null,
            updatePostId: null,
            streamingLink: null,
            isEditPost: false,
            pollDescription: null,
            detailsObj: null
        };
        this.commonApi = new CommonApis();
    }

    componentDidMount() {

        const queryParams = new URLSearchParams(window.location.search);
        const isEditPost = queryParams.get('isEditPost');
        const selectMedia = queryParams.get('select');
        if(selectMedia){
            this.redirectTo(selectMedia);
        }

        if(isEditPost && isEditPost === 'true'){
            this.updateState('isEditPost', true)
        }
        
        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile);

            if(this.props.match?.params?.draftPostId && this.props.match?.params?.draftPostId !== null && this.props.match?.params?.draftPostId !== 'null'){
                this.updateState('draftPostId',this.props.match.params.draftPostId);
                getDenPostById(this.props.match.params.draftPostId, this.setPostDetails);
                
            }else if(this.props.match?.params?.updatePostId && this.props.match?.params?.updatePostId !== null && this.props.match?.params?.updatePostId !== 'null'){
                getDenPostById(this.props.match.params.updatePostId, this.setPostDetails);
                this.updateState('updatePostId',this.props.match.params.updatePostId);
            }

        }
    }

    setCurrentUserDenProfile = (result) =>{
        if(result[0]){
            this.setState({ denProfile: result[0] })
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }

    setPostDetails = (result) =>{

        this.updateState('editPostDetails',result)
        this.updateState('feeling',result.feeling)
        if(result.type === "POLL"){
            this.updateState('pollData',result)
            this.updateState('pollDescription',result.pollDescription)
            this.updateState('description',result.description)
            this.updateState('isPublicDenPost',result.isPublic)
        }else{
            this.updateState('images',result.assets)
            this.updateState('description',result.description)
            this.updateState('isPublicDenPost',result.isPublic)
        }
        //this.updateState((result.type === "POLL" ? 'pollData' : 'images'), result.assets);

    }

   
    updateState = (key, val) => {
        this.setState({ [key] : val})
    }

    backToMainView = (key, val, key2, val2) => {
        this.setState({ 
            [key] : val,
            [key2]: val2
        })
    }

    createAndSharePost = (isShare) => { 
        var data;
        if(this.state.pollData){
            data = this.state.pollData;
            data.feeling = this.state.feeling;
            data.pollDescription = this.state.pollDescription;
            data.description = this.state.description;
            
        }else{
            data = {
                postAs : this.state.denProfile?.type,
                type : "POST",
                feeling : this.state.feeling,
                description : this.state.description,
                assets : this.state.images
            };
        }

        data.isDrafted = !isShare;
        data.denId = this.state.denProfile?._id;
        data.isPublic= this.state.isPublicDenPost
        if(isShare){
            if(!this.state.isEditPost){
                if(this.state.draftPostId){
                    data.isDrafted = false;
                    updateAndShareDraftPost(this.state.draftPostId, data, this.denPostShared)
                }else{
                    createAndShareDenPost(data, this.denPostShared, isShare);
                }
            }else{
                updateDenPost(this.state.draftPostId, data, (result)=>{
                    this.props.history.goBack();
                }, null);
            }

        }else{
            if(this.state.draftPostId){
               updateDenPost(this.state.draftPostId, {assets: data.assets, isPublic: this.state.isPublicDenPost, description: this.state.description, pollDescription: this.state.pollDescription}, ()=>{this.props.history.push(`/den/${this.state.denProfile?._id}`)},null)
            }else{
                createAndDraftDenPost(data, this.denPostShared, isShare);
            }
        }
    }

    shareStreamingOnTv = (isShare) => {
        var data = {
            type : "TV",
            description : this.state.description,
            feeling : this.state.feeling,
            postAs : this.state.denProfile?.type,
            assets : [this.state.streamingLink],
            denId : this.state.denProfile?._id,
            isDrafted : !isShare
        };


        if(isShare){
            createAndShareDenPost(data, this.denPostShared, isShare);
        }else{
            createAndDraftDenPost(data, this.denPostShared, isShare);
        }

    }

    denPostShared = (result, isShare?) =>{ 
        toast.success(`You post has been successfully ${isShare ? 'shared' : 'drafted' } !`);
        //this.props.updateDenProfile(result);
        //updateDenProfileInLocalStorage(result)
        this.props.history.push(`/den/${this.state.denProfile?._id}`)
    }

    redirectTo = (param) => {
        if(param === 'IMAGE'){
            this.triggerInputFile();
        }else if(param === 'VIDEO'){
            this.updateState('currentView',CREATE_POST_VIEWS.VIDEO_OPTIONS);
        }
    }


    triggerInputFile = () => this.fileInput.click();
    triggerInputFile2 = () => this.fileInput2.click();

    getCreatePostView = () => {
        const isPostEnabled =  (this.state.images.length > 0 || isValidString(this.state.description) || this.state.pollData !== null) ? true : false;
        
        var profilePicture = getDenProfilePicture(this.state.denProfile);


        return(
            <div className='col-12 col-lg-6 p-0'>
                <div className='post-card-bg p-2 pb-0 d-flex flex-column' style={{height:'85vh', overflow: 'auto'}}>
                    <div className='flex-grow-1'>
                        <div className='row px-2'>
                            <div className='col-3'></div>
                            <div className='col-6'>
                                <p className='fs-16 text-center fw-700 my-0'>{this.state.isEditPost ? 'Edit' : 'Create'} Post</p>
                            </div>
                            <div className='col-3 text-end'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.history.goBack()} />
                            </div>
                        </div>
                        <hr className='hr-line' />
                        <div className='d-flex p-2'>
                            <Image src={profilePicture?.url} param={this.state.denProfile?.name} className="rounded-circle" alt='' style={{height:'36px', width: '36px'}} />
                            <div className=' d-flex flex-column px-2'>
                                <h1 className='fs-14 fw-600 text-white mb-0'>{this.state.denProfile?.name}  <span style={{fontWeight: '300'}}>{POST_INTERACTIONS.FEELINGS[this.state.feeling]? `is feeling ${POST_INTERACTIONS.FEELINGS[this.state.feeling].NAME}` : this.state.feeling}</span>  {POST_INTERACTIONS.FEELINGS[this.state.feeling] && <img src={POST_INTERACTIONS.FEELINGS[this.state.feeling]?.LOGO} alt='' style={{height:'18px'}} />}  </h1> 
                                <div className=' d-flex align-items-center p-1' style={{border: '1px solid #AD26FF', borderRadius: '5px'}}>
                                    {!this.state.isPublicDenPost ? 
                                        <img src={POST_INTERACTIONS.AUDIENCE.FOLLOWERS} alt='' style={{height:'12px', width:'18px'}} />
                                        :
                                        <img src={POST_INTERACTIONS.AUDIENCE.PUBLIC} alt='' style={{height:'12px'}} />
                                    }
                                    
                                    <p className='fs-12 fw-400 text-white-50 my-0 mx-1'>{!this.state.isPublicDenPost ? 'Followers' : 'Public'}</p>
                                    <AiOutlineCaretDown style={{height:'12px', width:'12px', cursor:'pointer'}} onClick={() => this.updateState('currentView',CREATE_POST_VIEWS.AUDIENCE)} />
                                </div>
                            </div>
                            
                        </div>
                        <div className='p-2'>
                            <textarea rows={6} className='fs-14 fw-600 text-white w-100 p-1' placeholder='Write Something...' 
                                style={{ whiteSpace: 'pre-wrap', background:'transparent', borderRadius:'8px', border: '1px solid #ffffff4d' }}
                                onChange={(e) => this.updateState('description', e.target.value)} maxLength={4000}
                                defaultValue= {this.state.editPostDetails ? this.state.editPostDetails.description : this.state.description}
                            >
                               
                            </textarea>
                        </div>

                        {this.state.streamingLink && 
                            <div className='row my-2'>
                                <div className='col-4 col-lg-2 my-1' >
                                    <span>
                                        <AiFillCloseCircle className="btn-size-15 position-absolute text-white" style={{ marginTop: '-8px' }} onClick={() => this.setState({streamingLink: null}) } />
                                        <img src={liveStreamImage} alt='' className='mx-2' style={{height: '100px', width: 'auto'}} />
                                    </span>
                                </div>
                            </div>
                        }

                        {this.state.pollData  ?
                            <DenPoll onClicEditPoll={this.onClicEditPoll} draftPostId={this.state.draftPostId} updateState={this.updateState} post={this.state.pollData} denProfile={this.state.denProfile} updatePostDetails={()=>{}} enableEdit={true}  />
                        :
                            <div className='row my-2'>
                                {
                                    this.state.images?.map((el, i) => {
                                        return (
                                            <div className='col-4 col-lg-2 my-1' key={i}>
                                                <span>
                                                    <AiFillCloseCircle className="btn-size-15 position-absolute text-white" style={{ marginTop: '-8px' }} onClick={() => { this.removeImg(i) }} />
                                                    {el.type?.includes('image') ? 
                                                        <img src={el?.url} alt='' className='mx-2' style={{height: '50px', width: 'auto'}} />
                                                        :
                                                        <video className='mx-2' style={{height: '50px', width: 'auto'}}>
                                                            <source src={el.url} type="video/mp4" />
                                                        </video>
                                                    }
                                                </span>
                                                <p className='text-center my-0'>{el.type?.includes('image') ? 'Image' : 'Video'}</p>
                                            </div>
                                        )
                                    })
                                }
                                
                            </div>
                        }
                    </div>
                    {/* Bottom Section */}
                    <div className='row'>
                        <div className='col-12'>
                            <div className='p-2 pb-0'>
                                <div className='w-100'>
                                    {/* <p className='fs-14 fw-700'>Post as</p> */}
                                    {this.state.isEditPost ? 
                                        <div className='row my-2'>
                                            <div className='col-6 p-2'>
                                                <button className={`normal-post-btn selected-post-btn w-100 `} onClick={ isPostEnabled ? ()=>this.createAndSharePost(true) : ()=>{}}>Update</button>
                                            </div>
                                            <div className='col-6 p-2'>
                                                <button className={`normal-post-btn w-100`} disabled onClick={() => this.props.history.goBack()}>Cancel</button>
                                            </div>
                                        </div>
                                    :
                                        <div className='row my-2'>
                                            {this.state.streamingLink ?
                                                <>
                                                    <div className='col-6 p-2'>
                                                        <button className={`normal-post-btn selected-post-btn w-100 `} onClick={()=>this.shareStreamingOnTv(true)}>Share On TV</button>
                                                    </div>
                                                    <div className='col-6 p-2'>
                                                        <button className={`normal-post-btn w-100`} disabled onClick={()=>this.shareStreamingOnTv(false)}>Save as Draft</button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='col-6 p-2'>
                                                        <button className={`normal-post-btn selected-post-btn w-100 ${isPostEnabled ? '' : 'opacity-25'}`} onClick={ isPostEnabled ? ()=>this.createAndSharePost(true) : ()=>{}}>POST</button>
                                                    </div>
                                                    <div className='col-6 p-2'>
                                                        <button className={`normal-post-btn w-100 ${isPostEnabled ? '' : 'opacity-25'}`} onClick={ isPostEnabled ? ()=>this.createAndSharePost(false) : ()=>{}}>Save as Draft</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                           
                        </div>
                        <div className='col-12 px-0'>
                            <div className='p-0 pt-2'>
                                <div className='w-100'>
                                    <div className='create-post-options-box' style={{minHeight:'20px'}}>
                                        {this.state.pollData || isValidString(this.state.streamingLink?.url) ?
                                            <div className='d-flex justify-content-around align-items-center p-2 opacity-25'>
                                                <img src={video} alt='' className='den-icon' style={{width:'28px'}}  />
                                                <img src={image} alt='' className='den-icon' />
                                                <img src={poll} alt='' className='den-icon' />
                                                <img src={feeling} alt='' className='den-icon'/>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-around align-items-center p-2'>
                                                <img src={video} alt='' className='den-icon' style={{width:'28px'}} onClick={() => this.updateState('currentView',CREATE_POST_VIEWS.VIDEO_OPTIONS)} />
                                                <input type="file" multiple id="upload_file" accept=".png, .jpg, .jpeg" hidden ref={fileInput => this.fileInput = fileInput} onChange={this.uploadImages} name="" className="" />
                                                <img src={image} alt='' className='den-icon' onClick={this.triggerInputFile} />
                                                <img src={poll} alt='' className='den-icon' onClick={() => this.updateState('currentView',CREATE_POST_VIEWS.POLL_OPTIONS)} />
                                                <img src={feeling} alt='' className='den-icon' onClick={() => this.updateState('currentView',CREATE_POST_VIEWS.FEELING)} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }

    getVideoOptionsView = () => { 
      
    }

    getView = () => { 
        const {currentView} = this.state;
        switch (currentView) {
            case CREATE_POST_VIEWS.FEELING:
                return <PostFeelingSection updateState={this.updateState} pollData={null} backToMainView={this.backToMainView} />
            case CREATE_POST_VIEWS.AUDIENCE:
                return <PostAudienceSection isPublicDenPost={this.state.isPublicDenPost} updateState={this.updateState} pollData={null} backToMainView={this.backToMainView} />
            case CREATE_POST_VIEWS.POST_AS:
                return <PostAsSection postAs={this.state.postAs} chooseOrg={this.state.chooseOrg} updateState={this.updateState} pollData={null} backToMainView={this.backToMainView} />
            case CREATE_POST_VIEWS.VIDEO_OPTIONS:
                return <PostVideoSection uploadVideo={this.uploadVideo} streamingLink={this.state.streamingLink} fileInput2={this.fileInput2} updateState={this.updateState} pollData={null} backToMainView={this.backToMainView} />  
            case CREATE_POST_VIEWS.POLL_OPTIONS:
                if(this.state.editPollData){
                   
                    return <PostPollSection updateState={this.updateState} pollData={this.state.editPollData} backToMainView={this.backToMainView} />  
                }else{
                    return <PostPollSection updateState={this.updateState} pollData={null} backToMainView={this.backToMainView} /> 
                }
            default:
                return this.getCreatePostView();
        }
    };

    onClicEditPoll = () =>{
      
          var editPollData = {
            question: this.state.pollData?.pollDescription,
            options: this.state.pollData?.assets?.map((obj)=> {return obj.answer})
          }
          this.setState({
            editPollData: editPollData,
            currentView: CREATE_POST_VIEWS.POLL_OPTIONS
          });

    }

    triggerInputFile = () => {
        if(this.state.images.length === 20){
            toast.warning('Maximum 20 images are allowed');
            return false;
        }
        this.fileInput.click();
    };

    uploadImages = (event) => {
        if (event.target.files?.length && event.target.files?.length < 6 && this.state.images?.length < 6) {
            // let img = event.target.files[0];
            // if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
            //     toast.warning('Please select a valid image type.');
            //     return false;
            // }
            const formData = new FormData();
            for (let i = 0; i < event.target.files?.length; i++) {
                formData.append(i, event.target.files[i])
            }
            
            updateLoader(true)
            this.commonApi.uploadMultipleImg(formData)
                .then((response) => {
                    let images = this.state.images
                    for (let i = 0; i < response.data.result?.length; i++) {
                        if (images?.length <= 20) {
                            images.push(response.data.result[i])
                        }else{
                            toast.error('You cannot upload more than 20 files');
                            return false;
                        }
                    }
                    this.setState({ images: images });
                    updateLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    updateLoader(false)
                });
        }
    }

    uploadVideo = (event) => {
    // console.log('----------------------------------Inside Upload Video---------------------------------');
        let vid = event.target.files[0];
        let videoDuration = null;
        if (!vid.name.match(/\.(mp4|mov|mkv|wmv|webm)$/)) {
            toast.warning('Please select a valid video type.');
            return false;
        }
        var video = document.createElement('video');
        const formData = new FormData();
        formData.append('file', event.target.files[0])
        // for (let i = 0; i < event.target.files?.length; i++) {
        //     formData.append(i, event.target.files[i])
        // }
        //console.log("VIDEO FILE :: ",event.target.files[0])
        //console.log("VIDEO link:: ", URL.createObjectURL(event.target.files[0]))
        video.src = URL.createObjectURL(event.target.files[0]);
        video.ondurationchange= function(){
            videoDuration = this.duration;
            //console.log('videoDuration',videoDuration);
        }
        updateLoader(true)
        setTimeout(() => {
            if(videoDuration > 15){
                toast.error('Please upload a short video of max 15 seconds');
                updateLoader(false)
                return false;
            }
            this.commonApi.uploadMultipleVideo(formData)
                .then((response) => {
                    let images = this.state.images
                        if(images.length <= 20) {
                            images.push(response.data.result);
                        }else{
                            toast.error('You cannot upload more than 20 files');
                            return false;
                        }
                        this.setState({
                            images: images, currentView: CREATE_POST_VIEWS.CREATE_POST
                        });
                        updateLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    updateLoader(false)
                });
        }, 3000);
    }

    removeImg = (i) => {
        let images = this.state.images;
        images.splice(i, 1);
        this.setState({ images: images });
    };

    render() {
        return(
            <div className='body-section den_page'>
                <div className='container'>
                    <div className='row'>
                        <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                        {this.getView()}
                        <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                    </div>
                </div>
            </div>
        )
    }
}


DenCreatePost.propTypes = {}
export default DenCreatePost