import { Component } from "react";
// Assets
import { BsArrowLeftShort } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
// Style
import "../../styles/ForgotPassword.css";
// Utils
import { AxiosResponse, AxiosError } from "axios";
import validator from "validator";
import { errorHandler, updateLoader } from "../../utils/common.utils";
import { toast } from 'material-react-toastify';
// Services
import AuthApis from "../../helper/auth.api";

export default class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailErr: true,
            emailErrMsg: '',
            isDisabled: false
        };

        this.api = new AuthApis();
    }

    // Set email to process email validation
    updateEmail = (e) => {
        this.setState({ email: e.target.value })
        this.validateEmail(e.target.value);
    }

    // Send reset password link on email
    handleSendVerificationClick = (event) => {
        event.preventDefault();
        var data = {
            email: this.state.email
        };
        if (data.email === ''){
            toast.warning('Email is required')
            return false
        }
        updateLoader(true);
        this.api.forgotPassword(data).then((response: AxiosResponse) => {
            toast.success(response.data.message);
            this.handleCloseClick();
            updateLoader(false);
        }).catch((reason: AxiosError) => {
            updateLoader(false);
            errorHandler(reason);
        });

    }

    // Go back to login page
    backToLogin = () => {
        this.props.resetAndUpdateFlag(true,'openLogin',true);
    }

    // Discard the process of forgot password
    handleCloseClick = () => {
        this.props.resetAndUpdateFlag(false,'openForgetPassword',false);
    }

    // Validate email if it's valid or not
    validateEmail = (email) => {
        if (email === "") {
            this.setState({
                emailErr: true,
                emailErrMsg: "*email required",
            });
        } else {
            if (validator.isEmail(email)) {
                this.setState({
                    emailErr: false,
                    emailErrMsg: "Valid Email",
                    isDisabled: true
                });
            } else {
                this.setState({
                    emailErr: true,
                    emailErrMsg: "Invalid Email",
                    isDisabled: false
                });
            }
        }
    }

    render() {
        const { emailErr, emailErrMsg, isDisabled } = this.state;
        const buttonClass = isDisabled ? '' : 'button-disabled'
        return (
            <div className="p-4">
                <div className="row">
                    <form>
                        <div className="col-12 col-md-6 col-lg-6  d-flex flex-column">
                            <div className="">
                                <BsArrowLeftShort className="back-arrow position-absolute" style={{ top: '23px', left: '15px' }} onClick={this.backToLogin} />
                                <h1 className="text-heading fs-20 fw-600 text-white text-center">Forgot Password</h1>
                                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ top: '23px', right: '15px' }} onClick={this.handleCloseClick} />
                            </div>
                            <p className="text-description fs-14 text-white text-center pt-2 fw-normal mb-1">Enter your E-Mail Address to receive a verification link</p>

                            <div className="">
                                <label htmlFor="email" className="fs-16 fw-600 text-white mb-1">Email Address</label>
                                <div className="">
                                    <AiOutlineMail className="field-icon position-absolute" style={{ marginTop: '12px' }} />

                                    <input type="text" id="email" placeholder="Enter Email" autoComplete="off" className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white height-45" onChange={(e) => this.updateEmail(e)} />
                                </div>
                                {emailErr && <p className="error-msg">{emailErrMsg}</p>}
                            </div>

                            <button disabled={!isDisabled} className={`white-flat-btn w-75 fw-bold py-2 mt-4 m-auto ${buttonClass}`} onClick={(e) => this.handleSendVerificationClick(e)} type="submit">
                                Get Link
                            </button>

                            <p className="fs-14 text-white mt-3 mb-0">
                                Did not receive the verification link?<br />
                                <span className="fw-bold text-decoration-underline" onClick={(e) => this.handleSendVerificationClick(e)}>Send Again</span>
                            </p>
                        </div>
                    </form>

                </div>
            </div>
        )
    }
}