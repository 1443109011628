import { actionTypes } from "../constants/actionTypes";

const initialState = {}
export const tournamentDetailsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_TOURNAMENTDETAILS:
            return payload;
        default:
            return state;
    }
}
export const updateScoreReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_UPDATE_SCORE:
            return payload;
        default:
            return state;
    }
}