import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoIosArrowBack, IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import { DEN_POST_REPORT_TYPES } from '../../../../utils/enums.utils';

export default class ReportPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            post: props.post,
            i: props.i,
            denProfile: props.denProfile,
            interactions: props.interactions,
            feedDetails: props.feedDetails,
            selectedPostIndex: null,
            postToInteract: null,
        };
    }

    componentWillReceiveProps(newProps){
        this.setState({
            post: newProps.post,
            i: newProps.i,
            denProfile: newProps.denProfile,
            interactions: newProps.interactions,
            feedDetails: newProps.feedDetails,
        })
    }

  render() {
    return (
        <Modal className="d-flex flex-column post-bottomsheet w-100" show={this.props.openPostReportModal} dialogClassName="testsss my-0">
        {/* <div className='text-center cursor-pointer'><AiFillCloseCircle onClick={()=>this.props.showHidePostAction(null, false, false)} style={{height:'40px', width:'40px', color: 'white' }} /></div> */}
            <div className='post-action-container p-2'>
                <div className='d-flex justify-content-between align-items-center'>
                    <IoIosArrowBack className='text-white' style={{height:'20px', width:'20px', cursor: 'pointer'}} onClick={() => this.props.showHidePostAction(null,true, false)} />
                    <p className='text-white fs-16 fw-600 my-0'>Report</p>
                    <AiOutlineCloseCircle className='text-white' style={{height:'20px', width:'20px', cursor: 'pointer'}} onClick={() => this.props.showHidePostAction(null, false, false)} />
                </div>
                <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                <p className='text-white fs-14 fw-600'>Please select a reason to report</p>
                <p className='fs-14 fw-600 text-white-50 my-0'>Reported posts will be reviewed by Espotz.<br/>The action to be taken solely depends on the espotz administrative.</p>
                <hr className='my-0' style={{border: '1px solid #6D53DB'}} />
                <div className='my-1'>
                    {Object.keys(DEN_POST_REPORT_TYPES).map((key,i)=>{
                        if(key !== 'OTHER'){
                            return (
                                <div key={i}>
                                    {this.props.selectedReportType === DEN_POST_REPORT_TYPES[key].TYPE ? <IoMdRadioButtonOn className='text-white' id={key} key={i} onClick={()=>this.props.onCheckReportType(null)}/> : <IoMdRadioButtonOff className='text-white' id={key} onClick={()=>this.props.onCheckReportType(key)}/> }
                                    <label  className="fs-14 fw-600 my-0 ms-2 text-white">{DEN_POST_REPORT_TYPES[key].NAME}</label>
                                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />
                                </div>
                            )                                                    }
                    })}
                    {this.props.selectedReportType === DEN_POST_REPORT_TYPES.OTHER.TYPE ? <IoMdRadioButtonOn className='text-white' id='OTHER' onClick={()=>this.props.onCheckReportType(null)}/> : <IoMdRadioButtonOff className='text-white' id='OTHER' onClick={()=>this.props.onCheckReportType('OTHER')}/> }
                    <label htmlFor='' className="text-white fs-14 fw-600 my-0 ms-2">Others</label> <br />
                    <input type='text' onChange={(e)=>this.props.onChangeReportDescription(e.target.value)} defaultValue={this.props.selectedReportTypeDescription} placeholder='Tell us more about the issue..' className='w-100 fs-12 fw-600 text-white' style={{background:'transparent', border: 'none', outline: 'none' }} />
                    <hr className='my-1' style={{border: '1px solid #6D53DB'}} />

                    <div className={`w-100 text-center my-2 ${this.props.isRoportingEnabled ? '' : 'opacity-25'}`}>
                        <button type="button" className='report-post-btn'  disabled={!this.props.isRoportingEnabled} onClick={this.props.onSubmitReport}>Report</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
  }
}
