import { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../styles/activate-account.css";
import DefaultImage from '../../images/image 65.jpg'
class ShowMember extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount() {
        //console.log(this.props.members.members)
    }

    render() {
        return (
            <div className="p-3 d-flex flex-column" style={{maxHeight: '500px'}}>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => { this.props.onClickOpenShowMember() }} />
                <p className="fs-20 fw-600 text-center text-white mb-2">{this.props.members.title}</p>
                <div className="d-flex flex-column overflow-auto flex-grow-1">
                {
                    this.props.members.members ?
                        this.props.members.members.map((el, i) => {
                            //console.log('participant details::', el);
                            return (
                                <div className="mulberry-purple-card d-flex align-items-center mt-2 p-2" key={i}>
                                    <div >
                                        <img className="btn-size-32 ms-3 border-1 rounded-circle" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="" />
                                    </div>
                                    <div className="d-flex flex-column  w-75 w-md-100">
                                        <div className="ms-3 fs-16 fw-500 text-white text-start text-truncate">{(this.props.members.title === 'Team Members') ? el.inGameName : el?.username}</div>
                                        {el?.title && <div className="ms-3 fs-10 fw-500 text-white text-start" style={{ fontSize: '11px' }}>({el.title})</div>}
                                    </div>
                                </div>
                            )
                        })
                        : null
                }
                </div>
                <button type="button" className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3" onClick={() => { this.props.onClickOpenShowMember() }}>Ok</button>
            </div>
        )
    }
}

export default ShowMember