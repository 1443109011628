import React, { Component } from 'react';
import { getMarginTopForRounds, getMonthAndDate, saveCloseRegistration, errorHandler, linkWithDiscord } from '../../../../../../utils/common.utils';
import participantAvatar from '../../../../../../images/participantAvtar.jpg';
import { HiUserAdd } from "react-icons/hi";
import { addParticipantInTournament, addParticipantToFirstRound2, getMyTeams, getStageDetails } from '../../../../../../utils/Tournament.utils';
import { toast } from 'material-react-toastify';
import AcceptInvite from '../../../../../popup/AcceptInvite';
import MatchInvite from '../../../../../popup/MatchInvite';
import { Modal } from "react-bootstrap";
import Registration from '../../../Registration';
import SingleBrackets from '../../../../../common/SingleBrackets';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import { profile } from '../../../../../../utils/localstorage.utils';
import { ERROR } from '../../../../../../utils/Strings.utils';
import Scoreboard from '../../../Scoreboard';

export default class DuelsSingleBracketGroups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stageDetails: props?.stageDetails? props.stageDetails : null,
            tournamentDetails: props?.tournamentDetails? props.tournamentDetails : null,
            groupIndex: 0,
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null,
            showRegistrationScreen: false,
            matchIndex: '0.0',
            showScoreBoard: false,
            matchDetails: null,
            matchNo: null,
        }

        this.enableLoading = false;
    }

    componentDidMount(){
        if(this.props.setGetAlertMethod){
            this.props.setGetAlertMethod(this.registerForTournament);
        }

        saveCloseRegistration(this.closeRegistrationScreen);
    }

    componentWillReceiveProps(newProps) {
        if(!this.enableLoading){
            this.setState({
                stageDetails: newProps?.stageDetails? newProps.stageDetails : this.state.stageDetails,
                tournamentDetails: newProps?.tournamentDetails? newProps.tournamentDetails : this.state.tournamentDetails,
            })
        }
    }

    calculateMatches = (num,threshold) => {
        var stages = 1;
        var arr = [num];
        while (num !== 1) {
        num = (num / 2);
        arr.push(num);
        stages++;
        if (num === threshold) break;
        }
        return arr;
    }

    // START :: Tournament Registration  Process
    registerForTournament = (e, matchDetail, matchId, matchIndex) => {
    e.preventDefault();

        if(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true && this.state.tournamentDetails?.participantType === 'TEAM'){
            this.switchRegistrationScreens(matchDetail,matchIndex);
        }else{

            this.enableLoading = true;
            if(this.state.tournamentDetails?.participantType === 'SINGLE'){
                this.setState({
                    initiateRegistration: true,
                    selectedMatchDetail: matchDetail,
                    selectedMatchIndex: matchIndex
                });
            }else{
                this.setState({
                    selectedMatchDetail: matchDetail
                });
                getMyTeams(this.state.tournamentDetails.gameId, matchDetail, this.addParticipantInTournament, this.selectTeams)
            }
        }
    }   

    selectTeams = (success,data) =>{
        if(success){
            this.setState({
                teamsArray: data.teamsArray,
                initiateRegistration: true,
                selectedMatchDetail: data.selectedMatchDetail
            });
        }else{
            toast.warning(ERROR.NO_TEAM_FOR_TOURNAMENT);
        }

    }

    // addParticipantInTournament = (matchId, teamId) =>{
    //     if(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true){
    //         this.switchRegistrationScreens(this.state.selectedMatchDetail,this.state.selectedMatchIndex,teamId);
    //         this.resetRegistration();
    //     }else{
    //         addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail._id, teamId, this.state.stageDetails, this.state.tournamentDetails);
    
    //     }
    // }

    addParticipantInTournament = (matchId, teamId) =>{
        if(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true){
            this.switchRegistrationScreens(this.state.selectedMatchDetail,this.state.selectedMatchIndex,teamId);
            this.resetRegistration();
        }else{
           const stageInfo = this.state.tournamentDetails.stages[0];
            if(stageInfo.type === "DUEL" && (stageInfo.subType == "Single Elimination" || stageInfo.subType == "Double Elimination" || stageInfo.subType == "Bracket Groups" )){
                var data = { 
                    participantId : teamId,
                    groupIndex: this.state.groupIndex
                }
                addParticipantToFirstRound2(this.state.tournamentDetails, data, this.updateStageDetails);
            
            }else{
                addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail._id, teamId, this.state.stageDetails, this.state.tournamentDetails);
            }
        }
    }

    // onClickOpenInvite = (joinTeamId?) => {
    //     this.setState({
    //         initiateRegistration: !this.state.initiateRegistration
    //     });
    //     if (joinTeamId) {
    //         addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails);
    //     }
    //     this.resetRegistration();
    // }

    onClickOpenInvite = (joinTeamId?) => {
        this.setState({
            initiateRegistration: !this.state.initiateRegistration
        });
        if (joinTeamId) {
            const stageInfo = this.state.tournamentDetails.stages[0];
            if(stageInfo.type === "DUEL" && (stageInfo.subType == "Single Elimination" || stageInfo.subType == "Double Elimination" || stageInfo.subType == "Bracket Groups" )){
                var data = { 
                    participantId : joinTeamId,
                    groupIndex: this.state.groupIndex
                }
                addParticipantToFirstRound2(this.state.tournamentDetails, data, this.updateStageDetails);
            
            }else{
                addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails);
            }
        }
        this.resetRegistration();
    }

    updateStageDetails = (response) =>{
        this.setState({
            stageDetails: response,
        });
        this.resetRegistration();
    }

    resetRegistration = () =>{
        this.setState({
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null
        });
    }

    switchRegistrationScreens = (matchDetail, matchIndex, teamId?) =>{
        this.setState({
            showRegistrationScreen : !this.state.showRegistrationScreen,
            selectedMatchDetail: matchDetail,
            matchIndex: matchIndex,
            selectedTeams: (teamId ? teamId : null)
        })
    }
  
    // END :: Tournament Registration Process
  
    closeRegistrationScreen = () => {
        getStageDetails(this.state.tournamentDetails?._id,this.props.index,this.setData)
    }

    setData = (values) => {
        if (values) {
            this.setState({
                stageDetails: values,
                showRegistrationScreen: false
            });
        }else{
            this.setState({
                showRegistrationScreen: false
            });
        }
    }

    onClickRegister = () =>{
 
        if(profile()?.discordProfile?.discordUsername){
            return (
                <Modal.Body>
                    <AcceptInvite
                        clearInvitations={this.resetRegistration}
                        tournamentId={this.state.tournamentDetails?._id}
                        addParticipantInTournament={this.addParticipantInTournament}
                        joinTeamRole={null}
                        close={this.resetRegistration}
                        type="add"
                        info={{}}
                        title='Register'
                        role='player'
                        // matchId={this.state.selectedMatchDetail?._id}
                        isTournamentInvitation={true}
                    />
                </Modal.Body>
            );
        }else{
            return (
                <Modal.Body>
                    <ConfirmationModal confirm={linkWithDiscord()} onClickOpenConfirm={this.resetRegistration} confirmed={this.confirmed} />
                </Modal.Body>
            );
        }
    }

    confirmed =()=>{
        this.resetRegistration();
        this.props.history.push('/profile');
    }

    hideAndShowScoreBoard = (matchDetails?, matchNo?) => {
        this.setState({
            showScoreBoard: !this.state.showScoreBoard,
            matchDetails: !this.state.showScoreBoard === true ? matchDetails : null,
            matchNo
        });
    }

    render() {
   if(this.state.stageDetails){
            if(this.state.showRegistrationScreen){
                return (
                    <Registration 
                        tournamentDetails={this.state.tournamentDetails} 
                        stageDetails={this.state.stageDetails}  
                        selectedMatchDetail={this.state.selectedMatchDetail}
                        matchIndex={this.state.matchIndex}
                        selectedTeams={this.state.selectedTeams}
                    />
                )
            }else{
                var pairs = (this.state.stageDetails.maxParticipants / 2)
                var threshold = this.state.stageDetails.qualificationThreshold === 1 ? 1 : (this.state.stageDetails.qualificationThreshold/ 2);        var structure = this.calculateMatches(pairs, threshold);
                var structure = [];
                Object.keys(this.state.stageDetails?.groups[this.state.groupIndex]).map((key, i) => {
                    structure[i]= this.state.stageDetails?.groups[this.state.groupIndex][key].length;
                });
                var marginTopDiv = 0;
    
                return (
                    <div id='tournament-date'>
                        {/* Groups Filter ( Winners or Looser ) */}
                        <div className='overflow-auto ms-2 mb-2' >
                            <div className='' style={{width:'max-content'}}>
                                {this.state.stageDetails?.groups?.map((group, index) => {
                                    return (
                                        <button style={{padding:'15px 45px',boxShadow: '0.35px 3.35px 0.625px rgb(255 255 255 / 0%), 2.65px 2.65px 6.625px rgb(21 0 0 / 68%), inset -2.65px -2.65px 5.3px rgb(17 13 64 / 50%)', opacity: this.state.groupIndex === (index) ? '1' : '0.5'}} key={index} onClick={() => this.setState({groupIndex: index})} className='black-round-btn fs-16 fw-600 border border-dark text-warning me-4 rounded-10 mb-1' type='submit'>
                                            {this.state.stageDetails.groupsName[index]}
                                        </button> 
                                    )
                                })}
                            </div>
                        </div>
    
                        {/* Brackets Structure */}
                        {
                            this.state.showScoreBoard ? 
                            <Scoreboard
                                matchDetails={this.state.matchDetails}
                                hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                                tournamentDetails={this.state.tournamentDetails}
                                matchNo={this.state.matchNo}
                            />
                            :
                            <SingleBrackets 
                                structure={structure} 
                                includeThirdPlace={false} 
                                isSwappingEnabled={false} 
                                handleInputChange={this.handleInputChange} 
                                openInviteModal={this.registerForTournament} 
                                stageMatchDetails={this.state.stageDetails?.groups[this.state.groupIndex]} 
                                isTournamentOperator={false}
                                isBracketGroup={true}
                                tournamentDetails={this.state.tournamentDetails}
                                stageDetails={this.state.stageDetails}
                                index={this.props.index}
                                hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                                marginTopDiv=''
                            /> 
                        }
    
                        {/* Tournament Invitation Section */}
                        <Modal centered show={this.state.initiateRegistration} dialogClassName="ClanInvite-modal">
                        {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                            this.onClickRegister()
                            :
                            <Modal.Body>
                                <MatchInvite
                                    onClickOpenInvite={this.onClickOpenInvite}
                                    teamsArray={this.state.teamsArray}
                                    choice="Join Tournament"
                                    isRoundRobin={false}
                                />
                            </Modal.Body>
                        }
                        </Modal>
    
                    </div>
                );
            }
           
        }else{
            return (
                <div>
                    <h1>No Data Found</h1>
                </div>
            );
        }

    }
}