import React, { Component}from 'react';
import { scoreCardGameURL } from '../../../utils/common.utils';
//images
import matrix from '../../../images/score/matrix.png';
import orgIc from "../../../images/icon/org-ic.png";
import { Table } from 'react-bootstrap';

export default class PointsMatrix extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getPointsTable = () => { 
        const pointSystem = this.state.tournamentDetails?.gameDetails?.pointSystem;
        return(
            <div className='container'>
                <div className='d-flex align-items-center mb-3'>
                    <img src={matrix} alt='' />
                    <p className='fs-16 fw-bold my-0 ms-2'>Points Matrix</p>
                </div>
                <Table striped bordered hover className='text-center' style={{ border: '1px solid white', borderRadius:'5px'}}>
                    <thead style={{color: '#d1d1d1'}}>
                    <tr>
                        <th>Criteria</th>
                        <th>Points</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(pointSystem).map((key,i) => {
                        return(
                            <tr key={i}>
                                <td className='text-white-50 fw-bold'>Rank {key}</td>
                                <td className='text-white-50 fw-bold'>{pointSystem[key]} Points</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        const competitionType = this.state.tournamentDetails?.competitionType;
        return (
            <section className='tournament-scoreboard-page text-white p-3'>
                {competitionType === 'FFA' && this.getPointsTable()}
            </section>
        )
    }
}