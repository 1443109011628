import { Component } from 'react'
//images , icons
import streams from '../../../images/icon/streaming-ic.png'
import share from '../../../images/page/share.png'
import avatar from '../../../images/participantAvtar.jpg';
import Dropdown from 'react-bootstrap/Dropdown';
import { Accordion } from "react-bootstrap";

import ReactPlayer from 'react-player'
import TournamentFilter from '../../organization/tournaments/common/TournamentFilter';
import { errorHandler } from '../../../utils/common.utils';
import MatchesApis from '../../../helper/matches.api';

export default class Streams extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tournamentDetails: props?.tournamentDetails ? props?.tournamentDetails : [],
      streams: props?.tournamentDetails?.streams ? props?.tournamentDetails?.streams : [],
      selectedStream: props?.tournamentDetails?.streams?.length ? props?.tournamentDetails?.streams[0]?.label : null,
      selectedLink: props?.tournamentDetails?.streams?.length ? props?.tournamentDetails?.streams[0]?.link : null,
      stage: 0,
      group: 0,
      round: 1,
      matches: [],
      showLoosingBracket: false,
      isTorunamentStream: true
    }
    this.matchesApis = new MatchesApis();
  }

  onSelectStrem = (e) => {
    console.log("rrr - ", e.target.name)
    let index = e.target.name
    if (e.target.name) {
      this.setState({ selectedStream: this.state.streams[index]?.label, selectedLink: this.state.streams[index]?.link })
    }
  }
  componentDidMount() {
    if (this.props.tournamentDetails?.stages?.length) {
      this.filterMatches(0, 0, 1)
    }
  }
  componentWillReceiveProps(props) {
    this.setState({
      streams: props?.tournamentDetails?.streams,
      selectedStream: props?.tournamentDetails?.streams?.length ? props?.tournamentDetails?.streams[0]?.label : null,
      selectedLink: props?.tournamentDetails?.streams?.length ? props?.tournamentDetails?.streams[0]?.link : null
    });
  }
  filterMatches = (stage, group, round) => {
    console.log(`stage :: ${stage} || group :: ${group} || round :: ${round}`)

    this.setState({
      stage: stage,
      group: group,
      round: round
    });
    if (this.state.tournamentDetails?.stages[stage]?.subType === 'Bracket Groups' && this.state.tournamentDetails?.stages[stage]?.bracketType === 'DOUBLE') {
      this.getMatchDetails(this.state.tournamentDetails?.stages[stage]?.groups[this.state.showLoosingBracket ? 1 : 0][group][round]);
    } else {
      this.getMatchDetails(this.state.tournamentDetails?.stages[stage]?.groups[group][round]);
    }

  }

  switchWinnersBracket = (value, stage, group, round) => {
    this.setState({
      showLoosingBracket: value
    });
    this.getMatchDetails(this.state.tournamentDetails?.stages[stage]?.groups[value ? 1 : 0][group][round]);
  }

  getMatchDetails = (matches) => {

    var data = {
      ids: matches
    }
    this.props.updateLoader(true)
    this.matchesApis.getMultipleMatches(data).then(
      (res: AxiosResponse) => {
        this.props.updateLoader(false)

        this.setState({
          matches: res.data.result
        })
      }
    ).catch(
      (err: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(err);
      }
    )
  }
  render() {
    const { stage, group, round, matches, tournamentDetails } = this.state;
    console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
    console.log(`stage :: ${stage} || group :: ${group} || round :: ${round}`)
    console.log("~~~~~MATCHES :: ", matches);
    const isDoublesBracketGroup = (tournamentDetails?.stages[stage]?.subType === 'Bracket Groups' && tournamentDetails?.stages[stage]?.bracketType === 'DOUBLE');

    return (
      <section className='streams d-flex flex-column p-3'>
        <div className='d-flex align-items-center'>
          <img src={streams} alt='streams' className='me-2' style={{ height: '16px', width: '16px' }} />
          <h1 className='fs-16 fw-600 text-white mb-0'>Streams</h1>
        </div>
        <div className="btn-group mt-3" style={{ width: '100%' }} role="group" aria-label="Basic example">
          <button type="button" onClick={() => this.setState({ isTorunamentStream: true })} style={{ width: '50%', opacity: this.state.isTorunamentStream ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds mb-4`}>Tournament</button>
          <button type="button" onClick={() => this.setState({ isTorunamentStream: false })} style={{ width: '50%', opacity: !this.state.isTorunamentStream ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds mb-4`}>Matches</button>
        </div>
        {
          this.state.isTorunamentStream ?
            <div>
              <div className='row'>
                <div className='col-lg-6 offset-lg-3 col-12'>
                  <Dropdown onClick={() => { }} className="stream-dropdown mb-2 select-game-dropdown my-3">
                    <Dropdown.Toggle className='common-bg border-0 w-100 text-start p-3 bg-transparent'>
                      {
                        this.state.selectedStream ? this.state.selectedStream : 'Select Stream'
                      }

                    </Dropdown.Toggle>
                    {this.state.streams?.length ?
                      <Dropdown.Menu onClick={(e) => this.onSelectStrem(e)}>
                        {
                          (this.state.streams?.length > 1) && <Dropdown.Item href="#" name='' className='text-white'>All Strems</Dropdown.Item>
                        }
                        {
                          this.state.streams.map((el, i) => {
                            return (
                              <Dropdown.Item key={i} href="#" name={i} className='text-white me5'>{el.label}</Dropdown.Item>
                            )
                          })
                        }
                      </Dropdown.Menu>
                      : null
                    }
                  </Dropdown>
                </div>
              </div>
              <div className='row common-bg rounded-15 mt-2 mx-1'>
                {
                  this.state.selectedLink ?
                    <div className='col-lg-6 col-12 stream-player p-0'>
                      <ReactPlayer url={this.state.selectedLink} width='100%' />
                    </div>
                    :
                    <div className='text-center'>Tournament streaming Link not added</div>
                }
              </div>
            </div>
            :
            <div>
              <div className='row'>
                {tournamentDetails?.stages?.length > 0 ?
                  isDoublesBracketGroup ?
                    <TournamentFilter stageIndex={this.state.stage} filterMatches={this.filterMatches} state={this.state} groups={tournamentDetails?.stages[stage]?.groups} groupIndex={this.state.group} />
                    :
                    <TournamentFilter stageIndex={this.state.stage} filterMatches={this.filterMatches} state={this.state} groups={tournamentDetails?.stages[stage]?.groups} groupIndex={this.state.group} />
                  :
                  null
                }

                {isDoublesBracketGroup ?
                  <div className="btn-group" style={{ width: '100%' }} role="group" aria-label="Basic example">
                    <button type="button" onClick={() => this.switchWinnersBracket(false, stage, group, round)} style={{ width: '50%', opacity: this.state.showLoosingBracket ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds mb-4`}>Winners</button>
                    <button type="button" onClick={() => this.switchWinnersBracket(true, stage, group, round)} style={{ width: '50%', opacity: !this.state.showLoosingBracket ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds mb-4`}>Lossers</button>
                  </div>
                  :
                  null

                }
              </div>
              <div className='row'>
                <Accordion defaultActiveKey="1">
                  {matches.map((match, index) => {
                    console.log("match :: ", match.matchStreamingLink)
                    return (
                      <Accordion.Item eventKey={index} key={index} className='mt-3 rounds-time' style={{ backgroundColor: '#170d2a' }}>
                        <Accordion.Header className='round-slot-details rounds-time'><b>Match {group + 1}.{round}.{index + 1}</b></Accordion.Header>
                        <Accordion.Body className='row justify-content-center'>
                          <div className="">
                            {
                              match?.matchStreamingLink?.link ?
                                <ReactPlayer url={match?.matchStreamingLink?.link} width='100%' />
                                :
                                <div className='text-center'>Streaming Link not added for this match</div>
                            }
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}
                </Accordion>
              </div>
            </div>

        }

        <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center' type='submit'>
          <img src={share} alt='share' />
        </button>
      </section >
    )
  }
}
