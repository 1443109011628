import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AiOutlineClose } from 'react-icons/ai';
import profileLogo from '../../../../images/den/den-profile-logo.png';
import followAccept from '../../../../images/den/follow-accept.png';
import followReject from '../../../../images/den/follow-reject.png';
import LeftSideBar from '../common/LeftSideBar';
import RightSideBar from '../common/RightSideBar';
import { profile } from '../../../../utils/localstorage.utils';
import { acceptRequest, cancelRequest, getDenProfilePicture, getDenProfiles, updateDenProfileInLocalStorage } from '../../../../utils/Den.utils';
import Image from '../../../common/Image';
import { capitalize, getDetailsObj } from '../../../../utils/common.utils';
import { NavLink } from 'react-router-dom';
import { ASSETS, DEN } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';
import { DEN_EMPTY_SCREENS } from '../../../../utils/Images.utils';

/**
* @author
* @class DenFollowRequests
**/

class DenFollowRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: DEN.TABS.FOLLOW_REQUESTS,
            denProfile: null,
            requestedBy: [],
            detailsObj: null
        };
    }

    componentDidMount(){
        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile)
        }
    }

    setCurrentUserDenProfile = (result) =>{
        
        if(result[0]){
            this.setState({
                denProfile: result[0]
            });
            this.getRequestedDenProfiles(result[0]);
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }

    getRequestedDenProfiles = (denProfile) => {
        if(denProfile?.requestedBy?.length > 0){
            getDenProfiles({ profiles : denProfile.requestedBy},this.setDenProfiles)
        }else{
            this.setState({
                requestedBy: []
            })
        }
    }

    setDenProfiles = (result) =>{
        this.setState({
            requestedBy: result
        })
    }

    afterAcceptOrCancelRequest = (denProfile) =>{
        this.setState({
            denProfile: denProfile
        });
        //updateDenProfileInLocalStorage(denProfile)
        this.getRequestedDenProfiles(denProfile);
    }

    getView = () =>{
        return(
            <div className='col-12 col-lg-6'>
                <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2'>
                        <div className='row'>
                            <div className='col-3'></div>
                            <div className='col-6'>
                                <p className='fs-16 text-center fw-700 my-0'>Follow Requests</p>
                            </div>
                            <div className='col-3 text-end'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.history.goBack()} />
                            </div>
                        </div>
                        <hr className='hr-line' />
                        {this.state.requestedBy?.length>0 ?
                            <div className='row'>
                                {this.state.requestedBy.map((profile, i) => {
                                    var profilePicture = getDenProfilePicture(profile);
                                    return(
                                        <div className='col-12' key={i}>
                                            <div className='d-flex align-items-center'>
                                                <NavLink to={`/den/profile-page/${i+1}`}><Image src={profilePicture?.url} param={profile?.name ? profile?.name : "Test"} className='rounded-circle hover-zoomin' style={{height:'40px', width:'40px'}}/></NavLink>
                                                <NavLink to={`/den/profile-page/${i+1}`} className='flex-grow-1 mx-2'>
                                                    <p className='fs-16 text-white fw-600 my-0'>{profile.name}</p>
                                                    <spam className='fs-12 text-white-50 fw-500'>{profile.profileTag}</spam>
                                                </NavLink>
                                                    <img src={followAccept} alt='' onClick={()=>acceptRequest(this.state.denProfile?._id, profile._id,this.afterAcceptOrCancelRequest)} className='cursor-pointer mx-1 hover-zoomin' style={{height:'40px', width:'40px'}}/>
                                                    <img src={followReject} alt='' onClick={()=>cancelRequest(this.state.denProfile?._id, profile._id,this.afterAcceptOrCancelRequest)} className='cursor-pointer mx-1 hover-zoomin' style={{height:'40px', width:'40px'}}/>
                                            </div>
                                            <hr className='hr-line my-1' />
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <div className='row'>
                                <div className="text-center">
                                <img className="" src={DEN_EMPTY_SCREENS.NO_FOLLOW_REQUESTS} alt={""} />
                                </div>
                            </div>  
                        }

                        
                    </div>
                </div>
            </div>
        )
    }
    
    render() {
    return(
        <div className='body-section den_page'>
            <div className='container'>
                <div className='row'>
                    <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                    {this.getView()}
                    <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                </div>
            </div>
        </div>
        )
    }
 }


DenFollowRequests.propTypes = {}
export default DenFollowRequests