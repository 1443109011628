import { Component } from 'react';
import { getWebData } from '../../utils/common.utils';
import { RiAlertLine } from 'react-icons/ri';

export default class Disclaimer extends Component {
    constructor(props) {
        super(props)
      
        this.state = {}
    
        this.websiteData = getWebData();
    
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }
    
    render() {
        const disclaimers = this.websiteData?.STATIC_PAGE?.DISCLAIMER?.SET_UP_DISCLAIMER?.split("&&");
        
        return (
            <section className="body-section">      
                <div className="p-3 d-flex flex-column mx-auto notifications" >
                    <div className="row text-center">
                        <div className="col-12">
                        <h1 className="fs-20 fw-bold text-white mb-2 mt-3"> <RiAlertLine style={{color: 'white'}} />  Disclaimer </h1>
                        </div>
                    </div>
                    <div className='row '>
                    <ol className='text-white'>
                        {disclaimers?.map((disclaimer,i)=>{
                        return <li key={i}>{disclaimer}</li>
                        })}
                    </ol>
                    </div>
                </div>
            </section>
        )
    }
}
