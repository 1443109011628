import React, { Component } from 'react'
import { POST_INTERACTIONS, PROFILE_PAGE_IMAGES } from '../../../../utils/Images.utils'
import Image from '../../../common/Image'
import { BsThreeDots } from 'react-icons/bs';
import { BiGlobe } from 'react-icons/bi';
import { MdOutlineLock } from 'react-icons/md';

import avatar from '../../../../images/participantAvtar.jpg';
import { receivedAt } from '../../../../utils/dateTime.utils';
import { NavLink } from 'react-router-dom';
import { Carousel, Modal } from 'react-bootstrap';
import DenPoll from './DenPoll';
import Sparks from './Sparks';
import PostComments from './PostComments';
import { createAndShareDenPost, getDenProfilePicture, shareDenPost } from '../../../../utils/Den.utils';
import Interactions from './Interactions';
import { DEN } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';
import { getNumberFormat } from '../../../../utils/Numbers.utils';
import DenInteractions from '../../../popup/den/DenInteractions';

export default class RePostCard extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            post: props.post,
            i: props.i,
            denProfile: props.denProfile,
            interactions: props.interactions,
            feedDetails: props.feedDetails,
            showMoreNotificationIndex: props.showMoreNotificationIndex,
            selectedPostIndex: props.selectedPostIndex,
            postToInteract: null,
            showInteractions: false
        };
    }

    componentWillReceiveProps(newProps){
        this.setState({
            post: newProps.post,
            denProfile: newProps.denProfile,
            showMoreNotificationIndex: newProps.showMoreNotificationIndex,
            i: newProps.i,
            interactions: newProps.interactions,
            feedDetails: newProps.feedDetails,
            selectedPostIndex: newProps.selectedPostIndex,
            postToInteract: newProps.postToInteract,
            reportedPostId: newProps.reportedPostId
        })
    } 


    shareDenPost = (postId) =>{
        var data = {
            postAs :"USER",
            type : "REPOST",
            rePostId: postId,
            description : "",
            assets : [],
            denId: this.state.denProfile?._id
        };
        createAndShareDenPost(data, this.props.denPostShared, true);
    }

    checkStatus = (e) => {
        if(this.props.checkDenStatus){
            e.preventDefault();
        }
    }
    
    render() {
        const {i, post, interactions, denProfile, feedDetails, showInteractions, showMoreNotificationIndex, reportedPostId} = this.state;
        const styleLogo = { height: '20px', width: 'auto' };
        const openCommentSection = (this.state.postToInteract !== null && this.state.postToInteract === i)
        
        if(post.rePostDetails?.length >0 && post.rePostDetailsFrom?.length >0){

            var profilePicture1 = getDenProfilePicture(post.from[0]);
            var profilePicture2 = getDenProfilePicture(post.rePostDetailsFrom[0]);
         
            return (
                <div>
                    <div className='steel_gray-card mt-2' style={STYLE.AREA.RADIUS.R8}>
                                    
                        <div className='row px-3 pt-3 my-2'>
                            <div className='col-12'>
                                <div className="d-flex">
                                    <div onClick={()=>this.props.goToUserDenProfile(post.from[0]?._id)}  className="d-flex flex-grow-1">
                                        <Image src={profilePicture1?.url} className="rounded-circle btn-size-32  border-1" param={post.from[0]?.name? post.from[0]?.name : 'Test' } alt="owner-avatar" />
                                        <div className="ms-2 flex-grow-1">
                                            <h1 className='fs-14 fw-600 text-white mb-0'>{post.from[0]?.name? post.from[0]?.name : null}</h1> 
                                            
                                            <p className='fs-12 text-white-50 fw-bold opacity-25'>{receivedAt(post.createdAt)} <span className='me-1'>.</span>
                                                {post.from[0].isPublic ? <BiGlobe className='text-white btn-size-12' /> :  <MdOutlineLock className='text-white btn-size-12' /> }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cursor-pointer" onClick={() => {
                                        if(this.props.checkDenStatus()){
                                            this.props.showHidePostAction(i,true, false)}
                                        }}
                                    >
                                        <Image src={POST_INTERACTIONS.ACTIONS.MORE} params={'action'} className="" style={STYLE.AREA.WIDTH.W24} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mx-2" style={STYLE.DEN.POST.SUB_POST}>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="d-flex p-2" onClick={()=>this.props.goToUserDenProfile(post.rePostDetailsFrom[0]?._id)}>
                                        <Image src={profilePicture2?.url} className="rounded-circle btn-size-32  border-1" param={post.rePostDetailsFrom[0]?.name? post.rePostDetailsFrom[0]?.name : 'Test' } alt="owner-avatar" />
                                        <div className="ms-2">
                                            
                                            <h1 className='fs-14 fw-600 text-white mb-0'>{post.rePostDetailsFrom[0]?.name? post.rePostDetailsFrom[0]?.name : null}  <span style={STYLE.FONT.WEIGHT.W300}>{POST_INTERACTIONS.FEELINGS[post.rePostDetails[0].feeling]? `is feeling ${POST_INTERACTIONS.FEELINGS[post.rePostDetails[0].feeling].NAME}` : post.rePostDetails[0].feeling}</span>  {POST_INTERACTIONS.FEELINGS[post.rePostDetails[0].feeling] && <img src={POST_INTERACTIONS.FEELINGS[post.rePostDetails[0].feeling]?.LOGO} alt='' style={STYLE.FONT.HEIGHT.H18} />}  </h1> 
                                                
                                            
                                            <p className='fs-12 text-white-50 fw-bold opacity-25'>{receivedAt(post.rePostDetails[0]?.createdAt)} <span className='me-1'>.</span>
                                                {post.rePostDetailsFrom[0]?.isPublic ? <BiGlobe className='text-white btn-size-12' /> :  <MdOutlineLock className='text-white btn-size-12' /> }
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-12 my-1 p-2'>
                                        { ((this.state.showMoreNotificationIndex !== null && this.state.showMoreNotificationIndex === i) || post.rePostDetails[0]?.description.length < 251 ) ?  
                                            <span className='fs-12 fw-400 text-white'>{this.props.getFilterredDescription(post.rePostDetails[0]?.description)}</span>
                                            :
                                            <span className='fs-12 fw-400 text-white'>
                                                <em>{this.props.getFilterredDescription(post.rePostDetails[0]?.description?.slice(0, ( 250-post.rePostDetails[0]?.description.length)))}...</em>
                                                <a className="fs-14 fw-bold cursor-pointer den-show-more" onClick={() => this.props.updateState('showMoreNotificationIndex', i)}>Show More</a>                                           
                                            </span>
                                        }
                                    </div>
                                {/* Assets */}
                                    <div className='d-flex flex-column align-items-center'>

                                        {post.rePostDetails[0].type && post.rePostDetails[0].type === 'POST' ?
                                        
                                            <Carousel interval={null} className={`${post.rePostDetails[0].assets.length > 1 ? '' : 'single'}`}>
                                            {post.rePostDetails[0].assets.map((asset,i)=>{
                                                    return(
                                                        <Carousel.Item key={i} interval={null} className="">
                                                            {asset.type.includes('video') ? 
                                                                <video width="320" height="240" className='feed_post-image' controls autoPlay>
                                                                    <source src={asset.url} type="video/mp4"/>
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                                :
                                                                <img src={asset.url} className='feed_post-image' alt=''  onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src="https://espotz-webapp-images.s3.ap-south-1.amazonaws.com/assets/broken-1.png"
                                                                }} />
                                                            }
                                                        </Carousel.Item>
                                                    )
                                                })}
                                            </Carousel>
                                            :
                                            <DenPoll updateState={()=>{}} post={post.rePostDetails[0]} denProfile={this.state.denProfile} updatePostDetails={this.props.updatePostDetails} enableEdit={false} />
                                        }
                                        
                                        {/* Grid of spark's on a post */}
                                        {this.state.selectedPostIndex !== null &&  <Sparks denProfile={denProfile} index={i} feedDetails={feedDetails} selectedPostIndex={this.state.selectedPostIndex} updateLikedPostDetails={this.props.updateLikedPostDetails} updateProfileDetails={this.props.updateProfileDetails}  /> }
                                    </div>
                                
                                </div>
                            </div>
                        </div>

                        <hr className='my-1' style={STYLE.DEN.POST.LINE} />
                        
                        <div className='d-flex px-2 py-2'>
                            {/* <NavLink to={`/den/${denProfile?._id}/feed/post/${post._id}`} className='d-flex align-items-center flex-grow-1 cursor-pointer'>
                                {this.props.sparkDetails?.logo.map((key,k)=>{
                                    return  <img key={k} src={POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO} alt='' className='rounded-circle' style={STYLE.DEN.REACTED_USERS} />
                                })}
                                <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{this.props.sparkDetails?.likes > 0 ? getNumberFormat(this.props.sparkDetails?.likes) : null }</span>
                            </NavLink> */}
                            <div onClick={()=> this.props.checkDenStatus() ? this.setState({showInteractions: true}) : null} className='d-flex align-items-center flex-grow-1 cursor-pointer'>
                                {this.props.sparkDetails?.logo.map((key,k)=>{
                                    return  <img key={k} src={POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO} alt='' className='rounded-circle' style={STYLE.DEN.REACTED_USERS} />
                                })}
                                <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{this.props.sparkDetails?.likes > 0 ? getNumberFormat(this.props.sparkDetails?.likes) : null }</span>
                            </div>
                            <div className='d-flex align-items-center mx-2'>
                                <img src={POST_INTERACTIONS.HAMMER.OFF} alt='' style={STYLE.DEN.POST.INTERACTIONS} />
                                <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{post.hammer.length}</span>
                            </div>
                            <div className='d-flex align-items-center mx-2'>
                                <img src={POST_INTERACTIONS.SHARE.OFF} alt='' style={STYLE.DEN.POST.INTERACTIONS} />
                                <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{post.shares.length}</span>
                            </div>
                        </div>

                        <hr className='my-1' style={STYLE.DEN.POST.LINE}/>
                        <Interactions 
                            index={i} 
                            interactions={interactions} 
                            styleLogo={styleLogo} 
                            openCommentSection={openCommentSection} 
                            post={post} 
                            selectedPostIndex={this.state.selectedPostIndex}
                            postToInteract={this.state.postToInteract}
                            denProfile={denProfile} 
                            updateState={this.props.updateState}
                            shareDenPost={this.shareDenPost}
                        />

                    
                        {openCommentSection ?  <PostComments denProfile={denProfile} updateProfileDetails={this.props.updateProfileDetails} feedDetails={feedDetails} postDetails={post} postId={post._id}/> : null}

                    
                    </div>

                    {showInteractions && 
                        <Modal show={showInteractions} centered dialogClassName="transaction-details-modal">
                            <Modal.Body>
                                <DenInteractions
                                    denId={denProfile?._id}
                                    id={post._id}
                                    type="post"
                                    close={()=>this.setState({showInteractions: false})}
                                />
                            </Modal.Body>
                        </Modal>
                    }
                    
                </div>

            )
        }else{
            return null;
        }
    }
}
