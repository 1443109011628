import React, { Component } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { CREATE_POST_VIEWS } from '../../../../../utils/enums.utils';
import { ACTION, POST_INTERACTIONS } from '../../../../../utils/Images.utils';


export default class PostAsSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postAs: props.postAs,
            chooseOrg: props.chooseOrg
         };
    }

    componentWillReceiveProps(newProps){
        this.setState({
            postAs: newProps.postAs,
            chooseOrg: newProps.chooseOrg
        })
    }

    updateState = (key, val) => {
        this.setState({
            [key] : val
        });
    }


 
    render() {
        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{height:'82vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2 flex-grow-1'>
                        <div className='row px-2'>
                            <div className='col-2'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)} />
                            </div>
                            <div className='col-8'>
                                <p className='fs-16 text-center fw-700 my-0'>Post As</p>
                            </div>
                            <div className='col-2'>
                                <p className='cursor-pointer fs-16 fw-600 my-0 mx-1' style={{ color:'#AD26FF'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)}>Done</p>
                            </div>
                        </div>
                        <hr className='hr-line' />
                        <p className='fs-16 fw-500'>Choose the role for posting?</p>
                        <p className='fs-14 fw-500 my-0'>Your post will appear to be posted by the roles you choose from the options.</p>
                        <hr className='hr-line' />
                        {this.state.chooseOrg ?
                            <div>
                                <div className='d-flex align-items-center my-2'>
                                    <img src={POST_INTERACTIONS.AS.ORG} alt='' className='opacity-75' style={{ height:'24px', width:'24px'}} />
                                    <div className='d-flex flex-column flex-grow-1 mx-2'>
                                        <p className='fs-16 fw-500 my-0'>Bluethorns</p>
                                        <p className='fs-12 fw-500 text-white-50 my-0'>Anyone on Espotz Den</p>
                                    </div>
                                    {true? 
                                        <img src={ACTION.SELECTED} alt='' className='' style={{ height:'16px'}} />
                                        :
                                        <img src={ACTION.UNSELECTED} alt='' className='' style={{ height:'16px'}} onClick={() => this.props.updateState('audience','PUBLIC')}/>
                                    }
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <img src={POST_INTERACTIONS.AS.ORG} alt='' className='opacity-75' style={{ height:'24px', width:'24px'}} />
                                    <div className='d-flex flex-column flex-grow-1 mx-2'>
                                        <p className='fs-16 fw-500 my-0'>Alphaforntiters</p>
                                        <p className='fs-12 fw-500 text-white-50 my-0'>Anyone on Espotz Den</p>
                                    </div>
                                    {true? 
                                        <img src={ACTION.SELECTED} alt='' className='' style={{ height:'16px'}} />
                                        :
                                        <img src={ACTION.UNSELECTED} alt='' className='' style={{ height:'16px'}} onClick={() => this.props.updateState('audience','PUBLIC')}/>
                                    }
                                </div>
                            </div>
                            :
                            <div>
                                <div className='d-flex align-items-center my-2'>
                                    <img src={POST_INTERACTIONS.AS.USER} alt='' className='opacity-75' style={{ height:'30px'}}/>
                                    <div className='d-flex flex-column flex-grow-1 mx-2'>
                                        <p className='fs-16 fw-500 my-0'>You</p>
                                        <p className='fs-12 fw-500 text-white-50 my-0'>Your profile would appear on the post</p>
                                    </div>
                                    {this.state.postAs === 'SELF' ? 
                                        <img src={ACTION.SELECTED} alt='' className='' style={{ height:'16px'}} />
                                        :
                                        <img src={ACTION.UNSELECTED} alt='' className='' style={{ height:'16px'}} onClick={() => this.props.updateState('postAs','SELF')}/>
                                    }
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <img src={POST_INTERACTIONS.AS.CLAN} alt='' className='opacity-75' style={{ height:'30px', width:'24px'}} />
                                    <div className='d-flex flex-column flex-grow-1 mx-2'>
                                        <p className='fs-16 fw-500 my-0'>Clan (Deathriders)</p>
                                        <p className='fs-12 fw-500 text-white-50 my-0'>Anyone on Espotz Den</p>
                                    </div>
                                    {this.state.postAs === 'CLAN' ? 
                                        <img src={ACTION.SELECTED} alt='' className='cursor-pointer' style={{ height:'16px'}} />
                                        :
                                        <img src={ACTION.UNSELECTED} alt='' className='cursor-pointer' style={{ height:'16px'}} onClick={() => this.props.updateState('postAs','CLAN')} />
                                    }
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <img src={POST_INTERACTIONS.AS.ORG} alt='' className='opacity-75' style={{ height:'24px', width:'24px'}} />
                                    <div className='d-flex flex-column flex-grow-1 mx-2'>
                                        <p className='fs-16 fw-500 my-0'>Organization (Choose)</p>
                                        <p className='fs-12 fw-500 text-white-50 my-0'>Anyone on Espotz Den</p>
                                    </div>
                                    <img src={POST_INTERACTIONS.PROCEED} alt='' className='cursor-pointer' style={{ height:'16px'}} onClick={() => this.props.updateState('chooseOrg', true)}/>
                                </div>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}
